import EmployeeId from '@/domain/employee/EmployeeId';
import ClientId from '@/domain/client/ClientId';
import EmployeeName from '@/domain/employee/EmployeeName';
import EmployeeSurname from '@/domain/employee/EmployeeSurname';
import EmployeePosition from '@/domain/employee/EmployeePosition';
import EmployeeEntryDate from '@/domain/employee/EmployeeEntryDate';
import EmployeeExitDate from '@/domain/employee/EmployeeExitDate';
import EmployeeNif from '@/domain/employee/EmployeeNif';
import EmployeeDocumentData from '@/domain/employee/EmployeeDocumentData';
import EmployeeEmail from '@/domain/employee/EmployeeEmail';

export default abstract class EmployeeRepresentation {
  protected _id?: EmployeeId;

  protected _clientId?: ClientId;

  protected _name?: EmployeeName;

  protected _surname?: EmployeeSurname;

  protected _email: EmployeeEmail | null = null;

  protected _position: EmployeePosition | null = null;

  protected _entry: EmployeeEntryDate | null = null;

  protected _exit: EmployeeExitDate | null = null;

  protected _nif: EmployeeNif | null = null;

  protected _documentData: EmployeeDocumentData = EmployeeDocumentData.fromInt(0);

  setId(id: EmployeeId): this {
    this._id = id;
    return this;
  }

  setClientId(clientId: ClientId): this {
    this._clientId = clientId;
    return this;
  }

  setName(name: EmployeeName): this {
    this._name = name;
    return this;
  }

  setSurname(surname: EmployeeSurname): this {
    this._surname = surname;
    return this;
  }

  setEmail(email: EmployeeEmail | null): this {
    this._email = email;
    return this;
  }

  setPosition(position: EmployeePosition | null): this {
    this._position = position;
    return this;
  }

  setEntry(entry: EmployeeEntryDate | null): this {
    this._entry = entry;
    return this;
  }

  setExit(exit: EmployeeExitDate | null): this {
    this._exit = exit;
    return this;
  }

  setNif(nif: EmployeeNif | null): this {
    this._nif = nif;
    return this;
  }

  setDocumentData(documentData: EmployeeDocumentData): this {
    this._documentData = documentData;
    return this;
  }
}
