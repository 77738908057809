import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import Login from '@/domain/user/login/Login';
import UserPassLoginCredentials from '@/domain/user/login/Credentials/UserPassLoginCredentials';

export default class UserLoginHandler {
  private login: Login;

  constructor(login: Login) {
    this.login = login;
  }

  public async invoke(email: string, password: string): Promise<object> {
    try {
      const loginCredentials = new UserPassLoginCredentials(
        EmailValueObject.fromString(email),
        password,
      );
      const subdomain = await this.login.invoke(loginCredentials);
      return { success: true, subdomain };
    } catch (e) {
      return { success: false };
    }
  }
}
