import EquipmentId from '../../../domain/equipment/EquipmentId';

export default (
  equipmentRepository,
  createEquipmentEntity,
) => async (clientId, equipment) => {
  try {
    const equipmentId = EquipmentId.generate();
    const equipmentEntity = createEquipmentEntity(clientId, equipment, equipmentId);

    await equipmentRepository.save(equipmentEntity);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
