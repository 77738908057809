<template>
  <v-dialog
    v-if="url"
    v-model="showViewer"
    scrollable
    max-width="80%"
    persistent
    @click:outside="hideModalViewer"
    @keydown.esc="hideModalViewer"
  >
    <v-card
      class="viewer-pdf"
    >
      <v-toolbar
        dark
        dense
      >
        <v-row align="center" justify="center">
          <v-col md="6">
            <p class="viewer-pdf__name-document">
              {{ name }}
            </p>
          </v-col>
          <v-col md="6" class="text-right">
            <v-btn
              icon
              @click="hideModalViewer"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card-text class="viewer-pdf__iframe" :style="wrapperStyle">
        <iframe
          id="iframe_viewer_document"
          :src="pdfUrl"
          type="application/pdf"
          frameborder="0"
          height="100%"
          width="100%"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { mapGetters } from 'vuex';
import documentViewerService from '@/services/documents/documentViewer';

export default {
  name: 'document-viewer',
  computed: {
    ...mapGetters('viewDocument', [
      'showViewer',
      'url',
      'name',
    ]),
    pdfUrl() {
      return `${this.url}#toolbar=0`;
    },
  },
  data() {
    return {
      wrapperStyle: null,
      loaded: false,
    };
  },
  methods: {
    hideModalViewer() {
      documentViewerService.hideDocumentViewer();
      documentViewerService.resetDocumentInformation();
    },
  },
  mounted() {
    const heightWindow = window.innerHeight;
    this.wrapperStyle = {
      height: `${heightWindow}px`,
    };
  },
};
</script>
