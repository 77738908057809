<template>
  <div>
    <v-btn
      icon
      color="primary"
      v-track="`Edit Tom ${tom.nameKey}`"
      @click="goToEditTom()"
    >
      <v-icon>
        more_vert
      </v-icon>
    </v-btn>
    <dialog-form-tom
      v-if="showModal"
      :showModal.sync="showModal"
      :tomToEdit="tom"
      @save="saveTom"
      @update:showModal="closeModalAndRedirect()"
    />
  </div>
</template>

<script>
import DialogFormTom from '@/components/modules/tom/form/DialogFormTom.vue';
import FetchTomQuery from '@/application/tom/fetchTom/FetchTomQuery';
import EditTomCommand from '@/application/tom/editTom/EditTomCommand';

export default {
  name: 'edit-tom',
  inject: {
    fetchTom: 'fetchTom',
    editTom: 'editTom',
  },
  components: { DialogFormTom },
  props: {
    tomId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      tom: {},
    };
  },
  methods: {
    goToEditTom() {
      this.$router.push({ name: this.$route.name, params: { tomId: this.tomId } });
    },
    async loadTomAndShowModal() {
      this.$showLoading();
      const response = await this.fetchTom.invoke(new FetchTomQuery(this.tomId));
      this.$hideLoading();

      if (!response.success) {
        this.$showError(`toms.error.${response.error}`, 'toms.error.load_tom_form');
        return;
      }
      this.tom = response.tom;
      this.showModal = true;
    },
    openFormEditIfRouteRequestIt() {
      this.showModal = false;
      if (this.tomId === this.$route.params.tomId) {
        this.loadTomAndShowModal();
      }
    },
    closeModalAndRedirect() {
      this.showModal = false;
      this.$router.push({ name: this.$route.name, params: { tomId: null } });
    },
    async saveTom(tom) {
      this.$showLoading();
      const response = await this.editTom.invoke(new EditTomCommand(
        tom.id,
        tom.notes,
        tom.attachedDocuments,
        tom.attachedDocumentsToDelete,
        tom.status,
      ));
      this.$hideLoading();

      this.$track({
        action: 'Edit Tom -- finish',
        result: response.success,
        tom: this.tom.nameKey,
      });

      if (!response.success) {
        this.$showError(`toms.error.${response.error}`, 'toms.error.editing_tom');
        return;
      }

      this.$showSuccess('toms.information_modal.success_edition');
      this.$eventHub.$emit('tomWasUpdated');
      this.closeModalAndRedirect();
    },
  },
  beforeMount() {
    this.openFormEditIfRouteRequestIt();
    this.$watch(() => this.$route.params, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.openFormEditIfRouteRequestIt();
      }
    });
  },
};
</script>
