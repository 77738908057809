import EventListener from '@/application/eventBus/EventListener';
import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import DataBreachCollaboratorWasAdded
  from '@/domain/dataBreach/collaborator/DataBreachCollaboratorWasAdded';

export default class DataBreachCollaboratorWasAddedEventListener implements EventListener {
  private readonly clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public handle(dataBreachCollaboratorWasAdded: DataBreachCollaboratorWasAdded) {
    this.clientUserRepository.reset();
  }
}
