import ImportFileTypeValidationException
  from '@/domain/import/ImportFileTypeValidationException';

const RESTRICED_EMPLYEE = 'restrictedEmployees';

export default class ImportFileType {
  private static validTypes: string[] = [
    RESTRICED_EMPLYEE,
  ];

  private readonly type: string;

  protected constructor(type: string) {
    ImportFileType.validOrFail(type);
    this.type = type;
  }

  private static validOrFail(type: string) {
    if (!ImportFileType.validTypes.includes(type)) {
      throw new ImportFileTypeValidationException(type);
    }
  }

  public static fromString(type: string): ImportFileType {
    return new this(type);
  }

  public toString(): string {
    return this.type;
  }
}
