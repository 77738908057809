import RecipientId from '@/domain/recipient/RecipientId';
import DocumentDataRecipientRepresentation from './DocumentDataRecipientRepresentation';

export default (
  documentDataRecipientRepository,
) => async (recipientId, clientId) => {
  try {
    const recipientDocuments = await documentDataRecipientRepository.ofRecipient(
      RecipientId.fromString(recipientId),
      clientId,
    );

    return {
      success: true,
      documents: recipientDocuments.map(
        (documentData) => documentData.representedAs(new DocumentDataRecipientRepresentation()),
      ),
    };
  } catch (e) {
    return { success: false };
  }
};
