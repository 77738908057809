export default ({
  uploadedRepository,
  downloadFile,
}) => async (companyId, documentId, isAdmin) => {
  try {
    const urlDownload = await uploadedRepository.downloadDocument(companyId, documentId, isAdmin);
    downloadFile.download(urlDownload.url);

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
