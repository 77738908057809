import StringValueObject from '../shared/strings/StringValueObject';

export default class DocumentDataName extends StringValueObject {
  name;

  constructor(name) {
    super(name);
    this.name = name;
  }
}
