<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    @click:outside="showDialog = false"
    @keydown.esc="showDialog = false"
  >
    <v-card class="download-type-file">
      <v-card-title>
        <h2>
          {{ $t('download_extension.select_extension') }}
        </h2>
        <v-spacer />
        <v-btn
          icon
          @click="showDialog = false"
        >
          <v-icon v-text="'close'" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col class="text-right">
            <div @click="extensionWasSelected('pdf')">
              <FilePdf />
            </div>
          </v-col>
          <v-col class="text-left">
            <div @click="extensionWasSelected('doc')">
              <FileWord />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          color="primary"
          @click="showDialog = false"
        >
          {{ $t('download_extension.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import FilePdf from '../icons/FilePdf.vue';
import FileWord from '../icons/FileWord.vue';

export default {
  name: 'select-document-extension',
  components: {
    FilePdf,
    FileWord,
  },
  data() {
    return {
      showDialog: false,
      downloadDocument: {},
    };
  },
  methods: {
    showOptionsExtensionFile(document) {
      this.downloadDocument = document;
      this.showDialog = true;
    },
    extensionWasSelected(type) {
      this.showDialog = false;
      this.$eventHub.$emit('downloadDocument', this.downloadDocument, type);
    },
  },
  created() {
    this.$eventHub.$on('showOptionsExtensionFile', this.showOptionsExtensionFile);
  },
  beforeDestroy() {
    this.$eventHub.$off('showOptionsExtensionFile', this.showOptionsExtensionFile);
  },
};
</script>
