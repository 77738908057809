<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items.sync="states"
    :label="`${$t('recipient.form.state')} *`"
    :rules="rules"
    item-value="code"
  />
</template>

<script>
import statesUseCases from '@/application/country/state';
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'state-selector',
  components: {
    SelectAutocompleteField,
  },
  props: {
    stateSelected: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      states: [],
      value: this.stateSelected,
    };
  },
  methods: {
    async getStates() {
      this.states = await statesUseCases.fetchStates();
    },
  },
  beforeMount() {
    this.getStates();
  },
  watch: {
    stateSelected() {
      this.value = this.stateSelected;
    },
    value() {
      this.$emit('update:stateSelected', this.value);
    },
  },
};
</script>
