import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';

export default class ClientUserRawRegisterRepresentation extends ClientUserRepresentation {
  public toObject(): any {
    return {
      name: this._name.toString(),
      surname: this._surname.toString(),
      email: this._email.toString(),
      password: this._password!.toString(),
    };
  }
}
