<template>
  <general-table
    :headers="headers"
    :list="equipments"
    :search.sync="search"
    :customFilterSearch="customFilterSearch"
    :columns="['name', 'actions']"
  >
    <template v-slot:name="props">
      <edit-equipment
        :equipment="props.item"
      />
    </template>
    <template v-slot:actions="props">
      <remove-equipment
        :equipment="props.item"
      />
    </template>
  </general-table>
</template>

<script>
import EditEquipment from '@/components/modules/register/equipments/EditEquipment.vue';
import RemoveEquipment from '@/components/modules/register/equipments/RemoveEquipment.vue';
import GeneralTable from '../../../base/tables/GeneralTable.vue';

export default {
  name: 'list-equipments',
  inject: {
    user: 'user',
    fetchEquipments: 'fetchEquipments',
  },
  components: {
    RemoveEquipment,
    EditEquipment,
    GeneralTable,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('equipment.table.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: this.$t('equipment.table.type'),
          align: 'start',
          sortable: true,
          value: 'type',
          width: '20%',
        },
        {
          text: this.$t('equipment.table.location'),
          align: 'start',
          sortable: true,
          value: 'location',
          width: '20%',
        },
        {
          text: this.$t('equipment.table.security_measures'),
          align: 'start',
          sortable: false,
          value: 'securityMeasures',
          width: '40%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '10%',
        },
      ];
    },
  },
  data() {
    return {
      equipments: [],
      customFilterSearch: (value, search, item) => (
        item.nameNormalized.indexOf(search) !== -1
      ),
    };
  },
  methods: {
    async getEquipments() {
      this.$showLoading();
      const response = await this.fetchEquipments(this.user.clientId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('equipment.table.error');
        return;
      }

      this.equipments = response.equipments;
      this.$eventHub.$emit('equipmentsLoaded', this.equipments.length);
    },
  },
  beforeMount() {
    this.getEquipments();
  },
  created() {
    this.$eventHub.$on('equipmentWasCreated', this.getEquipments);
    this.$eventHub.$on('equipmentWasDeleted', this.getEquipments);
    this.$eventHub.$on('equipmentWasUpdated', this.getEquipments);
  },
  beforeDestroy() {
    this.$eventHub.$off('equipmentWasCreated', this.getEquipments);
    this.$eventHub.$off('equipmentWasDeleted', this.getEquipments);
    this.$eventHub.$off('equipmentWasUpdated', this.getEquipments);
  },
};
</script>
