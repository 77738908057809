import HeadquarterId from '@/domain/headquarter/HeadquarterId';
import HeadquarterRepresentation
  from '@/application/headquarter/fetchHeadquarter/HeadquarterRepresentation';

export default (
  headquarterRepository,
) => async (headquarterId) => {
  try {
    const headquarter = await headquarterRepository.retrieve(
      HeadquarterId.fromString(headquarterId),
    );

    const headquarterRepresentation = headquarter.representedAs(
      new HeadquarterRepresentation(),
    );

    return { success: true, headquarter: headquarterRepresentation };
  } catch (e) {
    return { success: false };
  }
};
