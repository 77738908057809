<template>
  <div>
    <cookies />
  </div>
</template>

<script>
import Cookies from '@/components/modules/legal/Cookies.vue';

export default {
  name: 'cookies-container',
  components: {
    Cookies,
  },
};
</script>
