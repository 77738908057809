import DataBreachStatusException from '@/domain/dataBreach/DataBreachStatusException';

export default class DataBreachStatus {
  private static DRAFT = 'draft';

  private static COMPLETED = 'completed';

  private static STATUS: string[] = [
    'draft',
    'completed',
  ];

  private readonly status: string;

  protected constructor(status: string) {
    DataBreachStatus.isValidOrFail(status);
    this.status = status;
  }

  private static isValidOrFail(status: string) {
    if (!DataBreachStatus.STATUS.includes(status)) {
      throw new DataBreachStatusException(`${this.constructor.name} doesn't exist`);
    }
  }

  public static draft(): DataBreachStatus {
    return new this(DataBreachStatus.DRAFT);
  }

  public static completed(): DataBreachStatus {
    return new this(DataBreachStatus.COMPLETED);
  }

  public static fromString(status: string): DataBreachStatus {
    return new this(status);
  }

  public toString(): string {
    return this.status;
  }
}
