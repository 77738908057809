import HeadquarterId from '@/domain/headquarter/HeadquarterId';

export default (
  headquartersRepository,
) => async (headquartersId) => {
  try {
    await headquartersRepository.delete(HeadquarterId.fromString(headquartersId));
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
