export default ({
  processingActivitiesRepository,
  dataMap,
}) => async (companyId, lang) => {
  try {
    const response = await processingActivitiesRepository.getInfoDataMap(
      companyId, lang,
    );

    let data = {};
    if (Object.keys(response).length) {
      data = dataMap.removeEmptyDataSource(response);
    }

    return { success: true, data };
  } catch (e) {
    if (e === 401) {
      return { success: true, data: {} };
    }
    return { success: false, data: {} };
  }
};
