import RecipientStatus from '@/domain/recipient/recipientStatus/RecipientStatus';
import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';
import RecipientWasUpdated from '@/domain/recipient/RecipientWasUpdated';
import RecipientToUpdateRepresentation from '@/domain/recipient/RecipientToUpdateRepresentation';
import RecipientName from '@/domain/recipient/RecipientName';
import RecipientBusinessName from '@/domain/recipient/RecipientBusinessName';
import RecipientLastModification from '@/domain/recipient/RecipientLastModification';
import CountryCode from '@/domain/country/CountryCode';
import State from '@/domain/country/state/State';
import RecipientCategory from '@/domain/recipient/recipientCategory/RecipientCategory';
import RecipientRole from '@/domain/recipient/recipientRole/RecipientRole';
import RecipientWarranty from '@/domain/recipient/recipientWarranties/RecipientWarranty';
import RecipientDpa from '@/domain/recipient/RecipientDpa';
import RecipientDpaStatus from '@/domain/recipient/recipientDpaStatus/RecipientDpaStatus';
import RecipientDocument from '@/domain/recipient/RecipientDocument';
import RecipientId from '@/domain/recipient/RecipientId';

function _createRecipientToUpdate(recipientForm) {
  const lastModification = DateTimeValueObject.generateW3C();

  const recipientToUpdateRepresentation = new RecipientToUpdateRepresentation();
  recipientToUpdateRepresentation.setName(RecipientName.fromString(recipientForm.name));

  if (recipientForm.businessName) {
    recipientToUpdateRepresentation.setBusinessName(
      RecipientBusinessName.fromString(recipientForm.businessName),
    );
  }
  recipientToUpdateRepresentation.setStatus(new RecipientStatus(recipientForm.status));
  recipientToUpdateRepresentation.setLastModification(
    new RecipientLastModification(lastModification.toString()),
  );

  if (recipientForm.country) {
    recipientToUpdateRepresentation.setCountry(new CountryCode(recipientForm.country));
  }
  if (recipientForm.state) {
    recipientToUpdateRepresentation.setState(new State(recipientForm.state));
  }
  if (recipientForm.category) {
    recipientToUpdateRepresentation.setCategory(new RecipientCategory(recipientForm.category));
  }
  if (recipientForm.role) {
    recipientToUpdateRepresentation.setRole(new RecipientRole(recipientForm.role));
  }
  recipientToUpdateRepresentation.setWarranties(
    recipientForm.warranties.map((warranty) => new RecipientWarranty(warranty)),
  );
  if (recipientForm.dpa) {
    recipientToUpdateRepresentation.setDpa(
      RecipientDpa.fromString(recipientForm.dpa),
    );
  }
  if (recipientForm.dpaStatus) {
    recipientToUpdateRepresentation.setDpaStatus(new RecipientDpaStatus(recipientForm.dpaStatus));
  }

  recipientToUpdateRepresentation.setDocuments(
    recipientForm.files.map((file) => RecipientDocument.fromContent(file)),
  );

  return recipientToUpdateRepresentation;
}

export default (
  recipientRepository,
  recipientWasUpdatedEventListener,
) => async (recipientForm, recipientId) => {
  try {
    const recipient = await recipientRepository.retrieve(RecipientId.fromString(recipientId));
    const recipientToUpdate = _createRecipientToUpdate(recipientForm);
    recipient.update(recipientToUpdate);

    await recipientRepository.update(recipient);

    const event = new RecipientWasUpdated(recipient);
    recipientWasUpdatedEventListener.invoke(event);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
