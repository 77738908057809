import dataBreachesPendingReadModel from '@/domain/dataBreach/pending/DataBreachesPendingReadModel';
import DataBreachesPendingRepresentation
  from '@/application/dataBreach/pending/fetchDataBreachesPending/DataBreachesPendingRepresentation';
import ClientId from '@/domain/client/ClientId';

export default class FetchDataBreachesPending {
  private readonly dataBreachPendingReadModel: dataBreachesPendingReadModel;

  constructor(dataBreachPendingReadModel: dataBreachesPendingReadModel) {
    this.dataBreachPendingReadModel = dataBreachPendingReadModel;
  }

  async invoke(clientId: number): Promise<object> {
    try {
      const dataBreachesPending: DataBreachesPendingRepresentation[] = await this
        .dataBreachPendingReadModel.ofClient(ClientId.fromInt(clientId));
      return {
        success: true,
        dataBreachesPending,
      };
    } catch (e) {
      return { success: false };
    }
  }
}
