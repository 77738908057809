import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MAX_LENGTH = 65;
const MIN_LENGTH = 8;

export default class EmployeeNif extends StringValueObject {
  constructor(string: string) {
    super(string, MIN_LENGTH, MAX_LENGTH);
  }
}
