import store from '@/store';
import adminUseCases from '@/usecases/admins';

async function getInfoAdmin(subdomain) {
  if (store.state.admin.subdomain !== subdomain) {
    await adminUseCases.fetchAdminInfo(subdomain);
  }
}

export default {
  getInfoAdmin,
};
