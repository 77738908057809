import store from '@/store/';
import RecipientWriteModel from '@/domain/recipient/writeModel/RecipientWriteModel';

export default class RecipientListRepresentationCache extends RecipientWriteModel {
  resetRecipients() {
    store.dispatch('recipientsListRepresentation/resetRecipientsListRepresentations');
  }

  static getRecipients() {
    return store.getters['recipientsListRepresentation/recipientsListRepresentations'];
  }

  static setRecipients(recipients) {
    store.dispatch('recipientsListRepresentation/addRecipientsRecipientRepresentations', recipients);
  }

  static removeRecipient(recipientId) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      recipientId,
    );

    recipients.splice(indexRecipient, 1);
  }

  static addRecipient(recipient) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    recipients.push(recipient);
  }

  static updateRecipient(recipient) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      recipient.id,
    );
    const recipientToUpdate = recipients[indexRecipient];
    Reflect.set(recipient, '_replacedBy', recipientToUpdate.replacedBy);
    Reflect.set(recipient, '_isReplacement', recipientToUpdate.isReplacement);
    Reflect.set(recipient, '_processingActivities', recipientToUpdate.processingActivities);
    recipients.splice(indexRecipient, 1);
    recipients.push(recipient);
  }

  static updateReplacedBy(recipientId, replacementRecipientId) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      recipientId,
    );

    Reflect.set(
      recipients[indexRecipient],
      '_replacedBy',
      replacementRecipientId,
    );
  }

  static updateIsReplacement(replacementRecipientId) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      replacementRecipientId,
    );

    Reflect.set(
      recipients[indexRecipient],
      '_isReplacement',
      true,
    );
  }

  static findRecipientIndexById(recipients, recipientId) {
    return recipients.findIndex((recipient) => recipientId === recipient.id);
  }

  static getTotalDocumentsByRecipientId(recipientId) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      recipientId,
    );
    return recipients[indexRecipient].totalDocuments;
  }

  static updateTotalDocumentsRecipient(recipientId, totalDocuments) {
    const recipients = RecipientListRepresentationCache.getRecipients();
    const indexRecipient = RecipientListRepresentationCache.findRecipientIndexById(
      recipients,
      recipientId,
    );
    recipients[indexRecipient].setDocuments(totalDocuments);
  }
}
