import Task from '@/domain/task/Task';
import TaskId from '@/domain/task/valueObjects/TaskId';
import Title from '@/domain/task/valueObjects/Title';
import ExpirationDate from '@/domain/task/valueObjects/ExpirationDate';
import TaskType from '@/domain/task/valueObjects/TaskType';
import RequestPetition from '@/domain/task/valueObjects/RequestPetition';
import TaskStatus from '@/domain/task/valueObjects/TaskStatus';
import CreationDate from '@/domain/task/valueObjects/CreationDate';
import CompletionDate from '@/domain/task/valueObjects/CompletionDate';
import DepartmentId from '@/domain/department/DepartmentId';

export default class TaskFactory {
  static create({
    taskId,
    title,
    department,
    expirationDate,
    taskType,
    critical,
    requestPetition,
    status,
    creationDate,
    completionDate,
    adminId,
    clientId,
    createdBy,
  }) {
    const taskIdValueObject = new TaskId(taskId);
    const titleValueObject = new Title(title);
    const departmentValueObject = DepartmentId.fromString(department);
    const expirationDateValueObject = new ExpirationDate(expirationDate);
    const taskTypeValueObject = new TaskType(taskType);
    const requestPetitionValueObject = new RequestPetition(requestPetition);
    const statusValueObject = new TaskStatus(status);
    const creationDateValueObject = new CreationDate(creationDate);
    const completionDateValueObject = new CompletionDate(completionDate);

    return new Task(
      taskIdValueObject,
      titleValueObject,
      departmentValueObject,
      expirationDateValueObject,
      taskTypeValueObject,
      critical,
      requestPetitionValueObject,
      statusValueObject,
      creationDateValueObject,
      completionDateValueObject,
      adminId,
      clientId,
      createdBy,
    );
  }
}
