import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ValidateClientUserCommand
  from '@/application/clientUser/validateClientUser/ValidateClientUserCommand';
import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class ValidateClientUserCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public async invoke(command: ValidateClientUserCommand): Promise<any> {
    try {
      await this.clientUserRepository.validate(
        StringValueObject.fromString(command.token),
      );

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
