import { postManagement } from '@/shared/restActions';
import store from '@/store';

async function saveResponse(task) {
  try {
    await postManagement(`/api/v2/tasks/${task.taskId.id}/responses`, {
      response_id: task.taskResponseId.id,
      client_user_id: task.clientUserId.id.toString(),
      completion_date: task.completionDate.completionDateResponse,
      reply: task.taskResponseReply.responseReply,
    });

    const listTasks = store.getters['task/tasks'];
    const indexTaskAnswered = listTasks.findIndex((storeTask) => storeTask.taskId.isEquals(
      task.taskId.id,
    ));
    listTasks.splice(indexTaskAnswered, 1);
  } catch (e) {
    console.error(e);
    throw new Error('Error save response');
  }
}

export default {
  saveResponse,
};
