import DataBreachHistoricWriteModelApi
  from '@/infrastructure/dataBreach/historic/DataBreachHistoricWriteModelApi';

export default class ResetDataBreaches {
  private readonly dataBreachHistoricWriteModel: DataBreachHistoricWriteModelApi;

  constructor(dataBreachRepository: DataBreachHistoricWriteModelApi) {
    this.dataBreachHistoricWriteModel = dataBreachRepository;
  }

  async invoke(): Promise<void> {
    await this.dataBreachHistoricWriteModel.reset();
  }
}
