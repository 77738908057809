import EmployeeRepository from '@/domain/employee/EmployeeRepository';

export default class ResetEmployees {
  private readonly employeeRepository: EmployeeRepository;

  constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  public async invoke(): Promise<void> {
    await this.employeeRepository.reset();
  }
}
