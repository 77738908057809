import EmployeeId from '@/domain/employee/EmployeeId';
import ClientId from '@/domain/client/ClientId';
import DocumentDataEmployeeRepresentation
  from '@/application/documentData/employee/fetchDocumentsDataEmployee/DocumentDataEmployeeRepresentation';

export default (
  documentDataEmployeeRepository,
  translator,
) => async (employeeId, clientId) => {
  try {
    const documentsDataEmployee = await documentDataEmployeeRepository.ofEmployee(
      EmployeeId.fromString(employeeId),
      ClientId.fromInt(clientId),
    );

    return {
      success: true,
      documentsData: documentsDataEmployee.map(
        (document) => document.representedAs(new DocumentDataEmployeeRepresentation(translator)),
      ),
    };
  } catch (e) {
    return { success: false };
  }
};
