export default class DocumentData {
  _id;

  _clientId;

  _name;

  _lastModification;

  constructor(id, clientId, name, lastModification) {
    this._id = id;
    this._clientId = clientId;
    this._name = name;
    this._lastModification = lastModification;
  }

  getId() {
    return this._id;
  }
}
