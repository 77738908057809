export default class FetchProcessingActivityForm {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke() {
    return this._processingActivityCache.getProcessingActivity();
  }
}
