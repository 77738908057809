<template>
  <v-alert
    text
    prominent
    border="left"
    :color="getColorAlert()"
    class="task-alert"
    :class="{'task-alert--selected': task.show}"
  >
    <question-task
      v-if="task.taskType === 'question'"
      :task.sync="task"
      @save="sendResponse"
    />
    <verification-task
      v-if="task.taskType === 'verification'"
      :task.sync="task"
      @save="sendResponse"
    />
    <documentation-request-task
      v-if="task.taskType === 'documentation_request'"
      :task.sync="task"
      @save="sendResponseDocumentationRequest"
    />
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

import taskResponseUsecases from '@/application/task/taskResponse/usecases';
import documentationRequestTaskResponseUsecases
  from '@/application/task/taskResponse/usecases/documentationRequest';
import QuestionTask from './tasksTypesAlerts/QuestionTask.vue';
import VerificationTask from './tasksTypesAlerts/VerificationTask.vue';
import DocumentationRequestTask from './tasksTypesAlerts/DocumentationRequestTask.vue';

export default {
  name: 'task-alert-response',
  components: {
    QuestionTask,
    VerificationTask,
    DocumentationRequestTask,
  },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('user', {
      userId: 'userId',
    }),
  },
  methods: {
    async sendResponse(task, reply) {
      this.$showLoading();
      const response = await taskResponseUsecases.saveResponse(task, reply, this.userId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('tasks.send.error');
        return;
      }

      this.$showSuccess('tasks.send.success');
      this.$emit('taskResponded');
    },
    async sendResponseDocumentationRequest(task, files) {
      this.$showLoading();
      const response = await documentationRequestTaskResponseUsecases
        .saveDocumentationRequestResponse(task, files, this.userId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('tasks.send.error');
        return;
      }

      this.$showSuccess('tasks.send.success');
      this.$emit('taskResponded');
    },
    getColorAlert() {
      return (this.task.show) ? 'primary' : 'grey';
    },
  },
};
</script>
