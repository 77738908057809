<template>
  <div>
    <privacy-policy-de
      v-if="$i18n.locale === deutsch_code"
    />
    <privacy-policy
      v-else
    />
  </div>
</template>

<script>
import PrivacyPolicy from '@/components/modules/legal/PrivacyPolicy.vue';
import PrivacyPolicyDe from '@/components/modules/legal/PrivacyPolicyDe.vue';

export default {
  name: 'privacy-policy-container',
  components: {
    PrivacyPolicy,
    PrivacyPolicyDe,
  },
  data() {
    return {
      deutsch_code: 'de',
    };
  },
};
</script>
