const addDepartment = ({ commit }, department) => {
  commit('ADD_DEPARTMENT', department);
};

const resetDepartment = ({ commit }) => {
  commit('RESET_DEPARTMENT');
};

const addListDepartment = ({ commit }, listDepartment) => {
  commit('ADD_LIST_DEPARTMENT', listDepartment);
};

const resetListDepartment = ({ commit }) => {
  commit('RESET_LIST_DEPARTMENT');
};

export default {
  addDepartment,
  resetDepartment,
  addListDepartment,
  resetListDepartment,
};
