import HeadquarterSecurityMeasures
  from '@/domain/headquarter/headquarterSecurityMeasures/HeadquarterSecurityMeasures';
import HeadquarterSecurityMeasuresRepresentation
  from '@/application/headquarter/fetchSecurityMeasures/HeadquarterSecurityMeasuresRepresentation';

export default (
  translator,
) => () => {
  const securityMeasures = HeadquarterSecurityMeasures.all();
  const securityMeasuresRepresentation = securityMeasures.map((securityMeasure) => securityMeasure
    .representedAs(
      new HeadquarterSecurityMeasuresRepresentation(translator),
    ));
  return securityMeasuresRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
