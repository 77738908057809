import HeadquarterSecurityMeasuresValidationException from './HeadquarterSecurityMeasuresValidationException';

export default class HeadquarterSecurityMeasures {
  _key;

  static VALID_SECURITY_MEASURES = [
    'storageBackup',
    'sanitaryFacilities',
    'emergencyPlan',
    'alarmSystem',
    'accessControl',
    'accessBarriers',
    'chipCards',
    'lockingSystem',
    'videoSurveillance',
    'keyProvision',
    'reception',
    'visitorBook',
    'badges',
    'visitorsAccompanied',
    'securityPersonnel',
    'cleaningService',
    'locks',
    'codeLocks',
    'buildingShafts',
    'knob',
    'lightBarriers',
    'fireDetection',
    'fireExtinguisher',
    'roomMonitoring',
    'roomAirconditioned',
    'powerSupply',
  ];

  constructor(key) {
    this.isValid(key);
    this._key = key;
  }

  isValid(key) {
    if (!key) {
      throw new HeadquarterSecurityMeasuresValidationException('Security measures should not be empty');
    }

    if (!HeadquarterSecurityMeasures.VALID_SECURITY_MEASURES.includes(key)) {
      throw new HeadquarterSecurityMeasuresValidationException('Security measures does not exist');
    }
  }

  toString() {
    return this._key;
  }

  static all() {
    return this.VALID_SECURITY_MEASURES.map((securityMeasure) => new this(securityMeasure));
  }

  static fromKey(key) {
    return new this(key);
  }

  representedAs(securityMeasuresRepresentation) {
    securityMeasuresRepresentation.setKey(this._key);
    return securityMeasuresRepresentation;
  }
}
