import AffectedDataInformation from '@/domain/dataBreach/AffectedDataInformation';
import DataBreach from '@/domain/dataBreach/DataBreach';
import DataBreachBuilder from '@/domain/dataBreach/DataBreachBuilder';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachObject from '@/application/dataBreach/DataBreachObject';
import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import DpoAnswers from '@/domain/dataBreach/DpoAnswers';
import PreventMeasures from '@/domain/dataBreach/PreventMeasures';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import WhatHappens from '@/domain/dataBreach/WhatHappens';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';

export default class EditDataBreach {
  private readonly dataBreachRepository: DataBreachRepository;

  constructor(dataBreachRepository: DataBreachRepository) {
    this.dataBreachRepository = dataBreachRepository;
  }

  public async invoke(dataBreach: DataBreachObject): Promise<object> {
    try {
      const dataBreachBuilder: DataBreachBuilder = new DataBreachBuilder();

      const attachedDocuments: AttachedDocumentStruct[] = this.addDocumentAttachedId(dataBreach);
      dataBreachBuilder.withId(dataBreach.id!)
        .withCreatedDate(
          DataBreachCreatedDate.fromStringDateTime(dataBreach.createdDate!).toString(),
        )
        .withStatus(dataBreach.status)
        .withCreatedBy(dataBreach.createdBy)
        .withWhatHappens(this.buildWhatHappens(dataBreach))
        .withAffectedDataInformation(this.buildAffectedDataInformation(dataBreach))
        .withPreventMeasures(this.buildPreventMeasure(dataBreach))
        .withAttachedDocuments(attachedDocuments)
        .withAttachedDocumentsToDelete(dataBreach.attachedDocumentsToDelete)
        .withDpoAnswer(this.buildDpoAnswers(dataBreach));

      const dataBreachEntity: DataBreach = dataBreachBuilder.create();

      await this.dataBreachRepository.update(dataBreachEntity);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }

  private buildWhatHappens(dataBreach: DataBreachObject): WhatHappens {
    return {
      occurredOn: `${dataBreach.occurredOnDate} ${dataBreach.occurredOnTime}`,
      discoveredOn: `${dataBreach.discoveredOnDate} ${dataBreach.discoveredOnTime}`,
      description: dataBreach.description,
      howDiscovered: dataBreach.howDiscovered,
      wasIntentional: dataBreach.wasIntentional,
    };
  }

  private buildAffectedDataInformation(dataBreach: DataBreachObject): AffectedDataInformation {
    return {
      compromisedData: dataBreach.compromisedData,
      affectedProfiles: dataBreach.affectedProfiles,
      consequences: dataBreach.consequences,
      affectedCountries: dataBreach.affectedCountries,
      numberAffected: Number(dataBreach.numberAffected),
    };
  }

  private buildPreventMeasure(dataBreach: DataBreachObject): PreventMeasures {
    return {
      securityMeasuresImplemented: dataBreach.securityMeasuresImplemented,
      futureSecurityMeasures: dataBreach.futureSecurityMeasures,
      securityMeasuresProblems: dataBreach.securityMeasuresProblems,
      informedAffectedPeople: dataBreach.informedAffectedPeople,
      informedAuthorities: dataBreach.informedAuthorities,
    };
  }

  private addDocumentAttachedId(dataBreach: DataBreachObject): AttachedDocumentStruct[] {
    const attachedDocuments: AttachedDocumentStruct[] = [];

    dataBreach.attachedDocuments.forEach((document: any) => {
      Object.assign(document, { id: UuidValueObject.generate().toString() });
      attachedDocuments.push(document);
    });

    return attachedDocuments;
  }

  private buildDpoAnswers(dataBreach: DataBreachObject): DpoAnswers {
    return {
      evaluation: dataBreach.evaluation,
      agencyNotification: dataBreach.agencyNotification,
      reportLanguage: dataBreach.reportLanguage,
    };
  }
}
