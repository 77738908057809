<template>
  <div class="h-100 w-100">
    <upload-users-file
      :usersFileUploaded="usersFileUploaded"
    />
    <v-col cols="12">
      <h4>
        <span>
         {{$t('clientUsers.massupload.step3')}}
        </span>
      </h4>
      <p>
        {{$t('clientUsers.massupload.step3_detail')}}
      </p>
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        rounded
        color="primary"
        class="ml-4 mt-4"
        :disabled="!hasUploadedFiles"
        @click="createMassiveUploadUsers()"
      >
        {{$t('clientUsers.massupload.save')}}
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import UploadUsersFile from '@/components/modules/clientUsers/importUsers/UploadUsersFile.vue';

const IMPORT_TYPE = 'restrictedEmployees';

export default {
  name: 'form-import-users',
  inject: {
    user: 'user',
    createImportFile: 'createImportFile',
  },
  components: {
    UploadUsersFile,
  },
  computed: {
    hasUploadedFiles() {
      return this.usersFileUploaded.length > 0;
    },
  },
  data() {
    return {
      usersFileUploaded: [],
    };
  },
  methods: {
    async createMassiveUploadUsers() {
      this.$showLoading();
      const response = await this.createImportFile.invoke(
        this.user.clientId, this.usersFileUploaded[0], IMPORT_TYPE,
      );
      this.$track({ action: 'Massupload -- finish', result: response.success });
      this.$hideLoading();
      if (!response.success) {
        this.$showError('clientUsers.massupload.error.file_upload');
        return;
      }
      this.$eventHub.$emit('clientUserFileWasUploaded');
      this.$showSuccess([
        'clientUsers.massupload.success.imported_users',
        'clientUsers.massupload.success.imported_users_info',
        'clientUsers.massupload.success.imported_users_subcopy',
      ]);
    },
  },
};
</script>
