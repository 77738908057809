import GetDocumentUrlApi from '@/infrastructure/document/GetDocumentUrlApi';
import PreviewFile from '@/infrastructure/document/PreviewFile';
import DownloadFile from '@/infrastructure/document/DownloadFile';
import previewDocumentBuilder from './previewDocument/previewDocument';
import downloadDocumentsOfEmployeeBuilder
  from './downloadDocumentsOfEmployee/downloadDocumentsOfEmployee';
import downloadDocumentsOfRecipientBuilder
  from './downloadDocumentsOfRecipient/downloadDocumentsOfRecipient';
import DownloadDocument from './downloadDocument/DownloadDocument';

export default {
  downloadDocument: new DownloadDocument(new GetDocumentUrlApi(), new DownloadFile()),
  previewDocument: previewDocumentBuilder(new GetDocumentUrlApi(), new PreviewFile()),
  downloadDocumentsOfEmployee: downloadDocumentsOfEmployeeBuilder(
    new GetDocumentUrlApi(), new DownloadFile(),
  ),
  downloadDocumentsOfRecipient: downloadDocumentsOfRecipientBuilder(
    new GetDocumentUrlApi(), new DownloadFile(),
  ),
};

export const downloadDocument = new DownloadDocument(
  new GetDocumentUrlApi(), new DownloadFile(),
);

export const previewDocument = previewDocumentBuilder(
  new GetDocumentUrlApi(), new PreviewFile(),
);
