import ClientUserWasCreatedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/ClientUserWasCreatedEventListenerBuilder';
import ClientUserWasDeletedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/ClientUserWasDeletedEventListenerBuilder';
import EmployeeWasUpdatedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/EmployeeWasUpdatedEventListenerBuilder';
import EmployeeWasDeletedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/EmployeeWasDeletedEventListenerBuilder';
import EmployeeWasCreatedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/EmployeeWasCreatedEventListenerBuilder';
import DataBreachCollaboratorWasAddedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/DataBreachCollaboratorWasAddedEventListenerBuilder';
import ClientSelectedWasChangedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/ClientSelectedWasChangedEventListenerBuilder';
import ClientUserWasUpdatedEventListerBuilder
  from '@/infrastructure/dependecyContainer/builder/ClientUserWasUpdatedEventListerBuilder';
import DepartmentWasCreatedEventListenerBuilder
  from '@/infrastructure/dependecyContainer/builder/DepartmentWasCreatedEventListenerBuilder';

export default {
  ClientUserWasCreated: [
    () => ClientUserWasCreatedEventListenerBuilder.build('Employee'),
    () => ClientUserWasCreatedEventListenerBuilder.build('TrainingEmployee'),
    () => ClientUserWasCreatedEventListenerBuilder.build('AddClientUser'),
  ],
  ClientUserWasUpdated: [
    () => ClientUserWasUpdatedEventListerBuilder.build(),
  ],
  ClientUserWasDeleted: [
    () => ClientUserWasDeletedEventListenerBuilder.build('TrainingEmployee'),
    () => ClientUserWasDeletedEventListenerBuilder.build('DeleteClientUser'),
  ],
  EmployeeWasCreated: [() => EmployeeWasCreatedEventListenerBuilder.build()],
  EmployeeWasDeleted: [() => EmployeeWasDeletedEventListenerBuilder.build()],
  EmployeeWasUpdated: [() => EmployeeWasUpdatedEventListenerBuilder.build()],
  DataBreachCollaboratorWasAdded: [
    () => DataBreachCollaboratorWasAddedEventListenerBuilder.build(),
  ],
  ClientSelectedWasChanged: [
    () => ClientSelectedWasChangedEventListenerBuilder.build('DataBreach'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('DataBreachHistoric'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('DataBreachPending'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('TrainingEmployee'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('Tom'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('Department'),
    () => ClientSelectedWasChangedEventListenerBuilder.build('ListDepartment'),
  ],
  DepartmentWasCreated: [
    () => DepartmentWasCreatedEventListenerBuilder.build(),
  ],
};
