<template>
  <div class="cookies-page">
    <h2>
      {{ $t('cookies.title') }}
    </h2>
    <p>
    {{ $t('cookies.p_first_first') }}
    <br>
    {{ $t('cookies.p_first_second') }}
    <br>
    {{ $t('cookies.p_first_third') }}
    </p>
    <default-table
      :items.sync="cookies"
      :headers="headers"
    />
    <p v-if="$i18n.locale !== english_code">
      {{ $t('cookies.p_second') }}
    </p>
    <p>
      <strong>
      {{ $t('cookies.p_third_bold') }}
      </strong>
      <br>
      {{ $t('cookies.p_third_normal') }}
      <ul>
        <li>
          <strong>
          {{ $t('cookies.p_third_list_first_bold') }}
          </strong>
          <br>
          <a v-vind:href="$t('cookies.p_third_list_first')" target="_blank">
          {{ $t('cookies.p_third_list_first') }}
          </a>
        </li>
        <li>
          <strong>
          {{ $t('cookies.p_third_list_second_bold') }}
          </strong>
          <br>
          <a v-vind:href="$t('cookies.p_third_list_second')" target="_blank">
          {{ $t('cookies.p_third_list_second') }}
          </a>
        </li>
        <li v-if="$i18n.locale !== deutsch_code">
          <strong>
          {{ $t('cookies.p_third_list_third_bold') }}
          </strong>
          <br>
          <a v-vind:href="$t('cookies.p_third_list_third')" target="_blank">
          {{ $t('cookies.p_third_list_third') }}
          </a>
        </li>
        <li>
          <strong>
          {{ $t('cookies.p_third_list_fourth_bold') }}
          </strong>
          <br>
          <a v-vind:href="$t('cookies.p_third_list_fourth')" target="_blank">
          {{ $t('cookies.p_third_list_fourth') }}
          </a>
        </li>
      </ul>
      {{ $t('cookies.p_fourth') }}
    </p>
  </div>
</template>

<script>
import DefaultTable from '../../base/tables/DefaultTable.vue';

const TOTAL_COOKIES = 9;
export default {
  name: 'cookies',
  components: {
    DefaultTable,
  },
  computed: {
    cookies() {
      const cookiesList = [];
      for (let i = 0; i < TOTAL_COOKIES; i += 1) {
        cookiesList.push({
          cookie: this.$t(`cookies.table.row_${i + 1}.cookie`),
          purpose: this.$t(`cookies.table.row_${i + 1}.purpose`),
          manage: this.$t(`cookies.table.row_${i + 1}.manage`),
        });
      }
      return cookiesList;
    },
    headers() {
      return [
        {
          text: this.$t('cookies.table.headers.cookie'),
          align: 'start',
          sortable: false,
          value: 'cookie',
          width: '20%',
        },
        {
          text: this.$t('cookies.table.headers.purpose'),
          align: 'start',
          sortable: false,
          value: 'purpose',
          width: '60%',
        },
        {
          text: this.$t('cookies.table.headers.manage'),
          align: 'end',
          sortable: false,
          value: 'manage',
          width: '20%',
        },
      ];
    },
  },
  data() {
    return {
      english_code: 'en',
      deutsch_code: 'de',
    };
  },
};
</script>
