import RecipientCategory from '@/domain/recipient/recipientCategory/RecipientCategory';
import RecipientCategoryRepresentation from './RecipientCategoryRepresentation';

export default (
  translator,
) => () => {
  const otherCategory = RecipientCategory.other();
  const recipientCategories = RecipientCategory.all();
  const categoriesWithoutOther = [];
  recipientCategories.forEach((category) => {
    if (category.isEquals(otherCategory)) {
      return;
    }
    categoriesWithoutOther.push(category.representedAs(
      new RecipientCategoryRepresentation(translator),
    ));
  });
  categoriesWithoutOther.sort((a, b) => ((a.name > b.name) ? 1 : -1));

  return [...categoriesWithoutOther,
    otherCategory.representedAs(new RecipientCategoryRepresentation(translator)),
  ];
};
