<template>
  <div>
    <v-btn
      outlined
      rounded
      color="primary"
      @click="showModal = true"
    >
      {{ $t('employee.table.documents', {'num': employee.totalDocumentsData}) }}
    </v-btn>
    <v-dialog
      v-if="showModal"
      v-model="showModal"
      persistent
      max-width="60%"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h2>
            {{ $t('employee.document_data.title', {'employeeName': employee.name}) }}
          </h2>
          <v-spacer />
          <v-btn
            icon
            @click="hideModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" offset-md="6" class="text-right">
              <v-btn
                color="primary"
                rounded
                @click="downloadAllDocumentData"
              >
                {{ $t('employee.document_data.download.button') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <list-document-data-employee
                :employee="employee"
                @downloadDocumentDataEmployee="showBannerFileTypeOneDocument"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-show="!showBannerFooterDownload"
          class="text-right"
        >
          <v-spacer/>
          <v-btn
            rounded
            color="primary"
            @click="hideModal"
          >
            {{ $t('employee.document_data.close') }}
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <banner-footer-download
            v-show="showBannerFooterDownload"
            :methodDownloadUseCase="methodDownload"
            typeDocument="contract"
            :id="documentDataEmployee.id"
            :show.sync="showBannerFooterDownload"
            :fileName="fileName"
          />
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListDocumentDataEmployee
  from '@/components/modules/register/employees/documentData/ListDocumentDataEmployee.vue';
import BannerFooterDownload from '@/components/base/section/BannerFooterDownload.vue';

export default {
  name: 'document-data-employee-modal',
  inject: {
    downloadDocumentsOfEmployee: 'downloadDocumentsOfEmployee',
  },
  components: {
    BannerFooterDownload,
    ListDocumentDataEmployee,
  },
  props: {
    employee: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showBannerFooterDownload: false,
      methodDownload: () => {},
      documentDataEmployee: {},
      fileName: '',
    };
  },
  methods: {
    hideModal() {
      this.showBannerFooterDownload = false;
      this.showModal = false;
    },
    setDataDocument(document) {
      this.methodDownload = null;
      this.documentDataEmployee = document;
      this.fileName = document.name;
    },
    async showBannerFileTypeOneDocument(document) {
      await this.setDataDocument(document);
      this.showBannerFooterDownload = true;
    },
    setMethodDownloadAll() {
      this.fileName = this.$t('employee.document_data.download.button');
      this.methodDownload = async (fileTypes) => {
        const response = await this.downloadDocumentsOfEmployee(this.employee.id, fileTypes.join('-'));

        if (!response.success) {
          this.$showError('employee.document_data.download.error');
          return;
        }

        this.showBannerFooterDownload = false;
      };
    },
    async downloadAllDocumentData() {
      this.showBannerFooterDownload = false;
      await this.setMethodDownloadAll();
      this.showBannerFooterDownload = true;
    },
  },
  watch: {
    showModal() {
      this.showBannerFooterDownload = false;
    },
  },
};
</script>
