<template>
  <delete-button-alert
    actionTracker="Delete User --start"
    @delete="deleteClientUserIsNeeded"
  >
    <template v-slot:alert-title>
      {{ $t('clientUsers.delete.title') }}
    </template>
    <template v-slot:text-alert>
      {{ $t('clientUsers.delete.alert')}}
    </template>
    <template v-slot:body-text>
      {{ $t('clientUsers.delete.explain', { name: `${clientUserToDelete.fullName}`} )}}<br />
      {{ $t('clientUsers.delete.confirm') }}
    </template>
  </delete-button-alert>
</template>

<script>
import DeleteButtonAlert from '@/components/base/tables/DeleteButtonAlert.vue';
import DeleteClientUserCommand
  from '@/application/clientUser/deleteClientUser/DeleteClientUserCommand';

export default {
  name: 'delete-client-user',
  inject: {
    deleteClientUser: 'deleteClientUser',
  },
  props: {
    clientUser: {
      type: Object,
      default: null,
    },
  },
  components: {
    DeleteButtonAlert,
  },
  data() {
    return {
      clientUserToDelete: this.clientUser,
    };
  },
  methods: {
    async deleteClientUserIsNeeded() {
      this.$showLoading();
      this.showAlertDeletion = false;

      const response = await this.deleteClientUser.invoke(
        new DeleteClientUserCommand(this.clientUserToDelete.id),
      );

      this.$hideLoading();

      if (!response.success) {
        this.$showError('clientUsers.error.delete');
        this.$track({ action: 'Delete user --error' });
        return;
      }

      this.$track({ action: 'Delete user --success' });
      this.$showSuccess('clientUsers.success.delete');
      this.$eventHub.$emit('userWasDeleted');
    },
  },
};
</script>
