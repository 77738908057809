<template>
  <general-table
    :headers="headers"
    :list="departments"
  />
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import ResetDepartmentCommand
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommand';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';

export default {
  name: 'list-departments',
  inject: {
    user: 'user',
    fetchListDepartment: 'fetchListDepartment',
    resetListDepartment: 'resetListDepartment',
  },
  components: { GeneralTable },
  computed: {
    headers() {
      return [
        {
          text: this.$t('departments.table.department'),
          align: 'start',
          value: 'name',
          width: '30%',
        },
      ];
    },
  },
  data() {
    return {
      departments: [],
    };
  },
  methods: {
    async loadDepartmentsList() {
      this.$showLoading();
      const response = await this.fetchListDepartment
        .invoke(new ListDepartmentQuery(this.user.clientId));
      this.$hideLoading();

      if (!response.success) {
        this.$showError('departments.error.load_departments_list');
        return;
      }
      this.departments = response.departments;
    },
    resetAndLoadDepartments() {
      this.resetListDepartment.invoke(new ResetDepartmentCommand());
      this.loadDepartmentsList();
    },
  },
  beforeMount() {
    this.loadDepartmentsList();
    this.$eventHub.$on('userLanguageWasChanged', this.resetAndLoadDepartments);
  },
  beforeDestroy() {
    this.$eventHub.$off('userLanguageWasChanged', this.resetAndLoadDepartments);
  },
};
</script>
