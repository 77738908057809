import RecipientReadModelApi from '@/infrastructure/recipient/RecipientReadModelApi';
import Translator from '@/infrastructure/Translator';
import normalizationService from '@/services/shared/normalization';
import processingDatesService from '@/services/shared/processingDates';
import RecipientRepositoryApi from '@/infrastructure/recipient/RecipientRepositoryApi';
import RecipientListRepresentationCache from '@/infrastructure/recipient/RecipientListRepresentationCache';
import RecipientWasCreatedEventListener from '@/application/recipient/recipientWasCreated/RecipientWasCreatedEventListener';
import RecipientWasDeletedEventListener
  from '@/application/recipient/recipientWasDeleted/RecipientWasDeletedEventListener';
import RecipientWasUpdatedEventListener
  from '@/application/recipient/recipientWasUpdated/RecipientWasUpdatedEventListener';
import RecipientCache from '@/infrastructure/recipient/RecipientCache';
import createRecipientBuilder from './createRecipient/createRecipient';
import fetchRecipientsBuilder from './fetchRecipients/fetchRecipients';
import resetRecipientsBuilder from './resetRecipients/resetRecipients';
import fetchRecipientDpaStatusBuilder from './fetchRecipientDpaStatus/fetchRecipientDpaStatus';
import fetchRecipientCategoriesBuilder from './fetchRecipientCategories/fetchRecipientCategories';
import fetchRecipientRolesBuilder from './fetchRecipientRoles/fetchRecipientRoles';
import fetchRecipientWarrantiesBuilder
  from './fetchRecipientWarranties/fetchRecipientWarranties';
import updateRecipientBuilder from './updateRecipient/updateRecipient';
import deleteRecipientBuilder from './deleteRecipient/deleteRecipient';
import fetchRecipientBuilder from './fetchRecipient/fetchRecipient';
import RequestRecipientReplace
  from './requestRecipientReplace/RequestRecipientReplace';
import RecipientReplaceWasRequestedEventListener
  from './recipientReplaceWasRequested/RecipientReplaceWasRequestedEventListener';
import FetchRecipientToReplacement
  from './fetchRecipientToReplacement/FetchRecipientToReplacement';

export default {
  fetchRecipients: fetchRecipientsBuilder(new RecipientReadModelApi(
    new Translator(), normalizationService, processingDatesService,
  )),
  resetRecipients: resetRecipientsBuilder(
    new RecipientListRepresentationCache(),
    new RecipientCache(),
  ),
  createRecipient: createRecipientBuilder(
    new RecipientRepositoryApi(),
    new RecipientWasCreatedEventListener(
      new Translator(),
      normalizationService,
      processingDatesService,
      RecipientListRepresentationCache,
    ),
  ),
  fetchRecipientDpaStatus: fetchRecipientDpaStatusBuilder(new Translator()),
  fetchRecipientCategories: fetchRecipientCategoriesBuilder(new Translator()),
  fetchRecipientRoles: fetchRecipientRolesBuilder(new Translator()),
  fetchRecipientWarranties: fetchRecipientWarrantiesBuilder(new Translator()),
  deleteRecipient: deleteRecipientBuilder(
    new RecipientRepositoryApi(),
    new RecipientWasDeletedEventListener(RecipientListRepresentationCache),
  ),
  updateRecipient: updateRecipientBuilder(
    new RecipientRepositoryApi(),
    new RecipientWasUpdatedEventListener(
      new Translator(),
      normalizationService,
      processingDatesService,
      RecipientListRepresentationCache,
    ),
  ),
  fetchRecipient: fetchRecipientBuilder(new RecipientRepositoryApi(), new Translator()),
};

export const fetchRecipients = fetchRecipientsBuilder(new RecipientReadModelApi(
  new Translator(), normalizationService, processingDatesService,
));

export const fetchRecipientCategories = fetchRecipientCategoriesBuilder(new Translator());

export const requestRecipientReplace = new RequestRecipientReplace(
  new RecipientRepositoryApi(),
  new RecipientReplaceWasRequestedEventListener(RecipientListRepresentationCache),
);

export const fetchRecipientToReplacement = new FetchRecipientToReplacement(
  new RecipientReadModelApi(new Translator(), normalizationService, processingDatesService),
);
