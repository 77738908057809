<template>
  <div class="filters">
    <p>
      {{ $t('filter.title') }}
      <v-tooltip
        v-if="informationTooltip"
        right
        max-width="300"
        :open-on-hover="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            color="primary"
            retain-focus-on-click
            @click="on.click"
          >
            <v-icon small>
              info
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ informationTooltip }}
        </span>
      </v-tooltip>
    </p>
    <v-row align="center" class="filters__row">
      <v-col
        v-for="(item, key) in items"
        :key="key"
        cols="12" lg="2" md="3" sm="4"
        class="filters__col"
      >
        <v-checkbox
          v-model="filtersSelect"
          :value="item.id"
          :label="item.name"
          color="primary"
          @change="selectFilter(item.id)"
        >
          <template
            v-if="item.tooltip"
            v-slot:label>
            <v-tooltip
              bottom
              max-width="300"
            >
              <template v-slot:activator="{ on }">
                <p
                  v-on="on"
                >
                  {{ item.name }}
                  <span
                    v-if="item.amount"
                  >
                    ({{ item.amount }})
                  </span>
                </p>
              </template>
              <span>
                {{ item.tooltip }}
              </span>
            </v-tooltip>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" v-if="items.length && secondItems.length" class="filters__divider">
        <v-divider/>
      </v-col>
      <v-col
        v-for="(item, key) in secondItems"
        :key="`second${key}`"
        cols="12"
        md="4"
        class="filters__col"
      >
        <v-checkbox
          v-model="filtersSelect"
          :label="item.name"
          :value="item.id"
          color="primary"
          @change="selectFilter(item.id)"
        />
      </v-col>
    </v-row>
    <div class="text-right filters__select_clear_all">
      <v-btn
        class="pr-0"
        text
        color="primary"
        :ripple="false"
        :plain="true"
        @click="updateFiltersSelected"
      >
        {{ selectOrClearText }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'filters',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    double: {
      type: Boolean,
      default: false,
    },
    secondItems: {
      type: Array,
      default: () => [],
    },
    informationTooltip: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectOrClearText() {
      if (!this.filtersSelect.length) {
        return this.$t('filter.select_all');
      }
      return this.$t('filter.clear_all');
    },
  },
  data() {
    return {
      filtersSelect: this.value,
    };
  },
  methods: {
    selectFilter(filterId) {
      this.trackCheckbox(filterId);
      this.$emit('update:value', this.filtersSelect);
    },
    updateFiltersSelected() {
      let filters = [];
      const hasSelectedAll = !this.filtersSelect.length;
      if (hasSelectedAll) {
        filters = this.items.map((filter) => filter.id);
      }
      this.trackSelectOrClearAll(hasSelectedAll);
      this.$emit('update:value', filters);
    },
    trackCheckbox(filterId) {
      const selected = this.filtersSelect.includes(filterId);
      const action = selected ? 'mark filter' : 'unmark filter';

      this.$track({
        action: `${action} ${this.section} ${filterId}`,
      });
    },
    trackSelectOrClearAll(hasSelectedAll) {
      const action = hasSelectedAll ? 'select all' : 'clear all';
      this.$track({
        action: `${action} ${this.section}`,
      });
    },
  },
  watch: {
    value() {
      this.filtersSelect = this.value;
    },
  },
};
</script>
