import FetchTomsQueryHandler from '@/application/tom/fetchToms/FetchTomsQueryHandler';
import TomRepositoryApi from '@/infrastructure/tom/TomRepositoryApi';
import Translator from '@/infrastructure/Translator';
import FetchTomFiltersQueryHandler
  from '@/application/tom/fetchTomFilters/FetchTomFiltersQueryHandler';
import ChangeTomStatusCommandHandler
  from '@/application/tom/changeTomStatus/ChangeTomStatusCommandHandler';
import FetchTomQueryHandler from '@/application/tom/fetchTom/FetchTomQueryHandler';
import EditTomCommandHandler from '@/application/tom/editTom/EditTomCommandHandler';
import ResetToms from '@/application/tom/resetTom/ResetToms';

export default {};

export const fetchToms = new FetchTomsQueryHandler(new TomRepositoryApi(), new Translator());
export const fetchTomFilters = new FetchTomFiltersQueryHandler();
export const changeTomStatus = new ChangeTomStatusCommandHandler(new TomRepositoryApi());
export const fetchTom = new FetchTomQueryHandler(new TomRepositoryApi(), new Translator());
export const editTom = new EditTomCommandHandler(new TomRepositoryApi());
export const resetToms = new ResetToms(new TomRepositoryApi());
