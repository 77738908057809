export default (
  getDocumentUrl,
  previewFile,
) => async (documentType, documentId, language, nameDocument) => {
  try {
    const documentUrl = await getDocumentUrl.preview(documentType, documentId, language);

    if (nameDocument !== 'NotShowDeprecated') {
      previewFile.invoke(documentUrl, nameDocument);
    }

    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
