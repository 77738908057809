import fetchDocumentsListBuilder from '@/usecases/documents/legalDocuments/fetchDocumentsList';
import legalDocumentsRepository from '@/repositories/documents/legalDocuments';
import fetchTypeOfDocumentBuilder from '@/usecases/documents/legalDocuments/fetchTypeOfDocument';
import downloadDocumentBuilder from '@/usecases/documents/legalDocuments/downloadDocument';
import viewDocumentBuilder from '@/usecases/documents/legalDocuments/viewDocument';
import documentViewer from '@/services/documents/documentViewer';
import downloadFile from '@/services/documents/downloadFile';
import documentFiltersService from '@/services/documents/documentFilters';

export default {
  fetchDocumentsList: fetchDocumentsListBuilder({ legalDocumentsRepository }),
  fetchTypeOfDocument: fetchTypeOfDocumentBuilder({ documentFiltersService }),
  downloadDocument: downloadDocumentBuilder({ legalDocumentsRepository, downloadFile }),
  viewDocument: viewDocumentBuilder({ legalDocumentsRepository, documentViewer }),
};
