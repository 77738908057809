import RecipientReadModelRepresentation from '@/domain/recipient/readModel/RecipientReadModelRepresentation';

export default class RecipientListRepresentation extends RecipientReadModelRepresentation {
  _translator;

  _normalization;

  _processingDateService;

  _nameNormalized = null;

  constructor(translator, normalizationService, processingDateService) {
    super();
    this._translator = translator;
    this._normalization = normalizationService;
    this._processingDateService = processingDateService;
  }

  get status() {
    return this._status ? this._translator.translate(`recipient.status.${this._status.toString()}`) : '';
  }

  get category() {
    return this._category ? this._translator.translate(`recipient.category.${this._category.toString()}`) : '';
  }

  get role() {
    return this._role ? this._translator.translate(`recipient.role.${this._role.toString()}`) : '';
  }

  get lastModification() {
    return (this._lastModification) ? this._processingDateService.getStringDateTimeFromDate(this._lastModification) : '';
  }

  get lastModificationToOrderBy() {
    return this._lastModification;
  }

  get nameNormalized() {
    if (this._nameNormalized === null) {
      this._nameNormalized = this._normalization.sanitizeString(this._name.toString());
    }

    return this._nameNormalized;
  }

  get totalDocuments() {
    if (typeof this._documents === 'number') {
      return this._documents;
    }
    return this._documents.length;
  }

  get isStatusValidated() {
    return this._status.toString() === 'validated';
  }

  get totalDocumentData() {
    return this._documentData ? this._documentData.toInt() : 0;
  }

  get replacedBy() {
    return this._replacedBy;
  }

  get isReplacement() {
    return !!this._isReplacement;
  }

  get processingActivities() {
    return this._processingActivities;
  }
}
