<template>
  <div class="h-100 w-100">
    <logo-img
      :logoUrl="urlLogo || companyLogoUrl"
      :companyName="companyName"
      :height="heightLogo"
      :position="positionLogo"
      :classLogo="classLogo"
      titleTag="h1"
    />
    <alert
      v-if="errorFeedback.length > 0"
      :messages.sync="errorFeedback"
      type="error"
    />
    <google-login-btn
      v-if="showGoogleSSO"
      @userGoogleLogin="googleLogin"
    />
    <input-field
      :value.sync="user.email"
      :label="$t('login.email') + ' *'"
      :autofocus="true"
      @keyUpEnter="userLogin"
      :error.sync="thereIsEmailError"
    />
    <input-field-password
      :value.sync="user.password"
      :label="$t('login.password') + ' *'"
      @keyUpEnter="userLogin"
      :error.sync="thereIsPasswordError"
    />
    <v-row
      align="center"
      class="mt-7 mx-n3"
    >
      <v-col cols="6">
        <router-link
          v-if="showUserRegisterLink"
          :to="{ name: 'user-register' }"
          class="mb-5"
          v-track="'Go to User Register'"
        >
          {{ $t('register.register') }}
        </router-link>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          class="mb-5"
          color="primary"
          rounded
          @click="userLogin"
        >
          {{ $t('login.login') }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-right">
        <router-link :to="{ name: 'forgot-password' }" class="recover_password">
          {{ $t('login.recover_password') }}
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import userAppUsecases from '@/application/user/usecases';
import { fetchUserInfo, googleLogin, login } from '@/application/user';

import formValidationService from '@/services/validations/formValidation';
import decryptQueryParamService from '@/services/user/decryptQueryParam';
import LoginCentralized from '@/services/login/LoginCentralized';
import subdomainService from '@/services/admin/subdomain';

import GoogleLoginBtn from '@/components/modules/login/GoogleLoginBtn.vue';
import HistoricRoute from '@/router/HistoricRoute';
import { changeSelectedClient } from '@/infrastructure/application/client';
import ChangeClientSelectedCommand
  from '@/application/client/changeClientSelected/ChangeClientSelectedCommand';
import InputField from '../base/form/InputField.vue';
import InputFieldPassword from '../base/form/InputFieldPassword.vue';
import Alert from '../base/feedback/Alert.vue';
import LogoImg from '../base/icons/LogoImg.vue';

export default {
  name: 'LoginContainer',
  inject: {
    urlLogo: 'urlLogo',
    heightLogo: 'heightLogo',
    positionLogo: 'positionLogo',
    classLogo: 'classLogo',
    hasAccessLoginLayoutB2B: 'hasAccessLoginLayoutB2B',
  },
  components: {
    GoogleLoginBtn,
    InputField,
    InputFieldPassword,
    Alert,
    LogoImg,
  },
  computed: {
    ...mapGetters('admin', {
      companyName: 'name',
      companyLogoUrl: 'logoUrl',
      isB2b: 'isB2b',
    }),
    ...mapGetters('user', {
      userId: 'userId',
      isAdmin: 'isAdmin',
      companyId: 'companyId',
    }),
    showGoogleSSO() {
      return this.showGoogleBtn && LoginCentralized.hasLoginCentralizedUrl();
    },
    showUserRegisterLink() {
      return this.hasAccessLoginLayoutB2B;
    },
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      clientId: '',
      errorFeedback: [],
      thereIsEmailError: false,
      thereIsPasswordError: false,
      showGoogleBtn: true,
    };
  },
  methods: {
    hideGoogleSSO() {
      this.showGoogleBtn = false;
    },
    async completeLogin(response, action, method) {
      if (!response.success) {
        this.$track({
          action,
          authentication: method,
          result: response.success,
        });

        this.errorFeedback = [this.$t('login.error.access_denied')];
        this.$hideLoading();
        return;
      }

      if (LoginCentralized.hasLoginCentralizedUrl()) {
        await this.getInfoAdminBySubdomain(response.subdomain);
      }

      this.$track({
        action,
        authentication: method,
        result: response.success,
      });

      await this.getUserInfo();

      this.$hideLoading();

      if ((this.isAdmin && !this.companyId)) {
        this.$router.push({ name: 'home' });
        return;
      }

      this.$router.push(HistoricRoute.routeToGo());
    },
    async userLogin() {
      this.$showLoading();
      this.errorFeedback = this.checkForm();
      if (this.errorFeedback.length !== 0) {
        this.$hideLoading();
        return;
      }

      const response = await login.invoke(this.user.email, this.user.password);
      await this.completeLogin(response, 'Platform access', 'login');
    },
    async googleLogin(googleAccessToken) {
      this.$showLoading();
      const response = await googleLogin.invoke(googleAccessToken);
      await this.completeLogin(response, 'Platform access', 'gmail');
    },
    async getUserInfo() {
      if (this.isAdmin) {
        await this.saveClientSelectedIfClientId();
        await userAppUsecases.storeUserRoleDepartments({ type: 'supervisor', departments: [] });
        return;
      }
      await fetchUserInfo.invoke(this.userId);
    },
    async saveClientSelectedIfClientId() {
      if (this.clientId) {
        await changeSelectedClient.invoke(new ChangeClientSelectedCommand(this.clientId));
      }
    },
    checkForm() {
      this.cleanErrors();
      const textErrors = [];

      const emptyEmail = formValidationService.isEmptyField(this.user.email);
      const emptyPassword = formValidationService.isEmptyField(this.user.password);
      const validEmail = formValidationService.isValidEmail(this.user.email);

      if (emptyEmail) {
        textErrors.push(this.$t('login.error.required_field_email'));
        this.thereIsEmailError = true;
      } else if (!validEmail) {
        textErrors.push(this.$t('login.error.incorrect_email'));
        this.thereIsEmailError = true;
      }

      if (emptyPassword) {
        textErrors.push(this.$t('login.error.required_field_password'));
        this.thereIsPasswordError = true;
      }

      return textErrors;
    },
    cleanErrors() {
      this.thereIsEmailError = false;
      this.thereIsPasswordError = false;
    },
    async getInfoAdminBySubdomain(subdomain) {
      await subdomainService.getInfoAdmin(subdomain);
    },
    redirectToCentralizedLoginIfNecessary() {
      if (this.isB2b) {
        LoginCentralized.goToLoginCentralized();
      }
    },
  },
  async beforeMount() {
    this.$showLoading();
    if (this.pudk) {
      const params = decryptQueryParamService.decrypt(this.pudk);
      this.user.email = params.email;
      this.clientId = params.clientId;
    }

    if (!LoginCentralized.hasLoginCentralizedUrl()) {
      const subdomain = window.location.hostname.split('.')[0];
      await this.getInfoAdminBySubdomain(subdomain);
      this.redirectToCentralizedLoginIfNecessary();
    }

    this.$hideLoading();
    this.$eventHub.$on('googleLoadingError', this.hideGoogleSSO);
  },
  beforeDestroy() {
    this.$eventHub.$off('googleLoadingError', this.hideGoogleSSO);
  },
};
</script>
