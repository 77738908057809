import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';
import EmployeeWasCreatedEventListener
  from '@/application/training/employee/EmployeeWasCreatedEventListener/EmployeeWasCreatedEventListener';

export default class EmployeeWasCreatedEventListenerBuilder {
  static build() {
    return new EmployeeWasCreatedEventListener(
      new TrainingEmployeeRepositoryApi(),
    );
  }
}
