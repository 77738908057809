<template>
  <v-dialog
    v-model="showModal"
    persistent
    scrollable
    max-width="60%"
  >
    <v-card>
      <v-card-title>
        <h2>
          {{ $t('toms.information_modal.title') }}
        </h2>
        <v-spacer />
        <v-btn
          icon
          @click="closeModal"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text id="form-tom__card-text">
        <v-form ref="tomForm">
          <div class="form-steps__list">
            <v-row class="form-steps__list__step">
              <tom-info
                :tom="tom"
              />
              <v-col v-if="showAlert" cols="12">
                <alert-fixed
                  type="error"
                  :text="$t('toms.error.exceed_characters_field')"
                />
              </v-col>
              <tom-notes
                :notes="tom.notes"
              />
              <upload-tom-document
                :filesToUpload="tom.attachedDocuments"
                :alreadyUploadedFiles="alreadyUploadedDocuments"
                :filesDeleted="tom.attachedDocumentsToDelete"
              />
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="elevation-lighten pt-5 mt-1">
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          rounded
          class="mb-0"
          @click="closeModal"
          v-track="'Cancel Tom Form'"
        >
          {{ $t('toms.information_modal.button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="mb-0"
          @click="save"
          v-track="'Save Tom Form -- start'"
        >
          {{ $t('toms.information_modal.button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TomInfo from '@/components/modules/tom/form/TomInfo.vue';
import TomNotes from '@/components/modules/tom/form/TomNotes.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import UploadTomDocument from '@/components/modules/tom/form/UploadTomDocument.vue';

export default {
  name: 'dialog-form-tom',
  components: {
    UploadTomDocument,
    TomInfo,
    TomNotes,
    AlertFixed,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    tomToEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tom: {
        id: '',
        type: '',
        name: '',
        list: '',
        category: '',
        referenceCode: '',
        status: '',
        description: '',
        notes: [],
        attachedDocuments: [],
        attachedDocumentsToDelete: [],
      },
      alreadyUploadedDocuments: [],
      showAlert: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
    },
    fillTomWithInfo() {
      Object.keys(this.tom).forEach((key) => {
        if (key === 'attachedDocuments') {
          this.alreadyUploadedDocuments = this.tomToEdit[key];
          return;
        }

        if (key === 'attachedDocumentsToDelete') {
          return;
        }

        this.tom[key] = this.tomToEdit[key];
      });
    },
    save() {
      if (!this.isFormValid()) {
        return;
      }

      this.$emit('save', this.tom);
    },
    isFormValid() {
      this.showAlert = false;
      if (!this.$refs.tomForm.validate()) {
        const textCard = document.getElementById('form-tom__card-text');
        const tomNote = document.getElementById('tom-notes');
        this.showAlert = true;
        textCard.scroll({ top: tomNote.offsetTop - 300, behavior: 'smooth' });

        return false;
      }

      return true;
    },
  },
  mounted() {
    if (this.tomToEdit) {
      this.fillTomWithInfo();
    }
  },
};
</script>
