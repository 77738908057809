<template>
  <div class="d-inline-block">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          color="primary"
          :disabled="replacementIsActive"
          @click="showReplaceModal"
        >
          <v-icon>
            autorenew
          </v-icon>
        </v-btn>
      </template>
      <span>
        {{ $t('recipient.replace.title') }}
      </span>
    </v-tooltip>
    <v-dialog
      v-if="showModal"
      v-model="showModal"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <h2>{{ $t('recipient.replace.title') }}</h2>
          <v-spacer/>
          <v-btn
            icon
            @click="hideReplaceModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="processing-activities__col" cols="12">
              <alert-processing-activities
                :recipient-to-replace="recipientToReplace"
              />
            </v-col>
            <v-col cols="12">
              <p class="mb-5 grey--text f-14">
                {{ $t('recipient.replace.recipient_selector') }}
              </p>
              <select-buttons
                :items="recipientsToReplaceList"
                :selection.sync="recipientNew"
              />
            </v-col>
            <v-col class="pr-10" cols="12" md="6">
              <recipient-details
                :recipientId="recipientToReplace.id"
                :title="$t('recipient.replace.recipient_to_replace')"
              />
            </v-col>
            <v-col class="pl-10" cols="12" md="6">
              <recipient-details
                :recipientId="recipientNew.id"
                :title="$t('recipient.replace.recipient_replaced')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            rounded
            outlined
            color="primary"
            @click="hideReplaceModal"
          >
            {{ $t('recipient.replace.cancel_button') }}
          </v-btn>
          <v-btn
            rounded
            color="primary"
            @click="requestReplacement"
          >
            {{ $t('recipient.replace.confirm_button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import AlertProcessingActivities
  from '@/components/modules/register/recipients/replace/AlertProcessingActivities.vue';
import SelectButtons from '@/components/base/form/SelectButtons.vue';
import RecipientDetails from '@/components/modules/register/recipients/replace/RecipientDetails.vue';

export default {
  name: 'replace-recipient',
  inject: {
    user: 'user',
    fetchRecipientToReplacement: 'fetchRecipientToReplacement',
    requestRecipientReplace: 'requestRecipientReplace',
  },
  components: {
    RecipientDetails,
    SelectButtons,
    AlertProcessingActivities,
  },
  props: {
    recipientToReplace: {
      type: Object,
      default: null,
    },
  },
  computed: {
    replacementIsActive() {
      return !!this.recipientToReplace.replacedBy || this.recipientToReplace.isReplacement;
    },
  },
  data() {
    return {
      showModal: false,
      recipientsToReplaceList: [],
      recipientNew: {},
    };
  },
  methods: {
    showReplaceModal() {
      this.showModal = true;
      this.getRecipientReplacements();
    },
    hideReplaceModal() {
      this.showModal = false;
      this.recipientNew = {};
    },
    async getRecipientReplacements() {
      await this.$showLoading();
      const response = await this.fetchRecipientToReplacement.invoke(
        this.user.clientId, this.recipientToReplace.id,
      );
      await this.$hideLoading();
      this.recipientsToReplaceList = response.recipients;
    },
    async requestReplacement() {
      await this.$showLoading();
      const response = await this.requestRecipientReplace.invoke(
        this.recipientToReplace.id,
        this.recipientNew.id,
      );
      await this.$hideLoading();
      if (!response.success) {
        this.$showError('recipient.replace.error');
        return;
      }
      this.hideReplaceModal();

      this.$showSuccess('recipient.replace.success');
      this.$eventHub.$emit('recipientReplacementWasRequested');
    },
  },
};
</script>
