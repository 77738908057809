import ClientUserType from '@/domain/clientUser/ClientUserType';
import ClientUserTypesRepresentation
  from '@/application/clientUser/fetchClientUserTypes/ClientUserTypesRepresentation';
import Translator from '@/infrastructure/Translator';
import FetchClientUserTypesQuery
  from '@/application/clientUser/fetchClientUserTypes/FetchClientUserTypesQuery';

export default class FetchClientUserTypesQueryHandler {
  private readonly translator: Translator;

  constructor(translator: Translator) {
    this.translator = translator;
  }

  public invoke(query: FetchClientUserTypesQuery) {
    try {
      const clientUserTypes: ClientUserType[] = ClientUserType.all();
      const clientUserTypesRepresentation: ClientUserTypesRepresentation[] = [];
      clientUserTypes.forEach((type: ClientUserType) => {
        if (!type.isTrainingsManager()) {
          clientUserTypesRepresentation
            .push(type.representedAs(
              new ClientUserTypesRepresentation(this.translator),
            ) as ClientUserTypesRepresentation);
        }
      });
      return { success: true, clientUserTypes: clientUserTypesRepresentation };
    } catch (e) {
      return { success: false };
    }
  }
}
