import ClientRepresentation from '@/domain/client/ClientRepresentation';

export default class ClientsRepresentation extends ClientRepresentation {
  private readonly normalizationService: any;

  private nameNormalization = '';

  private businessNameNormalization = '';

  constructor(normalizationService: any) {
    super();
    this.normalizationService = normalizationService;
  }

  get id(): number {
    return this._id.toInt();
  }

  get businessName(): string {
    return this._businessName.toString();
  }

  get name(): string {
    return this._name.toString();
  }

  get countryId(): number {
    return this._countryId.toInt();
  }

  get nameNormalized(): string {
    if (this.nameNormalization === '') {
      this.nameNormalization = this.normalizationService.normalizeString(this.name);
    }

    return this.nameNormalization;
  }

  get businessNameNormalized(): string {
    if (this.businessNameNormalization === '') {
      this.businessNameNormalization = this.normalizationService.normalizeString(this.businessName);
    }

    return this.businessNameNormalization;
  }
}
