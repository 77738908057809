<template>
  <v-menu
    v-model="datepickerShow"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="valueInput"
        :label="label"
        v-bind="attrs"
        v-on="on"
        readonly
        background-color="#F3F8F7"
        filled
        :rules="rules"
        @input="$emit('update:value', valueInput)"
      >
      <template
          v-slot:append
          v-if="clearDate && !!valueInput"
        >
          <v-btn
            icon
            text
            @click="resetDate"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      :max="maxDate"
      :locale="$i18n.locale"
      color="primary"
      :elevation="0"
      class="datepicker"
      @input="selectDate"
    />
  </v-menu>
</template>
<script>
export default {
  name: 'date-picker.field',
  props: {
    value: {
      type: [Date, String],
      default: () => {
        const auxDate = new Date();
        return `${auxDate.toLocaleDateString('en-GB')}  -  ${auxDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
      },
    },
    label: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    clearDate: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      datepickerShow: false,
      valueInput: this.value,
      date: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    selectDate() {
      const auxDate = new Date(this.date);
      this.valueInput = auxDate.toLocaleDateString('en-GB');
      this.$emit('update:value', this.valueInput);
      this.datepickerShow = false;
    },
    resetDate() {
      this.$emit('update:value', null);
    },
  },
  watch: {
    value() {
      this.valueInput = this.value;
    },
  },
};
</script>
