import taskResponseRepository from '@/infrastructure/task/taskResponseRepository';
import identityRepository from '@/infrastructure/identity/identityRepository';
import changeDateToUTC from '../services/changeDateToUTC';
import saveResponseBuilder from './saveResponse';

export default {
  saveResponse: saveResponseBuilder({
    taskResponseRepository,
    changeDateToUTC,
    identityRepository,
  }),
};
