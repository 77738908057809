export default [
  {
    id: 1,
    key: 'dropbox',
  },
  {
    id: 2,
    key: 'google_drive',
  },
  {
    id: 3,
    key: 'owncloud',
  },
  {
    id: 4,
    key: 'onedrive',
  },
  {
    id: 5,
    key: 'candis',
  },
  {
    id: 6,
    key: 'aws',
  },
  {
    id: 7,
    key: 'gsuite',
  },
  {
    id: 8,
    key: 'upcloud',
  },
  {
    id: 9,
    key: 'sage',
  },
  {
    id: 10,
    key: 'facebook',
  },
  {
    id: 11,
    key: 'zendesk',
  },
  {
    id: 12,
    key: 'zendesk_sell',
  },
  {
    id: 13,
    key: 'salesforce',
  },
  {
    id: 14,
    key: 'sap',
  },
  {
    id: 15,
    key: 'suite_crm',
  },
  {
    id: 16,
    key: 'sugar_crm',
  },
  {
    id: 17,
    key: 'zoho',
  },
  {
    id: 18,
    key: 'oracle',
  },
  {
    id: 19,
    key: 'skype_for_business',
  },
  {
    id: 20,
    key: 'crazy_egg',
  },
  {
    id: 21,
    key: 'google_ads',
  },
  {
    id: 22,
    key: 'hubspot',
  },
  {
    id: 23,
    key: 'mailchimp',
  },
  {
    id: 24,
    key: 'gmail',
  },
  {
    id: 25,
    key: 'outlook',
  },
  {
    id: 26,
    key: 'hotmail',
  },
  {
    id: 27,
    key: 'netsuite_erp',
  },
  {
    id: 28,
    key: 'acumatica_cloud_erp',
  },
  {
    id: 29,
    key: 'brightpearl',
  },
  {
    id: 30,
    key: 'ecount_erp',
  },
  {
    id: 31,
    key: 'odoo',
  },
  {
    id: 32,
    key: 'plentymarkets',
  },
  {
    id: 33,
    key: 'priority_software',
  },
  {
    id: 34,
    key: 'scoro',
  },
  {
    id: 35,
    key: 'syspro',
  },
  {
    id: 36,
    key: 'sage_intacct',
  },
  {
    id: 37,
    key: 'calendly',
  },
  {
    id: 38,
    key: 'eventbrite',
  },
  {
    id: 39,
    key: 'meetup',
  },
  {
    id: 40,
    key: 'one_and_one',
  },
  {
    id: 41,
    key: 'cloudflare',
  },
  {
    id: 42,
    key: 'domainfactory',
  },
  {
    id: 43,
    key: 'etzner_online',
  },
  {
    id: 44,
    key: 'strato',
  },
  {
    id: 45,
    key: 'zoho_people',
  },
  {
    id: 46,
    key: 'sap_success_factors',
  },
  {
    id: 47,
    key: 'adp_workforce_now',
  },
  {
    id: 48,
    key: 'bamboohr',
  },
  {
    id: 49,
    key: 'breezy_hr',
  },
  {
    id: 50,
    key: 'dayforce_hcm',
  },
  {
    id: 51,
    key: 'kronos_workforce_central',
  },
  {
    id: 52,
    key: 'lever',
  },
  {
    id: 53,
    key: 'personio',
  },
  {
    id: 54,
    key: 'starhunter',
  },
  {
    id: 55,
    key: 'sage_hrms',
  },
  {
    id: 56,
    key: 'factorial',
  },
  {
    id: 57,
    key: 'one_password',
  },
  {
    id: 58,
    key: 'lastpass',
  },
  {
    id: 59,
    key: 'intercom',
  },
  {
    id: 60,
    key: 'slack',
  },
  {
    id: 61,
    key: 'whatsapp',
  },
  {
    id: 62,
    key: 'whatsapp_business',
  },
  {
    id: 63,
    key: 'telegram',
  },
  {
    id: 64,
    key: 'hangouts',
  },
  {
    id: 65,
    key: 'microsoft_office',
  },
  {
    id: 66,
    key: 'paypal_payments_pro',
  },
  {
    id: 67,
    key: 'linkedin',
  },
  {
    id: 68,
    key: 'codility',
  },
  {
    id: 69,
    key: 'microsoft_office_365',
  },
  {
    id: 70,
    key: 'trello',
  },
  {
    id: 71,
    key: 'typeform',
  },
  {
    id: 72,
    key: 'wordpress',
  },
  {
    id: 73,
    key: 'xing',
  },
  {
    id: 74,
    key: 'jira',
  },
  {
    id: 75,
    key: 'google_analytics',
  },
  {
    id: 76,
    key: 'matomo',
  },
  {
    id: 85,
    key: 'human_resources_department',
  },
];
