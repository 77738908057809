<template>
  <v-dialog v-model="showModal" persistent max-width="60%" scrollable>
    <v-card>
      <v-card-title>
        <h2>
          {{ $t('employee.create.title') }}
        </h2>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:show-modal', false)"
        >
        <v-icon>
          close
        </v-icon>
        </v-btn>
        <p class="registers-form--explanation">
          {{ $t('employee.form.explanation') }}
        </p>
      </v-card-title>
      <v-card-text id="form-employee">
        <v-form
          ref="employeeForm"
        >
          <v-row>
            <v-col cols="12">
              <alert-fixed
                v-if="showAlert"
                type="error"
                :text="$t('form.review')"
              />
            </v-col>
             <v-col cols="12" md="6">
              <input-field
                :value.sync="employee.name"
                :label="`${$t('employee.form.name')} *`"
                :rules="[validation.required, validation.min, validation.max_name]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <input-field
                :value.sync="employee.surname"
                :label="`${$t('employee.form.surname')} *`"
                :rules="[validation.required, validation.max_surname, validation.min]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col
              cols="12" md="6"
            >
              <input-field
                :value.sync="employee.email"
                :label="`${$t('employee.form.email')}`"
                :rules="[validation.email]"
                :validateOnBlur="true"
                :disabled="emailAlreadyExist"
              />
            </v-col>
            <v-col
              v-if="showNif"
              cols="12" md="6"
              class="hidden-sm-and-down"
            />
            <v-col cols="12" md="6">
              <input-field
                :value.sync="employee.position"
                :label="`${$t('employee.form.position')}`"
                :rules="[validation.max_position, validation.min_optional]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col
              v-if="showNif"
              cols="12" md="6"
            >
              <input-field
                :value.sync="employee.nif"
                :label="`${$t('employee.form.nif')}`"
                :rules="[validation.max_nif, validation.min_nif]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <date-picker-field
                :value.sync="employee.entry"
                :label="`${$t('employee.form.entry_date')}`"
                :maxDate="maxEntryDate"
                :clearDate="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <date-picker-field
                :value.sync="employee.exit"
                :label="`${$t('employee.form.leaving_date')}`"
                :minDate="minExitDate"
                :clearDate="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('update:show-modal', false)"
        >
          {{ $t('employee.form.cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          @click="save()"
        >
          {{ $t('employee.form.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import InputField from '@/components/base/form/InputField.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import DatePickerField from '@/components/base/form/DatePickerField.vue';
import formValidationService from '@/services/validations/formValidation';

export default {
  name: 'dialog-form-employee',
  inject: {
    createDateFromString: 'createDateFromString',
  },
  components: {
    AlertFixed,
    InputField,
    DatePickerField,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    dataEmployee: {
      type: Object,
      default: () => {},
    },
    showNif: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    minExitDate() {
      if (!this.employee.entry) {
        return null;
      }

      return this.convertISODateStringWithoutTime(this.employee.entry);
    },
    maxEntryDate() {
      if (!this.employee.exit) {
        return null;
      }

      return this.convertISODateStringWithoutTime(this.employee.exit);
    },
  },
  data() {
    return {
      employee: {
        name: '',
        surname: '',
        email: '',
        position: '',
        nif: '',
        entry: null,
        exit: null,
        documentData: 0,
      },
      validation: {
        required: (v) => !!v || this.$t('form.validations.required_field'),
        min: (v) => v.trim().length >= 3 || this.$t('form.validations.min_length', { length: 3 }),
        min_optional: (v) => (!v || v.trim().length >= 3) || this.$t('form.validations.min_length', { length: 3 }),
        max_name: (v) => v.trim().length <= 100 || this.$t('form.validations.max_length', { length: 100 }),
        max_surname: (v) => v.trim().length <= 255 || this.$t('form.validations.max_length', { length: 255 }),
        max_position: (v) => v.trim().length <= 50 || this.$t('form.validations.max_length', { length: 50 }),
        max_nif: (v) => v.trim().length <= 30 || this.$t('form.validations.max_length', { length: 30 }),
        min_nif: (v) => (!v || v.trim().length >= 8) || this.$t('form.validations.min_length', { length: 8 }),
        email: (v) => (v === '' || (!!v && formValidationService.isValidEmail(v))) || this.$t('form.validations.wrong_email'),
      },
      showAlert: false,
      emailAlreadyExist: false,
    };
  },
  methods: {
    save() {
      if (!this.$refs.employeeForm.validate()) {
        this.showAlert = true;
        return;
      }

      this.$emit('saveEmployee', this.employee);
    },
    convertISODateStringWithoutTime(date) {
      return this.createDateFromString(date).toISOString().slice(0, 10);
    },
    fillEmployeeWithData() {
      Object.keys(this.employee).forEach((key) => {
        this.employee[key] = this.dataEmployee[key];
      });
    },
    blockEmailIfExists() {
      if (this.dataEmployee.email) {
        this.emailAlreadyExist = true;
      }
    },
  },
  mounted() {
    if (this.dataEmployee && Object.keys(this.dataEmployee).length) {
      this.fillEmployeeWithData();
      this.blockEmailIfExists();
    }
  },
};
</script>
