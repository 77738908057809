import TrainingUserId from '@/domain/training/user/TrainingUserId';
import Language from '@/domain/language/Language';

export default class TrainingUserRepresentation {
 protected _id!: TrainingUserId;

 protected _language!: Language;

 setId(id: TrainingUserId): this {
   this._id = id;
   return this;
 }

 setLanguage(language: Language): this {
   this._language = language;
   return this;
 }
}
