import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';
import DataBreachWasDeleted from '@/domain/dataBreach/DataBreachWasDeleted';

export default class DataBreachWasDeletedEventListener {
  private readonly dataBreachesPendingWriteModel: DataBreachesPendingWriteModel;

  constructor(
    dataBreachesPendingWriteModel: DataBreachesPendingWriteModel,
  ) {
    this.dataBreachesPendingWriteModel = dataBreachesPendingWriteModel;
  }

  async invoke(dataBreachWasDeleted: DataBreachWasDeleted) {
    await this.dataBreachesPendingWriteModel.deleteDataBreach(
      dataBreachWasDeleted.getDataBreachId(),
    );
  }
}
