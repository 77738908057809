function setProcessingActivitiesDepartmentIfHasDepartmentName(listInfo) {
  return listInfo.map((pa) => {
    if (pa.type !== 'processing-activity' || !pa.department_name) {
      return pa;
    }

    // eslint-disable-next-line no-param-reassign
    pa.department = pa.department_name;
    return pa;
  });
}

export default ({
  processingActivitiesRepository,
}) => async (companyId, lang, spainAdmin, isAdmin) => {
  try {
    const listInfo = await processingActivitiesRepository
      .getListDocuments(companyId, lang, spainAdmin, isAdmin);

    const processingActivities = setProcessingActivitiesDepartmentIfHasDepartmentName(listInfo);

    return {
      success: true,
      data: processingActivities.filter((obj) => !obj.hiddenForClient),
    };
  } catch (e) {
    if (e === 401) {
      return { success: true, data: [] };
    }
    return { success: false };
  }
};
