import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MAX_LENGTH = 700;

export default class RequestPetition extends StringValueObject {
  requestPetition;

  constructor(requestPetition) {
    super(requestPetition, null, MAX_LENGTH);
    this.requestPetition = requestPetition;
  }
}
