export default class AbstractHeadquarterRepresentation {
  _id;

  _clientId;

  _name;

  _address;

  _country;

  _locality;

  _securityMeasures;

  setId(id) {
    this._id = id;
  }

  setClientId(clientId) {
    this._clientId = clientId;
  }

  setName(name) {
    this._name = name;
  }

  setAddress(address) {
    this._address = address;
  }

  setCountry(country) {
    this._country = country;
  }

  setLocality(locality) {
    this._locality = locality;
  }

  setSecurityMeasures(securityMeasures) {
    this._securityMeasures = securityMeasures;
  }
}
