<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>

import AnonymousLayout from '@/views/layouts/AnonymousLayout.vue';
import DefaultLayout from '@/views/layouts/DefaultLayout.vue';
import SSOLayout from '@/views/layouts/SSOLayout.vue';
import LegalLayout from '@/views/layouts/LegalLayout.vue';
import AnonymousLayoutB2B from '@/views/layouts/AnonymousLayoutB2B.vue';
import subdomainService from '@/services/admin/subdomain';
import authRepository from '@/services/auth';
import '@/plugins/usercentrics';
import LoginCentralized from '@/services/login/LoginCentralized';
import { user } from '@/infrastructure/user/UserCache';
import { admin } from '@/infrastructure/admin/AdminCache';
// eslint-disable-next-line import/no-cycle
import { changeSelectedClient } from '@/infrastructure/application/client';
import ChangeClientSelectedCommand
  from '@/application/client/changeClientSelected/ChangeClientSelectedCommand';

export default {
  name: 'App',
  provide: {
    user,
    admin,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: {
    'default-layout': DefaultLayout,
    'anonymous-layout': AnonymousLayout,
    'anonymous-layout-b2b': AnonymousLayoutB2B,
    'legal-layout': LegalLayout,
    'sso-layout': SSOLayout,
  },
  methods: {
    setTitlePage() {
      const title = this.$t(`${this.$route.meta.title}`);
      document.title = LoginCentralized.hasLoginCentralizedUrl() ? `Pridatect - ${title}` : `Userdesk::${title}`;
    },
    setPridatectFaviconIfLoginCentralized() {
      if (!LoginCentralized.hasLoginCentralizedUrl()) {
        return;
      }
      const linkFavicon = document.getElementById('icon-favicon');
      linkFavicon.href = `${window.location.origin}/faviconPRDT.ico`;
    },
    async updateSelectedClient(clientId) {
      await changeSelectedClient.invoke(new ChangeClientSelectedCommand(Number(clientId)));
    },
  },
  async beforeMount() {
    if (this.$route.query.clientId) {
      await this.updateSelectedClient(this.$route.query.clientId);
    }

    this.setTitlePage();
    this.setPridatectFaviconIfLoginCentralized();

    if (this.$route.meta.layout === 'anonymous-layout') {
      this.$showLoading();
      await authRepository.logout();
    }

    if (!LoginCentralized.hasLoginCentralizedUrl()) {
      const subdomain = window.location.hostname.split('.')[0];
      await subdomainService.getInfoAdmin(subdomain);
    }

    if (this.$route.meta.layout === 'anonymous-layout') {
      this.$hideLoading();
    }

    this.$watch(() => this.$route.query, async (newValue, oldValue) => {
      if (newValue !== oldValue && this.$route.query.clientId) {
        await this.updateSelectedClient(this.$route.query.clientId);
      }
    });
  },
  watch: {
    $route() {
      this.setTitlePage();
    },
  },
};
</script>
