<template>
  <v-row>
    <v-col cols="12">
      <title-section
        :title="$t('risks.title')"
        :subtitle="$t('risks.subtitle')"
      />
    </v-col>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="search"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12">
      <filters
        v-if="isSpanishAdmin && filters.length > 1"
        :items.sync="filters"
        :value.sync="filtersSelected"
        section="risks"
      />
    </v-col>
    <v-col cols="12">
      <default-table
        :items.sync="documents"
        :headers="headers"
        :search.sync="search"
        :actions="['download', 'view']"
        :downloadType="['pdf', 'doc']"
        :typesDocuments.sync="filters"
        sortBy="title"
        @viewDocument="viewDocument"
        @downloadDocument="downloadRiskDocument"
        @selectExtensionShow="selectExtensionShow"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

import documentsUseCases from '@/usecases/documents/risksPias';
import uploadedDocumentUseCases from '@/usecases/documents/uploaded';

import TitleSection from '../base/section/TitleSection.vue';
import Filters from '../base/section/Filters.vue';
import InputSearch from '../base/form/InputSearch.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';

export default {
  name: 'risks-container',
  components: {
    TitleSection,
    Filters,
    InputSearch,
    DefaultTable,
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    ...mapGetters('admin', {
      isSpanishAdmin: 'isSpanishAdmin',
    }),
    headers() {
      return [
        {
          text: this.$t('risks.name_doc_table'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '50%',
        },
        {
          text: this.$t('risks.type_doc_table'),
          align: 'start',
          sortable: true,
          value: 'typeName',
          width: '30%',
        },
        {
          text: this.$t('risks.last_updated'),
          align: 'start',
          sortable: true,
          value: 'updated',
          width: '20%',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
    documents() {
      const documents = this.listDocuments;
      const filterDocuments = [];
      if (this.filtersSelected.length) {
        documents.forEach((document, key) => {
          if (this.filtersSelected.includes(documents[key].type)) {
            filterDocuments.push(documents[key]);
          }
        });
      }
      return filterDocuments;
    },
  },
  data() {
    return {
      listDocuments: [],
      filters: [],
      filtersSelected: [],
      search: '',
    };
  },
  methods: {
    async getRisksPias(spainAdmin) {
      const response = await documentsUseCases.fetchDocumentsList(
        this.clientId, this.$i18n.locale, spainAdmin, this.userIsAdmin,
      );

      if (response.success) {
        this.listDocuments = response.data;
        this.setDateText();
        const self = this;
        Object.keys(this.listDocuments).forEach((key) => {
          self.listDocuments[key].typeName = this.$t(`risks.${self.listDocuments[key].type}_filter`);
        });
      } else {
        this.$showError('risks.error.error_page_load');
      }
    },
    async getTypesDocuments() {
      const response = await documentsUseCases.fetchTypeOfDocument(this.listDocuments);

      if (response.success) {
        this.filters = response.data;

        this.filters.forEach((filter, key) => {
          this.filters[key].name = this.$t(`risks.${filter.id}_filter`);
          this.filtersSelected.push(filter.id);
        });
      } else {
        this.$showError('risks.error.error_page_load');
      }
    },
    async viewDocument(document) {
      this.$showLoading();

      const response = await documentsUseCases.viewDocument(
        document.id,
        document.type,
        this.$i18n.locale,
        document.title,
        this.clientId,
        this.userIsAdmin,
      );

      if (!response.success) {
        this.$showError('risks.error.error_view_document');
      }

      this.$hideLoading();
    },
    selectExtensionShow(document) {
      this.$eventHub.$emit('showOptionsExtensionFile', document);
    },
    async downloadRiskDocument(document, type = null) {
      this.$showLoading();
      let response = null;
      if (document.signedUrl) {
        response = await uploadedDocumentUseCases.downloadDocument(
          this.clientId, document.id, this.userIsAdmin,
        );
      } else {
        response = await documentsUseCases.downloadDocument(
          this.clientId, document.id, document.type, type, this.userIsAdmin,
        );
      }

      if (!response.success) {
        this.$showError('risks.error.error_download_document');
      }

      this.$hideLoading();
    },
    setDateText() {
      Object.keys(this.listDocuments).forEach((key) => {
        const date = new Date(this.listDocuments[key].modified.date);
        this.listDocuments[key].updated = date.getTime();
      });
    },
  },
  async beforeMount() {
    this.$showLoading();

    await this.getRisksPias(this.isSpanishAdmin);

    if (this.isSpanishAdmin) {
      await this.getTypesDocuments(this.isSpanishAdmin);
    } else {
      this.filters.push({ id: 'risk_evaluation', name: this.$t('risks.risk_evaluation_filter') });
      this.filtersSelected.push('risk_evaluation');
    }

    this.$eventHub.$on('downloadDocument', this.downloadRiskDocument);

    this.$hideLoading();
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadRiskDocument);
  },
};
</script>
