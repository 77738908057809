import TomRepository from '@/domain/tom/TomRepository';
import ClientId from '@/domain/client/ClientId';
import { getManagement, putManagement } from '@/shared/restActions';
import Tom from '@/domain/tom/Tom';
import TomBuilder from '@/domain/tom/TomBuilder';
import TomCache from '@/infrastructure/tom/TomCache';
import TomId from '@/domain/tom/TomId';
import TomRaw from '@/infrastructure/tom/TomRaw';
import TomToUpdateRepresentation from '@/infrastructure/tom/TomToUpdateRepresentation';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';
import TomToUpdate from '@/domain/tom/TomToUpdate';

export default class TomRepositoryApi implements TomRepository {
  public async ofClient(clientId: ClientId): Promise<Tom[]> {
    let toms: Tom[] = TomCache.all();

    if (toms.length) {
      return toms;
    }

    const tomsRaw: TomRaw[] = await TomRepositoryApi.getTomsFromApi(clientId);
    toms = TomRepositoryApi.buildToms(tomsRaw);

    TomCache.save(toms);

    return toms;
  }

  private static async getTomsFromApi(clientId: ClientId): Promise<any> {
    const response = await getManagement(`/api/v2/clients/${clientId.toInt()}/toms`);
    return response.data;
  }

  private static buildToms(tomsRaw: TomRaw[]) {
    const toms: Tom[] = [];
    tomsRaw.forEach((tomRaw: TomRaw) => {
      const tomBuilder = new TomBuilder();
      tomBuilder.withMandatoryValues(tomRaw.id, tomRaw.name, tomRaw.type, tomRaw.status, tomRaw.description)
        .withClassification(tomRaw.classification)
        .withReferenceCode(tomRaw.reference_code);

      toms.push(tomBuilder.create());
    });

    return toms;
  }

  public async retrieve(tomId: TomId): Promise<Tom> {
    let tom: Tom = TomCache.retrieve(tomId);

    if (tom.hasAllData()) {
      return Object.assign(Object.create(Object.getPrototypeOf(tom)), tom);
    }

    const tomRaw: TomRaw = await TomRepositoryApi.getTomFromApi(tomId);
    tom = TomRepositoryApi.buildTom(tomRaw);
    TomCache.update(tom);

    return tom;
  }

  public async retrieveFromCache(tomId: TomId): Promise<Tom> {
    return TomCache.retrieve(tomId);
  }

  private static async getTomFromApi(tomId: TomId): Promise<TomRaw> {
    const response = await getManagement(`/api/v2/toms/${tomId.toString()}`);
    return response.data;
  }

  private static buildTom(tomRaw: TomRaw): Tom {
    const tomBuilder = new TomBuilder();
    tomBuilder.withMandatoryValues(tomRaw.id, tomRaw.name, tomRaw.type, tomRaw.status, tomRaw.description)
      .withClassification(tomRaw.classification)
      .withReferenceCode(tomRaw.reference_code)
      .withNotes(tomRaw.notes!)
      .withAttachedDocuments(tomRaw.attached_documents!);

    return tomBuilder.create();
  }

  public async changeStatus(tom: Tom): Promise<void> {
    await putManagement(
      `/api/v2/toms/${tom.getId().toString()}/status`,
      { status: tom.getStatus().toString() },
    );
    TomCache.update(tom);
  }

  public async update(tom: Tom): Promise<void> {
    const tomRaw: TomToUpdateRepresentation = tom
      .representedAs(new TomToUpdateRepresentation()) as TomToUpdateRepresentation;

    const tomFromCache: Tom = TomCache.retrieve(tom.getId());
    const attachedDocumentsFromCache: TomAttachedDocument[] = tomFromCache.getAttachedDocuments();

    const realAttachedDocument: TomAttachedDocument[] = tom.getAttachedDocuments();
    const realAttachedDocumentsId = this.getRealTomAttachedDocumentsIds(realAttachedDocument);

    const attachedDocumentsToDelete = this.getTomAttachedDocumentsToDeleteIds(attachedDocumentsFromCache, realAttachedDocumentsId);

    await putManagement(
      `/api/v2/toms/${tom.getId().toString()}`,
      { ...tomRaw.toJson(), removed_documents: attachedDocumentsToDelete },
    );

    TomCache.update(this.updateTomWithoutAttachedDocumentContent(tom));
  }

  private getRealTomAttachedDocumentsIds(realAttachedDocument: TomAttachedDocument[]): string[] {
    return realAttachedDocument!.reduce((ids: string[], doc: TomAttachedDocument) => [...ids, doc.getId().toString()], []) || [];
  }

  private getTomAttachedDocumentsToDeleteIds(attachedDocumentsFromCache: TomAttachedDocument[], realAttachedDocumentsId: string[]) {
    const attachedDocumentsToDelete: string[] = [];
    attachedDocumentsFromCache.forEach((doc: TomAttachedDocument) => {
      if (!realAttachedDocumentsId.includes(doc.getId().toString())) {
        attachedDocumentsToDelete.push(doc.getId().toString());
      }
    });
    return attachedDocumentsToDelete;
  }

  private updateTomWithoutAttachedDocumentContent(tom: Tom): Tom {
    const attachedDocsWithoutContent: TomAttachedDocument[] = tom.getAttachedDocuments().map(
      (document: TomAttachedDocument) => (TomAttachedDocument.fromAttachedDocumentStruct({
        name: document.getName().toString(),
        id: document.getId().toString(),
      })),
    );

    const tomToUpdate = new TomToUpdate();
    tomToUpdate.setNotes(tom.getNotes())
      .setAttachedDocuments(attachedDocsWithoutContent)
      .setStatus(tom.getStatus());

    tom.update(tomToUpdate);
    return tom;
  }

  public reset(): void {
    TomCache.reset();
  }
}
