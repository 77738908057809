<template>
  <v-form ref="registerUserForm">
    <v-row class="mx-n3">
        <v-col cols="12">
          <p class="mb-3 text-center">
            {{ $t('register.form.info') }}
          </p>
        <alert-fixed
          v-if="showAlert"
        >
          {{textToAlert}}
          <span v-if="textToAlert === ''">
            {{ $t('register.form.error.register') }}
            <br/>
            {{ $t('register.form.error.register_subcopy') }}
          </span>
        </alert-fixed>
        </v-col>
        <v-col cols="12">
          <input-field
            :value.sync="user.name"
            :label="`${$t('register.form.name')} *`"
            :rules="[validations.required, validations.min]"
            :validateOnBlur="true"
          />
        </v-col>
        <v-col cols="12">
          <input-field
            :value.sync="user.surname"
            :label="`${$t('register.form.surname')} *`"
            :rules="[validations.required, validations.min]"
            :validateOnBlur="true"
          />
        </v-col>
        <v-col cols="12">
          <input-field
            :value.sync="user.email"
            :label="`${$t('register.form.email')} *`"
            :rules="[validations.required, validations.email]"
            :validateOnBlur="true"
          />
        </v-col>
        <v-col cols="12" class="mb-10">
          <input-field-password
            :value.sync="user.password"
            :label="`${$t('register.form.password')} *`"
            :rules="[validations.required, validations.password]"
            :hint="$t('form.password.rules')"
            :validate-on-blur="true"
          />
        </v-col>
        <v-col cols="6">
          <router-link
            class="mt-2"
            :to="{ name: 'login' }"
            v-track="'Go to login_register'"
          >
            {{ $t('register.form.login_with_account') }}
          </router-link>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            class="mb-5"
            color="primary"
            rounded
            @click="registerUser"
          >
            {{ $t('register.form.button_register') }}
          </v-btn>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import InputField from '@/components/base/form/InputField.vue';
import formValidationService from '@/services/validations/formValidation';
import InputFieldPassword from '@/components/base/form/InputFieldPassword.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import RegisterClientUserCommand
  from '@/application/clientUser/registerClientUser/RegisterClientUserCommand';
import { registerClientUser } from '@/infrastructure/application/clientUser';

export default {
  name: 'user-register-form',
  inject: {
    registerClientUser: 'registerClientUser',
  },
  components: {
    AlertFixed,
    InputFieldPassword,
    InputField,
  },
  data() {
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        password: '',
      },
      validations: {
        required: (v) => !!v.trim() || this.$t('form.validations.required_field'),
        min: (v) => v.trim().length >= 2 || this.$t('form.validations.min_length', { length: 2 }),
        email: (v) => formValidationService.isValidEmail(v) || this.$t('form.validations.wrong_email'),
        password: (v) => formValidationService.isValidPassword(v.trim()) || this.$t('form.password.rules'),
      },
      showAlert: false,
      textToAlert: '',
    };
  },
  methods: {
    async registerUser() {
      this.showAlert = false;
      this.textToAlert = '';
      if (!this.$refs.registerUserForm.validate()) {
        this.textToAlert = this.$t('form.review');
        this.showAlert = true;
        this.$track({
          action: 'Complete register Invalid',
        });
        return;
      }
      this.$showLoading();
      const response = await registerClientUser.invoke(new RegisterClientUserCommand(
        this.user.name,
        this.user.surname,
        this.user.email,
        this.user.password,
        this.$i18n.locale,
      ));
      this.$hideLoading();
      this.$track({
        action: 'Complete register',
        result: response.success,
      });
      if (!response.success) {
        this.textToAlert = this.$te(`register.form.error.${response.error}`)
          ? this.$t(`register.form.error.${response.error}`) : '';
        this.showAlert = true;
        return;
      }
      this.$emit('userWasRegistered', this.user);
    },
  },
};
</script>
