<template>
  <div>
    <a @click="getEquipmentAndShowForm">
      {{ equipment.name }}
    </a>
    <dialog-form-equipment
      v-if="openEquipmentModal"
      :showModal.sync="openEquipmentModal"
      :dataEquipment.sync="equipmentToEdit"
      @saveEquipment="saveEquipment"
    />
  </div>
</template>

<script>
import DialogFormEquipment from './form/DialogFormEquipment.vue';

export default {
  name: 'edit-equipment',
  inject: {
    fetchEquipment: 'fetchEquipment',
    updateEquipment: 'updateEquipment',
  },
  components: {
    DialogFormEquipment,
  },
  props: {
    equipment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      equipmentToEdit: {},
      openEquipmentModal: false,
    };
  },
  methods: {
    async getEquipmentAndShowForm() {
      this.$showLoading();

      const response = await this.fetchEquipment(this.equipment.id);

      this.$hideLoading();

      if (!response.success) {
        this.$showError('equipment.edit.error');
        return;
      }
      this.equipmentToEdit = response.equipment;
      this.openEquipmentModal = true;
    },
    async saveEquipment(equipment) {
      this.$showLoading();
      const response = await this.updateEquipment(equipment, this.equipmentToEdit.id);

      this.$hideLoading();
      if (!response.success) {
        this.$showError('equipment.edit.error');
        return;
      }

      this.$showSuccess('equipment.edit.success');
      this.openEquipmentModal = false;
      this.$eventHub.$emit('equipmentWasUpdated');
    },
  },
};
</script>
