import DomainEvent from '@/domain/domainEvent/DomainEvent';
import EmailValueObject from '@/domain/shared/email/EmailValueObject';

export default class DataBreachCollaboratorWasAdded extends DomainEvent {
  private readonly collaboratorEmail: string;

  protected constructor(collaboratorEmail: string) {
    super();
    this.collaboratorEmail = collaboratorEmail;
  }

  public static fromCollaboratorEmail(email: EmailValueObject) {
    return new this(email.toString());
  }

  public get eventName(): string {
    return 'DataBreachCollaboratorWasAdded';
  }

  public getCollaboratorEmail(): string {
    return this.collaboratorEmail;
  }
}
