import TomClassification from '@/domain/tom/clasification/TomClassification';
import TomDescription from '@/domain/tom/TomDescription';
import TomId from '@/domain/tom/TomId';
import TomName from '@/domain/tom/TomName';
import TomNote from '@/domain/tom/TomNote';
import TomReferenceCode from '@/domain/tom/TomReferenceCode';
import TomRepresentation from '@/domain/tom/TomRepresentation';
import TomStatus from '@/domain/tom/TomStatus';
import TomType from '@/domain/tom/TomType';
import TomToUpdate from '@/domain/tom/TomToUpdate';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';

export default class Tom {
  private readonly id: TomId;

  private readonly name: TomName;

  private readonly type: TomType;

  private status: TomStatus;

  private readonly classification: TomClassification;

  private readonly description: TomDescription;

  private referenceCode: TomReferenceCode | null = null;

  private notes: TomNote[] | null = null;

  private attachedDocuments: TomAttachedDocument[] | null = null;

  constructor(
    id: TomId,
    name: TomName,
    type: TomType,
    status: TomStatus,
    classification: TomClassification,
    description: TomDescription,
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.status = status;
    this.classification = classification;
    this.description = description;
  }

  public addReferenceCode(referenceCode: TomReferenceCode): Tom {
    this.referenceCode = referenceCode;
    return this;
  }

  public addNotes(notes: TomNote[]): Tom {
    this.notes = notes;
    return this;
  }

  public addAttachedDocuments(attachedDocuments: TomAttachedDocument[]): Tom {
    this.attachedDocuments = attachedDocuments;
    return this;
  }

  public representedAs(tomRepresentation: TomRepresentation): TomRepresentation {
    return tomRepresentation.setId(this.id)
      .setName(this.name)
      .setType(this.type)
      .setStatus(this.status)
      .setClassification(this.classification)
      .setReferenceCode(this.referenceCode)
      .setDescription(this.description)
      .setNotes(this.notes)
      .setAttachedDocuments(this.attachedDocuments);
  }

  public getId() {
    return this.id;
  }

  public getStatus(): TomStatus {
    return this.status;
  }

  public changeStatus(status: TomStatus): void {
    this.status = status;
  }

  public update(tomToUpdate: TomToUpdate) {
    this.notes = tomToUpdate.notes;
    this.attachedDocuments = tomToUpdate.attachedDocuments;
    this.status = tomToUpdate.status;
  }

  public hasAllData(): boolean {
    return !!this.notes;
  }

  public getAttachedDocuments(): TomAttachedDocument[] {
    return this.attachedDocuments || [];
  }

  public getNotes(): TomNote[] {
    return this.notes || [];
  }
}
