<template>
  <v-row justify="center" class="mt-5 mb-12">
    <v-col cols="12" class="pa-0">
      <v-expansion-panels
        accordion
        class="elevation-lighten"
      >
        <v-expansion-panel
          v-for="({ question, answer }, index) in faqs"
          :key="index"
        >
          <v-expansion-panel-header
            @click="changePanel(index)"
          >
            {{ question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span
              v-html="answer"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'faqs-data-breach',
  computed: {
    faqs() {
      return [
        {
          question: this.$t('data_breach.info.one_question'),
          answer: this.$t('data_breach.info.one_answer'),
        },
        {
          question: this.$t('data_breach.info.two_question'),
          answer: this.$t('data_breach.info.two_answer'),
        },
        {
          question: this.$t('data_breach.info.three_question'),
          answer: this.$t('data_breach.info.three_answer'),
        },
        {
          question: this.$t('data_breach.info.four_question'),
          answer: this.$t('data_breach.info.four_answer'),
        },
      ];
    },
  },
  data() {
    return {
      previousPanel: null,
    };
  },
  methods: {
    changePanel(currentPanel) {
      if (currentPanel !== this.previousPanel) {
        this.$track({ action: `Data breach info ${currentPanel + 1}`, result: 'unfold' });
      }

      if (this.previousPanel !== null) {
        this.$track({ action: `Data breach info ${this.previousPanel + 1}`, result: 'fold' });
      }

      this.previousPanel = this.previousPanel === currentPanel ? null : currentPanel;
    },
  },
};
</script>
