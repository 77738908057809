import ClientUserWasCreatedEventListenerTrainingEmployee
  from '@/application/training/employee/ClientUserWasCreatedEventListener/ClientUserWasCreatedEventListener';
import ClientUserWasCreatedEventListenerEmployee
  from '@/application/employee/resetEmployees/ClientUserWasCreatedEventListener';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';
import EmployeeRepositoryApi from '@/infrastructure/employee/EmployeeRepositoryApi';

import ClientUserWasCreatedEventListenerAddClientUser
  from '@/application/clientUser/fetchClientUsers/ClientUserWasCreatedEventListener';

import EventListener from '@/application/eventBus/EventListener';
import ClientUserRepositoryApi from '@/infrastructure/clientUser/ClientUserRepositoryApi';
import ClientUserWriteModelApi from '@/infrastructure/clientUser/ClientUserWriteModelApi';
import DepartmentReadModelApi
  from '@/infrastructure/department/listDepartment/DepartmentReadModelApi';
import ObtainDepartmentName from '@/domain/department/listDepartment/ObtainDepartmentName';
import Translator from '@/infrastructure/Translator';

const listenersContext: {[key: string]: EventListener} = {
  Employee: new ClientUserWasCreatedEventListenerEmployee(
    new EmployeeRepositoryApi(),
  ),
  TrainingEmployee: new ClientUserWasCreatedEventListenerTrainingEmployee(
    new TrainingEmployeeRepositoryApi(),
  ),
  AddClientUser: new ClientUserWasCreatedEventListenerAddClientUser(
    new ClientUserRepositoryApi(),
    new ClientUserWriteModelApi(),
    new ObtainDepartmentName(new DepartmentReadModelApi()),
    new Translator(),
  ),
};

export default class ClientUserWasCreatedEventListenerBuilder {
  static build(context: string) {
    return listenersContext[context];
  }
}
