<template>
  <v-dialog
    v-model="show"
    content-class="form-processing-activities"
    persistent
    max-width="95%"
    scrollable
  >
    <v-card class="modal-processing-activities">
      <v-row>
        <v-col
          cols="4"
          class="modal-processing-activities__layout"
        >
          <div>
            <left-annotations
              v-if="annotationsSteps"
              :annotation.sync="annotationsSteps[currentStep]"
              :adminInfo.sync="adminInfo"
            />
          </div>
        </v-col>
        <v-col cols="8" class="modal-processing-activities__form">
          <v-card-title class="modal-processing-activities__form--title">
            <h2>
              {{ $t('processing_activities.title') }}
            </h2>
            <v-spacer />
            <v-btn
              icon
              @click="$emit('update:show', false)"
              v-track="'Close Processing Activity Modal'"
            >
              <v-icon v-text="'close'"/>
            </v-btn>
          </v-card-title>
          <v-card-text :style="heightCardText" v-resize="setHeightCardText">
            <info-model-activity
              v-if="currentStep !== 'countryLanguage'"
              :countryId.sync="countryLanguageToUse.country_id"
              :language.sync="countryLanguageToUse.language"
              :editCountryLanguage.sync="canEditCountryLanguage"
              :department.sync="departmentActivity.name"
              :processingActivityName="getProcessingActivityName"
              @editCountryLanguage="goStep"
            />
            <v-slide-x-reverse-transition>
              <div v-show="currentStep === 'countryLanguage'">
                <country-language-selection
                  v-if="currentStep === 'countryLanguage'"
                  :language-list.sync="languageList"
                  :country-list.sync="countriesList"
                  :country-language-to-use="countryLanguageToUse"
                  @countryLanguageSelected="changedCountryLanguage"
                />
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition>
              <div v-show="currentStep === 'selectModel'">
                <model-selection
                  v-if="currentStep === 'selectModel'"
                  :departmentActivity.sync="departmentActivity.id"
                  :departments.sync="departmentsToShow"
                  :modelsList.sync="modelsSelectable"
                  @departmentSelected="departmentSelected"
                  @modelSelected="modelSelected"
                  @saveCustomName="saveCustomName"
                />
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition>
              <div v-show="currentStep === 'recipientsCategory'">
                <recipients-category
                  v-if="currentStep === 'recipientsCategory'"
                  :recipientsCategory.sync="recipientsCategoriesSelected"
                  :recipientsCategoryList.sync="recipientsCategoryList"
                  @saveNewRecipientsCategory="saveNewRecipientsCategory"
                  @goNextStep="goStep"
                />
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition>
              <div v-show="currentStep === 'recipients'">
                <recipients
                  v-if="currentStep === 'recipients' && hasRecipientAccess"
                  :recipientsSelected.sync="recipientsSelected"
                  :clientId="clientId"
                  :currentStep="currentStep"
                  :argsFormStatus="argsFormStatus"
                  :isSelectionRecipientStep.sync="isSelectionRecipientStep"
                  @goRecipientsCategorySelection="goStep"
                  @constructRecipientsGroups="constructRecipientsGroups"
                  @saveProcessingActivityOnCache="saveProcessingActivityOnCache"
                />
                <recipients-deprecated
                  v-if="currentStep === 'recipients' && !hasRecipientAccess"
                  :recipientsSelected.sync="recipientsSelected"
                  :recipientsList.sync="recipientsList"
                  :isSelectionRecipientStep.sync="isSelectionRecipientStep"
                  @saveNewRecipients="saveNewRecipients"
                  @goRecipientsCategorySelection="goStep"
                  @constructRecipientsGroups="constructRecipientsGroups"
                />
              </div>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition>
              <div v-show="currentStep === 'dataSecurity'">
                <data-security
                  :activity="activity"
                  :securityChecks="securityChecks"
                  @finishProcess="finishProcess"
                />
              </div>
            </v-slide-x-reverse-transition>
          </v-card-text>
          <v-card-actions
            class="modal-processing-activities__previous-link"
          >
            <a
              v-if="showPreviousStepLink"
              @click="goPreviousStep"
            >
              {{ $t('processing_activities.go_back') }}
            </a>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import userUsecases from '@/application/user/usecases';
import questionnairesProcessingActivityUseCases from '@/usecases/questionnaires/processingActivities';
import processingActivityUseCases, {
  fetchProcessingActivityForm,
  saveProcessingActivityForm,
  saveProcessingActivityFormStatus,
  resetProcessingActivityFormStatus,
  fetchActiveRecipients,
} from '@/application/processingActivity';
import { fetchRecipientCategories } from '@/application/recipient';
import processingActivityStepsServices from '@/services/questionnaires/processingActivities/steps';
import activityServices from '@/services/questionnaires/processingActivities/activity';
import recipientProcessingActivityAccess from '@/services/permissions/recipientProcessingActivity';
import getAdminInfoAnnotations from '@/application/services/getAdminInfoAnnotations';
import { fetchListDepartment } from '@/infrastructure/application/department/listDepartment';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';
import RecipientsCategory from './steps/RecipientsCategory.vue';
import CountryLanguageSelection from './steps/CountryLanguangeSelection.vue';
import LeftAnnotations from './LeftAnnotations.vue';
import InfoModelActivity from './InfoModelActivity.vue';
import ModelSelection from './steps/ModelSelection.vue';
import Recipients from './steps/Recipients.vue';
import DataSecurity from './steps/DataSecurity.vue';
import RecipientsDeprecated
  from './steps/RecipientsDeprecated.vue';

export default {
  name: 'processing-activities-modal',
  provide: {
    fetchActiveRecipients,
    resetProcessingActivityFormStatus,
    saveProcessingActivityForm,
    saveProcessingActivityFormStatus,
  },
  components: {
    RecipientsDeprecated,
    CountryLanguageSelection,
    LeftAnnotations,
    InfoModelActivity,
    ModelSelection,
    RecipientsCategory,
    Recipients,
    DataSecurity,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    processingActivityFormStatus: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('admin', {
      adminCompanyId: 'adminCompanyId',
    }),
    ...mapGetters('user', {
      clientId: 'companyId',
      userDepartments: 'departments',
      userRoleType: 'roleType',
    }),
    canEditCountryLanguage() {
      return this.currentStep === 'selectModel';
    },
    getProcessingActivityName() {
      if (this.activity.name !== '') {
        return this.activity.name;
      }

      let name = '';
      this.modelsSelectable.forEach((model) => {
        if (model.id === this.activity.model_id) {
          name = model.name;
        }
      });
      return name;
    },
    hasRecipientAccess() {
      return recipientProcessingActivityAccess.hasRecipientProcessingActivityAccess();
    },
    showPreviousStepLink() {
      return !this.isSelectionRecipientStep
        && processingActivityStepsServices.showPreviousStepLink(this.currentStep);
    },
  },
  data() {
    return {
      heightCardText: 'height: auto',
      adminInfo: {
        avatarUrl: '',
        name: '',
        position: '',
      },
      currentStep: processingActivityStepsServices.getFirstStep(),
      annotationsSteps: {},
      departmentActivity: {
        id: '',
        name: '',
      },
      countryLanguageToUse: {
        country_id: 0,
        language: this.$i18n.locale,
      },
      activity: {
        company_id: this.clientId,
        name: '',
        department: '',
        language: '',
        model_id: null,
        country_id: 0,
        process_owner: '',
        description_security_control_measures: '',
        recipients_groups: [
          {
            recipients_categories: 0,
            recipients: 0,
          },
        ],
        data_additional_comments: '',
      },
      recipientsCategoriesSelected: [],
      recipientsSelected: [],
      departments: [],
      departmentsToShow: [],
      modelsByDepartment: {},
      modelsSelectable: [],
      recipientsCategoryList: [],
      recipientsList: [],
      languageList: [],
      countriesList: [],
      clientCountryId: '',
      argsFormStatus: null,
      isSelectionRecipientStep: false,
      securityChecks: {
        hasAdditionalComments: null,
        hasSecurityControlMeasures: null,
      },
    };
  },
  methods: {
    getAnnotationsTranslations(annotations) {
      const annotationsTranslations = {};
      Object.keys(annotations).forEach((e) => {
        annotationsTranslations[e] = this.$t(`${annotations[e]}`);
      });

      return annotationsTranslations;
    },
    async getRecipientsCategoryList() {
      this.$showLoading();
      this.loadRecipientCategoriesSelected();
      await this.loadRecipientsCategory();
      this.$hideLoading();
    },
    loadRecipientCategoriesSelected() {
      if (!this.recipientsSelected.length) {
        return;
      }
      this.recipientsCategoriesSelected = [];
      this.recipientsSelected.forEach((obj) => {
        this.recipientsCategoriesSelected.push(obj.categoryId);
      });
    },
    async loadRecipientsCategory() {
      if (this.recipientsCategoryList.length) {
        return;
      }

      if (!this.hasRecipientAccess) {
        await this.loadRecipientsCategoryLegacy();
        return;
      }

      this.recipientsCategoryList = await fetchRecipientCategories();
    },
    async loadRecipientsCategoryLegacy() {
      const response = await questionnairesProcessingActivityUseCases.fetchRecipientsCategory();
      this.recipientsCategoryList = response.data;

      this.recipientsCategoryList.forEach((obj, key) => {
        let nameCategory = obj.name;
        if ((this.$te(`recipients_category.${obj.key}`))) {
          nameCategory = this.$t(`recipients_category.${obj.key}`);
        }
        this.recipientsCategoryList[key].name = nameCategory;
      });
      this.recipientsCategoryList = activityServices.sortListByName(this.recipientsCategoryList);
    },
    async getRecipientsList() {
      this.$showLoading();

      const recipients = this.recipientsSelected;
      this.recipientsSelected = [];

      this.recipientsCategoryList.forEach((obj) => {
        if (this.recipientsCategoriesSelected.includes(obj.id)) {
          let nameCategory = obj.name;
          if ((this.$te(`recipients_category.${obj.key}`))) {
            nameCategory = this.$t(`recipients_category.${obj.key}`);
          }
          this.recipientsSelected.push({
            categoryId: obj.id,
            categoryName: nameCategory,
            selection: activityServices.getRecipientsByCategoryId(obj.id, recipients),
          });
        }
      });

      if (!this.recipientsList.length) {
        const response = await questionnairesProcessingActivityUseCases.fetchRecipients();

        this.recipientsList = response.data;

        this.recipientsList.forEach((obj, key) => {
          let recipientName = obj.name;
          if (this.$te(`recipients.${obj.key}`)) {
            recipientName = this.$t(`recipients.${obj.key}`);
          }
          this.recipientsList[key].name = recipientName;
        });
      }

      this.recipientsList = activityServices.sortListByName(this.recipientsList);

      this.$hideLoading();
    },
    async getClientLanguagesList() {
      const response = await userUsecases.fetchClientLanguagesListByClientId(this.clientId);

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
      }

      response.data.forEach((obj, index) => {
        response.data[index].name = this.$t(`languages.by_code.lang_${obj.code}`);
      });

      this.languageList = response.data;
    },
    async getCountriesList() {
      const response = await questionnairesProcessingActivityUseCases.fetchListCountries();

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
      }

      this.countriesList = response.data;
    },
    async getUserCountry() {
      const response = await userUsecases.fetchUserCountryByClientId(this.clientId);

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
      }
      this.countryLanguageToUse.country_id = response.data.id;
      this.activity.country_id = response.data.id;
      this.clientCountryId = response.data.id;
    },
    async saveNewRecipientsCategory(newRecipientsCategory) {
      const response = await questionnairesProcessingActivityUseCases
        .saveNewRecipientsCategoryCustom(this.adminCompanyId, this.clientId, newRecipientsCategory);

      if (!response.success) {
        this.$showError('processing_activities.step_recipients_category.error_save');
        return;
      }

      this.recipientsCategoryList.push({
        id: `c${response.data.id}`,
        key: newRecipientsCategory,
        name: newRecipientsCategory,
      });

      this.recipientsCategoriesSelected.push(`c${response.data.id}`);
    },
    async saveNewRecipients(newRecipient, positionRecipient) {
      const response = await questionnairesProcessingActivityUseCases.saveNewRecipientsCustom(
        this.adminCompanyId,
        this.clientId,
        newRecipient,
      );

      if (!response.success) {
        this.$showError('processing_activities.step_recipients.error_save');
        return;
      }

      this.recipientsList.push({
        id: `c${response.data.id}`,
        key: newRecipient,
        name: newRecipient,
      });

      this.recipientsSelected[positionRecipient].selection.push(`c${response.data.id}`);
    },
    async getCountryLanguageList() {
      this.$showLoading();
      await this.getCountriesList();
      this.$hideLoading();
    },
    departmentSelected(department) {
      this.modelsSelectable = this.modelsByDepartment[department.key];
      this.departmentActivity.id = department.key;
      this.departmentActivity.name = department.name;
      this.activity.department = department.id;
    },
    modelSelected(modelId) {
      this.activity.model_id = modelId;
      this.goStep();
    },
    saveCustomName(name) {
      this.activity.name = name;
      this.activity.language = this.countryLanguageToUse.language;
      this.goStep();
    },
    async goStep(isPreviousStep = false) {
      const nextStep = processingActivityStepsServices.getNextStep(
        this.currentStep,
        isPreviousStep,
      );

      const stepUpperCaseFirst = nextStep.charAt(0).toUpperCase() + nextStep.slice(1);
      if (typeof this[`get${stepUpperCaseFirst}List`] === 'function') {
        await this[`get${stepUpperCaseFirst}List`]();
      }
      this.saveProcessingActivityOnCache();
      this.currentStep = nextStep;
    },
    constructRecipientsGroups() {
      this.activity.recipients_groups = activityServices.construcRecipientsGroup(
        this.recipientsSelected,
      );

      this.goStep();
    },
    async finishProcess() {
      this.$showLoading();

      const response = await questionnairesProcessingActivityUseCases
        .saveProcessingActivity(this.activity);

      this.$hideLoading();
      if (!response.success) {
        this.$showError('processing_activities.error.activity_saved');
        return;
      }

      this.$emit('newActivityCreated');
      this.$track({
        action: 'Create processing activity -- finish',
        questions_policy_data_delete: this.securityChecks.hasAdditionalComments ? 'yes' : 'no',
        questions_has_security_control_measures: this.securityChecks.hasSecurityControlMeasures ? 'yes' : 'no',
        result: response.success,
      });

      this.$showSuccess('processing_activities.success.activity_saved');
    },
    setVirtualAdminInfo() {
      this.adminInfo = getAdminInfoAnnotations(this.clientCountryId);
    },
    async loadDepartmentsAndModels() {
      await this.loadDepartmentsList();
      this.loadDepartmentsToShow();
      await this.getModelsByCountryAndLanguage();
    },
    async loadDepartmentsList() {
      const response = await fetchListDepartment.invoke(new ListDepartmentQuery(this.clientId));

      if (!response.success) {
        this.$showError('processing_activities.step_model.error_load_department');
        return;
      }

      this.departments = response.departments;
    },
    async getModelsByCountryAndLanguage() {
      this.$showLoading();
      const response = await processingActivityUseCases.fetchModelList(
        this.countryLanguageToUse.country_id,
        this.countryLanguageToUse.language,
      );

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
      }

      this.$hideLoading();

      this.modelsByDepartment = {};

      this.departmentsToShow.forEach((dept) => {
        this.modelsByDepartment[dept.model] = response.models
          .filter((model) => model.departmentKey === dept.model);
      });
    },
    loadDepartmentsToShow() {
      if (this.userRoleType !== 'employee') {
        this.departmentsToShow = this.departments;
        return;
      }

      this.departmentsToShow = this.departments.filter(
        (depart) => this.userDepartments.includes(depart.id),
      );
    },
    async changedCountryLanguage() {
      this.activity.country_id = this.countryLanguageToUse.country_id;
      this.activity.language = this.countryLanguageToUse.language;
      this.modelsSelectable = [];
      await this.loadDepartmentsAndModels();
      this.departmentActivity = {
        id: '',
        name: '',
      };
      this.activity.department = '';
      await this.goStep();
    },
    async restoreForm() {
      await this.getAndFillProcessingActivityFromStore();
      await this.loadDepartmentsAndModels();
      await this.setInfoActivityModel();
      this.argsFormStatus = this.processingActivityFormStatus.args;
      this.currentStep = this.processingActivityFormStatus.step;
      this.resetProcessingActivityFormStatus.invoke();
    },
    getAndFillProcessingActivityFromStore() {
      this.activity = fetchProcessingActivityForm.invoke();
    },
    async setInfoActivityModel() {
      this.countryLanguageToUse.country_id = this.activity.country_id;
      this.countryLanguageToUse.language = this.activity.language;
      await this.getModelsByCountryAndLanguage();
      let department = {
        key: this.activity.department,
        name: this.$t(`departments.${this.activity.department}`),
      };

      department = this.departments.find(
        (departmentRepresentation) => departmentRepresentation.id === this.activity.department,
      );

      this.departmentSelected(department);
    },
    saveProcessingActivityOnCache() {
      saveProcessingActivityForm.invoke(this.activity);
    },
    async goPreviousStep() {
      if (processingActivityStepsServices.isRecipientStep(this.currentStep)) {
        this.argsFormStatus = null;
      }

      this.currentStep = processingActivityStepsServices.getPreviousStep(this.currentStep);

      const stepUpperCaseFirst = this.currentStep
        .charAt(0).toUpperCase() + this.currentStep.slice(1);
      if (typeof this[`get${stepUpperCaseFirst}List`] === 'function') {
        await this[`get${stepUpperCaseFirst}List`]();
      }

      if (processingActivityStepsServices.isSelectModelStep(this.currentStep)) {
        this.activity.name = '';
        this.activity.model_id = null;
      }
    },
    setHeightCardText() {
      this.heightCardText = {
        height: `${document.getElementsByClassName('v-dialog form-processing-activities')[0].offsetHeight - 155}px`,
      };
    },
  },
  async beforeMount() {
    this.$showLoading();
    this.activity.company_id = this.clientId;

    const annotations = processingActivityStepsServices.getAnnotationsSteps();
    this.annotationsSteps = await this.getAnnotationsTranslations(annotations);

    await this.getClientLanguagesList();
    await this.getUserCountry();
    this.setVirtualAdminInfo();

    if (this.processingActivityFormStatus && this.processingActivityFormStatus.step) {
      await this.restoreForm();
    } else {
      const matchLanguage = this.languageList.find((lang) => lang.code === this.$i18n.locale);
      this.countryLanguageToUse.language = matchLanguage ? matchLanguage.code
        : this.languageList[0].code;
      this.activity.language = this.countryLanguageToUse.language;
    }

    await this.loadDepartmentsAndModels();

    this.saveProcessingActivityOnCache();
    this.$hideLoading();
  },
  mounted() {
    this.setHeightCardText();
  },
};
</script>
