/* eslint-disable import/prefer-default-export */
import FetchProcessingActivities
  from '@/application/recipient/processingActivity/fetchProcessingActivities/FetchProcessingActivities';
import ProcessingActivityReadModelApi
  from '@/infrastructure/recipient/processingActivity/ProcessingActivityReadModelApi';
import Translator from '@/infrastructure/Translator';
import normalization from '@/services/shared/normalization';
import processingDates from '@/services/shared/processingDates';

export const fetchProcessingActivitiesOfRecipient = new FetchProcessingActivities(
  new ProcessingActivityReadModelApi(new Translator(), normalization, processingDates),
);
