export default class EditTomCommand {
  public readonly id: string;

  public readonly notes: { content: string, language: string }[] = [];

  public readonly attachedDocuments: { name: string, content: string }[] = [];

  public readonly attachedDocumentsToDelete: string[] = [];

  public readonly status: string;

  constructor(
    id: string,
    notes: { content: string, language: string }[],
    attachedDocuments: { name: string, content: string }[],
    attachedDocumentsToDelete: string[],
    status: string,
  ) {
    this.id = id;
    this.notes = notes;
    this.attachedDocuments = attachedDocuments;
    this.attachedDocumentsToDelete = attachedDocumentsToDelete;
    this.status = status;
  }
}
