import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ResetClientUsersCommand
  from '@/application/clientUser/resetClientUser/ResetClientUsersCommand';

export default class ResetClientUsersCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public invoke(command: ResetClientUsersCommand): void {
    this.clientUserRepository.reset();
  }
}
