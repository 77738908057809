import AbstractEquipmentTypeRepresentation
  from '@/domain/equipment/equipmentType/AbstractEquipmentTypeRepresentation';

export default class EquipmentTypesRepresentation extends AbstractEquipmentTypeRepresentation {
  _translator;

  _name = null;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get key() {
    return this._key;
  }

  get name() {
    if (!this._name) {
      this._name = this._translator.translate(`equipment.type.${this._key}`);
    }

    return this._name;
  }
}
