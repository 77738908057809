import Vue from 'vue';

import VueI18n from 'vue-i18n';

import translations from '@/translations/translations';
import GAuth from '@/plugins/gAuth';

import configInterceptors from '@/shared/interceptors';

import AlertSnackBar from '@/infrastructure/shared/AlertSnackBar';
import Loading from '@/infrastructure/shared/Loading';

import Tracker from '@/infrastructure/analytics/tracker/Tracker';
import TrackerRepositoryApi from '@/infrastructure/analytics/tracker/TrackerRepositoryApi';
import BrowserInfo from '@/infrastructure/analytics/tracker/misc/BrowserInfo';

// @ts-ignore
import vueIntercom from 'vue-intercom';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/global.scss';

configInterceptors();

Vue.use(VueI18n);
Vue.use(vueIntercom, { appId: process.env.VUE_APP_FETURE_INTERCOM_APP_ID });

const i18n = new VueI18n({
  locale: 'en',
  messages: translations,
  fallbackLocale: 'en',
});

Vue.use(new Tracker(
  store,
  new TrackerRepositoryApi(),
  new BrowserInfo(),
));

Vue.use(new AlertSnackBar(
  store,
));

Vue.use(new Loading(
  store,
));

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
};

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();
Vue.use(GAuth, gauthOption);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

export default i18n;
