import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import Home from '@/views/Home.vue';
import Settings from '@/views/Settings.vue';
import ClientUsers from '@/views/ClientUsers.vue';
import ProcessingActivities from '@/views/ProcessingActivities.vue';
import DataMap from '@/views/DataMap.vue';
import LegalDocuments from '@/views/LegalDocuments.vue';
import Risks from '@/views/Risks.vue';
import Registers from '@/views/Registers.vue';
import DataBreach from '@/views/DataBreach.vue';
import ClientFolder from '@/views/ClientFolder.vue';
import TreatmentManagers from '@/views/TreatmentManagers.vue';
import Training from '@/views/Training.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import ActivateClientUser from '@/views/ActivateClientUser.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import LegalNotice from '@/views/LegalNotice.vue';
import Cookies from '@/views/Cookies.vue';
import LoginCentralized from '@/services/login/LoginCentralized';
// eslint-disable-next-line import/no-cycle
import AuthorizationRoute from '@/router/AuthorizationRoute';
import OktaSSO from '@/views/OktaSSO.vue';
import HistoricRoute from '@/router/HistoricRoute';
import UserRegister from '@/views/UserRegister.vue';
import UserValidated from '@/views/UserValidated.vue';
import Tom from '@/views/Tom.vue';

const authorizationRoute = new AuthorizationRoute();

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.login',
      hasToExclude: true,
    },
    props: (route) => ({
      pudk: route.query.pudk,
    }),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.forgot_password',
      hasToExclude: true,
    },
  },
  {
    path: '/new-password',
    props: (route) => ({ token: route.query.token, language: route.query.language }),
    name: 'change-password',
    component: ChangePassword,
    alias: '/userdesk/new_password',
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.change_password',
      hasToExclude: true,
    },
  },
  {
    path: '/activate',
    props: (route) => ({ token: route.query.token, language: route.query.language }),
    name: 'activate-client-user',
    component: ActivateClientUser,
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.activate_client_user',
      hasToExclude: true,
    },
  },
  {
    path: '/user-register',
    name: 'user-register',
    component: UserRegister,
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.user_register',
    },
  },
  {
    path: '/user-validated',
    props: (route) => ({ token: route.query.token, language: route.query.language }),
    name: 'user-validated',
    component: UserValidated,
    meta: {
      layout: LoginCentralized.getLayoutName(),
      title: 'titlePage.user_validated',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      layout: 'legal-layout',
      title: 'titlePage.privacy_policy',
    },
  },
  {
    path: '/legal-notice',
    name: 'legal-notice',
    component: LegalNotice,
    meta: {
      layout: 'legal-layout',
      title: 'titlePage.legal_notice',
    },
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies,
    meta: {
      layout: 'legal-layout',
      title: 'titlePage.cookies',
    },
  },
  {
    path: '/processing-activities',
    name: 'processing-activities',
    component: ProcessingActivities,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.processing_activities',
    },
    props: (route) => ({
      pudk: route.query.pudk,
    }),
  },
  {
    path: '/processing-activities/data-map',
    name: 'data-map',
    component: DataMap,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.data_map',
    },
  },
  {
    path: '/legal-documents',
    name: 'legal-documents',
    component: LegalDocuments,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.legal_documents',
    },
  },
  {
    path: '/risks',
    name: 'risks',
    component: Risks,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.risks',
    },
  },
  {
    path: '/registers',
    name: 'registers',
    component: Registers,
    props: true,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.registers',
    },
  },
  {
    path: '/data-breach',
    name: 'data-breach',
    component: DataBreach,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.data_breach',
    },
  },
  {
    path: '/data-breach/notify/:dataBreachId?/:cryptId?',
    name: 'data-breach-notify',
    component: DataBreach,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.data_breach',
    },
    props: (route) => ({
      dataBreachId: route.params.dataBreachId || null,
      cryptId: route.params.cryptId || null,
    }),
  },
  {
    path: '/data-breach/historic/:dataBreachId?',
    name: 'data-breach-historic',
    component: DataBreach,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.data_breach',
    },
    props: (route) => ({
      dataBreachId: route.params.dataBreachId || null,
    }),
  },
  {
    path: '/tom',
    name: 'tom',
    component: Tom,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.toms',
    },
  },
  {
    path: '/tom/library/:tomId?',
    name: 'tom-library',
    component: Tom,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.toms',
    },
    props: (route) => ({
      tomId: route.params.tomId || null,
    }),
  },
  {
    path: '/tom/implemented/:tomId?',
    name: 'tom-implemented',
    component: Tom,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.toms',
    },
    props: (route) => ({
      tomId: route.params.tomId || null,
    }),
  },
  {
    path: '/tom/pending-to-implement/:tomId?',
    name: 'tom-pending-to-implement',
    component: Tom,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.toms',
    },
    props: (route) => ({
      tomId: route.params.tomId || null,
    }),
  },
  {
    path: '/client-folder',
    name: 'client-folder',
    component: ClientFolder,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.client_folder',
    },
  },
  {
    path: '/treatment-managers',
    name: 'treatment-managers',
    component: TreatmentManagers,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.treatment_managers',
    },
  },
  {
    path: '/training/:cryptId?',
    name: 'training',
    component: Training,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.training',
    },
    props: (route) => ({
      cryptId: route.params.cryptId || null,
    }),
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.home',
      hasToExclude: true,
    },
    props: (route) => ({
      pudk: route.query.pudk,
    }),
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.settings',
    },
  },
  {
    path: '/settings/personal',
    name: 'settings-personal',
    component: Settings,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.settings',
    },
  },
  {
    path: '/settings/departments',
    name: 'settings-departments',
    component: Settings,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.settings',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: ClientUsers,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.users',
    },
  },
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      layout: 'default-layout',
      title: 'titlePage.page_not_found',
    },
  },
  {
    path: '/sso/okta/login',
    name: 'sso-okta-login',
    component: OktaSSO,
    meta: {
      layout: 'sso-layout',
      title: '',
      hasToExclude: true,
    },
    props: (route) => ({
      iss: route.query.iss,
    }),
  },
  {
    path: '/sso/okta/:oktaId/callback',
    name: 'sso-okta-callback',
    component: OktaSSO,
    meta: {
      layout: 'sso-layout',
      title: '',
      hasToExclude: true,
    },
    props: (route) => ({
      code: route.query.code,
      oktaId: route.params.oktaId,
    }),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  HistoricRoute.addRoute(from, to);

  const hasAuthorization = await authorizationRoute.hasAuthorization(to);

  if (!hasAuthorization) {
    next({
      path: authorizationRoute.getRedirectionPath(),
      query: authorizationRoute.getParams() || { pudk: to.query.pudk, clientId: to.query.clientId },
    });

    return;
  }

  next();
});

export default router;
