import ClientId from '@/domain/client/ClientId';
import HeadquartersRepresentation from './HeadquartersRepresentation';

export default (
  headquarterRepository,
  translator,
  normalization,
) => async (clientId) => {
  try {
    const headquarters = await headquarterRepository.ofClient(ClientId.fromInt(clientId));

    return {
      success: true,
      headquarters: headquarters.map(
        (headquarter) => headquarter.representedAs(
          new HeadquartersRepresentation(translator, normalization),
        ),
      ),
    };
  } catch (e) {
    return { success: false };
  }
};
