import TrainingRepository from '@/domain/training/TrainingRepository';
import ClientId from '@/domain/client/ClientId';

export default class RequestTraining {
  private readonly trainingRepository: TrainingRepository;

  constructor(trainingRepository: TrainingRepository) {
    this.trainingRepository = trainingRepository;
  }

  public async invoke(clientId: number) {
    try {
      await this.trainingRepository.request(ClientId.fromInt(clientId));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
