export default class RecipientWasDeleted {
  _recipientId;

  _ocurredOn;

  constructor(recipientId) {
    this._recipientId = recipientId;
    this._ocurredOn = new Date();
  }

  getRecipientId() {
    return this._recipientId.toString();
  }
}
