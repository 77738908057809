import TaskIdValidationException from '@/domain/task/exceptions/TaskIdValidationException';

export default class TaskId {
  id;

  constructor(id) {
    TaskId.isValid(id);
    this.id = id;
  }

  static isValid(id) {
    if (!id) {
      throw new TaskIdValidationException('Task id should not be empty');
    }
  }

  isEquals(id) {
    return this.id === id;
  }
}
