<template>
  <div>
    <div
      class="task-alert__header"
    >
      <p @click="showTask()">
        <v-icon>
          contact_support
        </v-icon>
        {{ task.title }}
      </p>
      <v-icon
        class="task-alert__header-icon-show"
        :class="{'active': task.show}"
        @click="showOrHide()"
      >
        add_circle_outline
      </v-icon>
    </div>
    <v-expand-transition>
      <div
        v-show="task.show"
        class="task-alert__content"
      >
        <p class="task-alert__content-expiration">
          <strong>
            {{ $t('tasks.answer_before_label') }} {{ task.expirationDate }}
          </strong>
        </p>
        <div class="task-alert__content-petition">
          <p>
            "{{ task.requestPetition }}"
          </p>
        </div>
        <textarea-field
          :value.sync="response"
          :label="$t('tasks.introduce_response')"
          :counter="500"
        />
        <v-row justify="center" align="center">
          <v-col cols="6" md="6" class="text-left">
            <change-department-btn
              v-if="user.isAdmin || user.isSupervisor"
              :task.sync="task"
            />
          </v-col>
          <v-col cols="6" md="6" class="text-right">
            <v-btn
              rounded
              color="primary"
              :disabled="isDisabled()"
              @click="$emit('save', task, response)"
            >
              {{ $t('tasks.send_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextareaField from '@/components/base/form/TextareaField.vue';
import ChangeDepartmentBtn from
  '@/components/modules/homePage/taskSection/ChangeDepartmentButton.vue';

export default {
  name: 'question-task',
  inject: {
    user: 'user',
  },
  components: {
    TextareaField,
    ChangeDepartmentBtn,
  },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      response: '',
    };
  },
  methods: {
    isDisabled() {
      return this.response.length < 1 || this.response.length > 500;
    },
    hideTasksAndShowOne(taskId) {
      this.response = '';
      this.task.show = this.task.taskId === taskId;
    },
    showTask() {
      this.$eventHub.$emit('hideTasksAndShowOne', this.task.taskId);
    },
    showOrHide() {
      if (this.task.show) {
        this.task.show = !this.task.show;
        this.response = '';
        return;
      }
      this.$eventHub.$emit('hideTasksAndShowOne', this.task.taskId);
    },
  },
  beforeMount() {
    this.$eventHub.$on('hideTasksAndShowOne', this.hideTasksAndShowOne);
  },
};
</script>
