import AddDataBreachCollaboratorCommandHandler
  from '@/application/dataBreach/addDataBreachCollaborator/AddDataBreachCollaboratorCommandHandler';
import DataBreachRepositoryApi from '@/infrastructure/dataBreach/DataBreachRepositoryApi';
import eventBus from '@/infrastructure/eventBus/EventBus';

export default {};

export const addDataBreachCollaborator = new AddDataBreachCollaboratorCommandHandler(
  new DataBreachRepositoryApi(), eventBus,
);
