import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import ClientUserLastLogin from '@/domain/clientUser/ClientUserLastLogin';
import Language from '@/domain/language/Language';
import ClientUserPassword from '@/domain/clientUser/ClientUserPassword';
import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';
import ClientUserToUpdate from '@/domain/clientUser/ClientUserToUpdate';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ClientUser {
  private readonly email: ClientUserEmail;

  private id!: ClientUserId | null;

  private validated = false;

  private readonly name: ClientUserName;

  private readonly surname: ClientUserSurname;

  private type: ClientUserType;

  private departments: DepartmentId[];

  private lastLogin!: ClientUserLastLogin | null;

  private readonly language: Language;

  private password: ClientUserPassword | null = null;

  constructor(
    email: ClientUserEmail,
    name: ClientUserName,
    surname: ClientUserSurname,
    type: ClientUserType,
    departments: DepartmentId[],
    language: Language,
  ) {
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.type = type;
    this.departments = departments;
    this.language = language;
  }

  addId(id: ClientUserId): void {
    this.id = id;
  }

  addLastLogin(lastLogin: ClientUserLastLogin): void {
    this.lastLogin = lastLogin;
  }

  addPassword(password: ClientUserPassword): void {
    this.password = password;
  }

  addValidated(validated: boolean): void {
    this.validated = validated;
  }

  public getId(): ClientUserId {
    return this.id!;
  }

  public representedAs(
    clientUserRepresentation: ClientUserRepresentation,
  ): ClientUserRepresentation {
    clientUserRepresentation.setEmail(this.email);
    clientUserRepresentation.setName(this.name);
    clientUserRepresentation.setSurname(this.surname);
    clientUserRepresentation.setType(this.type);
    clientUserRepresentation.setDepartments(this.departments);
    clientUserRepresentation.setLanguage(this.language);
    clientUserRepresentation.setId(this.id);
    clientUserRepresentation.setLastLogin(this.lastLogin);
    clientUserRepresentation.setPassword(this.password);
    clientUserRepresentation.setValidated(this.validated);

    return clientUserRepresentation;
  }

  public update(clientUserToUpdate: ClientUserToUpdate) {
    this.type = clientUserToUpdate.type;
    this.departments = clientUserToUpdate.departments;
  }

  public getDepartments(): DepartmentId[] {
    return this.departments;
  }
}
