const showLoadingPage = ({ commit }) => {
  commit('SHOW_LOADING_PAGE');
};
const hideLoadingPage = ({ commit }) => {
  commit('RESET_LOADING_PAGE');
};

const showSnackBar = ({ commit }, alert) => {
  commit('SHOW_SNACK_BAR', alert);
};
const hideSnackBar = ({ commit }) => {
  commit('HIDE_SNACK_BAR');
};

export default {
  showLoadingPage,
  hideLoadingPage,
  showSnackBar,
  hideSnackBar,
};
