import DepartmentWriteModel from '@/domain/department/listDepartment/DepartmentWriteModel';
import ListDepartmentCache from '@/infrastructure/department/listDepartment/ListDepartmentCache';
import DepartmentProjection from '@/domain/department/listDepartment/DepartmentProjection';

export default class DepartmentWriteModelApi implements DepartmentWriteModel {
  public reset(): void {
    ListDepartmentCache.reset();
  }

  public save(department: DepartmentProjection): void {
    ListDepartmentCache.save(department);
  }
}
