import TrainingUserRepository from '@/domain/training/user/TrainingUserRepository';
import TrainingUser from '@/domain/training/user/TrainingUser';
import TrainingUserId from '@/domain/training/user/TrainingUserId';
import Language from '@/domain/language/Language';
import ClientId from '@/domain/client/ClientId';

export default class CreateTrainingUser {
  private trainingUserRepository: TrainingUserRepository;

  constructor(trainingUserRepository: TrainingUserRepository) {
    this.trainingUserRepository = trainingUserRepository;
  }

  async invoke(language: string, clientId: number) {
    try {
      const trainingUser = new TrainingUser(TrainingUserId.generate(), new Language(language));

      await this.trainingUserRepository.save(trainingUser, ClientId.fromInt(clientId));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
