import HeadquarterId from '@/domain/headquarter/HeadquarterId';
import HeadquarterSecurityMeasures
  from '@/domain/headquarter/headquarterSecurityMeasures/HeadquarterSecurityMeasures';
import HeadquarterToUpdateRepresentation
  from '@/domain/headquarter/HeadquarterToUpdateRepresentation';
import HeadquarterName from '@/domain/headquarter/HeadquarterName';
import HeadquarterAddress from '@/domain/headquarter/HeadquarterAddress';
import HeadquarterLocality from '@/domain/headquarter/HeadquarterLocality';
import CountryCode from '@/domain/country/CountryCode';

function buildHeadquarterToUpdateRepresentation(headquarterForm) {
  const headquarterToUpdateRepresentation = new HeadquarterToUpdateRepresentation();

  headquarterToUpdateRepresentation.setName(
    HeadquarterName.fromString(headquarterForm.name),
  );
  headquarterToUpdateRepresentation.setAddress(
    HeadquarterAddress.fromString(headquarterForm.address),
  );
  headquarterToUpdateRepresentation.setLocality(
    HeadquarterLocality.fromString(headquarterForm.locality),
  );
  headquarterToUpdateRepresentation.setCountry(
    new CountryCode(headquarterForm.countryCode),
  );

  if (headquarterForm.securityMeasures) {
    headquarterToUpdateRepresentation.setSecurityMeasures(
      headquarterForm.securityMeasures.map(
        (securityMeasure) => HeadquarterSecurityMeasures.fromKey(securityMeasure),
      ),
    );
  }

  return headquarterToUpdateRepresentation;
}

export default (
  headquarterRepository,
) => async (headquarterForm, headquarterId) => {
  try {
    const headquarterToUpdateId = HeadquarterId.fromString(headquarterId);
    const headquarter = await headquarterRepository.retrieve(headquarterToUpdateId);
    const headquarterTodUpdateRepresentation = buildHeadquarterToUpdateRepresentation(
      headquarterForm,
    );

    headquarter.update(headquarterTodUpdateRepresentation);
    await headquarterRepository.update(headquarter);

    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
