import FetchDataBreachesPending
  from '@/application/dataBreach/pending/fetchDataBreachesPending/FetchDataBreachesPending';
import DataBreachesPendingReadModelApi
  from '@/infrastructure/dataBreach/pending/DataBreachesPendingReadModelApi';
import ResetDataBreachesPending
  from '@/application/dataBreach/pending/resetDataBreachesPending/ResetDataBreachesPending';
import DataBreachesPendingWriteModelApi
  from '@/infrastructure/dataBreach/pending/DataBreachesPendingWriteModelApi';

export default {
  resetDataBreaches: new ResetDataBreachesPending(new DataBreachesPendingWriteModelApi()),
  fetchDataBreachesPending: new FetchDataBreachesPending(new DataBreachesPendingReadModelApi()),
};
