import RecipientWarrantiesValidationException
  from '@/domain/recipient/recipientWarranties/RecipientWarrantiesValidationException';

export default class RecipientWarranty {
  _warranty;

  static VALID_WARRANTIES = [
    'adequacyDecision',
    'bcrs',
    'appropriateSafeguards',
    'specificSituationExemption',
    'euData',
    'noTransfers',
    'sccsTransfers',
  ];

  constructor(warranty) {
    this.isValid(warranty);
    this._warranty = warranty;
  }

  isValid(warranty) {
    if (!RecipientWarranty.VALID_WARRANTIES.includes(warranty)) {
      throw new RecipientWarrantiesValidationException('Recipient warranty does not exist');
    }
  }

  static all() {
    return this.VALID_WARRANTIES.map((warranty) => new this(warranty));
  }

  representedAs(recipientWarrantiesRepresentation) {
    recipientWarrantiesRepresentation.setKey(this._warranty);
    return recipientWarrantiesRepresentation;
  }

  toString() {
    return this._warranty;
  }
}
