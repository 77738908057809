import ProcessingActivityReadModel
  from '@/domain/recipient/processingActivity/ProcessingActivityReadModel';
import { getManagement } from '@/shared/restActions';
import ProcessingActivitiesRepresentation
  from '@/application/recipient/processingActivity/fetchProcessingActivities/ProcessingActivitiesRepresentation';

export default class ProcessingActivityReadModelApi extends ProcessingActivityReadModel {
  _translator;

  _normalizationService;

  _processingDates;

  constructor(translator, normalizationService, processingDates) {
    super();
    this._translator = translator;
    this._normalizationService = normalizationService;
    this._processingDates = processingDates;
  }

  async ofRecipient(recipientId) {
    try {
      const processingActivitiesRaw = await this._getProcessingActivitiesFromApi(
        recipientId.toString(),
      );
      const processingActivitiesRepresentation = this._buildProcessingActivitiesRepresentation(
        processingActivitiesRaw,
      );

      return processingActivitiesRepresentation;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching processing activities of recipient');
    }
  }

  async _getProcessingActivitiesFromApi(recipientId) {
    const response = await getManagement(`/api/v2/recipients/${recipientId}/processing-activities`);
    return response.data;
  }

  _buildProcessingActivitiesRepresentation(processingActivitiesRaw) {
    const processingActivitiesRepresentation = [];

    processingActivitiesRaw.forEach((processingActivity) => {
      const processingActivityRepresentation = new ProcessingActivitiesRepresentation(
        this._translator,
        this._normalizationService,
        this._processingDates,
      );

      processingActivityRepresentation.setId(processingActivity.id);
      processingActivityRepresentation.setName(processingActivity.name);
      processingActivityRepresentation.setLanguage(processingActivity.language);
      processingActivityRepresentation.setCountryId(processingActivity.country_id);
      processingActivityRepresentation.setStatus(processingActivity.status);
      processingActivityRepresentation.setLastModification(processingActivity.last_modification);

      processingActivitiesRepresentation.push(processingActivityRepresentation);
    });

    return processingActivitiesRepresentation;
  }
}
