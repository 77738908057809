import AbstractHeadquarterRepresentation from './AbstractHeadquarterRepresentation';

export default class HeadquarterToUpdateRepresentation extends AbstractHeadquarterRepresentation {
  get name() {
    return this._name;
  }

  get address() {
    return this._address;
  }

  get country() {
    return this._country;
  }

  get locality() {
    return this._locality;
  }

  get securityMeasures() {
    return this._securityMeasures;
  }
}
