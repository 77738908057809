import ClientUserWasDeletedEventListenerTrainingEmployee
  from '@/application/training/employee/ClientUserWasDeletedEventListener/ClientUserWasDeletedEventListener';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';
import EventListener from '@/application/eventBus/EventListener';
import ClientUserWasDeletedEventListenerDeleteClientUser
  from '@/application/clientUser/fetchClientUsers/ClientUserWasDeletedEventListener';
import ClientUserWriteModelApi from '@/infrastructure/clientUser/ClientUserWriteModelApi';

const listenersContext: {[key: string]: EventListener} = {
  TrainingEmployee: new ClientUserWasDeletedEventListenerTrainingEmployee(
    new TrainingEmployeeRepositoryApi(),
  ),
  DeleteClientUser: new ClientUserWasDeletedEventListenerDeleteClientUser(
    new ClientUserWriteModelApi(),
  ),
};

export default class ClientUserWasDeletedEventListenerBuilder {
  static build(context: string) {
    return listenersContext[context];
  }
}
