import axios from 'axios';
import userCase from '@/usecases/users';
import store from '@/store';
import LoginCentralized from '@/services/login/LoginCentralized';

export default function configInterceptors() {
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('user-token');
      const subdomain = LoginCentralized.hasLoginCentralizedUrl() ? store.getters['admin/subdomain'] : window.location.hostname.split('.')[0];
      const newConfig = config;

      if (token && !config.headers.Authorization) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }

      if (subdomain && !config.headers.common['userdesk-domain']) {
        newConfig.headers.common['userdesk-domain'] = subdomain;
      }

      const userLanguage = store.getters['user/language'];
      if (userLanguage !== '') {
        newConfig.headers.common.language = userLanguage;
      }

      return newConfig;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        userCase.logout();
        window.location = '/login';
      }

      return Promise.reject(error);
    },
  );
}
