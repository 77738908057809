import StringValueObject from '../shared/strings/StringValueObject';

export default class EquipmentName extends StringValueObject {
  name;

  constructor(name) {
    super(name, null, 450);
    this.name = name;
  }
}
