<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items.sync="roles"
    :label="$t('recipient.form.role')"
    :tooltip="tooltip"
    item-value="key"
    :attach="true"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'role-recipient-selector',
  components: {
    SelectAutocompleteField,
  },
  inject: {
    fetchRecipientRoles: 'fetchRecipientRoles',
  },
  props: {
    roleSelected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      roles: [],
      value: this.roleSelected,
      tooltip: {
        icon: this.$t('form.advanced_field.icon'),
        text: this.$t('form.advanced_field.text'),
      },
    };
  },
  methods: {
    getRecipientRoles() {
      this.roles = this.fetchRecipientRoles();
    },
  },
  beforeMount() {
    this.getRecipientRoles();
  },
  watch: {
    roleSelected() {
      this.value = this.roleSelected;
    },
    value() {
      this.$emit('update:roleSelected', this.value);
    },
  },
};
</script>
