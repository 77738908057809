import StringException from '@/domain/shared/strings/StringException';

const MAX_LENGTH = 200;
const MIN_LENGTH = 3;

export default class StringValueObject {
  private readonly string: string;

  public min: number = MIN_LENGTH;

  public max: number = MAX_LENGTH;

  constructor(string: string, min: number | null = null, max: number | null = null) {
    this.min = min !== null ? min : this.min;
    this.max = max || this.max;

    const stringTrimmed = string.trim();
    this.isValidOrFail(stringTrimmed);
    this.string = stringTrimmed;
  }

  private isValidOrFail(string: string): void {
    if (string.length < this.min) {
      throw new StringException(`${this.constructor.name} should have more than ${this.min} characters`);
    }

    if (string.length > this.max) {
      throw new StringException(`${this.constructor.name} should have less than ${this.max} characters`);
    }
  }

  public toString(): string {
    return this.string;
  }

  static fromString(string: string, min: number | null = null, max: number | null = null):
    StringValueObject {
    return new this(string, min, max);
  }
}
