import TrainingUserRepository from '@/domain/training/user/TrainingUserRepository';
import FetchInscriptionsQuery
  from '@/application/training/user/fethcInscriptions/FetchInscriptionsQuery';
import ClientId from '@/domain/client/ClientId';
import Inscription from '@/domain/training/inscription/Inscription';
import InscriptionsRepresentation
  from '@/application/training/user/fethcInscriptions/InscriptionsRepresentation';

export default class FetchInscriptionsQueryHandler {
  private readonly trainingUserRepository: TrainingUserRepository;

  constructor(trainingUserRepository: TrainingUserRepository) {
    this.trainingUserRepository = trainingUserRepository;
  }

  public async invoke(query: FetchInscriptionsQuery): Promise<any> {
    try {
      const inscriptions: Inscription[] = await this.trainingUserRepository
        .ofClient(ClientId.fromInt(query.clientId));

      return {
        success: true,
        inscriptions: inscriptions
          .map((inscription: Inscription) => inscription
            .representedAs(new InscriptionsRepresentation())),
      };
    } catch (e) {
      return { success: false, error: e.response?.data?.data || '' };
    }
  }
}
