import ClientUserWasDeleted from '@/domain/clientUser/ClientUserWasDeleted';
import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';
import EventListener from '@/application/eventBus/EventListener';

export default class ClientUserWasDeletedEventListener implements EventListener {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public handle(clientWasDeleted: ClientUserWasDeleted): void {
    this.trainingEmployeeRepository.reset();
  }
}
