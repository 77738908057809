export default class CreateClientUserCommand {
  public readonly clientId: number;

  public readonly name: string;

  public readonly surname: string;

  public readonly emailInit: string;

  public readonly emailRepeat: string;

  public readonly locale: string;

  public readonly departments: string[];

  public readonly type: string;

  constructor(
    clientId: number,
    type: string,
    name: string,
    surname: string,
    email_init: string,
    email_repeat: string,
    locale: string,
    departments: string[],
  ) {
    this.clientId = clientId;
    this.name = name;
    this.surname = surname;
    this.emailInit = email_init;
    this.emailRepeat = email_repeat;
    this.locale = locale;
    this.departments = departments;
    this.type = type;
  }
}
