export default {
  userExist(state) {
    return !!state.language;
  },
  language(state) {
    return state.language;
  },
  availableLanguages(state) {
    return state.availableLanguages;
  },
  isRequested(state) {
    return state.status === 'requested' || (!state.availableLanguages.length && state.status === 'active');
  },
  isActivated(state) {
    return !!state.availableLanguages.length && state.status === 'active';
  },
  trainingEmployees(state) {
    return state.trainingEmployees;
  },
};
