import TaskResponseIdValidationException from '@/domain/task/exceptions/TaskResponseIdValidationException';

export default class TaskResponseId {
  id;

  constructor(id) {
    TaskResponseId.isValid(id);
    this.id = id;
  }

  static isValid(id) {
    if (!id) {
      throw new TaskResponseIdValidationException('Response id should not be empty');
    }
  }
}
