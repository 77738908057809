import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachCode from '@/domain/dataBreach/DataBreachCode';
import DataBreachOccurredOn from '@/domain/dataBreach/DataBreachOccurredOn';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachDiscoveredOn from '@/domain/dataBreach/DataBreachDiscoveredOn';
import DataBreachDescription from '@/domain/dataBreach/DataBreachDescription';
import DataBreachHowDiscovered from '@/domain/dataBreach/DataBreachHowDiscovered';
import DataBreachWasIntentional from '@/domain/dataBreach/DataBreachWasIntentional';
import DataBreachCompromisedData from '@/domain/dataBreach/DataBreachCompromisedData';
import DataBreachAffectedProfiles from '@/domain/dataBreach/DataBreachAffectedProfiles';
import DataBreachConsequences from '@/domain/dataBreach/DataBreachConsequences';
import DataBreachAffectedCountries from '@/domain/dataBreach/DataBreachAffectedCountries';
import DataBreachNumberAffected from '@/domain/dataBreach/DataBreachNumberAffected';
import DataBreachSecurityMeasuresImplemented
  from '@/domain/dataBreach/DataBreachSecurityMeasuresImplemented';
import DataBreachFutureSecurityMeasures from '@/domain/dataBreach/DataBreachFutureSecurityMeasures';
import DataBreachSecurityMeasuresProblems
  from '@/domain/dataBreach/DataBreachSecurityMeasuresProblems';
import DataBreachInformedAffectedPeople from '@/domain/dataBreach/DataBreachInformedAffectedPeople';
import DataBreachInformedAuthorities from '@/domain/dataBreach/DataBreachInformedAuthorities';
import DataBreachEvaluation from '@/domain/dataBreach/DataBreachEvaluation';
import DataBreachReportLanguage from '@/domain/dataBreach/DataBreachReportLanguage';
import DataBreachStatus from '@/domain/dataBreach/DataBreachStatus';
import DataBreachCreatedBy from '@/domain/dataBreach/DataBreachCreatedBy';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';

export default abstract class DataBreachRepresentation {
  protected _id!: DataBreachId;

  protected _createdDate!: DataBreachCreatedDate;

  protected _occurredOn!: DataBreachOccurredOn;

  protected _discoveredOn!: DataBreachDiscoveredOn;

  protected _description!: DataBreachDescription;

  protected _status!: DataBreachStatus;

  protected _howDiscovered!: DataBreachHowDiscovered | null;

  protected _wasIntentional!: DataBreachWasIntentional | null;

  protected _compromisedData!: DataBreachCompromisedData | null;

  protected _affectedProfiles!: DataBreachAffectedProfiles | null;

  protected _consequences!: DataBreachConsequences | null;

  protected _affectedCountries!: DataBreachAffectedCountries | null;

  protected _numberAffected!: DataBreachNumberAffected | null;

  protected _securityMeasuresImplemented!: DataBreachSecurityMeasuresImplemented | null;

  protected _futureSecurityMeasures!: DataBreachFutureSecurityMeasures | null;

  protected _securityMeasuresProblems!: DataBreachSecurityMeasuresProblems | null;

  protected _informedAffectedPeople!: DataBreachInformedAffectedPeople | null;

  protected _informedAuthorities!: DataBreachInformedAuthorities | null;

  protected _attachedDocuments!: DataBreachAttachedDocuments[];

  protected _attachedDocumentsToDelete!: UuidValueObject[];

  protected _code!: DataBreachCode | null;

  protected _evaluation!: DataBreachEvaluation | null;

  protected _agencyNotification!: boolean;

  protected _reportLanguage!: DataBreachReportLanguage | null;

  protected _createdBy!: DataBreachCreatedBy;

  public setId(id: DataBreachId): void {
    this._id = id;
  }

  public setCreatedDate(createdDate: DataBreachCreatedDate): void {
    this._createdDate = createdDate;
  }

  public setOccurredOn(occurredOn: DataBreachOccurredOn): void {
    this._occurredOn = occurredOn;
  }

  public setDiscoveredOn(discoveredOn: DataBreachDiscoveredOn): void {
    this._discoveredOn = discoveredOn;
  }

  public setDescription(description: DataBreachDescription): void {
    this._description = description;
  }

  public setStatus(status: DataBreachStatus): void {
    this._status = status;
  }

  public setHowDiscovered(howDiscovered: DataBreachHowDiscovered | null): void {
    this._howDiscovered = howDiscovered;
  }

  public setWasIntentional(wasIntentional: DataBreachWasIntentional | null): void {
    this._wasIntentional = wasIntentional;
  }

  public setCompromisedData(compromisedData: DataBreachCompromisedData | null): void {
    this._compromisedData = compromisedData;
  }

  public setAffectedProfiles(affectedProfiles: DataBreachAffectedProfiles | null): void {
    this._affectedProfiles = affectedProfiles;
  }

  public setConsequences(consequences: DataBreachConsequences | null): void {
    this._consequences = consequences;
  }

  public setAffectedCountries(affectedCountries: DataBreachAffectedCountries | null): void {
    this._affectedCountries = affectedCountries;
  }

  public setNumberAffected(numberAffected: DataBreachNumberAffected | null): void {
    this._numberAffected = numberAffected;
  }

  public setSecurityMeasuresImplemented(
    securityMeasuresImplemented: DataBreachSecurityMeasuresImplemented | null,
  ): void {
    this._securityMeasuresImplemented = securityMeasuresImplemented;
  }

  public setFutureSecurityMeasures(
    futureSecurityMeasures: DataBreachFutureSecurityMeasures | null,
  ): void {
    this._futureSecurityMeasures = futureSecurityMeasures;
  }

  public setSecurityMeasuresProblems(
    securityMeasuresProblems: DataBreachSecurityMeasuresProblems | null,
  ): void {
    this._securityMeasuresProblems = securityMeasuresProblems;
  }

  public setInformedAffectedPeople(
    informedAffectedPeople: DataBreachInformedAffectedPeople | null,
  ): void {
    this._informedAffectedPeople = informedAffectedPeople;
  }

  public setInformedAuthorities(informedAuthorities: DataBreachInformedAuthorities | null): void {
    this._informedAuthorities = informedAuthorities;
  }

  public setAttachedDocuments(attachedDocuments: DataBreachAttachedDocuments[]): void {
    this._attachedDocuments = attachedDocuments;
  }

  public setAttachedDocumentsToDelete(attachedDocumentsToDelete: UuidValueObject[]): void {
    this._attachedDocumentsToDelete = attachedDocumentsToDelete;
  }

  public setCode(code: DataBreachCode | null): void {
    this._code = code;
  }

  public setEvaluation(evaluation: DataBreachEvaluation | null): void {
    this._evaluation = evaluation;
  }

  public setAgencyNotification(agencyNotification: boolean): void {
    this._agencyNotification = agencyNotification;
  }

  public setReportLanguage(reportLanguage: DataBreachReportLanguage | null): void {
    this._reportLanguage = reportLanguage;
  }

  public setCreatedBy(createdBy: DataBreachCreatedBy): void {
    this._createdBy = createdBy;
  }
}
