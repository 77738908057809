import ResponseReplyValidationException from '@/domain/task/exceptions/ResponseReplyValidationException';

export default class ResponseReply {
  responseReply;

  constructor(responseReply) {
    ResponseReply.isValid(responseReply);
    this.responseReply = responseReply;
  }

  static isValidResponseTypeValue(responseReply) {
    return (typeof responseReply === 'string'
      || Array.isArray(responseReply)
      || responseReply === true);
  }

  static isValid(responseReply) {
    if (!responseReply) {
      throw new ResponseReplyValidationException('Response reply should not be empty');
    }

    if (!ResponseReply.isValidResponseTypeValue(responseReply)) {
      throw new ResponseReplyValidationException('Wrong Response Reply type');
    }
  }
}
