import DomainEvent from '@/domain/domainEvent/DomainEvent';

export default class EmployeeWasCreated extends DomainEvent {
  private readonly employeeId: string;

  protected constructor(employeeId: string) {
    super();
    this.employeeId = employeeId;
  }

  public static fromEmployeeId(employeeId: any) {
    return new this(employeeId.toString());
  }

  public get eventName(): string {
    return 'EmployeeWasCreated';
  }

  public getEmployeeId(): string {
    return this.employeeId;
  }
}
