export default {
  language(state) {
    return state.language;
  },
  userEmail(state) {
    return state.email;
  },
  isAdmin(state) {
    return state.isAdmin;
  },
  companyId(state) {
    return state.companyId;
  },
  companyName(state) {
    return state.companyName;
  },
  userName(state) {
    return state.name;
  },
  userId(state) {
    return state.id;
  },
  countryId(state) {
    return state.countryId;
  },
  departments(state) {
    return state.departments.map((dept) => dept.id);
  },
  reducedMenu(state) {
    return state.reducedMenu;
  },
  isB2b(state) {
    return state.isB2b;
  },
  roleType(state) {
    return state.type;
  },
};
