import Translator from '@/infrastructure/Translator';
import { TranslateResult } from 'vue-i18n';
import TomRepresentation from '@/domain/tom/TomRepresentation';
import TomNote from '@/domain/tom/TomNote';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';

export default class GetTomRepresentation extends TomRepresentation {
  private readonly translator: Translator;

  private listTranslation: TranslateResult | null = null;

  private nameTranslation: TranslateResult | null = null;

  private typeTranslation: TranslateResult | null = null;

  private categoryTranslation: TranslateResult | null = null;

  private statusTranslation: TranslateResult | null = null;

  private descriptionTranslation: TranslateResult | null = null;

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  get id(): string {
    return this._id.toString();
  }

  get type(): string {
    return this._type.toString();
  }

  get typeTranslated(): TranslateResult {
    if (!this.typeTranslation) {
      this.typeTranslation = this.translator.translate(`toms.table.tooltip_${this._type.toString()}`);
    }

    return this.typeTranslation;
  }

  get nameKey(): string {
    return this._name.toString();
  }

  get name(): TranslateResult {
    if (!this.nameTranslation) {
      this.nameTranslation = this.translator.translate(this._name.toString());
    }

    return this.nameTranslation;
  }

  get listKey(): string {
    return this._classification.getList().toString();
  }

  get list(): TranslateResult {
    if (!this.listTranslation) {
      this.listTranslation = this.translator.translate(
        `tom.${this.listKey}.name`,
      );
    }
    return this.listTranslation;
  }

  get categoryKey(): string {
    return this._classification.getCategory()?.toString() || '';
  }

  get category(): TranslateResult | string {
    if (this.categoryTranslation !== null) {
      return this.categoryTranslation;
    }

    this.categoryTranslation = '';

    if (this.translator.translationExist(`tom.${this.listKey}.category.${this.categoryKey}`)) {
      this.categoryTranslation = this.translator.translate(`tom.${this.listKey}.category.${this.categoryKey}`);
    }

    return this.categoryTranslation;
  }

  get referenceCode(): string {
    return this._referenceCode?.toString() || '';
  }

  get status(): string {
    return this._status.toString();
  }

  get statusTranslated(): TranslateResult {
    if (!this.statusTranslation) {
      this.statusTranslation = this.translator.translate(
        `tom.status.${this.status}`,
      );
    }
    return this.statusTranslation;
  }

  get descriptionKey(): string {
    return this._description.toString();
  }

  get description(): TranslateResult | string {
    if (this.descriptionTranslation !== null) {
      return this.descriptionTranslation;
    }
    this.descriptionTranslation = '';

    if (this.translator.translationExist(this.descriptionKey)) {
      this.descriptionTranslation = this.translator.translate(this.descriptionKey);
    }

    return this.descriptionTranslation;
  }

  get notes(): { content: string, language: string }[] {
    return this._notes!.map((note: TomNote) => note.toObject());
  }

  get attachedDocuments(): object[] {
    return this._attachedDocuments!.map((doc: TomAttachedDocument) => doc.toObject());
  }
}
