const ADD_CLIENTS = (state, clients) => {
  state.clients = clients;
};

const RESET_CLIENTS = (state) => {
  state.clients = {};
};

export default {
  ADD_CLIENTS,
  RESET_CLIENTS,
};
