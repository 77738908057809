export default class RecipientDocumentWasDeletedEventListener {
  _writeModel;

  constructor(RecipientWriteModel) {
    this._writeModel = RecipientWriteModel;
  }

  invoke(recipientDocumentWasDeleted) {
    this._writeModel.deleteOneDocumentData(recipientDocumentWasDeleted.getRecipientId());
  }
}
