export default ({
  treatmentManagersRepository,
}) => async (companyId, lang, isAdmin) => {
  try {
    const listInfo = await treatmentManagersRepository.getListDocuments(
      companyId, lang, isAdmin,
    );
    return { success: true, data: listInfo };
  } catch (e) {
    if (e === 401) {
      return { success: true, data: [] };
    }
    return { success: false };
  }
};
