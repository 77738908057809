export const initialState = {
  models: [],
  processingActivity: {},
  formStatus: {
    step: null,
    args: null,
  },
};

const state = () => ({ ...initialState });

export default state();
