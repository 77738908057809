import fetchDocumentsListBuilder from '@/usecases/documents/processingActivities/fetchDocumentsList';
import downloadDocumentBuilder from '@/usecases/documents/processingActivities/downloadDocument';
import viewDocumentBuilder from '@/usecases/documents/processingActivities/viewDocument';
import fetchDataMapBuilder from '@/usecases/documents/processingActivities/fetchDataMap';
import processingActivitiesRepository from '@/repositories/documents/processingActivities';
import documentViewer from '@/services/documents/documentViewer';
import downloadFile from '@/services/documents/downloadFile';
import dataMap from '@/services/dataMap/dataMap';

export default {
  fetchDocumentsList: fetchDocumentsListBuilder({ processingActivitiesRepository }),
  downloadDocument: downloadDocumentBuilder({ processingActivitiesRepository, downloadFile }),
  viewDocument: viewDocumentBuilder({ processingActivitiesRepository, documentViewer }),
  fetchDataMap: fetchDataMapBuilder({ processingActivitiesRepository, dataMap }),
};
