import InscriptionRepresentation
  from '@/domain/training/inscription/InscriptionRepresentation';

export default class InscriptionsRepresentation extends InscriptionRepresentation {
  get id(): string {
    return this._id.toString();
  }

  get name(): string {
    return this._course.getName().toString();
  }

  get completedDate(): string {
    return this._completedDate!.toStringDate();
  }
}
