import ImportFileContentValidationException
  from '@/domain/import/ImportFileContentValidationException';

export default class ImportFileContent {
  private readonly content: string;

  protected constructor(content: string) {
    ImportFileContent.isValidOrFail(content);
    this.content = content;
  }

  private static isValidOrFail(content: string) {
    if (!content.trim()) {
      throw new ImportFileContentValidationException();
    }
  }

  public static fromString(content: string): ImportFileContent {
    return new this(content);
  }

  public toString(): string {
    return this.content;
  }
}
