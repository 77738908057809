import AbstractEquipmentLocationsRepresentation
  from '../../../domain/equipment/equipmentLocation/AbstractEquipmentLocationsRepresentation';

export default class LocationRepresentation extends AbstractEquipmentLocationsRepresentation {
  _translator;

  _headquarters;

  _name = null;

  constructor(translator, headquarters) {
    super();
    this._translator = translator;
    this._headquarters = headquarters;
  }

  get type() {
    return this._type;
  }

  get key() {
    return this._key;
  }

  get name() {
    if (!this._name && this._isHeadquarterType) {
      this._name = this._headquarters[this._key];
    }

    if (!this._name && !this._isHeadquarterType) {
      this._name = this._translator.translate(`equipment.location.${this._key}`);
    }

    return this._name;
  }

  isHeadquarter() {
    return this._isHeadquarterType;
  }
}
