<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items.sync="locations"
    :label="`${$t('equipment.form.location')}`"
    :item-value="locationSelection"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'location-selector',
  inject: {
    user: 'user',
    fetchLocations: 'fetchLocations',
  },
  components: {
    SelectAutocompleteField,
  },
  props: {
    selectedValues: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: this.selectedValues,
      locationSelection: (item) => ({ key: item.key, type: item.type }),
      locations: [],
    };
  },
  methods: {
    async getLocations() {
      this.locations = await this.fetchLocations(this.user.clientId);
      const hasHeadquarters = this.locations.find((location) => location.isHeadquarter());
      this.$eventHub.$emit('locationsLoaded', !!hasHeadquarters);
    },
  },
  mounted() {
    this.getLocations();
  },
  watch: {
    value() {
      this.$emit('update:selectedValues', this.value);
    },
    selectedValues() {
      this.value = this.selectedValues;
    },
  },
};
</script>
