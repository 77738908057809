import storeUserRoleDepartmentsBuilder from '@/application/user/usecases/storeUserRoleDepartments';
import fetchClientLanguagesListByClientIdBuilder from '@/application/user/usecases/fetchClientLanguagesListByClientId';
import fetchUserCountryByClientIdBuilder from '@/application/user/usecases/fetchUserCountryByClientId';
import userRepository from '@/infrastructure/user/userRepository';

export default {
  storeUserRoleDepartments: storeUserRoleDepartmentsBuilder({ userRepository }),
  fetchClientLanguagesListByClientId: fetchClientLanguagesListByClientIdBuilder({ userRepository }),
  fetchUserCountryByClientId: fetchUserCountryByClientIdBuilder({ userRepository }),
};
