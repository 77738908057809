import { getManagement } from '@/shared/restActions';

export default class GoToOktaAuthUrl {
  static async invoke(iss: string): Promise<object> {
    try {
      const response = await getManagement(`/api/v2/sso/okta?iss=${encodeURIComponent(iss)}`);
      window.location.href = response.data.url;

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
