import RecipientId from '@/domain/recipient/RecipientId';

export default class FetchProcessingActivities {
  _processingActivityReadModel;

  constructor(processingActivityReadModel) {
    this._processingActivityReadModel = processingActivityReadModel;
  }

  async invoke(recipientId) {
    try {
      const processingActivities = await this._processingActivityReadModel.ofRecipient(
        RecipientId.fromString(recipientId),
      );

      return {
        success: true,
        processingActivities: processingActivities.sort(
          (a, b) => ((a.nameNormalized > b.nameNormalized) ? 1 : -1),
        ),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
