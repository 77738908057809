import AffectedDataInformation from '@/domain/dataBreach/AffectedDataInformation';
import ClientId from '@/domain/client/ClientId';
import DataBreach from '@/domain/dataBreach/DataBreach';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachBuilder from '@/domain/dataBreach/DataBreachBuilder';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachObject from '@/application/dataBreach/DataBreachObject';
import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import DataBreachWasCreated from '@/domain/dataBreach/DataBreachWasCreated';
import DataBreachWasCreatedEventListener
  from '@/application/dataBreach/dataBreachWasCreated/DataBreachWasCreatedEventListener';
import DpoAnswers from '@/domain/dataBreach/DpoAnswers';
import PreventMeasures from '@/domain/dataBreach/PreventMeasures';
import WhatHappens from '@/domain/dataBreach/WhatHappens';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';

export default class CreateDataBreach {
  private readonly dataBreachRepository: DataBreachRepository;

  private readonly dataBreachWasCreatedEventListener: DataBreachWasCreatedEventListener;

  constructor(
    dataBreachRepository: DataBreachRepository,
    dataBreachWasCreatedEventListener: DataBreachWasCreatedEventListener,
  ) {
    this.dataBreachRepository = dataBreachRepository;
    this.dataBreachWasCreatedEventListener = dataBreachWasCreatedEventListener;
  }

  public async invoke(clientId: number, dataBreach: DataBreachObject): Promise<object> {
    try {
      const dataBreachBuilder: DataBreachBuilder = new DataBreachBuilder();

      const attachedDocuments: AttachedDocumentStruct[] = this.addDocumentAttachedId(dataBreach);

      const dataBreachId: string = DataBreachId.generate().toString();
      dataBreachBuilder.withId(dataBreachId)
        .withStatus(dataBreach.status)
        .withCreatedDate(DataBreachCreatedDate.generateW3C().toString())
        .withCreatedBy(dataBreach.createdBy)
        .withWhatHappens(this.buildWhatHappens(dataBreach))
        .withAffectedDataInformation(this.buildAffectedDataInformation(dataBreach))
        .withPreventMeasures(this.buildPreventMeasure(dataBreach))
        .withAttachedDocuments(attachedDocuments)
        .withDpoAnswer(this.buildDpoAnswers(dataBreach));

      const dataBreachEntity: DataBreach = dataBreachBuilder.create();

      await this.dataBreachRepository.save(
        ClientId.fromInt(clientId),
        dataBreachEntity,
      );

      const event = new DataBreachWasCreated(dataBreachEntity);
      await this.dataBreachWasCreatedEventListener.invoke(event);

      return { success: true, dataBreachId };
    } catch (e) {
      return { success: false };
    }
  }

  private buildWhatHappens(dataBreach: DataBreachObject): WhatHappens {
    return {
      occurredOn: `${dataBreach.occurredOnDate} ${dataBreach.occurredOnTime}`,
      discoveredOn: `${dataBreach.discoveredOnDate} ${dataBreach.discoveredOnTime}`,
      description: dataBreach.description,
      howDiscovered: dataBreach.howDiscovered,
      wasIntentional: dataBreach.wasIntentional,
    };
  }

  private buildAffectedDataInformation(dataBreach: DataBreachObject): AffectedDataInformation {
    return {
      compromisedData: dataBreach.compromisedData,
      affectedProfiles: dataBreach.affectedProfiles,
      consequences: dataBreach.consequences,
      affectedCountries: dataBreach.affectedCountries,
      numberAffected: Number(dataBreach.numberAffected),
    };
  }

  private buildPreventMeasure(dataBreach: DataBreachObject): PreventMeasures {
    return {
      securityMeasuresImplemented: dataBreach.securityMeasuresImplemented,
      futureSecurityMeasures: dataBreach.futureSecurityMeasures,
      securityMeasuresProblems: dataBreach.securityMeasuresProblems,
      informedAffectedPeople: dataBreach.informedAffectedPeople,
      informedAuthorities: dataBreach.informedAuthorities,
    };
  }

  private addDocumentAttachedId(dataBreach: DataBreachObject): AttachedDocumentStruct[] {
    const attachedDocuments: AttachedDocumentStruct[] = [];

    dataBreach.attachedDocuments.forEach((document: any) => {
      Object.assign(document, { id: UuidValueObject.generate().toString() });
      attachedDocuments.push(document);
    });

    return attachedDocuments;
  }

  private buildDpoAnswers(dataBreach: DataBreachObject): DpoAnswers {
    return {
      evaluation: dataBreach.evaluation,
      agencyNotification: dataBreach.agencyNotification,
      reportLanguage: dataBreach.reportLanguage,
    };
  }
}
