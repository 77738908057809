export default ({
  userRepository,
}) => async (clientId) => {
  try {
    const response = await userRepository.getUserCountry(clientId);

    return { success: true, data: response };
  } catch (e) {
    return { success: false, data: [] };
  }
};
