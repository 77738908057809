import EmployeeRepresentation from '../../../domain/employee/EmployeeRepresentation';

export default class EmployeesRepresentation extends EmployeeRepresentation {
  private readonly normalization: any;

  public _nameNormalized: string | null = null;

  constructor(normalizationService: any) {
    super();
    this.normalization = normalizationService;
  }

  get id(): string {
    return this._id!.toString();
  }

  get name(): string {
    return `${this._name!.toString()} ${this._surname!.toString()}`;
  }

  get email(): string {
    return (this._email) ? this._email.toString() : '';
  }

  get nameNormalized(): string {
    if (this._nameNormalized === null) {
      this._nameNormalized = this.normalization.normalizeString(`${this._name!.toString()} ${this._surname!.toString()}`);
    }

    return this._nameNormalized!;
  }

  get position(): string {
    return (this._position) ? this._position.toString() : '';
  }

  get entry(): string {
    return (this._entry) ? this._entry.toStringDate() : '';
  }

  get entryToOrderBy(): string {
    return (this._entry) ? this._entry.toString() : '';
  }

  get exit(): string {
    return (this._exit) ? this._exit.toStringDate() : '';
  }

  get exitToOrderBy(): string {
    return (this._exit) ? this._exit.toString() : '';
  }

  get nif(): string {
    return (this._nif) ? this._nif.toString() : '';
  }

  get totalDocumentsData() {
    return this._documentData ? this._documentData.toInt() : 0;
  }
}
