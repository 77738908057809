import CreateDataBreach from '@/application/dataBreach/createDataBreach/CreateDataBreach';
import DataBreachObject from '@/application/dataBreach/DataBreachObject';
import DataBreachStatus from '@/domain/dataBreach/DataBreachStatus';
import DataBreachWasCompleted from '@/domain/dataBreach/DataBreachWasCompleted';
import DataBreachWasCompletedEventListener
  from '@/application/dataBreach/dataBreachWasCompleted/DataBreachWasCompletedEventListener';

export default class CreateDataBreachWithReportWrapper {
  private readonly createDataBreach: CreateDataBreach;

  private readonly dataBreachWasCompletedEventListener: DataBreachWasCompletedEventListener;

  constructor(
    createDataBreach: CreateDataBreach,
    dataBreachWasCompletedEventListener: DataBreachWasCompletedEventListener,
  ) {
    this.createDataBreach = createDataBreach;
    this.dataBreachWasCompletedEventListener = dataBreachWasCompletedEventListener;
  }

  public async invoke(clientId: number, dataBreach: DataBreachObject): Promise<object> {
    try {
      this.hasMandatoryDpoAnswer(dataBreach);

      Object.assign(dataBreach, { status: DataBreachStatus.completed().toString() });
      const response: {[key: string]: any} = await this.createDataBreach.invoke(
        clientId, dataBreach,
      );

      if (!response.success) {
        return response;
      }

      const event = new DataBreachWasCompleted(response.dataBreachId);
      await this.dataBreachWasCompletedEventListener.invoke(event);

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }

  private hasMandatoryDpoAnswer(dataBreach: DataBreachObject): void {
    if (!dataBreach.evaluation.trim() || !dataBreach.reportLanguage.trim()) {
      throw Error('No mandatory fields are filled in to generate the report');
    }
  }
}
