import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';
import DataBreachHistoricWriteModel
  from '@/domain/dataBreach/historic/DataBreachHistoricWriteModel';
import DataBreachWasCompleted from '@/domain/dataBreach/DataBreachWasCompleted';

export default class DataBreachWasCompletedEventListener {
  private readonly dataBreachesPendingWriteModel: DataBreachesPendingWriteModel;

  private readonly dataBreachesHistoricWriteModel: DataBreachHistoricWriteModel;

  constructor(
    dataBreachesPendingWriteModel: DataBreachesPendingWriteModel,
    dataBreachesHistoricWriteModel: DataBreachHistoricWriteModel,
  ) {
    this.dataBreachesPendingWriteModel = dataBreachesPendingWriteModel;
    this.dataBreachesHistoricWriteModel = dataBreachesHistoricWriteModel;
  }

  async invoke(dataBreachWasCompleted: DataBreachWasCompleted) {
    await this.dataBreachesPendingWriteModel.deleteDataBreach(
      dataBreachWasCompleted.getDataBreachId(),
    );
    await this.dataBreachesHistoricWriteModel.reset();
  }
}
