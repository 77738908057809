import ChangeSelectedClient from '@/domain/client/ChangeSelectedClient';
import Client from '@/domain/client/Client';
import ClientCacheRepresentation from '@/infrastructure/client/ClientCacheRepresentation';
import ClientCache from '@/infrastructure/client/ClientCache';
import ClientId from '@/domain/client/ClientId';

export default class ChangeSelectedClientCache implements ChangeSelectedClient {
  public invoke(client: Client): void {
    const clientCacheRepresentation: ClientCacheRepresentation = client
      .representedAs(new ClientCacheRepresentation()) as ClientCacheRepresentation;

    ClientCache.changeClientSelected(clientCacheRepresentation);
  }

  public withId(clientId: ClientId): void {
    ClientCache.changeClientSelectedWithId({ id: clientId.toInt() });
  }
}
