<template>
  <alert-fixed
    :type="typeAlert"
  >
    <div
      v-if="processingActivities.length"
      class="processing-activities__alert"
    >
      <p>{{ $t('recipient.replace.alert_message') }}</p>
      <ul>
        <li
          v-for="item in processingActivities"
          :key="item.id"
        >
          {{item.name}}
        </li>
      </ul>
    </div>
    <span
      v-else
      class="processing-activities__info"
    >
      {{ $t('recipient.replace.not_processing_activities') }}
    </span>
  </alert-fixed>
</template>

<script>
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';

export default {
  name: 'alert-processing-activities',
  inject: {
    fetchProcessingActivitiesOfRecipient: 'fetchProcessingActivitiesOfRecipient',
  },
  components: {
    AlertFixed,
  },
  props: {
    recipientToReplace: {
      type: Object,
      default: null,
    },
  },
  computed: {
    typeAlert() {
      if (!this.processingActivities.length) {
        return 'primary';
      }
      return 'warning';
    },
  },
  data() {
    return {
      processingActivities: [],
    };
  },
  methods: {
    async getProcessingActivitiesList() {
      const response = await this.fetchProcessingActivitiesOfRecipient.invoke(
        this.recipientToReplace.id,
      );
      if (!response.success) {
        return;
      }
      this.processingActivities = response.processingActivities;
    },
  },
  beforeMount() {
    this.getProcessingActivitiesList();
  },
};
</script>
