import ImportFileName from '@/domain/import/ImportFileName';
import ImportFileContent from '@/domain/import/ImportFileContent';
import ImportFileType from '@/domain/import/ImportFileType';

export default class ImportFile {
  private readonly name: ImportFileName;

  private readonly content: ImportFileContent;

  private readonly type: ImportFileType;

  constructor(name: ImportFileName, content: ImportFileContent, type: ImportFileType) {
    this.name = name;
    this.content = content;
    this.type = type;
  }

  public getType(): string {
    return this.type.toString();
  }

  public toObject(): object {
    return {
      name: this.name.toString(),
      content: this.content.toString(),
    };
  }
}
