const ADD_RECIPIENTS_LIST_REPRESENTATIONS = (state, recipientsListRepresentations) => {
  state.recipientsListRepresentations = recipientsListRepresentations;
};

const RESET_RECIPIENTS_LIST_REPRESENTATION = (state) => {
  state.recipientsListRepresentations = [];
};

export default {
  ADD_RECIPIENTS_LIST_REPRESENTATIONS,
  RESET_RECIPIENTS_LIST_REPRESENTATION,
};
