import { getManagement } from '@/shared/restActions';
import StringValueObject from '@/domain/shared/strings/StringValueObject';
import ClientUserTokenRepository from '@/domain/clientUser/ClientUserTokenRepository';

export default class ClientUserTokenRepositoryApi implements ClientUserTokenRepository {
  public async retrieveValidationToken(cryptId: StringValueObject)
    : Promise<StringValueObject | null> {
    const response = await getManagement(`/api/v2/users/${cryptId.toString()}/validation-token`);
    return response.data?.token ? StringValueObject.fromString(response.data.token) : null;
  }
}
