<template>
  <div>
    <general-table
      :headers="headers"
      :list="documentDataRecipient"
      :columns="['actions']"
    >
      <template v-slot:actions="props">
        <v-btn
          icon
          color="primary"
          class="material-icons-round"
          @click="$emit('downloadDocumentRecipient', props.item)"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="$emit('setInfoAndShowBanner', props.item)"
        >
          <v-icon> delete </v-icon>
        </v-btn>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';

export default {
  name: 'list-document-data-recipient',
  inject: {
    user: 'user',
    fetchDocumentDataRecipient: 'fetchDocumentDataRecipient',
  },
  components: {
    GeneralTable,
  },
  props: {
    recipient: {
      type: Object,
      default: null,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('recipient.document_data.table.document'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '60%',
        },
        {
          text: this.$t('recipient.document_data.table.date'),
          align: 'start',
          sortable: true,
          value: 'lastModification',
          width: '20%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '20%',
        },
      ];
    },
  },
  data() {
    return {
      documentDataRecipient: [],
    };
  },
  methods: {
    async getDocumentDataRecipient() {
      this.$showLoading();
      const response = await this.fetchDocumentDataRecipient(this.recipient.id, this.user.clientId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('recipient.document_data.table.error');
        return;
      }

      this.documentDataRecipient = response.documents;
    },
  },
  beforeMount() {
    this.getDocumentDataRecipient();
  },
  mounted() {
    this.$eventHub.$on('documentDataRecipientWasDeleted', this.getDocumentDataRecipient);
  },
  beforeDestroy() {
    this.$eventHub.$off('documentDataRecipientWasDeleted', this.getDocumentDataRecipient);
  },
};
</script>
