import EmployeeId from '@/domain/employee/EmployeeId';
import ClientId from '@/domain/client/ClientId';
import EmployeeName from '@/domain/employee/EmployeeName';
import EmployeeSurname from '@/domain/employee/EmployeeSurname';
import EmployeeEmail from '@/domain/employee/EmployeeEmail';
import EmployeePosition from '@/domain/employee/EmployeePosition';
import EmployeeEntryDate from '@/domain/employee/EmployeeEntryDate';
import EmployeeExitDate from '@/domain/employee/EmployeeExitDate';
import EmployeeNif from '@/domain/employee/EmployeeNif';
import EmployeeDocumentData from '@/domain/employee/EmployeeDocumentData';
import Employee from '@/domain/employee/Employee';
import EmployeeBuilderValidationException
  from '@/domain/employee/EmployeeBuilderValidationException';

export default class EmployeeBuilder {
  private id: EmployeeId | null = null;

  private clientId: ClientId | null = null;

  private name: EmployeeName | null = null;

  private surname: EmployeeSurname | null = null;

  private email: EmployeeEmail | null = null;

  private position: EmployeePosition | null = null;

  private entry: EmployeeEntryDate | null = null;

  private exit: EmployeeExitDate | null = null;

  private nif: EmployeeNif | null = null;

  private documentData: EmployeeDocumentData = EmployeeDocumentData.fromInt(0);

  withId(id: string): this {
    this.id = EmployeeId.fromString(id);
    return this;
  }

  withClientId(clientId: string): this {
    this.clientId = ClientId.fromString(clientId);
    return this;
  }

  withName(name: string): this {
    this.name = new EmployeeName(name);
    return this;
  }

  withSurname(surname: string): this {
    this.surname = new EmployeeSurname(surname);
    return this;
  }

  withEmail(email: string | null): this {
    if (email) {
      this.email = EmployeeEmail.fromString(email);
    }
    return this;
  }

  withPosition(position: string | null): this {
    if (position) {
      this.position = new EmployeePosition(position);
    }
    return this;
  }

  withEntry(entry: string | null): this {
    if (entry) {
      this.entry = new EmployeeEntryDate(entry);
    }
    return this;
  }

  withExit(exit: string | null): this {
    if (exit) {
      this.exit = new EmployeeExitDate(exit);
    }
    return this;
  }

  withNif(nif: string | null): this {
    if (nif) {
      this.nif = new EmployeeNif(nif);
    }
    return this;
  }

  withDocumentData(documentData: number | null): this {
    if (documentData) {
      this.documentData = EmployeeDocumentData.fromInt(documentData);
    }
    return this;
  }

  reset() {
    this.id = null;
    this.clientId = null;
    this.name = null;
    this.surname = null;
    this.position = null;
    this.entry = null;
    this.exit = null;
    this.nif = null;
    this.documentData = EmployeeDocumentData.fromInt(0);
  }

  create(): Employee {
    this.hasMandatoryValues();

    const employee = new Employee(
      this.id!,
      this.clientId!,
      this.name!,
      this.surname!,
    );

    if (this.email) {
      employee.addEmail(this.email);
    }

    if (this.position) {
      employee.addPosition(this.position);
    }

    if (this.entry) {
      employee.addEntry(this.entry);
    }

    if (this.exit) {
      employee.addExit(this.exit);
    }

    if (this.nif) {
      employee.addNif(this.nif);
    }

    employee.addDocumentData(this.documentData);

    this.reset();

    return employee;
  }

  hasMandatoryValues(): void {
    if (!this.id) {
      throw new EmployeeBuilderValidationException('Employee must have an id');
    }

    if (!this.clientId) {
      throw new EmployeeBuilderValidationException('Employee must have a client id');
    }

    if (!this.name) {
      throw new EmployeeBuilderValidationException('Employee must have a name');
    }

    if (!this.surname) {
      throw new EmployeeBuilderValidationException('Employee must have a surname');
    }
  }
}
