export default [
  {
    id: 1,
    key: 'data_storage',
  },
  {
    id: 2,
    key: 'accounting_software',
  },
  {
    id: 3,
    key: 'cloud_computing',
  },
  {
    id: 4,
    key: 'crm_software',
  },
  {
    id: 5,
    key: 'e_commerce',
  },
  {
    id: 6,
    key: 'marketing_software',
  },
  {
    id: 7,
    key: 'email_services',
  },
  {
    id: 8,
    key: 'erp_software',
  },
  {
    id: 9,
    key: 'events_software',
  },
  {
    id: 10,
    key: 'hosting',
  },
  {
    id: 11,
    key: 'rrhh_software',
  },
  {
    id: 13,
    key: 'password_management',
  },
  {
    id: 14,
    key: 'messaging_services',
  },
  {
    id: 15,
    key: 'offices_apps',
  },
  {
    id: 16,
    key: 'paymeny_platform',
  },
  {
    id: 17,
    key: 'services_platform',
  },
  {
    id: 18,
    key: 'ordering_software',
  },
  {
    id: 28,
    key: 'tracking',
  },
  {
    id: 29,
    key: 'rrhh_department',
  },
  {
    id: 30,
    key: 'it_department',
  },
  {
    id: 31,
    key: 'legal_department',
  },
  {
    id: 32,
    key: 'marketing_department',
  },
  {
    id: 33,
    key: 'sell_department',
  },
  {
    id: 34,
    key: 'admin_department',
  },
  {
    id: 35,
    key: 'management',
  },
  {
    id: 36,
    key: 'pr_department',
  },
  {
    id: 37,
    key: 'internal_department',
  },
  {
    id: 38,
    key: 'other',
  },
  {
    id: 39,
    key: 'public_administration',
  },
  {
    id: 40,
    key: 'property_administration',
  },
  {
    id: 41,
    key: 'cloud_storage',
  },
  {
    id: 42,
    key: 'law_enforcement',
  },
  {
    id: 43,
    key: 'tax_management',
  },
  {
    id: 44,
    key: 'rrhh_management',
  },
  {
    id: 45,
    key: 'marketing_tools',
  },
  {
    id: 46,
    key: 'logistics',
  },
  {
    id: 47,
    key: 'prevention_risks',
  },
  {
    id: 48,
    key: 'security_services',
  },
  {
    id: 49,
    key: 'law_services',
  },
  {
    id: 50,
    key: 'tic_services',
  },
  {
    id: 51,
    key: 'customer_service_provider',
  },
  {
    id: 52,
    key: 'customer_support',
  },
  {
    id: 53,
    key: 'production_department',
  },
];
