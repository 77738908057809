<template>
  <v-row>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="searchRecipient"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right multiple-button">
      <download-document-button
        v-if="showDownloadButton && currentTab === 'recipient'"
        :id="user.clientId"
        errorMsg="recipient.download.error"
        :btnMsg="$t('recipient.download.button')"
        docType="recipients-list"
        actionTrack="Download recipient registry"
      />
      <create-recipient
        :openCreateRecipient="openCreateRecipient"
      />
    </v-col>
    <v-col cols="12">
      <list-recipients
        :search="searchRecipient"
      >
        <template slot-scope="props" slot="editRecipient">
          <edit-recipient :recipientToEdit="props.recipient" />
        </template>
        <template
          slot-scope="props"
          slot="replaceRecipient"
        >
          <replace-recipient
            :recipientToReplace="props.recipient" />
        </template>
        <template slot-scope="props" slot="removeRecipient">
          <remove-recipient :recipientToDelete="props.recipient" />
        </template>
      </list-recipients>
    </v-col>
  </v-row>
</template>

<script>
import recipientModelUseCases from '@/application/recipient/recipientModel';
import documentDataRecipientUseCases from '@/application/documentData/recipient';
import documentUseCases from '@/application/document';
import InputSearch from '@/components/base/form/InputSearch.vue';
import {
  resetProcessingActivityFormStatus,
  resetProcessingActivityForm,
} from '@/application/processingActivity';
import { fetchProcessingActivitiesOfRecipient } from '@/application/recipient/processingActivity';
import recipientUseCases, { fetchRecipientToReplacement, requestRecipientReplace } from '@/application/recipient';
import DownloadDocumentButton from '@/components/base/section/DownloadDocumentButton.vue';
import ListRecipients from './ListRecipients.vue';
import RemoveRecipient from './RemoveRecipient.vue';
import CreateRecipient from './CreateRecipient.vue';
import EditRecipient from './EditRecipient.vue';
import ReplaceRecipient from './ReplaceRecipient.vue';

export default {
  name: 'recipients-section',
  provide: {
    ...recipientUseCases,
    ...recipientModelUseCases,
    ...documentDataRecipientUseCases,
    ...documentUseCases,
    resetProcessingActivityFormStatus,
    resetProcessingActivityForm,
    fetchProcessingActivitiesOfRecipient,
    fetchRecipientToReplacement,
    requestRecipientReplace,
  },
  inject: {
    user: 'user',
  },
  components: {
    CreateRecipient,
    ListRecipients,
    RemoveRecipient,
    InputSearch,
    EditRecipient,
    ReplaceRecipient,
    DownloadDocumentButton,
  },
  props: {
    openCreateRecipient: {
      type: Boolean,
      default: false,
    },
    currentTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchRecipient: '',
      showDownloadButton: false,
    };
  },
  methods: {
    mustBeShowDownloadButton(recipients) {
      this.showDownloadButton = recipients.some((recipient) => recipient.isStatusValidated);
    },
  },
  beforeMount() {
    recipientUseCases.resetRecipients();
    recipientModelUseCases.resetRecipientsModelsCache();
    this.$eventHub.$on('recipientsLoaded', this.mustBeShowDownloadButton);
  },
  beforeDestroy() {
    this.$eventHub.$off('recipientsLoaded', this.mustBeShowDownloadButton);
  },
};

</script>
