function createDateFromString(date) {
  const dateSplitDate = date.split('/');
  const dateISO = `${dateSplitDate[2]}-${dateSplitDate[1]}-${dateSplitDate[0]}`;
  return new Date(dateISO);
}

function getStringDateTimeFromDate(dateIso) {
  const date = new Date(dateIso);
  return `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${date.getUTCFullYear()}
     ${String(date.getUTCHours() + 1).padStart(2, '0')}:${String(date.getUTCMinutes() + 1).padStart(2, '0')}`;
}

function sortTwoDatesTime(dateA, dateB) {
  const dateSplitA = dateA.split('/');
  const dateSplitAYearTime = dateSplitA[2].split(' ');
  const dateYMDA = `${dateSplitAYearTime[0].replace(/(\r\n|\n|\r)/gm, '')}-${dateSplitA[1]}-${dateSplitA[0]} ${dateSplitAYearTime[dateSplitAYearTime.length - 1]}:00`;
  const newDateA = new Date(dateYMDA);

  const dateSplitB = dateB.split('/');
  const dateSplitBYearTime = dateSplitB[2].split(' ');
  const dateYMDB = `${dateSplitBYearTime[0].replace(/(\r\n|\n|\r)/gm, '')}-${dateSplitB[1]}-${dateSplitB[0]} ${dateSplitBYearTime[dateSplitBYearTime.length - 1]}:00`;
  const newDateB = new Date(dateYMDB);

  return newDateA - newDateB;
}

export default {
  createDateFromString,
  getStringDateTimeFromDate,
  sortTwoDatesTime,
};
