import EmployeeId from '@/domain/employee/EmployeeId';

export default (
  getDocumentUrl,
  downloadFile,
) => async (employeeId, fileTypes) => {
  try {
    const documentUrl = await getDocumentUrl.downloadOfEmployee(
      EmployeeId.fromString(employeeId), fileTypes,
    );
    downloadFile.invoke(documentUrl);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
