<template>
    <v-container fluid>
      <v-row class="activity-step">
        <v-col cols="12" class="text-center">
          <h2>
            {{ $t('processing_activities.step_country_language.activity_country') }}
          </h2>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="12" md="4">
              <select-autocomplete-field
                :selectedItems.sync="countryLanguageToUse.country_id"
                :items.sync="countryList"
                itemValue="id"
                itemText="name"
                :label="$t('processing_activities.step_country_language.country_label')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
          <h2>
            {{ $t('processing_activities.step_country_language.activity_language') }}
          </h2>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="12" md="4">
              <select-field
                :value.sync="countryLanguageToUse.language"
                :items.sync="languageList"
                itemValue="code"
                itemText="name"
                :label="$t('processing_activities.step_country_language.language_label')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="primary"
            rounded
            v-track="'Change processing activity language or country -- finish'"
            @click="$emit('countryLanguageSelected', false)"
          >
            {{ $t('processing_activities.step_country_language.continue_button')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import SelectField from '../../../base/form/SelectField.vue';
import SelectAutocompleteField from '../../../base/form/SelectAutocompleteField.vue';

export default {
  name: 'country-language-selection',
  components: {
    SelectField,
    SelectAutocompleteField,
  },
  props: {
    countryLanguageToUse: {
      type: Object,
      default: () => {},
    },
    countryList: {
      type: Array,
      default: () => [],
    },
    languageList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
};
</script>
