<template>
    <v-row>
      <v-col cols="12">
        <title-section
          :title="$t('legalDocuments.title')"
          :subtitle="$t('legalDocuments.subtitle')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <input-search
          :value.sync="search"
          :label="$t('form.search')"
        />
      </v-col>
      <v-col cols="12">
        <filters
          v-if="filters.length > 1"
          :items.sync="filters"
          :value.sync="filtersSelected"
          section="legalDocuments"
        />
      </v-col>
      <v-col cols="12">
        <default-table
          :items.sync="documents"
          :headers="headers"
          :search.sync="search"
          :actions="['download', 'view']"
          :downloadType="['pdf', 'doc']"
          :typesDocuments.sync="filters"
          sortBy="title"
          @viewDocument="viewDocument"
          @downloadDocument="downloadLegalDocument"
          @selectExtensionShow="selectExtensionShow"
        />
      </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

import documentsUseCases from '@/usecases/documents/legalDocuments';
import uploadedDocumentUseCases from '@/usecases/documents/uploaded';

import TitleSection from '../base/section/TitleSection.vue';
import Filters from '../base/section/Filters.vue';
import InputSearch from '../base/form/InputSearch.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';

const ANOTHER_TYPE_ID = 'another';
export default {
  name: 'legal-documents-container',
  components: {
    TitleSection,
    Filters,
    InputSearch,
    DefaultTable,
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    headers() {
      return [
        {
          text: this.$t('legalDocuments.name_doc_table'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '50%',
        },
        {
          text: this.$t('legalDocuments.type_doc_table'),
          align: 'start',
          sortable: true,
          value: 'typeName',
          width: '30%',
        },
        {
          text: this.$t('legalDocuments.last_updated'),
          align: 'start',
          sortable: true,
          value: 'updated',
          width: '20%',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
    documents() {
      const documents = this.listDocuments;
      const filterDocuments = [];
      if (this.filtersSelected.length) {
        documents.forEach((document, key) => {
          if (this.filtersSelected.includes(documents[key].type)) {
            filterDocuments.push(documents[key]);
          }
        });
      }
      return filterDocuments;
    },
  },
  data() {
    return {
      listDocuments: [],
      filters: [],
      filtersSelected: [],
      search: '',
    };
  },
  methods: {
    async getLegalDocuments() {
      const response = await documentsUseCases.fetchDocumentsList(
        this.clientId, this.$i18n.locale, this.userIsAdmin,
      );

      if (response.success) {
        this.listDocuments = response.data;
        this.setDateText();
        Object.keys(this.listDocuments).forEach((key) => {
          this.listDocuments[key].typeName = this.$t(`legalDocuments.categories.${this.listDocuments[key].type}_filter`);
        });
      } else {
        this.$showError('legalDocuments.error.error_page_load');
      }
    },
    async getTypesDocuments() {
      const response = await documentsUseCases.fetchTypeOfDocument(this.listDocuments);

      if (response.success) {
        this.filters = response.data;

        this.filters.forEach((filter, key) => {
          this.filters[key].name = this.$t(`legalDocuments.categories.${filter.id}_filter`);
          this.filtersSelected.push(filter.id);
        });

        this.filters.sort((a, b) => ((a.name < b.name) ? -1 : 1));

        const indexAnother = this.filters.findIndex((x) => x.id === ANOTHER_TYPE_ID);
        if (indexAnother !== -1) {
          const filterAnother = this.filters[indexAnother];
          this.filters.splice(indexAnother, 1);
          this.filters.push(filterAnother);
        }
      } else {
        this.$showError('legalDocuments.error.error_page_load');
      }
    },
    async viewDocument(document) {
      this.$showLoading();

      const response = await documentsUseCases.viewDocument(
        document.id,
        document.type,
        this.$i18n.locale,
        document.title,
        this.clientId,
        this.userIsAdmin,
        document.adminLayoutId,
      );

      if (!response.success) {
        this.$showError('legalDocuments.error.error_view_document');
      }

      this.$hideLoading();
    },
    selectExtensionShow(document) {
      this.$eventHub.$emit('showOptionsExtensionFile', document);
    },
    async downloadLegalDocument(document, type = null) {
      this.$showLoading();

      let response = null;
      if (document.signedUrl) {
        response = await uploadedDocumentUseCases.downloadDocument(
          this.clientId, document.id, this.userIsAdmin,
        );
      } else {
        response = await documentsUseCases.downloadDocument(
          this.clientId,
          document.id,
          type,
          document.adminLayoutId,
        );
      }
      this.$hideLoading();
      this.$track({
        action: 'Download a legal document',
        result: response.success,
        document_format: type,
        legal_document_name: document.title,
      });
      if (!response.success) {
        this.$showError('legalDocuments.error.error_download_document');
      }
    },
    setDateText() {
      Object.keys(this.listDocuments).forEach((key) => {
        const date = new Date(this.listDocuments[key].modified.date);
        this.listDocuments[key].updated = date.getTime();
      });
    },
  },
  async beforeMount() {
    this.$showLoading();

    await this.getLegalDocuments();
    await this.getTypesDocuments();

    this.$eventHub.$on('downloadDocument', this.downloadLegalDocument);

    this.$hideLoading();
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadLegalDocument);
  },
};
</script>
