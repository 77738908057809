import { postManagement } from '@/shared/restActions';

export default class TrackerRepositoryApi {
  async save(data: object): Promise<void> {
    try {
      await postManagement('/api/v2/tracking', data);
    } catch (e) {
      console.error(e);
    }
  }
}
