<template>
  <general-table
    :headers="headers"
    :list="recipients"
    :search="search"
    :customFilterSearch="customFilterSearch"
    :columns="['name', 'status', 'documents', 'processingActivities', 'actions']"
  >
    <template v-slot:name="props">
      <slot
        name="editRecipient"
        :recipient="props.item"
      />
    </template>
    <template v-slot:status="props">
      <v-icon
        class="icon-status"
        :color="getColorByStatus(props.item.isStatusValidated)"
        small
      >
        circle
      </v-icon>
      {{ props.item.status }}
    </template>
    <template v-slot:documents="props">
      <document-data-recipient-modal
        v-if="props.item.totalDocuments"
        :item="props.item"
      />
    </template>
    <template v-slot:processingActivities="props">
      <processing-activities-modal
        v-if="props.item.processingActivities"
        :recipient="props.item"
      />
    </template>
    <template v-slot:actions="props">
      <slot name="replaceRecipient" :recipient="props.item" />
      <slot name="removeRecipient" :recipient="props.item" />
    </template>
  </general-table>
</template>

<script>

import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import ProcessingActivitiesModal
  from '@/components/modules/register/recipients/processingActivities/ProcessingActivitiesModal.vue';
import DocumentDataRecipientModal from './documentData/DocumentDataRecipientModal.vue';

export default {
  name: 'list-recipients',
  inject: {
    user: 'user',
    fetchRecipients: 'fetchRecipients',
  },
  components: {
    ProcessingActivitiesModal,
    GeneralTable,
    DocumentDataRecipientModal,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('recipient.table.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: this.$t('recipient.table.status'),
          align: 'start',
          sortable: true,
          value: 'status',
          width: '10%',
        },
        {
          text: this.$t('recipient.table.category'),
          align: 'start',
          sortable: true,
          value: 'category',
          width: '20%',
        },
        {
          text: this.$t('recipient.table.modification_date'),
          align: 'start',
          sortable: true,
          value: 'lastModification',
          width: '15%',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'documents',
          width: '10%',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'processingActivities',
          width: '10%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '15%',
        },
      ];
    },
  },
  data() {
    return {
      recipients: [],
      customFilterSearch: (value, search, item) => (
        item.nameNormalized.indexOf(search) !== -1
      ),
    };
  },
  methods: {
    async getRecipients() {
      this.$showLoading();
      const response = await this.fetchRecipients(this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('recipient.table.error');
        return;
      }
      this.recipients = response.recipients;
      this.$eventHub.$emit('recipientsLoaded', this.recipients);
    },
    getColorByStatus(status) {
      return status ? 'success' : 'grey lighten-1';
    },
    getRecipientsCreated() {
      this.$eventHub.$emit('passRecipientsCreated', this.recipients);
    },
  },
  beforeMount() {
    this.getRecipients();
    this.$eventHub.$on('recipientWasUpdated', this.getRecipients);
    this.$eventHub.$on('recipientWasCreated', this.getRecipients);
    this.$eventHub.$on('getRecipientsCreated', this.getRecipientsCreated);
    this.$eventHub.$on('recipientReplacementWasRequested', this.getRecipients);
    this.$eventHub.$on('recipientWasDeleted', this.getRecipients);
  },
  beforeDestroy() {
    this.$eventHub.$off('recipientWasUpdated', this.getRecipients);
    this.$eventHub.$off('recipientWasCreated', this.getRecipients);
    this.$eventHub.$off('getRecipientsCreated', this.getRecipientsCreated);
    this.$eventHub.$off('recipientReplacementWasRequested', this.getRecipients);
    this.$eventHub.$off('recipientWasDeleted', this.getRecipients);
  },
};
</script>
