export default {
  name(state) {
    return state.name;
  },
  logoUrl(state) {
    return state.logoUrl;
  },
  subdomain(state) {
    return state.subdomain;
  },
  isSpanishAdmin(state) {
    return state.isSpanishAdmin;
  },
  adminCompanyId(state) {
    return state.adminCompanyId;
  },
  countryCode(state) {
    return state.countryCode;
  },
  isB2b(state) {
    return state.isB2b;
  },
};
