<template>
  <general-table
    :headers="headers"
    :list.sync="processingActivities"
    :columns="['status', 'actions']"
  >
    <template
      v-slot:status="props"
    >
      <v-icon
        class="icon-status"
        :color="getColorByStatus(props.item)"
        small
      >
        circle
      </v-icon>
      {{ props.item.statusTranslation }}
    </template>
    <template v-slot:actions="props">
      <v-btn
        icon
        color="primary"
        class="material-icons-round"
        @click="$emit('downloadProcessingActivity', props.item)"
      >
        <v-icon>get_app</v-icon>
      </v-btn>
    </template>
  </general-table>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';

export default {
  name: 'list-processing-activities',
  inject: {
    fetchProcessingActivitiesOfRecipient: 'fetchProcessingActivitiesOfRecipient',
  },
  components: {
    GeneralTable,
  },
  props: {
    recipientId: {
      type: String,
      default: null,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('recipient.processingActivities.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: this.$t('processingActivities.status'),
          align: 'start',
          sortable: true,
          value: 'status',
          width: '15%',
        },
        {
          text: this.$t('processingActivities.language'),
          align: 'start',
          sortable: true,
          value: 'languageTranslation',
          width: '10%',
        },
        {
          text: this.$t('processingActivities.country'),
          align: 'start',
          sortable: true,
          value: 'country',
          width: '10%',
        },
        {
          text: this.$t('processingActivities.last_updated'),
          align: 'start',
          sortable: false,
          value: 'lastModificationString',
          width: '15%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '10%',
        },
      ];
    },
  },
  data() {
    return {
      processingActivities: [],
    };
  },
  methods: {
    getColorByStatus(processingActivity) {
      if (processingActivity.isStatusDraft()) {
        return 'grey';
      }

      if (processingActivity.isStatusActive()) {
        return 'success';
      }

      return 'error';
    },
    async getProcessingActivitiesList() {
      this.$showLoading();
      const response = await this.fetchProcessingActivitiesOfRecipient.invoke(
        this.recipientId,
      );

      this.$hideLoading();
      if (!response.success) {
        return;
      }
      this.processingActivities = response.processingActivities;
    },
  },
  beforeMount() {
    this.getProcessingActivitiesList();
  },
};
</script>
