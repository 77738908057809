import FetchTrainingInfo from '@/application/training/fetchTrainingInfo/FetchTrainingInfo';
import TrainingRepositoryApi from '@/infrastructure/training/TrainingRepositoryApi';
import CreateTrainingUser from '@/application/training/user/createTrainingUser/CreateTrainingUser';
import TrainingUserRepositoryApi from '@/infrastructure/training/user/TrainingUserRepositoryApi';
import GoTrainingUrl from '@/application/training/goTrainingUrl/GoTrainingUrl';
import RedirectToTrainingUrl from '@/infrastructure/training/RedirectToTrainingUrl';
import RequestTraining from '@/application/training/requestTraining/RequestTraining';
import FetchInscriptionsQueryHandler
  from '@/application/training/user/fethcInscriptions/FetchInscriptionsQueryHandler';

export default {};

export const fetchTrainingInfo = new FetchTrainingInfo(new TrainingRepositoryApi());
export const createTrainingUser = new CreateTrainingUser(new TrainingUserRepositoryApi());
export const goTrainingUrl = new GoTrainingUrl(
  new TrainingRepositoryApi(),
  new RedirectToTrainingUrl(),
);
export const requestTraining = new RequestTraining(new TrainingRepositoryApi());
export const fetchInscriptions = new FetchInscriptionsQueryHandler(new TrainingUserRepositoryApi());
