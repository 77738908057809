import AbstractEquipmentRepresentation from '@/domain/equipment/AbstractEquipmentRepresentation';

export default class EquipmentRepresentation extends AbstractEquipmentRepresentation {
  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get type() {
    return this._type.toString();
  }

  get location() {
    return (this._location) ? this._location.jsonSerialize() : null;
  }

  get securityMeasures() {
    return (this._securityMeasures) ? this._securityMeasures.map(
      (securityMeasure) => securityMeasure.toString(),
    ) : [];
  }
}
