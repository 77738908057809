import Tom from '@/domain/tom/Tom';
import TomId from '@/domain/tom/TomId';
import store from '../../store';

export default class TomCache {
  public static reset(): void {
    store.dispatch('tom/resetToms');
  }

  public static all(): Tom[] {
    return Object.values(store.getters['tom/toms']);
  }

  public static save(toms: Tom[]): void {
    const tomsList: { [key: string]: Tom } = {};
    toms.forEach((tom: Tom) => {
      tomsList[tom.getId().toString()] = tom;
    });

    store.dispatch('tom/addToms', tomsList);
  }

  public static retrieve(tomId: TomId): Tom {
    const tomCollection: { [key: string]: Tom } = store.getters['tom/toms'];
    return tomCollection[tomId.toString()];
  }

  public static update(tom: Tom): void {
    const tomCollection: { [key: string]: Tom } = store.getters['tom/toms'];
    tomCollection[tom.getId().toString()] = tom;

    store.dispatch('tom/addToms', tomCollection);
  }
}
