import DepartmentId from '@/domain/department/DepartmentId';

export default ({
  taskRepository,
}) => async (taskId, departmentId) => {
  try {
    await taskRepository.updateTaskDepartment(
      taskId,
      DepartmentId.fromString(departmentId),
    );
    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
