<template>
  <general-table
    :list.sync="toms"
    :headers="headers"
    :disableSort="true"
    :search="search"
    :customFilterSearch="customFilterSearch"
    :noDataTextKeyRoute="noDataText"
    class="tom-list"
    :columns="['type', 'status', 'actions']"
  >
    <template v-slot:type="props">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            small
            color="darkgrey"
            v-on="on"
          >
            {{ tomTypeIcon[props.item.type] }}
          </v-icon>
        </template>
        <span>
          {{ props.item.typeTranslated }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:status="props">
      <v-btn
        icon
        plain
        :ripple="false"
        class="clear-hover"
        @click="updateTomStatus(props.item, 'implemented')"
      >
        <v-icon
          class="icon-status"
          :color="getImplementedColorByStatus(props.item.status)"
        >
          check_circle
        </v-icon>
      </v-btn>
      <v-btn
        icon
        plain
        :ripple="false"
        class="clear-hover ml-0"
        @click="updateTomStatus(props.item, 'pendingToImplement')"
      >
        <v-icon
          class="icon-status"
          :color="getPendingColorByStatus(props.item.status)"
        >
          bookmark
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:actions="props">
      <edit-tom
        :tomId="props.item.id"
      />
    </template>
  </general-table>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import FetchTomsQuery from '@/application/tom/fetchToms/FetchTomsQuery';
import normalizationService from '@/services/shared/normalization';
import ChangeTomStatusCommand from '@/application/tom/changeTomStatus/ChangeTomStatusCommand';
import EditTom from '@/components/modules/tom/EditTom.vue';

const IMPLEMENTED_TOM_STATUS = 'implemented';
const PENDING_TOM_STATUS = 'pendingToImplement';
const AVAILABLE_TOM_STATUS = 'available';

export default {
  name: 'list-tom',
  inject: {
    user: 'user',
    fetchToms: 'fetchToms',
    changeTomStatus: 'changeTomStatus',
  },
  components: {
    GeneralTable,
    EditTom,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    filtersSelected: {
      type: Array,
      default: () => [],
    },
    statusFilter: {
      type: String,
      default: null,
    },
    noDataText: {
      type: String,
      default: 'table.no_data',
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('toms.table.type'),
          align: 'center',
          value: 'type',
          width: '5%',
        },
        {
          text: this.$t('toms.table.name'),
          align: 'start',
          value: 'nameTranslated',
          width: '30%',
        },
        {
          text: this.$t('toms.table.category'),
          align: 'start',
          value: 'categoryTranslated',
          width: '25%',
        },
        {
          text: this.$t('toms.table.standard'),
          align: 'start',
          value: 'listTranslated',
          width: '10%',
        },
        {
          text: this.$t('toms.table.reference_number'),
          align: 'start',
          value: 'referenceCode',
          width: '10%',
        },
        {
          text: this.$t('toms.table.status'),
          align: 'start',
          value: 'status',
          width: '15%',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          width: '5%',
        },
      ];
    },
    tomTypeIcon() {
      return {
        organisational: 'checklist_rtl',
        technical: 'build',
      };
    },
    toms() {
      let toms = [];
      if (this.filtersSelected.length) {
        toms = this.tomList.filter((tom) => this.filtersSelected.includes(tom.list));
      }
      if (this.statusFilter) {
        toms = this.tomList.filter((tom) => tom.status === this.statusFilter);
      }

      return toms;
    },
  },
  data() {
    return {
      tomList: [],
      customFilterSearch: (value, search, item) => normalizationService
        .normalizeString(item.nameTranslated).indexOf(search) !== -1
          || normalizationService.normalizeString(item.referenceCode).indexOf(search) !== -1,
    };
  },
  methods: {
    async loadTomList() {
      this.$showLoading();
      const response = await this.fetchToms.invoke(new FetchTomsQuery(this.user.clientId));
      this.$hideLoading();

      if (!response.success) {
        this.$showError('toms.error.loading_toms');
        return;
      }

      this.tomList = response.toms;
      this.$eventHub.$emit('tomListLoaded', this.tomList);
    },
    getImplementedColorByStatus(status) {
      return status === IMPLEMENTED_TOM_STATUS ? 'success' : 'grey lighten-1';
    },
    getPendingColorByStatus(status) {
      return status === PENDING_TOM_STATUS ? 'blue_pridatect' : 'grey lighten-1';
    },
    async updateTomStatus(tom, status) {
      this.$showLoading();
      const newStatus = this.getStatus(tom.status, status);
      const response = await this.changeTomStatus
        .invoke(new ChangeTomStatusCommand(tom.id, newStatus));
      this.$hideLoading();

      if (!response.success) {
        this.$showError(this.$t(`toms.error.change_status_${newStatus}`));
      }

      this.trackImplement(newStatus, tom.status, tom.name);

      this.$eventHub.$emit('tomStatusWasChanged', newStatus);
    },
    getStatus(tomStatus, statusToUpdate) {
      if (tomStatus === statusToUpdate) {
        return AVAILABLE_TOM_STATUS;
      }
      return statusToUpdate;
    },
    trackImplement(newStatus, tomStatus, tomNameKey) {
      const action = (newStatus === AVAILABLE_TOM_STATUS) ? `unmark tom as ${tomStatus}` : `mark tom as ${newStatus}`;
      this.$track({
        action,
        tom_name: this.$t(`${tomNameKey}`),
        tom_key: tomNameKey,
      });
    },
  },
  beforeMount() {
    this.$eventHub.$on('tomStatusWasChanged', this.loadTomList);
    this.$eventHub.$on('tomWasUpdated', this.loadTomList);
    this.loadTomList();
  },
  beforeDestroy() {
    this.$eventHub.$off('tomStatusWasChanged', this.loadTomList);
    this.$eventHub.$off('tomWasUpdated', this.loadTomList);
  },
};
</script>
