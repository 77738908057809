import TrainingUserId from '@/domain/training/user/TrainingUserId';
import Language from '@/domain/language/Language';
import TrainingUserRepresentation from '@/domain/training/user/TrainingUserRepresentation';

export default class TrainingUser {
  private readonly trainingUserId: TrainingUserId;

  private readonly language: Language;

  constructor(trainingUserId: TrainingUserId, language: Language) {
    this.trainingUserId = trainingUserId;
    this.language = language;
  }

  public representedAs(representation: TrainingUserRepresentation): TrainingUserRepresentation {
    return representation
      .setId(this.trainingUserId)
      .setLanguage(this.language);
  }
}
