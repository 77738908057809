export default class RecipientWasDeletedEventListener {
  _writeModel;

  constructor(
    RecipientsWriteModel,
  ) {
    this._writeModel = RecipientsWriteModel;
  }

  invoke(recipientWasDeleted) {
    this._writeModel.removeRecipient(recipientWasDeleted.getRecipientId());
  }
}
