export default class AbstractDocumentDataRecipientRepresentation {
  _id;

  _clientId;

  _name;

  _lastModification;

  setId(id) {
    this._id = id;
  }

  setClientId(clientId) {
    this._clientId = clientId;
  }

  setName(name) {
    this._name = name;
  }

  setLastModification(lastModification) {
    this._lastModification = lastModification;
  }
}
