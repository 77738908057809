import normalization from '@/services/shared/normalization';
import Translator from '@/infrastructure/Translator';
import HeadquarterCache from '@/infrastructure/headquarter/HeadquarterCache';
import EquipmentsCache from '@/infrastructure/equipment/EquipmentsCache';
import HeadquarterRepositoryApi from '@/infrastructure/headquarter/HeadquarterRepositoryApi';
import EquipmentRepositoryApi from '@/infrastructure/equipment/EquipmentRepositoryApi';
import createEquipmentEntity from '@/domain/equipment/createEquipmentEntity';
import resetEquipmentsBuilder from './resetEquipments/resetEquipments';
import removeEquipmentBuilder from './removeEquipment/removeEquipment';
import fetchEquipmentsBuilder from './fetchEquipments/fetchEquipments';
import getHeadquartersEquipmentCountBuilder from './getHeadquartersEquipment/getHeadquartersEquipmentCount';
import createEquipmentBuilder from './createEquipment/createEquipment';
import fetchSecurityMeasuresBuilder
  from './fetchSecurityMeasures/fetchSecurityMeasures';
import fetchTypesBuilder from './fetchTypes/fetchTypes';
import fetchLocationsBuilder from './fetchLocations/fetchLocations';
import updateEquipmentBuilder from './updateEquipment/updateEquipment';
import fetchEquipmentBuilder from './fetchEquipment/fetchEquipment';

export default {
  resetEquipments: resetEquipmentsBuilder(EquipmentsCache),
  removeEquipment: removeEquipmentBuilder(new EquipmentRepositoryApi()),
  fetchEquipments: fetchEquipmentsBuilder(
    HeadquarterCache,
    new EquipmentRepositoryApi(),
    new Translator(),
    normalization,
  ),
  getHeadquartersEquipmentCount: getHeadquartersEquipmentCountBuilder(new EquipmentRepositoryApi()),
  createEquipment: createEquipmentBuilder(new EquipmentRepositoryApi(), createEquipmentEntity),
  fetchSecurityMeasures: fetchSecurityMeasuresBuilder(new Translator()),
  fetchTypes: fetchTypesBuilder(new Translator()),
  fetchLocations: fetchLocationsBuilder(new HeadquarterRepositoryApi(), new Translator()),
  updateEquipment: updateEquipmentBuilder(new EquipmentRepositoryApi()),
  fetchEquipment: fetchEquipmentBuilder(new EquipmentRepositoryApi()),
};
