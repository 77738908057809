import AbstractRecipientModelRepresentation
  from '@/domain/recipient/recipientModel/AbstractRecipientModelRepresentation';

export default class RecipientModelRepresentation extends AbstractRecipientModelRepresentation {
  _nameNormalized = null;

  _normalizationService;

  constructor(normalizationServices) {
    super();
    this._normalizationService = normalizationServices;
  }

  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get nameNormalized() {
    if (!this._nameNormalized) {
      this._nameNormalized = this._normalizationService.normalizeString(this._name.toString());
    }

    return this._nameNormalized;
  }

  get businessName() {
    return (this._businessName) ? this._businessName.toString() : null;
  }

  get country() {
    return (this._country) ? this._country.toString() : null;
  }

  get state() {
    return (this._state) ? this._state.toString() : null;
  }

  get category() {
    return (this._category) ? this._category.toString() : null;
  }

  get dpa() {
    return (this._dpa) ? this._dpa.toString() : null;
  }

  get role() {
    return (this._role) ? this._role.toString() : null;
  }

  get warranties() {
    return (this._warranties.length) ? this._warranties.map((warranty) => warranty.toString()) : [];
  }
}
