export default class Recipient {
  _id;

  _clientId;

  _name;

  _businessName;

  _country;

  _state;

  _status;

  _category;

  _role;

  _warranties;

  _dpa;

  _dpaStatus;

  _lastModification;

  _documents;

  _recipientModel;

  _replacedBy;

  _isReplacement;

  constructor(
    id,
    clientId,
    name,
    status,
    lastModification,
  ) {
    this._id = id;
    this._clientId = clientId;
    this._name = name;
    this._status = status;
    this._lastModification = lastModification;
    this._warranties = [];
    this._documents = [];
  }

  getDocuments() {
    return this._documents;
  }

  addBusinessName(businessName) {
    this._businessName = businessName;
  }

  addCountry(country) {
    this._country = country;
  }

  addState(state) {
    this._state = state;
  }

  addCategory(value) {
    this._category = value;
  }

  addRole(value) {
    this._role = value;
  }

  addWarranties(warranties) {
    this._warranties = warranties;
  }

  addDpa(dpa) {
    this._dpa = dpa;
  }

  addDpaStatus(dpaStatus) {
    this._dpaStatus = dpaStatus;
  }

  addDocuments(documents) {
    this._documents = documents;
  }

  addRecipientModel(recipientModel) {
    this._recipientModel = recipientModel;
  }

  addReplacedBy(replacedBy) {
    this._replacedBy = replacedBy;
  }

  addIsReplacement(isReplacement) {
    this._isReplacement = isReplacement;
  }

  representedAs(recipientRepresentation) {
    recipientRepresentation.setId(this._id);
    recipientRepresentation.setClientId(this._clientId);
    recipientRepresentation.setName(this._name);
    recipientRepresentation.setBusinessName(this._businessName);
    recipientRepresentation.setStatus(this._status);
    recipientRepresentation.setCategory(this._category);
    recipientRepresentation.setRole(this._role);
    recipientRepresentation.setCountry(this._country);
    recipientRepresentation.setState(this._state);
    recipientRepresentation.setWarranties(this._warranties);
    recipientRepresentation.setDpa(this._dpa);
    recipientRepresentation.setDpaStatus(this._dpaStatus);
    recipientRepresentation.setDocuments(this._documents);
    recipientRepresentation.setLastModification(this._lastModification);
    recipientRepresentation.setRecipientModel(this._recipientModel);
    recipientRepresentation.setReplacedBy(this._replacedBy);
    recipientRepresentation.setIsReplacement(this._isReplacement);

    return recipientRepresentation;
  }

  update(recipientToUpdateRepresentation) {
    this._name = recipientToUpdateRepresentation.name;
    this._businessName = recipientToUpdateRepresentation.businessName;
    this._status = recipientToUpdateRepresentation.status;
    this._category = recipientToUpdateRepresentation.category;
    this._role = recipientToUpdateRepresentation.role;
    this._country = recipientToUpdateRepresentation.country;
    this._state = recipientToUpdateRepresentation.state;
    this._warranties = recipientToUpdateRepresentation.warranties;
    this._dpa = recipientToUpdateRepresentation.dpa;
    this._dpaStatus = recipientToUpdateRepresentation.dpaStatus;
    this._documents.push(...recipientToUpdateRepresentation.documents);
    this._lastModification = recipientToUpdateRepresentation.lastModification;
  }

  getId() {
    return this._id;
  }
}
