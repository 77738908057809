import FetchClientUserTokenQuery
  from '@/application/clientUser/fetchClientUserToken/FetchClientUserTokenQuery';
import StringValueObject from '@/domain/shared/strings/StringValueObject';
import ClientUserTokenRepository from '@/domain/clientUser/ClientUserTokenRepository';

export default class FetchClientUserTokenQueryHandler {
  private readonly clientUserTokenRepository: ClientUserTokenRepository;

  constructor(clientUserTokenRepository: ClientUserTokenRepository) {
    this.clientUserTokenRepository = clientUserTokenRepository;
  }

  public async invoke(query: FetchClientUserTokenQuery): Promise<any> {
    try {
      const token: StringValueObject | null = await this.clientUserTokenRepository
        .retrieveValidationToken(StringValueObject.fromString(query.cryptoId));

      return { success: true, token: token?.toString() || null };
    } catch (e) {
      return { success: false };
    }
  }
}
