import DownloadFile from '@/infrastructure/document/DownloadFile';
import GetDocumentUrl from '@/domain/document/GetDocumentUrl';

export default class DownloadDocument {
  private readonly getDocumentUrl: GetDocumentUrl;

  private readonly downloadFile: DownloadFile;

  constructor(getDocumentUrl: GetDocumentUrl, downloadFile: DownloadFile) {
    this.getDocumentUrl = getDocumentUrl;
    this.downloadFile = downloadFile;
  }

  public async invoke(
    documentType: string,
    documentId: string | number,
    fileType: string | null = null,
  ): Promise<object> {
    try {
      const documentUrl: string = await this.getDocumentUrl.download(
        documentType, documentId, fileType,
      );
      this.downloadFile.invoke(documentUrl);
      return { success: true };
    } catch (e) {
      console.error(e);
      return { success: false };
    }
  }
}
