<template>
  <div>
    <loading-page />
    <alert-snack-bar />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingPage from '@/components/base/loading/LoadingPage.vue';
import AlertSnackBar from '@/components/base/feedback/AlertSnackBar.vue';
import { fetchUserInfo, oktaLogin } from '@/application/user';
import GoToOktaAuthUrl from '@/infrastructure/sso/GoToOktaAuthUrl';
import subdomainService from '@/services/admin/subdomain';

export default {
  name: 'okta-login',
  components: {
    AlertSnackBar,
    LoadingPage,
  },
  props: {
    iss: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    oktaId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('user', {
      userId: 'userId',
    }),
  },
  methods: {
    async goToOktaUrl() {
      const response = await GoToOktaAuthUrl.invoke(this.iss);
      if (!response.success) {
        this.$showError('login.okta.error');
      }
    },
    async login() {
      const response = await oktaLogin.invoke(this.code, this.oktaId);

      if (!response.success) {
        this.$showError('login.okta.error');
        this.$track({
          action: 'Platform access',
          authentication: 'okta',
          result: response.success,
        });
        return;
      }

      await this.getInfoAdminBySubdomain(response.subdomain);
      await this.getUserInfo();

      this.$track({
        action: 'Platform access',
        authentication: 'okta',
        result: response.success,
      });

      this.$router.push({ name: 'home' });
    },
    async getInfoAdminBySubdomain(subdomain) {
      await subdomainService.getInfoAdmin(subdomain);
    },
    async getUserInfo() {
      await fetchUserInfo.invoke(this.userId);
    },
  },
  async mounted() {
    this.$showLoading();
    if (!this.iss && !this.code) {
      this.$showError('login.okta.error');
      return;
    }

    if (this.iss) {
      await this.goToOktaUrl();
    }

    if (this.code) {
      await this.login();
    }
  },
};
</script>
