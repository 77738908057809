import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import CollaboratorName from '@/domain/dataBreach/collaborator/CollaboratorName';
import CollaboratorSurname from '@/domain/dataBreach/collaborator/CollaboratorSurname';
import CollaboratorMessage from '@/domain/dataBreach/collaborator/CollaboratorMessage';

export default class DataBreachCollaborator {
  private readonly email: EmailValueObject;

  private readonly name: CollaboratorName;

  private readonly surname: CollaboratorSurname;

  private message: CollaboratorMessage | null = null;

  constructor(
    email: EmailValueObject,
    name: CollaboratorName,
    surname: CollaboratorSurname,
  ) {
    this.email = email;
    this.name = name;
    this.surname = surname;
  }

  public addMessage(message: CollaboratorMessage): DataBreachCollaborator {
    this.message = message;
    return this;
  }

  toObject(): {[key: string]: string | null} {
    return {
      name: this.name.toString(),
      surname: this.surname.toString(),
      email: this.email.toString(),
      message: this.message?.toString() || null,
    };
  }
}
