import DataBreach from '@/domain/dataBreach/DataBreach';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import store from '../../store';

export default class DataBreachCache {
  public static reset(): void {
    store.dispatch('dataBreach/resetDataBreaches');
  }

  public static save(dataBreach: DataBreach): void {
    const dataBreaches: { [key: string]: DataBreach } = store.getters['dataBreach/dataBreaches'];
    dataBreaches[dataBreach.getId().toString()] = dataBreach;

    store.dispatch('dataBreach/addDataBreaches', dataBreaches);
  }

  public static all(): DataBreach[] {
    return Object.values(store.getters['dataBreach/dataBreaches']);
  }

  public static retrieve(dataBreachId: DataBreachId): DataBreach {
    const dataBreaches = store.getters['dataBreach/dataBreaches'];
    return dataBreaches[dataBreachId.toString()];
  }

  public static update(dataBreach: DataBreach): void {
    const dataBreaches = store.getters['dataBreach/dataBreaches'];
    const attachedDocuments = dataBreach.getAttachedDocuments();
    attachedDocuments.push(...dataBreaches[dataBreach.getId().toString()].getAttachedDocuments());

    dataBreach.addAttachedDocuments(this.getAttachedDocumentsWithoutAttachedDocumentsToDelete(
      attachedDocuments,
      dataBreach.getAttachedDocumentsToDelete(),
    ));
    dataBreach.addAttachedDocumentsToDelete([]);

    dataBreaches[dataBreach.getId().toString()] = dataBreach;

    store.dispatch('dataBreach/addDataBreaches', dataBreaches);
  }

  private static getAttachedDocumentsWithoutAttachedDocumentsToDelete(
    attachedDocuments: DataBreachAttachedDocuments[],
    attachedDocumentsToDeleteCollection: UuidValueObject[],
  ) {
    const attachedDocumentToDelete = attachedDocumentsToDeleteCollection.map(
      (documentId: UuidValueObject) => documentId.toString(),
    );

    return attachedDocuments.filter(
      (document: DataBreachAttachedDocuments) => !attachedDocumentToDelete.includes(
        document.getId().toString(),
      ),
    );
  }
}
