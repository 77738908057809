const steps = [
  'countryLanguage',
  'selectModel',
  'recipientsCategory',
  'recipients',
  'dataSecurity',
];

const recipientsSteps = [
  'categories',
  'recipients',
];

const stepsToShowPrevious = [
  'recipientsCategory',
  'recipients',
  'dataSecurity',
];

function getAnnotationsSteps() {
  return {
    selectModel: 'processing_activities.annotations.select_model',
    countryLanguage: 'processing_activities.annotations.country_language',
    recipientsCategory: 'processing_activities.annotations.recipients_category',
    recipients: 'processing_activities.annotations.recipients',
    dataSecurity: 'processing_activities.annotations.data_security',
  };
}

function getNextStep(currentStep, isPreviousStep) {
  const index = steps.indexOf(currentStep);

  if (isPreviousStep) {
    return steps[index - 1];
  }

  if (index >= 0) {
    return steps[index + 1];
  }
  return steps[1];
}

function getPreviousStep(currentStep) {
  const index = steps.indexOf(currentStep);
  return steps[index - 1];
}

function getFirstStep() {
  return steps[1];
}

function getFirstStepOfRecipients() {
  return recipientsSteps[0];
}

function getNextStepOfRecipients(currentStep) {
  const index = recipientsSteps.indexOf(currentStep);
  if (typeof recipientsSteps[index + 1] !== 'undefined') {
    return recipientsSteps[index + 1];
  }
  return recipientsSteps[index - 1];
}

function isSelectModelStep(step) {
  return steps[1] === step;
}

function showPreviousStepLink(currentStep) {
  return stepsToShowPrevious.includes(currentStep);
}

function isRecipientStep(currentStep) {
  return steps[3] === currentStep;
}

export default {
  getAnnotationsSteps,
  getNextStep,
  getFirstStep,
  getFirstStepOfRecipients,
  getNextStepOfRecipients,
  getPreviousStep,
  isSelectModelStep,
  isRecipientStep,
  showPreviousStepLink,
};
