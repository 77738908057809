import {
  deleteMethodManagement, getManagement, postManagement, putManagement,
} from '@/shared/restActions';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import EmployeeCache from '@/infrastructure/employee/EmployeeCache';
import Employee from '@/domain/employee/Employee';
import ClientId from '@/domain/client/ClientId';
import EmployeeId from '@/domain/employee/EmployeeId';
import EmployeesRaw from '@/infrastructure/employee/EmployeesRaw';
import EmployeeBuilder from '@/domain/employee/EmployeeBuilder';
import EmployeeRepresentationApi from '@/infrastructure/employee/EmployeeRepresentationApi';

export default class EmployeeRepositoryApi implements EmployeeRepository {
  public async ofClient(clientId: ClientId): Promise<Employee[]> {
    try {
      let employees: Employee[] = EmployeeCache.getEmployees();

      if (employees.length) {
        return employees;
      }

      const employeesRaw: EmployeesRaw[] = await EmployeeRepositoryApi
        ._getEmployeesFromApi(clientId.toInt());
      employees = this._buildEmployees(employeesRaw);

      EmployeeCache.setEmployees(employees);

      return employees;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching employees');
    }
  }

  private static async _getEmployeesFromApi(clientId: number): Promise<EmployeesRaw[]> {
    try {
      const response = await getManagement(`/api/v2/clients/${clientId}/employees`);
      return response.data;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching employees from api');
    }
  }

  private _buildEmployees(employeesRaw: EmployeesRaw[]): Employee[] {
    const employees: Employee[] = [];

    employeesRaw.forEach((employee: EmployeesRaw) => {
      const employeeBuilder: any = new EmployeeBuilder();

      employeeBuilder.withId(employee.id)
        .withClientId(employee.client_id)
        .withName(employee.name)
        .withSurname(employee.surname)
        .withEmail(employee.email || null)
        .withPosition(employee.position)
        .withEntry(employee.start_date)
        .withExit(employee.end_date)
        .withNif(employee.nif)
        .withDocumentData(employee.contracts);

      employees.push(employeeBuilder.create());
    });

    return employees;
  }

  public reset(): void {
    EmployeeCache.resetEmployees();
  }

  public async retrieve(employeeId: EmployeeId): Promise<Employee> {
    const employee: Employee | undefined = EmployeeCache.getEmployee(employeeId);

    if (!employee) {
      throw new Error('Error fetching employee');
    }
    return employee;
  }

  public async save(employee: Employee): Promise<void> {
    try {
      const employeeRepresentation: any = employee.representedAs(
        new EmployeeRepresentationApi(),
      );
      await postManagement('/api/v2/employees', employeeRepresentation.toJson());
      EmployeeCache.addEmployee(employee);
    } catch (e) {
      console.error(e);
      throw new Error('Error saving employee');
    }
  }

  public async update(employee: Employee): Promise<void> {
    try {
      const employeeRepresentation: any = employee.representedAs(
        new EmployeeRepresentationApi(),
      );
      await putManagement(`/api/v2/employees/${employee.getId().toString()}`, employeeRepresentation.toJson());
      EmployeeCache.updateEmployee(employee);
    } catch (e) {
      console.error(e);
      throw new Error('Error updating employee');
    }
  }

  async delete(employeeId: EmployeeId): Promise<void> {
    try {
      await deleteMethodManagement(`/api/v2/employees/${employeeId.toString()}`);
      EmployeeCache.removeEmployee(employeeId);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting employee');
    }
  }
}
