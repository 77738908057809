import EventListener from '@/application/eventBus/EventListener';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';
import TomRepository from '@/domain/tom/TomRepository';

export default class ClientSelectedWasChangedEventListener implements EventListener {
  private readonly tomRepository: TomRepository;

  constructor(tomRepository: TomRepository) {
    this.tomRepository = tomRepository;
  }

  public handle(event: ClientSelectedWasChanged) {
    this.tomRepository.reset();
  }
}
