import { getManagement, putManagement } from '@/shared/restActions';
import store from '@/store';
import TaskFactory from '../../domain/task/TaskFactory';

function setStoreTasksList(tasks) {
  return store.dispatch('task/addTasks', tasks);
}

function resetStoreTasksList() {
  return store.dispatch('task/resetTasks');
}

function getStoreTasksList() {
  return store.getters['task/tasks'];
}

async function restoreStoreFromBackend(clientId) {
  try {
    const tasks = [];
    const response = await getManagement(`/api/v2/tasks/client/${clientId}`);
    const rawTasks = response.data;

    rawTasks.forEach((task) => {
      tasks.push(TaskFactory.create({
        taskId: task.task_id,
        title: task.title,
        department: task.department.id,
        expirationDate: task.expiration_date,
        taskType: task.type,
        critical: task.critical,
        requestPetition: task.request_petition,
        status: task.status,
        creationDate: task.creation_date,
        completionDate: task.completion_date,
        adminId: task.admin_id,
        clientId: task.client_id,
        createdBy: task.created_by,
      }));
    });
    await setStoreTasksList(tasks);
  } catch (e) {
    console.error(e);
    throw new Error('Error fetch list of tasks');
  }
}

async function updateTaskDepartment(taskId, department) {
  try {
    await putManagement(`/api/v2/tasks/${taskId}/department`, {
      department: department.toString(),
    });
    await store.dispatch('task/updateTaskDepartment', { taskId, department });
  } catch (e) {
    console.error(e);
    throw new Error('Error fetch updating a task');
  }
}

async function fillStoreIfEmpty(clientId) {
  if (getStoreTasksList().length !== 0) {
    return;
  }

  await restoreStoreFromBackend(clientId);
}
async function getPendingTasks(clientId) {
  await fillStoreIfEmpty(clientId);
  return getStoreTasksList();
}

export default {
  getPendingTasks,
  setStoreTasksList,
  resetStoreTasksList,
  updateTaskDepartment,
};
