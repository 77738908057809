export default ({ clientFolderRepository }) => async (
  companyId, isAdmin,
) => {
  try {
    const listInfo = await clientFolderRepository.getListFiles(
      companyId, isAdmin,
    );
    return { success: true, data: listInfo };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
