<template>
  <div>
    <div class="triangle"/>
    <div class="change-department">
      <v-btn
        icon
        color="white"
        @click="toggleModal()"
      >
        <v-icon>
          account_circle
        </v-icon>
      </v-btn>
    </div>
    <v-dialog
      v-model="showModal"
      max-width="600"
      @click:outside="toggleModal()"
    >
      <v-card class="modal-change-department">
        <v-card-title
        >
          <h2>
            {{ $t('tasks.reassign_task') }}
          </h2>
          <v-spacer/>
          <v-btn
            icon
            @click="toggleModal()"
          >
            <v-icon v-text="'close'" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8" md="12" class="text-left">
              <p>{{ $t('tasks.reassign_task_title') }}</p>
            </v-col>
          </v-row>
         <v-row>
           <v-col cols="8" md="12" class="text-center">
             <select-field
               :value.sync="value"
               :items.sync="departments"
               itemText="name"
               itemValue="id"
               :label="$t('tasks.select_department')"
             />
           </v-col>
         </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            rounded
            outlined
            color="primary"
            @click="toggleModal()"
          >
            {{ $t('tasks.cancel') }}
          </v-btn>
          <v-btn
            rounded
            color="primary"
            :disabled="!value.length"
            @click="changeDepartment()"
          >
            {{ $t('tasks.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectField from '@/components/base/form/SelectField.vue';
import taskUsecases from '@/application/task/usecases';
import { fetchListDepartment } from '@/infrastructure/application/department/listDepartment';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';

export default {
  name: 'change-department-btn',
  inject: {
    user: 'user',
  },
  components: {
    SelectField,
  },
  props: {
    task: Object,
    default: () => ({}),
  },
  data() {
    return {
      showModal: false,
      value: '',
      departments: [],
    };
  },
  methods: {
    async getDepartmentsList() {
      const response = await fetchListDepartment
        .invoke(new ListDepartmentQuery(this.user.clientId));

      if (!response.success) {
        this.$showError('tasks.changeDepartment.error_department_list');
        return;
      }

      this.departments = response.departments;
    },
    async toggleModal() {
      this.$showLoading();

      await this.getDepartmentsList();

      this.$hideLoading();
      this.showModal = !this.showModal;
    },
    async changeDepartment() {
      await this.$showLoading();
      const response = await taskUsecases.changeTaskDepartment(this.task.taskId, this.value);
      await this.$hideLoading();
      if (!response.success) {
        this.$showError('tasks.changeDepartment.error');
        return;
      }
      this.$eventHub.$emit('taskUpdated');
      this.$showSuccess('tasks.changeDepartment.success');
      this.showModal = false;
    },
  },
  async beforeMount() {
    this.value = this.task.department;
  },
};
</script>
