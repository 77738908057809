import DepartmentWasCreatedEventListener
  from '@/application/department/listDepartment/command/saveDepartment/DepartmentWasCreatedEventListener';
import SaveDepartmentCommandHandler
  from '@/application/department/listDepartment/command/saveDepartment/SaveDepartmentCommandHandler';
import DepartmentRepositoryApi from '@/infrastructure/department/DepartmentRepositoryApi';
import DepartmentWriteModelApi
  from '@/infrastructure/department/listDepartment/DepartmentWriteModelApi';
import { user } from '@/infrastructure/user/UserCache';

export default class DepartmentWasCreatedEventListenerBuilder {
  static build() {
    return new DepartmentWasCreatedEventListener(
      new SaveDepartmentCommandHandler(
        new DepartmentRepositoryApi(),
        new DepartmentWriteModelApi(),
        user,
      ),
    );
  }
}
