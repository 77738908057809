export default class AbstractEquipmentLocationsRepresentation {
  _key;

  _type;

  _isHeadquarterType;

  setKey(key) {
    this._key = key;
  }

  setType(type) {
    this._type = type;
  }

  setIsHeadquarterType(isHeadquarter) {
    this._isHeadquarterType = isHeadquarter;
  }
}
