<template>
  <div class="mb-10">
    <alert-fixed
      v-if="showAlert"
      type="error"
      :text="textAlert"
    />
    <div class="upload-files" v-if="componentLoaded">
      <div class="text-center">
        <vue2Dropzone
          ref="uploadFilesDropzone"
          id="upload-files__dropzone"
          :options="options"
          @vdropzone-file-added="fileAdded"
          :useCustomSlot="true"
          class="text-center"
        >
          <v-icon class="upload-files__dropzone__icon material-icons-round">file_upload</v-icon>
          <div>
            <p
              v-if="!isDeutschLanguage"
            >
              <strong>{{ $t('form.upload_document.upload_action_drag&drop') }}</strong>
              {{ $t('form.upload_document.upload_action') }}
            </p>
            <p
              v-else
            >
              {{ $t('form.upload_document.upload_action') }}
              <strong>{{ $t('form.upload_document.upload_action_drag&drop') }}</strong>

            </p>
            <p><strong>{{ $t('form.upload_document.upload_or') }}</strong></p>
            <v-btn
              color="primary"
              rounded
              class="mb-0"
            >
              {{ $t('form.upload_document.upload_search_files') }}
            </v-btn>
            <p>{{ $t('form.upload_document.upload_file_size', { size:'20MB' }) }}</p>
          </div>
        </vue2Dropzone>
        <div class="dropzone-previews" id="tpl" />
      </div>
    </div>
    <div
      v-if="listFiles.length"
      class="upload-files__list mt-5"
    >
      <p class="mb-5">
        {{ $t('form.upload_document.upload_files') }}
      </p>
      <v-card
        v-for="(file, index) in listFiles"
        :key="index"
        elevation="0"
        class="mb-3"
      >
        <v-card-text
          class="upload-files__list-card"
          :class="{ 'upload-files__list-card--grid': mustShowButtons }"
        >
          <span>{{ file.name }}</span>
          <div class="text-right">
            <v-btn
              v-if="mustShowDownloadButton()"
              icon
              @click="downloadFile(file)"
            >
              <v-icon
                class="material-icons-round"
              >
                download
              </v-icon>
            </v-btn>
            <v-btn
              v-if="mustShowDeleteButton()"
              icon
              @click="removeFile(index, file.id)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import formValidation from '@/services/validations/formValidation';
import { downloadDocument } from '@/application/document';
import AlertFixed from '../feedback/AlertFixed.vue';

const DEUTSCH_CODE = 'de';

export default {
  name: 'upload-document',
  components: {
    AlertFixed,
    Vue2Dropzone,
  },
  props: {
    filesUploaded: {
      type: Array,
      default: () => [],
    },
    filesDeleted: {
      type: Array,
      default: null,
    },
    alreadyUploadedFiles: {
      type: Array,
      default: () => [],
    },
    allowedExtensions: {
      type: Array,
      default: null,
    },
    documentType: {
      type: String,
      default: '',
    },
  },
  computed: {
    options() {
      const options = {
        url: 'https://pridatect.com', // random url - without url the component broken
        maxFiles: 1,
        autoProcessQueue: false,
        uploadMultiple: false,
      };

      if (this.componentLoaded) {
        options.previewsContainer = 'div.dropzone-previews';
      }
      return options;
    },
    isDeutschLanguage() {
      return this.$i18n.locale === DEUTSCH_CODE;
    },
  },
  data() {
    return {
      showAlert: false,
      textAlert: '',
      listFiles: [],
      totalSize: 0,
      componentLoaded: false,
    };
  },
  methods: {
    async fileAdded(file) {
      if (!this.validFile(file)) {
        return;
      }

      this.totalSize += file.size;

      const base64 = await this.getBase64(file)
        .catch((e) => false);

      if (!base64) {
        this.textAlert = this.$t('form.upload.error_obtain_file');
        this.showAlert = true;
        return;
      }

      this.filesUploaded.push({ name: file.name, content: base64.split(',')[1] });
      this.listFiles.push({ name: file.name, base64 });
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    validFile(file) {
      this.showAlert = false;
      this.textAlert = '';

      if (!formValidation.correctTypeFiles(file.name, this.allowedExtensions)) {
        this.textAlert = this.$t('form.upload.no_correct_file_type');
        this.showAlert = true;
        return false;
      }

      if (!formValidation.correctFileSize(file.size, 'mb20')) {
        this.textAlert = this.$t('form.upload.no_correct_files_size');
        this.showAlert = true;
        return false;
      }

      if (!formValidation.correctAllFilesSize(this.totalSize + file.size, 'mb20')) {
        this.textAlert = this.$t('form.upload.no_correct_files_size');
        this.showAlert = true;
        return false;
      }

      return true;
    },
    removeFile(indexFileRemoved, fileRemovedId) {
      if (!fileRemovedId) {
        const fileUploadedIndex = indexFileRemoved - (
          this.listFiles.length - this.filesUploaded.length
        );
        this.filesUploaded.splice(fileUploadedIndex, 1);
      } else {
        this.filesDeleted.push(fileRemovedId);
      }

      this.listFiles.splice(indexFileRemoved, 1);
    },
    downloadFile(file) {
      if (file.id) {
        this.downloadUploadedFile(file.id);
        return;
      }

      this.downloadNewlyUploadedFile(file);
    },
    downloadNewlyUploadedFile(file) {
      const link = document.createElement('a');
      link.href = file.base64;
      link.download = file.name;
      link.click();
      document.body.removeChild(link);
    },
    async downloadUploadedFile(fileToDownloadId) {
      const response = await downloadDocument.invoke(this.documentType, fileToDownloadId);
      if (!response.success) {
        this.$showError('errorPage.error_download');
      }
    },
    mustShowButtons() {
      return this.mustShowDownloadButton() || this.mustShowDeleteButton();
    },
    mustShowDownloadButton() {
      return this.documentType;
    },
    mustShowDeleteButton() {
      return this.filesDeleted !== null;
    },
  },
  mounted() {
    this.listFiles = [...this.alreadyUploadedFiles];
    this.componentLoaded = true;
  },
  watch: {
    'alreadyUploadedFiles.length': function () {
      this.listFiles = [...this.alreadyUploadedFiles];
    },
  },
};
</script>
