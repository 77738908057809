import { getManagement } from '@/shared/restActions';
import DocumentDataEmployeeRepository
  from '@/domain/documentData/employee/DocumentDataEmployeeRepository';
import DocumentDataEmployeeBuilder
  from '@/domain/documentData/employee/DocumentDataEmployeeBuilder';

export default class DocumentDataEmployeeRepositoryApi extends DocumentDataEmployeeRepository {
  async ofEmployee(employeeId, clientId) {
    try {
      const documentsDataRaw = await this._getDocumentDataOfEmployeeApi(employeeId.toString());
      return this._buildDocumentsData(documentsDataRaw, clientId.toInt());
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching documents data of Employee');
    }
  }

  async _getDocumentDataOfEmployeeApi(employeeId) {
    try {
      const response = await getManagement(`/api/v2/employees/${employeeId}/contracts`);
      return response.data;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching documents data of Employee');
    }
  }

  _buildDocumentsData(documentsDataRaw, clientId) {
    const documentsDataList = [];
    documentsDataRaw.forEach((documentDataRaw) => {
      const documentDataBuilder = new DocumentDataEmployeeBuilder();
      documentDataBuilder.withId(documentDataRaw.id)
        .withClientId(clientId)
        .withName(documentDataRaw.name)
        .withLanguage(documentDataRaw.language)
        .withLastModification(documentDataRaw.last_modification);

      documentsDataList.push(documentDataBuilder.create());
    });

    return documentsDataList;
  }
}
