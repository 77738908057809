const URL_PRIDATECT: any = process.env.VUE_APP_LOGIN_CENTRALIZED_URL;

export default class LoginCentralized {
  public static getLayoutName(): string {
    return LoginCentralized.isUrlPridatect() ? 'anonymous-layout-b2b' : 'anonymous-layout';
  }

  public static isShowingLoginLayoutB2B(): boolean {
    return LoginCentralized.isUrlPridatect();
  }

  public static hasLoginCentralizedUrl(): boolean {
    return LoginCentralized.isUrlPridatect();
  }

  public static goToLoginCentralized(): void {
    let url = URL_PRIDATECT;
    if (!url.includes('http')) {
      url = `https://${url}`;
    }
    window.location.href = url;
  }

  private static isUrlPridatect(): boolean {
    return window.location.href.includes(URL_PRIDATECT);
  }
}
