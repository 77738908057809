import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MAX_LENGTH = 255;
const MIN_LENGTH = 0;

export default class EmployeeSurname extends StringValueObject {
  constructor(string: string) {
    super(string, MIN_LENGTH, MAX_LENGTH);
  }
}
