import EventListener from '@/application/eventBus/EventListener';
import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';

export default class ClientSelectedWasChangedEventListener implements EventListener {
  private readonly dataBreachesPendingWriteModel: DataBreachesPendingWriteModel;

  constructor(dataBreachesPendingWriteModel: DataBreachesPendingWriteModel) {
    this.dataBreachesPendingWriteModel = dataBreachesPendingWriteModel;
  }

  public handle(event: ClientSelectedWasChanged): void {
    this.dataBreachesPendingWriteModel.reset();
  }
}
