<template>
  <v-card-actions class="banner-delete">
    <v-row align="center">
      <v-col cols="12" class="banner-delete__form">
        <p><strong>{{ $t('recipient.document_data.delete.title') }}</strong></p>
        <p>{{ $t('recipient.document_data.delete.deletion') }} <strong>{{ fileName }}</strong></p>
        <p>{{ $t('recipient.document_data.delete.sure') }}</p>
      </v-col>
      <v-col cols="12">
        <alert-fixed
          v-if="showAlertSelection"
          type="error"
        >
          {{ $t('banner_footer.download.alert') }}
        </alert-fixed>
      </v-col>
      <v-col cols="12" class="text-right banner-delete__button">
        <v-btn
            color="error"
            outlined
            rounded
            @click="$emit('update:show', false)"
        >
         {{ $t('deletion.cancel') }}
        </v-btn>
        <v-btn
            color="error"
            rounded
            @click="deleteItem()"
        >
          {{ $t('deletion.confirm') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>

import AlertFixed from '@/components/base/feedback/AlertFixed.vue';

export default {
  name: 'banner-footer-deletion',
  components: {
    AlertFixed,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    methodDeleteUseCase: {
      type: Function,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showAlertSelection: false,
    };
  },
  methods: {
    deleteItem() {
      this.methodDeleteUseCase();
    },
  },
};
</script>
