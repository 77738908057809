<template>
  <div
    class="recipient__info"
  >
    <h2>
    <span class="subtitle-1 grey--text">
      {{ title }}
    </span>
      <br>
      {{ recipientDetails.name }}
    </h2>
    <v-divider />
    <p>
      <span class="grey--text">{{ $t('recipient.replace.business_name') }}: </span>
      {{ recipientDetails.businessName }}
      <br>
      <span class="grey--text">{{ $t('recipient.replace.category') }}: </span>
      {{ recipientDetails.categoryTranslation }}
      <br>
      <span class="grey--text">{{ $t('recipient.replace.role') }}: </span>
      {{ recipientDetails.roleTranslation }}
      <br>
      <span class="grey--text">{{ $t('recipient.replace.country') }}: </span>
      {{ recipientDetails.countryTranslation }}
      <br>
      <span v-if="!!recipientDetails.state">
        <span class="grey--text">{{ $t('recipient.replace.state') }}: </span>
        {{ recipientDetails.stateTranslation }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'recipient-details',
  inject: {
    fetchRecipient: 'fetchRecipient',
  },
  props: {
    recipientId: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      recipientDetails: {},
    };
  },
  methods: {
    async getRecipientDetails() {
      const response = await this.fetchRecipient(this.recipientId);
      if (!response.success) {
        return;
      }
      this.recipientDetails = response.recipient;
    },
  },
  beforeMount() {
    this.getRecipientDetails();
  },
  watch: {
    recipientId() {
      this.getRecipientDetails();
    },
  },
};
</script>
