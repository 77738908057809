import { getManagement } from '@/shared/restActions';
import ClientRepository from '@/domain/client/ClientRepository';
import Client from '@/domain/client/Client';
import ClientRaw from '@/infrastructure/client/ClientRaw';
import ClientId from '@/domain/client/ClientId';
import ClientBusinessName from '@/domain/client/ClientBusinessName';
import ClientName from '@/domain/client/ClientName';
import ClientCountryId from '@/domain/client/ClientCountryId';
import ClientCache from '@/infrastructure/client/ClientCache';

export default class ClientRepositoryApi implements ClientRepository {
  public async all(): Promise<Client[]> {
    let clients: Client[] = ClientCache.all();
    if (clients.length) {
      return clients;
    }

    const clientsRaw: ClientRaw[] = await this.getClientFromApi();
    clients = this.buildClients(clientsRaw);

    ClientCache.saveAll(clients);
    return clients;
  }

  private async getClientFromApi(): Promise<ClientRaw[]> {
    return getManagement('/api/v2/user/admin/clients');
  }

  private buildClients(clientsRaw: ClientRaw[]): Client[] {
    return clientsRaw.map((clientRaw: ClientRaw) => new Client(
      ClientId.fromInt(clientRaw.id),
      ClientBusinessName.fromString(clientRaw.business_name),
      ClientName.fromString(clientRaw.name),
      ClientCountryId.fromInt(clientRaw.countryId),
    ));
  }

  public async findById(clientId: ClientId): Promise<Client | undefined> {
    return ClientCache.retrieve(clientId);
  }
}
