import { initialState } from './state';

const RESET_LOADING_PAGE = (state) => {
  state.loadingPage = initialState.loadingPage;
};
const SHOW_LOADING_PAGE = (state) => {
  state.loadingPage = true;
};

const SHOW_SNACK_BAR = (state, config) => {
  state.snackBar.text = config.text;
  state.snackBar.parameters = config.parameters;
  state.snackBar.default = config.default;
  state.snackBar.type = config.type;
  state.snackBar.show = true;
};

const HIDE_SNACK_BAR = (state) => {
  state.snackBar.show = false;
};

export default {
  RESET_LOADING_PAGE,
  SHOW_LOADING_PAGE,
  SHOW_SNACK_BAR,
  HIDE_SNACK_BAR,
};
