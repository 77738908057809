import 'material-icons/iconfont/material-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    icons: {
      iconfont: 'md',
    },
    themes: {
      light: {
        primary: '#609D98',
        primary_lighten: '#E8F1F0',
        text_color: '#424242',
        text_muted: '#9D9D9D',
        grey_lighten: '#F5F5F5',
        blue_pridatect: '#0A6ECC',
        red_pridatect: '#C2002F',
        warning: '#EE9035',
        error: '#B31A03',
      },
      dark: {
        primary: '#609D98',
        primary_lighten: '#E8F1F0',
        text_color: '#424242',
        text_muted: '#9D9D9D',
        grey_lighten: '#F5F5F5',
        blue_pridatect: '#0A6ECC',
        red_pridatect: '#C2002F',
        warning: '#EE9035',
        error: '#B31A03',
      },
    },
  },
});
