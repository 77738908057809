<template>
  <div>
    <v-radio-group
      v-model="roleSelected"
      column
      mandatory
      class="mt-1 mb-4"
      @change="$emit('update:userRole', roleSelected)"
    >
      <v-radio
        v-for="(role, index) in availableRoles"
        :key="role.type"
        :value="role.type"
        :class="(index !== availableRoles.length -1) && 'mb-10'"
        v-track="`Change user role -- mark ${role.type}`"
      >
        <template
          v-slot:label
        >
          <span>
            <strong>{{ role.name }} </strong>
            {{$t(`clientUsers.change_role.${role.type}_info`)}}
          </span>
        </template>
      </v-radio>
    </v-radio-group>
    <v-expand-transition>
      <div
        v-show="roleSelectedIsEmployee"
        class="mx-6"
      >
        <checkbox-button-field
          :items="availableDepartments"
          :value="departmentSelected"
          :textUppercase="true"
          itemValue="id"
          @update:value="changeDepartment"
        />
        <v-checkbox
          v-model="selectAllDepartments"
          :label="$t('clientUsers.change_role.department_all')"
          color="primary"
          @change="changeDepartment(departmentSelected)"
        />
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import CheckboxButtonField from '@/components/base/form/CheckboxButtonField.vue';
import FetchClientUserTypesQuery
  from '@/application/clientUser/fetchClientUserTypes/FetchClientUserTypesQuery';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';

const EMPLOYEE_TYPE = 'employee';

export default {
  name: 'select-user-role-and-departments',
  components: {
    CheckboxButtonField,
  },
  inject: {
    user: 'user',
    fetchClientUserTypes: 'fetchClientUserTypes',
    fetchListDepartment: 'fetchListDepartment',
  },
  props: {
    userRole: {
      type: String,
      default: '',
    },
    departmentSelected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectAllDepartments: {
      get() {
        return this.departmentSelected.length === this.availableDepartments.length;
      },
      set(selected) {
        if (selected) {
          this.$emit('update:departmentSelected', this.availableDepartments.map((department) => (department.id)));
        } else {
          this.$emit('update:departmentSelected', []);
        }
      },
    },
    roleSelectedIsEmployee() {
      return this.userRole === EMPLOYEE_TYPE;
    },
  },
  data() {
    return {
      roleSelected: this.userRole,
      availableRoles: [],
      availableDepartments: [],
    };
  },
  methods: {
    loadClientUsersTypes() {
      const response = this.fetchClientUserTypes.invoke(new FetchClientUserTypesQuery());
      this.availableRoles = response.clientUserTypes.filter((role) => this.user.isB2B || !role.isRestrictedEmployeeType);
      this.$emit('clientUserTypesWereLoaded', this.availableRoles);
    },
    async loadDepartments() {
      const response = await this.fetchListDepartment
        .invoke(new ListDepartmentQuery(this.user.clientId));

      if (!response.success) {
        this.$showError('clientUsers.error.load_page');
        return;
      }

      this.availableDepartments = response.departments;
    },
    changeDepartment(departmentsSelected) {
      this.$track({
        action: `Change user role -- departments selected: ${departmentsSelected.join(', ')}`,
      });
    },
  },
  beforeMount() {
    this.loadClientUsersTypes();
    this.loadDepartments();
  },
};
</script>
