export default ({
  processingActivitiesRepository,
}) => async (adminId, clientId, recipientCategoryCustom) => {
  try {
    const response = await processingActivitiesRepository.saveNewRecipientsCategoryCustom(
      adminId,
      clientId,
      recipientCategoryCustom,
    );

    return { success: true, data: response.data };
  } catch (e) {
    return { success: false };
  }
};
