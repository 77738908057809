import { Route } from 'vue-router';
import userdeskUserRolesAccess from '@/services/permissions/userdeskUserRolesAccess';
import departmentSettingsAccess from '@/services/permissions/departmentSettingsAccess';
import { user } from '@/infrastructure/user/UserCache';
import {
  fetchClientUserToken,
} from '@/infrastructure/application/clientUser/fetchClientUserTokenBuilder';
import FetchClientUserTokenQuery
  from '@/application/clientUser/fetchClientUserToken/FetchClientUserTokenQuery';
import LoginCentralized from '@/services/login/LoginCentralized';

const HOME_RESTRICTED_EMPLOYEE_PATH = '/training';
const HOME_TRAININGS_MANAGER_PATH = '/training';
const HOME_PATH = '/';
const LOGIN_PATH = '/login';
const ACTIVATE_ACCOUNT_PATH = '/activate';

export default class AuthorizationRoute {
  private redirectionPath: string = HOME_PATH;

  private queryPath: {[key: string]: string} = {};

  private accessRoutes: {[key: string]: any} = {
    login: async (nextRoute: Route) => this.hasLoginAccess(nextRoute),
    'user-register': async (nextRoute: Route) => this.hasUserRegisterAccess(nextRoute),
    'user-validated': async (nextRoute: Route) => this.hasUserRegisterAccess(nextRoute),
    'forgot-password': async (nextRoute: Route) => this.hasForgotPasswordAccess(nextRoute),
    'processing-activities': async (nextRoute: Route) => await this.hasProcessingActivitiesAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'data-map': async (nextRoute: Route) => await this.hasProcessingActivitiesAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'legal-documents': async (nextRoute: Route) => await this.hasLegalDocumentsAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    risks: async (nextRoute: Route) => await this.hasRisksAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    registers: async (nextRoute: Route) => await this.hasRegistersAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    'data-breach': async (nextRoute: Route) => await this.hasDataBreachAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'data-breach-notify': async (nextRoute: Route) => await this.hasDataBreachNotifyAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'data-breach-historic': async (nextRoute: Route) => await this.hasDataBreachAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    tom: async (nextRoute: Route) => await this.hasTomAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    'tom-library': async (nextRoute: Route) => await this.hasTomAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'tom-implemented': async (nextRoute: Route) => await this.hasTomAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'tom-pending-to-implement': async (nextRoute: Route) => await this.hasTomAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'client-folder': async (nextRoute: Route) => await this.hasClientFolderAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'treatment-managers': async (nextRoute: Route) => await this.hasTreatmentManagersAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    training: async (nextRoute: Route) => await this.hasTrainingAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    home: async (nextRoute: Route) => this.hasHomeAccess(nextRoute),
    settings: async (nextRoute: Route) => await this.hasSettingsAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    'settings-personal': async (nextRoute: Route) => await this.hasSettingsDepartmentsAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    'settings-departments': async (nextRoute: Route) => await this.hasSettingsDepartmentsAccess(nextRoute) && !this.isUserAdminWithoutClientSelected(),
    users: async (nextRoute: Route) => await this.hasUsersAccess(nextRoute)
      && !this.isUserAdminWithoutClientSelected(),
    'page-not-found': (nextRoute: Route) => this.hasPageNotFoundAccess(nextRoute),
  }

  public getRedirectionPath(): string {
    return this.redirectionPath;
  }

  public getParams(): {[key: string]: string} | null {
    const params = { ...this.queryPath };
    this.queryPath = {};

    return Object.keys(params).length ? params : null;
  }

  public async hasAuthorization(nextRoute: Route): Promise<boolean> {
    this.redirectionPath = HOME_PATH;

    if (!nextRoute.name) {
      return true;
    }

    if (this.accessRoutes[nextRoute.name] === 'undefined' || !this.accessRoutes[nextRoute.name]) {
      return true;
    }

    return this.accessRoutes[nextRoute.name](nextRoute);
  }

  private async hasLoginAccess(nextRoute: Route): Promise<boolean> {
    return !await this.isUserLogged(nextRoute);
  }

  private async hasUserRegisterAccess(nextRoute: Route): Promise<boolean> {
    return !await this.isUserLogged(nextRoute) && LoginCentralized.hasLoginCentralizedUrl();
  }

  private async hasForgotPasswordAccess(nextRoute: Route): Promise<boolean> {
    return !await this.isUserLogged(nextRoute);
  }

  private async hasProcessingActivitiesAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasLegalDocumentsAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasRisksAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasRegistersAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasDataBreachAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute) && this.isUserB2B();
  }

  private async hasDataBreachNotifyAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute) && this.isUserB2B();
  }

  private async hasTomAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute) && this.isUserB2B() && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasClientFolderAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee() && this.isNotTrainingsManager();
  }

  private async hasTreatmentManagersAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasTrainingAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
    && user.isB2B;
  }

  private async hasHomeAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && this.isNotTrainingsManager();
  }

  private async hasSettingsAccess(nextRoute: Route): Promise<boolean> {
    return this.isUserLogged(nextRoute);
  }

  private async hasSettingsDepartmentsAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && departmentSettingsAccess.hasDepartmentSettingsAccess()
      && user.isAdmin;
  }

  private async hasUsersAccess(nextRoute: Route): Promise<boolean> {
    return await this.isUserLogged(nextRoute)
      && this.isNotRestrictedEmployee()
      && userdeskUserRolesAccess.hasUserdeskUserRolesAccess();
  }

  private async hasPageNotFoundAccess(nextRoute: Route): Promise<boolean> {
    return this.isUserLogged(nextRoute);
  }

  private async isUserLogged(nextRoute: Route): Promise<boolean> {
    if (!localStorage.getItem('user-token')) {
      this.redirectionPath = LOGIN_PATH;

      if (nextRoute.params?.cryptId) {
        await this.changeRedirectionAndQueryIfUserIsNotValidated(
          nextRoute.params.cryptId,
        );
      }

      return false;
    }

    return true;
  }

  private async changeRedirectionAndQueryIfUserIsNotValidated(cryptId: string): Promise<void> {
    const response: any = await fetchClientUserToken.invoke(new FetchClientUserTokenQuery(cryptId));

    if (!response.success || !response.token) {
      return;
    }

    this.queryPath.token = response.token;
    this.redirectionPath = ACTIVATE_ACCOUNT_PATH;
  }

  private isUserB2B(): boolean {
    if (!user.isB2B) {
      this.redirectionPath = HOME_PATH;
      return false;
    }

    return true;
  }

  private isNotRestrictedEmployee(): boolean {
    if (user.isRestrictedEmployee) {
      this.redirectionPath = HOME_RESTRICTED_EMPLOYEE_PATH;
      return false;
    }

    return true;
  }

  private isNotTrainingsManager(): boolean {
    if (user.isTrainingsManager) {
      this.redirectionPath = HOME_TRAININGS_MANAGER_PATH;
      return false;
    }

    return true;
  }

  private isUserAdminWithoutClientSelected(): boolean {
    if (user.isAdmin && !user.clientId) {
      this.redirectionPath = HOME_PATH;
      return true;
    }

    return false;
  }
}
