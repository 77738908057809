import Login from '@/domain/user/login/Login';
import TokenLoginCredentials from '@/domain/user/login/Credentials/TokenLoginCredentials';

export default class GoogleLoginHandler {
  private login: Login;

  constructor(login: Login) {
    this.login = login;
  }

  public async invoke(googleAccessToken: string): Promise<object> {
    try {
      const loginCredentials = new TokenLoginCredentials(googleAccessToken);
      const subdomain = await this.login.invoke(loginCredentials);
      return { success: true, subdomain };
    } catch (e) {
      return { success: false };
    }
  }
}
