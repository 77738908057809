<template>
  <v-app-bar
    dense
    class="horizontal-menu elevation-0"
    height="60"
    tag="header"
    app
    absolute
  >
    <v-toolbar-title>
      <logo-img
        :logoUrl.sync="companyLogoUrl"
        :companyName.sync="companyName"
        titleTag="h2"
      />
    </v-toolbar-title>
    <v-spacer />
    <div class="selected_client">
      <select-client
        v-if="userIsAdmin"
        :label="$t('menus.select_client_label')"
      />
    </div>
    <v-btn
      icon
      color="primary"
      @click="showOptionsSettings = !showOptionsSettings"
    >
      <v-icon>
        more_horiz
      </v-icon>
    </v-btn>
    <div class="horizontal-menu__options"
      :class="{'active': showOptionsSettings}">
      <div class="button_tooltip" v-if="clientId">
        <button @click="goTo('settings')">
          <v-icon>
            settings
          </v-icon>
        </button>
        <span>{{$t('menus.settings')}}</span>
      </div>
      <div v-if="hasAccessClientUsersSection()" class="button_tooltip">
        <button @click="goTo('users')">
          <v-icon>
            supervisor_account
          </v-icon>
        </button>
        <span>{{ $t('menus.users') }}</span>
      </div>
      <div class="button_tooltip">
        <button @click="logout()">
          <v-icon>
            exit_to_app
          </v-icon>
        </button>
        <span>{{$t('menus.logout')}}</span>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex';

import userUseCases from '@/usecases/users';
import userdeskUserRolesAccess from '@/services/permissions/userdeskUserRolesAccess';

import HistoricRoute from '@/router/HistoricRoute';
import SelectClient from '../form/SelectClient.vue';
import LogoImg from '../icons/LogoImg.vue';

export default {
  name: 'horizontal-menu',
  inject: {
    user: 'user',
  },
  components: {
    SelectClient,
    LogoImg,
  },
  computed: {
    ...mapGetters('admin', {
      companyName: 'name',
      companyLogoUrl: 'logoUrl',
      adminCompanyId: 'adminCompanyId',
    }),
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
  },
  data() {
    return {
      showOptionsSettings: false,
      reloadSelectClient: 0,
    };
  },
  created() {
    const self = this;
    window.addEventListener('click', (e) => {
      if (!self.$el.contains(e.target)) {
        self.showOptionsSettings = false;
      }
    });
  },
  methods: {
    goTo(pathName) {
      this.showOptionsSettings = false;
      this.$router.push({ name: pathName });
    },
    async logout() {
      this.$track({ action: 'Logout' });
      this.$router.push({ name: 'login' });
      HistoricRoute.reset();
      await userUseCases.logout();
    },
    hasAccessClientUsersSection() {
      return userdeskUserRolesAccess.hasUserdeskUserRolesAccess()
        && this.clientId
        && !this.user.isRestrictedEmployee;
    },
  },
};
</script>
