import RecipientDpaStatus from '@/domain/recipient/recipientDpaStatus/RecipientDpaStatus';
import RecipientDpaStatusRepresentation from './RecipientDpaStatusRepresentation';

export default (
  translator,
) => () => {
  const dpaStatus = RecipientDpaStatus.all();
  return dpaStatus.map(
    (dpa) => dpa.representedAs(new RecipientDpaStatusRepresentation(translator)),
  );
};
