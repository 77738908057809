<template>
  <div class="h-100 w-100">
    <h2 class="mb-2">4.
      <span class="grey--text text--darken-3">{{ $t("data_breach.form.step.four.title") }}</span>
    </h2>
    <v-divider class="ml-5 mb-4"/>
    <v-row class="pl-5 data-breach__form-step">
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.four.question_1") }}</span></h4>
        <upload-document
          :filesUploaded="dataBreach.attachedDocuments"
          :filesDeleted="dataBreach.attachedDocumentsToDelete"
          :alreadyUploadedFiles="alreadyUploadedFiles"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UploadDocument from '@/components/base/form/UploadDocument.vue';

export default {
  name: 'additional-info',
  components: { UploadDocument },
  props: {
    dataBreach: {
      type: Object,
      default: () => {},
    },
    alreadyUploadedFiles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
