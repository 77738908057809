export default ({
  clientFolderRepository,
  downloadFile,
}) => async (companyId, fileId, isAdmin) => {
  try {
    const urlDownload = await clientFolderRepository.downloadFile(
      companyId, fileId, isAdmin,
    );

    downloadFile.download(urlDownload);

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
