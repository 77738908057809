import { getManagement, postManagement, deleteMethodManagement } from '@/shared/restActions';

function getListFilesUrl(companyId, isAdmin) {
  let url = '/api/v2/files';

  if (isAdmin) {
    url = `/api/v2/customers/${companyId}/files`;
  }
  return url;
}

function getDownloadFileUrl(companyId, fileId, isAdmin) {
  let url = `/api/v2/files/${fileId}`;

  if (isAdmin) {
    url = `/api/v2/customers/${companyId}/files/${fileId}`;
  }
  return url;
}

function getDeleteFileURl(companyId, fileId, isAdmin) {
  let url = `/api/v2/files/${fileId}`;

  if (isAdmin) {
    url = `/api/v2/customers/${companyId}/files/${fileId}`;
  }
  return url;
}

function getListFiles(companyId, isAdmin) {
  const url = getListFilesUrl(companyId, isAdmin);

  return getManagement(url);
}

function downloadFile(companyId, fileId, isAdmin) {
  const url = getDownloadFileUrl(companyId, fileId, isAdmin);

  return getManagement(url);
}

function deleteFile(companyId, fileId, isAdmin) {
  const url = getDeleteFileURl(companyId, fileId, isAdmin);

  return deleteMethodManagement(url);
}

function uploadFiles(clientId, files) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formData.append('files[]', files[i]);
  }

  const url = `/api/v2/client-folder/${clientId}`;

  return postManagement(url, formData);
}

export default {
  getListFiles,
  downloadFile,
  deleteFile,
  uploadFiles,
};
