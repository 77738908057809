import {
  postManagement, putManagement, deleteMethodManagement, getManagement,
} from '@/shared/restActions';
import RecipientRepository from '@/domain/recipient/RecipientRepository';
import RecipientRepresentationApi from '@/infrastructure/recipient/RecipientRepresentationApi';
import RecipientBuilder from '@/domain/recipient/RecipientBuilder';
import RecipientCache from '@/infrastructure/recipient/RecipientCache';

export default class RecipientRepositoryApi extends RecipientRepository {
  async retrieve(recipientId) {
    try {
      let recipient = RecipientCache.getRecipient(recipientId);

      if (recipient) {
        return recipient;
      }

      const response = await getManagement(`/api/v2/recipients/${recipientId.toString()}`);
      recipient = this._buildRecipient(response.data, recipientId);
      RecipientCache.addRecipient(recipient);
      return recipient;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching recipient');
    }
  }

  async save(recipient) {
    const recipientRepresentation = recipient.representedAs(new RecipientRepresentationApi());
    await postManagement('/api/v2/recipients', recipientRepresentation.toJson());
    this._activatePendingRecipientDocuments(recipient);
    RecipientCache.addRecipient(recipient);
  }

  async update(recipient) {
    try {
      const recipientRepresentation = recipient.representedAs(new RecipientRepresentationApi());
      await putManagement(`/api/v2/recipients/${recipient.getId().toString()}`, recipientRepresentation.toJson());
      this._activatePendingRecipientDocuments(recipient);
      RecipientCache.updateRecipient(recipient);
    } catch (e) {
      console.error(e);
      throw new Error('Error updating recipient');
    }
  }

  async delete(recipientId) {
    try {
      await deleteMethodManagement(`/api/v2/recipients/${recipientId.toString()}`);
      RecipientCache.removeRecipient(recipientId);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting recipient');
    }
  }

  async requestReplacement(fromRecipientId, toRecipientId) {
    try {
      await putManagement(
        `/api/v2/recipients/${fromRecipientId.toString()}/request-replacement`,
        { replaceTo: toRecipientId.toString() },
      );
    } catch (e) {
      console.error(e);
      throw new Error('Error replacing recipient');
    }
  }

  _activatePendingRecipientDocuments(recipient) {
    recipient._documents.map((document) => document.activate());
  }

  _buildRecipient(recipientApiResponse, recipientId) {
    const recipientBuilder = new RecipientBuilder();

    recipientBuilder
      .withId(recipientId.toString())
      .withClientId(recipientApiResponse.client_id)
      .withName(recipientApiResponse.name)
      .withStatus(recipientApiResponse.status)
      .withLastModification(recipientApiResponse.last_modification)
      .withBusinessName(recipientApiResponse.business_name)
      .withCountry(recipientApiResponse.country)
      .withState(recipientApiResponse.state)
      .withCategory(recipientApiResponse.category)
      .withRole(recipientApiResponse.role)
      .withWarranties(recipientApiResponse.warranties)
      .withDpa(recipientApiResponse.dpa)
      .withDpaStatus(recipientApiResponse.dpa_status)
      .withDocuments(recipientApiResponse.files);

    return recipientBuilder.create();
  }
}
