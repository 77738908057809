import Translator from '@/infrastructure/Translator';
import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';
import DepartmentNameValue from '@/domain/department/DepartmentNameValue';

export default class ClientUsersRepresentation extends ClientUserRepresentation {
  private translator!: Translator;

  private roleTranslation = '';

  protected _departmentsName: DepartmentNameValue[] = [];

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  public setDepartmentsName(departmentsName: DepartmentNameValue[]): ClientUsersRepresentation {
    this._departmentsName = departmentsName;
    return this;
  }

  get id(): number {
    return this._id?.toInt() || 0;
  }

  get name(): string {
    return this._name.toString();
  }

  get surname(): string {
    return this._surname.toString();
  }

  get email(): string {
    return this._email.toString();
  }

  get departments(): string[] {
    return this._departmentsName.map((department: DepartmentNameValue) => department.toString());
  }

  get type(): string {
    return this._type.toString();
  }

  get language(): string {
    return this._language.toString();
  }

  get validated(): boolean {
    return this._validated;
  }

  get lastLogin() {
    return this._lastLogin?.toString() || '';
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get isValidated(): boolean {
    return this._validated;
  }

  get lastLoginDate(): string {
    if (!this._lastLogin) {
      return '';
    }

    return this._lastLogin.toStringDate();
  }

  get isTrainingsManager(): boolean {
    return this._type.isTrainingsManager();
  }

  get role(): string {
    if (this.roleTranslation === '') {
      this.roleTranslation = this.getRoleTranslation();
    }

    return this.roleTranslation;
  }

  private getRoleTranslation(): string {
    if (!this._type.isEmployee()) {
      return this.translator.translate(`clientUsers.table.${this._type.toString()}`) as string;
    }

    const departmentsTranslations: string[] = [];

    this.departments.forEach((department: string) => {
      const keyTranslation = `departments.${department}`;
      if (this.translator.translationExist(keyTranslation)) {
        departmentsTranslations.push(
          this.translator.translate(keyTranslation) as string,
        );
      } else {
        departmentsTranslations.push(department);
      }
    });

    departmentsTranslations.sort();

    return `${this.translator.translate('clientUsers.table.coordinator')} ${departmentsTranslations.join(', ')}`;
  }
}
