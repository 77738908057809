<template>
  <div class="h-100 w-100">
    <v-row>
      <v-col cols="12">
        <title-section
          :title="$t('registers.title')"
          :subtitle="$t('registers.subtitle')"
        />
      </v-col>
      <v-col cols="12">
       <submenu-section
         :tabActive.sync="currentTab"
         :sections="menuSections"
         v-on:update:tabActive="trackActions"
       >
         <v-tab-item
           v-for="item in menuSections"
           :key="item.key"
           :value="item.key"
           :transition="false"
         >
           <component
             :is="item.key"
             :currentTab="currentTab"
             :openCreateRecipient="openCreateRecipient"
           />
         </v-tab-item>
       </submenu-section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TitleSection from '../base/section/TitleSection.vue';
import SubmenuSection from '../base/menus/SubmenuSection.vue';
import HeadquartersSection from '../modules/register/headquarters/HeadquartersSection.vue';
import EquipmentsSection from '../modules/register/equipments/EquipmentsSection.vue';
import EmployeesSection from '../modules/register/employees/EmployeesSection.vue';
import RecipientsSection from '../modules/register/recipients/RecipientsSection.vue';

export default {
  name: 'registers-container',
  components: {
    SubmenuSection,
    TitleSection,
    headquarter: HeadquartersSection,
    equipment: EquipmentsSection,
    employee: EmployeesSection,
    recipient: RecipientsSection,
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    openCreateRecipient() {
      return !!(this.currentTab === 'recipient' && this.$route.params.createRecipient);
    },
    menuSections() {
      return [
        {
          name: this.$t('recipient.menu'),
          key: 'recipient',
        },
        {
          name: this.$t('headquarters.menu'),
          key: 'headquarter',
        },
        {
          name: this.$t('equipment.menu'),
          key: 'equipment',
        },
        {
          name: this.$t('employee.menu'),
          key: 'employee',
        },
      ];
    },
  },
  data() {
    return {
      currentTab: 'recipient',
    };
  },
  methods: {
    changeCurrentTab(tab) {
      this.currentTab = tab;
    },
    trackActions(sectionName) {
      this.$track({
        action: `Go to ${sectionName}`,
      });
    },
  },
  async beforeMount() {
    this.$eventHub.$on('goToHeadquartersTab', this.changeCurrentTab);
  },
  mounted() {
    if (this.$route.params.createRecipient) {
      this.currentTab = this.$route.params.createRecipient;
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('goToHeadquartersTab', this.changeCurrentTab);
  },
};
</script>
