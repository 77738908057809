<template>
  <select-autocomplete-field
      :selectedItems.sync="value"
      :items.sync="types"
      :label="`${$t('equipment.form.type')} *`"
      :rules="rules"
      item-value="key"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'type-selector',
  inject: {
    fetchTypes: 'fetchTypes',
  },
  components: {
    SelectAutocompleteField,
  },
  props: {
    selectedValues: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: this.selectedValues,
      types: [],
    };
  },
  methods: {
    getEquipmentTypes() {
      this.types = this.fetchTypes();
    },
  },
  beforeMount() {
    this.getEquipmentTypes();
  },
  watch: {
    value() {
      this.$emit('update:selectedValues', this.value);
    },
    selectedValues() {
      this.value = this.selectedValues;
    },
  },
};
</script>
