import store from '@/store';

export default class AdminCache {
  get name(): string {
    return store.state.admin.name;
  }

  get logoUrl(): string {
    return store.state.admin.logoUrl;
  }

  get subdomain(): string {
    return store.state.admin.subdomain;
  }

  get isSpanishAdmin(): boolean {
    return store.state.admin.isSpanishAdmin;
  }

  get companyId(): number {
    return store.state.admin.adminCompanyId;
  }

  get countryCode(): string {
    return store.state.admin.countryCode;
  }

  get isB2B(): boolean {
    return store.state.admin.isB2b;
  }
}

export const admin = new AdminCache();
