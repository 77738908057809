<template>
  <v-row v-if="showSection">
    <v-col cols="12">
      <title-section
        :title="$t('clientUsers.title')"
        :subtitle="$t('clientUsers.subtitle')"
      />
    </v-col>
    <v-col cols="12" md="6">
       <input-search
        :value.sync="search"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right">
      <create-client-user-modal />
    </v-col>
    <v-col cols="12">
      <client-users-list-table
        :search.sync="search"
      />
    </v-col>
    <v-col cols="12">
      <a @click="$router.go(-1)">
       {{ $t('clientUsers.go_back') }}
      </a>
    </v-col>
  </v-row>
</template>

<script>
import ClientUsersListTable from '@/components/modules/clientUsers/ClientUsersListTable.vue';
import CreateClientUserModal from '@/components/modules/clientUsers/CreateClientUserModal.vue';
import TitleSection from '@/components/base/section/TitleSection.vue';
import InputSearch from '@/components/base/form/InputSearch.vue';
import userdeskUserRolesAccess from '@/services/permissions/userdeskUserRolesAccess';
import { fetchListDepartment } from '@/infrastructure/application/department/listDepartment';

import {
  fetchClientUsers,
  createClientUser,
  deleteClientUser,
  resetClientUsers,
  resendActivationClientUserEmail,
  fetchClientUserTypes,
  fetchClientUser,
  changeClientUserRole,
} from '@/infrastructure/application/clientUser';
import ResetClientUsersCommand
  from '@/application/clientUser/resetClientUser/ResetClientUsersCommand';

export default {
  name: 'client-users-container',
  provide: {
    fetchClientUser,
    fetchClientUsers,
    createClientUser,
    deleteClientUser,
    resendActivationClientUserEmail,
    fetchClientUserTypes,
    fetchListDepartment,
    changeClientUserRole,
  },
  inject: {
    user: 'user',
  },
  components: {
    ClientUsersListTable,
    CreateClientUserModal,
    TitleSection,
    InputSearch,
  },
  data() {
    return {
      search: '',
      showSection: false,
    };
  },
  async beforeMount() {
    this.showSection = userdeskUserRolesAccess.hasUserdeskUserRolesAccess() && this.user.clientId;
    if (!this.showSection) {
      this.$router.push({ name: 'home' });
    }
    resetClientUsers.invoke(new ResetClientUsersCommand());
  },
};
</script>
