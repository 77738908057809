<template>
  <div class="h-100 w-100">
    <v-col cols="12">
      <h4>
        <span>
          {{ $t('toms.information_modal.toms_name') }}
        </span>
      </h4>
      <textarea-field
        :value.sync="tom.name"
        :rows="1"
        :readonly="true"
      />
    </v-col>
    <v-col v-if="!!tom.description" cols="12">
      <h4>
        <span>
          {{ $t('toms.information_modal.toms_description') }}
        </span>
      </h4>
      <textarea-field
        :value.sync="tom.description"
        :rows="1"
        :readonly="true"
      />
    </v-col>
    <v-col cols="12">
      <h4>
        <span>
          {{ $t('toms.information_modal.status') }}
        </span>
      </h4>
      <icon-radio-button
        :items="tomStatus"
        :value.sync="tom.status"
        defaultValue="available"
      />
    </v-col>
    <v-col :cols="!tom.referenceCode ? 12 : 6">
      <h4>
        <span>
          {{ $t('toms.information_modal.standard') }}
        </span>
      </h4>
      <input-field
        :value.sync="tom.list"
        :readonly="true"
      />
    </v-col>
    <v-col v-if="!!tom.referenceCode" cols="6">
      <h4>
        <span>
          {{ $t('toms.information_modal.reference_number') }}
        </span>
      </h4>
      <input-field
        :value.sync="tom.referenceCode"
        :readonly="true"
      />
    </v-col>
    <v-col v-if="!!tom.category" cols="12">
    <h4>
        <span>
          {{ $t('toms.information_modal.category') }}
        </span>
    </h4>
    <input-field
      :value.sync="tom.category"
      :readonly="true"
    />
    </v-col>
  </div>
</template>

<script>
import InputField from '@/components/base/form/InputField.vue';
import IconRadioButton from '@/components/base/form/IconRadioButton.vue';
import TextareaField from '@/components/base/form/TextareaField.vue';

export default {
  name: 'tom-info',
  components: {
    IconRadioButton,
    TextareaField,
    InputField,
  },
  props: {
    tom: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    tomStatus() {
      return [
        {
          icon: 'check_circle',
          name: this.$t('toms.information_modal.status_implemented'),
          value: 'implemented',
          color: 'green',
        },
        {
          icon: 'bookmark',
          name: this.$t('toms.information_modal.status_pending'),
          value: 'pendingToImplement',
          color: 'blue_pridatect',
        },
      ];
    },
  },
};
</script>
