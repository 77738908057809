import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachOccurredOn from '@/domain/dataBreach/DataBreachOccurredOn';
import DataBreachDiscoveredOn from '@/domain/dataBreach/DataBreachDiscoveredOn';
import DataBreachDescription from '@/domain/dataBreach/DataBreachDescription';
import DataBreachHowDiscovered from '@/domain/dataBreach/DataBreachHowDiscovered';
import DataBreachWasIntentional from '@/domain/dataBreach/DataBreachWasIntentional';
import DataBreachCompromisedData from '@/domain/dataBreach/DataBreachCompromisedData';
import DataBreachAffectedProfiles from '@/domain/dataBreach/DataBreachAffectedProfiles';
import DataBreachConsequences from '@/domain/dataBreach/DataBreachConsequences';
import DataBreachAffectedCountries from '@/domain/dataBreach/DataBreachAffectedCountries';
import DataBreachNumberAffected from '@/domain/dataBreach/DataBreachNumberAffected';
import DataBreachSecurityMeasuresImplemented
  from '@/domain/dataBreach/DataBreachSecurityMeasuresImplemented';
import DataBreachFutureSecurityMeasures from '@/domain/dataBreach/DataBreachFutureSecurityMeasures';
import DataBreachSecurityMeasuresProblems
  from '@/domain/dataBreach/DataBreachSecurityMeasuresProblems';
import DataBreachInformedAffectedPeople from '@/domain/dataBreach/DataBreachInformedAffectedPeople';
import DataBreachInformedAuthorities from '@/domain/dataBreach/DataBreachInformedAuthorities';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import DataBreachCode from '@/domain/dataBreach/DataBreachCode';
import DataBreachBuilderValidationException
  from '@/domain/dataBreach/DataBreachBuilderValidationException';
import DataBreach from '@/domain/dataBreach/DataBreach';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import WhatHappens from '@/domain/dataBreach/WhatHappens';
import AffectedDataInformation from '@/domain/dataBreach/AffectedDataInformation';
import PreventMeasures from '@/domain/dataBreach/PreventMeasures';
import DpoAnswers from '@/domain/dataBreach/DpoAnswers';
import DataBreachEvaluation from '@/domain/dataBreach/DataBreachEvaluation';
import DataBreachReportLanguage from '@/domain/dataBreach/DataBreachReportLanguage';
import DataBreachStatus from '@/domain/dataBreach/DataBreachStatus';
import DataBreachCreatedBy from '@/domain/dataBreach/DataBreachCreatedBy';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';

export default class DataBreachBuilder {
  private id: string | null = null;

  private createdDate: string | null = null;

  private createdBy: string | null = null;

  private occurredOn: string | null = null;

  private discoveredOn: string | null = null;

  private description: string | null = null;

  private status: string = DataBreachStatus.draft().toString();

  private howDiscovered: string | null = null;

  private wasIntentional: string | null = null;

  private compromisedData: string | null = null;

  private affectedProfiles: string | null = null;

  private consequences: string | null = null;

  private affectedCountries: string | null = null;

  private numberAffected: number | null = null;

  private securityMeasuresImplemented: string | null = null;

  private futureSecurityMeasures: string | null = null;

  private securityMeasuresProblems: string | null = null;

  private informedAffectedPeople: string | null = null;

  private informedAuthorities: string | null = null;

  private attachedDocuments: AttachedDocumentStruct[] = [];

  private attachedDocumentsToDelete: string[] = [];

  private code: string | null = null;

  private evaluation: string | null = null;

  private agencyNotification: boolean = false;

  private reportLanguage: string | null = null;

  public withId(id: string): DataBreachBuilder {
    this.id = id;
    return this;
  }

  public withCreatedDate(createdDate: string): DataBreachBuilder {
    this.createdDate = createdDate;
    return this;
  }

  public withCreatedBy(createdBy: string): DataBreachBuilder {
    this.createdBy = createdBy;
    return this;
  }

  public withStatus(status: string | null): DataBreachBuilder {
    this.status = status || DataBreachStatus.draft().toString();
    return this;
  }

  public withWhatHappens(whatHappens: WhatHappens): DataBreachBuilder {
    this.occurredOn = whatHappens.occurredOn;
    this.discoveredOn = whatHappens.discoveredOn;
    this.description = whatHappens.description;
    this.howDiscovered = whatHappens.howDiscovered;
    this.wasIntentional = whatHappens.wasIntentional;
    return this;
  }

  public withAffectedDataInformation(affectedDataInformation: AffectedDataInformation)
    : DataBreachBuilder {
    this.compromisedData = affectedDataInformation.compromisedData;
    this.affectedProfiles = affectedDataInformation.affectedProfiles;
    this.consequences = affectedDataInformation.consequences;
    this.affectedCountries = affectedDataInformation.affectedCountries;
    this.numberAffected = affectedDataInformation.numberAffected;
    return this;
  }

  public withPreventMeasures(preventMeasures: PreventMeasures): DataBreachBuilder {
    this.securityMeasuresImplemented = preventMeasures.securityMeasuresImplemented;
    this.futureSecurityMeasures = preventMeasures.futureSecurityMeasures;
    this.securityMeasuresProblems = preventMeasures.securityMeasuresProblems;
    this.informedAffectedPeople = preventMeasures.informedAffectedPeople;
    this.informedAuthorities = preventMeasures.informedAuthorities;
    return this;
  }

  public withCode(code: string | null): DataBreachBuilder {
    if (this.code) {
      this.code = code;
    }
    return this;
  }

  public withAttachedDocuments(
    attachedDocuments: AttachedDocumentStruct[],
  ): DataBreachBuilder {
    this.attachedDocuments = attachedDocuments;
    return this;
  }

  public withAttachedDocumentsToDelete(attachedDocumentsToDelete: string[])
    : DataBreachBuilder {
    this.attachedDocumentsToDelete = attachedDocumentsToDelete;
    return this;
  }

  public withDpoAnswer(dpoAnswers: DpoAnswers): DataBreachBuilder {
    this.evaluation = dpoAnswers.evaluation;
    this.agencyNotification = dpoAnswers.agencyNotification;
    this.reportLanguage = dpoAnswers.reportLanguage;
    return this;
  }

  private reset(): void {
    this.id = null;
    this.createdDate = null;
    this.createdBy = null;
    this.occurredOn = null;
    this.discoveredOn = null;
    this.description = null;
    this.status = DataBreachStatus.draft().toString();
    this.howDiscovered = null;
    this.wasIntentional = null;
    this.compromisedData = null;
    this.affectedProfiles = null;
    this.consequences = null;
    this.affectedCountries = null;
    this.numberAffected = null;
    this.securityMeasuresImplemented = null;
    this.futureSecurityMeasures = null;
    this.securityMeasuresProblems = null;
    this.informedAffectedPeople = null;
    this.informedAuthorities = null;
    this.code = null;
    this.attachedDocuments = [];
    this.attachedDocumentsToDelete = [];
    this.evaluation = null;
    this.agencyNotification = false;
    this.reportLanguage = null;
  }

  public create(): DataBreach {
    this.hasMandatoryValuesOrFails();

    const dataBreachOccurredOn: DataBreachOccurredOn = (this.occurredOn!.includes('/'))
      ? DataBreachOccurredOn.fromStringDateTime(this.occurredOn!)
      : DataBreachOccurredOn.fromW3CDate(this.occurredOn!);

    const dataBreachDiscoveredOn: DataBreachDiscoveredOn = (this.discoveredOn!.includes('/'))
      ? DataBreachOccurredOn.fromStringDateTime(this.discoveredOn!)
      : DataBreachOccurredOn.fromW3CDate(this.discoveredOn!);

    const dataBreach: DataBreach = new DataBreach(
      DataBreachId.fromString(this.id!),
      DataBreachCreatedDate.fromW3CDate(this.createdDate!),
      DataBreachCreatedBy.fromString(this.createdBy!),
      dataBreachOccurredOn,
      dataBreachDiscoveredOn,
      DataBreachDescription.fromString(this.description!),
      DataBreachStatus.fromString(this.status),
    );

    if (this.howDiscovered && this.howDiscovered.trim()) {
      dataBreach.addHowDiscovered(DataBreachHowDiscovered.fromString(this.howDiscovered));
    }

    if (this.wasIntentional && this.wasIntentional.trim()) {
      dataBreach.addWasIntentional(DataBreachWasIntentional.fromString(this.wasIntentional));
    }

    if (this.compromisedData && this.compromisedData.trim()) {
      dataBreach.addCompromisedData(DataBreachCompromisedData.fromString(this.compromisedData));
    }

    if (this.affectedProfiles && this.affectedProfiles.trim()) {
      dataBreach.addAffectedProfiles(DataBreachAffectedProfiles.fromString(this.affectedProfiles));
    }

    if (this.consequences && this.consequences.trim()) {
      dataBreach.addConsequences(DataBreachConsequences.fromString(this.consequences));
    }

    if (this.affectedCountries && this.affectedCountries.trim()) {
      dataBreach.addAffectedCountries(DataBreachAffectedCountries.fromString(
        this.affectedCountries,
      ));
    }

    if (this.numberAffected) {
      dataBreach.addNumberAffected(DataBreachNumberAffected.fromInt(
        this.numberAffected,
      ));
    }

    if (this.securityMeasuresImplemented && this.securityMeasuresImplemented.trim()) {
      dataBreach.addSecurityMeasuresImplemented(DataBreachSecurityMeasuresImplemented.fromString(
        this.securityMeasuresImplemented,
      ));
    }

    if (this.futureSecurityMeasures && this.futureSecurityMeasures.trim()) {
      dataBreach.addFutureSecurityMeasures(DataBreachFutureSecurityMeasures.fromString(
        this.futureSecurityMeasures,
      ));
    }

    if (this.securityMeasuresProblems && this.securityMeasuresProblems.trim()) {
      dataBreach.addSecurityMeasuresProblems(DataBreachSecurityMeasuresProblems.fromString(
        this.securityMeasuresProblems,
      ));
    }

    if (this.informedAffectedPeople && this.informedAffectedPeople.trim()) {
      dataBreach.addInformedAffectedPeople(DataBreachInformedAffectedPeople.fromString(
        this.informedAffectedPeople,
      ));
    }

    if (this.informedAuthorities && this.informedAuthorities.trim()) {
      dataBreach.addInformedAuthorities(DataBreachInformedAuthorities.fromString(
        this.informedAuthorities,
      ));
    }

    if (this.code) {
      dataBreach.addCode(DataBreachCode.fromString(this.code));
    }

    if (this.attachedDocuments.length) {
      dataBreach.addAttachedDocuments(
        this.buildAttachedDocuments(),
      );
    }

    if (this.attachedDocumentsToDelete.length) {
      dataBreach.addAttachedDocumentsToDelete(
        this.attachedDocumentsToDelete.map(
          (documentId: string) => UuidValueObject.fromString(documentId),
        ),
      );
    }

    if (this.evaluation && this.evaluation.trim()) {
      dataBreach.addEvaluation(DataBreachEvaluation.fromString(this.evaluation));
    }

    dataBreach.addAgencyNotification(this.agencyNotification);

    if (this.reportLanguage) {
      dataBreach.addReportLanguage(DataBreachReportLanguage.fromString(this.reportLanguage));
    }

    this.reset();

    return dataBreach;
  }

  private hasMandatoryValuesOrFails(): void {
    if (!this.id) {
      throw new DataBreachBuilderValidationException('Data breach id is mandatory');
    }

    if (!this.createdDate) {
      throw new DataBreachBuilderValidationException('Data breach created date is mandatory');
    }

    if (!this.occurredOn) {
      throw new DataBreachBuilderValidationException('Data breach occurred on is mandatory');
    }

    if (!this.discoveredOn) {
      throw new DataBreachBuilderValidationException('Data breach discovered on is mandatory');
    }

    if (!this.description) {
      throw new DataBreachBuilderValidationException('Data breach description is mandatory');
    }
    if (this.createdBy === null) {
      throw new DataBreachBuilderValidationException('Data breach created by is mandatory');
    }
  }

  private buildAttachedDocuments(): DataBreachAttachedDocuments[] {
    const documents: DataBreachAttachedDocuments[] = [];
    this.attachedDocuments.forEach((document: AttachedDocumentStruct) => {
      const attachedDocument: DataBreachAttachedDocuments = DataBreachAttachedDocuments
        .fromAttachedDocumentStruct(document);

      documents.push(attachedDocument);
    });

    return documents;
  }
}
