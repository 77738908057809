export default class RecipientRepository {
  retrieve(recipientId) {}

  save(recipient) {}

  update(recipient) {}

  delete(recipientId) {}

  requestReplacement(fromRecipientId, toRecipientId) {}
}
