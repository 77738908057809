import store from '@/store';
import { getManagement } from '@/shared/restActions';

function getInfo(subdomain) {
  return getManagement(`/api/v2/anon/basic-info-subdomain/${subdomain}`);
}

async function storeInfo(adminInfo) {
  await store.dispatch('admin/addAdminInfo', adminInfo);
}

function resetInfo() {
  store.dispatch('admin/resetAdminInfo');
}

function getLastUploadedDocuments() {
  return getManagement('/api/v2/statistics/documents/last-uploaded-documents');
}

export default {
  getInfo,
  storeInfo,
  resetInfo,
  getLastUploadedDocuments,
};
