import AbstractEquipmentRepresentation from '../../../domain/equipment/AbstractEquipmentRepresentation';
import HeadquarterId from '../../../domain/headquarter/HeadquarterId';

export default class EquipmentsRepresentation extends AbstractEquipmentRepresentation {
  _nameNormalized = null;

  constructor(HeadquarterCache, translator, normalizationService) {
    super();
    this._translator = translator;
    this._normalizationService = normalizationService;
    this._headquartersCache = HeadquarterCache;
  }

  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get nameNormalized() {
    if (!this._nameNormalized) {
      this._nameNormalized = this._normalizationService.normalizeString(this._name.toString());
    }

    return this._nameNormalized;
  }

  get type() {
    return this._translator.translate(`equipment.type.${this._type.toString()}`);
  }

  get location() {
    if (!this._location) {
      return '';
    }
    if (this._location.isHeadquartersType()) {
      return this._getHeadquarterName();
    }
    return this._translator.translate(`equipment.location.${this._location.toString()}`);
  }

  _getHeadquarterName() {
    const headquarters = this._headquartersCache.getHeadquarterById(
      HeadquarterId.fromString(this._location.toString()),
    );

    if (!headquarters) {
      return '';
    }
    return headquarters._name.toString();
  }

  get securityMeasures() {
    const securityMeasuresTranslations = [];
    this._securityMeasures.forEach((element) => {
      securityMeasuresTranslations.push(this._translator.translate(`equipment.security_measures.${element}`));
    });
    return securityMeasuresTranslations.join(', ');
  }
}
