export default ({
  processingActivitiesRepository,
  documentViewer,
}) => async (companyId, documentId, typeDocument, lang, nameDocument, isAdmin) => {
  try {
    const urlView = await processingActivitiesRepository.viewDocument(
      companyId, documentId, typeDocument, lang, isAdmin,
    );

    const url = urlView?.data?.url || urlView.preview_url;
    documentViewer.setDocumentInformation({ url, name: nameDocument });
    documentViewer.showDocumentViewer();

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
