const ADD_TASKS = (state, tasks) => {
  state.tasks = tasks;
};

const RESET_TASKS = (state) => {
  state.tasks = [];
};

const UPDATE_TASK_DEPARTMENT = (state, payload) => {
  state.tasks.forEach((task) => {
    if (task.taskId.id === payload.taskId) {
      task.updateDepartment(payload.department);
    }
  });
};

export default {
  ADD_TASKS,
  RESET_TASKS,
  UPDATE_TASK_DEPARTMENT,
};
