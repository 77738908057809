<template>
  <span>
    <v-btn
      color="primary"
      rounded
      v-track="'Create new employee -- start'"
      @click="openNewEmployeeModal"
    >
      {{ $t('employee.create.button') }}
    </v-btn>
    <dialog-form-employee
      v-if="modalIsOpen"
      :show-modal.sync="modalIsOpen"
      :showNif="showNif"
      @saveEmployee="saveEmployee"
    />
  </span>

</template>

<script>

import DialogFormEmployee from './form/DialogFormEmployee.vue';

export default {
  name: 'create-employee',
  inject: {
    user: 'user',
    createEmployee: 'createEmployee',
  },
  components: {
    DialogFormEmployee,
  },
  props: {
    showNif: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    openNewEmployeeModal() {
      this.modalIsOpen = true;
    },
    async saveEmployee(employee) {
      this.$showLoading();
      const response = await this.createEmployee.invoke(employee, this.user.clientId);
      this.$track({ action: 'Create new employee -- finish', result: response.success });
      this.$hideLoading();
      if (!response.success) {
        this.$showError('employee.create.error');
        return;
      }
      this.$showSuccess('employee.create.success');
      this.$eventHub.$emit('employeeWasCreated');
      this.modalIsOpen = false;
    },
  },
};
</script>
