export default ({
  clientFolderRepository,
  uploadFilesValidationService,
}) => async (clientId, files) => {
  const validation = uploadFilesValidationService.validate(files);
  if (validation.success) {
    try {
      await clientFolderRepository.uploadFiles(clientId, files);

      return { success: true };
    } catch (e) {
      if (e === 401) {
        return { success: true };
      }
      return { error: { type: 'apiFailure' } };
    }
  } else {
    return { error: validation.error };
  }
};
