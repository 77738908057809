import TaskResponseFactory from '@/domain/task/response/TaskResponseFactory';

export default ({
  taskResponseRepository,
  changeDateToUTC,
  identityRepository,
}) => async (task, response, userId) => {
  try {
    const id = identityRepository.createUuid();
    const taskResponse = TaskResponseFactory.create({
      taskResponseId: id,
      taskId: task.taskId,
      clientUserId: userId,
      completionDate: changeDateToUTC.change(),
      taskResponseReply: response,
    });

    await taskResponseRepository.saveResponse(taskResponse);
    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
