<template>
  <div>
    <general-table
      :headers="headers"
      :list.sync="documents"
      :columns="['actions']"
    >
      <template v-slot:actions="props">
        <v-btn
          icon
          color="primary"
          class="material-icons-round"
          @click="$emit('downloadDocumentDataEmployee', props.item)"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';

export default {
  name: 'list-document-data-employee',
  inject: {
    user: 'user',
    fetchDocumentsDataEmployee: 'fetchDocumentsDataEmployee',
  },
  components: {
    GeneralTable,
  },
  props: {
    employee: {
      type: Object,
      default: null,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('employee.document_data.table.document'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '50%',
        },
        {
          text: this.$t('employee.document_data.table.last_modification'),
          align: 'start',
          sortable: false,
          value: 'lastModification',
          width: '20%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '20%',
        },
      ];
    },
  },
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    async getContractsEmployee() {
      this.$showLoading();
      const response = await this.fetchDocumentsDataEmployee(this.employee.id, this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('employee.document_data.table.error');
        return;
      }

      this.documents = response.documentsData;
    },
  },
  beforeMount() {
    this.getContractsEmployee();
  },
};
</script>
