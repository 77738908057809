export default class SaveProcessingActivityFormStatus {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke(step, args) {
    this._processingActivityCache.setFormStatus({ step, args });
  }
}
