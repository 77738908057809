import TaskStatusValidationException from '@/domain/task/exceptions/TaskStatusValidationException';

const PENDING = 'pending';

const VALID_STATUSES = [
  PENDING,
];

export default class TaskStatus {
  status;

  constructor(taskStatus) {
    TaskStatus.isValid(taskStatus);
    this.status = taskStatus;
  }

  static isValid(taskStatus) {
    if (!taskStatus) {
      throw new TaskStatusValidationException('Task status should not be empty');
    }

    if (!VALID_STATUSES.includes(taskStatus)) {
      throw new TaskStatusValidationException('Task status does not exist');
    }
  }
}
