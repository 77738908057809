const addAdminInfo = ({ commit }, payload) => {
  commit('ADD_IS_SPANISH_ADMIN', payload.isSpanishAdmin);
  commit('ADD_ADMIN_COUNTRY_CODE', payload.countryCode);
  commit('ADD_ADMIN_LOGO_URL', payload.logo);
  commit('ADD_ADMIN_NAME', payload.name);
  commit('ADD_SUBDOMAIN', payload.subdomain);
  commit('ADD_ADMIN_COMPANY_ID', payload.adminCompanyId);
  commit('ADD_ADMIN_IS_B2B', payload.isB2b);
};

const resetAdminInfo = ({ commit }) => {
  commit('RESET_IS_SPANISH_ADMIN');
  commit('RESET_COUNTRY_CODE');
  commit('RESET_ADMIN_LOGO_URL');
  commit('RESET_ADMIN_NAME');
  commit('RESET_SUBDOMAIN');
};

export default {
  addAdminInfo,
  resetAdminInfo,
};
