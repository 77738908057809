import store from '@/store';
import {
  getManagement, postManagement, putManagement,
} from '@/shared/restActions';

function deleteUserInfo() {
  store.dispatch('user/resetUserInfo');
}

function getInfoByToken(token) {
  return getManagement(`/api/v2/anon/user/${token}`);
}

function activate(token, password) {
  postManagement('/api/v2/users/password', { password, token });
}

function changeLanguage(language) {
  return putManagement('/api/v2/user/change-language', { language });
}

function changePasswordSettings(currentPassword, newPassword) {
  return putManagement('/api/v2/user/changepassword', { currentPassword, newPassword });
}

export default {
  deleteUserInfo,
  getInfoByToken,
  changeLanguage,
  changePasswordSettings,
  activate,
};
