import DepartmentReadModel from '@/domain/department/listDepartment/DepartmentReadModel';
import Translator from '@/infrastructure/Translator';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';
import ClientId from '@/domain/client/ClientId';
import DepartmentProjection from '@/domain/department/listDepartment/DepartmentProjection';
import normalization from '@/services/shared/normalization';

export default class ListDepartmentQueryHandler {
  private readModel: DepartmentReadModel;

  private translator: Translator;

  constructor(readModel: DepartmentReadModel, translator: Translator) {
    this.readModel = readModel;
    this.translator = translator;
  }

  public async invoke(query: ListDepartmentQuery)
    : Promise<{ success: boolean, departments?: object[], error?: string }> {
    try {
      const departments: DepartmentProjection[] = await this.readModel
        .ofClient(ClientId.fromInt(query.clientId));

      const departmentTranslated = departments
        .map((dept: DepartmentProjection) => this.obtainTranslatedDepartment(dept));

      return {
        success: true,
        departments: departmentTranslated
          .sort(
            (dept1, dept2) => (normalization.normalizeString(dept1.name) < normalization.normalizeString(dept2.name) ? -1 : 1),
          ),
      };
    } catch (e) {
      return { success: false, error: e.response?.data?.data || '' };
    }
  }

  private obtainTranslatedDepartment(department: DepartmentProjection) {
    return {
      id: department.id,
      name: this.translator.translationExist(`departments.${department.name}`)
        ? this.translator.translate(`departments.${department.name}`)
        : department.name,
      model: department.model,
      key: department.model,
    };
  }
}
