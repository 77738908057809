<template>
  <v-row class="task">
    <v-col cols="12" class="task__title">
      <h3>{{ $t('tasks.pending_tasks_title') }}</h3>
      <v-divider/>
    </v-col>
    <v-col cols="12" class="task__content">
      <select-field
        :value.sync="tab"
        :items="userDepartments"
        :label="$t('tasks.department')"
        itemText="name"
        itemValue="id"
      />
      <v-tabs-items v-model="tab" :transition="false">
        <v-tab-item
          v-for="(department) in userDepartments"
          :value="department.id"
          :key="department.id"
          class="task__list--scroll"
          :style="heightScroll"
          :transition="false"
        >
          <tasks-department
            v-if="tab === 'all'"
            :groupListTasks.sync="groupListTasks"
            @taskResponded="getPendingTasks"
          />
          <tasks-department
            v-else
            :groupListTasks.sync="tasksGroupByDepartment[department.id]"
            @taskResponded="getPendingTasks"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import taskUsecases from '@/application/task/usecases';
import { fetchListDepartment } from '@/infrastructure/application/department/listDepartment';
import ListDepartmentQuery
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQuery';
import SelectField from '@/components/base/form/SelectField.vue';
import TasksDepartment from './TasksDepartment.vue';

export default {
  name: 'tasks-list',
  inject: {
    user: 'user',
  },
  components: {
    SelectField,
    TasksDepartment,
  },
  computed: {
    ...mapGetters('user', {
      clientId: 'companyId',
      userStoreDepartments: 'departments',
    }),
    userDepartments() {
      const listDepartments = [{
        name: this.$t('tasks.all'),
        id: 'all',
      }];
      if (!this.departments.length) {
        return listDepartments;
      }
      return [...listDepartments, ...this.departments];
    },
  },
  data() {
    return {
      groupListTasks: this.resetGroupTasks(),
      tasksGroupByDepartment: {},
      heightScroll: { height: '0px' },
      tab: 'all',
      departments: [],
    };
  },
  methods: {
    async getDepartmentsList() {
      if (!this.user.isEmployee) {
        await this.loadClientDepartments();
        return;
      }
      this.loadUserDepartments();
    },
    async loadClientDepartments() {
      const response = await fetchListDepartment
        .invoke(new ListDepartmentQuery(this.clientId));
      if (!response.success) {
        this.$showError('home.client.error.error_page_load');
        return;
      }
      this.departments = response.departments;
    },
    loadUserDepartments() {
      this.departments = this.user.departments.map((dept) => ({
        name: this.$t(`departments.${dept.name}`),
        id: dept.id,
      }));
    },
    async getPendingTasks() {
      const response = await taskUsecases.fetchPendingTasks(this.clientId);
      if (!response.success) {
        this.$showError('home.client.error.error_page_load');
        return;
      }

      this.hasPendingTasks = response.tasks.length > 0;
      const tasksList = await this.orderByDate(response.tasks);
      await this.groupTasksByGroupAndDepartment(tasksList);
    },
    groupTasksByGroupAndDepartment(pendingTasks) {
      this.$set(this.groupListTasks, {});
      this.$set(this.tasksGroupByDepartment, {});

      this.groupListTasks = this.resetGroupTasks();
      this.tasksGroupByDepartment = this.resetTasksGroupByDepartment();

      pendingTasks.forEach((task) => {
        if (Object.keys(this.tasksGroupByDepartment).includes(task.department)) {
          if (!this.groupListTasks[task.groupBy]) {
            this.$set(this.groupListTasks, `${task.groupBy}`, []);
          }
          if (!this.tasksGroupByDepartment[task.department][task.groupBy]) {
            this.$set(this.tasksGroupByDepartment[task.department], `${task.groupBy}`, []);
          }
          this.groupListTasks[task.groupBy].push(task);
          this.tasksGroupByDepartment[task.department][task.groupBy].push(task);
        }
      });
    },
    orderByDate(listTasks) {
      return listTasks.sort((task1, task2) => {
        let splitDate = task1.expirationDate.split('/');
        const date1 = new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);
        splitDate = task2.expirationDate.split('/');
        const date2 = new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`);

        return date1 - date2;
      });
    },
    resetGroupTasks() {
      return {
        critical: [],
        expired: [],
        today: [],
        tomorrow: [],
      };
    },
    resetTasksGroupByDepartment() {
      const listDepartments = {};
      this.userDepartments.forEach((department) => {
        listDepartments[department.id] = this.resetGroupTasks();
      });
      return listDepartments;
    },
    trackActions(department) {
      this.$track({
        action: 'Pending tasks tabs',
        tab: department,
      });
    },
  },
  async beforeMount() {
    this.$showLoading();
    await taskUsecases.resetStoreTasks();
    await this.getDepartmentsList();
    await this.getPendingTasks();

    const windowHeight = window.innerHeight;
    this.heightScroll.height = `${windowHeight - 230}px`;
    this.$eventHub.$on('taskUpdated', this.getPendingTasks);
    this.$hideLoading();
  },
  beforeDestroy() {
    this.$eventHub.$off('hideTasksAndShowOne');
    this.$eventHub.$off('taskUpdated');
  },
  watch: {
    async clientId() {
      await taskUsecases.resetStoreTasks();
      await this.getPendingTasks();
    },
    tab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$eventHub.$emit('hideTasksAndShowOne', null);
      }
    },
  },
};
</script>
