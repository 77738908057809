import ClientId from '@/domain/client/ClientId';

export default class FetchRecipientToReplacement {
  _recipientReadModel;

  constructor(recipientReadModel) {
    this._recipientReadModel = recipientReadModel;
  }

  async invoke(clientId, oldRecipientId) {
    try {
      const recipients = await this._recipientReadModel.ofClient(ClientId.fromInt(clientId));
      const recipientsFiltered = recipients.filter(
        (newRecipient) => this._isValidReplacement(newRecipient, oldRecipientId),
      );

      return {
        success: true,
        recipients: recipientsFiltered.sort((firstRecipient, secondRecipient) => (
          (firstRecipient.nameNormalized > secondRecipient.nameNormalized) ? 1 : -1)),
      };
    } catch (e) {
      return { success: false };
    }
  }

  _isValidReplacement(newRecipient, oldRecipientId) {
    return newRecipient.isStatusValidated
      && !newRecipient.isReplacement
      && !newRecipient.replacedBy
      && newRecipient.id !== oldRecipientId;
  }
}
