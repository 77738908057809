/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { DiagramNode } from './DiagramNode';

const generateId = () => Math.trunc(Math.random() * 1000);

/**
 * @class DiagramModel
 */
export class DiagramModel {
  /**
   */
  constructor() {
    this._model = {
      nodes: [],
      links: [],
    };
  }

  /**
   * Adds a node to the diagram
   * @param {String} title  The title of the node
   * @param {Integer} x      X coordinate
   * @param {Integer} y      Y Coordinate
   * @param {Integer} width  Width
   * @param {Integer} height Height
   * @return {Node} The node created
   */
  addNode(
    title,
    x,
    y,
    width,
    height,
    type,
    icon,
    class_bottom,
    text_top,
    text_bottom,
    tooltip,
  ) {
    const newNode = new DiagramNode(
      generateId(),
      title,
      x,
      y,
      width,
      height,
      type,
      icon,
      class_bottom,
      text_top,
      text_bottom,
      tooltip,
    );
    this._model.nodes.push(newNode);
    return newNode;
  }

  deleteNode(node) {
    const index = this._model.nodes.indexOf(node);
    for (let j = 0; j < this._model.links.length; j += 1) {
      const currentLink = this._model.links[j];

      for (let i = 0; i < node.ports.length; i += 1) {
        const currentPort = node.ports[i];

        if (
          currentLink.from === currentPort.id
          || currentLink.to === currentPort.id
        ) {
          this.deleteLink(currentLink);
          j -= 1;
        }
      }
    }
    this._model.nodes.splice(index, 1);
  }

  deleteLink(link) {
    const index = this._model.links.indexOf(link);
    this._model.links.splice(index, 1);
  }

  /**
   * Adds a link between two ports
   * @param {Integer} from   Port id. Must be an out port
   * @param {Integer} to     Port id. Must be an in port
   * @param {Array}  points  Optional. Array of points to
   * make the link represented as a segmented line
   */
  addLink(from, to, points = []) {
    this._model.links.push({
      id: generateId(),
      from,
      to,
      positionFrom: {},
      positionTo: {},
      points,
    });
  }

  /**
   * Serializes the diagram model into a JSON object
   * @return {Object} The diagram model
   */
  serialize() {
    return JSON.stringify(this._model);
  }

  /**
   * Load into the diagram model a serialized diagram
   * @param  {Object} serializedModel
   */
  deserialize(serializedModel) {
    this._model = JSON.parse(serializedModel);
  }
}

export default DiagramModel;
