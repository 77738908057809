const addRecipients = ({ commit }, recipients) => {
  commit('ADD_RECIPIENTS', recipients);
};

const resetRecipients = ({ commit }) => {
  commit('RESET_RECIPIENTS');
};

export default {
  addRecipients,
  resetRecipients,
};
