export const initialState = {
  loadingPage: false,
  snackBar: {
    text: '',
    parameters: {},
    default: null,
    type: 'error',
    show: false,
  },
};

const state = () => ({ ...initialState });

export default state();
