<template>
  <forgot-password-container />
</template>
<script>
import ForgotPasswordContainer from '@/components/containers/ForgotPasswordContainer.vue';

export default {
  name: 'forgot-password',
  components: {
    ForgotPasswordContainer,
  },
};
</script>
