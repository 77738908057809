import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';

export default class ResetDataBreaches {
  private readonly dataBreachRepository: DataBreachRepository;

  constructor(dataBreachRepository: DataBreachRepository) {
    this.dataBreachRepository = dataBreachRepository;
  }

  public async invoke(): Promise<void> {
    await this.dataBreachRepository.reset();
  }
}
