<template>
  <v-tabs-items
    v-model="tab"
  >
    <v-tab-item
      key="categories"
      id="categories"
      :transition="false"
    >
      <v-row class="activity-step activity-step__recipient">
          <v-col cols="12" class="text-center">
            <h2>{{ $t('processing_activities.step_recipients.where_data_share') }}</h2>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(recipient, key) in recipients"
              :key="key"
            >
              <v-col cols="12" md="5" class="activity-step__recipient-block">
                <div class="activity-step__recipient-category">
                  <p>{{ recipient.categoryName }}</p>
                  <v-icon
                    small
                    color="primary"
                    @click="deleteRecipientCategory(key)"
                  >
                    close
                  </v-icon>
                </div>
              </v-col>
              <v-col cols="12" md="7" class="activity-step__recipient-recipient">
                <div
                  v-if="!recipient.selection.length"
                  class="activity-step__recipient-recipient--edit"
                  @click="addRecipients(key)"
                >
                  <v-btn
                    color="primary"
                    outlined
                    icon
                    small
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                  <a>
                    {{ $t('processing_activities.step_recipients.add_recipients') }}
                  </a>
                </div>
                <div
                  v-else
                  class="activity-step__recipient-recipient--list"
                >
                  <p>
                    <span
                      v-for="(item, index) in recipient.selection"
                      :key="index"
                    >
                      <span v-if="index < 4">
                        {{ item | recipientTranslation(recipientsList) }}
                      </span>
                      <span v-if="(index < 3 && recipient.selection.length - 1 !== index)">
                        ,
                      </span>
                      <span v-if="index === 3">
                        ...
                      </span>
                    </span>
                  </p>
                  <v-btn
                    color="primary"
                    outlined
                    icon
                    small
                    @click="addRecipients(key)"
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              :disabled="isBtnDisabled()"
              @click="$emit('constructRecipientsGroups')"
            >
              {{ $t('processing_activities.step_recipients.continue') }}
            </v-btn>
          </v-col>
        </v-row>
    </v-tab-item>
    <v-tab-item
      key="recipients"
      id="recipients"
      :transition="false"
    >
      <v-row class="activity-step" v-if="recipients.length">
        <v-col cols="12" class="text-center">
          <h2>
            {{ $t('processing_activities.step_recipients.recipient_share') }}
          </h2>
        </v-col>
        <v-col
          ref="scrollCheckboxButtons"
          cols="12"
          class="activity-step__recipient-selection"
        >
          <checkbox-button-field
            :value.sync="recipients[positionRecipient].selection"
            :items.sync="recipientsList"
            :cols="4"
          />
        </v-col>
        <v-col cols="12">
          <input-field
            :value.sync="newRecipient"
            :label="$t('processing_activities.step_recipients.add_new_recipient')"
            :disabled.sync="disabledInput"
            @keyUpEnter="saveNewRecipient"
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="primary"
            :disabled="!recipients[positionRecipient].selection.length"
            rounded
            @click="recipientSelected"
          >
            {{ $t('processing_activities.step_recipients.continue') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import processingActivityStepsServices from '@/services/questionnaires/processingActivities/steps';

import CheckboxButtonField from '../../../base/form/CheckboxButtonField.vue';
import InputField from '../../../base/form/InputField.vue';

export default {
  name: 'recipients-deprecated',
  components: {
    CheckboxButtonField,
    InputField,
  },
  props: {
    recipientsSelected: {
      type: Array,
      default: () => [],
    },
    recipientsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      recipients: this.recipientsSelected,
      tab: processingActivityStepsServices.getFirstStepOfRecipients(),
      positionRecipient: 0,
      newRecipient: '',
      disabledInput: false,
    };
  },
  methods: {
    deleteRecipientCategory(index) {
      this.positionRecipient = 0;
      this.recipients.splice(index, 1);
      this.$emit('update:recipients', this.recipients);
      this.$emit('goRecipientsCategorySelection', true);
    },
    addRecipients(index) {
      this.positionRecipient = index;
      this.tab = processingActivityStepsServices.getNextStepOfRecipients(this.tab);
    },
    recipientSelected() {
      this.tab = processingActivityStepsServices.getNextStepOfRecipients(this.tab);
    },
    saveNewRecipient() {
      if (this.newRecipient !== '' && !this.disabledInput) {
        this.disabledInput = true;
        this.$emit('saveNewRecipients', this.newRecipient, this.positionRecipient);
      }
    },
    isBtnDisabled() {
      let totalCompletedSelection = 0;
      this.recipientsSelected.forEach((obj) => {
        if (obj.selection.length > 0) {
          totalCompletedSelection += 1;
        }
      });
      return !(totalCompletedSelection === this.recipientsSelected.length);
    },
  },
  mounted() {
    this.recipients = this.recipientsSelected;
  },
  watch: {
    recipientsSelected() {
      this.recipients = this.recipientsSelected;
    },
    recipientsList() {
      this.$vuetify.goTo(1000, { container: this.$refs.scrollCheckboxButtons });

      this.newRecipient = '';
      this.disabledInput = false;
    },
  },
  filters: {
    recipientTranslation(recipientId, recipientsList) {
      let recipientTranslation = '';
      recipientsList.forEach((obj) => {
        if (obj.id === recipientId) {
          recipientTranslation = obj.name;
        }
      });
      return recipientTranslation;
    },
  },
};
</script>
