const addDataBreachesHistoric = ({ commit }, dataBreachesHistoric) => {
  commit('ADD_DATA_BREACHES_HISTORIC', dataBreachesHistoric);
};

const addDataBreaches = ({ commit }, dataBreaches) => {
  commit('ADD_DATA_BREACHES', dataBreaches);
};

const addDataBreachesPending = ({ commit }, dataBreachesPensing) => {
  commit('ADD_DATA_BREACHES_PENDING', dataBreachesPensing);
};

const resetDataBreachesHistoric = ({ commit }) => {
  commit('RESET_DATA_BREACHES_HISTORIC');
};

const resetDataBreaches = ({ commit }) => {
  commit('RESET_DATA_BREACHES');
};

const resetDataBreachesPending = ({ commit }) => {
  commit('RESET_DATA_BREACHES_PENDING');
};

export default {
  addDataBreachesHistoric,
  addDataBreaches,
  addDataBreachesPending,
  resetDataBreachesHistoric,
  resetDataBreaches,
  resetDataBreachesPending,
};
