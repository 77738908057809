<template>
  <v-container fluid class="home-page">
    <v-row>
      <v-col cols="12">
        <h1>{{ $t('home.admin.welcome', { userName }) }}</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <p>
              {{ $t('home.admin.view_client_userdesk') }}
            </p>
          </v-col>
          <v-col cols="12" md="6" offset-md="3">
            <div class="selected_client">
              <select-client
                :label="$t('home.admin.select_client_label')"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h2>
              {{ $t('home.admin.title_last_uploaded_files') }}
            </h2>
            <default-table
              :items.sync="filesList"
              :headers="headers"
              sortBy="LastModified"
              :sortDesc="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import adminsUseCases from '@/usecases/admins';
import SelectClient from '../base/form/SelectClient.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';

export default {
  name: 'home-admin-container',
  components: {
    SelectClient,
    DefaultTable,
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', {
      userName: 'userName',
      clientId: 'companyId',
    }),
    headers() {
      return [
        {
          text: this.$t('home.admin.table_headers.document'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '40%',
        },
        {
          text: this.$t('home.admin.table_headers.client'),
          align: 'left',
          sortable: true,
          value: 'client',
          width: '30%',
        },
        {
          text: this.$t('home.admin.table_headers.last_modification'),
          align: 'right',
          sortable: true,
          value: 'LastModified',
          width: '30%',
        },
      ];
    },
  },
  data() {
    return {
      filesList: [],
    };
  },
  methods: {
    async getFilesList() {
      const result = await adminsUseCases.fetchLastUploadedDocuments();
      if (result.success) {
        return result.data;
      }
      return [];
    },
  },
  async beforeMount() {
    this.$showLoading();
    this.filesList = await this.getFilesList();
    this.$hideLoading();
  },
};
</script>
