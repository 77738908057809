<template>
  <v-container class="legal-content">
    <h1>Userdesk</h1>
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'legal-layout',
  beforeMount() {
    const lang = navigator.language || navigator.userLanguage;
    const langcode = lang.split('-')[0];
    this.$i18n.locale = langcode;
  },
};
</script>
