import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import UserRepository from '@/domain/user/UserRepository';

export default class RecoverPassword {
  private readonly userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  public async invoke(email: string): Promise<object> {
    try {
      await this.userRepository.recoverPassword(EmailValueObject.fromString(email));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
