import AbstractLanguageRepresentation from '@/domain/language/AbstractLanguageRepresentation';
import LanguageValidationException from './LanguageValidationException';

export default class Language {
  _code: string;

  static LANGUAGES: string[] = [
    'en',
    'es',
    'de',
    'ca',
  ];

  static LANGUAGES_PRIORITIES: string[] = [
    'en',
    'es',
    'de',
    'ca',
  ];

  constructor(language: string) {
    this.isValidOrFail(language);
    this._code = language;
  }

  isValidOrFail(language: string): void {
    if (!language) {
      throw new LanguageValidationException(`${this.constructor.name} should not be empty`);
    }

    if (!Language.LANGUAGES.includes(language)) {
      throw new LanguageValidationException(`${this.constructor.name} code does not exist`);
    }
  }

  representedAs(languageRepresentation: AbstractLanguageRepresentation):
    AbstractLanguageRepresentation {
    languageRepresentation.setCode(this._code);
    return languageRepresentation;
  }

  static all() {
    return Language.LANGUAGES.map((lang) => new Language(lang));
  }

  static getPriorityLanguages(): Language[] {
    return Language.LANGUAGES_PRIORITIES.map((lang: string) => new this(lang));
  }

  public toString(): string {
    return this._code;
  }

  public isEquals(code: string): boolean {
    return this._code === code;
  }

  public static fromString(language: string): Language {
    return new this(language);
  }
}
