<template>
  <v-row>
    <v-col cols="6">
      <input-search
        :value.sync="search"
        :label="$t('toms.search_bar')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right">
      <download-document-button
        v-if="showDownloadButton"
        :id="user.clientId"
        :btnMsg="$t('toms.implemented_toms.export_list')"
        :errorMsg="$t('toms.implemented_toms.error.export_list')"
        docType="implemented-tom-list"
        actionTrack="Download implemented tom"
      />
    </v-col>
    <v-col cols="12">
      <list-tom
        v-if="isCurrentTab"
        :search="search"
        statusFilter="implemented"
        noDataText="toms.implemented_toms.empty"
      />
    </v-col>
  </v-row>
</template>

<script>
import ListTom from '@/components/modules/tom/ListTom.vue';
import InputSearch from '@/components/base/form/InputSearch.vue';
import DownloadDocumentButton from '@/components/base/section/DownloadDocumentButton.vue';

export default {
  name: 'implemented-tom-section',
  inject: {
    user: 'user',
  },
  components: {
    DownloadDocumentButton,
    InputSearch,
    ListTom,
  },
  props: {
    isCurrentTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      showDownloadButton: false,
    };
  },
  methods: {
    hasImplementedTom(tomList) {
      const implementedTom = tomList.find((tom) => tom.status === 'implemented');
      this.showDownloadButton = !!implementedTom;
    },
  },
  beforeMount() {
    this.$eventHub.$on('tomListLoaded', this.hasImplementedTom);
  },
  beforeDestroy() {
    this.$eventHub.$off('tomListLoaded', this.hasImplementedTom);
  },
};
</script>
