import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class UserToken extends StringValueObject {
  constructor(token: string) {
    super(token, 64, 64);
  }

  static fromString(token: string) {
    return new this(token);
  }
}
