import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ClientUser from '@/domain/clientUser/ClientUser';
import ClientUserToUpdate from '@/domain/clientUser/ClientUserToUpdate';
import EventBus from '@/application/eventBus/EventBus';
import ClientUserWasUpdated from '@/domain/clientUser/ClientUserWasUpdated';

export default class EditClientUser {
  private readonly clientUserRepository: ClientUserRepository;

  private readonly eventBus: EventBus;

  constructor(clientUserRepository: ClientUserRepository, eventBus: EventBus) {
    this.clientUserRepository = clientUserRepository;
    this.eventBus = eventBus;
  }

  public async invoke(clientUser: ClientUser, clientUserToUpdate: ClientUserToUpdate) {
    try {
      clientUser.update(clientUserToUpdate);
      await this.clientUserRepository.update(clientUser);
      this.eventBus.handle(ClientUserWasUpdated.fromClientUserId(clientUser.getId()));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
