import fetchDocumentsListBuilder from '@/usecases/documents/risksPias/fetchDocumentsList';
import fetchTypeOfDocumentBuilder from '@/usecases/documents/risksPias/fetchTypeOfDocument';
import downloadDocumentBuilder from '@/usecases/documents/risksPias/downloadDocument';
import viewDocumentBuilder from '@/usecases/documents/risksPias/viewDocument';
import risksPiasRepository from '@/repositories/documents/risksPias';
import documentViewer from '@/services/documents/documentViewer';
import downloadFile from '@/services/documents/downloadFile';
import documentFiltersService from '@/services/documents/documentFilters';

export default {
  fetchDocumentsList: fetchDocumentsListBuilder({ risksPiasRepository }),
  fetchTypeOfDocument: fetchTypeOfDocumentBuilder({ documentFiltersService }),
  downloadDocument: downloadDocumentBuilder({ risksPiasRepository, downloadFile }),
  viewDocument: viewDocumentBuilder({ risksPiasRepository, documentViewer }),
};
