export default ({
  risksPiasRepository,
  documentViewer,
}) => async (documentId, typeDocument, lang, nameDocument, companyId, isAdmin) => {
  try {
    const urlView = await risksPiasRepository.viewDocument(
      documentId, typeDocument, lang, companyId, isAdmin,
    );

    const url = urlView.preview_url || urlView.url;
    documentViewer.setDocumentInformation({ url, name: nameDocument });
    documentViewer.showDocumentViewer();
    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
