export default {
  dataBreachesHistoric(state) {
    return state.dataBreachesHistoric;
  },
  dataBreaches(state) {
    return state.dataBreaches;
  },
  dataBreachesPending(state) {
    return state.dataBreachesPending;
  },
};
