import ClientUserWasUpdatedEventListener
  from '@/application/clientUser/fetchClientUsers/ClientUserWasUpdatedEventListener';
import ClientUserWriteModelApi from '@/infrastructure/clientUser/ClientUserWriteModelApi';
import ClientUserRepositoryApi from '@/infrastructure/clientUser/ClientUserRepositoryApi';
import ObtainDepartmentName from '@/domain/department/listDepartment/ObtainDepartmentName';
import DepartmentReadModelApi
  from '@/infrastructure/department/listDepartment/DepartmentReadModelApi';
import Translator from '@/infrastructure/Translator';

export default class ClientUserWasUpdatedEventListerBuilder {
  public static build() {
    return new ClientUserWasUpdatedEventListener(
      new ClientUserRepositoryApi(),
      new ClientUserWriteModelApi(),
      new ObtainDepartmentName(new DepartmentReadModelApi()),
      new Translator(),
    );
  }
}
