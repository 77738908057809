import DepartmentId from '@/domain/department/DepartmentId';
import DepartmentName from '@/domain/department/DepartmentName';

export default abstract class DepartmentRepresentation {
  protected _id!: DepartmentId;

  protected _name!: DepartmentName[];

  public setId(id: DepartmentId): this {
    this._id = id;
    return this;
  }

  public setName(name: DepartmentName[]): this {
    this._name = name;
    return this;
  }
}
