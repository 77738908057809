import EquipmentLocationValidationException from './EquipmentLocationValidationException';
import UuidValueObject from '../../shared/uuid/UuidValueObject';

export default class EquipmentLocation {
  _type;

  _key;

  static HEADQUARTERS = 'headquarters'

  static DEFAULT = 'default'

  static VALID_TYPES = [
    EquipmentLocation.HEADQUARTERS,
    EquipmentLocation.DEFAULT,
  ];

  static VALID_DEFAULT_KEYS = [
    'external',
    'remote',
  ];

  constructor(type, key) {
    this.isValidType(type);
    this._type = type;
    this.isValidKey(key);
    this._key = key;
  }

  isValidType(type) {
    if (!type) {
      throw new EquipmentLocationValidationException('Equipment location type should not be empty');
    }

    if (!EquipmentLocation.VALID_TYPES.includes(type)) {
      throw new EquipmentLocationValidationException('Equipment location type does not exist');
    }
  }

  isValidKey(key) {
    if (!key) {
      throw new EquipmentLocationValidationException('Equipment location key should not be empty');
    }

    if (this.isHeadquartersType() && !UuidValueObject.isValid(key)) {
      throw new EquipmentLocationValidationException('Equipment location key is not a valid uuid');
    }

    if (this.isDefaultType() && !EquipmentLocation.VALID_DEFAULT_KEYS.includes(key)) {
      throw new EquipmentLocationValidationException('Equipment location key does not exist');
    }
  }

  isHeadquartersType() {
    return EquipmentLocation.HEADQUARTERS === this._type;
  }

  isDefaultType() {
    return EquipmentLocation.DEFAULT === this._type;
  }

  representedAs(locationRepresentation) {
    locationRepresentation.setKey(this._key);
    locationRepresentation.setType(this._type);
    locationRepresentation.setIsHeadquarterType(this.isHeadquartersType(this._type));
    return locationRepresentation;
  }

  toString() {
    return this._key;
  }

  jsonSerialize() {
    return { key: this._key, type: this._type };
  }

  static all(headquartersIds) {
    const locationsDefault = this.VALID_DEFAULT_KEYS.map(
      (key) => new this(EquipmentLocation.DEFAULT, key),
    );

    const locationsHeadquarters = headquartersIds.map(
      (id) => new this(EquipmentLocation.HEADQUARTERS, id),
    );

    return locationsDefault.concat(locationsHeadquarters);
  }
}
