import TomRepresentation from '@/domain/tom/TomRepresentation';
import Translator from '@/infrastructure/Translator';
import { TranslateResult } from 'vue-i18n';

export default class TomsRepresentation extends TomRepresentation {
  private readonly translator: Translator;

  private listTranslation: TranslateResult | null = null;

  private nameTranslation: TranslateResult | null = null;

  private typeTranslation: TranslateResult | null = null;

  private categoryTranslation: TranslateResult | null = null;

  private statusTranslation: TranslateResult | null = null;

  private descriptionTranslation: TranslateResult | null = null;

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  get id(): string {
    return this._id.toString();
  }

  get type(): string {
    return this._type.toString();
  }

  get typeTranslated(): TranslateResult {
    if (!this.typeTranslation) {
      this.typeTranslation = this.translator.translate(`toms.table.tooltip_${this._type.toString()}`);
    }

    return this.typeTranslation;
  }

  get name(): string {
    return this._name.toString();
  }

  get nameTranslated(): TranslateResult {
    if (!this.nameTranslation) {
      this.nameTranslation = this.translator.translate(this._name.toString());
    }

    return this.nameTranslation;
  }

  get list(): string {
    return this._classification.getList().toString();
  }

  get listTranslated(): TranslateResult {
    if (!this.listTranslation) {
      this.listTranslation = this.translator.translate(
        `tom.${this.list}.name`,
      );
    }
    return this.listTranslation;
  }

  get category(): string {
    return this._classification.getCategory()?.toString() || '';
  }

  get categoryTranslated(): TranslateResult | string {
    if (this.categoryTranslation !== null) {
      return this.categoryTranslation;
    }

    this.categoryTranslation = '';

    if (this.translator.translationExist(`tom.${this.list}.category.${this.category}`)) {
      this.categoryTranslation = this.translator.translate(`tom.${this.list}.category.${this.category}`);
    }

    return this.categoryTranslation;
  }

  get referenceCode(): string {
    return this._referenceCode?.toString() || '';
  }

  get status(): string {
    return this._status.toString();
  }

  get statusTranslated(): TranslateResult {
    if (!this.statusTranslation) {
      this.statusTranslation = this.translator.translate(
        `tom.status.${this.status}`,
      );
    }
    return this.statusTranslation;
  }

  get description(): string {
    return this._description.toString();
  }

  get descriptionTranslated(): TranslateResult | string {
    if (this.descriptionTranslation !== null) {
      return this.descriptionTranslation;
    }
    this.descriptionTranslation = '';

    if (this.translator.translationExist(this.description)) {
      this.descriptionTranslation = this.translator.translate(this.description);
    }

    return this.descriptionTranslation;
  }
}
