import {
  getManagement,
  postManagement,
  deleteMethodManagement,
  putManagement,
} from '@/shared/restActions';
import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import ClientId from '@/domain/client/ClientId';
import DataBreach from '@/domain/dataBreach/DataBreach';
import DataBreachRawRepresentation from '@/infrastructure/dataBreach/DataBreachRawRepresentation';
import DataBreachCache from '@/infrastructure/dataBreach/DataBreachCache';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachBuilder from '@/domain/dataBreach/DataBreachBuilder';
import DataBreachRaw from '@/infrastructure/dataBreach/DataBreachRaw';
import DataBreachCollaborator from '@/domain/dataBreach/collaborator/DataBreachCollaborator';

export default class DataBreachRepositoryApi implements DataBreachRepository {
  async reset(): Promise<void> {
    await DataBreachCache.reset();
  }

  public async save(clientId: ClientId, dataBreach: DataBreach): Promise<void> {
    try {
      const dataBreachRawRepresentation:
        DataBreachRawRepresentation = <DataBreachRawRepresentation>dataBreach.representedAs(
          new DataBreachRawRepresentation(),
        );

      await postManagement(
        `/api/v2/clients/${clientId.toString()}/data-breaches`,
        dataBreachRawRepresentation.toJson(),
      );

      DataBreachCache.save(dataBreach);
    } catch (e) {
      throw new Error('Error saving data breach');
    }
  }

  public async retrieve(dataBreachId: DataBreachId): Promise<DataBreach> {
    try {
      const dataBreachCache = DataBreachCache.retrieve(dataBreachId);

      if (dataBreachCache) {
        return dataBreachCache;
      }

      const dataBreachRaw: DataBreachRaw = await DataBreachRepositoryApi
        .getDataBreachFromApi(dataBreachId);
      const dataBreach: DataBreach = DataBreachRepositoryApi.buildDataBreach(
        dataBreachRaw,
        dataBreachId,
      );

      DataBreachCache.save(dataBreach);

      return dataBreach;
    } catch (e) {
      throw new Error(`Error getting data breach with id: ${dataBreachId.toString()}`);
    }
  }

  private static async getDataBreachFromApi(dataBreachId: DataBreachId): Promise<DataBreachRaw> {
    const dataBreachRaw = await getManagement(
      `/api/v2/data-breaches/${dataBreachId.toString()}`,
    );
    return dataBreachRaw.data;
  }

  private static buildDataBreach(
    dataBreachInfo: DataBreachRaw,
    dataBreachId: DataBreachId,
  ): DataBreach {
    const dataBreachBuilder = new DataBreachBuilder();

    dataBreachBuilder
      .withId(dataBreachId.toString())
      .withStatus(dataBreachInfo.status)
      .withCreatedDate(dataBreachInfo.created_date)
      .withCreatedBy(dataBreachInfo.created_by)
      .withAttachedDocuments(dataBreachInfo.attached_documents)
      .withWhatHappens({
        occurredOn: dataBreachInfo.occurred_on,
        discoveredOn: dataBreachInfo.discovered_on,
        description: dataBreachInfo.description,
        howDiscovered: dataBreachInfo.how_discovered,
        wasIntentional: dataBreachInfo.was_intentional,
      })
      .withAffectedDataInformation({
        compromisedData: dataBreachInfo.compromised_data,
        affectedProfiles: dataBreachInfo.affected_profiles,
        consequences: dataBreachInfo.consequences,
        affectedCountries: dataBreachInfo.affected_countries,
        numberAffected: dataBreachInfo.number_affected,
      })
      .withPreventMeasures({
        securityMeasuresImplemented: dataBreachInfo.security_measures_implemented,
        futureSecurityMeasures: dataBreachInfo.future_security_measures,
        securityMeasuresProblems: dataBreachInfo.security_measures_problems,
        informedAffectedPeople: dataBreachInfo.informed_affected_people,
        informedAuthorities: dataBreachInfo.informed_authorities,
      })
      .withDpoAnswer({
        evaluation: dataBreachInfo.evaluation,
        agencyNotification: dataBreachInfo.agency_notification,
        reportLanguage: dataBreachInfo.report_language,
      });

    return dataBreachBuilder.create();
  }

  public async delete(dataBreachId: DataBreachId): Promise<void> {
    await deleteMethodManagement(`/api/v2/data-breaches/${dataBreachId.toString()}`);
  }

  public async update(dataBreach: DataBreach): Promise<void> {
    try {
      const dataBreachRawRepresentation:
        DataBreachRawRepresentation = <DataBreachRawRepresentation>dataBreach.representedAs(
          new DataBreachRawRepresentation(),
        );

      await putManagement(
        `/api/v2/clients/data-breaches/${dataBreach.getId().toString()}`,
        dataBreachRawRepresentation.toJson(),
      );

      DataBreachCache.update(dataBreach);
    } catch (e) {
      throw new Error('Error updating data breach');
    }
  }

  public async addCollaborator(
    dataBreachId: DataBreachId, collaborator: DataBreachCollaborator,
  ): Promise<void> {
    try {
      await postManagement(
        `/api/v2/data-breaches/${dataBreachId.toString()}/collaborators`,
        collaborator.toObject(),
      );
    } catch (e) {
      throw new Error((e.response.data) ? e.response.data.data : 'Error inviting collaborator');
    }
  }
}
