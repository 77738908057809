export default {
  models(state) {
    return state.models;
  },

  processingActivity(state) {
    return state.processingActivity;
  },

  formStatus(state) {
    return state.formStatus;
  },
};
