<template>
  <general-table
    :headers="headers"
    :list="trainingEmployees"
    :columns="['certificates']"
  >
      <template v-slot:certificates="props">
        <ul class="list-none">
          <li
            v-for="(inscription, index) in props.item.inscriptions"
            :key="index"
            class="mb-3 mt-3"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="material-icons-round mr-1"
                  v-on="on"
                  :color="getColorByStatus(inscription.isCompleted)"
                >
                 {{ getIconByStatus(inscription.isCompleted) }}
                </v-icon>
              </template>
              <span>
                {{ $t(`training.employees_trainings.table.tooltip.${inscription.status}`)}}
              </span>
            </v-tooltip>
            {{ inscription.name }}
            <v-btn
              v-if="inscription.isCompleted"
              color="primary"
              outlined
              rounded
              @click="downloadTrainingCertificate(inscription.id, inscription.name)"
            >
              <v-icon
                class="material-icons-round"
              >
                file_download
              </v-icon>
              PDF
            </v-btn>
          </li>
        </ul>
      </template>
  </general-table>
</template>

<script>
import GeneralTable from '../../../base/tables/GeneralTable.vue';

export default {
  name: 'list-employees-trainings',
  inject: {
    user: 'user',
    fetchTrainingEmployees: 'fetchTrainingEmployees',
    downloadDocument: 'downloadDocument',
  },
  components: {
    GeneralTable,
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('training.employees_trainings.table.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '25%',
        },
        {
          text: this.$t('training.employees_trainings.table.email'),
          align: 'start',
          sortable: true,
          value: 'email',
          width: '25%',
        },
        {
          text: this.$t('training.employees_trainings.table.courses'),
          align: 'start',
          sortable: false,
          value: 'certificates',
          width: '50%',
        },
      ];
    },
  },
  data() {
    return {
      trainingEmployees: [],
    };
  },
  methods: {
    async loadEmployeesTrainings() {
      this.$showLoading();
      const response = await this.fetchTrainingEmployees.invoke(this.user.clientId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('training.employees_trainings.error.load');
        return;
      }

      this.trainingEmployees = response.trainingEmployees;
    },
    getColorByStatus(status) {
      return status ? 'success' : 'warning';
    },
    getIconByStatus(status) {
      return status ? 'check_circle' : 'pending';
    },
    async downloadTrainingCertificate(documentId, documentName) {
      this.$showLoading();
      const response = await this.downloadDocument.invoke('training-certificate', documentId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('errorPage.error_download');
      }

      this.$track({
        action: 'download training certificate',
        result: response.success,
        training_certificate: documentName,
      });
    },
  },
  beforeMount() {
    this.loadEmployeesTrainings();
  },
};
</script>
