<template>
  <div class="h-100 w-100">
    <v-autocomplete
      v-model="itemsSelected"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :no-data-text="noDataText"
      :search-input.sync="searchInput"
      :multiple="multiple"
      :chips="multiple"
      :small-chips="multiple"
      :filter="searchCustomFilter"
      :rules="rules"
      filled
      append-icon="mdi-chevron-down"
      :attach="attach"
      class="select-autcomplete select-autcomplete__custom"
      @change="searchInput = ''"
      @input="inputWasChanged"
      @blur="unfocused"
    >
      <template v-slot:prepend-inner v-if="tooltip">
        <v-tooltip bottom >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              {{ tooltip.icon }}
            </v-icon>
          </template>
          {{ tooltip.text }}
        </v-tooltip>
      </template>
      <template v-slot:selection="data" v-if="multiple">
        <v-chip
          v-if="data.index < 2"
          v-bind="data.attr"
          :input-value="data.selected"
          small
        >
          <span v-if="data.index === 0">
            {{ data.item.name }}
          </span>
          <span v-if="data.index === 1">
            ...
          </span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import normalizationService from '@/services/shared/normalization';

export default {
  name: 'select-autocomplete-field',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: [String, Array, Number, Object],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: [String, Function],
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: Object,
      default: () => null,
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noDataText() {
      if (this.searchInput === '' || !this.searchInput) {
        return this.$t('customSelect.no_element');
      }
      return this.$t('customSelect.no_result_search');
    },
  },
  data() {
    return {
      searchInput: '',
      itemsSelected: this.selectedItems,
    };
  },
  methods: {
    searchCustomFilter(item, queryString, itemText) {
      const searchNorm = normalizationService.normalizeString(queryString);
      const itemNorm = normalizationService.normalizeString(itemText);
      return itemNorm.includes(searchNorm);
    },
    inputWasChanged(value) {
      if (!this.multiple && this.selectedItems === value) {
        this.itemsSelected = '';
      }

      this.$emit('update:selectedItems', this.itemsSelected);
    },
    unfocused() {
      if (!this.multiple && this.searchInput === '') {
        this.itemsSelected = '';
        this.$emit('update:selectedItems', this.itemsSelected);
      }
    },
  },
  watch: {
    selectedItems() {
      this.itemsSelected = this.selectedItems;
    },
  },
};
</script>
