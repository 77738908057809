<template>
  <div>
    <v-btn
      outlined
      rounded
      color="primary"
      @click="showProcessingActivitiesModal"
    >
     {{ recipient.processingActivities }} {{ $t('recipient.processingActivities.button') }}
    </v-btn>
    <v-dialog
      v-if="showModal"
      v-model="showModal"
      persistent
      max-width="60%"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h2>
            {{ $t('recipient.processingActivities.title', {'recipient': recipient.name}) }}
          </h2>
          <v-spacer />
          <v-btn
            icon
            @click="hideModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <list-processing-activities
            :recipient-id="recipient.id"
            @downloadProcessingActivity="showBannerFileTypeDocument"
          />
        </v-card-text>
        <v-card-actions
          v-show="!showBannerFooterDownload"
          class="text-right"
        >
          <v-spacer/>
          <v-btn
            rounded
            color="primary"
            @click="hideModal"
          >
            {{ $t('recipient.processingActivities.close') }}
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <banner-footer-download
            v-show="showBannerFooterDownload"
            :multiple-type="false"
            type-document="processing-activity"
            :id="processingActivityToDownload.id"
            :show.sync="showBannerFooterDownload"
            :fileName="processingActivityToDownload.name"
          />
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import ListProcessingActivities
  from '@/components/modules/register/recipients/processingActivities/ListProcessingActivities.vue';
import BannerFooterDownload from '@/components/base/section/BannerFooterDownload.vue';

export default {
  name: 'processing-activities-modal',
  components: {
    BannerFooterDownload,
    ListProcessingActivities,
  },
  props: {
    recipient: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showBannerFooterDownload: false,
      processingActivityToDownload: {},
    };
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showProcessingActivitiesModal() {
      this.showModal = true;
    },
    async showBannerFileTypeDocument(processingActivity) {
      await this.setDataProcessingActivity(processingActivity);
      this.showBannerFooterDownload = true;
    },
    setDataProcessingActivity(processingActivity) {
      this.processingActivityToDownload = processingActivity;
    },
  },
  watch: {
    showModal() {
      this.showBannerFooterDownload = false;
    },
  },
};
</script>
