export default class DepartmentProjection {
  public readonly id: string;

  public readonly name: string;

  public readonly model: string | null;

  constructor(id: string, name: string, model: string | null = null) {
    this.id = id;
    this.name = name;
    this.model = model;
  }
}
