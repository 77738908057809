export default class DownloadFile {
  invoke(url: string): void {
    const fileLink: HTMLAnchorElement = document.createElement('a');
    fileLink.href = url;
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  }
}
