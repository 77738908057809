import EmployeeRepresentation from '@/domain/employee/EmployeeRepresentation';

export default class EmployeeToUpdateRepresentation extends EmployeeRepresentation {
  get id(): string {
    return this._id!.toString();
  }

  get name(): string {
    return this._name!.toString();
  }

  get surname(): string {
    return this._surname!.toString();
  }

  get email(): string {
    return (this._email) ? this._email.toString() : '';
  }

  get position(): string {
    return (this._position) ? this._position.toString() : '';
  }

  get entry(): string | null {
    return (this._entry) ? this._entry.toStringDate() : null;
  }

  get exit(): string | null {
    return (this._exit) ? this._exit.toStringDate() : null;
  }

  get nif(): string {
    return (this._nif) ? this._nif.toString() : '';
  }

  get documentData(): number {
    return (this._documentData) ? this._documentData.toInt() : 0;
  }
}
