import RecipientRoleValidationException from '@/domain/recipient/recipientRole/RecipientRoleValidationException';

export default class RecipientRole {
  _role;

  static VALID_ROLES = [
    'controller',
    'jointController',
    'others',
    'processors',
    'publicAuthorities',
  ];

  static OTHER_ROLE = 'others';

  constructor(role) {
    this.isValid(role);
    this._role = role;
  }

  static other() {
    return new RecipientRole(RecipientRole.OTHER_ROLE);
  }

  isValid(role) {
    if (!RecipientRole.VALID_ROLES.includes(role)) {
      throw new RecipientRoleValidationException('Recipient role does not exist');
    }
  }

  static all() {
    return this.VALID_ROLES.map((role) => new this(role));
  }

  representedAs(recipientRoleRepresentation) {
    recipientRoleRepresentation.setKey(this._role);
    return recipientRoleRepresentation;
  }

  toString() {
    return this._role;
  }

  isEquals(recipientRole) {
    return recipientRole._role === this._role;
  }
}
