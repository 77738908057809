import taskResponseUseCases from '@/application/task/taskResponse/usecases';
import clientFolderUseCases from '@/usecases/clientFolder';
import saveDocumentationRequestResponseBuilder from './saveDocumentationRequestResponse';

export default {
  saveDocumentationRequestResponse: saveDocumentationRequestResponseBuilder({
    clientFolderUseCases,
    taskResponseUseCases,
  }),
};
