<template>
  <div>
    <vertical-menu :key="`vm-${reloadComponent}`"/>
    <horizontal-menu />
    <v-main>
      <v-container class="mb-16" fluid :key="reloadComponent">
        <slot />
      </v-container>
    </v-main>
    <document-viewer />
    <loading-page class="default-layout__loading-page" />
    <alert-snack-bar />
    <alert-overlay />
    <select-document-extension />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import taskUsecases from '@/application/task/usecases';
import consents from '@/services/permissions/consentCookies';

import HorizontalMenu from '@/components/base/menus/HorizontalMenu.vue';
import VerticalMenu from '@/components/base/menus/VerticalMenu.vue';
import DocumentViewer from '@/components/base/section/DocumentViewer.vue';
import SelectDocumentExtension from '@/components/base/section/SelectDocumentExtension.vue';
import LoadingPage from '@/components/base/loading/LoadingPage.vue';
import AlertSnackBar from '@/components/base/feedback/AlertSnackBar.vue';
import AlertOverlay from '@/components/base/feedback/AlertOverlay.vue';

export default {
  name: 'default-layout',
  inject: {
    user: 'user',
  },
  components: {
    AlertOverlay,
    AlertSnackBar,
    HorizontalMenu,
    VerticalMenu,
    DocumentViewer,
    LoadingPage,
    SelectDocumentExtension,
  },
  computed: {
    ...mapGetters('user', {
      userLanguage: 'language',
      clientId: 'companyId',
      isAdmin: 'isAdmin',
      userName: 'userName',
      userEmail: 'userEmail',
    }),
    ...mapGetters('admin', [
      'adminCompanyId',
    ]),
  },
  data() {
    return {
      reloadComponent: 1,
    };
  },
  methods: {
    showIntercomChat() {
      if (consents.hasAcceptedIntercomConsent() && this.user.isB2B) {
        this.$intercom.boot({
          language_override: this.userLanguage,
          name: this.userName,
          email: this.userEmail,
        });
      }
    },
  },
  async beforeMount() {
    this.$i18n.locale = this.userLanguage;

    if (this.user.isRestrictedEmployee || this.user.isTrainingsManager) {
      return;
    }

    if (this.clientId) {
      await taskUsecases.fetchPendingTasks(this.clientId);
    }
  },
  mounted() {
    if (typeof window.usercentrics !== 'undefined') {
      this.showIntercomChat();
    } else {
      document.getElementById(process.env.VUE_APP_USERCENTRICS_ID).onload = () => {
        this.showIntercomChat();
      };
    }
  },
  watch: {
    clientId(newVal, oldVal) {
      if (!oldVal && this.isAdmin) {
        this.$eventHub.$emit('clientSelectedWasChanged', newVal);
      }

      this.reloadComponent += 1;
    },
  },
};
</script>
