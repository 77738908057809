import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';

export default class ResetTrainingEmployees {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public invoke() {
    this.trainingEmployeeRepository.reset();
  }
}
