<template>
  <v-row>
    <v-col cols="6">
      <input-search
        :value.sync="search"
        :label="$t('toms.search_bar')"
      />
    </v-col>
    <v-col cols="12">
      <filters v-if="filtersTom.length > 1"
        :items="filtersTom"
        :value.sync="filtersSelected"
        :informationTooltip="$t('toms.filters.info_tooltip')"
        section="toms"
      />
    </v-col>
    <v-col cols="12">
      <list-tom
        v-if="isCurrentTab"
        :search="search"
        :filtersSelected="filtersSelected"
      />
    </v-col>
  </v-row>
</template>

<script>
import FetchTomFiltersQuery from '@/application/tom/fetchTomFilters/FetchTomFiltersQuery';
import ListTom from '@/components/modules/tom/ListTom.vue';
import InputSearch from '@/components/base/form/InputSearch.vue';
import Filters from '@/components/base/section/Filters.vue';

export default {
  name: 'library-tom-section',
  inject: {
    fetchTomFilters: 'fetchTomFilters',
  },
  components: {
    Filters,
    InputSearch,
    ListTom,
  },
  props: {
    isCurrentTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      filtersTom: [],
      filtersSelected: [],
    };
  },
  methods: {
    getFiltersTom(list) {
      if (this.filtersTom.length) {
        return;
      }
      this.$showLoading();
      const listTomsFilters = this.fetchTomFilters.invoke(new FetchTomFiltersQuery());
      this.$hideLoading();
      const filters = [];
      const totalTomsByList = {};

      list.forEach((tom) => {
        totalTomsByList[tom.list] = totalTomsByList[tom.list]
          ? totalTomsByList[tom.list] += 1 : 1;
        if (listTomsFilters.includes(tom.list) && !filters.includes(tom.list)) {
          filters.push(tom.list);
        }
      });
      this.filtersTom = filters.map((filter) => ({
        id: filter,
        name: this.$t(`toms.filters.${filter}`, { amount: totalTomsByList[filter] }),
        tooltip: this.$t(`toms.filters.${filter}_tooltip`),
        amount: totalTomsByList[filter],
      }));

      this.filtersSelected = filters;
    },
  },
  beforeMount() {
    this.$eventHub.$on('tomListLoaded', this.getFiltersTom);
  },
  beforeDestroy() {
    this.$eventHub.$off('tomListLoaded', this.getFiltersTom);
  },
};
</script>
