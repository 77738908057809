class BrowserInfo {
  private readonly name: string;

  private readonly version: string;

  private readonly platform: string;

  private readonly screenWidth: number;

  private readonly screenHeight: number;

  constructor() {
    const response = BrowserInfo.parse(navigator.userAgent);
    this.name = response.name;
    this.version = response.version;
    this.platform = navigator.platform;
    this.screenWidth = window.screen.width * window.devicePixelRatio;
    this.screenHeight = window.screen.height * window.devicePixelRatio;
  }

  private static parse(userAgent: string) {
    let navigatorInfo: RegExpMatchArray = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let version: RegExpMatchArray | null;

    if (/trident/i.test(navigatorInfo[1])) {
      version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return { name: 'IE', version: (version[1] || '') };
    }

    if (navigatorInfo[1] === 'Chrome') {
      version = userAgent.match(/\bOPR|Edge\/(\d+)/);
      if (version != null) { return { name: 'Opera', version: version[1] }; }
    }

    navigatorInfo = navigatorInfo[2] ? [navigatorInfo[1], navigatorInfo[2]] : [navigator.appName, navigator.appVersion, '-?'];
    version = userAgent.match(/version\/(\d+)/i);

    if (version != null) {
      navigatorInfo.splice(1, 1, version[1]);
    }

    return {
      name: navigatorInfo[0],
      version: navigatorInfo[1],
    };
  }

  public toObject(): object {
    return {
      name: this.name,
      version: this.version,
      platform: this.platform,
      screen_width: this.screenWidth,
      screen_height: this.screenHeight,
      browser_width: window.innerWidth,
      browser_height: window.innerHeight,
    };
  }
}

export default BrowserInfo;
