import recipients from './selectLists/recipients';
import recipientsCategories from './selectLists/recipientsCategories';

function getRecipientsList() {
  return recipients;
}

function getRecipientsCategoriesList() {
  return recipientsCategories;
}

export default {
  getRecipientsList,
  getRecipientsCategoriesList,
};
