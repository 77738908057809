<template>
<div>
  <v-stepper
    altLabels
    class="elevation-0 horizontal-stepper"
    v-model="currentStep"
  >
    <v-stepper-header>
      <template v-for="(step, index) in steps">
      <v-stepper-step
        :key="`${index}-step`"
        :class="{'horizontal-stepper--active': currentStep > index + 1}"
        :step="index + 1"
        @click="goToStep(step.id, index)"
      >
        {{ step.name }}
      </v-stepper-step>
      <v-divider
        v-if="index < steps.length - 1"
        :key="index"
      >
      </v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</div>
</template>

<script>
export default {
  name: 'horizontal-steps-line',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    DOMScrollId: {
      type: String,
      default: '',
    },
    DOMSteps: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentStep: 1,
      stepPreviousScrolling: 0,
      stepToGoByClick: 0,
    };
  },
  methods: {
    goToStep(id, index) {
      this.stepToGoByClick = index + 1;
      const step = document.getElementById(id);
      const DOMScroll = document.getElementById(this.DOMScrollId);
      DOMScroll.scroll({ top: step.offsetTop - 200, behavior: 'smooth' });
      this.currentStep = index + 1;
    },
    changeStepWhenScrolling() {
      const DOMScroll = document.getElementById(this.DOMScrollId);
      const steps = Array.from(document.getElementsByClassName(this.DOMSteps));
      DOMScroll.onscroll = () => {
        steps.forEach((step) => {
          const stepTop = step.offsetTop;
          if (DOMScroll.scrollTop >= stepTop - 400) {
            const id = step.getAttribute('id');
            this.currentStep = this.steps.findIndex((el) => el.id === id) + 1;
          }
        });
        this.trackStepChange();
      };
    },
    trackStepChange() {
      if (!this.isInAStep() || !this.stepHasChanged()) {
        return;
      }

      this.stepPreviousScrolling = this.currentStep;
      this.trackStepChangeIfNeeded();
    },
    isInAStep() {
      return this.currentStep !== 0;
    },
    stepHasChanged() {
      return this.currentStep !== this.stepPreviousScrolling;
    },
    trackStepChangeIfNeeded() {
      if (!this.stepHasToBeTracked()) {
        return;
      }
      this.$track({ action: `Scroll to ${this.currentStep}. ${this.getStepId(this.currentStep - 1)}` });
      this.stepToGoByClick = 0;
    },
    stepHasToBeTracked() {
      return this.stepToGoByClick === 0 || this.currentStep === this.stepToGoByClick;
    },
    getStepId(stepToGet) {
      return this.steps[stepToGet].id;
    },
  },
  mounted() {
    this.changeStepWhenScrolling();
  },
};
</script>
