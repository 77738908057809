export default ({
  clientFolderUseCases,
  taskResponseUseCases,
}) => async (task, files, userId) => {
  try {
    let response = await clientFolderUseCases.uploadFile(task.getClientId(), files);
    if (!response.success) {
      return { success: false };
    }

    const fileNames = files.map((file) => file.name);
    response = await taskResponseUseCases.saveResponse(task, fileNames, userId);
    if (!response.success) {
      return { success: false };
    }

    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
