import RecipientWarranty from '@/domain/recipient/recipientWarranties/RecipientWarranty';
import RecipientWarrantyRepresentation from './RecipientWarrantyRepresentation';

export default (
  translator,
) => () => {
  const warranties = RecipientWarranty.all();
  const warrantiesRepresentation = warranties.map(
    (warranty) => warranty.representedAs(new RecipientWarrantyRepresentation(translator)),
  );
  return warrantiesRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
