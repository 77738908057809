export default {
  url(state) {
    return state.url;
  },
  name(state) {
    return state.name;
  },
  showViewer(state) {
    return state.showViewer;
  },
};
