import DocumentDataId from '@/domain/documentData/DocumentDataId';
import RecipientDocumentWasDeleted from '@/domain/recipient/RecipientDocumentWasDeleted';

export default (
  documentDataRecipientRepository,
  recipientDocumentWasDeletedEventListener,
) => async (documentDataId, recipientId) => {
  try {
    await documentDataRecipientRepository.delete(DocumentDataId.fromString(documentDataId));

    const event = new RecipientDocumentWasDeleted(recipientId);
    recipientDocumentWasDeletedEventListener.invoke(event);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
