import clientFolderRepository from '@/repositories/clientFolder';
import fetchFilesListBuilder from '@/usecases/clientFolder/fetchFilesList';
import downloadFileBuilder from '@/usecases/clientFolder/downloadFile';
import deleteFileBuilder from '@/usecases/clientFolder/deleteFile';
import uploadFileBuilder from '@/usecases/clientFolder/uploadFiles';
import uploadFilesValidationService from '@/services/validations/uploadFilesValidation';
import downloadFile from '@/services/documents/downloadFile';

export default {
  fetchFilesList: fetchFilesListBuilder({ clientFolderRepository }),
  downloadFile: downloadFileBuilder({ clientFolderRepository, downloadFile }),
  deleteFile: deleteFileBuilder({ clientFolderRepository }),
  uploadFile: uploadFileBuilder({ clientFolderRepository, uploadFilesValidationService }),
};
