<template>
  <div class="h-100 w-100">
    <div class="text-center confirm-passwrod__icon">
      <v-icon color="primary">
        check_circle
      </v-icon>
    </div>
    <p>
      {{ $t('changePassword.confirm_password_changed') }}
    </p>
    <router-link :to="{name: 'login'}">
      {{ $t('changePassword.return_login_page') }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'confirm-change-password',
};
</script>
