<template>
  <div class="select-buttons">
    <v-btn
        v-for="item in itemsList"
        :key="item.id"
        :outlined="item.id !== selection.id"
        color="primary"
        @click="updateSelection(item)"
    >
      {{ item.name }}
    </v-btn>
  </div>
</template>

<script>
import normalization from '@/services/shared/normalization';

export default {
  name: 'select-buttons',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: Object,
      default: () => {},
    },
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    itemsList() {
      if (!this.search) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.nameNormalized.indexOf(normalization.normalizeString(this.search)) !== -1,
      );
    },
  },
  methods: {
    updateSelection(item) {
      this.$emit('update:selection', item);
      this.$emit('selectedWasChanged');
    },
  },
};
</script>
