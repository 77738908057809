import TrainingRepository from '@/domain/training/TrainingRepository';
import ClientId from '@/domain/client/ClientId';
import RedirectToUrl from '@/application/training/goTrainingUrl/RedirectToUrl';

export default class GoTrainingUrl {
  private trainingRepository: TrainingRepository;

  private _redirectToTraining;

  constructor(trainingRepository: TrainingRepository, redirectToUrl: RedirectToUrl) {
    this.trainingRepository = trainingRepository;
    this._redirectToTraining = redirectToUrl;
  }

  public async invoke(clientId: number) {
    try {
      const trainingUrl = await this.trainingRepository.getUrl(ClientId.fromInt(clientId));
      await this._redirectToTraining.redirect(
        trainingUrl.url.toString(),
        trainingUrl.token.toString(),
      );
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
