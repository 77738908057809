import ClientId from '@/domain/client/ClientId';
import TrainingEmployee from '@/domain/training/trainingEmployee/TrainingEmployee';
import TrainingEmployeeRepository from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';
import TrainingEmployeesRepresentation
  from '@/application/training/employee/fetchTrainingEmployee/TrainingEmployeesRepresentation';

export default class FetchTrainingEmployees {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public async invoke(clientId: number): Promise<any> {
    try {
      const trainingEmployees: TrainingEmployee[] = await this.trainingEmployeeRepository.ofClient(
        ClientId.fromInt(clientId),
      );

      const trainingEmployeesRepresentation: TrainingEmployeesRepresentation[] = trainingEmployees
        .map((trainingEmployee: TrainingEmployee) => trainingEmployee
          .representedAs(
            new TrainingEmployeesRepresentation(),
          )) as TrainingEmployeesRepresentation[];

      return {
        success: true,
        trainingEmployees: trainingEmployeesRepresentation.sort(
          (firstEmployee, secondEmployee) => (
            (firstEmployee.name.toLowerCase() < secondEmployee.name.toLowerCase()) ? -1 : 1),
        ),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
