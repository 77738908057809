import ClientId from '@/domain/client/ClientId';
import ClientBusinessName from '@/domain/client/ClientBusinessName';
import ClientName from '@/domain/client/ClientName';
import ClientCountryId from '@/domain/client/ClientCountryId';

export default abstract class ClientRepresentation {
  protected _id!: ClientId;

  protected _businessName!: ClientBusinessName;

  protected _name!: ClientName;

  protected _countryId!: ClientCountryId;

  setId(id: ClientId): ClientRepresentation {
    this._id = id;
    return this;
  }

  setBusinessName(businessName: ClientBusinessName): ClientRepresentation {
    this._businessName = businessName;
    return this;
  }

  setName(name: ClientName): ClientRepresentation {
    this._name = name;
    return this;
  }

  setCountryId(countryId: ClientCountryId): ClientRepresentation {
    this._countryId = countryId;
    return this;
  }
}
