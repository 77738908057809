import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MIN_LENGTH = 1;
const MAX_LENGTH = 700;

export default class CollaboratorMessage extends StringValueObject {
  constructor(string: string) {
    super(string, MIN_LENGTH, MAX_LENGTH);
  }
}
