export default ({
  treatmentManagersRepository,
  downloadFile,
}) => async (documentId, typeDocument, type) => {
  try {
    const urlDownload = await treatmentManagersRepository.downloadDocument(
      documentId, typeDocument, type,
    );
    downloadFile.download(urlDownload.url);

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
