export default class AbstractRecipientModelRepresentation {
  _id;

  _name;

  _country;

  _state;

  _category;

  _role;

  _warranties;

  _dpa;

  _businessName;

  setId(id) {
    this._id = id;
  }

  setName(name) {
    this._name = name;
  }

  setBusinessName(businessName) {
    this._businessName = businessName;
  }

  setCategory(category) {
    this._category = category;
  }

  setCountry(country) {
    this._country = country;
  }

  setState(state) {
    this._state = state;
  }

  setRole(role) {
    this._role = role;
  }

  setWarranties(warranties) {
    this._warranties = warranties;
  }

  setDpa(dpa) {
    this._dpa = dpa;
  }
}
