import RecipientId from '@/domain/recipient/RecipientId';

export default (
  getDocumentUrl,
  downloadFile,
) => async (recipientId) => {
  try {
    const documentUrl = await getDocumentUrl.downloadDocumentsOfRecipient(
      RecipientId.fromString(recipientId),
    );
    downloadFile.invoke(documentUrl);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
