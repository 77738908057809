export const initialState = {
  token: '',
  email: '',
  companyId: null,
  companyName: null,
  id: 0,
  isAdmin: false,
  type: '',
  language: '',
  name: '',
  countryId: null,
  departments: [],
  isB2b: false,
  reducedMenu: false,
};

const state = () => ({ ...initialState });

export default state();
