import AbstractEquipmentRepresentation
  from '../../domain/equipment/AbstractEquipmentRepresentation';

export default class EquipmentRepresentationApi extends AbstractEquipmentRepresentation {
  toJson() {
    return JSON.stringify({
      id: this._id.toString(),
      clientId: this._clientId.toInt(),
      name: this._name.toString(),
      type: this._type.toString(),
      location: (this._location) ? this._location.jsonSerialize() : null,
      security_measures: this._securityMeasures.map(
        (securityMeasure) => securityMeasure.toString(),
      ),
    });
  }
}
