import DocumentData from '../DocumentData';

export default class DocumentDataEmployee extends DocumentData {
  _language;

  _employeeName;

  _employeeSurname;

  _employeeNif;

  constructor(id, clientId, name, language, lastModification) {
    super(id, clientId, name, lastModification);
    this._language = language;
  }

  addEmployeeName(employeeName) {
    this._employeeName = employeeName;
  }

  addEmployeeSurname(employeeSurname) {
    this._employeeSurname = employeeSurname;
  }

  addEmployeeNif(employeeNif) {
    this._employeeNif = employeeNif;
  }

  representedAs(contractRepresentation) {
    contractRepresentation.setId(this._id);
    contractRepresentation.setName(this._name);
    contractRepresentation.setClientId(this._clientId);
    contractRepresentation.setLanguage(this._language);
    contractRepresentation.setEmployeeName(this._employeeName);
    contractRepresentation.setEmployeeSurname(this._employeeSurname);
    contractRepresentation.setEmployeeNif(this._employeeNif);
    contractRepresentation.setLastModification(this._lastModification);

    return contractRepresentation;
  }
}
