import { getManagement } from '@/shared/restActions';
import store from '@/store';

function setStoreUserRoleAndDepartments(userInfo) {
  return store.dispatch('user/setUserRole', userInfo);
}

function getLanguagesList(clientId) {
  return getManagement(`/api/v2/clients/${clientId}/languages`);
}

function getUserCountry(clientId) {
  return getManagement(`/api/v2/client/${clientId}/country`);
}

export default {
  setStoreUserRoleAndDepartments,
  getLanguagesList,
  getUserCountry,
};
