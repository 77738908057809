export const initialState = {
  availableLanguages: [],
  language: null,
  status: null,
  trainingEmployees: [],
};

const state = () => ({ ...initialState });

export default state();
