export default (readModel) => async (country, language) => {
  try {
    const allModels = await readModel.getAll();

    const modelsFiltered = allModels.filter((model) => model.isModelOfThisCountry(country)
      && model.isModelOfThisLanguage(language));

    const modelsSort = modelsFiltered.sort((a, b) => ((a.name < b.name) ? -1 : 1));
    return { success: true, models: modelsSort };
  } catch (e) {
    console.error(e);
    return { success: false, models: [] };
  }
};
