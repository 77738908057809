import { getManagement } from '@/shared/restActions';
import DepartmentReadModel from '@/domain/department/listDepartment/DepartmentReadModel';
import ClientId from '@/domain/client/ClientId';
import DepartmentProjection from '@/domain/department/listDepartment/DepartmentProjection';
import ListDepartmentCache from '@/infrastructure/department/listDepartment/ListDepartmentCache';
import ListDepartmentRaw from '@/infrastructure/department/listDepartment/ListDepartmentRaw';
import DepartmentId from '@/domain/department/DepartmentId';

export default class DepartmentReadModelApi implements DepartmentReadModel {
  public async ofClient(clientId: ClientId): Promise<DepartmentProjection[]> {
    let listDepartment: DepartmentProjection[] = ListDepartmentCache.all();

    if (listDepartment.length) {
      return listDepartment;
    }

    const listDepartmentRaw: ListDepartmentRaw[] = await DepartmentReadModelApi
      .getListDepartmentFromApi(clientId);

    listDepartment = listDepartmentRaw.map((dept: ListDepartmentRaw) => new DepartmentProjection(
      dept.id,
      dept.name,
      dept.model,
    ));

    ListDepartmentCache.saveAll(listDepartment);
    return listDepartment;
  }

  private static async getListDepartmentFromApi(clientId: ClientId): Promise<ListDepartmentRaw[]> {
    const response = await getManagement(`/api/v2/clients/${clientId.toInt()}/departments`);
    return response.data;
  }

  public retrieve(departmentId: DepartmentId): DepartmentProjection {
    return ListDepartmentCache.retrieve(departmentId.toString());
  }
}
