import DocumentDataEmployeeRepositoryApi
  from '@/infrastructure/documentData/employee/DocumentDataEmployeeRepositoryApi';
import Translator from '@/infrastructure/Translator';
import fetchDocumentsDataEmployeeBuilder
  from './fetchDocumentsDataEmployee/fetchDocumentsDataEmployee';

export default {
  fetchDocumentsDataEmployee: fetchDocumentsDataEmployeeBuilder(
    new DocumentDataEmployeeRepositoryApi(), new Translator(),
  ),
};
