<template>
  <div>
    <legal-notice-es
      v-if="$i18n.locale === spanish_code"
    />
    <legal-notice
      v-else
    />
  </div>
</template>

<script>
import LegalNotice from '../modules/legal/LegalNotice.vue';
import LegalNoticeEs from '../modules/legal/LegalNoticeEs.vue';

export default {
  name: 'legal-notice-container',
  components: {
    LegalNoticeEs,
    LegalNotice,
  },
  data() {
    return {
      spanish_code: 'es',
    };
  },
};
</script>
