import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import ClientUserLastLogin from '@/domain/clientUser/ClientUserLastLogin';
import Language from '@/domain/language/Language';
import ClientUserPassword from '@/domain/clientUser/ClientUserPassword';
import DepartmentId from '@/domain/department/DepartmentId';

export default abstract class ClientUserRepresentation {
  protected _email!: ClientUserEmail;

  protected _id!: ClientUserId | null;

  protected _validated!: boolean;

  protected _name!: ClientUserName;

  protected _surname!: ClientUserSurname;

  protected _type!: ClientUserType;

  protected _departments!: DepartmentId[];

  protected _lastLogin!: ClientUserLastLogin | null;

  protected _language!: Language;

  protected _password!: ClientUserPassword | null;

  setEmail(email: ClientUserEmail): ClientUserRepresentation {
    this._email = email;
    return this;
  }

  setId(id: ClientUserId | null): ClientUserRepresentation {
    this._id = id;
    return this;
  }

  setValidated(validated: boolean): ClientUserRepresentation {
    this._validated = validated;
    return this;
  }

  setName(name: ClientUserName): ClientUserRepresentation {
    this._name = name;
    return this;
  }

  setSurname(surname: ClientUserSurname): ClientUserRepresentation {
    this._surname = surname;
    return this;
  }

  setType(type: ClientUserType): ClientUserRepresentation {
    this._type = type;
    return this;
  }

  setDepartments(departments: DepartmentId[]): ClientUserRepresentation {
    this._departments = departments;
    return this;
  }

  setLastLogin(lastLogin: ClientUserLastLogin | null): ClientUserRepresentation {
    this._lastLogin = lastLogin;
    return this;
  }

  setLanguage(language: Language): ClientUserRepresentation {
    this._language = language;
    return this;
  }

  setPassword(password: ClientUserPassword | null): ClientUserRepresentation {
    this._password = password;
    return this;
  }
}
