import RecipientModelRepresentation from './RecipientModelRepresentation';

export default (
  recipientModelRepository,
  normalizationService,
) => async () => {
  try {
    const recipientsModels = await recipientModelRepository.all();
    const recipientModelsRepresentation = recipientsModels.map((model) => model.representedAs(
      new RecipientModelRepresentation(normalizationService),
    ));
    return {
      success: true,
      recipientsModels: recipientModelsRepresentation.sort(
        (a, b) => ((a.nameNormalized > b.nameNormalized) ? 1 : -1),
      ),
    };
  } catch (e) {
    return { success: false };
  }
};
