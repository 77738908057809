import DataBreachCollaboratorWasAddedEventListener
  from '@/application/clientUser/DataBreachCollaboratorWasAddedEventListener/DataBreachCollaboratorWasAddedEventListener';
import ClientUserRepositoryApi from '@/infrastructure/clientUser/ClientUserRepositoryApi';

export default class DataBreachCollaboratorWasAddedEventListenerBuilder {
  static build() {
    return new DataBreachCollaboratorWasAddedEventListener(
      new ClientUserRepositoryApi(),
    );
  }
}
