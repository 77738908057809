<template>
  <v-form
    ref="recipientCustomForm"
  >
    <v-row>
      <v-col cols="12">
        <slot />
        <alert-fixed
          v-if="showAlert"
          type="error"
          :text="$t('form.review')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <input-field
          :value.sync="recipient.name"
          :label="`${$t('recipient.form.name')} *`"
          :rules="[validation.required, validation.min, validation.max_name]"
          :validateOnBlur="true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <input-field
            :value.sync="recipient.businessName"
            :label="`${$t('recipient.form.business_name')} *`"
            :rules="[validation.required, validation.min, validation.max]"
            :validateOnBlur="true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <country-selector
          :item.sync="recipient.country"
          :label="`${$t('recipient.form.country')} *`"
          :rules="[validation.required]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <state-selector
          v-if="isUSACountry"
          :stateSelected.sync="recipient.state"
          :rules="[validation.required_state]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <recipient-category-selector
          :categorySelected.sync="recipient.category"
          :rules="[validation.required]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <role-recipient-selector
          :role-selected.sync="recipient.role"
        />
      </v-col>
      <v-col cols="12" md="12">
        <warranties-recipient-selector
          :warranties-selected.sync="recipient.warranties"
        />
      </v-col>
      <v-col cols="12">
        <p class="form-recipient__link-explanation">
          {{ $t('recipient.form.link_explanation') }}
        </p>
      </v-col>
      <v-col cols="12">
        <input-field
          :value.sync="recipient.dpa"
          :label="$t('recipient.form.link')"
          :rules="[validation.min, validation.max]"
          :validateOnBlur="true"
        />
      </v-col>
      <v-col cols="12">
        <p class="form-recipient__label-upload">
          {{ $t('recipient.form.upload') }}
        </p>
        <upload-document
          :key="reloadUploadFiles"
          :filesUploaded.sync="recipient.files"
          :alreadyUploadedFiles="alreadyUploadedDocuments"
        />
      </v-col>
      <v-col cols="12">
        <check-contract-selector
          :dpa-status-selected.sync="recipient.dpaStatus"
        />
      </v-col>
      <v-col cols="12" class="text-right form-recipient__btn-save">
        <v-btn
          color="primary"
          rounded
          :loading="loadingButton"
          @click="save"
        >
          {{ $t('recipient.form.accept') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import InputField from '@/components/base/form/InputField.vue';
import UploadDocument from '@/components/base/form/UploadDocument.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import CountrySelector from '@/components/base/form/country/CountrySelector.vue';
import StateSelector from '@/components/base/form/country/StateSelector.vue';
import RecipientCategorySelector from './RecipientCategorySelector.vue';
import RoleRecipientSelector from './RoleRecipientSelector.vue';
import CheckContractSelector from './CheckContractSelector.vue';
import WarrantiesRecipientSelector from './WarrantiesRecipientSelector.vue';

const USA_CODE = 'US';

export default {
  name: 'custom-recipient-form',
  components: {
    AlertFixed,
    WarrantiesRecipientSelector,
    CheckContractSelector,
    RoleRecipientSelector,
    RecipientCategorySelector,
    StateSelector,
    CountrySelector,
    InputField,
    UploadDocument,
  },
  props: {
    recipientInfo: {
      type: Object,
      default: null,
    },
    loadingButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUSACountry() {
      return this.recipient.country === USA_CODE;
    },
    alreadyUploadedDocuments() {
      return (this.recipientInfo && this.recipientInfo.documents)
        ? this.recipientInfo.documents : [];
    },
  },
  data() {
    return {
      recipient: {
        name: '',
        businessName: '',
        country: '',
        state: '',
        category: '',
        role: '',
        warranties: [],
        status: '',
        dpa: '',
        files: [],
        dpaStatus: '',
        recipientModel: '',
      },
      validation: {
        required: (v) => !!v || this.$t('form.validations.required_field'),
        min: (v) => !v || v.trim().length >= 3 || this.$t('form.validations.min_length', { length: 3 }),
        max: (v) => !v || v.trim().length <= 200 || this.$t('form.validations.max_length', { length: 200 }),
        max_name: (v) => v.trim().length <= 255 || this.$t('form.validations.max_length', { length: 255 }),
        required_state: (v) => (this.isUSACountry && !!v) || this.$t('form.validations.required_field'),
      },
      showAlert: false,
      reloadUploadFiles: 0,
    };
  },
  methods: {
    save() {
      if (!this.$refs.recipientCustomForm.validate()) {
        const textCard = document.getElementById('form-recipient__card-text');
        textCard.scroll({ top: 0, behavior: 'smooth' });
        this.showAlert = true;
        return;
      }
      this.$emit('saveRecipient', this.recipient);
    },
    fillRecipientWithInfo() {
      Object.keys(this.recipient).forEach((key) => {
        if (key === 'files') {
          return;
        }
        this.recipient[key] = this.recipientInfo[key];
      });
    },
    resetUploadedFiles() {
      this.recipient.files = [];
      this.reloadUploadFiles += 1;
    },
  },
  beforeMount() {
    this.$eventHub.$on('saveRecipientFailed', this.resetUploadedFiles);

    if (this.recipientInfo) {
      this.fillRecipientWithInfo();
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('saveRecipientFailed', this.resetUploadedFiles);
  },
  watch: {
    'recipient.country': function (value) {
      if (value !== USA_CODE) {
        this.recipient.state = '';
      }
    },
  },
};
</script>
