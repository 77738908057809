<template>
  <v-row class="upload-files mb-5">
    <v-col cols="12" class="text-center upload-files__file-extension mt-10">
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <text class="extension-name" x="50%" y="60%" dominant-baseline="middle" text-anchor="middle"
              fill="#1B746C"
        >
          {{ extension }}
        </text>
        <g opacity="0.1">
          <path
            d="M30 10C24.5 10 20.05 14.5 20.05 20L20 100C20 105.5 24.45 110 29.95 110H90C95.5 110
              100 105.5 100 100V40L70 10H30ZM65 45V17.5L92.5 45H65Z"
            fill="#609D98"
          />
        </g>
      </svg>
    </v-col>
    <v-col cols="12" class="text-center">
      <span>{{ $t('clientUsers.massupload.file_name') }}</span> {{ filesUploaded[0].name }}
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        color="primary"
        text
        class="mt-5"
        @click="removeFile"
      >
        {{ $t('clientUsers.massupload.delete_file') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'uploaded-file',
  props: {
    filesUploaded: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    extension() {
      return this.filesUploaded[0].name.split(/\.(?=[^.]+$)/)[1].toUpperCase();
    },
  },
  methods: {
    removeFile() {
      this.filesUploaded.pop();
    },
  },
};
</script>
