import DocumentDataId from '@/domain/documentData/DocumentDataId';
import ClientId from '../../client/ClientId';
import DocumentDataName from '../DocumentDataName';
import Language from '../../language/Language';
import DocumentDataLastModification from '../DocumentDataLastModification';
import EmployeeName from '../../employee/EmployeeName';
import EmployeeSurname from '../../employee/EmployeeSurname';
import EmployeeNif from '../../employee/EmployeeNif';
import DocumentDataEmployee from './DocumentDataEmployee';
import DocumentDataEmployeeBuilderValidationException
  from './DocumentDataEmployeeBuilderValidationException';

export default class DocumentDataEmployeeBuilder {
  id = null;

  clientId = null;

  name = null;

  language = null;

  employeeName = null;

  employeeSurname = null;

  employeeNif = null;

  lastModification = null;

  withId(id) {
    this.id = DocumentDataId.fromString(id);
    return this;
  }

  withClientId(clientId) {
    this.clientId = ClientId.fromInt(clientId);
    return this;
  }

  withName(name) {
    this.name = new DocumentDataName(name);
    return this;
  }

  withLanguage(language) {
    this.language = new Language(language);
    return this;
  }

  withLastModification(lastModification) {
    this.lastModification = new DocumentDataLastModification(lastModification);
    return this;
  }

  withEmployeeName(employeeName) {
    if (employeeName) {
      this.employeeName = new EmployeeName(employeeName);
    }
    return this;
  }

  withEmployeeSurname(employeeSurname) {
    if (employeeSurname) {
      this.employeeSurname = new EmployeeSurname(employeeSurname);
    }
    return this;
  }

  withEmployeeNif(employeeNif) {
    if (employeeNif) {
      this.employeeNif = new EmployeeNif(employeeNif);
    }
    return this;
  }

  reset() {
    this.id = null;
    this.clientId = null;
    this.name = null;
    this.language = null;
    this.employeeName = null;
    this.employeeSurname = null;
    this.employeeNif = null;
    this.lastModification = null;
  }

  create() {
    this.hasMandatoryValues();

    const documentData = new DocumentDataEmployee(
      this.id,
      this.clientId,
      this.name,
      this.language,
      this.lastModification,
    );

    if (this.employeeName) {
      documentData.addEmployeeName(this.employeeName);
    }

    if (this.employeeSurname) {
      documentData.addEmployeeSurname(this.employeeSurname);
    }

    if (this.employeeNif) {
      documentData.addEmployeeNif(this.employeeNif);
    }

    this.reset();

    return documentData;
  }

  hasMandatoryValues() {
    if (!this.id) {
      throw new DocumentDataEmployeeBuilderValidationException('Document data employee should has an id');
    }

    if (!this.clientId) {
      throw new DocumentDataEmployeeBuilderValidationException('Document data employee should has a client id');
    }

    if (!this.name) {
      throw new DocumentDataEmployeeBuilderValidationException('Document data employee should has a name');
    }

    if (!this.language) {
      throw new DocumentDataEmployeeBuilderValidationException('Document data employee should has a language');
    }

    if (!this.lastModification) {
      throw new DocumentDataEmployeeBuilderValidationException('Document data employee should has a last modification');
    }
  }
}
