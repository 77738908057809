import { getManagement } from '@/shared/restActions';
import GetDocumentUrl from '@/domain/document/GetDocumentUrl';

export default class GetDocumentUrlApi implements GetDocumentUrl {
  async download(
    documentType: string,
    documentId: string | number,
    fileType: string | null,
  ): Promise<string> {
    try {
      const fileTypeUrl: string = fileType ? `/${fileType}` : '';
      const response: {[key: string]: {[key: string]: string}} = await getManagement(
        `/api/v2/documents/${documentType}/${documentId}/download${fileTypeUrl}`,
      );
      return response.data.url;
    } catch (e) {
      console.error(e);
      throw new Error('Error retrieving document url');
    }
  }

  async preview(
    documentType: string,
    documentId: string | number,
    language: string,
  ): Promise<string> {
    try {
      const response: {[key: string]: {[key: string]: string}} = await getManagement(
        `/api/v2/documents/${documentType}/${documentId}/preview/${language}`,
      );
      return response.data.url;
    } catch (e) {
      console.error(e);
      throw new Error('Error retrieving document url');
    }
  }

  async downloadOfEmployee(
    employeeId: string,
    fileTypes: string | string[],
  ): Promise<string> {
    try {
      const response: {[key: string]: {[key: string]: string}} = await getManagement(
        `/api/v2/employees/${employeeId.toString()}/documents/contract/download/${fileTypes}`,
      );
      return response.data.url;
    } catch (e) {
      console.error(e);
      throw new Error('Error retrieving documents employee');
    }
  }

  async downloadDocumentsOfRecipient(recipientId: string): Promise<string> {
    try {
      const response: {[key: string]: {[key: string]: string}} = await getManagement(
        `/api/v2/recipients/${recipientId.toString()}/documents/contracts/download`,
      );
      return response.data.url;
    } catch (e) {
      console.error(e);
      throw new Error('Error retrieving contracts recipient');
    }
  }
}
