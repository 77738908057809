const SAVE_HISTORIC_ROUTES = (state, historicRoutes) => {
  state.historicRoutes = historicRoutes;
};

const RESET_HISTORIC_ROUTES = (state) => {
  state.historicRoutes = [];
};

export default {
  SAVE_HISTORIC_ROUTES,
  RESET_HISTORIC_ROUTES,
};
