<template>
  <div class="title-section" :class="{'title-section--has-slot': hasSlot}">
    <div>
      <h1>
        {{ title }}
      </h1>
      <p>
        {{ subtitle }}
      </p>
    </div>
    <div class="title-section__slot">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'title-section',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasSlot: false,
    };
  },
  mounted() {
    this.hasSlot = Object.keys(this.$slots).length;
  },
};
</script>
