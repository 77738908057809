import { getManagement } from '@/shared/restActions';

function getUrlUserIsAdmin(companyId, isAdmin) {
  if (isAdmin) {
    return `?company=${companyId}`;
  }
  return '';
}

function getListDocumentsUrl(companyId, locale, spainAdmin) {
  let url = `/api/v2/documents-legacy/processing-activities/${locale}/list/${companyId}`;

  if (!spainAdmin) {
    url = `/api/v2/documents-legacy/processing-activities/${locale}/non-spain-list/${companyId}`;
  }
  return url;
}

function getDownloadUrl(companyId, documentId, typeDocument, type, lang) {
  if (typeDocument === 'ret') {
    return `/api/v2/documents-legacy/download/rets/${lang}/${type}/${documentId}`;
  }

  return `/api/v2/documents/processing-activity/${documentId}/download/${type}`;
}

function getViewUrl(companyId, documentId, typeDocument, lang) {
  let url = `/api/v2/documents-legacy/preview/layouts/${lang}/${documentId}`;

  if (typeDocument === 'ret') {
    url = `/api/v2/documents-legacy/preview/rets/${lang}/${documentId}`;
  }

  if (typeDocument === 'processing-activity') {
    return `/api/v2/documents/processing-activity/${documentId}/preview/${lang}`;
  }
  return url;
}

function getListDocuments(companyId, locale, spainAdmin, isAdmin) {
  let url = getListDocumentsUrl(companyId, locale, spainAdmin);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function downloadDocument(companyId, documentId, typeDocument, type, lang, isAdmin) {
  if (typeDocument === 'rat') {
    return getManagement(`/api/v2/clients/${companyId}/documents/${documentId}/${type}`, {});
  }

  let url = getDownloadUrl(companyId, documentId, typeDocument, type, lang);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function viewDocument(companyId, documentId, typeDocument, lang, isAdmin) {
  let url = getViewUrl(companyId, documentId, typeDocument, lang);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

async function getInfoDataMap(companyId, lang) {
  const response = await getManagement(`/api/v2/data-map/${lang}/${companyId}`, {});
  return response.data;
}

export default {
  getListDocuments,
  downloadDocument,
  viewDocument,
  getInfoDataMap,
};
