import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import FetchClientUserQuery from '@/application/clientUser/fetchClientUser/FetchClientUserQuery';
import ClientUserToEditRepresentation
  from '@/application/clientUser/fetchClientUser/ClientUserToEditRepresentation';
import ClientUser from '@/domain/clientUser/ClientUser';
import ClientUserId from '@/domain/clientUser/ClientUserId';

export default class FetchClientUserQueryHandler {
  private clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public async invoke(query: FetchClientUserQuery)
    : Promise<{ success: boolean, clientUser?: ClientUserToEditRepresentation, error?: string}> {
    try {
      const clientUser: ClientUser = await this.clientUserRepository
        .retrieve(ClientUserId.fromInt(query.clientUserId));

      return {
        success: true,
        clientUser: clientUser
          .representedAs(new ClientUserToEditRepresentation()) as ClientUserToEditRepresentation,
      };
    } catch (e) {
      return { success: false, error: e.response?.data?.data || '' };
    }
  }
}
