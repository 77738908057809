import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class RecipientDpa extends StringValueObject {
    url;

    constructor(url) {
      super(url, null, 255);
      this.url = url;
    }
}
