import Translator from '@/infrastructure/Translator';
import eventBus from '@/infrastructure/eventBus/EventBus';
import ClientUserRepositoryApi from '@/infrastructure/clientUser/ClientUserRepositoryApi';
import DeleteClientUserCommandHandler
  from '@/application/clientUser/deleteClientUser/DeleteClientUserCommandHandler';
import FetchClientUsersQueryHandler from '@/application/clientUser/fetchClientUsers/FetchClientUsersQueryHandler';
import ResetClientUsersCommandHandler
  from '@/application/clientUser/resetClientUser/ResetClientUsersCommandHandler';
import ResendActivationClientUserEmailCommandHandler
  from '@/application/clientUser/resendActivationClientUserEmail/ResendActivationClientUserEmailCommandHandler';
import FetchClientUserTypesQueryHandler
  from '@/application/clientUser/fetchClientUserTypes/FetchClientUserTypesQueryHandler';
import RegisterClientUserCommandHandler
  from '@/application/clientUser/registerClientUser/RegisterClientUserCommandHandler';
import ValidateClientUserCommandHandler
  from '@/application/clientUser/validateClientUser/ValidateClientUserCommandHandler';
import ClientUserReadModelApi from '@/infrastructure/clientUser/ClientUserReadModelApi';
import CreateClientUserCommandHandler
  from '@/application/clientUser/createClientUser/CreateClientUserCommandHandler';
import FetchClientUserQueryHandler
  from '@/application/clientUser/fetchClientUser/FetchClientUserQueryHandler';
import ChangeClientUserRoleCommandHandler
  from '@/application/clientUser/editClientUser/ChangeClientUserRoleCommandHandler';
import EditClientUser from '@/application/clientUser/editClientUser/EditClientUser';

export default {};

export const fetchClientUsers = new FetchClientUsersQueryHandler(
  new ClientUserReadModelApi(),
);

export const createClientUser = new CreateClientUserCommandHandler(
  new ClientUserRepositoryApi(),
  eventBus,
);

export const fetchClientUser = new FetchClientUserQueryHandler(new ClientUserRepositoryApi());

export const deleteClientUser = new DeleteClientUserCommandHandler(
  new ClientUserRepositoryApi(),
  eventBus,
);

export const resetClientUsers = new ResetClientUsersCommandHandler(
  new ClientUserRepositoryApi(),
);

export const resendActivationClientUserEmail = new ResendActivationClientUserEmailCommandHandler(
  new ClientUserRepositoryApi(),
);

export const changeClientUserRole = new ChangeClientUserRoleCommandHandler(
  new ClientUserRepositoryApi(),
  new EditClientUser(new ClientUserRepositoryApi(), eventBus),
);

export const fetchClientUserTypes = new FetchClientUserTypesQueryHandler(new Translator());

export const registerClientUser = new RegisterClientUserCommandHandler(
  new ClientUserRepositoryApi(),
);

export const validateClientUser = new ValidateClientUserCommandHandler(
  new ClientUserRepositoryApi(),
);
