import LanguageRepository from '@/domain/language/LanguageRepository';
import Translator from '@/infrastructure/Translator';
import Language from '@/domain/language/Language';
import ClientId from '@/domain/client/ClientId';
import LanguagesRepresentation
  from '@/application/language/fetchLanguagesOfClient/LanguagesRepresentation';

export default class FetchLanguagesOfClient {
  private readonly languageRepository: LanguageRepository;

  private readonly translator: Translator;

  constructor(
    languageRepository: LanguageRepository,
    translator: Translator,
  ) {
    this.languageRepository = languageRepository;
    this.translator = translator;
  }

  public async invoke(clientId: number): Promise<object> {
    try {
      const languages: Language[] = await this.languageRepository.ofClient(
        ClientId.fromInt(clientId),
      );

      return {
        success: true,
        languages: languages.map((language) => language.representedAs(
          new LanguagesRepresentation(this.translator),
        )),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
