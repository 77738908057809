export default class TaskRepresentation {
  #taskId;

  #title;

  #department;

  #expirationDate;

  #taskType;

  #critical;

  #requestPetition;

  #status;

  #creationDate;

  #completionDate;

  #adminId;

  #clientId;

  #createdBy;

  setTaskId(taskId) {
    this.#taskId = taskId;
  }

  setTitle(title) {
    this.#title = title;
  }

  setDepartment(department) {
    this.#department = department;
  }

  setExpirationDate(expirationDate) {
    this.#expirationDate = expirationDate;
  }

  setTaskType(taskType) {
    this.#taskType = taskType;
  }

  setCritical(critical) {
    this.#critical = critical;
  }

  setRequestPetition(requestPetition) {
    this.#requestPetition = requestPetition;
  }

  setStatus(status) {
    this.#status = status;
  }

  setCreationDate(creationDate) {
    this.#creationDate = creationDate;
  }

  setCompletationDate(completionDate) {
    this.#completionDate = completionDate;
  }

  setAdminId(adminId) {
    this.#adminId = adminId;
  }

  setClientId(clientId) {
    this.#clientId = clientId;
  }

  setCreatedBy(createdBy) {
    this.#createdBy = createdBy;
  }

  getTaskId() {
    return this.#taskId.id;
  }

  getTitle() {
    return this.#title.title || this.#title;
  }

  getDepartment() {
    return this.#department;
  }

  getExpirationDate() {
    return this.#expirationDate.expirationDate;
  }

  getTaskType() {
    return this.#taskType.type;
  }

  getCritical() {
    return this.#critical;
  }

  getRequestPetition() {
    return this.#requestPetition.requestPetition;
  }

  getStatus() {
    return this.#status.status;
  }

  getCreationDate() {
    return this.#creationDate.creationDate;
  }

  getCompletationDate() {
    return (!this.#completionDate) ? null : this.#completionDate.completionDate;
  }

  getIsCompleted() {
    return this.#status.isCompleted();
  }

  getAdminId() {
    return this.#adminId;
  }

  getClientId() {
    return this.#clientId;
  }

  getCreatedBy() {
    return this.#createdBy;
  }
}
