import Department from '@/domain/department/Department';
import DepartmentId from '@/domain/department/DepartmentId';
import store from '../../store';

export default class DepartmentCache {
  public static save(department: Department): void {
    const departments = store.getters['department/department'];
    departments[department.getId().toString()] = department;
    store.dispatch('department/addDepartment', departments);
  }

  public static retrieve(departmentId: DepartmentId): Department {
    const departments: {[key: string]: Department} = store.getters['department/department'];
    return departments[departmentId.toString()];
  }

  public static reset(): void {
    store.dispatch('department/resetDepartment');
  }
}
