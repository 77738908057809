import EmployeeId from '@/domain/employee/EmployeeId';
import ClientId from '@/domain/client/ClientId';
import EmployeeName from '@/domain/employee/EmployeeName';
import EmployeeSurname from '@/domain/employee/EmployeeSurname';
import EmployeePosition from '@/domain/employee/EmployeePosition';
import EmployeeEntryDate from '@/domain/employee/EmployeeEntryDate';
import EmployeeExitDate from '@/domain/employee/EmployeeExitDate';
import EmployeeNif from '@/domain/employee/EmployeeNif';
import EmployeeDocumentData from '@/domain/employee/EmployeeDocumentData';
import EmployeeEmail from '@/domain/employee/EmployeeEmail';
import EmployeeRepresentation from '@/domain/employee/EmployeeRepresentation';

export default class Employee {
  private readonly id: EmployeeId;

  private readonly clientId: ClientId;

  private readonly name: EmployeeName;

  private readonly surname: EmployeeSurname;

  private email: EmployeeEmail | null = null;

  private position: EmployeePosition | null = null;

  private entry: EmployeeEntryDate | null = null;

  private exit: EmployeeExitDate | null = null;

  private nif: EmployeeNif | null = null;

  private documentData: EmployeeDocumentData = EmployeeDocumentData.fromInt(0);

  constructor(id: EmployeeId, clientId: ClientId, name: EmployeeName, surname: EmployeeSurname) {
    this.id = id;
    this.clientId = clientId;
    this.name = name;
    this.surname = surname;
  }

  addEmail(email: EmployeeEmail): Employee {
    this.email = email;
    return this;
  }

  addPosition(position: EmployeePosition): Employee {
    this.position = position;
    return this;
  }

  addEntry(entry: EmployeeEntryDate): Employee {
    this.entry = entry;
    return this;
  }

  addExit(exit: EmployeeExitDate): Employee {
    this.exit = exit;
    return this;
  }

  addNif(nif: EmployeeNif): Employee {
    this.nif = nif;
    return this;
  }

  addDocumentData(documentData: EmployeeDocumentData): Employee {
    this.documentData = documentData;
    return this;
  }

  getId(): EmployeeId {
    return this.id;
  }

  representedAs(
    employeeRepresentation: EmployeeRepresentation,
  ): EmployeeRepresentation {
    employeeRepresentation.setId(this.id);
    employeeRepresentation.setClientId(this.clientId);
    employeeRepresentation.setName(this.name);
    employeeRepresentation.setSurname(this.surname);
    employeeRepresentation.setEmail(this.email);
    employeeRepresentation.setPosition(this.position);
    employeeRepresentation.setEntry(this.entry);
    employeeRepresentation.setExit(this.exit);
    employeeRepresentation.setNif(this.nif);
    employeeRepresentation.setDocumentData(this.documentData);

    return employeeRepresentation;
  }
}
