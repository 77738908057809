import TaskId from '@/domain/task/valueObjects/TaskId';
import TaskResponseId from '@/domain/task/valueObjects/TaskResponseId';
import ClientUserId from '@/domain/task/valueObjects/ClientUserId';
import CompletionDateResponse from '@/domain/task/valueObjects/CompletionDateResponse';
import ResponseReply from '@/domain/task/valueObjects/ResponseReply';
import TaskResponse from '@/domain/task/response/TaskResponse';

export default class TaskResponseFactory {
  static create({
    taskResponseId,
    taskId,
    clientUserId,
    completionDate,
    taskResponseReply,
  }) {
    const taskResponseIdValueObject = new TaskResponseId(taskResponseId);
    const taskIdValueObject = new TaskId(taskId);
    const clientUserIdValueObject = new ClientUserId(clientUserId);
    const completionDateValueObject = new CompletionDateResponse(completionDate);
    const taskResponseReplyValueObject = new ResponseReply(taskResponseReply);

    return new TaskResponse(
      taskResponseIdValueObject,
      taskIdValueObject,
      clientUserIdValueObject,
      completionDateValueObject,
      taskResponseReplyValueObject,
    );
  }
}
