<template>
  <v-row class="checkbox-button">
    <v-col
      v-for="(item, index) in items"
      :key="index"
      cols="12"
      :md="cols === 0 ? 'auto' : cols"
    >
      <v-btn
        :outlined="!value.includes(item[itemValue])"
        color="primary"
        @click="updateValue(item[itemValue])"
        :class="{'text-uppercase' : textUppercase}"
      >
        {{ item.name }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'checkbox-button-field',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: 0,
    },
    textUppercase: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
  methods: {
    updateValue(id) {
      if (this.input.includes(id)) {
        const index = this.input.indexOf(id);
        if (index > -1) {
          this.input.splice(index, 1);
        }
      } else {
        this.input.push(id);
      }
      this.$emit('update:value', this.input);
    },
  },
  watch: {
    value() {
      this.input = this.value;
    },
  },
};
</script>
