const ADD_TOMS = (state, dataBreaches) => {
  state.toms = dataBreaches;
};

const RESET_TOMS = (state) => {
  state.toms = {};
};

export default {
  ADD_TOMS,
  RESET_TOMS,
};
