import FetchDataBreachesHistoric from '@/application/dataBreach/historic/fetchDataBreaches/FetchDataBreachesHistoric';
import DataBreachHistoricReadModelApi from '@/infrastructure/dataBreach/historic/DataBreachHistoricReadModelApi';
import Translator from '@/infrastructure/Translator';
import ResetDataBreaches from '@/application/dataBreach/historic/resetDataBreaches/ResetDataBreaches';
import DataBreachHistoricWriteModelApi
  from '@/infrastructure/dataBreach/historic/DataBreachHistoricWriteModelApi';

export default {
  resetDataBreaches: new ResetDataBreaches(new DataBreachHistoricWriteModelApi()),
  fetchDataBreaches: new FetchDataBreachesHistoric(
    new DataBreachHistoricReadModelApi(new Translator()),
  ),
};

export const resetDataBreaches = new ResetDataBreaches(new DataBreachHistoricWriteModelApi());

export const fetchDataBreaches = new FetchDataBreachesHistoric(
  new DataBreachHistoricReadModelApi(new Translator()),
);
