import Translator from '@/infrastructure/Translator';
import normalization from '@/services/shared/normalization';

export default ({
  countryRepository,
}) => async () => {
  try {
    const translator = new Translator();

    const response = await countryRepository.getCountriesList();
    response.data.forEach((obj, index) => {
      if (translator.translationExist(`countries.by_id.country_${obj.id}`)) {
        response.data[index].name = translator.translate(`countries.by_id.country_${obj.id}`);
      }
    });

    return {
      success: true,
      data: response.data.sort(
        (firstCountry, secondCountry) => (
          (normalization.normalizeString(firstCountry.name)
            > normalization.normalizeString(secondCountry.name)) ? 1 : -1),
      ),
    };
  } catch (e) {
    return { success: false, data: [] };
  }
};
