import ClientId from '@/domain/client/ClientId';
import ImportFileRepository from '@/domain/import/ImportFileRepository';
import ImportFile from '@/domain/import/ImportFile';
import ImportFileType from '@/domain/import/ImportFileType';
import ImportFileContent from '@/domain/import/ImportFileContent';
import ImportFileName from '@/domain/import/ImportFileName';

export default class CreateImportFile {
  private readonly importFileRepository: ImportFileRepository;

  constructor(importFileRepository: ImportFileRepository) {
    this.importFileRepository = importFileRepository;
  }

  public async invoke(
    clientId: number, file: {[key: string]: string}, type: string,
  ): Promise<object> {
    try {
      await this.importFileRepository.save(
        ClientId.fromInt(clientId),
        new ImportFile(
          ImportFileName.fromString(file.name),
          ImportFileContent.fromString(file.content),
          ImportFileType.fromString(type),
        ),
      );
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
