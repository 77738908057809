import store from '@/store';

export default class HistoricRoute {
  public static addRoute(previousRoute: any, nextRoute: any) {
    const routeToSave: any = !previousRoute.name
      ? this.buildRouteToSave(nextRoute)
      : this.buildRouteToSave(previousRoute);

    if (routeToSave.hasToExclude) {
      return;
    }

    const historicRoutes: any[] = this.load();
    historicRoutes.push(routeToSave);

    if (historicRoutes.length > 5) {
      historicRoutes.shift();
    }

    this.save(historicRoutes);
  }

  private static buildRouteToSave(route: any) {
    return {
      name: route.name,
      fullPath: route.fullPath,
      meta: route.meta,
      hash: route.hash,
      params: route.params,
      path: route.path,
      query: route.query,
      hasToExclude: route.meta?.hasToExclude || false,
    };
  }

  private static load(): any[] {
    return store.getters['historicRoutes/historicRoutes'];
  }

  private static save(historicRoutes: any[]) {
    store.dispatch('historicRoutes/saveHistoricRoutes', historicRoutes);
  }

  public static reset() {
    store.dispatch('historicRoutes/resetHistoricRoutes');
  }

  public static hasHistoric(): boolean {
    const historicRoutes: any[] = HistoricRoute.load();
    return !!historicRoutes.length;
  }

  public static routeToGo(): any {
    const routes: any = this.load();
    if (!routes.length) {
      return { name: 'home' };
    }

    return routes[routes.length - 1];
  }
}
