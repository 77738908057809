import TaskRepresentationForList from './TaskRepresentationForList';

export default ({
  taskRepository,
}) => async (clientId) => {
  try {
    const tasks = await taskRepository.getPendingTasks(clientId);
    const tasksList = [];
    tasks.forEach((task) => {
      tasksList.push(task.representedAs(new TaskRepresentationForList()));
    });

    return { success: true, tasks: tasksList };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
