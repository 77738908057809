import TomRepository from '@/domain/tom/TomRepository';
import Translator from '@/infrastructure/Translator';
import FetchTomQuery from '@/application/tom/fetchTom/FetchTomQuery';
import Tom from '@/domain/tom/Tom';
import TomId from '@/domain/tom/TomId';
import GetTomRepresentation from '@/application/tom/fetchTom/GetTomRepresentation';

export default class FetchTomQueryHandler {
  private readonly tomRepository: TomRepository;

  private readonly translator: Translator;

  constructor(tomRepository: TomRepository, translator: Translator) {
    this.tomRepository = tomRepository;
    this.translator = translator;
  }

  public async invoke(query: FetchTomQuery): Promise<any> {
    try {
      const tom: Tom = await this.tomRepository.retrieve(TomId.fromString(query.tomId));

      return { success: true, tom: tom.representedAs(new GetTomRepresentation(this.translator)) };
    } catch (e) {
      return { success: false, error: e.response?.data?.data || '' };
    }
  }
}
