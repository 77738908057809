const showDocumentViewer = ({ commit }) => {
  commit('SHOW_DOCUMENT_VIEWER');
};
const hideDocumentViewer = ({ commit }) => {
  commit('RESET_DOCUMENT_VIEWER');
};

const setDocumentInformation = ({ commit }, data) => {
  commit('SET_URL_DOCUMENT', data.url);
  commit('SET_NAME_DOCUMENT', data.name);
};
const resetDocumentInformation = ({ commit }) => {
  commit('RESET_URL_DOCUMENT');
  commit('RESET_NAME_DOCUMENT');
};

export default {
  showDocumentViewer,
  hideDocumentViewer,
  setDocumentInformation,
  resetDocumentInformation,
};
