<template>
  <v-row>
    <v-col cols="12">
      <title-section
        :title="$t('toms.title')"
        :subtitle="$t('toms.description')"
      />
    </v-col>
    <v-col cols="12">
      <submenu-section
        :tabActive.sync="currentTab"
        :sections="menuSections"
        v-on:update:tabActive="tabWasChanged"
      >
        <v-tab-item
          v-for="section in menuSections"
          :key="section.key"
          :value="section.key"
          :transition="false"
        >
          <v-row class="py-0 mt-n10">
            <v-col cols="12">
              <p>{{ section.subtitle }}</p>
            </v-col>
          </v-row>
          <component
            :isCurrentTab="section.key === currentTab"
            :is="section.key"
          />
        </v-tab-item>
      </submenu-section>
    </v-col>
  </v-row>
</template>

<script>
import TitleSection from '@/components/base/section/TitleSection.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import LibraryTomSection from '@/components/modules/tom/library/LibraryTomSection.vue';
import ImplementedTomSection from '@/components/modules/tom/implemented/ImplementedTomSection.vue';
import {
  changeTomStatus,
  fetchTomFilters,
  fetchToms,
  fetchTom,
  editTom,
} from '@/infrastructure/application/tom';
import languagesUseCases from '@/application/language/';
import { downloadDocument } from '@/application/document';
import PendingToImplementTomSection
  from '@/components/modules/tom/pendingToImplement/PendingToImplementTomSection.vue';

export default {
  name: 'tom-container',
  provide: {
    fetchToms,
    fetchTomFilters,
    changeTomStatus,
    fetchTom,
    ...languagesUseCases,
    editTom,
    downloadDocument,
  },
  components: {
    SubmenuSection,
    TitleSection,
    library: LibraryTomSection,
    implemented: ImplementedTomSection,
    'pending-to-implement': PendingToImplementTomSection,
  },
  computed: {
    menuSections() {
      return [
        {
          name: this.$t('toms.menu.implemented_toms', { amount: this.totalImplementedToms }),
          key: 'implemented',
          subtitle: this.$t('toms.implemented_toms.info'),
        },
        {
          name: this.$t('toms.menu.pending_to_implement', { amount: this.totalPendingImplementToms }),
          key: 'pending-to-implement',
          subtitle: this.$t('toms.info.pending_to_implement'),
        },
        {
          name: this.$t('toms.menu.toms_library', { amount: this.totalToms }),
          key: 'library',
          subtitle: this.$t('toms.info.toms_library'),
        },
      ];
    },
  },
  data() {
    return {
      currentTab: 'library',
      totalToms: 0,
      totalImplementedToms: 0,
      totalPendingImplementToms: 0,
    };
  },
  methods: {
    async updateTotalAmounts(list) {
      this.totalToms = list.length;
      this.totalImplementedToms = list.filter((tom) => tom.status === 'implemented').length;
      this.totalPendingImplementToms = list.filter((tom) => tom.status === 'pendingToImplement').length;
    },
    updateCurrentTab() {
      this.$eventHub.$off('tomListLoaded', this.updateCurrentTab);
      if (this.totalImplementedToms > 0) {
        this.currentTab = 'implemented';
        return;
      }
      this.$router.push({ name: 'tom-library' });
    },
    tabWasChanged(sectionName) {
      this.trackActions(sectionName);
      this.$router.push({ path: `/tom/${sectionName}` });
    },
    trackActions(sectionName) {
      this.$track({
        action: `Go to Toms  ${sectionName}`,
      });
    },
    updateCurrentTabByRoute() {
      if (!this.$route.path.includes('tom')) {
        return;
      }

      const tab = this.getTabFromRoute();

      if (!tab) {
        this.$router.push({ name: `tom-${this.currentTab}` });
        return;
      }

      this.currentTab = tab;
    },
    getTabFromRoute() {
      return this.$route.path.substring(1).split('/')[1];
    },
  },
  beforeMount() {
    this.$eventHub.$on('tomListLoaded', this.updateTotalAmounts);

    this.$watch(() => this.$route, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.updateCurrentTabByRoute();
      }
    });

    if (!this.getTabFromRoute()) {
      this.$eventHub.$on('tomListLoaded', this.updateCurrentTab);
      return;
    }
    this.updateCurrentTabByRoute();
  },
  beforeDestroy() {
    this.$eventHub.$off('tomListLoaded', this.updateTotalAmounts);
  },
};
</script>
