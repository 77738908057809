<template>
  <tooltip
    :items="documents"
  >
    <template slot-scope="props">
      <v-btn
        v-on="props.on"
        rounded
        outlined
        color="primary"
        @click="downloadAttachedDocument(props.item.id, props.item.name)"
      >
        <v-icon
          class="material-icons-round"
        >
          file_download
        </v-icon>
        {{ props.itemName }}
      </v-btn>
    </template>
  </tooltip>
</template>

<script>
import Tooltip from '@/components/base/section/Tooltip.vue';

export default {
  name: 'download-attached-document-button',
  components: { Tooltip },
  inject: {
    downloadDocument: 'downloadDocument',
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async downloadAttachedDocument(documentId, documentName) {
      this.$showLoading();
      const response = await this.downloadDocument.invoke('data-breach-document', documentId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('errorPage.error_download');
      }

      this.$track({
        action: 'download data breach attached document',
        result: response.success,
        data_breach_attached_document_name: documentName,
      });
    },
  },
};
</script>
