import EquipmentType from '@/domain/equipment/equipmentType/EquipmentType';
import EquipmentTypesRepresentation from '@/application/equipment/fetchTypes/EquipmentTypesRepresentation';

export default (
  translator,
) => () => {
  const types = EquipmentType.all();
  const typesRepresentation = types.map((type) => type.representedAs(
    new EquipmentTypesRepresentation(translator),
  ));
  return typesRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
