<template>
  <div class="h-100 w-100">
    <alert
      v-if="errorFeedback.length > 0"
      :messages.sync="errorFeedback"
      type="error"
    />
    <p
      class="mb-5"
    >
      {{ $t('forgotPassword.put_email') }}
    </p>
    <input-field
      :value.sync="user.email"
      :label="$t('forgotPassword.email') + '*'"
      @keyUpEnter="$emit('recoverPassword', user.email)"
      :error.sync="errorInput"
    />
    <v-row
      class="inline__elements"
      align="center"
    >
      <v-col cols="6" class="text-left">
        <router-link :to="{name: 'login'}">
          {{ $t('forgotPassword.return_login_page') }}
        </router-link>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          color="primary"
          rounded
          @click="$emit('recoverPassword', user.email)"
        >
          {{ $t('forgotPassword.continue') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InputField from '../../base/form/InputField.vue';
import Alert from '../../base/feedback/Alert.vue';

export default {
  name: 'ask-email',
  components: {
    InputField,
    Alert,
  },
  props: {
    errorFeedback: {
      type: Array,
      default: () => [],
    },
    errorInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.errorFeedback.length > 0;
    },
  },
  data() {
    return {
      user: {
        email: '',
      },
    };
  },
};
</script>
