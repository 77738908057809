import { getManagement } from '@/shared/restActions';

const typeDocumentUrl = {
  contracts: 'treatment-manager-contract',
  registersTreatment: 'processors-list',
};

function getUrlUserIsAdmin(companyId, isAdmin) {
  if (isAdmin) {
    return `?company=${companyId}`;
  }
  return '';
}

function getListDocuments(companyId, lang, isAdmin) {
  let url = `/api/v2/documents-legacy/treatmentManagers/${lang}/list-contracts/${companyId}`;

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function downloadDocument(
  documentId, typeDocument, type,
) {
  return getManagement(`/api/v2/documents/${typeDocumentUrl[typeDocument]}/${documentId}/download/${type}`, {})
    .then((response) => response.data);
}

function viewDocument(
  documentId, typeDocument, lang,
) {
  return getManagement(`/api/v2/documents/${typeDocumentUrl[typeDocument]}/${documentId}/preview/${lang}`, {})
    .then((response) => response.data);
}

function hasTreatmentManagers(companyId) {
  const url = `/api/v2/documents-legacy/treatmentManagers/has-treatment-managers/${companyId}`;

  return getManagement(url, {});
}

export default {
  getListDocuments,
  downloadDocument,
  viewDocument,
  hasTreatmentManagers,
};
