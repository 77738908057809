<template>
  <v-col cols="12">
    <h4>
      <span>
       {{ $t('clientUsers.massupload.step2') }}
      </span>
    </h4>
    <p>{{ $t('clientUsers.massupload.formats') }}</p>
    <upload-document
      v-if="!usersFileUploaded.length"
      :allowedExtensions="allowedExtensions"
      :filesUploaded="usersFileUploaded"
      v-track="'Massupload -- upload file'"
    />
    <uploaded-file
      v-else
      :filesUploaded="usersFileUploaded"
      v-track="'Massupload -- delete file'"
    />
  </v-col>
</template>

<script>
import UploadDocument from '@/components/base/form/UploadDocument.vue';
import UploadedFile from '@/components/base/form/UploadedFile.vue';

const ALLOWED_EXTENSIONS = ['xlsx'];

export default {
  name: 'upload-users-file',
  components: {
    UploadedFile,
    UploadDocument,
  },
  props: {
    usersFileUploaded: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    allowedExtensions() {
      return ALLOWED_EXTENSIONS;
    },
  },
  methods: {
    keepOneFile() {
      this.usersFileUploaded.splice(1, this.usersFileUploaded.length);
    },
  },
  watch: {
    'usersFileUploaded.length': function () {
      this.keepOneFile();
    },
  },
};
</script>
