import Translator from '@/infrastructure/Translator';

const URL_SPANISH_ADMIN_AVATAR = require('@/assets/img/avatar_lisa.png');
const URL_ENGLISH_ADMIN_AVATAR = require('@/assets/img/avatar_charlie.png');
const URL_WORLD_ADMIN_AVATAR = require('@/assets/img/avatar_lisa.png');

const SPAIN_COUNTRY_ID = 205;
const UK_COUNTRY_ID = 241;

export default function getAdminInfoAnnotations(userCountry) {
  const translator = new Translator();
  const adminInfo = {};
  switch (userCountry) {
    case SPAIN_COUNTRY_ID:
      adminInfo.name = 'Lisa Hofmann';
      adminInfo.avatarUrl = URL_SPANISH_ADMIN_AVATAR;
      adminInfo.position = translator.translate('processing_activities.admin_info.position.female');
      break;
    case UK_COUNTRY_ID:
      adminInfo.name = 'Charles Trevitt';
      adminInfo.avatarUrl = URL_ENGLISH_ADMIN_AVATAR;
      adminInfo.position = translator.translate('processing_activities.admin_info.position.male');
      break;
    default:
      adminInfo.name = 'Lisa Hofmann';
      adminInfo.avatarUrl = URL_WORLD_ADMIN_AVATAR;
      adminInfo.position = translator.translate('processing_activities.admin_info.position.female');
      break;
  }
  return adminInfo;
}
