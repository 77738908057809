<template>
  <div>
    <h2>
      {{ $t('legalNotice.title') }}
    </h2>
     <v-row justify="center">
       <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.legal_info.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.legal_info.p_first') }}
              <strong>{{ $t('legalNotice.legal_info.p_first_bold') }}</strong>
              {{ $t('legalNotice.legal_info.p_first_normal') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.user_accept.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.user_accept.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.use_content.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.use_content.p_first_first') }}
              <br>
              {{ $t('legalNotice.use_content.p_first_second') }}
              <br>
              {{ $t('legalNotice.use_content.p_first_third') }}
            </p>
            <p>
              <ul>
                <li>
                  <strong>
                    {{ $t('legalNotice.use_content.p_second_list_first_bold') }}
                  </strong>
                  <br>
                    {{ $t('legalNotice.use_content.p_second_list_first_first') }}
                  <br>
                    {{ $t('legalNotice.use_content.p_second_list_first_second') }}
                  <br>
                    {{ $t('legalNotice.use_content.p_second_list_first_third') }}
                  <ul>
                    <li>
                      {{ $t('legalNotice.use_content.p_second_list_list_first') }}
                    </li>
                    <li>
                      {{ $t('legalNotice.use_content.p_second_list_list_second') }}
                    </li>
                    <li>
                      {{ $t('legalNotice.use_content.p_second_list_list_third') }}
                    </li>
                  </ul>
                  <br>
                  {{ $t('legalNotice.use_content.p_second_list_second_second') }}
                  <br>
                  {{ $t('legalNotice.use_content.p_second_list_second_third') }}
                  <br>
                  {{ $t('legalNotice.use_content.p_second_list_second_fourth') }}
                  <ul>
                    <li>
                      <strong>
                        {{ $t('legalNotice.use_content.p_second_list_list') }}
                      </strong>
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.software.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>
                {{ $t('legalNotice.software.p_first_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.software.p_first_first') }}
              <br>
              {{ $t('legalNotice.software.p_first_second') }}
              <br>
              {{ $t('legalNotice.software.p_first_third') }}
              <br>
              {{ $t('legalNotice.software.p_first_fourth') }}
            </p>
            <p>
              <strong>
                {{ $t('legalNotice.software.p_second_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.software.p_second_first') }}
              <br>
              {{ $t('legalNotice.software.p_second_second') }}
              <br>
              {{ $t('legalNotice.software.p_second_third') }}
              <br>
              {{ $t('legalNotice.software.p_second_fourth') }}
              <br>
              {{ $t('legalNotice.software.p_second_fifth') }}
            </p>
            <p>
              <strong>
                {{ $t('legalNotice.software.p_third_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.software.p_third_first') }}
              {{ $t('legalNotice.software.p_third_first_bold') }}
              {{ $t('legalNotice.software.p_third_first_normal') }}
              <br>
              {{ $t('legalNotice.software.p_third_second') }}
              <br>
              {{ $t('legalNotice.software.p_third_third') }}
              <br>
              {{ $t('legalNotice.software.p_third_fourth') }}
              <br>
              {{ $t('legalNotice.software.p_third_fifth') }}
              <ul>
                <li>
                  {{ $t('legalNotice.software.p_third_fifth_list_first') }}
                </li>
                <li>
                  {{ $t('legalNotice.software.p_third_fifth_list_second') }}
                </li>
              </ul>
              {{ $t('legalNotice.software.p_third_sixth') }}
              <br>
              {{ $t('legalNotice.software.p_third_seventh') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.juridic_services.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>
                {{ $t('legalNotice.juridic_services.p_first_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.juridic_services.p_first_first') }}
              <ul>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_first') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_second') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_third') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_fourth') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_fifth') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_sixth') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_seventh') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_eigth') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_nineth') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_first_first_list_tenth') }}
                </li>
              </ul>
            </p>
            <p>
              <strong>
                {{ $t('legalNotice.juridic_services.p_second_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.juridic_services.p_second_first') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_second_second') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_second_third') }}
            </p>
            <p>
              <strong>
                {{ $t('legalNotice.juridic_services.p_third_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.juridic_services.p_third_first') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_second') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_third') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_fourth') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_fifth') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_sixth') }}
              <ul>
                <li>
                  {{ $t('legalNotice.juridic_services.p_third_sixth_list_first') }}
                </li>
                <li>
                  {{ $t('legalNotice.juridic_services.p_third_sixth_list_second') }}
                </li>
              </ul>
              {{ $t('legalNotice.juridic_services.p_third_seventh') }}
              <br>
              {{ $t('legalNotice.juridic_services.p_third_eigth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.academy.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>
                {{ $t('legalNotice.academy.p_first_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.academy.p_first_first') }}
              {{ $t('legalNotice.academy.p_first_first_bold') }}
              <br>
              {{ $t('legalNotice.academy.p_first_second') }}
              <br>
              {{ $t('legalNotice.academy.p_first_third') }}
              <br>
              {{ $t('legalNotice.academy.p_first_fourth') }}
            </p>
            <p>
              <strong>
                {{ $t('legalNotice.academy.p_second_bold') }}
              </strong>
              <br>
              {{ $t('legalNotice.academy.p_second_first') }}
              {{ $t('legalNotice.academy.p_second_first_bold') }}
              {{ $t('legalNotice.academy.p_second_first_normal') }}
              <ul>
                <li>
                  <strong>
                    {{ $t('legalNotice.academy.p_second_first_list_bold') }}
                  </strong>
                  <br>
                  {{ $t('legalNotice.academy.p_second_first_list_first') }}
                  <br>
                  {{ $t('legalNotice.academy.p_second_first_list_second') }}
                  <strong>
                    {{ $t('legalNotice.academy.p_second_first_list_second_bold') }}
                  </strong>
                  <br>
                    {{ $t('legalNotice.academy.p_second_first_list_third') }}
                  <br>
                    {{ $t('legalNotice.academy.p_second_first_list_fourth') }}
                </li>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.responsibility.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.responsibility.p_first') }}
              <ul>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_first') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_second') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_third') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_fourth') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_fifth') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_sixth') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_seventh') }}
                </li>
                <li>
                  {{ $t('legalNotice.responsibility.p_first_list_eigth') }}
                </li>
              </ul>
            </p>
            <p>
              {{ $t('legalNotice.responsibility.p_second_first') }}
              <br>
              {{ $t('legalNotice.responsibility.p_second_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.policy.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.policy.p_first_first') }}
              <br>
              {{ $t('legalNotice.policy.p_first_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
       </v-expansion-panels>
     </v-row>
  </div>
</template>

<script>
export default {
  name: 'legal-notice-es',
};
</script>
