import store from '@/store';

export default class PreviewFile {
  invoke(url, name) {
    PreviewFile.setDocumentInformation({ url, name });
    PreviewFile.showDocumentViewer();
  }

  static setDocumentInformation(data) {
    store.dispatch('viewDocument/setDocumentInformation', data);
  }

  static showDocumentViewer() {
    store.dispatch('viewDocument/showDocumentViewer');
  }
}
