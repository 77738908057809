import CreateDataBreach from '@/application/dataBreach/createDataBreach/CreateDataBreach';
import DataBreachRepositoryApi from '@/infrastructure/dataBreach/DataBreachRepositoryApi';
import ResetDataBreaches from '@/application/dataBreach/resetDataBreaches/ResetDataBreaches';
import DataBreachWasCreatedEventListener
  from '@/application/dataBreach/dataBreachWasCreated/DataBreachWasCreatedEventListener';
import DataBreachesPendingWriteModelApi
  from '@/infrastructure/dataBreach/pending/DataBreachesPendingWriteModelApi';
import FetchDataBreachesPending
  from '@/application/dataBreach/pending/fetchDataBreachesPending/FetchDataBreachesPending';
import DataBreachesPendingReadModelApi
  from '@/infrastructure/dataBreach/pending/DataBreachesPendingReadModelApi';
import DeleteDataBreach from '@/application/dataBreach/deleteDataBreach/deleteDataBreach';
import DataBreachWasDeletedEventListener
  from '@/application/dataBreach/dataBreachWasDeleted/DataBreachWasDeletedEventListener';
import FetchDataBreach from '@/application/dataBreach/fetchDataBreach/FetchDataBreach';
import EditDataBreach from '@/application/dataBreach/editDataBreach/EditDataBreach';
import CreateDataBreachWithReportWrapper
  from '@/application/dataBreach/createDataBreachWithReportWrapper/CreateDataBreachWithReportWrapper';
import DataBreachWasCompletedEventListener
  from '@/application/dataBreach/dataBreachWasCompleted/DataBreachWasCompletedEventListener';
import DataBreachHistoricWriteModelApi
  from '@/infrastructure/dataBreach/historic/DataBreachHistoricWriteModelApi';
import EditDataBreachWithReportWrapper
  from '@/application/dataBreach/editDataBreachWithReportWrapper/EditDataBreachWithReportWrapper';

export default {
  resetDataBreaches: new ResetDataBreaches(new DataBreachRepositoryApi()),
  createDataBreach: new CreateDataBreach(
    new DataBreachRepositoryApi(),
    new DataBreachWasCreatedEventListener(new DataBreachesPendingWriteModelApi()),
  ),
  fetchDataBreachesPending: new FetchDataBreachesPending(new DataBreachesPendingReadModelApi()),
  deleteDataBreach: new DeleteDataBreach(
    new DataBreachRepositoryApi(),
    new DataBreachWasDeletedEventListener(new DataBreachesPendingWriteModelApi()),
  ),
  fetchDataBreach: new FetchDataBreach(new DataBreachRepositoryApi()),
  editDataBreach: new EditDataBreach(new DataBreachRepositoryApi()),
  createDataBreachWithReportWrapper: new CreateDataBreachWithReportWrapper(
    new CreateDataBreach(
      new DataBreachRepositoryApi(),
      new DataBreachWasCreatedEventListener(new DataBreachesPendingWriteModelApi()),
    ),
    new DataBreachWasCompletedEventListener(
      new DataBreachesPendingWriteModelApi(),
      new DataBreachHistoricWriteModelApi(),
    ),
  ),
  editDataBreachWithReportWrapper: new EditDataBreachWithReportWrapper(
    new EditDataBreach(new DataBreachRepositoryApi()),
    new DataBreachWasCompletedEventListener(
      new DataBreachesPendingWriteModelApi(),
      new DataBreachHistoricWriteModelApi(),
    ),
  ),
};
