const ADD_RECIPIENTS_MODELS = (state, recipientsModels) => {
  state.recipientsModels = recipientsModels;
};

const RESET_RECIPIENTS_MODELS = (state) => {
  state.recipientsModels = [];
};

export default {
  ADD_RECIPIENTS_MODELS,
  RESET_RECIPIENTS_MODELS,
};
