import EmployeeName from '@/domain/training/trainingEmployee/EmployeeName';
import EmployeeEmail from '@/domain/training/trainingEmployee/EmployeeEmail';
import Inscription from '@/domain/training/inscription/Inscription';

export default abstract class TrainingEmployeeRepresentation {
  protected _name!: EmployeeName;

  protected _email!: EmployeeEmail | null;

  protected _inscriptions!: Inscription[];

  setName(name: EmployeeName) {
    this._name = name;
  }

  setEmail(email: EmployeeEmail | null) {
    this._email = email;
  }

  setInscriptions(inscriptions: Inscription[]) {
    this._inscriptions = inscriptions;
  }
}
