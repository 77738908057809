<template>
  <div>
    <v-data-table
      :sort-by="sortBy"
      :search="search"
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :no-results-text="$t('table.no_results')"
      :no-data-text="$t('table.no_results')"
      :sort-desc="sortDesc"
      hide-default-footer
      class="elevation-0 table-documents"
      item-key="key"
      :custom-filter="searchFilterItems"
      @pagination="updateTotalItems"
    >
      <template v-slot:item.title="{ item }">
        <a
          v-if="actions.includes('view') && !item.signedUrl"
          @click="previousView(item)"
        >
          {{ item.title | noNameFilter($t('table.no_name')) }}
        </a>
        <span v-else>
          {{ item.title }}
        </span>
        <feature tag="span" name="processingActivitiesOldChip">
          <v-tooltip bottom v-if="['rat','ret','uploaded-file'].includes(item.type)">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" small class="table-chip__old">
                {{ $t('table.processing_activity_old_label') }}
              </v-chip>
            </template>
            <span>
              {{ $t('table.processing_activity_old') }}
            </span>
          </v-tooltip>
        </feature>
      </template>
      <template v-slot:item.type="{ item }">
        {{  item.typeName }}
      </template>
      <template v-slot:item.LastModified="{ item }">
        {{  new Date(item.LastModified).toLocaleDateString('en-GB') }}
          - {{  new Date(item.LastModified).toLocaleTimeString([],
          { hour: '2-digit', minute: '2-digit' }) }}
      </template>
      <template v-slot:item.updated="{ item }">
        {{ new Date(item.updated).toLocaleDateString('en-GB') }}
      </template>
      <template v-slot:item.lastLogin="{ item }" v-if="actions.includes('resendEmail')">
        <span v-if="item.lastLogin">
          {{ item.lastLogin }}
        </span>
        <a v-else @click="$emit('resend', item)">
          {{ $t('clientUsers.table.resend') }} >
        </a>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="actions.includes('download')" class="td_actions">
          <v-btn
            v-if="downloadType.length !== 0 && downloadType.includes('doc') && !item.signedUrl"
            @click="previousDownload(item)"
            icon
          >
            <v-icon class="material-icons-round">
              get_app
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            @click="$emit('downloadDocument', item)"
          >
            <v-icon class="material-icons-round">
              get_app
            </v-icon>
          </v-btn>
        </div>
        <div v-if="actions.includes('delete')" class="td_actions">
          <v-btn
            icon
            @click="$emit('delete', item)"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </div>
        <div
          v-if="actions.includes('deleteUser') && item.id !== userId"
          class="td_actions"
        >
          <v-btn
            icon
            @click="$emit('delete', item)"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <footer-table
      v-show="showFooterIfHasMinItems"
      :page.sync="page"
      :itemsPerPage.sync="itemsPerPage"
      :totalItems="totalItems"
      :listLength="items.length"
    />
  </div>
</template>
<script>
import feature from '@/shared/featureToggle';
import normalizationService from '@/services/shared/normalization';

import FooterTable from './FooterTable.vue';

const ITEMS_PER_PAGE_DEFAULT = 10;

export default {
  name: 'default-table',
  components: {
    FooterTable,
    feature,
  },
  props: {
    sortBy: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    downloadType: {
      type: Array,
      default: () => [],
    },
    typesDocuments: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    searchBy: {
      type: Array,
      default: () => [],
    },
    customSearchFilterItems: {
      type: Function,
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    showFooterIfHasMinItems() {
      return this.totalItems > ITEMS_PER_PAGE_DEFAULT;
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
      totalItems: 0,
    };
  },
  methods: {
    previousDownload(document) {
      this.$emit('selectExtensionShow', document);
    },
    previousView(document) {
      this.$emit('viewDocument', document);
    },
    searchFilterItems(value, search, item) {
      const searchNorm = normalizationService.normalizeString(search);
      const normFields = [];

      const itemKeys = (this.searchBy.length) ? this.searchBy : Object.keys(item);

      itemKeys.map((key) => {
        if (this.headers.some((e) => e.value === key)) {
          const normValue = item[key] ? normalizationService.normalize(key, item[key]) : '';
          normFields.push(normValue);
        }
        return true;
      });
      return normFields.some((e) => e.includes(searchNorm));
    },
    updateTotalItems(paginationInfo) {
      this.totalItems = paginationInfo.itemsLength;
    },
  },
  filters: {
    noNameFilter(name, noName) {
      return (name === '') ? noName : name;
    },
  },
};
</script>
