import RecipientBuilder from '@/domain/recipient/RecipientBuilder';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';
import RecipientStatus from '@/domain/recipient/recipientStatus/RecipientStatus';
import RecipientWasCreated from '@/domain/recipient/RecipientWasCreated';

export default (
  recipientRepository,
  recipientWasCreatedEventListener,
) => async (recipientForm, clientId) => {
  try {
    const recipientId = UuidValueObject.generate();
    const lastModification = DateTimeValueObject.generateW3C();
    const recipientBuilder = new RecipientBuilder();

    recipientBuilder
      .withId(recipientId.toString())
      .withClientId(clientId)
      .withName(recipientForm.name)
      .withStatus(recipientForm.status || RecipientStatus.getDefaultStatus())
      .withLastModification(lastModification.toString())
      .withBusinessName(recipientForm.businessName)
      .withCountry(recipientForm.country)
      .withState(recipientForm.state)
      .withCategory(recipientForm.category)
      .withRole(recipientForm.role)
      .withWarranties(recipientForm.warranties)
      .withDpa(recipientForm.dpa)
      .withDpaStatus(recipientForm.dpaStatus)
      .attachDocuments(recipientForm.files)
      .withRecipientModel(recipientForm.recipientModel);

    const recipient = recipientBuilder.create();

    await recipientRepository.save(recipient);
    const event = new RecipientWasCreated(recipient);
    recipientWasCreatedEventListener.invoke(event);
    return { success: true };
  } catch (e) {
    return { success: false, error: e.response?.data?.data || null };
  }
};
