export default ({
  userRepository,
}) => async (token, password) => {
  try {
    await userRepository.activate(token, password);
    return { success: true };
  } catch (e) {
    return { success: false, data: e.data };
  }
};
