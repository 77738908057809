import TrainingRepository from '@/domain/training/TrainingRepository';
import FetchTrainingInfoRepresentation
  from '@/application/training/fetchTrainingInfo/FetchTrainingInfoRepresentation';
import ClientId from '@/domain/client/ClientId';

export default class FetchTrainingInfo {
  private trainingRepository: TrainingRepository;

  constructor(trainingRepository: TrainingRepository) {
    this.trainingRepository = trainingRepository;
  }

  public async invoke(clientId: string) {
    try {
      const trainingInfo = await this.trainingRepository.ofClient(ClientId.fromString(clientId));
      const representation = trainingInfo.representedAs(new FetchTrainingInfoRepresentation());

      return { success: true, trainingInfo: representation };
    } catch (e) {
      return { success: false };
    }
  }
}
