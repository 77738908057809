<template>
  <div class="h-100 w-100">
    <div class="text-center confirm-passwrod__icon">
      <v-icon color="error">
        cancel
      </v-icon>
    </div>
    <p>
      {{ $t('changePassword.token_error.token_invalid') }}
    </p>
    <router-link :to="{name: 'forgot-password'}">
      {{ $t('changePassword.token_error.new_password') }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'change-password-token-error',
};
</script>
