<template>
<div class="h-100 w-100">
  <h2 class="mb-2"> 3.
    <span class="grey--text text--darken-3">{{ $t("data_breach.form.step.three.title") }}</span>
  </h2>
  <v-divider class="ml-5 mb-4"/>
  <v-row class="pl-5 data-breach__form-step">
    <v-col cols="12">
      <h4><span>{{ $t("data_breach.form.step.three.question_1") }}</span></h4>
      <p>{{ $t("data_breach.form.step.three.question_1_info") }}</p>
      <textarea-field
        :value.sync="dataBreach.securityMeasuresImplemented"
        :counter="600"
        :counterValue="textCounter"
        :rules="[validations.maxLength]"
      />
    </v-col>
    <v-col cols="12">
      <h4><span>{{ $t("data_breach.form.step.three.question_2") }}</span></h4>
      <textarea-field
        :value.sync="dataBreach.futureSecurityMeasures"
        :counter="600"
        :counterValue="textCounter"
        :rules="[validations.maxLength]"
      />
    </v-col>
    <v-col cols="12">
      <h4><span>{{ $t("data_breach.form.step.three.question_3") }}</span></h4>
      <textarea-field
        :value.sync="dataBreach.securityMeasuresProblems"
        :counter="600"
        :counterValue="textCounter"
        :rules="[validations.maxLength]"
      />
    </v-col>
    <v-col cols="12">
      <h4><span>{{ $t("data_breach.form.step.three.question_4") }}</span></h4>
      <p>
        {{ $t("data_breach.form.step.three.question_4_info") }}
      </p>
      <textarea-field
        :value.sync="dataBreach.informedAffectedPeople"
        :counter="600"
        :counterValue="textCounter"
        :rules="[validations.maxLength]"
      />
    </v-col>
    <v-col cols="12">
      <h4><span>{{ $t("data_breach.form.step.three.question_5") }}</span></h4>
      <p>
        {{ $t("data_breach.form.step.three.question_5_info") }}
      </p>
      <textarea-field
        :value.sync="dataBreach.informedAuthorities"
        :counter="600"
        :counterValue="textCounter"
        :rules="[validations.maxLength]"
      />
    </v-col>
  </v-row>
</div>
</template>

<script>
import TextareaField from '@/components/base/form/TextareaField.vue';

export default {
  name: 'preventive-measures',
  components: { TextareaField },
  props: {
    dataBreach: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      validations: {
        maxLength: (v) => v.trim().length <= 600 || this.$t('form.validations.max_length', { length: 600 }),
      },
    };
  },
  methods: {
    textCounter(text) {
      return text.trim().length;
    },
  },
};
</script>
