import TrainingEmployeeRepresentation
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepresentation';
import Inscription from '@/domain/training/inscription/Inscription';

interface Inscriptions {
  name: string,
  status: string,
  isCompleted: boolean,
  id: string,
}

export default class TrainingEmployeesRepresentation extends TrainingEmployeeRepresentation {
  get name(): string {
    return this._name.toString();
  }

  get email(): string {
    return (this._email) ? this._email.toString() : '';
  }

  get inscriptions(): Inscriptions[] {
    return this._inscriptions.map((inscription: Inscription) => ({
      name: inscription.getNameCourse().toString(),
      status: inscription.getStatus().toString(),
      isCompleted: inscription.getStatus().isCompleted(),
      id: inscription.getId().toString(),
    }));
  }
}
