export default class RecipientWasUpdated {
  recipient;

  ocurredOn;

  constructor(recipient) {
    this._ocurredOn = new Date();
    this._recipient = recipient;
  }

  getRecipient() {
    return this._recipient;
  }
}
