import Headquarter from '../../../domain/headquarter/Headquarter';
import UuidValueObject from '../../../domain/shared/uuid/UuidValueObject';
import ClientId from '../../../domain/client/ClientId';
import HeadquarterName from '../../../domain/headquarter/HeadquarterName';
import HeadquarterAddress from '../../../domain/headquarter/HeadquarterAddress';
import Country from '../../../domain/country/Country';
import HeadquarterLocality from '../../../domain/headquarter/HeadquarterLocality';
import HeadquarterSecurityMeasures from '../../../domain/headquarter/headquarterSecurityMeasures/HeadquarterSecurityMeasures';

export default (
  headquarterRepository,
) => async (headquarterForm, clientId) => {
  try {
    const headquarter = new Headquarter(
      UuidValueObject.generate(),
      ClientId.fromInt(clientId),
      HeadquarterName.fromString(headquarterForm.name),
      HeadquarterAddress.fromString(headquarterForm.address),
      Country.fromCode(headquarterForm.countryCode),
      HeadquarterLocality.fromString(headquarterForm.locality),
    );

    if (headquarterForm.securityMeasures.length) {
      headquarter.addSecurityMeasures(
        headquarterForm.securityMeasures.map(
          (securityMeasure) => HeadquarterSecurityMeasures.fromKey(securityMeasure),
        ),
      );
    }
    await headquarterRepository.save(headquarter);

    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
