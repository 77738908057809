<template>
  <v-col cols="12">
    <h4>
      <span>
        {{ $t('clientUsers.massupload.step1') }}
      </span>
    </h4>
    <p>
      {{ $t('clientUsers.massupload.step1_detail') }}
    </p>
    <v-btn
      outlined
      rounded
      color="primary"
      :href="templateUrl[$i18n.locale]"
      class="ml-4"
      v-track="'Massupload -- donwload template'"
    >
      <v-icon
        class="material-icons-round"
      >
        download
      </v-icon>
      {{ $t('clientUsers.massupload.download_excel_cta') }}
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'download-template',
  computed: {
    templateUrl() {
      return {
        ca: 'https://pud-assets.s3.eu-central-1.amazonaws.com/importers/restrictedEmployee/Plantilla-Pujada-Usuaris.xlsx',
        de: 'https://pud-assets.s3.eu-central-1.amazonaws.com/importers/restrictedEmployee/Vorlage-Benutzer-Upload.xlsx',
        en: 'https://pud-assets.s3.eu-central-1.amazonaws.com/importers/restrictedEmployee/Template-Users-Upload.xlsx',
        es: 'https://pud-assets.s3.eu-central-1.amazonaws.com/importers/restrictedEmployee/Plantilla-Subida-Usuarios.xlsx',
      };
    },
  },
};
</script>
