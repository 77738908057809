import EmailValueObject from '@/domain/shared/email/EmailValueObject';

export default class UserPassLoginCredentials implements LoginCredentials {
  private readonly _username: EmailValueObject;

  private readonly _password: string;

  constructor(username: EmailValueObject, password: string) {
    this._username = username;
    this._password = password;
  }

  get username(): string {
    return this._username.toString();
  }

  get password(): string {
    return this._password;
  }
}
