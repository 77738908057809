<template>
  <client-folder-container />
</template>
<script>
import ClientFolderContainer from '@/components/containers/ClientFolderContainer.vue';

export default {
  name: 'client-folder',
  components: {
    ClientFolderContainer,
  },
};
</script>
