<template>
  <registers-container />
</template>
<script>
import RegistersContainer from '@/components/containers/RegistersContainer.vue';

export default {
  name: 'registers',
  components: {
    RegistersContainer,
  },
};
</script>
