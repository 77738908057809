import TaskRepresentation from '@/domain/task/TaskRepresentation';

const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export default class TaskRepresentationForList extends TaskRepresentation {
  show;

  constructor() {
    super();
    this.show = false;
  }

  get taskId() {
    return super.getTaskId();
  }

  get taskType() {
    return super.getTaskType();
  }

  get requestPetition() {
    return super.getRequestPetition();
  }

  get expirationDate() {
    const date = new Date(`${super.getExpirationDate()}`);
    return `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${date.getUTCFullYear()}`;
  }

  get department() {
    return super.getDepartment().toString();
  }

  get groupBy() {
    if (super.getCritical()) {
      return 'critical';
    }

    const expirationDateSplit = super.getExpirationDate().split('-');
    const expiratonDateDaySplit = expirationDateSplit[2].split('T');
    const currentDate = new Date();
    const expirationDate = new Date(
      expirationDateSplit[0],
      expirationDateSplit[1],
      expiratonDateDaySplit[0],
    );
    const nextDate = new Date(new Date().setDate(currentDate.getUTCDate() + 1));

    currentDate.setDate(currentDate.getUTCDate());
    currentDate.setMonth(currentDate.getUTCMonth());
    currentDate.setFullYear(currentDate.getUTCFullYear());

    expirationDate.setDate(expiratonDateDaySplit[0]);
    expirationDate.setMonth(expirationDateSplit[1] - 1);
    expirationDate.setFullYear(expirationDateSplit[0]);

    nextDate.setDate(nextDate.getUTCDate());
    nextDate.setMonth(nextDate.getUTCMonth());
    nextDate.setFullYear(nextDate.getUTCFullYear());

    const today = currentDate.setHours(0, 0, 0, 0);
    const expirationDay = expirationDate.setHours(0, 0, 0, 0);
    const tomorrow = nextDate.setHours(0, 0, 0, 0);

    if (today > expirationDay) {
      return 'expired';
    }

    if (today === expirationDay) {
      return 'today';
    }

    if (tomorrow === expirationDay) {
      return 'tomorrow';
    }

    return `${expirationDate.getDate()}_${MONTHS[expirationDate.getMonth()]}_${expirationDate.getFullYear()}`;
  }

  get title() {
    return super.getTitle();
  }
}
