<template>
    <v-row class="activity-step">
      <v-col cols="12" class="text-center">
        <h2>
          {{ $t('processing_activities.step_data_security.process_owner_title') }}
        </h2>
      </v-col>
      <v-col cols="12" md="4" offset-md="4" class="pb-6">
        <input-field
          :value.sync="activity.process_owner"
          :label="$t('processing_activities.step_data_security.process_owner_label')"
        />
      </v-col>
      <v-col cols="12" class="text-center">
        <h2>
          {{ $t('processing_activities.step_data_security.data_deletion_title') }}
        </h2>
      </v-col>
      <v-col cols="12" class="text-center">
          <checkbox-yes-no  :value.sync="firstCheck"/>
      </v-col>
      <v-col cols="12" >
        <v-expand-transition>
          <div v-show="firstCheck" class="pb-6">
            <textarea-field
              :value.sync="activity.data_additional_comments"
              :label="$t('processing_activities.step_data_security.data_deletion_text_area_label')"
            />
          </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" class="text-center">
        <h2>
          {{ $t('processing_activities.step_data_security.data_process_title') }}
        </h2>
      </v-col>
      <v-col cols="12" class="text-center">
        <checkbox-yes-no  :value.sync="secondCheck"/>
      </v-col>
      <v-col cols="12">
        <div v-show="secondCheck" class="pb-6">
          <textarea-field
            :value.sync="activity.description_security_control_measures"
            :label="$t('processing_activities.step_data_security.data_process_text_area_label')"
          />
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          color="primary"
          rounded
          :disabled="!btnActive"
          @click="$emit('finishProcess', false)"
        >
          {{ $t('processing_activities.step_data_security.finish_process')}}
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>

import InputField from '../../../base/form/InputField.vue';
import CheckboxYesNo from '../../../base/form/CheckboxYesNo.vue';
import TextareaField from '../../../base/form/TextareaField.vue';

export default {
  name: 'data-security',
  components: {
    TextareaField,
    InputField,
    CheckboxYesNo,
  },
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
    securityChecks: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    btnActive() {
      return !!this.activity.process_owner && this.firstCheck !== null && this.secondCheck !== null;
    },
  },
  data() {
    return {
      firstCheck: null,
      secondCheck: null,
    };
  },
  watch: {
    firstCheck() {
      if (!this.firstCheck) {
        this.activity.data_additional_comments = '';
      }
      this.securityChecks.hasAdditionalComments = !!this.firstCheck;
    },
    secondCheck() {
      if (!this.secondCheck) {
        this.activity.description_security_control_measures = '';
      }
      this.securityChecks.hasSecurityControlMeasures = !!this.secondCheck;
    },
  },
};
</script>
