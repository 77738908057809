<template>
  <div>
      <legal-notice-container />
  </div>
</template>

<script>
import LegalNoticeContainer from '../components/containers/LegalNoticeContainer.vue';

export default {
  components: { LegalNoticeContainer },
  name: 'legal-notice',
};
</script>
