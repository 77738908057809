function getTypesDocuments(documents) {
  const existingTypes = [];
  Object.keys(documents).forEach((key) => {
    if (!existingTypes.includes(documents[key].type)) {
      existingTypes.push(documents[key].type);
    }
  });

  const filters = [];
  existingTypes.forEach((value) => {
    filters.push({
      id: value,
    });
  });

  return filters;
}

export default {
  getTypesDocuments,
};
