import InscriptionStatus from '@/domain/training/inscription/InscriptionStatus';
import Course from '@/domain/training/course/Course';
import InscriptionId from '@/domain/training/inscription/InscriptionId';
import InscriptionCompletedDate
  from '@/domain/training/inscription/InscriptionCompletedDate';

export default abstract class InscriptionRepresentation {
  protected _status!: InscriptionStatus;

  protected _course!: Course;

  protected _id!: InscriptionId;

  protected _completedDate: InscriptionCompletedDate | null = null;

  setStatus(status: InscriptionStatus): InscriptionRepresentation {
    this._status = status;
    return this;
  }

  setCourse(course: Course): InscriptionRepresentation {
    this._course = course;
    return this;
  }

  setId(id: InscriptionId): InscriptionRepresentation {
    this._id = id;
    return this;
  }

  setCompletedDate(completedDate: InscriptionCompletedDate | null): InscriptionRepresentation {
    this._completedDate = completedDate;
    return this;
  }
}
