import { postManagement } from '@/shared/restActions';
import ClientId from '@/domain/client/ClientId';
import ImportFileRepository from '@/domain/import/ImportFileRepository';
import ImportFile from '@/domain/import/ImportFile';

export default class ImportFileRepositoryApi implements ImportFileRepository {
  public async save(clientId: ClientId, file: ImportFile): Promise<void> {
    try {
      await postManagement(
        `/api/v2/clients/${clientId.toInt()}/imports/${file.getType()}`,
        file.toObject(),
      );
    } catch (e) {
      console.error(e);
      throw new Error('Error uploading users file');
    }
  }
}
