export default ({
  userRepository,
  authRepository,
  adminRepository,
  refreshContainerService,
}) => () => {
  userRepository.deleteUserInfo();
  adminRepository.resetInfo();
  authRepository.logout();
  refreshContainerService.invoke();
};
