import RecipientStatusValidationException from '@/domain/recipient/recipientStatus/RecipientStatusValidationException';

export default class RecipientStatus {
  _status;

  static VALID_STATUS = [
    'validated',
    'inactive',
  ];

  constructor(status) {
    this.isValid(status);
    this._status = status;
  }

  isValid(status) {
    if (!RecipientStatus.VALID_STATUS.includes(status)) {
      throw new RecipientStatusValidationException('Recipient status does not exist');
    }
  }

  static all() {
    return this.VALID_STATUS.map((status) => new this(status));
  }

  static getDefaultStatus() {
    return 'validated';
  }

  representedAs(recipientStatusRepresentation) {
    recipientStatusRepresentation.setKey(this._status);
    return recipientStatusRepresentation;
  }

  toString() {
    return this._status;
  }
}
