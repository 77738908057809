<template>
  <div>
    <a
      @click="getRecipient"
    >
      {{recipientToEdit.name}}
    </a>
    <dialog-form-recipient
      v-if="openEditRecipientModal"
      :showModal.sync="openEditRecipientModal"
      :title="$t('recipient.edit.title', { recipient: recipientToEdit.name })"
      :recipientInfo="recipientToUpdate"
      :loadingButton="loadingButton"
      @saveRecipient="saveRecipient"
    />
  </div>
</template>

<script>

import DialogFormRecipient from './form/DialogFormRecipient.vue';

export default {
  name: 'edit-recipient',
  inject: {
    fetchRecipient: 'fetchRecipient',
    resetProcessingActivityFormStatus: 'resetProcessingActivityFormStatus',
    resetProcessingActivityForm: 'resetProcessingActivityForm',
    updateRecipient: 'updateRecipient',
  },
  components: {
    DialogFormRecipient,
  },
  props: {
    clientId: {
      type: Number,
      default: null,
    },
    recipientToEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      openEditRecipientModal: false,
      recipientToUpdate: {},
      loadingButton: false,
    };
  },
  methods: {
    async getRecipient() {
      this.$showLoading();

      const response = await this.fetchRecipient(this.recipientToEdit.id);

      this.$hideLoading();
      if (!response.success) {
        this.$showError('recipient.edit.error_load');
        return;
      }
      this.recipientToUpdate = response.recipient;
      this.openEditRecipientModal = true;
    },
    async saveRecipient(recipient) {
      this.$showLoading();
      const response = await this.updateRecipient(
        recipient,
        this.recipientToUpdate.id,
        this.clientId,
      );

      this.$hideLoading();

      if (!response.success) {
        this.$showError('recipient.edit.error');
        this.$eventHub.$emit('saveRecipientFailed');
        return;
      }

      this.$showSuccess('recipient.edit.success');
      this.openEditRecipientModal = false;
      this.$eventHub.$emit('recipientWasUpdated');
    },
  },
  watch: {
    openEditRecipientModal() {
      if (!this.openEditRecipientModal) {
        this.resetProcessingActivityForm.invoke();
        this.resetProcessingActivityFormStatus.invoke();
      }
    },
  },
};
</script>
