import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class RecipientBusinessName extends StringValueObject {
    businessName;

    constructor(businessName) {
      super(businessName);
      this.businessName = businessName;
    }
}
