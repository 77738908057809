import AbstractHeadquarterRepresentation from '@/domain/headquarter/AbstractHeadquarterRepresentation';

export default class HeadquarterRepresentation extends AbstractHeadquarterRepresentation {
  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get address() {
    return this._address.toString();
  }

  get countryCode() {
    return this._country.toString();
  }

  get locality() {
    return this._locality.toString();
  }

  get securityMeasures() {
    return this._securityMeasures.map((securityMeasure) => securityMeasure.toString());
  }

  get clientId() {
    return this._clientId.toInt();
  }
}
