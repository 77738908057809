import DataBreachRepresentation from '@/domain/dataBreach/DataBreachRepresentation';

export default class DataBreachesPendingReadModelRepresentation extends DataBreachRepresentation {
  get id(): string {
    return this._id.toString();
  }

  get createdDate(): string {
    return this._createdDate.toStringDatetime();
  }

  get createdBy(): string {
    return this._createdBy.toString();
  }
}
