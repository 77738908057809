<template>
  <v-switch
    v-model="inputValue"
    :label="label"
    @change="$emit('update:value', inputValue)"
  />
</template>

<script>
export default {
  name: 'switch-field',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
