<template>
  <user-register-container />
</template>

<script>
import UserRegisterContainer from '@/components/containers/UserRegisterContainer.vue';

export default {
  name: 'user-register',
  components: { UserRegisterContainer },
};
</script>
