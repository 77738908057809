const INTERCOM_USERCENTRICS_ID = 'ryDQcVoOoZQ';
const MIXPANEL_USERCENTRICS_ID = 'N5uvpK-j';

function hasAcceptedIntercomConsent() {
  return window.usercentrics.getConsents(INTERCOM_USERCENTRICS_ID).consentStatus;
}

function hasAcceptedMixpanelConsent() {
  return window.usercentrics.getConsents(MIXPANEL_USERCENTRICS_ID).consentStatus;
}

export default {
  hasAcceptedIntercomConsent,
  hasAcceptedMixpanelConsent,
};

export const HasMixpanelConsent = hasAcceptedMixpanelConsent;
