export default class TaskResponse {
  taskResponseId;

  taskId;

  clientUserId;

  completionDate;

  taskResponseReply;

  constructor(
    taskResponseId,
    taskId,
    clientUserId,
    completionDate,
    taskResponseReply,
  ) {
    this.taskResponseId = taskResponseId;
    this.taskId = taskId;
    this.clientUserId = clientUserId;
    this.completionDate = completionDate;
    this.taskResponseReply = taskResponseReply;
  }
}
