<template>
  <activate-client-user-container :token="token" />
</template>
<script>
import ActivateClientUserContainer from '@/components/containers/ActivateClientUserContainer.vue';

export default {
  name: 'activate-client-user',
  components: {
    ActivateClientUserContainer,
  },
  props: [
    'language',
    'token',
  ],
  methods: {
    getBrowserLanguage() {
      const lang = navigator.language || navigator.userLanguage;
      return lang.split('-')[0];
    },
  },
  mounted() {
    this.$i18n.locale = this.language || this.getBrowserLanguage();
  },
};
</script>
