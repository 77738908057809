import EquipmentId from '../../../domain/equipment/EquipmentId';

export default (
  EquipmentRepository,
) => async (equipmentId) => {
  try {
    await EquipmentRepository.delete(EquipmentId.fromString(equipmentId));
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
