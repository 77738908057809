import ListValidationException from '@/domain/tom/clasification/ListValidationException';

const ISO_27701 = 'iso27701';
const BASIC_TOMS = 'basic';
const ENISA = 'enisa';
const ISO_27001 = 'iso27001';
const HIPAA = 'hipaa';

const VALID_LIST = [
  BASIC_TOMS,
  ENISA,
  HIPAA,
  ISO_27001,
  ISO_27701,
];

export default class TomList {
  private readonly list: string;

  protected constructor(list: string) {
    TomList.isValidOrFail(list);
    this.list = list;
  }

  private static isValidOrFail(list: string) {
    if (!VALID_LIST.includes(list)) {
      throw new ListValidationException(list);
    }
  }

  public toString(): string {
    return this.list;
  }

  public static fromString(list: string): TomList {
    return new this(list);
  }

  public static all(): TomList[] {
    return VALID_LIST.map((list: string) => new this(list));
  }
}
