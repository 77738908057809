<template>
  <div>
    <privacy-policy-container />
  </div>
</template>

<script>
import PrivacyPolicyContainer from '@/components/containers/PrivacyPolicyContainer.vue';

export default {
  components: { PrivacyPolicyContainer },
  name: 'privacy-policy',
};
</script>
