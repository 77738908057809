import AbstractRecipientRepresentation from '@/domain/recipient/AbstractRecipientRepresentation';

export default class RecipientReadModelRepresentation extends AbstractRecipientRepresentation {
  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get status() {
    return this._status ? this._status.toString() : '';
  }

  get category() {
    return this._category ? this._category.toString() : '';
  }

  get role() {
    return this._role ? this._role.toString() : '';
  }

  get lastModification() {
    return (this._lastModification) ? this._lastModification : '';
  }

  get documents() {
    return this._documents ? this._documents : 0;
  }

  get replacedBy() {
    return (this._replacedBy) ? this._replacedBy : null;
  }

  get isReplacement() {
    return this._isReplacement;
  }
}
