import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreach from '@/domain/dataBreach/DataBreach';
import DataBreachFetchRepresentation
  from '@/application/dataBreach/fetchDataBreach/DataBreachFetchRepresentation';

export default class FetchDataBreach {
  private dataBreachRepository: DataBreachRepository;

  constructor(dataBreachRepository: DataBreachRepository) {
    this.dataBreachRepository = dataBreachRepository;
  }

  async invoke(dataBreachId: string): Promise<object> {
    try {
      const dataBreach: DataBreach = await this.dataBreachRepository
        .retrieve(DataBreachId.fromString(dataBreachId));
      return {
        success: true,
        dataBreach: dataBreach.representedAs(new DataBreachFetchRepresentation()),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
