import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import RegisterClientUserCommand
  from '@/application/clientUser/registerClientUser/RegisterClientUserCommand';
import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserPassword from '@/domain/clientUser/ClientUserPassword';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import Language from '@/domain/language/Language';
import ClientUser from '@/domain/clientUser/ClientUser';

export default class RegisterClientUserCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public async invoke(command: RegisterClientUserCommand): Promise<any> {
    try {
      const clientUser: ClientUser = this.buildClientUser(command);

      await this.clientUserRepository.register(clientUser);

      return { success: true };
    } catch (e) {
      return { success: false, error: e?.message || null };
    }
  }

  private buildClientUser(command: RegisterClientUserCommand): ClientUser {
    const user: ClientUser = new ClientUser(
      ClientUserEmail.fromString(command.email),
      ClientUserName.fromString(command.name),
      ClientUserSurname.fromString(command.surname),
      ClientUserType.byDefault(),
      [],
      Language.fromString(command.language),
    );

    user.addPassword(ClientUserPassword.fromString(command.password));

    return user;
  }
}
