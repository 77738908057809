import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import AddDataBreachCollaboratorCommand
  from '@/application/dataBreach/addDataBreachCollaborator/AddDataBreachCollaboratorCommand';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import DataBreachCollaborator from '@/domain/dataBreach/collaborator/DataBreachCollaborator';
import CollaboratorName from '@/domain/dataBreach/collaborator/CollaboratorName';
import CollaboratorSurname from '@/domain/dataBreach/collaborator/CollaboratorSurname';
import EventBus from '@/application/eventBus/EventBus';
import DataBreachCollaboratorWasAdded
  from '@/domain/dataBreach/collaborator/DataBreachCollaboratorWasAdded';
import CollaboratorMessage from '@/domain/dataBreach/collaborator/CollaboratorMessage';

export default class AddDataBreachCollaboratorCommandHandler {
  private readonly dataBreachRepository: DataBreachRepository;

  private readonly eventBus: EventBus;

  constructor(dataBreachRepository: DataBreachRepository, eventBus: EventBus) {
    this.dataBreachRepository = dataBreachRepository;
    this.eventBus = eventBus;
  }

  public async invoke(command: AddDataBreachCollaboratorCommand):
    Promise<{ success: boolean, error?: string}> {
    try {
      const collaboratorEmail = EmailValueObject.fromString(command.email);
      const collaborator = this.buildCollaborator(collaboratorEmail, command);

      await this.dataBreachRepository.addCollaborator(
        DataBreachId.fromString(command.dataBreachId),
        collaborator,
      );

      this.eventBus.handle(DataBreachCollaboratorWasAdded.fromCollaboratorEmail(collaboratorEmail));
      return { success: true };
    } catch (e) {
      return { success: false, error: e.message };
    }
  }

  private buildCollaborator(
    collaboratorEmail: EmailValueObject,
    command: AddDataBreachCollaboratorCommand,
  ) {
    const collaborator: DataBreachCollaborator = new DataBreachCollaborator(
      collaboratorEmail,
      CollaboratorName.fromString(command.name),
      CollaboratorSurname.fromString(command.surname),
    );

    if (command.message.trim() !== '') {
      collaborator.addMessage(CollaboratorMessage.fromString(command.message));
    }
    return collaborator;
  }
}
