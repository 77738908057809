import DataBreachesPendingRepresentation
  from '@/application/dataBreach/pending/fetchDataBreachesPending/DataBreachesPendingRepresentation';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import store from '../../../store';

export default class DataBreachPendingCache {
  public static reset(): void {
    store.dispatch('dataBreach/resetDataBreachesPending');
  }

  public static saveAll(dataBreachesPending: DataBreachesPendingRepresentation[]): void {
    const dataBreachesPendingList: { [key: string]: DataBreachesPendingRepresentation } = {};

    dataBreachesPending.forEach((dataBreach: DataBreachesPendingRepresentation) => {
      dataBreachesPendingList[dataBreach.id] = dataBreach;
    });

    store.dispatch('dataBreach/addDataBreachesPending', dataBreachesPendingList);
  }

  public static all(): DataBreachesPendingRepresentation[] {
    return Object.values(store.getters['dataBreach/dataBreachesPending']);
  }

  public static save(dataBreachPending: DataBreachesPendingRepresentation): void {
    const dataBreachesPendingList: { [key: string]: DataBreachesPendingRepresentation } = store.getters['dataBreach/dataBreachesPending'];
    dataBreachesPendingList[dataBreachPending.id] = dataBreachPending;
    store.dispatch('dataBreach/addDataBreachesPending', dataBreachesPendingList);
  }

  public static deleteDataBreach(dataBreachId: DataBreachId): void {
    const dataBreachesPendingList: { [key: string]: DataBreachesPendingRepresentation } = store.getters['dataBreach/dataBreachesPending'];
    delete dataBreachesPendingList[(dataBreachId.toString())];
    store.dispatch('dataBreach/addDataBreachesPending', dataBreachesPendingList);
  }
}
