import DataBreachRepresentation from '@/domain/dataBreach/DataBreachRepresentation';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';

export default class DataBreachesHistoricReadModelRepresentation extends DataBreachRepresentation {
  get id(): string {
    return this._id.toString();
  }

  get code(): string {
    return (this._code) ? this._code.toString() : '';
  }

  get agencyNotification(): boolean {
    return this._agencyNotification;
  }

  get occurredOn(): string {
    return this._occurredOn.toStringDate();
  }

  get documents(): object[] {
    return this._attachedDocuments.map(
      (document: DataBreachAttachedDocuments) => document.toObject(),
    );
  }

  get createdBy(): string {
    return this._createdBy.toString();
  }
}
