import AbstractEquipmentRepresentation from '@/domain/equipment/AbstractEquipmentRepresentation';

export default class EquipmentToUpdateRepresentation extends AbstractEquipmentRepresentation {
  get name() {
    return this._name;
  }

  get type() {
    return this._type;
  }

  get location() {
    return this._location;
  }

  get securityMeasures() {
    return this._securityMeasures;
  }
}
