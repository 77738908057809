const saveHistoricRoutes = ({ commit }, historicRoutes) => {
  commit('SAVE_HISTORIC_ROUTES', historicRoutes);
};

const resetHistoricRoutes = ({ commit }) => {
  commit('RESET_HISTORIC_ROUTES');
};

export default {
  saveHistoricRoutes,
  resetHistoricRoutes,
};
