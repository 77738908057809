import EmployeeWasDeletedEventListener
  from '@/application/training/employee/EmployeeWasDeletedEventListener/EmployeeWasDeletedEventListener';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';

export default class EmployeeWasDeletedEventListenerBuilder {
  static build() {
    return new EmployeeWasDeletedEventListener(
      new TrainingEmployeeRepositoryApi(),
    );
  }
}
