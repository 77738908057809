<template>
  <delete-button-alert
    actionTracker="Delete Equipment --start"
    @delete="deleteEquipment"
  >
    <template v-slot:alert-title>
      {{ $t('equipment.delete.title') }}
    </template>
    <template v-slot:text-alert>
      {{ $t('equipment.delete.alert') }}
    </template>
    <template v-slot:body-text>
      {{ $t('equipment.delete.deletion') }}
      <strong>{{ equipmentToDelete.name }}</strong><br/>
      {{ $t('equipment.delete.sure') }}
    </template>
  </delete-button-alert>
</template>
<script>
import DeleteButtonAlert from '@/components/base/tables/DeleteButtonAlert.vue';

export default {
  name: 'remove-equipment',
  inject: {
    removeEquipment: 'removeEquipment',
  },
  components: {
    DeleteButtonAlert,
  },
  props: {
    equipment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      equipmentToDelete: this.equipment,
    };
  },
  methods: {
    async deleteEquipment() {
      this.$showLoading();
      const response = await this.removeEquipment(this.equipmentToDelete.id);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('equipment.delete.error');
        this.$track({ action: 'Delete Inventory --error' });
        return;
      }

      this.$showSuccess('equipment.delete.success');
      this.$track({ action: 'Delete Inventory --success' });
      this.$eventHub.$emit('equipmentWasDeleted');
    },
  },
};
</script>
