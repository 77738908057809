<template>
  <div class="h-100 w-100">
    <title-section
      :title="$t('errorPage.notFound.title')"
    />
      <v-container fluid class="error-page">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" class="text-center">
            <div class="error-page__number">
              <p>404</p>
            </div>
            <p>{{ $t('errorPage.notFound.message') }}</p>
            <p>{{ $t('errorPage.notFound.apologize') }}</p>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>
import TitleSection from '@/components/base/section/TitleSection.vue';

export default {
  name: 'page-not-found',
  components: {
    TitleSection,
  },
};
</script>
