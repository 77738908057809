/* eslint-disable no-param-reassign */
import { HasMixpanelConsent } from '@/services/permissions/consentCookies';
import { Store } from 'vuex';
import { DirectiveBinding } from 'vue/types/options.d';
import CountryName from './misc/CountryName';
import BrowserInfo from './misc/BrowserInfo';
import TrackerRepositoryApi from './TrackerRepositoryApi';
import EventTracker from './EventTracker';

export default class Tracker {
  private vue: any;

  private store: Store<any>;

  private browserInfo: BrowserInfo;

  private api: TrackerRepositoryApi;

  constructor(
    store: Store<any>,
    api: TrackerRepositoryApi,
    browserInfo: BrowserInfo,
  ) {
    this.store = store;
    this.browserInfo = browserInfo;
    this.api = api;
  }

  public install(Vue: any) {
    this.vue = Vue;
    this.addPrototypes();
    this.addDirectives();
  }

  private addPrototypes(): void {
    this.vue.prototype.$track = async (event: EventTracker): Promise<void> => {
      await this.track(event);
    };
  }

  private addDirectives(): void {
    this.vue.directive('track', {
      bind: (el: HTMLElement, binding: DirectiveBinding) => {
        el.addEventListener('click', () => {
          this.track({ action: binding.value, result: true });
        });
      },
      unbind: (el: HTMLElement) => {
        el.removeEventListener('click', () => {});
      },
    });
  }

  public async track(event: EventTracker): Promise<void> {
    if (this.isUserEarlyAdopterAndDevelopers()) {
      return;
    }

    if (typeof event.result === 'boolean') {
      event.result = event.result ? 'success' : 'fail';
    }

    await this.api.save({
      ...this.getData(),
      ...event,
    });
  }

  private isUserEarlyAdopterAndDevelopers(): boolean {
    const adminIdCollection: string[] = [
      ...process.env.VUE_APP_DEVELOPERS_ADMINS!.split(','),
      ...process.env.VUE_APP_FT_EARLY_ADOPTERS!.split(','),
    ];

    const adminCompanyId: number | null = this.store.getters['admin/adminCompanyId'];
    if (!adminCompanyId) {
      return false;
    }
    return adminIdCollection.includes(adminCompanyId.toString());
  }

  private getData(): object {
    return HasMixpanelConsent() ? this.getConsentData() : this.getAnonymousData();
  }

  private getConsentData(): object {
    const userId: number | null | undefined = this.store.getters['user/userId'];
    if (userId === 0 || !userId) {
      return {
        user_id: this.store.getters['admin/subdomain'],
        user_role: null,
        account_manager: this.store.getters['admin/name'],
      };
    }

    return {
      user_id: userId,
      user_role: this.getRole(),
      company_id: this.store.getters['user/companyId'],
      company_name: this.store.getters['user/companyName'],
      country: CountryName.retrieve(this.store.getters['user/countryId']),
      language: this.store.getters['user/language'],
      account_manager: this.store.getters['admin/name'],
      client_type: this.store.getters['user/isB2b'] ? 'B2B' : 'B2B2B',
      ...this.browserInfo.toObject(),
    };
  }

  private getRole(): string {
    if (this.store.getters['user/isAdmin']) {
      return 'admin';
    }
    return this.store.getters['user/roleType'];
  }

  private getAnonymousData(): object {
    const userId: number | null | undefined = this.store.getters['user/userId'];
    if (userId === 0 || !userId) {
      return {
        user_id: 'anonymous',
        user_role: null,
      };
    }

    return {
      user_id: 'anonymous',
      user_role: this.getRole(),
      company_name: this.store.getters['user/companyName'],
      country: CountryName.retrieve(this.store.getters['user/countryId']),
      language: this.store.getters['user/language'],
      client_type: this.store.getters['user/isB2b'] ? 'B2B' : 'B2B2B',
      ...this.browserInfo.toObject(),
    };
  }
}
