import UserPasswordValidationException from '@/domain/user/UserPasswordValidationException';

const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=(.*\d)|(.*[!"#$%^'()*+´\-./:;<=>?@[\\\]^_`{|}])).{8,}$/i;

export default class UserPassword {
  private readonly password: string;

  protected constructor(password: string) {
    UserPassword.isValidOrFail(password);
    this.password = password;
  }

  private static isValidOrFail(password: string): void {
    if (!PASSWORD_VALIDATION.test(password)) {
      throw new UserPasswordValidationException(`${this.constructor.name} is not valid`);
    }
  }

  public static fromString(password: string): UserPassword {
    return new this(password);
  }

  public toString(): string {
    return this.password;
  }
}
