import ClientUserIdValidationException from '@/domain/task/exceptions/ClientUserIdValidationException';

export default class ClientUserId {
  id;

  constructor(id) {
    ClientUserId.isValid(id);
    this.id = id;
  }

  static isValid(id) {
    if (!id) {
      throw new ClientUserIdValidationException('Client user id should not be empty');
    }
  }
}
