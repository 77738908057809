import UserRepository from '@/domain/user/UserRepository';
import UserId from '@/domain/user/UserId';

export default class FetchUserInfo {
  private readonly userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  public async invoke(userId: number) {
    try {
      await this.userRepository.retrieve(UserId.fromInt(userId));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
