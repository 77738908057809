export default ({
  userRepository,
}) => async (userRoleDepts) => {
  try {
    await userRepository.setStoreUserRoleAndDepartments(userRoleDepts);
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
