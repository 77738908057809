import ClientRepresentation from '@/domain/client/ClientRepresentation';

export default class ClientCacheRepresentation extends ClientRepresentation {
  public toObject(): {
    id: number,
    name: string,
    countryId: number,
    } {
    return {
      id: this._id.toInt(),
      name: this._name.toString(),
      countryId: this._countryId.toInt(),
    };
  }
}
