import DepartmentProjection from '@/domain/department/listDepartment/DepartmentProjection';
import store from '@/store';

interface ListDepartmentCacheStruct {
  [key: string]: DepartmentProjection
}

export default class ListDepartmentCache {
  public static all(): DepartmentProjection[] {
    return Object.values(store.getters['department/listDepartment']);
  }

  public static saveAll(listDepartment: DepartmentProjection[]): void {
    const listDepartmentCollection: ListDepartmentCacheStruct = {};
    listDepartment.forEach((department: DepartmentProjection) => {
      listDepartmentCollection[department.id] = department;
    });

    store.dispatch('department/addListDepartment', listDepartmentCollection);
  }

  public static save(department: DepartmentProjection): void {
    const listDepartmentCollection: ListDepartmentCacheStruct = store.getters['department/listDepartment'];
    listDepartmentCollection[department.id] = department;
    store.dispatch('department/addListDepartment', listDepartmentCollection);
  }

  public static reset(): void {
    store.dispatch('department/resetListDepartment');
  }

  public static retrieve(departmentId: string): DepartmentProjection {
    const listDepartmentCollection: ListDepartmentCacheStruct = store.getters['department/listDepartment'];
    return listDepartmentCollection[departmentId];
  }
}
