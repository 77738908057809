import UrlNotValidException from '@/domain/shared/url/UrlNotValidException';

export default class Url {
  private readonly url: string;

  private constructor(url: string) {
    Url.isValidOrFail(url);
    this.url = url;
  }

  public static fromString(url: string): Url {
    return new this(url);
  }

  private static isValidOrFail(url: string) {
    const pattern = 'http(s)?:\\/\\/(www\\.)?[-a-zA-Z0-9:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9():%_\\+.~#?&\\/\\/=]*)';
    const regExp = new RegExp(pattern);
    if (!regExp.test(url)) {
      throw new UrlNotValidException('Url with invalid format');
    }
  }

  public toString() {
    return this.url;
  }
}
