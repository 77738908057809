import fetchDocumentsListBuilder from '@/usecases/documents/treatmentManagers/fetchDocumentsList';
import downloadDocumentBuilder from '@/usecases/documents/treatmentManagers/downloadDocument';
import viewDocumentBuilder from '@/usecases/documents/treatmentManagers/viewDocument';
import hasTreatmentManagersBuilder from '@/usecases/documents/treatmentManagers/hasTreatmentManagers';
import treatmentManagersRepository from '@/repositories/documents/treatmentManagers';
import documentViewer from '@/services/documents/documentViewer';
import downloadFile from '@/services/documents/downloadFile';

export default {
  fetchDocumentsList: fetchDocumentsListBuilder({ treatmentManagersRepository }),
  downloadDocument: downloadDocumentBuilder({ treatmentManagersRepository, downloadFile }),
  viewDocument: viewDocumentBuilder({ treatmentManagersRepository, documentViewer }),
  hasTreatmentManagers: hasTreatmentManagersBuilder({ treatmentManagersRepository }),
};
