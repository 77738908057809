export default abstract class ClientUserTypeRepresentation {
  protected _type!: string;

  protected _isRestrictedEmployee!: boolean;

  public setType(type: string) {
    this._type = type;
  }

  public setIsRestrictedEmployeeType(isRestrictedEmployee: boolean) {
    this._isRestrictedEmployee = isRestrictedEmployee;
  }
}
