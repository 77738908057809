import RecipientId from '@/domain/recipient/RecipientId';
import RecipientRepresentation
  from '@/application/recipient/fetchRecipient/RecipientRepresentation';

export default (
  recipientRepository,
  translator,
) => async (recipientId) => {
  try {
    const recipient = await recipientRepository.retrieve(
      RecipientId.fromString(recipientId),
    );

    const recipientRepresentation = recipient.representedAs(
      new RecipientRepresentation(translator),
    );

    return { success: true, recipient: recipientRepresentation };
  } catch (e) {
    return { success: false };
  }
};
