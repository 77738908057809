import DataBreach from '@/domain/dataBreach/DataBreach';

export default class DataBreachWasCreated {
  private readonly dataBreach: DataBreach;

  constructor(dataBreach: DataBreach) {
    this.dataBreach = dataBreach;
  }

  public getDataBreach(): DataBreach {
    return this.dataBreach;
  }
}
