import TomRepository from '@/domain/tom/TomRepository';

export default class ResetToms {
  private readonly tomRepository: TomRepository;

  constructor(tomRepository: TomRepository) {
    this.tomRepository = tomRepository;
  }

  async invoke(): Promise<void> {
    await this.tomRepository.reset();
  }
}
