import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import EditClientUser from '@/application/clientUser/editClientUser/EditClientUser';
import ChangeClientUserRoleCommand
  from '@/application/clientUser/editClientUser/ChangeClientUserRoleCommand';
import ClientUser from '@/domain/clientUser/ClientUser';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserToUpdate from '@/domain/clientUser/ClientUserToUpdate';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ChangeClientUserRoleCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  private readonly editClientUser: EditClientUser;

  constructor(
    clientUserRepository: ClientUserRepository,
    editClientUser: EditClientUser,
  ) {
    this.clientUserRepository = clientUserRepository;
    this.editClientUser = editClientUser;
  }

  public async invoke(command: ChangeClientUserRoleCommand) {
    try {
      const clientUser: ClientUser = await this.clientUserRepository.retrieve(
        ClientUserId.fromInt(command.id),
      );

      const clientUserToUpdate: ClientUserToUpdate = this.buildClientUserToUpdate(command);

      return this.editClientUser.invoke(clientUser, clientUserToUpdate);
    } catch (e) {
      return { success: false };
    }
  }

  private buildClientUserToUpdate(command: ChangeClientUserRoleCommand): ClientUserToUpdate {
    const clientUserToUpdate = new ClientUserToUpdate();
    const clientUserType = ClientUserType.fromString(command.type);
    clientUserToUpdate.setType(clientUserType)
      .setDepartments(clientUserType.isEmployee()
        ? this.buildDepartments(command.departments)
        : []);

    return clientUserToUpdate;
  }

  private buildDepartments(departments: string[]): DepartmentId[] {
    return departments.map((dept: string) => DepartmentId.fromString(dept));
  }
}
