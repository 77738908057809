const addEmployees = ({ commit }, employees) => {
  commit('ADD_EMPLOYEES', employees);
};

const resetEmployees = ({ commit }) => {
  commit('RESET_EMPLOYEES');
};

export default {
  addEmployees,
  resetEmployees,
};
