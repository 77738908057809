import TrainingStatusValidationException from '@/domain/training/TrainingStatusValidationException';

const REQUESTED = 'requested';
const ACTIVE = 'active';
const INACTIVE = 'inactive';

const VALID_STATUSES = [
  REQUESTED,
  ACTIVE,
  INACTIVE,
];

export default class TrainingStatus {
  status;

  constructor(trainingStatus: string) {
    this.isValid(trainingStatus);
    this.status = trainingStatus;
  }

  isValid(trainingStatus: string) {
    if (!trainingStatus) {
      throw new TrainingStatusValidationException('Training status should not be empty');
    }

    if (!VALID_STATUSES.includes(trainingStatus)) {
      throw new TrainingStatusValidationException('Training status does not exist');
    }
  }

  public static fromString(status: string): TrainingStatus {
    return new this(status);
  }

  public static defaultStatus(): TrainingStatus {
    return new this(INACTIVE);
  }

  public toString(): string {
    return this.status;
  }
}
