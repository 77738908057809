import { getManagement } from '@/shared/restActions';
import feature from '@/shared/featureToggle';

function isACustomLayoutMigrated(adminLayoutId) {
  return feature.isVisible('customLayoutsMigrated', '', { adminLayoutId });
}

function getUrlUserIsAdmin(companyId, isAdmin) {
  if (isAdmin) {
    return `?company=${companyId}`;
  }
  return '';
}

function getListDocuments(companyId, locale, isAdmin) {
  let url = `/api/v2/documents-legacy/legal-documents/${locale}/list/${companyId}`;

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function downloadDocument(companyId, documentId, type, adminLayoutId) {
  if (!isACustomLayoutMigrated(adminLayoutId)) {
    return getManagement(`/api/v2/clients/${companyId}/documents/${documentId}/${type}`, {})
      .then((response) => ({ url: response.preview_url }));
  }
  return getManagement(`/api/v2/documents/custom-document/${documentId}/download/${type}`, {}).then((response) => response.data);
}

function getViewUrl(documentId, typeDocument, lang) {
  return `/api/v2/documents-legacy/preview/layouts/${lang}/${documentId}`;
}

function viewDocument(documentId, typeDocument, lang, companyId, isAdmin, adminLayoutId) {
  if (!isACustomLayoutMigrated(adminLayoutId)) {
    const url = getViewUrl(documentId, typeDocument, lang) + getUrlUserIsAdmin(companyId, isAdmin);

    return getManagement(url, {}).then((response) => ({ url: response.preview_url }));
  }

  return getManagement(`/api/v2/documents/custom-document/${documentId}/preview/${lang}`, {}).then((response) => response.data);
}

export default {
  getListDocuments,
  downloadDocument,
  viewDocument,
};
