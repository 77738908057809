import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUsersRepresentation
  from '@/application/clientUser/fetchClientUsers/ClientUsersRepresentation';
import store from '../../store';

export default class ClientUserListCache {
  public static saveAll(clientUsers: ClientUsersRepresentation[]): void {
    const clientUsersCollection: { [key: string]: ClientUsersRepresentation } = {};
    clientUsers.forEach((clientUser: ClientUsersRepresentation) => {
      clientUsersCollection[clientUser.id] = clientUser;
    });

    store.dispatch('clientUsers/addClientUsersList', clientUsersCollection);
  }

  public static all(): ClientUsersRepresentation[] {
    return Object.values(store.getters['clientUsers/clientUsersList']);
  }

  public static delete(clientUserId: ClientUserId): void {
    const clientUserCollection: { [key: string]: ClientUsersRepresentation } = store.getters['clientUsers/clientUsersList'];
    delete clientUserCollection[clientUserId.toInt().toString()];
    store.dispatch('clientUsers/addClientUsersList', clientUserCollection);
  }

  public static save(clientUser: ClientUsersRepresentation): void {
    const clientUserCollection: { [key: string]: ClientUsersRepresentation } = store.getters['clientUsers/clientUsersList'];
    clientUserCollection[clientUser.id] = clientUser;
    store.dispatch('clientUsers/addClientUsersList', clientUserCollection);
  }

  public static reset(): void {
    store.dispatch('clientUsers/resetClientUsersList');
  }
}
