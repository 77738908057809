<template>
  <div class="h-100 w-100">
    <v-textarea
      v-model="inputValue"
      :label="label"
      :rules="rules"
      :counter="counter"
      :rows="rows"
      :counter-value="counterValue"
      :disabled="disabled"
      :readonly="readonly"
      filled
      auto-grow
      @input="$emit('update:value', inputValue)"
    />
  </div>
</template>
<script>
export default {
  name: 'textarea-field',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    counter: {
      type: [Number, Boolean],
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    counterValue: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
