const addClients = ({ commit }, clients) => {
  commit('ADD_CLIENTS', clients);
};

const resetClients = ({ commit }) => {
  commit('RESET_CLIENTS');
};

export default {
  addClients,
  resetClients,
};
