import Language from '@/domain/language/Language';
import TomNoteContent from '@/domain/tom/TomNoteContent';

export default class TomNote {
  private readonly content: TomNoteContent;

  private readonly language: Language;

  constructor(content: TomNoteContent, language: Language) {
    this.content = content;
    this.language = language;
  }

  toObject(): { content: string, language: string } {
    return {
      content: this.content.toString(),
      language: this.language.toString(),
    };
  }
}
