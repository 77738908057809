<template>
  <div class="h-100 w-100">
    <v-card-text>
      <v-row
        align="center"
      >
        <v-col
          cols="12"
          md="9"
        >
          <h2 class="font-weight-bold mb-2">
            {{ $t("training.access.title") }}
          </h2>
          <p
            v-html="$t('training.access.title_info')"
            class="mb-0"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-btn
            color="primary"
            rounded
            block
            class="mb-0"
            v-track="'Access Trainings'"
            @click="goToTraining"
          >
            <v-icon small>
              open_in_new
            </v-icon>
            {{ $t('training.access.button') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <dialog-select-languages
      v-if="showDialogSelectLanguage"
      :showModal.sync="showDialogSelectLanguage"
      :languages="availableLanguages"
      @save="languageSelected"
    />
  </div>
</template>

<script>

import DialogSelectLanguages from '@/components/modules/training/userTrainings/DialogSelectLanguages.vue';

export default {
  name: 'go-to-training',
  inject: {
    user: 'user',
    createTrainingUser: 'createTrainingUser',
    goTrainingUrl: 'goTrainingUrl',
  },
  components: {
    DialogSelectLanguages,
  },
  props: {
    userLanguage: {
      type: String,
      default: null,
    },
    availableLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialogSelectLanguage: false,
    };
  },
  methods: {
    goToTraining() {
      if (this.userLanguage) {
        this.redirect();
        return;
      }
      if (this.availableLanguages.length > 1) {
        this.openModal();
        return;
      }

      this.createUserTraining(this.availableLanguages.shift());
      this.redirect();
    },
    openModal() {
      this.showDialogSelectLanguage = true;
    },
    async createUserTraining(language) {
      this.$showLoading();
      const response = await this.createTrainingUser.invoke(language, this.user.clientId);
      this.$track({
        action: 'created Training User',
        result: response.success,
      });
      this.$hideLoading();

      if (!response.success) {
        this.$showError('training.error.access_error');
      }
    },
    async redirect() {
      this.$showLoading();
      const response = await this.goTrainingUrl.invoke(this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('training.error.access_error');
      }
    },
    async languageSelected(language) {
      this.showDialogSelectLanguage = false;
      await this.createUserTraining(language);
      await this.redirect();
    },
  },
};
</script>
