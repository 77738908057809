<template>
  <delete-button-alert
    actionTracker="Delete Employee --start"
    @delete="deleteEmployee"
  >
    <template v-slot:alert-title>
      {{ $t('employee.delete.title') }}
    </template>
    <template v-slot:text-alert>
      {{ $t('employee.delete.alert') }}
    </template>
    <template v-slot:body-text>
      {{ $t('employee.delete.delete_employee') }}
      <strong>{{ employeeToDelete.name }}</strong><br />
      {{ $t('employee.delete.sure') }}
    </template>
  </delete-button-alert>
</template>

<script>
import DeleteButtonAlert from '@/components/base/tables/DeleteButtonAlert.vue';

export default {
  name: 'remove-employee',
  inject: {
    removeEmployee: 'removeEmployee',
  },
  components: {
    DeleteButtonAlert,
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      employeeToDelete: this.employee,
    };
  },
  methods: {
    async deleteEmployee() {
      this.$showLoading();
      const response = await this.removeEmployee.invoke(this.employeeToDelete.id);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('employee.delete.error');
        this.$track({ action: 'Delete Employee --error' });
        return;
      }

      this.$showSuccess('employee.delete.success');
      this.$track({ action: 'Delete Employee --success' });
      this.$eventHub.$emit('employeeWasDeleted');
    },
  },
};
</script>
