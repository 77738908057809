import ClientRepository from '@/domain/client/ClientRepository';
import FetchClientsQuery from '@/application/client/fetchClients/FetchClientsQuery';
import ClientsRepresentation from '@/application/client/fetchClients/ClientsRepresentation';
import Client from '@/domain/client/Client';

export default class FetchClientsQueryHandler {
  private readonly clientRepository: ClientRepository;

  private readonly normalizationService: any;

  constructor(clientRepository: ClientRepository, normalizationService: any) {
    this.clientRepository = clientRepository;
    this.normalizationService = normalizationService;
  }

  public async invoke(query: FetchClientsQuery):
    Promise<{ success: boolean, clients?: ClientsRepresentation[] }> {
    try {
      const clients: Client[] = await this.clientRepository.all();
      const clientsRepresentation: ClientsRepresentation[] = clients
        .map((client: Client) => client
          .representedAs(new ClientsRepresentation(this.normalizationService)) as
          ClientsRepresentation);

      const clientsSorted: ClientsRepresentation[] = clientsRepresentation.sort(
        (client1: ClientsRepresentation, client2: ClientsRepresentation) => (
          (client1.nameNormalized < client2.nameNormalized) ? -1 : 1),
      );

      return { success: true, clients: clientsSorted };
    } catch (e) {
      return { success: false };
    }
  }
}
