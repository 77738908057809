import LanguageRepository from '@/domain/language/LanguageRepository';
import ClientId from '@/domain/client/ClientId';
import Language from '@/domain/language/Language';
import { getManagement } from '@/shared/restActions';
import LanguageRaw from '@/infrastructure/language/LanguageRaw';

export default class LanguageRepositoryApi implements LanguageRepository {
  public async ofClient(clientId: ClientId): Promise<Language[]> {
    try {
      const languages = await LanguageRepositoryApi.getLanguagesFromApi(clientId);
      return languages.map((language) => Language.fromString(language.code));
    } catch (e) {
      throw Error('Error fetching languages of client');
    }
  }

  private static async getLanguagesFromApi(clientId: ClientId): Promise<LanguageRaw[]> {
    return getManagement(`/api/v2/clients/${clientId.toInt()}/languages`);
  }
}
