import { initialState } from './state';

const ADD_USER_TOKEN = (state, userToken) => {
  state.token = userToken;
};

const ADD_USER_EMAIL = (state, userEmail) => {
  state.email = userEmail;
};

const ADD_USER_COMPANY_ID = (state, userCompanyId) => {
  state.companyId = userCompanyId;
};

const ADD_USER_COMPANY_NAME = (state, userCompanyName) => {
  state.companyName = userCompanyName;
};

const ADD_USER_ID = (state, userId) => {
  state.id = userId;
};

const ADD_USER_IS_ADMIN = (state, userIsAdmin) => {
  state.isAdmin = userIsAdmin;
};

const ADD_USER_LANGUAGE = (state, userLanguage) => {
  state.language = userLanguage;
};

const ADD_USER_NAME = (state, userName) => {
  state.name = userName;
};

const ADD_COUNTRY_ID = (state, countryId) => {
  state.countryId = countryId;
};

const ADD_IS_B2B = (state, isB2b) => {
  state.isB2b = isB2b;
};

const SET_USER_TYPE = (state, type) => {
  state.type = type;
};

const SET_USER_DEPARTMENTS = (state, departments) => {
  state.departments = departments;
};

const RESET_USER_TOKEN = (state) => {
  state.token = initialState.token;
};

const RESET_USER_EMAIL = (state) => {
  state.email = initialState.email;
};

const RESET_USER_COMPANY_ID = (state) => {
  state.companyId = initialState.companyId;
};

const RESET_USER_COMPANY_NAME = (state) => {
  state.companyName = initialState.companyName;
};

const RESET_USER_ID = (state) => {
  state.id = initialState.id;
};

const RESET_USER_IS_ADMIN = (state) => {
  state.isAdmin = initialState.isAdmin;
};

const RESET_USER_LANGUAGE = (state) => {
  state.language = initialState.language;
};

const RESET_USER_NAME = (state) => {
  state.name = initialState.name;
};

const RESET_COUNTRY_ID = (state) => {
  state.countryId = null;
};

const RESET_IS_B2B = (state) => {
  state.isB2b = false;
};

const RESET_USER_TYPE = (state) => {
  state.type = initialState.type;
};

const RESET_USER_DEPARTMENTS = (state) => {
  state.departments = initialState.departments;
};

const CHANGE_REDUCED_MENU = (state, isReducedMenu) => {
  state.reducedMenu = isReducedMenu;
};

export default {
  ADD_USER_TOKEN,
  ADD_USER_EMAIL,
  ADD_USER_COMPANY_ID,
  ADD_USER_COMPANY_NAME,
  ADD_USER_ID,
  ADD_USER_IS_ADMIN,
  ADD_USER_LANGUAGE,
  ADD_USER_NAME,
  ADD_COUNTRY_ID,
  ADD_IS_B2B,
  RESET_USER_TOKEN,
  RESET_USER_EMAIL,
  RESET_USER_COMPANY_ID,
  RESET_USER_COMPANY_NAME,
  RESET_USER_ID,
  RESET_USER_LANGUAGE,
  RESET_USER_IS_ADMIN,
  RESET_USER_NAME,
  RESET_COUNTRY_ID,
  RESET_IS_B2B,
  SET_USER_TYPE,
  SET_USER_DEPARTMENTS,
  RESET_USER_TYPE,
  RESET_USER_DEPARTMENTS,
  CHANGE_REDUCED_MENU,
};
