<template>
    <LoginContainer
    :pudk="pudk"
    />
</template>

<script>
import LoginContainer from '@/components/containers/LoginContainer.vue';

export default {
  name: 'Login',
  components: {
    LoginContainer,
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
};
</script>
