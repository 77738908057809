import axios from 'axios';

const doCall = (method, url, payload, headers) => axios({
  method,
  url: `/management${url}`,
  data: payload,
  headers,
})
  .then((res) => (res.statusText === 'OK' || (res.status >= 200 && res.status < 300) ? res.data : Promise.reject(res)))
  .catch((error) => Promise.reject(error));

export function removeCommonHeaders() {
  delete axios.defaults.headers.common.Authorization;
  delete axios.defaults.headers.common['userdesk-domain'];
  delete axios.defaults.headers.common.language;
}

export const postManagement = (url, payload, headers = {}) => doCall('POST', url, payload, headers);
export const getManagement = (url, payload, headers = {}) => doCall('GET', url, payload, headers);
export const deleteMethodManagement = (url, payload, headers = {}) => doCall('DELETE', url, payload, headers);
export const putManagement = (url, payload, headers = {}) => doCall('PUT', url, payload, headers);
