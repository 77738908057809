import Language from '@/domain/language/Language';
import Training from '@/domain/training/Training';
import store from '../../store';

const REQUESTED_STATUS = 'requested';

export default class TrainingCache {
  public static reset(): void {
    store.dispatch('training/resetTrainingInfo');
  }

  public static save(training: Training): void {
    store.dispatch('training/addTrainingInfo', training);
  }

  public static retrieveAvailableLanguages(): Array<Language> {
    return store.getters['training/availableLanguages'];
  }

  public static changeStatusToRequested() {
    store.dispatch('training/changeStatus', REQUESTED_STATUS);
  }

  public static setUserLanguage(language: string): void {
    store.dispatch('training/setUserLanguage', language);
  }
}
