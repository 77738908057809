var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 table-documents",attrs:{"sort-by":_vm.sortBy,"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"no-results-text":_vm.$t('table.no_results'),"no-data-text":_vm.$t('table.no_results'),"sort-desc":_vm.sortDesc,"hide-default-footer":"","item-key":"key","custom-filter":_vm.searchFilterItems},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"pagination":_vm.updateTotalItems},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(_vm.actions.includes('view') && !item.signedUrl)?_c('a',{on:{"click":function($event){return _vm.previousView(item)}}},[_vm._v(" "+_vm._s(_vm._f("noNameFilter")(item.title,_vm.$t('table.no_name')))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('feature',{attrs:{"tag":"span","name":"processingActivitiesOldChip"}},[(['rat','ret','uploaded-file'].includes(item.type))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"table-chip__old",attrs:{"small":""}},on),[_vm._v(" "+_vm._s(_vm.$t('table.processing_activity_old_label'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('table.processing_activity_old'))+" ")])]):_vm._e()],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.typeName)+" ")]}},{key:"item.LastModified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.LastModified).toLocaleDateString('en-GB'))+" - "+_vm._s(new Date(item.LastModified).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updated).toLocaleDateString('en-GB'))+" ")]}},(_vm.actions.includes('resendEmail'))?{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [(item.lastLogin)?_c('span',[_vm._v(" "+_vm._s(item.lastLogin)+" ")]):_c('a',{on:{"click":function($event){return _vm.$emit('resend', item)}}},[_vm._v(" "+_vm._s(_vm.$t('clientUsers.table.resend'))+" > ")])]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.actions.includes('download'))?_c('div',{staticClass:"td_actions"},[(_vm.downloadType.length !== 0 && _vm.downloadType.includes('doc') && !item.signedUrl)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.previousDownload(item)}}},[_c('v-icon',{staticClass:"material-icons-round"},[_vm._v(" get_app ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('downloadDocument', item)}}},[_c('v-icon',{staticClass:"material-icons-round"},[_vm._v(" get_app ")])],1)],1):_vm._e(),(_vm.actions.includes('delete'))?_c('div',{staticClass:"td_actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',[_vm._v(" delete ")])],1)],1):_vm._e(),(_vm.actions.includes('deleteUser') && item.id !== _vm.userId)?_c('div',{staticClass:"td_actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',[_vm._v(" delete ")])],1)],1):_vm._e()]}}],null,true)}),_c('footer-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFooterIfHasMinItems),expression:"showFooterIfHasMinItems"}],attrs:{"page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"totalItems":_vm.totalItems,"listLength":_vm.items.length},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }