import feature from '@/shared/featureToggle';
import store from '@/store/index';
import { user } from '@/infrastructure/user/UserCache';

function hasUserdeskUserRolesAccess() {
  if (user.isAdmin) {
    return true;
  }
  return (feature.isVisible('userdeskUserRoles', '', { adminCompanyId: store.state.admin.adminCompanyId }) || user.isB2B)
    && (user.isSupervisor || user.isTrainingsManager);
}

export default {
  hasUserdeskUserRolesAccess,
};
