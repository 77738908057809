<template>
  <div class="h-100 w-100">
    <v-img
      :src="bgUrl"
      width="100%"
      position="top center"
      class="bg_pa"
    >
      <h2 class="processing-activities__annotations">
        {{ annotation }}
      </h2>
      <div class="processing-activities__admin">
        <div class="processing-activities__admin--info">
          <p>{{adminInfo.name}}</p>
          <p>{{adminInfo.position}}</p>
        </div>
        <v-avatar
          size="100"
          class="processing-activities__admin--avatar"
        >
          <img
            :src="adminInfo.avatarUrl"
          >
        </v-avatar>
      </div>
    </v-img>
  </div>
</template>

<script>
const URL_IMG_BG = require('@/assets/img/bg_pa.png');

export default {
  name: 'right-annotations',
  props: {
    annotation: {
      type: String,
      default: '',
    },
    adminInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bgUrl: URL_IMG_BG,
    };
  },
};
</script>
