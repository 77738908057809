<template>
  <div class="h-100 w-100">
    <v-row class="mb-10">
      <v-col cols="12">
        <title-section
          :title="$t('treatmentManagers.title')"
          :subtitle="$t('treatmentManagers.subtitle')"
        />
      </v-col>
      <v-col cols="12">
        <h2>
          {{ $t('treatmentManagers.title_treatment_managers') }}
        </h2>
      </v-col>
      <v-col cols="12">
        <default-table
          :items.sync="register"
          :headers="headersTreatmentManagers"
          :actions="['download', 'view']"
          :downloadType="downloadTypes"
          sortBy="name"
          @viewDocument="viewDocument"
          @downloadDocument="downloadTreatmentManagerDocument"
          @selectExtensionShow="selectExtensionShow"
        />
      </v-col>
      <v-col cols="12">
        <h2 class="section-subtitle">
          {{ $t('treatmentManagers.title_treatment_managers_contracts') }}
        </h2>
      </v-col>
      <v-col cols="12" md="6">
        <input-search
          :value.sync="search"
          :label="$t('form.search')"
        />
      </v-col>
      <v-col cols="12">
        <default-table
          :items.sync="documents"
          :headers="headersContracts"
          :search.sync="search"
          :actions="['download', 'view']"
          :downloadType="['pdf', 'doc']"
          sortBy="name"
          @viewDocument="viewDocument"
          @downloadDocument="downloadTreatmentManagerDocument "
          @selectExtensionShow="selectExtensionShow"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import documentsUseCases from '@/usecases/documents/treatmentManagers';
import uploadedDocumentUseCases from '@/usecases/documents/uploaded';

import TitleSection from '../base/section/TitleSection.vue';
import InputSearch from '../base/form/InputSearch.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';

const PROCESSORS_LIST = 'registersTreatment';

export default {
  name: 'registers-container',
  components: {
    TitleSection,
    InputSearch,
    DefaultTable,
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    headersTreatmentManagers() {
      return [
        {
          text: this.$t('treatmentManagers.treatment_managers_table'),
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
    headersContracts() {
      return [
        {
          text: this.$t('treatmentManagers.treatment_managers_contracts_table'),
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: this.$t('treatmentManagers.last_updated'),
          align: 'start',
          sortable: true,
          value: 'updated',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
    documents() {
      return this.listDocuments;
    },
    register() {
      if (this.hasTreatmentContracts) {
        return [
          {
            id: 'registersTreatment',
            type: 'registersTreatment',
            title: this.$t('treatmentManagers.title_treatment_managers_list'),
          },
        ];
      }
      return [];
    },
    downloadTypes() {
      return ['pdf', 'doc'];
    },
  },
  data() {
    return {
      search: '',
      listDocuments: [],
      hasTreatmentContracts: false,
    };
  },
  methods: {
    async getContractsTreatmentManagers() {
      const response = await documentsUseCases.fetchDocumentsList(
        this.clientId, this.$i18n.locale, this.userIsAdmin,
      );

      if (response.success) {
        this.listDocuments = response.data;
        this.setDateText();
      } else {
        this.$showError('treatmentManagers.error.error_page_load');
      }
    },
    async hasTreatmentManagers() {
      const treatmentManagers = await documentsUseCases.hasTreatmentManagers(this.clientId);
      this.hasTreatmentContracts = treatmentManagers.data;
    },
    async viewDocument(document) {
      this.$showLoading();

      const response = await documentsUseCases.viewDocument(
        this.getDocumentId(document),
        document.type,
        this.$i18n.locale,
        document.title,
      );

      if (!response.success) {
        this.$showError('treatmentManagers.error.error_view_document');
      }

      this.$hideLoading();
    },
    selectExtensionShow(document) {
      this.$eventHub.$emit('showOptionsExtensionFile', document);
    },
    async downloadTreatmentManagerDocument(document, type = null) {
      this.$showLoading();
      let response = null;
      if (document.signedUrl) {
        response = await uploadedDocumentUseCases.downloadDocument(
          this.clientId, document.id, this.userIsAdmin,
        );
      } else {
        response = await documentsUseCases.downloadDocument(
          this.getDocumentId(document),
          document.type,
          type,
        );
      }

      if (!response.success) {
        this.$showError('treatmentManagers.error.error_download_document');
      }

      this.$hideLoading();
    },
    getDocumentId(document) {
      return document.type === PROCESSORS_LIST ? this.clientId : document.id;
    },
    setDateText() {
      Object.keys(this.listDocuments).forEach((key) => {
        if (this.listDocuments[key].signedUrl) {
          const date = new Date(this.listDocuments[key].modified.date);
          this.listDocuments[key].updated = date.getTime();
        } else {
          this.listDocuments[key].updated = this.listDocuments[key].modified * 1000;
        }
      });
    },
  },
  async beforeMount() {
    this.$showLoading();

    await this.hasTreatmentManagers();
    await this.getContractsTreatmentManagers();

    this.$eventHub.$on('downloadDocument', this.downloadTreatmentManagerDocument);

    this.$hideLoading();
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadTreatmentManagerDocument);
  },
};
</script>
