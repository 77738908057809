<template>
  <div class="h-100 w-100">
    <logo-img
      :logoUrl="urlLogo"
      :height="heightLogo"
      :position="positionLogo"
      :classLogo="classLogo"
      titleTag="h1"
    />
    <user-register-form
      v-if="!showFeedBack"
      @userWasRegistered="setUserEmailAndShowFeedback"
    />
    <user-register-feedback
      v-else
      :userEmail="userEmail"
    />
  </div>
</template>

<script>
import UserRegisterFeedback from '@/components/modules/userRegister/UserRegisterFeedback.vue';
import UserRegisterForm from '@/components/modules/userRegister/UserRegisterForm.vue';
import { registerClientUser } from '@/infrastructure/application/clientUser';
import LogoImg from '../base/icons/LogoImg.vue';

export default {
  name: 'user-register-container',
  inject: {
    urlLogo: 'urlLogo',
    heightLogo: 'heightLogo',
    positionLogo: 'positionLogo',
    classLogo: 'classLogo',
  },
  provide: {
    registerClientUser,
  },
  components: {
    UserRegisterFeedback,
    UserRegisterForm,
    LogoImg,
  },
  data() {
    return {
      showFeedBack: false,
      userEmail: '',
    };
  },
  methods: {
    setUserEmailAndShowFeedback(user) {
      this.userEmail = user.email;
      this.showFeedBack = true;
    },
  },
};
</script>
