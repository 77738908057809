import StringValueObject from '../shared/strings/StringValueObject';

export default class HeadquarterAddress extends StringValueObject {
  _address;

  constructor(address) {
    super(address, 0, 500);
    this._address = address;
  }
}
