function download(urlDownload) {
  const fileLink = document.createElement('a');

  fileLink.href = urlDownload;

  document.body.appendChild(fileLink);

  fileLink.click();

  document.body.removeChild(fileLink);
}

export default {
  download,
};
