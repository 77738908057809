import TomRepository from '@/domain/tom/TomRepository';
import FetchTomsQuery from '@/application/tom/fetchToms/FetchTomsQuery';
import Tom from '@/domain/tom/Tom';
import ClientId from '@/domain/client/ClientId';
import Translator from '@/infrastructure/Translator';
import TomsRepresentation from '@/application/tom/fetchToms/TomsRepresentation';
import TomList from '@/domain/tom/clasification/TomList';

export default class FetchTomsQueryHandler {
  private readonly tomRepository: TomRepository;

  private readonly translator: Translator;

  constructor(tomRepository: TomRepository, translator: Translator) {
    this.tomRepository = tomRepository;
    this.translator = translator;
  }

  public async invoke(query: FetchTomsQuery): Promise<any> {
    try {
      const toms: Tom[] = await this.tomRepository.ofClient(ClientId.fromInt(query.clientId));
      const tomsList: TomsRepresentation[] = toms.map((tom: Tom) => tom.representedAs(
        new TomsRepresentation(this.translator),
      ) as TomsRepresentation);

      const tomsSorted: TomsRepresentation[] = FetchTomsQueryHandler
        .sortTomsAndGroupByList(tomsList);

      return { success: true, toms: tomsSorted };
    } catch (e) {
      return { success: false };
    }
  }

  private static sortTomsAndGroupByList(tomsCollection: TomsRepresentation[])
    : TomsRepresentation[] {
    const listCollection: TomList[] = TomList.all();
    let tomsSorted: TomsRepresentation[] = [];

    listCollection.forEach((list: TomList) => {
      let tomsByList: TomsRepresentation[] = tomsCollection.filter(
        (tom: TomsRepresentation) => tom.list === list.toString(),
      );

      tomsByList = FetchTomsQueryHandler.sortTomsByCategoryAndName(tomsByList);

      tomsSorted = tomsSorted.concat(tomsByList);
    });

    return tomsSorted;
  }

  private static sortTomsByCategoryAndName(tomsByList: TomsRepresentation[]): TomsRepresentation[] {
    return tomsByList.sort(
      (tom1: TomsRepresentation, tom2: TomsRepresentation) => {
        if (tom1.category === tom2.category) {
          return tom1.name < tom2.name ? -1 : 1;
        }
        return tom1.category < tom2.category ? -1 : 1;
      },
    );
  }
}
