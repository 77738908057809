import DataBreachRepresentation from '@/domain/dataBreach/DataBreachRepresentation';

export default class DataBreachFetchRepresentation extends DataBreachRepresentation {
  get id(): string {
    return this._id.toString();
  }

  get createdDate(): string {
    return this._createdDate.toStringDatetime();
  }

  get createdBy(): string {
    return this._createdBy.toString();
  }

  get occurredOnDate(): string {
    return this._occurredOn.toStringDate();
  }

  get occurredOnTime(): string {
    return this._occurredOn.toStringTime();
  }

  get discoveredOnDate(): string {
    return this._discoveredOn.toStringDate();
  }

  get discoveredOnTime(): string {
    return this._discoveredOn.toStringTime();
  }

  get description(): string {
    return this._description.toString();
  }

  get howDiscovered(): string {
    return this._howDiscovered ? this._howDiscovered.toString() : '';
  }

  get wasIntentional(): string {
    return this._wasIntentional ? this._wasIntentional.toString() : '';
  }

  get compromisedData(): string {
    return this._compromisedData ? this._compromisedData.toString() : '';
  }

  get affectedProfiles(): string {
    return this._affectedProfiles ? this._affectedProfiles.toString() : '';
  }

  get consequences(): string {
    return this._consequences ? this._consequences.toString() : '';
  }

  get affectedCountries(): string {
    return this._affectedCountries ? this._affectedCountries.toString() : '';
  }

  get numberAffected(): number | null {
    return this._numberAffected ? this._numberAffected.toInt() : null;
  }

  get securityMeasuresImplemented(): string {
    return this._securityMeasuresImplemented ? this._securityMeasuresImplemented.toString() : '';
  }

  get futureSecurityMeasures(): string {
    return this._futureSecurityMeasures ? this._futureSecurityMeasures.toString() : '';
  }

  get securityMeasuresProblems(): string {
    return this._securityMeasuresProblems ? this._securityMeasuresProblems.toString() : '';
  }

  get informedAffectedPeople(): string {
    return this._informedAffectedPeople ? this._informedAffectedPeople.toString() : '';
  }

  get informedAuthorities(): string {
    return this._informedAuthorities ? this._informedAuthorities.toString() : '';
  }

  get attachedDocuments(): { id: string, name: string }[] {
    return this._attachedDocuments.map(
      (document) => ({ id: document.getId().toString(), name: document.getName().toString() }),
    );
  }

  get attachedDocumentsToDelete(): string[] {
    return [];
  }

  get evaluation(): string {
    return this._evaluation ? this._evaluation.toString() : '';
  }

  get agencyNotification(): boolean {
    return this._agencyNotification;
  }

  get reportLanguage(): string {
    return this._reportLanguage ? this._reportLanguage.toString() : '';
  }
}
