import EmployeeId from '@/domain/employee/EmployeeId';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import EmployeeToUpdateRepresentation
  from '@/application/employee/fetchEmployee/EmployeeToUpdateRepresentation';

export default class FetchEmployee {
  private readonly employeeRepository: EmployeeRepository;

  constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  public async invoke(employeeId: string): Promise<object> {
    try {
      const employee = await this.employeeRepository.retrieve(EmployeeId.fromString(employeeId));

      const employeeRepresentation = employee.representedAs(new EmployeeToUpdateRepresentation());

      return { success: true, employee: employeeRepresentation };
    } catch (e) {
      return { success: false };
    }
  }
}
