import CourseName from '@/domain/training/course/CourseName';

export default class Course {
  private readonly name: CourseName;

  constructor(name: CourseName) {
    this.name = name;
  }

  public getName(): CourseName {
    return this.name;
  }
}
