import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import Language from '@/domain/language/Language';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserLastLogin from '@/domain/clientUser/ClientUserLastLogin';
import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ClientUserToUpdate extends ClientUserRepresentation {
  get email(): ClientUserEmail {
    return this._email;
  }

  get name(): ClientUserName {
    return this._name;
  }

  get surname(): ClientUserSurname {
    return this._surname;
  }

  get type(): ClientUserType {
    return this._type;
  }

  get departments(): DepartmentId[] {
    return this._departments;
  }

  get language(): Language {
    return this._language;
  }

  get id(): ClientUserId {
    return this._id!;
  }

  get lastLogin(): ClientUserLastLogin | null {
    return (this._lastLogin) ? this._lastLogin : null;
  }
}
