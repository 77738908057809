export default ({
  clientFolderRepository,
}) => async (companyId, fileId, isAdmin) => {
  try {
    await clientFolderRepository.deleteFile(
      companyId, fileId, isAdmin,
    );
    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
