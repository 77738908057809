const ADD_EMPLOYEES = (state, employees) => {
  state.employees = employees;
};

const RESET_EMPLOYEES = (state) => {
  state.employees = [];
};

export default {
  ADD_EMPLOYEES,
  RESET_EMPLOYEES,
};
