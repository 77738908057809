<template >
  <v-overlay
    :value="show"
    absolute
    color="rgba(255, 255, 255, 0.9)"
    z-index="204"
    opacity="1"
    class="text-center alert-overlay"
  >
    <v-icon
      :color="typeAlert[options.type]['color']"
      class="material-icons-round"
    >
      {{ typeAlert[options.type]['icon'] }}
    </v-icon>
    <p class="text-center">
      <span v-if="!hasMoreThanOneText">
        {{ options.text }}
      </span>
      <span
        v-else
        v-for="(text, index) in options.text"
        :key="index"
      >
        {{ text }}
        <br v-if="index !== options.text.length - 1">
      </span>
    </p>
    <v-btn
      v-for="({text, action}, index) in options.buttons"
      :key="index"
      class="alert-overlay__buttons"
      :outlined="index < options.buttons.length -1"
      rounded
      color="primary"
      @click="clickButton(action)"
    >
      {{ text }}
    </v-btn>
  </v-overlay>
</template>

<script>
export default {
  name: 'alert-overlay',
  computed: {
    hasMoreThanOneText() {
      return Array.isArray(this.options.text);
    },
    typeAlert() {
      return {
        error: {
          color: '#D71313',
          icon: 'error',
        },
        success: {
          color: 'primary',
          icon: 'check_circle',
        },
      };
    },
  },
  data() {
    return {
      show: false,
      options: {
        type: 'error',
        text: '',
        buttons: [],
      },
    };
  },
  methods: {
    showAlertOverlay(options) {
      this.options = options;
      this.show = true;
    },
    clickButton(action) {
      action();
      this.show = false;
    },
  },
  beforeMount() {
    this.$eventHub.$on('showAlertOverlay', this.showAlertOverlay);
  },
  beforeDestroy() {
    this.$eventHub.$off('showAlertOverlay', this.showAlertOverlay);
  },
};
</script>

<style scoped>

</style>
