import TaskTypeValidationException from '@/domain/task/exceptions/TaskTypeValidationException';

const VERIFICATION = 'verification';
const QUESTION = 'question';
const DOCUMENTATION_REQUEST = 'documentation_request';

const VALID_TYPES = [
  VERIFICATION,
  QUESTION,
  DOCUMENTATION_REQUEST,
];

export default class TaskType {
  type;

  constructor(taskType) {
    TaskType.isValid(taskType);
    this.type = taskType;
  }

  static isValid(taskType) {
    if (!taskType) {
      throw new TaskTypeValidationException('Task type should not be empty');
    }

    if (!VALID_TYPES.includes(taskType)) {
      throw new TaskTypeValidationException('Task type does not exist');
    }
  }
}
