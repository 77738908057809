import Employee from '@/domain/employee/Employee';
import EmployeeId from '@/domain/employee/EmployeeId';
import store from '../../store';

export default class EmployeeCache {
  public static resetEmployees(): void {
    store.dispatch('employee/resetEmployees');
  }

  public static setEmployees(employees: Employee[]): void {
    store.dispatch('employee/addEmployees', employees);
  }

  public static getEmployees(): Employee[] {
    return store.getters['employee/employees'];
  }

  public static getEmployee(employeeId: EmployeeId): Employee | undefined {
    const employees = EmployeeCache.getEmployees();
    return employees.find((employee) => employee.getId().isEquals(employeeId));
  }

  public static removeEmployee(employeeId: EmployeeId): void {
    const employees = EmployeeCache.getEmployees();
    const indexEmployee = EmployeeCache.findIndex(employees, employeeId);

    employees.splice(indexEmployee, 1);
  }

  static addEmployee(employee: Employee): void {
    const employees = EmployeeCache.getEmployees();
    employees.push(employee);
  }

  static findIndex(employees: Employee[], employeeId: EmployeeId): number {
    return employees.findIndex((element) => element.getId().isEquals(
      employeeId,
    ));
  }

  static updateEmployee(employee: Employee): void {
    const employees = EmployeeCache.getEmployees();
    const indexEmployee = EmployeeCache.findIndex(employees, employee.getId());
    employees[indexEmployee] = employee;
  }
}
