import DataBreachId from '@/domain/dataBreach/DataBreachId';

export default class DataBreachWasDeleted {
  private readonly dataBreachId: DataBreachId;

  constructor(dataBreachId: DataBreachId) {
    this.dataBreachId = dataBreachId;
  }

  public getDataBreachId(): DataBreachId {
    return this.dataBreachId;
  }
}
