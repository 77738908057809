import ClientUsersWriteModel from '@/application/clientUser/fetchClientUsers/ClientUsersWriteModel';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserWasDeleted from '@/domain/clientUser/ClientUserWasDeleted';

export default class ClientUserWasDeletedEventListener {
  private readonly clientUsersWriteModel: ClientUsersWriteModel;

  constructor(
    clientUsersWriteModel: ClientUsersWriteModel,
  ) {
    this.clientUsersWriteModel = clientUsersWriteModel;
  }

  public async handle(event: ClientUserWasDeleted) {
    this.clientUsersWriteModel.delete(ClientUserId.fromInt(event.getClientUserId()));
  }
}
