import ProcessingActivityModelRepresentation from '@/domain/processingActivity/modelList/ProcessingActivityModelRepresentation';

const SPAIN_ID = 205;
const UNITED_KINGDOM_ID = 241;
const GERMANY_ID = 87;
const USA_ID = 242;

const NOT_INTERNATIONAL_COUNTRIES = [
  SPAIN_ID,
  UNITED_KINGDOM_ID,
  GERMANY_ID,
  USA_ID,
];

export default class ListProcessingActivityModelRepresentation
  extends ProcessingActivityModelRepresentation {
  _translator;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get country() {
    return this._translator.translate(`countries.by_id.country_${this._countryId}`);
  }

  get language() {
    return this._translator.translate(`languages.by_code.lang_${this._languageCode}`);
  }

  isModelOfThisCountry(countryId) {
    if (!NOT_INTERNATIONAL_COUNTRIES.includes(countryId) && this._countryId === null) {
      return true;
    }
    return this._countryId === countryId;
  }

  isModelOfThisLanguage(languageCode) {
    return this._languageCode === languageCode;
  }

  isModelOfThisDepartment(department) {
    return this._department === department;
  }
}
