import RecipientId from '@/domain/recipient/RecipientId';
import RecipientWasDeleted from '@/domain/recipient/RecipientWasDeleted';

export default (
  recipientRepository,
  recipientWasDeletedEventListener,
) => async (recipientId) => {
  try {
    const recipientIdToDelete = RecipientId.fromString(recipientId);
    const event = new RecipientWasDeleted(recipientIdToDelete);
    await recipientRepository.delete(recipientIdToDelete);
    recipientWasDeletedEventListener.invoke(event);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
