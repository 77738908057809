import es from './es/translations';
import en from './en/translations';
import de from './de/translations';
import ca from './ca/translations';

export default {
  es,
  en,
  ca,
  de,
};
