import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';

export default class ResetDataBreachesPending {
  private readonly dataBreachesPendingWriteModel: DataBreachesPendingWriteModel;

  constructor(dataBreachesPendingWriteModel: DataBreachesPendingWriteModel) {
    this.dataBreachesPendingWriteModel = dataBreachesPendingWriteModel;
  }

  async invoke(): Promise<void> {
    await this.dataBreachesPendingWriteModel.reset();
  }
}
