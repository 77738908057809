export const initialState = {
  isSpanishAdmin: true,
  countryCode: '',
  logoUrl: '',
  name: '',
  subdomain: null,
  adminCompanyId: null,
  isB2b: false,
};

const state = () => ({ ...initialState });

export default state();
