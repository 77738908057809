import { getManagement } from '@/shared/restActions';

function getUrlUserIsAdmin(companyId, isAdmin) {
  if (isAdmin) {
    return `?company=${companyId}`;
  }
  return '';
}

function getDownloadUrl(companyId, documentId) {
  return `/api/v2/documents-legacy/download/${companyId}/companyfile/${documentId}`;
}

function downloadDocument(
  companyId, documentId, isAdmin,
) {
  let url = getDownloadUrl(companyId, documentId);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

export default {
  downloadDocument,
};
