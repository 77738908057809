import ClientBusinessName from '@/domain/client/ClientBusinessName';
import ClientCountryId from '@/domain/client/ClientCountryId';
import ClientId from '@/domain/client/ClientId';
import ClientName from '@/domain/client/ClientName';
import ClientRepresentation from '@/domain/client/ClientRepresentation';

export default class Client {
  private readonly id: ClientId;

  private readonly businessName: ClientBusinessName;

  private readonly name: ClientName;

  private readonly countryId: ClientCountryId;

  constructor(
    id: ClientId,
    businessName: ClientBusinessName,
    name: ClientName,
    countryId: ClientCountryId,
  ) {
    this.id = id;
    this.businessName = businessName;
    this.name = name;
    this.countryId = countryId;
  }

  public getId(): ClientId {
    return this.id;
  }

  public representedAs(clientRepresentation: ClientRepresentation): ClientRepresentation {
    return clientRepresentation.setId(this.id)
      .setBusinessName(this.businessName)
      .setName(this.name)
      .setCountryId(this.countryId);
  }
}
