import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ClientUsersWriteModel from '@/application/clientUser/fetchClientUsers/ClientUsersWriteModel';
import ClientUser from '@/domain/clientUser/ClientUser';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUsersRepresentation
  from '@/application/clientUser/fetchClientUsers/ClientUsersRepresentation';
import Translator from '@/infrastructure/Translator';
import ClientUserWasUpdated from '@/domain/clientUser/ClientUserWasUpdated';
import ObtainDepartmentName from '@/domain/department/listDepartment/ObtainDepartmentName';
import DepartmentId from '@/domain/department/DepartmentId';
import DepartmentNameValue from '@/domain/department/DepartmentNameValue';

export default class ClientUserWasUpdatedEventListener {
  private readonly clientUserRepository: ClientUserRepository;

  private readonly clientUsersWriteModel: ClientUsersWriteModel;

  private readonly obtainDepartmentName: ObtainDepartmentName;

  private readonly translator: Translator;

  constructor(
    clientUserRepository: ClientUserRepository,
    clientUsersWriteModel: ClientUsersWriteModel,
    obtainDepartmentName: ObtainDepartmentName,
    translator: Translator,
  ) {
    this.clientUserRepository = clientUserRepository;
    this.clientUsersWriteModel = clientUsersWriteModel;
    this.obtainDepartmentName = obtainDepartmentName;
    this.translator = translator;
  }

  public async handle(event: ClientUserWasUpdated) {
    const clientUser: ClientUser = await this.clientUserRepository
      .retrieve(ClientUserId.fromInt(event.getClientUserId()));

    this.clientUsersWriteModel.update(this.buildClientUsersRepresentation(clientUser));
  }

  private buildClientUsersRepresentation(clientUser: ClientUser) {
    const clientUserListRepresentation: ClientUsersRepresentation = clientUser
      .representedAs(new ClientUsersRepresentation(
        this.translator,
      )) as ClientUsersRepresentation;

    clientUserListRepresentation
      .setDepartmentsName(clientUser.getDepartments()
        .map((dept: DepartmentId) => DepartmentNameValue
          .fromString(this.obtainDepartmentName.invoke(dept))));

    return clientUserListRepresentation;
  }
}
