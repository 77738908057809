<template>
  <span>
    <v-btn
      rounded
      color="primary"
      v-track="'New recipient -- start'"
      @click="showForm = true"
    >
      {{ $t('recipient.create.button') }}
    </v-btn>
    <dialog-form-recipient
      v-if="showForm"
      :showModal.sync="showForm"
      :title="$t('recipient.create.title')"
      @saveRecipient="saveRecipient"
    />
  </span>
</template>

<script>
import DialogFormRecipient from './form/DialogFormRecipient.vue';

export default {
  name: 'create-recipient',
  inject: {
    user: 'user',
    createRecipient: 'createRecipient',
  },
  components: {
    DialogFormRecipient,
  },
  props: {
    openCreateRecipient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    async saveRecipient(recipient) {
      this.$showLoading();

      const response = await this.createRecipient(recipient, this.user.clientId);
      const trackerAction = recipient.recipientModel ? 'choose' : 'create';
      this.$track({ action: `New recipient -- ${trackerAction} -- finish`, result: response.success });
      this.$hideLoading();

      if (!response.success) {
        this.showErrorAlert(response.error);
        this.$eventHub.$emit('saveRecipientFailed');
        return;
      }

      this.showForm = false;
      this.showSuccessAlert();
      this.$eventHub.$emit('recipientWasCreated');
    },
    openForm() {
      this.showForm = true;
    },
    showErrorAlert(errorApi) {
      if (this.openCreateRecipient) {
        this.emitRedirectionEvent(true, errorApi);
        return;
      }

      this.$showError('recipient.create.error');
    },
    showSuccessAlert() {
      if (this.openCreateRecipient) {
        this.emitRedirectionEvent(false);
        return;
      }

      this.$showSuccess('recipient.create.success');
    },
    emitRedirectionEvent(error, errorApi) {
      const errorMessage = errorApi === 'RecipientAlreadyExists'
        ? this.$t('recipient.form.alert.recipient_exist')
        : this.$t('recipient.create.error');

      this.$eventHub.$emit('showAlertOverlay', {
        type: error ? 'error' : 'success',
        text: error ? errorMessage : this.$t('recipient.create.success'),
        buttons: [
          {
            text: this.$t('recipient.create.from_processing.button_repeat'),
            action: this.openForm,
          },
          {
            text: this.$t('recipient.create.from_processing.button_back'),
            action: () => this.$router.push({ name: 'processing-activities' }),
          },
        ],
      });
    },
  },
  beforeMount() {
    this.$eventHub.$on('openRecipientDialog', this.openForm);
  },
  beforeDestroy() {
    this.$eventHub.$off('openRecipientDialog', this.openForm);
  },
  mounted() {
    if (this.openCreateRecipient) {
      this.showForm = true;
    }
  },
};
</script>
