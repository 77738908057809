<template>
  <delete-button-alert
    actionTracker="Delete Headquarter --start"
    @alertDeletionWasShowed="loadHeadquarter"
    @delete="removeHeadquarter"
  >
    <template v-slot:alert-title>
      {{ $t('headquarters.delete.title') }}
    </template>
    <template v-slot:text-alert>
        <span v-if="equipmentDependency">
          {{ $t('headquarters.delete.alert_use') }}
        </span>
      <span v-else>
          {{ $t('headquarters.delete.alert') }}
        </span>
    </template>
    <template v-slot:body-text>
      {{ $t('headquarters.delete.deletion') }}
      <strong>{{ headquarterToDelete.name }}</strong><br/>
      {{ $t('headquarters.delete.sure') }}
    </template>
  </delete-button-alert>
</template>
<script>
import DeleteButtonAlert from '@/components/base/tables/DeleteButtonAlert.vue';

export default {
  name: 'remove-headquarters',
  inject: {
    getHeadquartersEquipmentCount: 'getHeadquartersEquipmentCount',
    removeHeadquarters: 'removeHeadquarters',
  },
  components: {
    DeleteButtonAlert,
  },
  props: {
    headquarter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headquarterToDelete: null,
      equipmentDependency: true,
    };
  },
  methods: {
    async loadHeadquarter() {
      this.$showLoading();
      await this.checkHeadquartersEquipment(this.headquarter.id);
      this.$hideLoading();
      this.headquarterToDelete = this.headquarter;
    },
    async removeHeadquarter() {
      this.$showLoading();
      const response = await this.removeHeadquarters(this.headquarterToDelete.id);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('headquarters.delete.error');
        this.$track({ action: 'Delete office location --error' });
        return;
      }

      this.$showSuccess('headquarters.delete.success');
      this.$eventHub.$emit('headquarterWasDeleted');
      this.$track({ action: 'Delete office location --success' });
    },
    async checkHeadquartersEquipment(headquarterId) {
      const response = await this.getHeadquartersEquipmentCount(headquarterId);

      if (!response.success) {
        return;
      }

      this.equipmentDependency = response.numEquipments;
    },
  },
};
</script>
