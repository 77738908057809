<template>
    <v-dialog
      v-model="showCloseModal"
      persistent
      scrollable
      max-width="45%"
    >
      <v-card>
        <v-card-title>
          <h2>{{ $t("data_breach.form.modal_close_title") }}</h2>
          <v-spacer />
          <v-btn
            icon
            @click="$emit('update:showCloseModal', false)"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("data_breach.form.modal_close_description") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
            <v-btn
              color="primary"
              outlined
              rounded
              class="mb-0"
              @click="save"
              v-track="'Data Breach leave --save'"
            >
              {{ $t("data_breach.form.save") }}
            </v-btn>
            <v-btn
              color="primary"
              rounded
              class="mb-0"
              @click="exitAndClose"
              v-track="'Data Breach leave --not saving'"
            >
              {{ $t("data_breach.form.modal_leave_without_saving_button") }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'close-dialog-form',
  props: {
    showCloseModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    save() {
      this.$emit('saveDataBreach');
    },
    exitAndClose() {
      this.$emit('closeDataBreach');
    },
  },
};
</script>
