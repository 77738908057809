import AbstractRecipientRoleRepresentation
  from '@/domain/recipient/recipientRole/AbstractRecipientRoleRepresentation';

export default class RecipientRoleRepresentation extends AbstractRecipientRoleRepresentation {
  _translator;

  _name = null;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get key() {
    return this._key.toString();
  }

  get name() {
    if (!this._name) {
      this._name = this._translator.translate(`recipient.role.${this._key.toString()}`);
    }

    return this._name;
  }
}
