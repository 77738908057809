<template>
  <div>
    <h2>
      {{ $t('legalNotice.title') }}
    </h2>
     <v-row justify="center">
       <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.contact.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>{{ $t('legalNotice.contact.p_first_first_bold') }}</strong>
              <br>
              {{ $t('legalNotice.contact.p_first_second') }}
              <br>
              {{ $t('legalNotice.contact.p_first_third') }}
              <br>
              {{ $t('legalNotice.contact.p_first_fourth') }}
              <br>
              {{ $t('legalNotice.contact.p_first_fifth') }}
              <br>
              <strong>
                {{ $t('legalNotice.contact.p_first_sixth_bold') }}
              </strong>
              {{ $t('legalNotice.contact.p_first_sixth') }}
            </p>
            <p>
              <strong>
              {{ $t('legalNotice.contact.p_second_first_bold') }}
              </strong>
              {{ $t('legalNotice.contact.p_second_second') }}
              <br>
              {{ $t('legalNotice.contact.p_second_third') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.resolution.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.resolution.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="$i18n.locale !== deutsch_code">
          <v-expansion-panel-header>
            {{ $t('legalNotice.alternative.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.alternative.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.content.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.content.p_first_first') }}
              <br>
              {{ $t('legalNotice.content.p_first_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.links.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.links.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('legalNotice.copyrigth.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('legalNotice.copyrigth.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
       </v-expansion-panels>
     </v-row>
  </div>
</template>

<script>
export default {
  name: 'legal-notice',
  data() {
    return {
      deutsch_code: 'de',
    };
  },
};
</script>
