import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ResendActivationClientUserEmailCommand
  from '@/application/clientUser/resendActivationClientUserEmail/ResendActivationClientUserEmailCommand';
import ClientUserId from '@/domain/clientUser/ClientUserId';

export default class ResendActivationClientUserEmailCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  constructor(clientUserRepository: ClientUserRepository) {
    this.clientUserRepository = clientUserRepository;
  }

  public async invoke(command: ResendActivationClientUserEmailCommand) {
    try {
      await this.clientUserRepository.resend(ClientUserId.fromInt(command.clientUserId));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
