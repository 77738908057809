<template>
  <v-text-field
    v-model="inputValue"
    :type="showPassword ? 'text' : 'password'"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :label="label"
    :rules="rules"
    :error="error"
    :hint="hint"
    persistent-hint
    :validate-on-blur="validateOnBlur"
    filled
    @input="$emit('update:value', inputValue)"
    @click:append="showPassword = !showPassword"
    @keyup.enter="$emit('keyUpEnter')"
  />
</template>
<script>
export default {
  name: 'input-field-password',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      showPassword: false,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
