const addToms = ({ commit }, toms) => {
  commit('ADD_TOMS', toms);
};
const resetToms = ({ commit }) => {
  commit('RESET_TOMS');
};

export default {
  addToms,
  resetToms,
};
