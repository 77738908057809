export default class AbstractProcessingActivityRepresentation {
  _id;

  _name;

  _language;

  _status;

  _countryId;

  _lastModification;

  setId(id) {
    this._id = id;
  }

  setName(name) {
    this._name = name;
  }

  setLanguage(language) {
    this._language = language;
  }

  setStatus(status) {
    this._status = status;
  }

  setCountryId(countryId) {
    this._countryId = countryId;
  }

  setLastModification(lastModification) {
    this._lastModification = lastModification;
  }
}
