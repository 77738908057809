<template>
  <div class="h-100 w-100">
    <home-admin-container
      v-if="userIsAdmin && !userIsSelected"
      :pudk="pudk"
    />
    <home-client-container v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import HomeAdminContainer from '@/components/containers/HomeAdminContainer.vue';
import HomeClientContainer from '@/components/containers/HomeClientContainer.vue';

export default {
  name: 'home',
  components: {
    HomeAdminContainer,
    HomeClientContainer,
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      userIsSelected: 'companyId',
    }),
  },
};
</script>
