<template>
  <div class="filters">
    <p>
      {{ $t('filter.title') }}
    </p>
    <v-row align="center" class="filters__row">
      <v-row
        v-for="(filter, filterType, key) in items"
        class="filters__col--lop"
        :key="filterType"
      >
        <v-row
          v-if="filter.filters.length"
          class="filters__col--show"
        >
          <v-col
            cols="12"
            lg="1"
            md="3"
            sm="4"
            class="filters__col"
          >
            <p>
              {{ filter.name }}
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="11"
            md="9"
            sm="8"
            class="filters__col"
          >
            <v-row>
              <v-col
                v-for="(item, index) in filter.filters"
                :key="index"
                cols="12"
                md="4"
                class="filters__col"
              >
                <v-checkbox
                  v-model="filtersSelect[filterType]"
                  :label="item.name"
                  :value="item.id"
                  color="primary"
                  @change="selectFilter(filterType, item.id)"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            v-if="showDivider(key)"
            class="filters__divider"
          >
            <v-divider/>
          </v-col>
        </v-row>
      </v-row>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'filters-name',
  props: {
    selectedItems: {
      type: Object,
      default: () => [],
    },
    items: {
      type: Object,
      default: () => {},
    },
    section: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filtersSelect: this.selectedItems,
    };
  },
  methods: {
    selectFilter(filterType, filterId) {
      this.trackCheckbox(filterType, filterId);
      this.$emit('update:value', this.filtersSelect[filterType]);
    },
    showDivider(index) {
      const itemsLength = Object.keys(this.items).length;
      for (let i = index + 1; i < itemsLength; i += 1) {
        if (this.items[Object.keys(this.items)[i]].filters.length > 0) return true;
      }

      return false;
    },
    trackCheckbox(filterType, filterId) {
      const selected = this.filtersSelect[filterType].includes(filterId);
      const action = selected ? 'mark filter' : 'unmark filter';

      this.$track({
        action: `${action} ${this.section} ${filterId}`,
      });
    },
  },
  watch: {
    value() {
      this.filtersSelect = this.value;
    },
  },
};
</script>
