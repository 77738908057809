<template>
  <v-menu
    ref="menu"
    v-model="showTimePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        readonly
        background-color="#F3F8F7"
        filled
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        @input="$emit('update:value', time)"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="showTimePicker"
      v-model="time"
      format="24hr"
      color="primary"
      :elevation="0"
      full-width
      :min="minTime"
      :max="maxTime"
      @click:minute="minuteWasSelected"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'time-picker-field',
  props: {
    value: {
      type: String,
      default: 'null',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    minTime: {
      type: String,
      default: null,
    },
    maxTime: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showTimePicker: false,
      time: this.value,
    };
  },
  methods: {
    minuteWasSelected() {
      this.$refs.menu.save(this.time);
      this.$emit('update:value', this.time);
    },
  },
  watch: {
    value() {
      this.time = this.value;
    },
  },
};

</script>
