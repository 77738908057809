<template>
  <div>
    <v-btn class="login-google__btn" @click="handleClickSignIn">
      <img
        width="22"
        height="22"
        src="@/assets/img/google_icon.svg"
        alt=""
      >
      {{ $t('login.google') }}
    </v-btn>
    <div class="login-google__or">
      <v-divider class="login-google__or-line" />
      <p> {{ $t('login.or') }} </p>
      <v-divider class="login-google__or-line"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'google-login-btn',
  methods: {
    login(data) {
      this.$emit('userGoogleLogin', data);
    },
    async handleClickSignIn() {
      let googleUser;
      try {
        googleUser = await this.$gAuth.signIn();
      } catch (e) {
        return;
      }

      if (!googleUser) {
        return;
      }
      this.login(googleUser.getAuthResponse().id_token);
    },
  },
};
</script>
