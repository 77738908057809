<template>
  <div class="h-100 w-100">
    <v-dialog
      v-model="show"
      max-width="50%"
      persistent
    >
      <v-card class="change-password" tile>
        <v-card-title>
          <h2 class="change-password__title">
            {{ $t('settings.configuration_password') }}
          </h2>
          <v-spacer/>
          <v-btn
            icon
            @click="$emit('update:show', false)"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
          <p v-html="$t('settings.configuration_password_subtitle')"/>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <slot name="alert" />
            </v-col>
            <v-col cols="12">
              <input-field-password
                :value.sync="user.currentPassword"
                :label="$t('settings.current_password')"
                @keyUpEnter="$emit('changePassword', user)"
                :error="errorInput.currentPassword"
              />
            </v-col>
            <v-col cols="12">
              <input-field-password
                :value.sync="user.newPassword"
                :label="$t('settings.new_password')"
                @keyUpEnter="$emit('changePassword', user)"
                :error="errorInput.newPassword"
              />
            </v-col>
            <v-col cols="12">
              <input-field-password
                :value.sync="user.newPasswordConfirm"
                :label="$t('settings.new_password_confirm')"
                :error="errorInput.newPasswordConfirm"
                @keyUpEnter="$emit('changePassword', user)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="$emit('update:show', false)"
            rounded
            outlined
            color="primary"
          >
            {{ $t('settings.cancel') }}
          </v-btn>
          <v-btn
            @click="$emit('changePassword', user)"
            rounded
            color="primary"
          >
            {{ $t('settings.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InputFieldPassword from '../../../base/form/InputFieldPassword.vue';

export default {
  name: 'change-password',
  components: {
    InputFieldPassword,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errorInput: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    };
  },
};
</script>
