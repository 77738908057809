<template>
  <v-tabs-items
    v-model="tab"
  >
    <v-tab-item
      key="categories"
      id="categories"
      :transition="false"
    >
      <v-row class="activity-step activity-step__recipient">
          <v-col cols="12" class="text-center">
            <h2>{{ $t('processing_activities.step_recipients.where_data_share') }}</h2>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(recipient, key) in recipients"
              :key="key"
            >
              <v-col cols="12" md="5" class="activity-step__recipient-block">
                <div class="activity-step__recipient-category">
                  <p>{{ recipient.categoryName }}</p>
                  <v-icon
                    small
                    color="primary"
                    @click="deleteRecipientCategory(key)"
                  >
                    close
                  </v-icon>
                </div>
              </v-col>
              <v-col cols="12" md="7" class="activity-step__recipient-recipient">
                <div
                  v-if="!recipient.selection.length"
                  class="activity-step__recipient-recipient--edit"
                  @click="addRecipients(key)"
                >
                  <v-btn
                    color="primary"
                    outlined
                    icon
                    small
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                  <a>
                    {{ $t('processing_activities.step_recipients.add_recipients') }}
                  </a>
                </div>
                <div
                  v-else
                  class="activity-step__recipient-recipient--list"
                >
                  <p>
                    <span
                      v-for="(item, index) in recipient.selection"
                      :key="index"
                    >
                      <span v-if="index < 4">
                        {{ item | recipientTranslation(recipientsList) }}
                      </span>
                      <span v-if="(index < 3 && recipient.selection.length - 1 !== index)">
                        ,
                      </span>
                      <span v-if="index === 3">
                        ...
                      </span>
                    </span>
                  </p>
                  <v-btn
                    color="primary"
                    outlined
                    icon
                    small
                    @click="addRecipients(key)"
                  >
                    <v-icon
                      small
                    >
                      edit
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              :disabled="isBtnDisabled()"
              v-track="'Create processing activity -- add recipients -- continue'"
              @click="$emit('constructRecipientsGroups')"
            >
              {{ $t('processing_activities.step_recipients.continue') }}
            </v-btn>
          </v-col>
        </v-row>
    </v-tab-item>
    <v-tab-item
      key="recipients"
      id="recipients"
      :transition="false"
    >
      <v-row class="activity-step" v-if="recipients.length">
        <v-col cols="12" class="text-center">
          <h2>
            {{ $t('processing_activities.step_recipients.recipient_share') }}
          </h2>
        </v-col>
        <v-col
          ref="scrollCheckboxButtons"
          cols="12"
          class="activity-step__recipient-selection"
        >
          <checkbox-button-field
            :value.sync="recipients[positionRecipient].selection"
            :items.sync="recipientsList"
            :cols="4"
            @update:value="trackSelections"
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <a
            @click="goToCreateRecipient()"
            v-track="'Create processing activity -- create new recipient'"
            v-html="$t('processing_activities.step_recipients.create_recipient')"
          >
          </a>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="primary"
            :disabled="!recipients[positionRecipient].selection.length"
            rounded
            @click="recipientSelected"
          >
            {{ $t('processing_activities.step_recipients.continue') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import processingActivityStepsServices from '@/services/questionnaires/processingActivities/steps';

import CheckboxButtonField from '../../../base/form/CheckboxButtonField.vue';

export default {
  name: 'recipients',
  inject: {
    fetchActiveRecipients: 'fetchActiveRecipients',
    saveProcessingActivityFormStatus: 'saveProcessingActivityFormStatus',
    resetProcessingActivityFormStatus: 'resetProcessingActivityFormStatus',
  },
  components: {
    CheckboxButtonField,
  },
  props: {
    recipientsSelected: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: Number,
      default: null,
    },
    currentStep: {
      type: String,
      default: '',
    },
    argsFormStatus: {
      type: Object,
      default: null,
    },
    isSelectionRecipientStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipients: this.recipientsSelected,
      tab: processingActivityStepsServices.getFirstStepOfRecipients(),
      positionRecipient: 0,
      recipientsList: [],
      selectedRecipients: [],
    };
  },
  methods: {
    deleteRecipientCategory(index) {
      this.positionRecipient = 0;
      this.recipients.splice(index, 1);
      this.$emit('update:recipients', this.recipients);
      this.$emit('goRecipientsCategorySelection', true);
    },
    addRecipients(index) {
      this.positionRecipient = index;
      this.tab = processingActivityStepsServices.getNextStepOfRecipients(this.tab);
      this.$track({ action: 'Create processing activity -- add recipients' });
    },
    recipientSelected() {
      this.tab = processingActivityStepsServices.getNextStepOfRecipients(this.tab);
    },
    isBtnDisabled() {
      let totalCompletedSelection = 0;
      this.recipientsSelected.forEach((obj) => {
        if (obj.selection.length > 0) {
          totalCompletedSelection += 1;
        }
      });
      return (totalCompletedSelection !== this.recipientsSelected.length);
    },
    async getRecipients() {
      const response = await this.fetchActiveRecipients.invoke(this.clientId);

      if (!response.success) {
        return;
      }

      this.recipientsList = response.recipients;
    },
    goToCreateRecipient() {
      this.$emit('saveProcessingActivityOnCache');
      this.saveProcessingActivityFormStatus.invoke(this.currentStep, {
        positionRecipient: this.positionRecipient,
        tab: this.tab,
        recipients: this.recipients,
      });
      this.$router.push({ name: 'registers', params: { createRecipient: 'recipient' } });
    },
    restoreComponent() {
      this.tab = this.argsFormStatus.tab;
      this.positionRecipient = this.argsFormStatus.positionRecipient;
      this.recipients = this.argsFormStatus.recipients;
      this.$emit('update:recipientsSelected', this.recipients);
      this.resetProcessingActivityFormStatus.invoke();
    },
    trackSelections(recipients) {
      let recipientSelect;
      let recipient = recipients.filter((value) => !this.selectedRecipients.includes(value));
      if (recipient.length > 0) {
        recipientSelect = this.recipientsList.filter((value) => value.id === recipient[0]);
        this.$track({
          action: 'Select recipient recipient',
          recipient: recipientSelect[0].name,
        });
        this.selectedRecipients = [...recipients];
        return;
      }
      recipient = this.selectedRecipients.filter((value) => !recipients.includes(value));
      recipientSelect = this.recipientsList.filter((value) => value.id === recipient[0]);
      this.selectedRecipients = [...recipients];
      this.$track({
        action: 'Unselect recipient recipient',
        recipient: recipientSelect[0].name,
      });
    },
  },
  beforeMount() {
    if (this.argsFormStatus) {
      this.restoreComponent();
    }
    this.getRecipients();
  },
  mounted() {
    this.recipients = this.recipientsSelected;
  },
  watch: {
    recipientsSelected() {
      this.recipients = this.recipientsSelected;
    },
    tab() {
      this.$emit('update:isSelectionRecipientStep', this.tab === 'recipients');
    },
  },
  filters: {
    recipientTranslation(recipientId, recipientsList) {
      let recipientTranslation = '';
      recipientsList.forEach((obj) => {
        if (obj.id === recipientId) {
          recipientTranslation = obj.name;
        }
      });
      return recipientTranslation;
    },
  },
};
</script>
