export default ({
  userRepository,
}) => async (currentPassword, newPassword) => {
  try {
    await userRepository.changePasswordSettings(currentPassword, newPassword);
    return { success: true };
  } catch (e) {
    return { success: false, code: e.response.status };
  }
};
