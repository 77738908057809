import EmployeeRepresentation from '../../domain/employee/EmployeeRepresentation';

export default class EmployeeRepresentationApi extends EmployeeRepresentation {
  toJson() {
    return JSON.stringify({
      id: this._id!.toString(),
      client_id: this._clientId!.toInt(),
      name: this._name!.toString(),
      surname: this._surname!.toString(),
      email: (this._email) ? this._email.toString() : null,
      position: (this._position) ? this._position.toString() : null,
      nif: (this._nif) ? this._nif.toString() : null,
      start_date: (this._entry) ? this._entry.toString() : null,
      end_date: (this._exit) ? this._exit.toString() : null,
    });
  }
}
