<template>
  <div>
    <h2>
      {{ $t('privacyPolicy.privacy_policy.title') }}
    </h2>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.privacy_policy.basic_info') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.treatment_responsible') }}
            </h3>
            <p>
              {{ $t('privacyPolicy.privacy_policy.pridatect') }}
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.purposes') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.info_web') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.info_management') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.formative_download') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.formative_online') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.contract_software') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.contract_legal') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.pridatect_access') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.userdesk_access') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.academy_access') }}
                </li>
              </ul>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.legitimacy_treatment') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.consent_interested') }}
                  </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.contract_execute') }}
                  </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.legitimate_interest') }}
                  </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.legal_compliance') }}
                  </li>
              </ul>
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.data_retention') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.data_retention_explanation') }}
                </li>
              </ul>
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.recipients') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.recipients_explanation') }}
                </li>
              </ul>
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.rights_intereseted') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.rights_intereseted_explanation') }}
                </li>
              </ul>
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.rights_intereseted_explanation') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.pridatect_web') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.rrss') }}
                  <ul>
                    <li>Twitter</li>
                    <li>LinkedIn</li>
                    <li>Facebook</li>
                  </ul>
                </li>
              </ul>
            </p>
            <h3>
              {{ $t('privacyPolicy.privacy_policy.additional_info') }}
            </h3>
            <p>
              <ul>
                <li>
                  {{ $t('privacyPolicy.privacy_policy.additional_info_explanation') }}
                </li>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <h2>
      {{ $t('privacyPolicy.additional_info.title') }}
    </h2>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.data_protect.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.data_protect.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.treatment_responsible.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.treatment_responsible.p_first') }}
              <strong>
                {{ $t('privacyPolicy.additional_info.treatment_responsible.p_first_email') }}
              </strong>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.presence_rrss.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.presence_rrss.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.additional_info.presence_rrss.p_first_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.web.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.web.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.additional_info.web.p_first_second') }}
              <br>
              {{ $t('privacyPolicy.additional_info.web.p_first_third') }}
              <br>
              {{ $t('privacyPolicy.additional_info.web.p_first_four') }}
              <ul>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_first_four_list_first') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_first_four_list_second') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_first_four_list_third') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_first_four_list_fourth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_first_four_list_fifth') }}
                </li>
              </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.web.p_second') }}
              <ul>
                <li>
                  {{ $t('privacyPolicy.additional_info.web.p_second_list_first') }}
                </li>
              </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.web.p_third_seventh_first') }}
              <strong>
                {{ $t('privacyPolicy.additional_info.web.p_third_seventh_second') }}
              </strong>
              {{ $t('privacyPolicy.additional_info.web.p_third_seventh_third') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.userdesk.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.additional_info.userdesk.p_first_second') }}
              <br>
              {{ $t('privacyPolicy.additional_info.userdesk.p_first_third') }}
              <ul>
                <li>
                  {{ $t('privacyPolicy.additional_info.userdesk.p_first_third_list_first') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.userdesk.p_first_third_list_second') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.userdesk.p_first_third_list_third') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.userdesk.p_first_third_list_fourth') }}
                </li>
              </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_second_first') }}
              <strong>
                {{ $t('privacyPolicy.additional_info.userdesk.p_second_second') }}
              </strong>
              {{ $t('privacyPolicy.additional_info.userdesk.p_second_third') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_third') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_fourth') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_fifth') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.userdesk.p_sixth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.data_collect.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.data_collect.p_first') }}
              <ul>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_first') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_second') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_third') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_fourth') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_fifth') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_sixth') }}
                </li>
                 <li>
                  {{ $t('privacyPolicy.additional_info.data_collect.p_first_list_seventh') }}
                </li>
              </ul>
            </p>
              {{ $t('privacyPolicy.additional_info.data_collect.p_second_first') }}
              <br>
              {{ $t('privacyPolicy.additional_info.data_collect.p_second_second') }}
              <br>
              {{ $t('privacyPolicy.additional_info.data_collect.p_second_third') }}
            <p>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.legitimation.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.legitimation.p_first') }}
            <ul>
              <li>
                {{ $t('privacyPolicy.additional_info.legitimation.p_first_list_first') }}
              </li>
              <li>
                {{ $t('privacyPolicy.additional_info.legitimation.p_first_list_second') }}
              </li>
              <li>
                {{ $t('privacyPolicy.additional_info.legitimation.p_first_list_third') }}
              </li>
              <li>
                {{ $t('privacyPolicy.additional_info.legitimation.p_first_list_fourth') }}
                <ul>
                  <li>
                    {{ $t(`privacyPolicy.additional_info.legitimation
                      .p_first_list_fourth_list_first`) }}
                  </li>
                  <li>
                    {{ $t(`privacyPolicy.additional_info.legitimation.
                      p_first_list_fourth_list_second`) }}
                  </li>
                </ul>
              </li>
            </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.legitimation.p_second') }}
              <ul>
                <li>
                  {{ $t('privacyPolicy.additional_info.legitimation.p_second_list') }}
                </li>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.conservation_time.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.conservation_time.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.conservation_time.p_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.conservation_time.p_third') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.recipients.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_third') }}
              <ul>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_first') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_second') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_third') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_fourth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_fifth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_sixth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_seventh') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_eighth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_nineth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_tenth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_eleventh') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_twelfth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_thirteenth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_fourteenth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_fiveteenth') }}
                </li>
                <li>
                  {{ $t('privacyPolicy.additional_info.recipients.p_third_list_sixteenth') }}
                </li>
              </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_fourth') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_fifth') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.recipients.p_sixth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.data_location.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.data_location.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.rights.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.rights.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.rights.p_second') }}
            <ul>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_first_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_first_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_second_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_second_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_third_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_third_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_fourth_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_fourth_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_fifth_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_fifth_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_sixth_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_sixth_normal') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.additional_info.rights.p_second_list_seventh_bold') }}
                </strong>
                {{ $t('privacyPolicy.additional_info.rights.p_second_list_seventh_normal') }}
              </li>
            </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.rights.p_third') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.data_veracity.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.data_veracity.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.data_veracity.p_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.data_veracity.p_third') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.data_security.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.data_security.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.data_security.p_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.cookies.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.cookies.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.security_policy.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.security_policy.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.privacy_modified.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.privacy_modified.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.additional_info.responsible_uk.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_second_first') }}
              <br>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_second_second') }}
              <br>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_second_third') }}
              <br>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_second_fourth') }}
              <br>
              {{ $t('privacyPolicy.additional_info.responsible_uk.p_second_fifth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'privacy-policy',
};
</script>
