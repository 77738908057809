export default class ResetProcessingActivityFormStatus {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke() {
    this._processingActivityCache.resetFormStatus();
  }
}
