<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items.sync="warranties"
    :label="$t('recipient.form.warranties')"
    :tooltip="tooltip"
    item-value="key"
    :multiple="true"
    :attach="true"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'warranties-recipient-selector',
  inject: {
    fetchRecipientWarranties: 'fetchRecipientWarranties',
  },
  components: {
    SelectAutocompleteField,
  },
  props: {
    warrantiesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: this.warrantiesSelected,
      warranties: [],
      tooltip: {
        icon: this.$t('form.advanced_field.icon'),
        text: this.$t('form.advanced_field.text'),
      },
    };
  },
  methods: {
    getWarranties() {
      this.warranties = this.fetchRecipientWarranties();
    },
  },
  beforeMount() {
    this.getWarranties();
  },
  watch: {
    warrantiesSelected() {
      this.value = this.warrantiesSelected;
    },
    value() {
      this.$emit('update:warrantiesSelected', this.value);
    },
  },
};
</script>
