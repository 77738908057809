<template>
  <div class="h-100 w-100">
    <v-col cols="12">
      <h4>
        <span>
          {{ $t('toms.information_modal.upload_documents') }}
        </span>
      </h4>
      <upload-document
        :filesUploaded="filesToUpload"
        :alreadyUploadedFiles="alreadyUploadedFiles"
        :filesDeleted="filesDeleted"
        documentType="tom-document"
      />
    </v-col>
  </div>
</template>

<script>
import UploadDocument from '@/components/base/form/UploadDocument.vue';

export default {
  name: 'upload-tom-document',
  components: { UploadDocument },
  props: {
    filesToUpload: {
      type: Array,
      default: () => [],
    },
    alreadyUploadedFiles: {
      type: Array,
      default: () => [],
    },
    filesDeleted: {
      type: Array,
      default: () => [],
    },
  },

};
</script>
