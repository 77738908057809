import store from '@/store/';
import RecipientWriteModel from '@/domain/recipient/writeModel/RecipientWriteModel';
import RecipientListRepresentationCache from './RecipientListRepresentationCache';

export default class RecipientCache extends RecipientWriteModel {
  resetRecipients() {
    store.dispatch('recipient/resetRecipients');
  }

  static getRecipients() {
    return store.getters['recipient/recipients'];
  }

  static removeRecipient(recipientId) {
    const recipients = RecipientCache.getRecipients();
    const indexRecipient = RecipientCache.findRecipientIndexById(
      recipients,
      recipientId,
    );

    recipients.splice(indexRecipient, 1);
  }

  static addRecipient(recipient) {
    const recipients = RecipientCache.getRecipients();
    recipients.push(recipient);
  }

  static updateRecipient(recipient) {
    const recipients = RecipientCache.getRecipients();
    const indexRecipient = RecipientCache.findRecipientIndexById(recipients, recipient.getId());
    recipients.splice(indexRecipient, 1);
    recipients.push(recipient);
  }

  static deleteOneDocumentData(recipientId) {
    const totalDocs = RecipientListRepresentationCache.getTotalDocumentsByRecipientId(recipientId);
    RecipientListRepresentationCache.updateTotalDocumentsRecipient(recipientId, totalDocs - 1);
  }

  static findRecipientIndexById(recipients, recipientId) {
    return recipients.findIndex((recipient) => recipientId.isEquals(recipient.getId()));
  }

  static getRecipient(recipientId) {
    const recipients = RecipientCache.getRecipients();

    return recipients.find((recipient) => recipient.getId().isEquals(
      recipientId,
    ));
  }
}
