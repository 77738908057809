<template>
  <div class="h-100 w-100">
    <v-btn
      text
      color="primary"
      @click="showDeleteModal = true"
      v-track="'Delete DataBreach --start'"
    >
      {{ $t("data_breach.form.delete") }}
    </v-btn>
    <v-dialog
      v-model="showDeleteModal"
      persistent
      scrollable
      max-width="45%"
    >
      <v-card>
        <v-card-title>
          <h2>{{ $t("data_breach.form.modal_delete_title") }}</h2>
          <v-spacer />
          <v-btn
            icon
            @click="showDeleteModal = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("data_breach.form.modal_delete_description") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            rounded
            class="mb-0"
            @click="showDeleteModal = false"
            v-track="'Delete DataBreach --cancel'"
          >
            {{ $t('data_breach.form.modal_delete_no') }}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mb-0"
            @click="confirmDeleteDataBreach"
            v-track="'Delete DataBreach --confirm'"
          >
            {{ $t('data_breach.form.modal_delete_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'delete-data-breach',
  inject: {
    deleteDataBreach: 'deleteDataBreach',
  },
  props: {
    dataBreachId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  methods: {
    async confirmDeleteDataBreach() {
      const response = await this.deleteDataBreach.invoke(this.dataBreachId);
      this.$track({ action: 'Delete data breach', result: response.success });

      if (!response.success) {
        this.$showError('data_breach.form.modal_delete_error');
        return;
      }

      this.showDeleteModal = false;
      this.$showSuccess('data_breach.form.alert_message_form_delete_success');

      this.$emit('hideFormModal');
      this.$eventHub.$emit('dataBreachWasDeleted');
    },
  },
};
</script>
