import DomainEvent from '@/domain/domainEvent/DomainEvent';
import ClientUserId from '@/domain/clientUser/ClientUserId';

export default class ClientSelectedWasChanged extends DomainEvent {
  private readonly clientId: number;

  protected constructor(clientId: number) {
    super();
    this.clientId = clientId;
  }

  public static fromClientUserId(clientUserId: ClientUserId) {
    return new this(clientUserId.toInt());
  }

  public get eventName(): string {
    return 'ClientSelectedWasChanged';
  }

  public getClientId(): number {
    return this.clientId;
  }
}
