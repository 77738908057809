<template>
  <change-password-container :token="token" />
</template>
<script>
import ChangePasswordContainer from '@/components/containers/ChangePasswordContainer.vue';

export default {
  name: 'change-password',
  components: {
    ChangePasswordContainer,
  },
  props: [
    'language',
    'token',
  ],
  mounted() {
    this.$i18n.locale = this.language;
  },
};
</script>
