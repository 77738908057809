import ClientId from '@/domain/client/ClientId';
import EmployeesRepresentation from '@/application/employee/fetchEmployees/EmployeesRepresentation';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import Employee from '@/domain/employee/Employee';

export default class FetchEmployees {
  private readonly employeeRepository: EmployeeRepository;

  private readonly normalization: any;

  constructor(employeeRepository: EmployeeRepository, normalization: any) {
    this.employeeRepository = employeeRepository;
    this.normalization = normalization;
  }

  public async invoke(clientId: number): Promise<object> {
    try {
      const employees: Employee[] = await this.employeeRepository.ofClient(
        ClientId.fromInt(clientId),
      );

      return {
        success: true,
        employees: employees.map(
          (employee) => employee.representedAs(new EmployeesRepresentation(this.normalization)),
        ),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
