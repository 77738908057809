<template>
  <div class="h-100 w-100">
    <div>
      <v-form v-model="formValidate">
        <v-row>
          <v-col cols="12" md="6">
            <input-field
              :value.sync="user.name"
              :label="`${$t('clientUsers.form.name')} *`"
              :rules="[validations.required, validations.min]"
              :disabled="userIsBeingSaving"
              :validateOnBlur="true"
              @keyUpEnter="save"
            />
          </v-col>
          <v-col cols="12" md="6">
            <input-field
              :value.sync="user.surname"
              :label="`${$t('clientUsers.form.surname')} *`"
              :rules="[validations.required]"
              :disabled="userIsBeingSaving"
              :validateOnBlur="true"
              @keyUpEnter="save"
            />
          </v-col>
          <v-col cols="12" md="6">
            <select-field
              :value.sync="user.locale"
              :items.sync="languageList"
              itemValue="code"
              itemText="name"
              :label="`${$t('clientUsers.form.language')} *`"
              :disabled="userIsBeingSaving"
              :validateOnBlur="true"
            />
          </v-col>
          <v-col md="6" v-if="!$vuetify.breakpoint.smAndDown" />
          <v-col cols="12" md="6">
            <input-field
              :value.sync="user.email_init"
              :label="`${$t('clientUsers.form.email')} *`"
              :rules="[validations.required, validations.email, validations.changeEmail]"
              :disabled="userIsBeingSaving"
              @keyUpEnter="save"
              :validateOnBlur="true"
            />
          </v-col>
          <v-col cols="12" md="6">
            <input-field
              :value.sync="user.email_repeat"
              :label="`${$t('clientUsers.form.repeat_email')} *`"
              :rules="[validations.required, validations.email, validations.emailEquals]"
              :disabled="userIsBeingSaving"
              ref="repeatEmailComponent"
              reference="repeatEmailInput"
              :validateOnBlur="true"
              @keyUpEnter="save"
            />
          </v-col>
          <v-col cols="12">
            <p class="p-label">{{ $t('clientUsers.form.role') }} *</p>
            <select-user-role-and-departments
              :userRole.sync="user.type"
              :departmentSelected.sync="user.departments"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-card-actions>
    <v-spacer/>
    <v-btn
      rounded
      color="primary"
      :disabled="!isFormValid"
      :loading="userIsBeingSaving"
      @click="save"
    >
      {{ $t('clientUsers.form.save') }}
    </v-btn>
    <v-spacer/>
  </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userUsecases from '@/application/user/usecases';
import formValidationService from '@/services/validations/formValidation';
import InputField from '@/components/base/form/InputField.vue';
import SelectField from '@/components/base/form/SelectField.vue';
import SelectUserRoleAndDepartments
  from '@/components/modules/clientUsers/SelectUserRoleAndDepartments.vue';

const EMPLOYEE_TYPE = 'employee';

export default {
  name: 'form-client-user',
  components: {
    SelectUserRoleAndDepartments,
    InputField,
    SelectField,
  },
  props: {
    userIsBeingSaving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', {
      clientId: 'companyId',
    }),
    isFormValid() {
      if (this.user.type === EMPLOYEE_TYPE && !this.user.departments.length) {
        return false;
      }

      return this.formValidate;
    },
  },
  data() {
    return {
      user: {
        name: '',
        surname: '',
        email_init: '',
        email_repeat: '',
        locale: this.$i18n.locale,
        type: '',
        departments: [],
      },
      formValidate: false,
      validations: {
        required: (v) => !!v.trim() || this.$t('form.validations.required_field'),
        min: (v) => v.trim().length >= 2 || this.$t('form.validations.min_length', { length: 2 }),
        email: (v) => formValidationService.isValidEmail(v) || this.$t('form.validations.wrong_email'),
        emailEquals: (v) => formValidationService.isEmailsSame(this.user.email_init, v) || this.$t('form.validations.not_same_emails'),
        changeEmail: () => this.changeFirstEmail(),
      },
      languageList: [],
    };
  },
  methods: {
    save() {
      this.$emit('saveUser', this.user);
    },
    changeFirstEmail() {
      if (this.$refs.repeatEmailComponent) {
        this.$refs.repeatEmailComponent.$refs.repeatEmailInput.validate();
      }
      return true;
    },
    async getClientLanguagesList() {
      const response = await userUsecases.fetchClientLanguagesListByClientId(this.clientId);

      if (!response.success) {
        this.$showError('clientUsers.error.load_page');
      }

      response.data.forEach((obj, index) => {
        response.data[index].name = this.$t(`languages.by_code.lang_${obj.code}`);
      });

      this.languageList = response.data;
    },
  },
  beforeMount() {
    this.getClientLanguagesList();
  },
};
</script>
