import ClientId from '@/domain/client/ClientId';

export default class FetchActiveRecipients {
  _recipientReadModel;

  constructor(recipientReadModel) {
    this._recipientReadModel = recipientReadModel;
  }

  async invoke(clientId) {
    try {
      const recipients = await this._recipientReadModel.ofClient(ClientId.fromInt(clientId));

      const activeRecipients = recipients.filter((recipient) => recipient.isStatusValidated);

      return {
        success: true,
        recipients: activeRecipients.sort((firstRecipient, secondRecipient) => (
          (firstRecipient.nameNormalized > secondRecipient.nameNormalized) ? 1 : -1)),
      };
    } catch (e) {
      return { success: false };
    }
  }
}
