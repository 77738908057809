import ClientUserWasCreated from '@/domain/clientUser/ClientUserWasCreated';
import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';
import EventListener from '@/application/eventBus/EventListener';

export default class ClientUserWasCreatedEventListener implements EventListener {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  handle(clientWasCreated: ClientUserWasCreated): void {
    this.trainingEmployeeRepository.reset();
  }
}
