export default ({
  documentFiltersService,
}) => async (documents) => {
  try {
    const listInfo = await documentFiltersService.getTypesDocuments(documents);
    return { success: true, data: listInfo };
  } catch (e) {
    return { success: false };
  }
};
