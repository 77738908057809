<template>
  <div class="h-100 w-100">
    <h2 class="mb-2"> 2.
      <span class="grey--text text--darken-3">{{ $t("data_breach.form.step.two.title") }}</span>
    </h2>
    <v-divider class="ml-5 mb-4"/>
    <v-row class="pl-5 data-breach__form-step">
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.two.question_1") }}</span></h4>
        <p>
          {{ $t("data_breach.form.step.two.question_1_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.compromisedData"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.two.question_2") }}</span></h4>
        <p>
          {{ $t("data_breach.form.step.two.question_2_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.affectedProfiles"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.two.question_3") }}</span></h4>
        <p>
          {{ $t("data_breach.form.step.two.question_3_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.consequences"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.two.question_4") }}</span></h4>
        <p>
          {{ $t("data_breach.form.step.two.question_4_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.affectedCountries"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
      <v-col cols="12">
        <h4><span>{{ $t("data_breach.form.step.two.question_5") }}</span></h4>
        <p>
          {{ $t("data_breach.form.step.two.question_5_info") }}
        </p>
        <number-input-field
          :value.sync="dataBreach.numberAffected"
          :rules="[validations.intNumber]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextareaField from '@/components/base/form/TextareaField.vue';
import NumberInputField from '@/components/base/form/NumberInputField.vue';

export default {
  name: 'affected-data-info',
  components: {
    TextareaField,
    NumberInputField,
  },
  props: {
    dataBreach: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      validations: {
        maxLength: (v) => v.trim().length <= 600 || this.$t('form.validations.max_length', { length: 600 }),
        intNumber: (v) => (Number.isInteger(Number(v)) && v >= 0) || this.$t('form.validations.invalid_integer'),
      },
    };
  },
  methods: {
    textCounter(text) {
      return text.trim().length;
    },
  },
};
</script>
