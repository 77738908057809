import formValidationService from './formValidation';

function validate(files) {
  const filesWrongType = [];
  const filesWrongSize = [];
  const correctFiles = [];
  let totalSize = 0;

  for (let i = 0; i < files.length; i += 1) {
    const isCorrectTypeFile = formValidationService.correctTypeFiles(files[i].name);
    const isCorrectFileSize = formValidationService.correctFileSize(files[i].size, 'mb10');
    totalSize += files[i].size;

    if (isCorrectTypeFile && isCorrectFileSize) {
      correctFiles.push(files[i]);
    }

    if (!isCorrectTypeFile) {
      filesWrongType.push(files[i]);
    }
    if (!isCorrectFileSize) {
      filesWrongSize.push(files[i]);
    }
  }

  if (!formValidationService.correctAllFilesSize(totalSize, 'mb50')) {
    return { success: false, error: { type: 'maxFilesSizes' } };
  }

  if (correctFiles.length !== files.length) {
    return { success: false, error: { type: 'wrongFiles', data: { filesWrongType, filesWrongSize } } };
  }

  return { success: true };
}

export default {
  validate,
};
