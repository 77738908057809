<template>
  <div>
    <cookies-container />
  </div>
</template>

<script>
import CookiesContainer from '../components/containers/CookiesContainer.vue';

export default {
  components: { CookiesContainer },
  name: 'cookies',
};
</script>
