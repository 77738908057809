<template>
  <div class="h-100 w-100">
    <div class="login__container">
      <div class="login__background">
        <v-img
          :src="urlImg"
          height="100%"
          :aspect-ratio="16/9"
        />
      </div>
      <div class="login__form">
        <loading-page />
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="8">
              <slot />
            </v-col>
          </v-row>
        </v-container>
        <v-footer
          padless
          absolute
        >
          <div class="footer-legacy">
            <a
              @click="goToBlank('privacy-policy')"
            >
              {{ $t('menus.privacy_policy') }}
            </a>
            <p>|</p>
            <a
              @click="goToBlank('legal-notice')"
            >
              {{ $t('menus.legal_notice') }}
            </a>
            <p>|</p>
            <a
              @click="goToBlank('cookies')"
            >
              {{ $t('menus.cookies') }}
            </a>
          </div>
        </v-footer>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from '@/components/base/loading/LoadingPage.vue';

const URL_IMG_BG = require('@/assets/img/bg_login.png');

export default {
  name: 'anonymous-layout',
  provide: {
    urlLogo: null,
    heightLogo: null,
    positionLogo: null,
    classLogo: 'login__brand',
    hasAccessLoginLayoutB2B: false,
  },
  components: {
    'loading-page': LoadingPage,
  },
  data() {
    return {
      urlImg: URL_IMG_BG,
    };
  },
  methods: {
    goToBlank(route) {
      const routeToGo = this.$router.resolve({ name: route });
      window.open(routeToGo.href, '_blank');
    },
  },
  beforeMount() {
    const lang = navigator.language || navigator.userLanguage;
    const langcode = lang.split('-')[0];
    this.$i18n.locale = langcode;
  },
};
</script>
