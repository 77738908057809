const addEquipments = ({ commit }, equipments) => {
  commit('ADD_EQUIPMENTS', equipments);
};

const resetEquipments = ({ commit }) => {
  commit('RESET_EQUIPMENTS');
};

export default {
  addEquipments,
  resetEquipments,
};
