export default ({
  legalDocumentsRepository,
  documentViewer,
}) => async (
  documentId, typeDocument, lang, nameDocument, clientId, isAdmin, adminLayoutId) => {
  try {
    const urlView = await legalDocumentsRepository.viewDocument(
      documentId, typeDocument, lang, clientId, isAdmin, adminLayoutId,
    );
    documentViewer.setDocumentInformation({ url: urlView.url, name: nameDocument });
    documentViewer.showDocumentViewer();
    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
