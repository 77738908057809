<template>
  <div class="text-center upload-files">
    <vue2Dropzone
      ref="uploadFilesDropzone"
      id="upload-files__dropzone"
      :options="options"
      @vdropzone-file-added="filesAdded"
      @vdropzone-queue-complete="completedFilesAdded"
      :useCustomSlot="true"
      class="text-center"
    >
      <p v-if="fromTask">
        {{ $t('uploadFile.from_task') }}
      </p>
      <div v-else>
        <v-icon class="upload-files__dropzone__icon material-icons-round">file_upload</v-icon>
        <p
          v-if="!isDeutschLanguage"
        >
          <strong>{{ $t('form.upload_document.upload_action_drag&drop') }}</strong>
          {{ $t('form.upload_document.upload_action') }}
        </p>
        <p
         v-else
        >
          {{ $t('form.upload_document.upload_action') }}
          <strong>{{ $t('form.upload_document.upload_action_drag&drop') }}</strong>

        </p>
        <p><strong>{{ $t('form.upload_document.upload_or') }}</strong></p>
        <v-btn
          color="primary"
          rounded
          class="mb-0"
        >
          {{ $t('form.upload_document.upload_search_files') }}
        </v-btn>
        <p>{{ $t('form.upload_document.upload_file_size', { size:'10MB' }) }}</p>
      </div>
    </vue2Dropzone>
    <div class="dropzone-previews" id="tpl"/>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const DEUTSCH_CODE = 'de';

export default {
  name: 'upload-file',
  components: {
    vue2Dropzone,
  },
  props: {
    filesToUpdated: {
      type: Array,
      default: () => [],
    },
    fromTask: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      return {
        url: 'https://httpbin.org/post', // randomurl - without url the component broken
        autoProcessQueue: false,
        thumbnailWidth: 200,
        uploadMultiple: true,
        previewsContainer: 'div.dropzone-previews',
      };
    },
    isDeutschLanguage() {
      return this.$i18n.locale === DEUTSCH_CODE;
    },
  },
  data() {
    return {
      totalUploadFiles: 0,
    };
  },
  methods: {
    async filesAdded(file) {
      this.$emit('startCatchFiles');
      await this.filesToUpdated.push(file);
      this.completedFilesAdded();
    },
    completedFilesAdded() {
      this.totalUploadFiles += 1;
      if (this.totalUploadFiles === this.filesToUpdated.length) {
        this.$emit('uploadFiles', this.filesToUpdated);
        this.totalUploadFiles = 0;
      }
    },
  },
};
</script>
