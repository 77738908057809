import AbstractHeadquarterRepresentation
  from '../../../domain/headquarter/AbstractHeadquarterRepresentation';

export default class HeadquartersRepresentation extends AbstractHeadquarterRepresentation {
  _translator;

  _normalization;

  _addressComplete = null;

  _nameNormalized = null;

  _addressNormalized = null;

  _securityMeasuresTranslations = null;

  constructor(translator, normalization) {
    super();
    this._translator = translator;
    this._normalization = normalization;
  }

  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get nameNormalized() {
    if (!this._nameNormalized) {
      this._nameNormalized = this._normalization.normalizeString(this._name.toString());
    }

    return this._nameNormalized;
  }

  get address() {
    if (!this._addressComplete) {
      this._addressComplete = `${this._address.toString()}; ${this.location} (${this.country})`;
    }
    return this._addressComplete;
  }

  get addressNormalized() {
    if (this._addressNormalized === null) {
      this._addressNormalized = this._normalization.normalizeString(this.address);
    }

    return this._addressNormalized;
  }

  get country() {
    return this._translator.translate(`countries.by_code.${this._country.toString()}`);
  }

  get location() {
    return this._locality.toString();
  }

  get securityMeasures() {
    if (!this._securityMeasuresTranslations) {
      this._securityMeasuresTranslations = [];
      if (this._securityMeasures) {
        this._securityMeasures.forEach((element) => {
          this._securityMeasuresTranslations.push(this._translator.translate(`headquarters.security_measures.${element}`));
        });
      }
    }

    return this._securityMeasuresTranslations.join(', ');
  }
}
