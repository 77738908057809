import {
  deleteMethodManagement, getManagement, postManagement, putManagement,
} from '@/shared/restActions';
import HeadquarterRepository from '@/domain/headquarter/HeadquarterRepository';
import Headquarter from '@/domain/headquarter/Headquarter';
import HeadquarterId from '@/domain/headquarter/HeadquarterId';
import HeadquarterName from '@/domain/headquarter/HeadquarterName';
import HeadquarterAddress from '@/domain/headquarter/HeadquarterAddress';
import Country from '@/domain/country/Country';
import HeadquarterLocality from '@/domain/headquarter/HeadquarterLocality';
import ClientId from '@/domain/client/ClientId';
import HeadquarterSecurityMeasures
  from '@/domain/headquarter/headquarterSecurityMeasures/HeadquarterSecurityMeasures';
import HeadquarterCache from './HeadquarterCache';
import HeadquarterRepresentationApi from './HeadquarterRepresentationApi';

export default class HeadquarterRepositoryApi extends HeadquarterRepository {
  async ofClient(clientId) {
    try {
      let headquarters = HeadquarterCache.getHeadquarters();

      if (headquarters.length) {
        return headquarters;
      }

      const headquartersRaw = await this._getHeadquartersFromApi(clientId.toInt());
      headquarters = this._buildHeadquarters(headquartersRaw, clientId.toInt());
      HeadquarterCache.setHeadquarters(headquarters);

      return headquarters;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching headquarters');
    }
  }

  async save(headquarter) {
    try {
      const headquarterRepresentation = headquarter.representedAs(
        new HeadquarterRepresentationApi(),
      );
      await postManagement('/api/v2/headquarters', headquarterRepresentation.toJson());
      HeadquarterCache.addHeadquarter(headquarter);
    } catch (e) {
      console.error(e);
      throw Error('Error saving headquarter');
    }
  }

  async _getHeadquartersFromApi(clientId) {
    try {
      const response = await getManagement(`/api/v2/clients/${clientId}/headquarters`);
      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  _buildHeadquarters(headquartersRaw, clientId) {
    const headquarters = [];
    headquartersRaw.forEach((headquarterRaw) => {
      const headquarter = new Headquarter(
        HeadquarterId.fromString(headquarterRaw.id),
        ClientId.fromInt(clientId),
        new HeadquarterName(headquarterRaw.name),
        new HeadquarterAddress(headquarterRaw.address),
        new Country(headquarterRaw.country),
        new HeadquarterLocality(headquarterRaw.locality),
      );

      headquarter.addSecurityMeasures(
        headquarterRaw.security_measures.map(
          (securityMeasure) => new HeadquarterSecurityMeasures(securityMeasure),
        ),
      );

      headquarters.push(headquarter);
    });

    return headquarters;
  }

  async delete(headquartersId) {
    try {
      await deleteMethodManagement(`/api/v2/headquarters/${headquartersId.toString()}`);
      HeadquarterCache.removeHeadquarters(headquartersId);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting headquarters');
    }
  }

  async retrieve(headquartersId) {
    const headquarter = HeadquarterCache.getHeadquarterById(headquartersId);

    if (!headquarter) {
      throw new Error('Error fetching headquarter');
    }
    return headquarter;
  }

  async update(headquarter) {
    try {
      const headquarterRepresentation = headquarter.representedAs(
        new HeadquarterRepresentationApi(),
      );
      await putManagement(`/api/v2/headquarters/${headquarter.getId().toString()}`, headquarterRepresentation.toJson());
      HeadquarterCache.updateHeadquarter(headquarter);
    } catch (e) {
      console.error(e);
      throw new Error('Error updating the headquarters');
    }
  }
}
