import ClientUserPasswordValidationException
  from '@/domain/clientUser/ClientUserPasswordValidationException';

const REGEX_VALIDATION = /^(?=.*[a-z])(?=(.*\d)|(.*[!"#$%^'()*+´\-./:;<=>?@[\\\]^_`{|}])).{8,}$/i;

export default class ClientUserPassword {
  private readonly password: string;

  protected constructor(password: string) {
    const passwordWithoutSpaces = password.trim();
    ClientUserPassword.isValidOrFail(passwordWithoutSpaces);
    this.password = passwordWithoutSpaces;
  }

  private static isValidOrFail(password: string) {
    if (!REGEX_VALIDATION.test(password)) {
      throw new ClientUserPasswordValidationException(password);
    }
  }

  public toString(): string {
    return this.password;
  }

  public static fromString(password: string) {
    return new this(password);
  }
}
