<template>
  <v-row>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="searchEmployee"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right multiple-button">
      <download-document-button
        v-if="showDownloadButton"
        :id="user.clientId"
        errorMsg="employee.download.error"
        :btnMsg="$t('employee.download.button')"
        docType="employees-list"
        actionTrack="Download employees registry"
      />
       <create-employee
        :showNif="showNif"
      />
    </v-col>
    <v-col cols="12">
      <list-employees
        :search.sync="searchEmployee"
        :showNif="showNif"
      />
    </v-col>
  </v-row>
</template>

<script>
import employeesUseCases from '@/application/employee';
import documentUseCases from '@/application/document';
import documentDataEmployeeUseCases from '@/application/documentData/employee';
import countryUseCases from '@/application/country';
import processingDatesServices from '@/services/shared/processingDates';
import DownloadDocumentButton from '../../../base/section/DownloadDocumentButton.vue';
import ListEmployees from './ListEmployees.vue';
import InputSearch from '../../../base/form/InputSearch.vue';
import CreateEmployee from './CreateEmployee.vue';

export default {
  name: 'employees-section',
  provide: {
    ...documentUseCases,
    ...employeesUseCases,
    ...documentDataEmployeeUseCases,
    ...processingDatesServices,
  },
  inject: {
    user: 'user',
  },
  components: {
    DownloadDocumentButton,
    ListEmployees,
    InputSearch,
    CreateEmployee,
  },
  props: {
    currentTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchEmployee: '',
      showDownloadButton: false,
      showNif: false,
    };
  },
  methods: {
    mustBeShowDownloadButton(employeesLength) {
      this.showDownloadButton = !!employeesLength && this.currentTab === 'employee';
    },
  },
  beforeMount() {
    employeesUseCases.resetEmployees.invoke();
    this.showNif = countryUseCases.mandatoryNifByCountry(this.user.countryId);
    this.$eventHub.$on('employeesLoaded', this.mustBeShowDownloadButton);
  },
  beforeDestroy() {
    this.$eventHub.$off('employeesLoaded', this.mustBeShowDownloadButton);
  },
};
</script>
