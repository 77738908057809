<template>
  <v-snackbar
    v-model="snackBar.show"
    color="white"
    class="alert-snack-bar mt-4 mr-6"
    right
    top
    :timeout="properties[snackBar.type]['delay']"
  >
    <div class="alert-snack-bar__content">
      <v-icon
        :color="properties[snackBar.type]['color']"
        class="material-icons-round"
      >
        {{ properties[snackBar.type]['icon'] }}
      </v-icon>
      <p
        v-if="!hasMoreThanOneText"
        class="mt-2 mx-4 alert-snack-bar__text pr-2 mb-0 font-weight-bold"
      >
        {{ $t(`${textAlert}`, snackBar.parameters) }}
      </p>
      <p
        v-else
        class="mx-4 alert-snack-bar__text pr-2 mb-0"
      >
      <span
        v-for="(text, index) in snackBar.text"
        :key="index"
        :class="{ 'font-weight-bold': index === 0 }"
      >
        {{ $t(`${text}`) }}
        <br v-if="index !== snackBar.length - 1" />
      </span>
      </p>
    </div>
    <v-btn
      v-if="properties[snackBar.type]['close']"
      icon
      x-small
      color="grey lighten-1"
      class="pl-4"
      @click="hideSnackBar"
    >
      <v-icon>
        close
      </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'alert-snack-bar',
  computed: {
    ...mapGetters('shared', {
      snackBar: 'snackBar',
    }),
    properties() {
      return {
        error: {
          color: 'error',
          icon: 'error',
          close: true,
          delay: 8000,
        },
        success: {
          color: 'success',
          icon: 'check_circle',
          close: false,
          delay: 5000,
        },
        warning: {
          color: 'warning',
          icon: 'warning',
          close: true,
          delay: 8000,
        },
      };
    },
    hasMoreThanOneText() {
      return Array.isArray(this.snackBar.text);
    },
    textAlert() {
      if (this.snackBar.default === null) {
        return this.snackBar.text;
      }
      return this.$te(this.snackBar.text) ? this.snackBar.text : this.snackBar.default;
    },
  },
  methods: {
    hideSnackBar() {
      this.$hideSnackBar();
    },
  },
  beforeMount() {
    this.hideSnackBar();
  },
};
</script>
