import store from '../../store';

export default class ProcessingActivityFormCache {
  static setProcessingActivity(processingActivity) {
    store.dispatch('processingActivity/saveProcessingActivity', processingActivity);
  }

  static setFormStatus(formStatus) {
    store.dispatch('processingActivity/saveFormStatus', formStatus);
  }

  static getProcessingActivity() {
    return store.getters['processingActivity/processingActivity'];
  }

  static getFormStatus() {
    return store.getters['processingActivity/formStatus'];
  }

  static resetProcessingActivity() {
    store.dispatch('processingActivity/resetProcessingActivity');
  }

  static resetFormStatus() {
    store.dispatch('processingActivity/resetFormStatus');
  }
}
