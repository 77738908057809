<template>
  <user-validated-container
    :token="token"
  />
</template>

<script>
import UserValidatedContainer from '@/components/containers/UserValidatedContainer.vue';

export default {
  name: 'user-validated',
  components: { UserValidatedContainer },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
};
</script>
