<template>
  <form-client-user
    :userIsBeingSaving.sync="userIsBeingSaving"
    @saveUser="createUser"
  />
</template>
<script>
import FormClientUser from '@/components/modules/clientUsers/FormClientUser.vue';
import CreateClientUserCommand
  from '@/application/clientUser/createClientUser/CreateClientUserCommand';

export default {
  name: 'add-user-section',
  inject: {
    user: 'user',
    createClientUser: 'createClientUser',
  },
  components: {
    FormClientUser,
  },
  data() {
    return {
      userIsBeingSaving: false,
    };
  },
  methods: {
    async createUser(user) {
      this.userIsBeingSaving = true;

      const response = await this.createClientUser.invoke(new CreateClientUserCommand(
        this.user.clientId,
        user.type,
        user.name,
        user.surname,
        user.email_init,
        user.email_repeat,
        user.locale,
        user.departments,
      ));

      this.$track({ action: 'Create Single User -- finish', result: response.success });
      this.userIsBeingSaving = false;
      if (!response.success) {
        this.$showError(
          `clientUsers.error.${response.error}`,
          'clientUsers.error.create_client',
        );
        return;
      }

      this.$eventHub.$emit('anUserWasCreated');
      this.$showSuccess('clientUsers.success.create_client');
    },

  },
};
</script>
