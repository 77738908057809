import AbstractProcessingActivityModelRepresentation from '../AbstractProcessingActivityModelRepresentation';

export default class ProcessingActivityModelRepresentation
  extends AbstractProcessingActivityModelRepresentation {
  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get country() {
    return this._countryId;
  }

  get language() {
    return this._languageCode;
  }

  get departmentKey() {
    return this._department;
  }
}
