/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
const generateId = () => Math.trunc(Math.random() * 1000);

/**
 * @class DiagramNode
 */
export class DiagramNode {
  /**
   *  This should not be called directly. Use the "addNode" method from the DiagramModel class
   * @param  {String} title  [description]
   * @param  {Integer} x      [description]
   * @param  {Integer} y      [description]
   * @param  {Integer} width  [description]
   * @param  {Integer} height [description]
   * @param  {Integer} id [description]
   */
  constructor(
    id,
    title,
    x,
    y,
    width,
    height,
    type,
    icon,
    class_bottom,
    text_top,
    text_bottom = null,
    tooltip = null,
  ) {
    this.title = typeof title === 'undefined' ? '' : title;
    this.tooltip = tooltip;
    this.type = type;
    this.icon = icon;
    this.class_bottom = typeof class_bottom === 'undefined' ? '' : class_bottom;
    this.text_top = text_top;
    this.text_bottom = text_bottom;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.ports = [];
  }

  /**
   * Adds a new "in" port into the node.
   * @param {String} name
   * @return {Integer} The port id
   */
  addInPort(name, node_id) {
    let newPort = {
      id: node_id,
      type: 'in',
      name,
    };

    this.ports.push(newPort);

    return newPort.id;
  }

  /**
   * Adds a new "out" port into the node.
   * @param {String} name
   * @return {Integer} The port id
   */
  addOutPort(name, node_id) {
    let newPort = {
      id: node_id,
      type: 'out',
      name,
    };

    this.ports.push(newPort);

    return newPort.id;
  }
}

export default DiagramNode;
