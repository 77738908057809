<template>
  <div>
    <a @click="getHeadquarterAndShowForm">
      {{ headquarter.name }}
    </a>
    <dialog-form-headquarter
      v-if="modalIsOpen"
      :show-modal.sync="modalIsOpen"
      :dataHeadquarter.sync="headquarterToEdit"
      @saveHeadquarters="updateHeadquarters"
    />
  </div>
</template>

<script>
import DialogFormHeadquarter from './form/DialogFormHeadquarter.vue';

export default {
  name: 'edit-headquarter',
  inject: {
    fetchHeadquarter: 'fetchHeadquarter',
    updateHeadquarter: 'updateHeadquarter',
  },
  components: {
    DialogFormHeadquarter,
  },
  props: {
    headquarter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headquarterToEdit: {},
      modalIsOpen: false,
    };
  },
  methods: {
    async getHeadquarterAndShowForm() {
      this.$showLoading();
      const response = await this.fetchHeadquarter(this.headquarter.id);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('headquarters.edit.error_load');
        return;
      }
      this.headquarterToEdit = response.headquarter;
      this.modalIsOpen = true;
    },
    async updateHeadquarters(headquarter) {
      this.$showLoading();
      const response = await this.updateHeadquarter(headquarter, this.headquarterToEdit.id);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('headquarters.edit.error');
        return;
      }

      this.$showSuccess('headquarters.edit.success');
      this.$eventHub.$emit('headquartersWasUpdated');
      this.modalIsOpen = false;
    },
  },
};
</script>
