import { Store } from 'vuex';

export default class Loading {
  private vue: any;

  private store: Store<any>;

  constructor(store: Store<any>) {
    this.store = store;
  }

  public install(Vue: any) {
    this.vue = Vue;
    this.addPrototypes();
  }

  private addPrototypes(): void {
    this.vue.prototype.$showLoading = (): void => {
      this.store.dispatch('shared/showLoadingPage', {});
    };

    this.vue.prototype.$hideLoading = (): void => {
      this.store.dispatch('shared/hideLoadingPage', {});
    };
  }
}
