import DataBreachCodeValidationException
  from '@/domain/dataBreach/DataBreachCodeValidationException';

const CODE_FORMAT = /^\d{4}-\d{3}$/;

export default class DataBreachCode {
  private readonly code: string;

  protected constructor(code: string) {
    DataBreachCode.isValidOrFail(code);
    this.code = code;
  }

  private static isValidOrFail(code: string): void {
    if (!code.match(CODE_FORMAT)) {
      throw new DataBreachCodeValidationException(`${this.constructor.name} is not valid`);
    }
  }

  public toString(): string {
    return this.code;
  }

  public static fromString(code: string): DataBreachCode {
    return new this(code);
  }
}
