import AbstractRecipientRepresentation from '@/domain/recipient/AbstractRecipientRepresentation';

export default class RecipientToUpdateRepresentation extends AbstractRecipientRepresentation {
  get name() {
    return this._name;
  }

  get businessName() {
    return this._businessName;
  }

  get status() {
    return this._status;
  }

  get category() {
    return this._category;
  }

  get country() {
    return this._country;
  }

  get state() {
    return this._state;
  }

  get role() {
    return this._role;
  }

  get warranties() {
    return this._warranties;
  }

  get dpa() {
    return this._dpa;
  }

  get dpaStatus() {
    return this._dpaStatus;
  }

  get lastModification() {
    return this._lastModification;
  }

  get documents() {
    return this._documents;
  }
}
