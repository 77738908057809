import ChangeClientSelectedCommand
  from '@/application/client/changeClientSelected/ChangeClientSelectedCommand';
import Client from '@/domain/client/Client';
import ClientId from '@/domain/client/ClientId';
import ChangeSelectedClient from '@/domain/client/ChangeSelectedClient';
import ClientRepository from '@/domain/client/ClientRepository';
import EventBus from '@/application/eventBus/EventBus';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';

export default class ChangeClientSelectedCommandHandler {
  private readonly clientRepository: ClientRepository;

  private readonly changeSelectedClient: ChangeSelectedClient;

  private readonly eventBus: EventBus;

  constructor(
    clientRepository: ClientRepository,
    changeSelectedClient: ChangeSelectedClient,
    eventBus: EventBus,
  ) {
    this.clientRepository = clientRepository;
    this.changeSelectedClient = changeSelectedClient;
    this.eventBus = eventBus;
  }

  public async invoke(command: ChangeClientSelectedCommand): Promise<{ success: boolean }> {
    try {
      const clientId: ClientId = ClientId.fromInt(command.id);
      const client: Client | undefined = await this.clientRepository.findById(clientId);

      if (!client) {
        this.changeSelectedClient.withId(clientId);
      } else {
        this.changeSelectedClient.invoke(client);
      }

      this.eventBus.handle(ClientSelectedWasChanged.fromClientUserId(clientId));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
