import taskRepository from '@/infrastructure/task/taskRepository';
import DepartmentRepositoryApi from '@/infrastructure/department/DepartmentRepositoryApi';
import fetchPendingTasksBuilder from './fetchPendingTasks/fetchPendingTasks';
import changeTaskDepartmentBuilder
  from './editTask/changeTaskDepartment';
import resetStoreTasksBuilder from './resetStoreTasks';

export default {
  fetchPendingTasks: fetchPendingTasksBuilder({ taskRepository }),
  resetStoreTasks: resetStoreTasksBuilder({ taskRepository }),
  changeTaskDepartment: changeTaskDepartmentBuilder({
    taskRepository,
    departmentRepository: new DepartmentRepositoryApi(),
  }),
};
