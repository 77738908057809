const addModels = ({ commit }, models) => {
  commit('ADD_MODELS', models);
};

const saveProcessingActivity = ({ commit }, processingActivity) => {
  commit('SAVE_PROCESSING_ACTIVITY', processingActivity);
};

const saveFormStatus = ({ commit }, formStatus) => {
  commit('SAVE_FORM_STATUS', formStatus);
};

const resetProcessingActivity = ({ commit }) => {
  commit('RESET_PROCESSING_ACTIVITY');
};

const resetFormStatus = ({ commit }) => {
  commit('RESET_FORM_STATUS');
};

export default {
  addModels,
  saveProcessingActivity,
  saveFormStatus,
  resetProcessingActivity,
  resetFormStatus,
};
