<template>
  <div class="h-100 w-100">
    <title-section
      :title="$t('processingActivities.title')"
      :subtitle="$t('processingActivities.subtitle')"
    >
      <v-btn
        class="btn--show-on-hover"
        color="primary"
        @click="$router.push({name: 'processing-activities'})"
      >
        <v-icon class="btn--show-on-hover__icon">
          list
        </v-icon>
        <span class="btn--show-on-hover__text">
          {{ $t('processingActivities.show_list') }}
        </span>
      </v-btn>
    </title-section>
    <data-map
      v-if="Object.keys(dataMap).length"
      :dataMap.sync="dataMap"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import processingActivitiesUseCases from '@/usecases/documents/processingActivities';

import TitleSection from '../base/section/TitleSection.vue';
import DataMap from '../modules/dataMap/DataMap.vue';

export default {
  name: 'data-map-container',
  components: {
    TitleSection,
    DataMap,
  },
  computed: {
    ...mapGetters('user', {
      companyId: 'companyId',
    }),
  },
  data() {
    return {
      dataMap: {},
    };
  },
  methods: {
    async getInfoDataMap() {
      const response = await processingActivitiesUseCases.fetchDataMap(
        this.companyId, this.$i18n.locale,
      );

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
        return {};
      }

      return response.data;
    },
  },
  async beforeMount() {
    this.$showLoading();
    this.dataMap = await this.getInfoDataMap();
    this.$hideLoading();
  },
};
</script>
