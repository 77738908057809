import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';
import DataBreachWasCreated from '@/domain/dataBreach/DataBreachWasCreated';
import DataBreachesPendingRepresentation
  from '@/application/dataBreach/pending/fetchDataBreachesPending/DataBreachesPendingRepresentation';
import DataBreach from '@/domain/dataBreach/DataBreach';

export default class DataBreachWasCreatedEventListener {
  private readonly dataBreachesPendingWriteModel: DataBreachesPendingWriteModel;

  constructor(dataBreachesPendingWriteModel: DataBreachesPendingWriteModel) {
    this.dataBreachesPendingWriteModel = dataBreachesPendingWriteModel;
  }

  async invoke(dataBreachWasCreated: DataBreachWasCreated) {
    const dataBreach: DataBreach = dataBreachWasCreated.getDataBreach();

    const dataBreachPendingRepresentation:
      DataBreachesPendingRepresentation = <DataBreachesPendingRepresentation>dataBreach
        .representedAs(new DataBreachesPendingRepresentation());

    await this.dataBreachesPendingWriteModel.addDataBreach(dataBreachPendingRepresentation);
  }
}
