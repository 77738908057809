export default class RecipientWriteModel {
  static addRecipient(recipientRepresentation) {}

  static updateRecipient(recipientRepresentation) {}

  static removeRecipient(recipientId) {}

  static deleteOneDocumentData(recipientId) {}

  static updateReplacedBy(recipientId, replacementRecipientId) {}

  static updateIsReplacement(replacementRecipientId) {}
}
