const ADD_RECIPIENTS = (state, recipients) => {
  state.recipients = recipients;
};

const RESET_RECIPIENTS = (state) => {
  state.recipients = [];
};

export default {
  ADD_RECIPIENTS,
  RESET_RECIPIENTS,
};
