<template>
  <div class="h-100 w-100">
    <div v-if="userExists">
      <logo-img
        :logoUrl="urlLogo || companyLogoUrl"
        :companyName="companyName"
        :height="heightLogo"
        :position="positionLogo"
        :classLogo="classLogo"
        titleTag="h1"
      />
      <div v-if="!confirmChangePassword">
        <ask-password
          :userEmail.sync="user.email"
          :errorFeedback.sync="errorFeedback"
          @recoverPassword="recoverPassword"
          :errorInput.sync="thereIsPasswordError"
        />
      </div>
      <div v-else>
        <confirm-change-password />
      </div>
    </div>
    <div v-else>
      <change-password-token-error />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import formValidationService from '@/services/validations/formValidation';
import userUseCases from '@/usecases/users';
import ChangePasswordTokenError from '@/components/modules/changePassword/ChangePasswordTokenError.vue';
import { changePassword } from '@/application/user';

import AskPassword from '../modules/changePassword/AskPassword.vue';
import ConfirmChangePassword from '../modules/changePassword/ConfirmChangePassword.vue';
import LogoImg from '../base/icons/LogoImg.vue';

export default {
  name: 'change-password-container',
  inject: {
    heightLogo: 'heightLogo',
    hasAccessLoginLayoutB2B: 'hasAccessLoginLayoutB2B',
    positionLogo: 'positionLogo',
    urlLogo: 'urlLogo',
  },
  components: {
    AskPassword,
    ConfirmChangePassword,
    ChangePasswordTokenError,
    LogoImg,
  },
  computed: {
    ...mapGetters('admin', {
      companyName: 'name',
      companyLogoUrl: 'logoUrl',
    }),
    classLogo() {
      if (!this.hasAccessLoginLayoutB2B) {
        return 'login__brand';
      }

      return this.confirmChangePassword ? 'mb-0' : 'mb-10';
    },
  },
  props: ['token'],
  data() {
    return {
      user: {},
      errorFeedback: [],
      thereIsPasswordError: false,
      confirmChangePassword: false,
      userExists: false,
    };
  },
  methods: {
    async recoverPassword(password) {
      this.$showLoading();
      this.errorFeedback = this.checkForm(password);

      if (this.errorFeedback.length === 0) {
        const response = await changePassword.invoke(this.token, password);
        this.showAlertResponse(response);
      }

      this.$hideLoading();
    },
    showAlertResponse(response) {
      if (response.success) {
        this.confirmChangePassword = true;
      } else {
        this.$showError(
          `changePassword.exception.${response.data}`,
          'changePassword.exception.generic',
        );
      }
    },
    checkForm(password) {
      const textErrors = [];
      this.thereIsPasswordError = false;

      const emptyPassword = formValidationService.isEmptyField(password);
      const validPassword = formValidationService.isValidPassword(password);

      if (emptyPassword) {
        textErrors.push(this.$t('changePassword.error.required_field_password'));
        this.thereIsPasswordError = true;
      } else if (!validPassword) {
        textErrors.push(this.$t('changePassword.error.incorrect_password'));
        this.thereIsPasswordError = true;
      }

      return textErrors;
    },
    async loadUserByToken() {
      const response = await userUseCases.getInfoByToken(this.token);
      if (response) {
        this.user = response;
        this.userExists = true;
      }
    },
  },
  beforeMount() {
    this.$showLoading();

    this.loadUserByToken();
    this.$i18n.locale = this.user.locale;

    this.$hideLoading();
  },
};
</script>
