import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachesHistoricRepresentation
  from '@/application/dataBreach/historic/fetchDataBreaches/DataBreachesHistoricRepresentation';
import store from '../../../store';

export default class DataBreachHistoricCache {
  public static reset(): void {
    store.dispatch('dataBreach/resetDataBreachesHistoric');
  }

  public static saveAll(dataBreaches: DataBreachesHistoricRepresentation[]): void {
    const dataBreachList: { [key: string]: any } = {};

    dataBreaches.forEach((dataBreach: DataBreachesHistoricRepresentation) => {
      dataBreachList[dataBreach.id] = dataBreach;
    });

    store.dispatch('dataBreach/addDataBreachesHistoric', dataBreachList);
  }

  public static all(): DataBreachesHistoricRepresentation[] {
    return Object.values(store.getters['dataBreach/dataBreachesHistoric']);
  }

  public static retrieve(dataBreachId: DataBreachId): DataBreachesHistoricRepresentation {
    const dataBreaches = store.getters['dataBreach/dataBreachesHistoric'];
    return dataBreaches[dataBreachId.toString()];
  }
}
