<template>
  <v-row>
    <v-col cols="12">
      <pending-data-breaches />
    </v-col>
    <v-col cols="12">
      <create-data-breach />
    </v-col>
    <v-col cols="12">
      <faqs-data-breach />
    </v-col>
  </v-row>
</template>

<script>
import CreateDataBreach from '@/components/modules/dataBreach/notify/CreateDataBreach.vue';
import PendingDataBreaches from '@/components/modules/dataBreach/notify/PendingDataBreaches.vue';
import FaqsDataBreach from '@/components/modules/dataBreach/notify/FaqsDataBreach.vue';
import DataBreachUsesCase from '@/application/dataBreach';
import PendingDataBreachUsesCase from '@/application/dataBreach/pending';
import processingDatesServices from '@/services/shared/processingDates';
import { addDataBreachCollaborator } from '@/infrastructure/application/dataBreach';

export default {
  name: 'notify-section',
  provide: {
    ...DataBreachUsesCase,
    ...PendingDataBreachUsesCase,
    processingDatesServices,
    addDataBreachCollaborator,
  },
  components: {
    CreateDataBreach,
    PendingDataBreaches,
    FaqsDataBreach,
  },
  beforeMount() {
    DataBreachUsesCase.resetDataBreaches.invoke();
  },
};
</script>
