import InscriptionStatus from '@/domain/training/inscription/InscriptionStatus';
import Course from '@/domain/training/course/Course';
import InscriptionId from '@/domain/training/inscription/InscriptionId';
import InscriptionCompletedDate
  from '@/domain/training/inscription/InscriptionCompletedDate';
import InscriptionRepresentation
  from '@/domain/training/inscription/InscriptionRepresentation';

export default class Inscription {
  private readonly status: InscriptionStatus;

  private readonly course: Course;

  private readonly id: InscriptionId;

  private completedDate: InscriptionCompletedDate | null = null;

  constructor(status: InscriptionStatus, course: Course, id: InscriptionId) {
    this.status = status;
    this.course = course;
    this.id = id;
  }

  public addCompletedDate(date: InscriptionCompletedDate) {
    this.completedDate = date;
  }

  public getStatus() {
    return this.status;
  }

  public getNameCourse() {
    return this.course.getName();
  }

  public getId() {
    return this.id;
  }

  public representedAs(
    inscriptionRepresentation: InscriptionRepresentation,
  ): InscriptionRepresentation {
    return inscriptionRepresentation.setId(this.id)
      .setCourse(this.course)
      .setStatus(this.status)
      .setCompletedDate(this.completedDate);
  }
}
