<template>
  <div>
    <v-btn
      outlined
      rounded
      color="primary"
      @click="showModalDocumentData"
    >
      {{ $t('recipient.table.document_data', {'num': item.totalDocuments}) }}
    </v-btn>
    <v-dialog
      v-if="showModal"
      v-model="showModal"
      persistent
      max-width="60%"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h2>
         {{ $t('recipient.document_data.title', { recipient: item.name }) }}
          </h2>
          <v-spacer />
          <v-btn
            icon
            @click="hideModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" offset-md="6" class="text-right">
              <v-btn
                color="primary"
                rounded
                @click="downloadAllRecipientDocuments"
              >
                {{ $t('recipient.document_data.download_all') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <list-document-data-recipient
                :recipient="item"
                @downloadDocumentRecipient="downloadDocumentRecipient"
                @setInfoAndShowBanner="setInfoAndShowBannerDeletion"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-show="!showBannerFooterDeletion"
          class="text-right"
        >
          <v-spacer/>
          <v-btn
            rounded
            color="primary"
            @click="hideModal"
          >
            {{ $t('recipient.document_data.close') }}
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <banner-footer-deletion
            v-show="showBannerFooterDeletion"
            :show.sync="showBannerFooterDeletion"
            :id="documentDataRecipient.id"
            :fileName="documentDataRecipient.name"
            :methodDeleteUseCase="methodDeleteUseCase"
          />
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import BannerFooterDeletion from '@/components/base/section/BannerFooterDeletion.vue';
import ListDocumentDataRecipient from './ListDocumentDataRecipient.vue';

export default {
  name: 'document-data-recipient-modal',
  inject: {
    deleteDocumentDataRecipient: 'deleteDocumentDataRecipient',
    downloadDocument: 'downloadDocument',
    downloadDocumentsOfRecipient: 'downloadDocumentsOfRecipient',
  },
  components: {
    ListDocumentDataRecipient,
    BannerFooterDeletion,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showBannerFooterDeletion: false,
      documentDataRecipient: {},
      methodDeleteUseCase: async () => {
        const response = await this.deleteDocumentDataRecipient(
          this.documentDataRecipient.id,
          this.item.id,
        );
        if (!response.success) {
          this.$showError('recipient.document_data.delete.error');

          return;
        }

        this.$showSuccess('recipient.document_data.delete.success');
        this.showBannerFooterDeletion = false;
        this.$eventHub.$emit('documentDataRecipientWasDeleted');
      },
    };
  },
  methods: {
    hideModal() {
      this.showBannerFooterDeletion = false;
      this.showModal = false;
    },
    showModalDocumentData() {
      this.showBannerFooterDeletion = false;
      this.documentDataRecipient = {};
      this.showModal = true;
    },
    setDataDocumentData(document) {
      this.documentDataRecipient = document;
    },
    async downloadAllRecipientDocuments() {
      this.$showLoading();
      const response = await this.downloadDocumentsOfRecipient(this.item.id);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('recipient.document_data.download_all_error');
      }
    },
    async downloadDocumentRecipient(item) {
      this.$showLoading();
      const response = await this.downloadDocument.invoke('contract-recipient', item.id);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('recipient.document_data.download.error');
      }
    },
    async setInfoAndShowBannerDeletion(item) {
      this.showBannerFooterDeletion = false;
      await this.setDataDocumentData(item);
      this.showBannerFooterDeletion = true;
    },
  },
};
</script>
