function getInitDiagram() {
  return {
    activities: [
      {
        activity: {
          id: 1,
          title: 'Actividad de I',
          x: null,
          y: null,
          icon: null,
          class_bottom: null,
        },
        dataflows_multidestino: [
          {
            origen: {
              id: 1,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'self-generated',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 2,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
              {
                id: 3,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
              {
                id: 4,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
            ],
          },
          {
            origen: {
              id: 5,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'data-processor',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 6,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
              {
                id: 7,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
            ],
          },
          {
            origen: {
              id: 8,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'data-controller',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 9,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
            ],
          },
          {
            origen: {
              id: 10,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'other-processing-activity',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 11,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
            ],
          },
        ],
        dataflows_multiorigen: [
          {
            destino: {
              id: 31,
              title: 'DESTINO',
              x: null,
              y: null,
              icon: null,
              class_bottom: 'interno',
              text_top: 'Departamento de RRHH',
              text_bottom: 'Jefe de departamento',
            },
            origenes: [
              {
                id: 32,
                title: 'ORIGEN',
                x: null,
                y: null,
                icon: 'data-subject-directly',
                class_bottom: 'no-sensitive',
                text_top: null,
                text_bottom: 'Data subject directly',
              },
              {
                id: 33,
                title: 'ORIGEN',
                x: null,
                y: null,
                icon: 'public-source',
                class_bottom: 'sensitive',
                text_top: null,
                text_bottom: 'Self generated',
              },
            ],
          },
        ],
      },
      {
        activity: {
          id: 2,
          title: 'Actividad de II',
          x: null,
          y: null,
          icon: null,
          class_bottom: null,
        },
        dataflows_multidestino: [
          {
            origen: {
              id: 101,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'self-generated',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 102,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
              {
                id: 103,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
              {
                id: 104,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
            ],
          },
          {
            origen: {
              id: 105,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'data-processor',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 106,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
              {
                id: 107,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento TEST',
              },
            ],
          },
          {
            origen: {
              id: 108,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'data-controller',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 109,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
            ],
          },
          {
            origen: {
              id: 110,
              title: 'ORIGEN',
              x: null,
              y: null,
              icon: 'other-processing-activity',
              class_bottom: 'sensitive',
              text_top: null,
              text_bottom: 'Self generated',
            },
            destinos: [
              {
                id: 111,
                title: 'DESTINO',
                x: null,
                y: null,
                icon: null,
                class_bottom: 'interno',
                text_top: 'Departamento de RRHH',
                text_bottom: 'Jefe de departamento',
              },
            ],
          },
        ],
        dataflows_multiorigen: [
          {
            destino: {
              id: 131,
              title: 'DESTINO',
              x: null,
              y: null,
              icon: null,
              class_bottom: 'interno',
              text_top: 'Departamento de RRHH',
              text_bottom: 'Jefe de departamento',
            },
            origenes: [
              {
                id: 132,
                title: 'ORIGEN',
                x: null,
                y: null,
                icon: 'data-subject-directly',
                class_bottom: 'no-sensitive',
                text_top: null,
                text_bottom: 'Data subject directly',
              },
              {
                id: 133,
                title: 'ORIGEN',
                x: null,
                y: null,
                icon: 'public-source',
                class_bottom: 'sensitive',
                text_top: null,
                text_bottom: 'Self generated',
              },
            ],
          },
        ],
      },
    ],
  };
}

export default {
  getInitDiagram,
};
