export default class AbstractRecipientRepresentation {
  _id;

  _clientId;

  _name;

  _businessName;

  _country;

  _state;

  _status;

  _category;

  _role;

  _warranties;

  _dpa;

  _dpaStatus;

  _lastModification;

  _documents;

  _recipientModel;

  _replacedBy;

  _isReplacement;

  _processingActivities;

  setId(id) {
    this._id = id;
  }

  setClientId(id) {
    this._clientId = id;
  }

  setName(name) {
    this._name = name;
  }

  setBusinessName(businessName) {
    this._businessName = businessName;
  }

  setStatus(status) {
    this._status = status;
  }

  setCategory(category) {
    this._category = category;
  }

  setCountry(country) {
    this._country = country;
  }

  setState(state) {
    this._state = state;
  }

  setRole(role) {
    this._role = role;
  }

  setWarranties(warranties) {
    this._warranties = warranties;
  }

  setDpa(dpa) {
    this._dpa = dpa;
  }

  setDpaStatus(dpaStatus) {
    this._dpaStatus = dpaStatus;
  }

  setLastModification(lastModification) {
    this._lastModification = lastModification;
  }

  setDocuments(documents) {
    this._documents = documents;
  }

  setRecipientModel(recipientModel) {
    this._recipientModel = recipientModel;
  }

  setReplacedBy(replacedBy) {
    this._replacedBy = replacedBy;
  }

  setIsReplacement(isReplacement) {
    this._isReplacement = isReplacement;
  }

  setProcessingActivities(processingActivities) {
    this._processingActivities = processingActivities;
  }
}
