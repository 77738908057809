<template>
  <div class="h-100 w-100">
    <v-card
      class="elevation-lighten"
    >
      <v-card-text>
        <v-row
          align="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <h2
              class="mb-2"
            >
              {{ $t("data_breach.report_title") }}
            </h2>
            <p
              class="mb-0"
            >
              {{ $t("data_breach.report_description") }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-right"
          >
            <v-btn
              color="primary"
              rounded
              class="mb-0"
              v-track="'Create data breach -- start'"
              @click="showForm = true"
            >
              {{ $t("data_breach.create.button.report_data_breach") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <dialog-form-data-breach
      v-if="showForm"
      :show-modal.sync="showForm"
      @saveDataBreach="saveDataBreach"
      @generateReport="createAndGenerateReportDataBreach"
    />
  </div>
</template>

<script>
import DialogFormDataBreach from '@/components/modules/dataBreach/notify/form/DialogFormDataBreach.vue';

const TRACK = {
  SAVE_DATA_BREACH: 'New data breach -- finish',
  SAVE_DATA_BREACH_AND_GENERATE_REPORT: 'New data breach and generate report -- finish',
};

export default {
  name: 'create-data-breach',
  inject: {
    user: 'user',
    createDataBreach: 'createDataBreach',
    createDataBreachWithReportWrapper: 'createDataBreachWithReportWrapper',
  },
  components: {
    DialogFormDataBreach,
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    async saveDataBreach(dataBreach) {
      this.$showLoading();
      const response = await this.createDataBreach.invoke(this.user.clientId, dataBreach);
      this.$track({ action: TRACK.SAVE_DATA_BREACH, result: response.success });
      this.$hideLoading();

      if (!response.success) {
        this.$showError('data_breach.errorPage.error_save_form');
        return;
      }

      this.showForm = false;
      this.$showSuccess('data_breach.form.alert_message_form_saved_success');
      this.$eventHub.$emit('dataBreachWasCreated');
    },
    async createAndGenerateReportDataBreach(dataBreach) {
      this.$showLoading();
      const response = await this.createDataBreachWithReportWrapper.invoke(
        this.user.clientId, dataBreach,
      );
      this.$track({ action: TRACK.SAVE_DATA_BREACH_AND_GENERATE_REPORT, result: response.success });
      this.$hideLoading();

      if (!response.success) {
        this.$showError('data_breach.errorPage.error_generate_report');
        return;
      }

      this.$showSuccess('data_breach.form.alert_message_form_generate_report_success');
      this.showForm = false;
      this.$eventHub.$emit('dataBreachAndReportWereCreated');
    },
  },
};
</script>
