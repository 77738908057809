<template>
  <v-row class="activity-step activity-step__model">
    <v-col cols="12">
      <h2>
        {{ $t('processing_activities.step_model.department_happening') }}
      </h2>
    </v-col>
    <v-col cols="12">
      <v-btn
        v-for="(department, key) in departments"
        :key="key"
        color="primary"
        :class="{'active': departmentActivity === department.key}"
        @click="departmentSelected(department)"
      >
        <v-icon>
          {{ getIcon(department.key) }}
        </v-icon>
        {{ department.name }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-expand-transition>
        <div
          v-show="!showModelSelected"
          class="activity-step__model--animation"
        />
      </v-expand-transition>
      <div v-show="showModelSelected">
        <div
          v-show="modelsList.length"
          class="activity-step__model--selection"
          :style="minHeightButtons"
        >
          <v-divider />
          <v-btn
            v-for="(model, key) in modelsList"
            :key="`m${key}`"
            outlined
            color="primary"
            @click="modelSelected(model)"
          >
            {{ model.name }}
          </v-btn>
        </div>
        <input-field
          :value.sync="customNameActivity"
          :label="$t('processing_activities.step_model.custom_name')"
          class="activity-step__model--name"
          @keyUpEnter="saveCustomName(customNameActivity)"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import InputField from '@/components/base/form/InputField.vue';

const ICONS = {
  human_resources: 'accessibility_new',
  marketing: 'insert_chart',
  customer_service: 'supervised_user_circle',
  administration: 'all_inbox',
  commercial: 'attach_money',
  accounting: 'calculate',
  development: 'developer_board',
  general: 'group_work',
  legal: 'gavel',
};

export default {
  name: 'model-selection',
  components: {
    InputField,
  },
  props: {
    departmentActivity: {
      type: String,
      default: '',
    },
    departments: {
      type: Array,
      default: () => [],
    },
    modelsList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showModelSelected() {
      return this.departmentActivity;
    },
  },
  data() {
    return {
      customNameActivity: '',
      minHeightButtons: '',
    };
  },
  methods: {
    setMinHeight() {
      this.minHeightButtons = {
        'min-height': `${document.getElementsByClassName('form-processing-activities')[0].offsetHeight - 645}px`,
      };
    },
    departmentSelected(department) {
      this.$emit('departmentSelected', department);
      this.$track({
        action: 'Processing activity choose department',
        department: department.name,
      });
    },
    modelSelected(model) {
      this.$emit('modelSelected', model.id);
      this.$track({
        action: 'Define processing activity',
        model: model.name,
      });
    },
    saveCustomName(customNameActivity) {
      this.$emit('saveCustomName', customNameActivity);
      this.$track({
        action: 'Introduce custom processing activity',
        custom_name: customNameActivity,
      });
    },
    getIcon(departmentKey) {
      return ICONS[departmentKey];
    },
  },
  mounted() {
    if (this.minHeightButtons === '') {
      this.setMinHeight();
    }
  },
  watch: {
    'modelsList.length': function () {
      this.setMinHeight();
    },
  },
};
</script>
