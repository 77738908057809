import { postManagement } from '@/shared/restActions';
import Login from '@/domain/user/login/Login';
import UserRaw from '@/infrastructure/user/UserRaw';
import UserCache from '@/infrastructure/user/UserCache';
import OktaLoginCredentials from '@/domain/user/login/Credentials/OktaLoginCredentials';

export default class LoginOktaApi implements Login {
  async invoke(loginCredentials: OktaLoginCredentials): Promise<string> {
    try {
      const userInfo: UserRaw = await postManagement(
        '/api/v2/login',
        { code: loginCredentials.code, okta_id: loginCredentials.oktaId },
        { loginType: 'okta' },
      );

      localStorage.setItem('user-token', userInfo.token);

      UserCache.save(userInfo);

      return userInfo.subdomain;
    } catch (e) {
      throw Error('Error login user with okta');
    }
  }
}
