import Country from '@/domain/country/Country';
import CountriesRepresentation from './CountriesRepresentation';

export default (translator) => () => {
  const countries = Country.all();
  const countriesRepresentation = countries.map((country) => country.representedAs(
    new CountriesRepresentation(translator),
  ));
  return countriesRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
