import DataBreachRepresentation from '@/domain/dataBreach/DataBreachRepresentation';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';

export default class DataBreachRawRepresentation extends DataBreachRepresentation {
  public toJson(): object {
    return {
      id: this._id.toString(),
      created_date: this._createdDate.toString(),
      occurred_on: this._occurredOn.toString(),
      discovered_on: this._discoveredOn.toString(),
      description: this._description.toString(),
      status: this._status.toString(),
      how_discovered: (this._howDiscovered) ? this._howDiscovered.toString() : null,
      was_intentional: (this._wasIntentional) ? this._wasIntentional.toString() : null,
      compromised_data: (this._compromisedData) ? this._compromisedData.toString() : null,
      affected_profiles: (this._affectedProfiles) ? this._affectedProfiles.toString() : null,
      consequences: (this._consequences) ? this._consequences.toString() : null,
      affected_countries: (this._affectedCountries) ? this._affectedCountries.toString() : null,
      number_affected: (this._numberAffected) ? this._numberAffected.toInt() : null,
      security_measures_implemented: (this._securityMeasuresImplemented)
        ? this._securityMeasuresImplemented.toString() : null,
      future_security_measures: (this._futureSecurityMeasures)
        ? this._futureSecurityMeasures.toString() : null,
      security_measures_problems: (this._securityMeasuresProblems)
        ? this._securityMeasuresProblems.toString() : null,
      informed_affected_people: (this._informedAffectedPeople)
        ? this._informedAffectedPeople.toString() : null,
      informed_authorities: (this._informedAuthorities)
        ? this._informedAuthorities.toString() : null,
      attached_documents: this._attachedDocuments.map(
        (document:DataBreachAttachedDocuments) => document.toObject(),
      ),
      removed_documents: this._attachedDocumentsToDelete.map(
        (documentId: UuidValueObject) => documentId.toString(),
      ),
      evaluation: (this._evaluation) ? this._evaluation.toString() : null,
      agency_notification: this._agencyNotification || false,
      report_language: (this._reportLanguage) ? this._reportLanguage.toString() : null,
    };
  }
}
