import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';

export default class RecipientId extends UuidValueObject {
  id;

  constructor(id) {
    super(id);
    this.id = id;
  }
}
