const addHeadquarters = ({ commit }, headquarters) => {
  commit('ADD_HEADQUARTERS', headquarters);
};

const resetHeadquarters = ({ commit }) => {
  commit('RESET_HEADQUARTERS');
};

export default {
  addHeadquarters,
  resetHeadquarters,
};
