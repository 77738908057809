export default class ResetProcessingActivityForm {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke() {
    this._processingActivityCache.resetProcessingActivity();
  }
}
