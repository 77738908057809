<template>
  <v-alert
    text
    :type="type"
    :icon="false"
  >
    <span v-if="messages.length === 1">
      {{ messages[0] }}
    </span>
    <ul v-else>
      <li
        v-for="(message, key) in messages"
        :key="key"
      >
        {{ message }}
      </li>
    </ul>
  </v-alert>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    messages: {
      type: [Array],
      default: () => [],
    },
    type: {
      type: String,
      default: 'success',
    },
  },
};
</script>
