import ClientUserWasCreated from '@/domain/clientUser/ClientUserWasCreated';
import EventListener from '@/application/eventBus/EventListener';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';

export default class ClientUserWasCreatedEventListener implements EventListener {
  private readonly employeeRepository: EmployeeRepository

  constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  handle(clientUserWasCreated: ClientUserWasCreated): void {
    this.employeeRepository.reset();
  }
}
