const COUNTRIES: {[key:string]: string} = {
  country_205: 'Spain',
  country_1: 'Afghanistan',
  country_2: 'Åland Islands',
  country_3: 'Albania',
  country_4: 'Algeria',
  country_5: 'American Samoa',
  country_6: 'Andorra',
  country_7: 'Angola',
  country_8: 'Anguilla',
  country_9: 'Antarctica',
  country_10: 'Antigua and Barbuda',
  country_11: 'Argentina',
  country_12: 'Armenia',
  country_13: 'Aruba',
  country_15: 'Australia',
  country_16: 'Austria',
  country_17: 'Azerbaijan',
  country_18: 'Bahamas',
  country_19: 'Bahrain',
  country_20: 'Bangladesh',
  country_21: 'Barbados',
  country_22: 'Belarus',
  country_23: 'Belgium',
  country_24: 'Belize',
  country_25: 'Benin',
  country_26: 'Bermuda',
  country_27: 'Bhutan',
  country_28: 'Bolivia',
  country_29: 'Bosnia and Herzegovina',
  country_30: 'Botswana',
  country_31: 'Brazil',
  country_32: 'British Indian Ocean Territory',
  country_33: 'British Virgin Islands',
  country_34: 'Brunei',
  country_35: 'Bulgaria',
  country_36: 'Burkina Faso',
  country_37: 'Burundi',
  country_38: 'Cambodia',
  country_39: 'Cameroon',
  country_40: 'Canada',
  country_42: 'Cape Verde',
  country_43: 'Caribbean Netherlands',
  country_44: 'Cayman Islands',
  country_45: 'Central African Republic',
  country_47: 'Chad',
  country_48: 'Chile',
  country_49: 'China',
  country_50: 'Christmas Island',
  country_51: 'Cocos (Keeling) Islands',
  country_52: 'Colombia',
  country_53: 'Comoros',
  country_54: 'Congo - Brazzaville',
  country_55: 'Congo - Kinshasa',
  country_56: 'Cook Islands',
  country_57: 'Costa Rica',
  country_58: "Côte d'Ivoire",
  country_59: 'Croatia',
  country_60: 'Cuba',
  country_61: 'Curaçao',
  country_62: 'Cyprus',
  country_63: 'Czech Republic',
  country_64: 'Denmark',
  country_66: 'Djibouti',
  country_67: 'Dominica',
  country_68: 'Dominican Republic',
  country_69: 'Ecuador',
  country_70: 'Egypt',
  country_71: 'El Salvador',
  country_72: 'Equatorial Guinea',
  country_73: 'Eritrea',
  country_74: 'Estonia',
  country_75: 'Ethiopia',
  country_76: 'Malvinas Islands',
  country_77: 'Faroe Islands',
  country_78: 'Fiji',
  country_79: 'Finland',
  country_80: 'France',
  country_81: 'French Guiana',
  country_82: 'French Polynesia',
  country_83: 'French Southern Territories',
  country_84: 'Gabon',
  country_85: 'Gambia',
  country_86: 'Georgia',
  country_87: 'Germany',
  country_88: 'Ghanaian',
  country_89: 'Gibraltar',
  country_90: 'Greece',
  country_91: 'Greenland',
  country_92: 'Grenada',
  country_93: 'Guadeloupe',
  country_94: 'Guam',
  country_95: 'Guatemala',
  country_96: 'Guernsey',
  country_97: 'Guinea',
  country_98: 'Guinea-Bissau',
  country_99: 'Guiana',
  country_100: 'Haiti',
  country_101: 'Honduras',
  country_102: 'Hong Kong SAR China',
  country_103: 'Hungary',
  country_104: 'Iceland',
  country_105: 'India',
  country_106: 'Indonesia',
  country_107: 'Iran',
  country_108: 'Iraq',
  country_109: 'Ireland',
  country_110: 'Isle of Man',
  country_111: 'Israel',
  country_112: 'Italy',
  country_113: 'Jamaica',
  country_114: 'Japan',
  country_115: 'Jersey',
  country_116: 'Jordan',
  country_117: 'Kazakhstan',
  country_118: 'Kenya',
  country_119: 'Kiribati',
  country_121: 'Kuwait',
  country_122: 'Kyrgyzstan',
  country_123: 'Laos',
  country_124: 'Latvia',
  country_125: 'Lebanon',
  country_126: 'Lesotho',
  country_127: 'Liberia',
  country_128: 'Libya',
  country_129: 'Liechtenstein',
  country_130: 'Lithuania',
  country_131: 'Luxembourg',
  country_132: 'Macao Special Administrative Region',
  country_133: 'Macedonia',
  country_134: 'Madagascar',
  country_135: 'Malawi',
  country_136: 'Malaysia',
  country_137: 'Maldives',
  country_138: 'Mali',
  country_139: 'Malta',
  country_140: 'Marshall Islands',
  country_141: 'Martinique',
  country_142: 'Mauritania',
  country_143: 'Mauritius',
  country_144: 'Mayotte',
  country_145: 'Mexico',
  country_146: 'Micronesia',
  country_147: 'Moldavia',
  country_148: 'Monaco',
  country_149: 'Mongolia',
  country_150: 'Montenegro',
  country_151: 'Montserrat',
  country_152: 'Morocco',
  country_153: 'Mozambique',
  country_154: 'Myanmar (Burma)',
  country_155: 'Namibia',
  country_156: 'Nauru',
  country_157: 'Nepal',
  country_158: 'Netherlands',
  country_159: 'New Calcedonia',
  country_160: 'New Zealand',
  country_161: 'Nicaragua',
  country_162: 'Niger',
  country_163: 'Nigeria',
  country_164: 'Niue',
  country_165: 'Norfolk Island',
  country_166: 'North Korea',
  country_167: 'Northern Mariana Islands',
  country_168: 'Norway',
  country_169: 'Oman',
  country_170: 'Pakistan',
  country_171: 'Palau',
  country_172: 'Palestinian Territories',
  country_173: 'Panama',
  country_174: 'Papua New Guinea',
  country_175: 'Paraguay',
  country_176: 'Peru',
  country_177: 'Philippines',
  country_178: 'Pitcairn Islands',
  country_179: 'Poland',
  country_180: 'Portugal',
  country_181: 'Puerto Rico',
  country_182: 'Katar',
  country_183: 'Meeting',
  country_184: 'Romania',
  country_185: 'Russian Federation',
  country_186: 'Rwanda',
  country_187: 'Samoa',
  country_188: 'San Marino',
  country_189: 'Sao Tome and Principe',
  country_190: 'Saudi Arabia',
  country_191: 'Senegal',
  country_192: 'Serbia',
  country_193: 'Seychelles',
  country_194: 'Sierra Leone',
  country_195: 'Singapore',
  country_196: 'Sint Maarten',
  country_197: 'Slovakia',
  country_198: 'Slovenia',
  country_199: 'Solomon Islands',
  country_200: 'Somalia',
  country_201: 'South Africa',
  country_202: 'South Georgia and South Sandwich Islands',
  country_203: 'South Korea',
  country_204: 'Southern Sudan',
  country_206: 'Sri Lanka',
  country_207: 'Saint Bartholomew',
  country_208: 'Saint Helena',
  country_209: 'Saint Kitts and Nevis',
  country_210: 'Saint Lucia',
  country_211: 'St. Martin',
  country_212: 'St. Pierre',
  country_213: 'Saint Vincent and the Grenadines',
  country_214: 'Sudan',
  country_215: 'Suriname',
  country_216: 'Svalbard and Jan Mayen',
  country_217: 'Swaziland',
  country_218: 'Sweden',
  country_219: 'Switzerland',
  country_220: 'Syria',
  country_221: 'Taiwan',
  country_222: 'Tajikistan',
  country_223: 'Tanzania',
  country_224: 'Thailand',
  country_225: 'Timor-Leste',
  country_226: 'Togo',
  country_227: 'Tokelau',
  country_228: 'Tonga',
  country_229: 'Trinidad and Tobago',
  country_231: 'Tunisia',
  country_232: 'Turkey',
  country_233: 'Turkmenistan',
  country_234: 'Turks and Caicos Islands',
  country_235: 'Tuvalu',
  country_236: 'United States Peripheral Islands',
  country_237: 'U.S. Virgin Islands',
  country_238: 'Uganda',
  country_239: 'Ukraine',
  country_240: 'United Arab Emirates',
  country_241: 'United Kingdom',
  country_242: 'United States',
  country_243: 'Uruguay',
  country_244: 'Uzbekistan',
  country_245: 'Vanuatu',
  country_246: 'Vatican City',
  country_247: 'Venezuela',
  country_248: 'Vietnam',
  country_249: 'Wallis and Futuna',
  country_250: 'Western Sahara',
  country_251: 'Yemen',
  country_252: 'Zambia',
  country_253: 'Zimbabwe',
};

export default class CountryName {
  public static retrieve(countryId: number): string {
    return COUNTRIES[`country_${countryId}`];
  }
}
