<template>
  <general-table
    :headers="headers"
    :list.sync="headquarters"
    :search.sync="search"
    :customFilterSearch="customFilterSearch"
    :columns="['name', 'actions']"
  >
    <template v-slot:name="props">
      <edit-headquarter
        :headquarter="props.item"
      />
    </template>
    <template v-slot:actions="props">
      <remove-headquarters
        :headquarter="props.item"
      />
    </template>
  </general-table>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import EditHeadquarter from '@/components/modules/register/headquarters/EditHeadquarter.vue';
import RemoveHeadquarters from '@/components/modules/register/headquarters/RemoveHeadquarters.vue';

export default {
  name: 'list-headquarters',
  inject: {
    user: 'user',
    fetchHeadquarters: 'fetchHeadquarters',
  },
  components: {
    RemoveHeadquarters,
    EditHeadquarter,
    GeneralTable,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('headquarters.table.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: this.$t('headquarters.table.address'),
          align: 'start',
          sortable: true,
          value: 'address',
          width: '30%',
        },
        {
          text: this.$t('headquarters.table.security_measures'),
          align: 'start',
          sortable: false,
          value: 'securityMeasures',
          width: '40%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '10%',
        },
      ];
    },
  },
  data() {
    return {
      headquarters: [],
      customFilterSearch: (value, search, item) => (
        item.nameNormalized.indexOf(search) !== -1 || item.addressNormalized.indexOf(search) !== -1
      ),
    };
  },
  methods: {
    async getHeadquarters() {
      this.$showLoading();
      const response = await this.fetchHeadquarters(this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('headquarters.table.error');
        return;
      }
      this.headquarters = response.headquarters;
      this.$eventHub.$emit('headquartersLoaded', this.headquarters.length);
    },
  },
  beforeMount() {
    this.getHeadquarters();
  },
  created() {
    this.$eventHub.$on('headquarterWasDeleted', this.getHeadquarters);
    this.$eventHub.$on('headquarterWasCreated', this.getHeadquarters);
    this.$eventHub.$on('headquartersWasUpdated', this.getHeadquarters);
  },
  beforeDestroy() {
    this.$eventHub.$off('headquarterWasDeleted', this.getHeadquarters);
    this.$eventHub.$off('headquarterWasCreated', this.getHeadquarters);
    this.$eventHub.$off('headquartersWasUpdated', this.getHeadquarters);
  },
};
</script>
