import RecipientRole from '@/domain/recipient/recipientRole/RecipientRole';
import RecipientRoleRepresentation from './RecipientRoleRepresentation';

export default (
  translator,
) => () => {
  const roles = RecipientRole.all();
  const otherRole = RecipientRole.other();

  const rolesWithoutOtherRepresentation = [];
  roles.forEach((role) => {
    if (otherRole.isEquals(role)) {
      return;
    }
    rolesWithoutOtherRepresentation.push(
      role.representedAs(new RecipientRoleRepresentation(translator)),
    );
  });
  rolesWithoutOtherRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
  return [...rolesWithoutOtherRepresentation, otherRole.representedAs(
    new RecipientRoleRepresentation(translator),
  )];
};
