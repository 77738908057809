const ADD_HEADQUARTERS = (state, headquarters) => {
  state.headquarters = headquarters;
};

const RESET_HEADQUARTERS = (state) => {
  state.headquarters = [];
};

export default {
  ADD_HEADQUARTERS,
  RESET_HEADQUARTERS,
};
