<template>
  <client-users-container />
</template>
<script>
import ClientUsersContainer from '@/components/containers/ClientUsersContainer.vue';

export default {
  name: 'client-users',
  components: {
    ClientUsersContainer,
  },
};
</script>
