import RecipientId from '@/domain/recipient/RecipientId';
import ClientId from '@/domain/client/ClientId';
import RecipientName from '@/domain/recipient/RecipientName';
import RecipientStatus from '@/domain/recipient/recipientStatus/RecipientStatus';
import RecipientBusinessName from '@/domain/recipient/RecipientBusinessName';
import CountryCode from '@/domain/country/CountryCode';
import State from '@/domain/country/state/State';
import RecipientCategory from '@/domain/recipient/recipientCategory/RecipientCategory';
import RecipientRole from '@/domain/recipient/recipientRole/RecipientRole';
import RecipientWarranty from '@/domain/recipient/recipientWarranties/RecipientWarranty';
import RecipientDpa from '@/domain/recipient/RecipientDpa';
import RecipientDpaStatus from '@/domain/recipient/recipientDpaStatus/RecipientDpaStatus';
import RecipientLastModification from '@/domain/recipient/RecipientLastModification';
import RecipientDocument from '@/domain/recipient/RecipientDocument';
import Recipient from '@/domain/recipient/Recipient';
import RecipientBuilderValidationException
  from '@/domain/recipient/RecipientBuilderValidationException';

export default class RecipientBuilder {
  id = null;

  clientId = null;

  name = null;

  businessName = null;

  country = null;

  state = null;

  status = null;

  category = null;

  role = null;

  warranties = null;

  dpa = null;

  dpaStatus = null;

  lastModification = null;

  documents = [];

  recipientModel = null;

  replacedBy = null;

  isReplacement = false;

  withId(id) {
    this.id = RecipientId.fromString(id);
    return this;
  }

  withClientId(clientId) {
    this.clientId = ClientId.fromInt(clientId);
    return this;
  }

  withName(name) {
    this.name = new RecipientName(name);
    return this;
  }

  withStatus(status) {
    this.status = new RecipientStatus(status);
    return this;
  }

  withLastModification(lastModification) {
    this.lastModification = new RecipientLastModification(lastModification);
    return this;
  }

  withBusinessName(businessName) {
    if (businessName) {
      this.businessName = new RecipientBusinessName(businessName);
    }

    return this;
  }

  withCountry(country) {
    if (country) {
      this.country = new CountryCode(country);
    }

    return this;
  }

  withState(state) {
    if (state) {
      this.state = new State(state);
    }

    return this;
  }

  withCategory(category) {
    if (category) {
      this.category = new RecipientCategory(category);
    }

    return this;
  }

  withRole(role) {
    if (role) {
      this.role = new RecipientRole(role);
    }

    return this;
  }

  withWarranties(warranties) {
    if (warranties.length) {
      this.warranties = warranties.map((warranty) => new RecipientWarranty(warranty));
    }

    return this;
  }

  withDpa(dpa) {
    if (dpa) {
      this.dpa = new RecipientDpa(dpa);
    }

    return this;
  }

  withDpaStatus(dpaStatus) {
    if (dpaStatus) {
      this.dpaStatus = new RecipientDpaStatus(dpaStatus);
    }

    return this;
  }

  withDocuments(documents) {
    if (!!documents && !!documents.length) {
      this.documents = documents.map((document) => RecipientDocument.fromName(document));
    }

    return this;
  }

  attachDocuments(documents) {
    if (!!documents && !!documents.length) {
      this.documents.push(...documents.map((document) => RecipientDocument.fromContent(document)));
    }

    return this;
  }

  withRecipientModel(recipientModel) {
    if (recipientModel) {
      this.recipientModel = recipientModel;
    }

    return this;
  }

  withReplacedBy(replacedBy) {
    if (replacedBy) {
      this.replacedBy = replacedBy;
    }

    return this;
  }

  withIsReplacement(isReplacement) {
    this.isReplacement = !!isReplacement;
    return this;
  }

  reset() {
    this.id = null;
    this.clientId = null;
    this.name = null;
    this.businessName = null;
    this.country = null;
    this.state = null;
    this.status = null;
    this.category = null;
    this.role = null;
    this.warranties = null;
    this.dpa = null;
    this.dpaStatus = null;
    this.lastModification = null;
    this.documents = null;
    this.replacedBy = null;
    this.isReplacement = false;
  }

  create() {
    this.hasMandatoryValuesOrFails();

    const recipient = new Recipient(
      this.id,
      this.clientId,
      this.name,
      this.status,
      this.lastModification,
    );

    if (this.businessName) {
      recipient.addBusinessName(this.businessName);
    }

    if (this.country) {
      recipient.addCountry(this.country);
    }

    if (this.state) {
      recipient.addState(this.state);
    }

    if (this.category) {
      recipient.addCategory(this.category);
    }

    if (this.role) {
      recipient.addRole(this.role);
    }

    if (this.warranties) {
      recipient.addWarranties(this.warranties);
    }

    if (this.dpa) {
      recipient.addDpa(this.dpa);
    }

    if (this.dpaStatus) {
      recipient.addDpaStatus(this.dpaStatus);
    }

    if (this.documents) {
      recipient.addDocuments(this.documents);
    }

    if (this.recipientModel) {
      recipient.addRecipientModel(this.recipientModel);
    }

    recipient.addReplacedBy(this.replacedBy);
    recipient.addIsReplacement(this.isReplacement);

    this.reset();

    return recipient;
  }

  hasMandatoryValuesOrFails() {
    if (!this.id) {
      throw new RecipientBuilderValidationException('id is mandatory');
    }
    if (!this.clientId) {
      throw new RecipientBuilderValidationException('client id is mandatory');
    }
    if (!this.name) {
      throw new RecipientBuilderValidationException('name is mandatory');
    }
    if (!this.status) {
      throw new RecipientBuilderValidationException('status is mandatory');
    }
    if (!this.lastModification) {
      throw new RecipientBuilderValidationException('last modification is mandatory');
    }
  }
}
