import EquipmentId from '@/domain/equipment/EquipmentId';
import EquipmentRepresentation from '@/application/equipment/fetchEquipment/EquipmentRepresentation';

export default (
  equipmentRepository,
) => async (equipmentId) => {
  try {
    const equipment = await equipmentRepository.retrieve(EquipmentId.fromString(equipmentId));

    const equipmentRepresentation = equipment.representedAs(new EquipmentRepresentation());
    return { success: true, equipment: equipmentRepresentation };
  } catch (e) {
    return { success: false };
  }
};
