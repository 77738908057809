<template>
  <div>
    <v-card
      class="elevation-lighten mb-10"
    >
      <v-img
        :src="urlImg"
      />
      <activate-training-button
        v-if="!isActivated"
        :isActivated="isActivated"
        :isRequested="isRequested"
      />
      <go-to-training
        v-else
        :userLanguage="language"
        :availableLanguages="availableLanguages"
        :isActivated="isActivated"
      />
    </v-card>
    <list-user-certificates />
  </div>
</template>

<script>
import GoToTraining from '@/components/modules/training/userTrainings/GoToTraining.vue';
import ActivateTrainingButton from '@/components/modules/training/userTrainings/ActivateTrainingButton.vue';
import { mapGetters } from 'vuex';
import {
  createTrainingUser,
  fetchTrainingInfo,
  goTrainingUrl,
  requestTraining,
  fetchInscriptions,
} from '@/application/training';
import { downloadDocument } from '@/application/document';
import ListUserCertificates from '@/components/modules/training/userTrainings/ListUserCertificates.vue';

const URL_IMG_BANNER = require('@/assets/img/training-banner.jpg');

export default {
  name: 'user-trainings-section',
  provide: {
    createTrainingUser,
    goTrainingUrl,
    requestTraining,
    downloadDocument,
    fetchInscriptions,
  },
  inject: {
    user: 'user',
  },
  components: {
    ListUserCertificates,
    GoToTraining,
    ActivateTrainingButton,
  },
  computed: {
    ...mapGetters('training', {
      isActivated: 'isActivated',
      language: 'language',
      availableLanguages: 'availableLanguages',
      isRequested: 'isRequested',
    }),
  },
  data() {
    return {
      urlImg: URL_IMG_BANNER,
    };
  },
  methods: {
    async loadTrainingInfo() {
      this.$showLoading();
      const response = await fetchTrainingInfo.invoke(this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('training.error.something_went_wrong');
      }
    },
  },
  async beforeMount() {
    await this.loadTrainingInfo();
  },
};
</script>
