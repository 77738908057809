export default class RecipientReplaceWasRequestedEventListener {
  _writeModel;

  constructor(RecipientWriteModel) {
    this._writeModel = RecipientWriteModel;
  }

  invoke(recipientReplaceWasRequested) {
    this._writeModel.updateReplacedBy(
      recipientReplaceWasRequested.getRecipientIdToReplace(),
      recipientReplaceWasRequested.getReplacementRecipientId(),
    );
    this._writeModel.updateIsReplacement(
      recipientReplaceWasRequested.getReplacementRecipientId(),
    );
  }
}
