import DateTimeValueObject from '../shared/dateTimeValueObject/DateTimeValueObject';

export default class DocumentDataLastModification extends DateTimeValueObject {
  lastModification;

  constructor(lastModification) {
    super(lastModification);
    this.lastModification = lastModification;
  }
}
