<template>
  <general-table
    :headers="headers"
    :list="clientsUsersList"
    :search="search"
    :customFilterSearch="customFilterSearch"
    sortBy="name"
    :columns="['role', 'lastLoginDate', 'actions']"
  >
    <template v-slot:role="props">
      <edit-client-user-role
        v-if="canEditRole(props.item)"
        :client-user="props.item"
      />
      <span
        v-else
      >
          {{props.item.role}}
        </span>
    </template>
    <template v-slot:lastLoginDate="props">
      <resend-activation-client-user
        :clientUser="props.item"
      />
    </template>
    <template v-slot:actions="props">
      <delete-client-user
        v-if="props.item.id !== user.id"
        :client-user="props.item"
      />
    </template>
  </general-table>
</template>

<script>
import normalization from '@/services/shared/normalization';
import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import ResendActivationClientUser from '@/components/modules/clientUsers/ResendActivationClientUser.vue';
import DeleteClientUser from '@/components/modules/clientUsers/DeleteClientUser.vue';
import EditClientUserRole from '@/components/modules/clientUsers/EditClientUserRole.vue';
import FetchClientUsersQuery from '@/application/clientUser/fetchClientUsers/FetchClientUsersQuery';

export default {
  name: 'client-users-list-table',
  inject: {
    user: 'user',
    fetchClientUsers: 'fetchClientUsers',
  },
  components: {
    EditClientUserRole,
    DeleteClientUser,
    GeneralTable,
    ResendActivationClientUser,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('clientUsers.table.name'),
          align: 'start',
          sortable: true,
          value: 'fullName',
          width: '30%',
        },
        {
          text: this.$t('clientUsers.table.email'),
          align: 'start',
          sortable: true,
          value: 'email',
          width: '25%',
        },
        {
          text: this.$t('clientUsers.table.rol'),
          align: 'start',
          sortable: true,
          value: 'role',
          width: '30%',
        },
        {
          text: this.$t('clientUsers.table.last_access'),
          align: 'start',
          sortable: true,
          value: 'lastLoginDate',
          width: '25%',
          sort: (a, b) => {
            let aDate = new Date(a);
            let bDate = new Date(b);
            if (a) {
              const aSplitDate = a.split('/');
              aDate = new Date(aSplitDate[2], aSplitDate[1] - 1, aSplitDate[0]);
            }
            if (b) {
              const bSplitDate = b.split('/');
              bDate = new Date(bSplitDate[2], bSplitDate[1] - 1, bSplitDate[0]);
            }
            return aDate.getTime() - bDate.getTime();
          },
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '10%',
        },
      ];
    },
  },
  data() {
    return {
      clientsUsersList: [],
      customFilterSearch: (value, search, item) => (
        normalization.normalizeString(item.fullName).indexOf(search) !== -1
        || normalization.normalizeString(item.email).indexOf(search) !== -1
      ),
    };
  },
  methods: {
    async getClientUsersList() {
      this.$showLoading();

      const response = await this.fetchClientUsers.invoke(
        new FetchClientUsersQuery(this.user.clientId),
      );

      this.$hideLoading();

      if (!response.success) {
        this.$showError('clientUsers.error.get_list');
        return;
      }

      this.clientsUsersList = response.clientUsers;
    },
    canEditRole(clientUser) {
      if (this.user.isTrainingsManager || clientUser.isTrainingsManager) {
        return false;
      }
      return clientUser.id !== this.user.id;
    },
  },
  async beforeMount() {
    await this.getClientUsersList();

    this.$eventHub.$on('anUserWasCreated', this.getClientUsersList);
    this.$eventHub.$on('userWasDeleted', this.getClientUsersList);
    this.$eventHub.$on('clientUserRoleWasUpdated', this.getClientUsersList);
  },
  beforeDestroy() {
    this.$eventHub.$off('anUserWasCreated', this.getClientUsersList);
    this.$eventHub.$off('userWasDeleted', this.getClientUsersList);
    this.$eventHub.$off('clientUserRoleWasUpdated', this.getClientUsersList);
  },
};
</script>
