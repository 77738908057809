import EquipmentId from './EquipmentId';
import Equipment from './Equipment';
import ClientId from '../client/ClientId';
import EquipmentName from './EquipmentName';
import EquipmentType from './equipmentType/EquipmentType';
import EquipmentSecurityMeasures from './equipmentSecurityMeasures/EquipmentSecurityMeasures';
import EquipmentLocation from './equipmentLocation/EquipmentLocation';

export default function createEquipmentEntity(clientId, equipment, equipmentId) {
  const equipmentEntity = new Equipment(
    EquipmentId.fromString(equipmentId.toString()),
    ClientId.fromInt(clientId),
    EquipmentName.fromString(equipment.name),
    EquipmentType.fromKey(equipment.type),
  );

  const securityMeasures = [];
  equipment.securityMeasures.forEach((securityMeasure) => {
    securityMeasures.push(EquipmentSecurityMeasures.fromKey(securityMeasure));
  });
  equipmentEntity.addSecurityMeasures(securityMeasures);

  if (equipment.location && Object.keys(equipment.location).length) {
    equipmentEntity.addLocation(
      new EquipmentLocation(
        equipment.location.type,
        equipment.location.key,
      ),
    );
  }
  return equipmentEntity;
}
