import { getManagement } from '@/shared/restActions';
import store from '@/store';
import ProcessingActivityModelReadModel from '@/domain/processingActivity/modelList/ProcessingActivityModelReadModel';
import ListProcessingActivityModelRepresentation from '@/application/processingActivity/fetchModelList/ListProcessingActivityModelRepresentation';

export default class ProcessingActivityModelReadModelApi extends ProcessingActivityModelReadModel {
  translator;

  normalizationService;

  constructor(translator, normalizationService) {
    super();
    this.translator = translator;
    this.normalizationService = normalizationService;
  }

  async getAll() {
    let list = await ProcessingActivityModelReadModelApi.getListFromStore();

    if (!list.length) {
      list = await ProcessingActivityModelReadModelApi.getListFromApi();
      ProcessingActivityModelReadModelApi.setStoreModels(list);
    }

    const listModelRepresentation = [];
    list.forEach((model) => {
      const modelRepresentation = new ListProcessingActivityModelRepresentation(
        this.translator, this.normalizationService,
      );
      modelRepresentation.setId(model.id);
      modelRepresentation.setName(model.name);
      modelRepresentation.setCountryId(model.country_id);
      modelRepresentation.setLanguage(model.language_code);
      modelRepresentation.setDepartment(model.department);
      listModelRepresentation.push(modelRepresentation);
    });
    return listModelRepresentation;
  }

  static setStoreModels(models) {
    return store.dispatch('processingActivity/addModels', models);
  }

  static getListFromStore() {
    return store.getters['processingActivity/models'];
  }

  static async getListFromApi() {
    const response = await getManagement('/api/v2/processing-activities/models');
    return response.data;
  }
}
