<template>
  <v-row>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="searchHeadquarter"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right multiple-button">
      <download-document-button
        v-if="showDownloadButton"
        :id="user.clientId"
        errorMsg="headquarters.download.error"
        :btnMsg="$t('headquarters.download.button')"
        docType="headquarters-list"
        actionTrack="Download location registry"
      />
      <create-headquarter />
    </v-col>
    <v-col cols="12">
      <list-headquarters
        :search.sync="searchHeadquarter"
      />
    </v-col>
  </v-row>
</template>

<script>
import headquartersUseCases from '@/application/headquarter';
import documentUseCases from '@/application/document';
import equipmentUseCases from '@/application/equipment';
import InputSearch from '@/components/base/form/InputSearch.vue';
import DownloadDocumentButton from '@/components/base/section/DownloadDocumentButton.vue';
import ListHeadquarters from './ListHeadquarters.vue';
import CreateHeadquarter from './CreateHeadquarter.vue';

export default {
  name: 'headquarters-section',
  provide: {
    ...headquartersUseCases,
    ...documentUseCases,
    getHeadquartersEquipmentCount: equipmentUseCases.getHeadquartersEquipmentCount,
  },
  inject: {
    user: 'user',
  },
  components: {
    DownloadDocumentButton,
    ListHeadquarters,
    InputSearch,
    CreateHeadquarter,
  },
  props: {
    currentTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchHeadquarter: '',
      showDownloadButton: false,
    };
  },
  methods: {
    mustBeShowDownloadButton(headquartersLength) {
      this.showDownloadButton = !!headquartersLength && this.currentTab === 'headquarter';
    },
  },
  beforeMount() {
    headquartersUseCases.resetHeadquarters();
    this.$eventHub.$on('headquartersLoaded', this.mustBeShowDownloadButton);
  },
  beforeDestroy() {
    this.$eventHub.$off('headquartersLoaded', this.mustBeShowDownloadButton);
  },
};

</script>
