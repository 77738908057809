import ClientUserTypeRepresentation from '@/domain/clientUser/ClientUserTypeRepresentation';
import Translator from '@/infrastructure/Translator';

export default class ClientUserTypesRepresentation extends ClientUserTypeRepresentation {
  private translator: Translator;

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  get type(): string {
    return this._type;
  }

  get name(): string {
    return this.translator.translate(`clientUsers.change_role.${this._type}`) as string;
  }

  get isRestrictedEmployeeType(): boolean {
    return this._isRestrictedEmployee;
  }
}
