<template>
  <v-row>
    <v-col cols="12">
      <title-section
        :title="$t('data_breach.title')"
        :subtitle="$t('data_breach.description')"
      />
    </v-col>
    <v-col cols="12">
      <submenu-section
        :tabActive.sync="currentTab"
        :sections="menuSections"
        v-on:update:tabActive="tabWasChanged"
      >
        <v-tab-item
          v-for="section in menuSections"
          :key="section.key"
          :value="section.key"
          :transition="false"
        >
          <component
            :is="section.key"
          />
        </v-tab-item>
      </submenu-section>
    </v-col>
  </v-row>
</template>

<script>
import { downloadDocument } from '@/application/document';
import dataBreachUseCases from '@/application/dataBreach/historic';
import languagesUseCases from '@/application/language/';
import HistoricSection from '@/components/modules/dataBreach/historic/HistoricSection.vue';
import NotifySection from '@/components/modules/dataBreach/notify/NotifySection.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import TitleSection from '../base/section/TitleSection.vue';

export default {
  name: 'data-breach-container',
  provide: {
    ...dataBreachUseCases,
    downloadDocument,
    ...languagesUseCases,
  },
  components: {
    SubmenuSection,
    notify: NotifySection,
    historic: HistoricSection,
    TitleSection,
  },
  computed: {
    menuSections() {
      return [
        {
          name: this.$t('data_breach.report_data_breach.menu'),
          key: 'notify',
        },
        {
          name: this.$t('data_breach.history_data_breach.menu'),
          key: 'historic',
        },
      ];
    },
  },
  data() {
    return {
      currentTab: 'notify',
    };
  },
  methods: {
    tabWasChanged(sectionName) {
      this.trackActions(sectionName);
      this.$router.push({ path: `/data-breach/${sectionName}` });
    },
    trackActions(sectionName) {
      this.$track({
        action: `Go to Data Breach  ${sectionName}`,
      });
    },
    updateCurrentTabByRoute() {
      if (!this.$route.path.includes('data-breach')) {
        return;
      }

      const tab = this.$route.path.substring(1).split('/')[1];

      if (!tab) {
        return;
      }

      this.currentTab = tab;
    },
  },
  beforeMount() {
    this.updateCurrentTabByRoute();
    this.$watch(() => this.$route, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.updateCurrentTabByRoute();
      }
    });
  },
};
</script>
