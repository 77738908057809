<template>
  <div class="h-100 w-100">
    <v-btn
      rounded
      outlined
      color="primary"
      @click="$eventHub.$emit('showOptionsExtensionFile', dataBreachId)"
    >
      <v-icon
        class="material-icons-round"
      >
        file_download
      </v-icon>
      {{ $t('data_breach.report') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'download-report-button',
  inject: {
    downloadDocument: 'downloadDocument',
  },
  props: {
    dataBreachId: {
      type: String,
      default: null,
    },
    dataBreachCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModalExtension: false,
    };
  },
  methods: {
    async downloadReport(dataBreachId, fileType) {
      this.showModalExtension = false;
      this.$showLoading();
      const response = await this.downloadDocument.invoke('data-breach-report', dataBreachId, fileType);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('errorPage.error_download');
      }

      this.$track({
        action: 'download data breach report',
        result: response.success,
        document_format: fileType,
        data_breach_download_report: this.dataBreachCode,
      });
    },
  },
  beforeMount() {
    this.$eventHub.$on('downloadDocument', this.downloadReport);
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadReport);
  },
};
</script>
