import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachCode from '@/domain/dataBreach/DataBreachCode';
import DataBreachOccurredOn from '@/domain/dataBreach/DataBreachOccurredOn';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import DataBreachRepresentation from '@/domain/dataBreach/DataBreachRepresentation';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachDiscoveredOn from '@/domain/dataBreach/DataBreachDiscoveredOn';
import DataBreachDescription from '@/domain/dataBreach/DataBreachDescription';
import DataBreachHowDiscovered from '@/domain/dataBreach/DataBreachHowDiscovered';
import DataBreachWasIntentional from '@/domain/dataBreach/DataBreachWasIntentional';
import DataBreachCompromisedData from '@/domain/dataBreach/DataBreachCompromisedData';
import DataBreachAffectedProfiles from '@/domain/dataBreach/DataBreachAffectedProfiles';
import DataBreachConsequences from '@/domain/dataBreach/DataBreachConsequences';
import DataBreachAffectedCountries from '@/domain/dataBreach/DataBreachAffectedCountries';
import DataBreachNumberAffected from '@/domain/dataBreach/DataBreachNumberAffected';
import DataBreachSecurityMeasuresImplemented
  from '@/domain/dataBreach/DataBreachSecurityMeasuresImplemented';
import DataBreachFutureSecurityMeasures from '@/domain/dataBreach/DataBreachFutureSecurityMeasures';
import DataBreachSecurityMeasuresProblems
  from '@/domain/dataBreach/DataBreachSecurityMeasuresProblems';
import DataBreachInformedAffectedPeople from '@/domain/dataBreach/DataBreachInformedAffectedPeople';
import DataBreachInformedAuthorities from '@/domain/dataBreach/DataBreachInformedAuthorities';
import DataBreachEvaluation from '@/domain/dataBreach/DataBreachEvaluation';
import DataBreachReportLanguage from '@/domain/dataBreach/DataBreachReportLanguage';
import DataBreachStatus from '@/domain/dataBreach/DataBreachStatus';
import DataBreachCreatedBy from '@/domain/dataBreach/DataBreachCreatedBy';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';

export default class DataBreach {
  private readonly id: DataBreachId;

  private readonly createdDate: DataBreachCreatedDate;

  private readonly occurredOn: DataBreachOccurredOn;

  private readonly createdBy: DataBreachCreatedBy;

  private readonly discoveredOn: DataBreachDiscoveredOn;

  private readonly description: DataBreachDescription;

  private readonly status: DataBreachStatus;

  private howDiscovered: DataBreachHowDiscovered | null = null;

  private wasIntentional: DataBreachWasIntentional | null = null;

  private compromisedData: DataBreachCompromisedData | null = null;

  private affectedProfiles: DataBreachAffectedProfiles | null = null;

  private consequences: DataBreachConsequences | null = null;

  private affectedCountries: DataBreachAffectedCountries | null = null;

  private numberAffected: DataBreachNumberAffected | null = null;

  private securityMeasuresImplemented: DataBreachSecurityMeasuresImplemented | null = null;

  private futureSecurityMeasures: DataBreachFutureSecurityMeasures | null = null;

  private securityMeasuresProblems: DataBreachSecurityMeasuresProblems | null = null;

  private informedAffectedPeople: DataBreachInformedAffectedPeople | null = null;

  private informedAuthorities: DataBreachInformedAuthorities | null = null;

  private attachedDocuments: DataBreachAttachedDocuments[] = [];

  private attachedDocumentsToDelete: UuidValueObject[] = [];

  private code: DataBreachCode | null = null;

  private evaluation: DataBreachEvaluation | null = null;

  private agencyNotification: boolean = false;

  private reportLanguage: DataBreachReportLanguage | null = null;

  constructor(
    id: DataBreachId,
    createdDate: DataBreachCreatedDate,
    createdBy: DataBreachCreatedBy,
    occurredOn: DataBreachOccurredOn,
    discoveredOn: DataBreachDiscoveredOn,
    description: DataBreachDescription,
    status: DataBreachStatus,
  ) {
    this.id = id;
    this.createdDate = createdDate;
    this.createdBy = createdBy;
    this.occurredOn = occurredOn;
    this.discoveredOn = discoveredOn;
    this.description = description;
    this.status = status;
  }

  public addHowDiscovered(howDiscovered: DataBreachHowDiscovered): void {
    this.howDiscovered = howDiscovered;
  }

  public addWasIntentional(wasIntentional: DataBreachWasIntentional): void {
    this.wasIntentional = wasIntentional;
  }

  public addCompromisedData(compromisedData: DataBreachCompromisedData): void {
    this.compromisedData = compromisedData;
  }

  public addAffectedProfiles(affectedProfiles: DataBreachAffectedProfiles): void {
    this.affectedProfiles = affectedProfiles;
  }

  public addConsequences(consequences: DataBreachConsequences): void {
    this.consequences = consequences;
  }

  public addAffectedCountries(affectedCountries: DataBreachAffectedCountries): void {
    this.affectedCountries = affectedCountries;
  }

  public addNumberAffected(numberAffected: DataBreachNumberAffected): void {
    this.numberAffected = numberAffected;
  }

  public addSecurityMeasuresImplemented(
    securityMeasuresImplemented: DataBreachSecurityMeasuresImplemented,
  ): void {
    this.securityMeasuresImplemented = securityMeasuresImplemented;
  }

  public addFutureSecurityMeasures(
    futureSecurityMeasures: DataBreachFutureSecurityMeasures,
  ): void {
    this.futureSecurityMeasures = futureSecurityMeasures;
  }

  public addSecurityMeasuresProblems(
    securityMeasuresProblems: DataBreachSecurityMeasuresProblems,
  ): void {
    this.securityMeasuresProblems = securityMeasuresProblems;
  }

  public addInformedAffectedPeople(informedAffectedPeople: DataBreachInformedAffectedPeople): void {
    this.informedAffectedPeople = informedAffectedPeople;
  }

  public addInformedAuthorities(informedAuthorities: DataBreachInformedAuthorities): void {
    this.informedAuthorities = informedAuthorities;
  }

  public addCode(code: DataBreachCode): void {
    this.code = code;
  }

  public addAttachedDocuments(attachedDocuments: DataBreachAttachedDocuments[]): void {
    this.attachedDocuments = attachedDocuments;
  }

  public addAttachedDocumentsToDelete(attachedDocumentsToDelete: UuidValueObject[]): void {
    this.attachedDocumentsToDelete = attachedDocumentsToDelete;
  }

  public addEvaluation(evaluation: DataBreachEvaluation): void {
    this.evaluation = evaluation;
  }

  public addAgencyNotification(agencyNotification: boolean): void {
    this.agencyNotification = agencyNotification;
  }

  public addReportLanguage(reportLanguage: DataBreachReportLanguage): void {
    this.reportLanguage = reportLanguage;
  }

  public representedAs(
    dataBreachRepresentation: DataBreachRepresentation,
  ): DataBreachRepresentation {
    dataBreachRepresentation.setId(this.id);
    dataBreachRepresentation.setCreatedDate(this.createdDate);
    dataBreachRepresentation.setCreatedBy(this.createdBy);
    dataBreachRepresentation.setOccurredOn(this.occurredOn);
    dataBreachRepresentation.setDiscoveredOn(this.discoveredOn);
    dataBreachRepresentation.setDescription(this.description);
    dataBreachRepresentation.setStatus(this.status);
    dataBreachRepresentation.setHowDiscovered(this.howDiscovered);
    dataBreachRepresentation.setWasIntentional(this.wasIntentional);
    dataBreachRepresentation.setCompromisedData(this.compromisedData);
    dataBreachRepresentation.setAffectedProfiles(this.affectedProfiles);
    dataBreachRepresentation.setConsequences(this.consequences);
    dataBreachRepresentation.setAffectedCountries(this.affectedCountries);
    dataBreachRepresentation.setNumberAffected(this.numberAffected);
    dataBreachRepresentation.setSecurityMeasuresImplemented(this.securityMeasuresImplemented);
    dataBreachRepresentation.setFutureSecurityMeasures(this.futureSecurityMeasures);
    dataBreachRepresentation.setSecurityMeasuresProblems(this.securityMeasuresProblems);
    dataBreachRepresentation.setInformedAffectedPeople(this.informedAffectedPeople);
    dataBreachRepresentation.setInformedAuthorities(this.informedAuthorities);
    dataBreachRepresentation.setCode(this.code);
    dataBreachRepresentation.setAttachedDocuments(this.attachedDocuments);
    dataBreachRepresentation.setAttachedDocumentsToDelete(this.attachedDocumentsToDelete);
    dataBreachRepresentation.setEvaluation(this.evaluation);
    dataBreachRepresentation.setAgencyNotification(this.agencyNotification);
    dataBreachRepresentation.setReportLanguage(this.reportLanguage);

    return dataBreachRepresentation;
  }

  public getId(): DataBreachId {
    return this.id;
  }

  public getAttachedDocuments(): DataBreachAttachedDocuments[] {
    return this.attachedDocuments;
  }

  public getAttachedDocumentsToDelete(): UuidValueObject[] {
    return this.attachedDocumentsToDelete;
  }
}
