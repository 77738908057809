function removeEmptyDataSource(processingActivities) {
  const processingHasDataFlows = {};

  Object.keys(processingActivities).forEach((obj) => {
    if (processingActivities[obj].data_flows[processingActivities[obj].id].data_source.length !== 0
      && processingActivities[obj].data_flows[processingActivities[obj].id].receivers.length !== 0
    ) {
      processingHasDataFlows[processingActivities[obj].id] = { ...processingActivities[obj] };
    }
  });

  return processingHasDataFlows;
}

export default {
  removeEmptyDataSource,
};
