import store from '@/store';

export default class RecipientModelCache {
  static resetRecipientsModels() {
    store.dispatch('recipientModel/resetRecipientsModels');
  }

  static setRecipientsModels(recipientsModels) {
    store.dispatch('recipientModel/addRecipientsModels', recipientsModels);
  }

  static getRecipientsModels() {
    return store.getters['recipientModel/recipientsModels'];
  }
}
