import UserLoginHandler from '@/application/user/userLogin/UserLoginHandler';
import UserRepositoryApi from '@/infrastructure/user/UserRepositoryApi';
import RecoverPassword from '@/application/user/recoverPassword/RecoverPassword';
import ChangePassword from '@/application/user/changePassword/ChangePassword';
import GoogleLoginHandler from '@/application/user/googleLogin/GoogleLoginHandler';
import LoginGoogleApi from '@/infrastructure/user/login/LoginGoogleApi';
import LoginUserApi from '@/infrastructure/user/login/LoginUserApi';
import OktaLoginHandler from '@/application/user/oktaLogin/OktaLoginHandler';
import LoginOktaApi from '@/infrastructure/user/login/LoginOktaApi';
import FetchUserInfo from '@/application/user/fetchUserInfo/FetchUserInfo';

export default {};

export const login = new UserLoginHandler(new LoginUserApi());
export const recoverPassword = new RecoverPassword(new UserRepositoryApi());
export const changePassword = new ChangePassword(new UserRepositoryApi());
export const googleLogin = new GoogleLoginHandler(new LoginGoogleApi());
export const oktaLogin = new OktaLoginHandler(new LoginOktaApi());
export const fetchUserInfo = new FetchUserInfo(new UserRepositoryApi());
