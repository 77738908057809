import EventListener from '@/application/eventBus/EventListener';
import EmployeeWasDeleted from '@/domain/employee/EmployeeWasDeleted';
import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';

export default class EmployeeWasDeletedEventListener implements EventListener {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public handle(employeeWasDeleted: EmployeeWasDeleted): void {
    this.trainingEmployeeRepository.reset();
  }
}
