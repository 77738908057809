import { initialState } from './state';

const ADD_IS_SPANISH_ADMIN = (state, isSpanishAdmin) => {
  state.isSpanishAdmin = isSpanishAdmin;
};

const ADD_ADMIN_LOGO_URL = (state, logoUrl) => {
  state.logoUrl = logoUrl;
};

const ADD_ADMIN_NAME = (state, name) => {
  state.name = name;
};

const ADD_SUBDOMAIN = (state, subdomain) => {
  state.subdomain = subdomain;
};

const ADD_ADMIN_COMPANY_ID = (state, adminCompanyId) => {
  state.adminCompanyId = adminCompanyId;
};

const ADD_ADMIN_COUNTRY_CODE = (state, countryCode) => {
  state.countryCode = countryCode;
};

const ADD_ADMIN_IS_B2B = (state, isB2b) => {
  state.isB2b = isB2b;
};

const RESET_IS_SPANISH_ADMIN = (state) => {
  state.isSpanishAdmin = initialState.isSpanishAdmin;
};

const RESET_COUNTRY_CODE = (state) => {
  state.countryCode = initialState.countryCode;
};

const RESET_ADMIN_LOGO_URL = (state) => {
  state.logoUrl = initialState.logoUrl;
};

const RESET_ADMIN_NAME = (state) => {
  state.name = initialState.name;
};

const RESET_SUBDOMAIN = (state) => {
  state.subdomain = initialState.subdomain;
};

const RESET_ADMIN_COMPANY_ID = (state) => {
  state.adminCompanyId = initialState.adminCompanyId;
};

export default {
  ADD_IS_SPANISH_ADMIN,
  ADD_ADMIN_LOGO_URL,
  ADD_ADMIN_NAME,
  ADD_SUBDOMAIN,
  ADD_ADMIN_COMPANY_ID,
  ADD_ADMIN_COUNTRY_CODE,
  ADD_ADMIN_IS_B2B,
  RESET_IS_SPANISH_ADMIN,
  RESET_COUNTRY_CODE,
  RESET_ADMIN_LOGO_URL,
  RESET_ADMIN_NAME,
  RESET_SUBDOMAIN,
  RESET_ADMIN_COMPANY_ID,
};
