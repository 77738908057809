export default class ChangeTomStatusCommand {
  public readonly tomId: string;

  public readonly status: string;

  constructor(tomId:string, status: string) {
    this.tomId = tomId;
    this.status = status;
  }
}
