export default ({
  userRepository,
  userLanguageService,
}) => async (language) => {
  try {
    await userRepository.changeLanguage(language);
    userLanguageService.changeUserLanguage(language);
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
