import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUser from '@/domain/clientUser/ClientUser';
import store from '../../store';

export default class ClientUsersCache {
  public static save(clientUser: ClientUser): void {
    const clientUserCollection: { [key: string]: ClientUser } = store.getters['clientUsers/clientUsers'];
    clientUserCollection[clientUser.getId().toString()] = clientUser;
    store.dispatch('clientUsers/addClientUsers', clientUserCollection);
  }

  public static retrieve(clientUserId: ClientUserId): ClientUser {
    const clientUserCollection: { [key: string]: ClientUser } = store.getters['clientUsers/clientUsers'];
    return clientUserCollection[clientUserId.toString()];
  }

  public static delete(clientUserId: ClientUserId): void {
    const clientUserCollection: { [key: string]: ClientUser } = store.getters['clientUsers/clientUsers'];
    delete clientUserCollection[clientUserId.toString()];
    store.dispatch('clientUsers/addClientUsers', clientUserCollection);
  }

  public static reset(): void {
    store.dispatch('clientUsers/resetClientUsers');
  }
}
