import EmployeeName from '@/domain/training/trainingEmployee/EmployeeName';
import EmployeeEmail from '@/domain/training/trainingEmployee/EmployeeEmail';
import Inscription from '@/domain/training/inscription/Inscription';
import TrainingEmployeeRepresentation
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepresentation';

export default class TrainingEmployee {
  private readonly name: EmployeeName;

  private email: EmployeeEmail | null = null;

  private inscriptions: Inscription[] = [];

  constructor(name: EmployeeName) {
    this.name = name;
  }

  public addEmail(email: EmployeeEmail) {
    this.email = email;
  }

  public addInscriptions(inscriptions: Inscription[]) {
    this.inscriptions = inscriptions;
  }

  public representedAs(
    trainingEmployeeRepresentation: TrainingEmployeeRepresentation,
  ): TrainingEmployeeRepresentation {
    trainingEmployeeRepresentation.setName(this.name);
    trainingEmployeeRepresentation.setEmail(this.email);
    trainingEmployeeRepresentation.setInscriptions(this.inscriptions);

    return trainingEmployeeRepresentation;
  }
}
