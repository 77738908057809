export default ({
  processingActivitiesRepository,
}) => async (activity) => {
  try {
    const response = await processingActivitiesRepository.createProcessingActivity(activity);
    return { success: true, data: response };
  } catch (e) {
    console.log(e);
    return { success: false, data: [] };
  }
};
