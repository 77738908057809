import { getManagement } from '@/shared/restActions';
import RecipientModelRepository from '@/domain/recipient/recipientModel/RecipientModelRepository';
import RecipientModel from '@/domain/recipient/recipientModel/RecipientModel';
import RecipientBusinessName from '@/domain/recipient/RecipientBusinessName';
import RecipientWarranty from '@/domain/recipient/recipientWarranties/RecipientWarranty';
import RecipientId from '@/domain/recipient/RecipientId';
import RecipientName from '@/domain/recipient/RecipientName';
import CountryCode from '@/domain/country/CountryCode';
import State from '@/domain/country/state/State';
import RecipientCategory from '@/domain/recipient/recipientCategory/RecipientCategory';
import RecipientDpa from '@/domain/recipient/RecipientDpa';
import RecipientRole from '@/domain/recipient/recipientRole/RecipientRole';
import RecipientModelCache from './RecipientModelCache';

export default class RecipientModelRepositoryApi extends RecipientModelRepository {
  async all() {
    try {
      let recipientsModels = RecipientModelCache.getRecipientsModels();

      if (recipientsModels.length) {
        return recipientsModels;
      }

      const recipientsModelsRaw = await this._getRecipientsModelsFromApi();

      recipientsModels = this._buildRecipientsModels(recipientsModelsRaw);
      RecipientModelCache.setRecipientsModels(recipientsModels);

      return recipientsModels;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching recipients models');
    }
  }

  async _getRecipientsModelsFromApi() {
    const response = await getManagement('/api/v2/recipients/models');
    return response.data;
  }

  _buildRecipientsModels(recipientsModelsRaw) {
    const recipientsModels = [];
    recipientsModelsRaw.forEach((model) => {
      const recipientModel = new RecipientModel(
        RecipientId.fromString(model.id), new RecipientName(model.name),
      );

      if (model.business_name) {
        recipientModel.addBusinessName(new RecipientBusinessName(model.business_name));
      }

      if (model.country) {
        recipientModel.addCountry(new CountryCode(model.country.toUpperCase()));
      }

      if (model.state) {
        recipientModel.addState(new State(model.state));
      }

      if (model.category) {
        recipientModel.addCategory(new RecipientCategory(model.category));
      }

      if (model.dpa) {
        recipientModel.addDpa(new RecipientDpa(model.dpa));
      }

      if (model.role) {
        recipientModel.addRole(new RecipientRole(model.role));
      }

      if (model.warranties.length) {
        const warranties = model.warranties.map((warranty) => new RecipientWarranty(warranty));
        recipientModel.addWarranties(warranties);
      }

      recipientsModels.push(recipientModel);
    });

    return recipientsModels;
  }
}
