<template>
  <div class="h-100 w-100">
    <v-row>
      <v-col cols="12">
        <title-section
          :title="$t('processingActivities.title')"
          :subtitle="$t('processingActivities.subtitle')"
        >
          <v-btn
            v-show="showDataMap"
            class="btn--show-on-hover"
            color="primary"
            @click="$router.push({name: 'data-map'})"
            v-track="'Show datamap'"
          >
            <v-icon class="btn--show-on-hover__icon">
              device_hub
            </v-icon>
            <span class="btn--show-on-hover__text">
              {{ $t('processingActivities.show_data_map') }}
            </span>
          </v-btn>
        </title-section>
      </v-col>
      <v-col cols="12" md="6">
        <input-search
          :value.sync="search"
          :label="$t('form.search')"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-right multiple-button"
      >
        <v-btn
          v-if="showButtonDownloadReport"
          rounded
          color="primary"
          @click="selectExtensionReportShow"
        >
          {{ $t('processingActivities.report.download.button') }}
        </v-btn>
        <feature
          name="b2b"
          :data="{ 'adminCompanyId': adminCompanyId }"
          class="feature"
        >
          <v-btn
            color="primary"
            rounded
            v-track="'Create processing activity -- start'"
            @click="newActivity()"
          >
            {{ $t('processingActivities.new_activity') }}
          </v-btn>
        </feature>
      </v-col>
      <v-col cols="12">
        <filters-name
          v-if="filtersCollection.filtersCountry.filters.length
        || filtersCollection.filtersLanguage.filters.length
        || filtersCollection.filtersDepartments.filters.length"
          :items.sync="filtersCollection"
          :selectedItems.sync="filtersSelected"
          section="processingActivities"
        />
      </v-col>
      <v-col cols="12">
        <default-table
          :items.sync="processingActivities"
          :headers="headers"
          :search.sync="search"
          :actions="['download', 'view']"
          :downloadType="['pdf', 'doc']"
          sortBy="title"
          @viewDocument="viewDocument"
          @downloadDocument="downloadPaDocument"
          @selectExtensionShow="selectExtensionShow"
        />
      </v-col>
    </v-row>
    <processing-activities-modal
      v-if="showModalFormPA"
      :show.sync="showModalFormPA"
      :processingActivityFormStatus="processingActivityFormStatus"
      @newActivityCreated="newActivityCreated"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import processingActivityDocumentsUseCases from '@/usecases/documents/processingActivities';
import { downloadDocument } from '@/application/document';
import {
  resetProcessingActivityForm,
  resetProcessingActivityFormStatus,
  fetchProcessingActivityFormStatus,
} from '@/application/processingActivity';
import uploadedDocumentUseCases from '@/usecases/documents/uploaded';
import feature from '@/shared/featureToggle';

import FiltersName from '@/components/base/section/FiltersName.vue';
import TitleSection from '../base/section/TitleSection.vue';
import InputSearch from '../base/form/InputSearch.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';
import ProcessingActivitiesModal from '../modules/processingActivities/ProcessingActivitiesModal.vue';

const STATUS_ACTIVE_PROCESSING_ACTIVITY = 1;
const TYPE_PROCESSING_ACTIVITY = 'processing-activity';
const PROCESSING_ACTIVITY_REPORT_DOC_TYPE = 'processing-activity-report';

export default {
  name: 'processing-activties-container',
  inject: {
    user: 'user',
  },
  components: {
    TitleSection,
    InputSearch,
    DefaultTable,
    FiltersName,
    ProcessingActivitiesModal,
    feature,
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
      userDepartments: 'departments',
    }),
    ...mapGetters('admin', {
      isSpanishAdmin: 'isSpanishAdmin',
      adminCompanyId: 'adminCompanyId',
    }),
    processingActivities() {
      if (this.allFiltersEmpty()) {
        return this.listDocuments;
      }

      return this.filterDocuments();
    },
    headers() {
      return [
        {
          text: this.$t('processingActivities.name_doc_table'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '30%',
        },
        {
          text: this.$t('processingActivities.status'),
          align: 'left',
          sortable: true,
          value: 'statusName',
          width: '10%',
        },
        {
          text: this.$t('processingActivities.department'),
          align: 'start',
          sortable: true,
          value: 'department',
          width: '15%',
        },
        {
          text: this.$t('processingActivities.country'),
          align: 'start',
          sortable: true,
          value: 'countryText',
          width: '15%',
        },
        {
          text: this.$t('processingActivities.language'),
          align: 'start',
          sortable: true,
          value: 'languageText',
          width: '15%',
        },
        {
          text: this.$t('processingActivities.last_updated'),
          align: 'start',
          sortable: true,
          value: 'updated',
          width: '10%',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
    showButtonDownloadReport() {
      if (!this.user.isSupervisor) {
        return false;
      }

      return !!this.listDocuments.find(
        (processing) => processing.type === TYPE_PROCESSING_ACTIVITY
          && processing.status === STATUS_ACTIVE_PROCESSING_ACTIVITY,
      );
    },
  },
  data() {
    return {
      search: '',
      listDocuments: [],
      filtersCollection: {
        filtersCountry: {
          name: this.$t('processingActivities.country'),
          filters: [],
          documentField: 'countryId',
        },
        filtersLanguage: {
          name: this.$t('processingActivities.language'),
          filters: [],
          documentField: 'language',
        },
        filtersDepartments: {
          name: this.$t('processingActivities.department'),
          filters: [],
          documentField: 'department',
        },
      },
      filtersSelected: {
        filtersCountry: [],
        filtersLanguage: [],
        filtersDepartments: [],
      },
      status: {
        1: {
          name: this.$t('processingActivities.status_name.active'),
        },
        2: {
          name: this.$t('processingActivities.status_name.draft'),
        },
        3: {
          name: this.$t('processingActivities.status_name.cancelled'),
        },
      },
      showDataMap: 0,
      showModalFormPA: false,
      processingActivityFormStatus: null,
    };
  },
  methods: {
    async getProcessingActivities(spainAdmin) {
      const response = await processingActivityDocumentsUseCases.fetchDocumentsList(
        this.clientId, this.$i18n.locale, spainAdmin, this.userIsAdmin,
      );
      if (response.success) {
        this.listDocuments = response.data;
        this.setDateText();
        this.setStatusText();
        this.getFilters();
        this.listDocuments.forEach((obj, key) => {
          this.setCountryText(key);
          this.setLanguageText(key);
          this.setDepartmentText(key);
        });
      } else {
        this.$showError('processingActivities.error.error_page_load');
      }
    },
    async getInfoDataMap() {
      const response = await processingActivityDocumentsUseCases.fetchDataMap(
        this.clientId, this.$i18n.locale,
      );

      if (!response.success) {
        return {};
      }

      return response.data;
    },
    async viewDocument(document) {
      this.$showLoading();

      const response = await processingActivityDocumentsUseCases.viewDocument(
        this.clientId,
        document.id,
        document.type,
        document.language || this.$i18n.locale,
        document.title,
        this.userIsAdmin,
      );

      if (!response.success) {
        this.$showError('processingActivities.error.error_view_document');
      }

      this.$hideLoading();
    },
    selectExtensionShow(document) {
      this.$eventHub.$emit('showOptionsExtensionFile', document);
    },
    selectExtensionReportShow() {
      this.$eventHub.$emit('showOptionsExtensionFile', PROCESSING_ACTIVITY_REPORT_DOC_TYPE);
    },
    async downloadReport(type) {
      this.$showLoading();

      const response = await downloadDocument.invoke(
        PROCESSING_ACTIVITY_REPORT_DOC_TYPE,
        this.clientId,
        type,
      );

      if (!response.success) {
        this.$showError('processingActivities.report.download.error');
      }

      this.$hideLoading();

      this.$track({
        action: 'Download the processing activity report',
        result: response.success,
        document_format: type,
      });
    },
    async downloadPaDocument(document, type = null) {
      if (document === PROCESSING_ACTIVITY_REPORT_DOC_TYPE) {
        await this.downloadReport(type);
        return;
      }
      this.$showLoading();

      let response = null;
      if (document.signedUrl) {
        response = await uploadedDocumentUseCases.downloadDocument(
          this.clientId, document.id, this.userIsAdmin,
        );
      } else {
        response = await processingActivityDocumentsUseCases.downloadDocument(
          this.clientId,
          document.id,
          document.type,
          type,
          document.language || this.$i18n.locale,
          this.userIsAdmin,
        );
      }

      if (!response.success) {
        this.$showError('processingActivities.error.error_download_document');
      }

      this.$hideLoading();
      this.$track({
        action: 'Download a processing activity',
        result: response.success,
        document_format: type,
      });
    },
    setStatusText() {
      Object.keys(this.listDocuments).forEach((key) => {
        if (this.listDocuments[key].status) {
          this.listDocuments[key].statusName = this.status[this.listDocuments[key].status].name;
        }
      });
    },
    setDateText() {
      Object.keys(this.listDocuments).forEach((key) => {
        if (this.listDocuments[key].lastModifiedDate) {
          const date = new Date(this.listDocuments[key].lastModifiedDate);
          this.listDocuments[key].updated = date.getTime();
        } else {
          this.listDocuments[key].updated *= 1000;
        }
      });
    },
    setCountryText(key) {
      this.listDocuments[key].countryText = this.listDocuments[key].countryId ? this.$t(`countries.by_id.country_${this.listDocuments[key].countryId}`) : '';
    },
    setLanguageText(key) {
      this.listDocuments[key].languageText = this.listDocuments[key].language ? this.$t(`languages.by_code.lang_${this.listDocuments[key].language}`) : '';
    },
    setDepartmentText(key) {
      this.listDocuments[key].department = this.listDocuments[key].department ? this.$t(`departments.${this.listDocuments[key].department}`) : '';
    },
    getFilters() {
      this.filtersCollection.filtersCountry.filters = [];
      this.filtersCollection.filtersLanguage.filters = [];
      this.filtersCollection.filtersDepartments.filters = [];
      const filtersCountryId = [];
      const filtersLanguageId = [];
      const filtersDepartmentName = [];
      this.listDocuments.forEach((obj) => {
        if (obj.countryId && !filtersCountryId.includes(obj.countryId)) {
          filtersCountryId.push(obj.countryId);
          this.filtersSelected.filtersCountry.push(obj.countryId);
        }
        if (obj.language && !filtersLanguageId.includes(obj.language)) {
          filtersLanguageId.push(obj.language);
          this.filtersSelected.filtersLanguage.push(obj.language);
        }

        if (obj.department && !filtersDepartmentName.includes(obj.department)) {
          filtersDepartmentName.push(obj.department);
          this.filtersSelected.filtersDepartments.push(this.$t(`departments.${obj.department}`));
        }
      });

      if (filtersCountryId.length > 1) {
        filtersCountryId.forEach((obj) => {
          this.filtersCollection.filtersCountry.filters.push({
            name: this.$t(`countries.by_id.country_${obj}`),
            id: obj,
          });
        });
      }
      if (filtersLanguageId.length > 1) {
        filtersLanguageId.forEach((obj) => {
          this.filtersCollection.filtersLanguage.filters.push({
            name: this.$t(`languages.by_code.lang_${obj}`),
            id: obj,
          });
        });
      }
      if (filtersDepartmentName.length > 1) {
        filtersDepartmentName.forEach((obj) => {
          this.filtersCollection.filtersDepartments.filters.push({
            name: this.$t(`departments.${obj}`),
            id: this.$t(`departments.${obj}`),
          });
        });
      }
    },
    newActivity() {
      resetProcessingActivityFormStatus.invoke();
      resetProcessingActivityForm.invoke();
      this.showModalFormPA = true;
    },
    async newActivityCreated() {
      this.$showLoading();
      this.showModalFormPA = false;

      await this.getProcessingActivities(this.isSpanishAdmin);

      if (!this.showDataMap) {
        const dataMap = await this.getInfoDataMap();
        this.showDataMap = Object.keys(dataMap).length;
      }

      this.$hideLoading();
    },
    allFiltersEmpty() {
      return this.filtersCollection.filtersCountry.filters.length === 0
        && this.filtersCollection.filtersLanguage.filters.length === 0
        && this.filtersCollection.filtersDepartments.filters.length === 0;
    },
    filterDocuments() {
      return this.listDocuments.filter((obj) => Object.keys(this.filtersSelected)
        .every((filterType) => {
          const documentFieldValue = obj[this.filtersCollection[filterType].documentField];
          return this.filtersSelected[filterType].includes(documentFieldValue)
            || !documentFieldValue;
        }));
    },
    async showProcessingActivityFormIfHaveStatusForm() {
      const response = fetchProcessingActivityFormStatus.invoke();

      if (!response.step) {
        return;
      }

      this.processingActivityFormStatus = response;
      this.showModalFormPA = true;
    },
  },
  async beforeMount() {
    this.$showLoading();

    this.showProcessingActivityFormIfHaveStatusForm();

    if (this.userIsAdmin && !this.clientId) {
      await this.$router.push({ name: 'home' });
      return;
    }

    await this.getProcessingActivities(this.isSpanishAdmin);
    const dataMap = await this.getInfoDataMap();
    this.showDataMap = Object.keys(dataMap).length;

    this.$eventHub.$on('downloadDocument', this.downloadPaDocument);

    this.$hideLoading();
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadPaDocument);
  },
  watch: {
    showModalFormPA() {
      if (!this.showModalFormPA) {
        resetProcessingActivityForm.invoke();
        resetProcessingActivityFormStatus.invoke();
        this.processingActivityFormStatus = null;
      }
    },
  },
};
</script>
