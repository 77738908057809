<template functional>
  <div
    class="material-design-icon file-word-icon"
    role="img"
  >
    <svg
      class="material-design-icon__svg"
      :width="120"
      :height="120"
      viewBox="0 0 24 24">
      <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,
        2M15.2,20H13.8L12,13.2L10.2,20H8.8L6.6,11H8.1L9.5,17.8L11.3,11H12.6L14.4,
        17.8L15.8,11H17.3L15.2,20M13,9V3.5L18.5,9H13Z">
      </path>
    </svg>
    <p class="extension-name">DOC</p>
  </div>
</template>

<script>
export default {
  name: 'FileWordIcon',
};
</script>
