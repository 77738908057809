<template>
  <span>
    <v-btn
      color="primary"
      rounded
      @click="openModalExtensionSelect"
    >
      {{ btnMsg }}
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'download-document-button',
  inject: {
    downloadDocument: 'downloadDocument',
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    btnMsg: {
      type: String,
      default: '',
    },
    docType: {
      type: String,
      default: null,
    },
    actionTrack: {
      type: String,
      default: null,
    },
  },
  methods: {
    openModalExtensionSelect() {
      this.$eventHub.$emit('showOptionsExtensionFile', { docType: this.docType, id: this.id });
    },
    trackIfNeeded(fileType, success) {
      if (this.actionTrack) {
        this.$track({ action: this.actionTrack, result: success, document_format: fileType });
      }
    },
    async downloadDocumentWithExtension(document, fileType) {
      this.$showLoading();
      const response = await this.downloadDocument.invoke(document.docType, document.id, fileType);
      this.$hideLoading();
      this.trackIfNeeded(fileType, response.success);
      if (!response.success) {
        this.$showError(this.errorMsg);
      }
    },
  },
  beforeMount() {
    this.$eventHub.$on('downloadDocument', this.downloadDocumentWithExtension);
  },
  beforeDestroy() {
    this.$eventHub.$off('downloadDocument', this.downloadDocumentWithExtension);
  },
};
</script>
