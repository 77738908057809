import EmployeeWasUpdatedEventListener
  from '@/application/training/employee/EmployeeWasUpdatedEventListener/EmployeeWasUpdatedEventListener';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';

export default class EmployeeWasUpdatedEventListenerBuilder {
  static build() {
    return new EmployeeWasUpdatedEventListener(
      new TrainingEmployeeRepositoryApi(),
    );
  }
}
