import EquipmentId from '@/domain/equipment/EquipmentId';
import EquipmentToUpdateRepresentation from '@/domain/equipment/EquipmentToUpdateRepresentation';
import EquipmentName from '@/domain/equipment/EquipmentName';
import EquipmentType from '@/domain/equipment/equipmentType/EquipmentType';
import EquipmentSecurityMeasures
  from '@/domain/equipment/equipmentSecurityMeasures/EquipmentSecurityMeasures';
import EquipmentLocation from '@/domain/equipment/equipmentLocation/EquipmentLocation';

function buildEquipmentToUpdateRepresentation(equipmentForm) {
  const equipmentTodUpdateRepresentation = new EquipmentToUpdateRepresentation();

  equipmentTodUpdateRepresentation.setName(
    EquipmentName.fromString(equipmentForm.name),
  );

  if (equipmentForm.type) {
    equipmentTodUpdateRepresentation.setType(
      EquipmentType.fromKey(equipmentForm.type),
    );
  }

  if (equipmentForm.location && Object.keys(equipmentForm.location).length) {
    equipmentTodUpdateRepresentation.setLocation(
      new EquipmentLocation(
        equipmentForm.location.type,
        equipmentForm.location.key,
      ),
    );
  }

  if (equipmentForm.securityMeasures) {
    equipmentTodUpdateRepresentation.setSecurityMeasures(
      equipmentForm.securityMeasures.map(
        (securityMeasure) => EquipmentSecurityMeasures.fromKey(securityMeasure),
      ),
    );
  }

  return equipmentTodUpdateRepresentation;
}

export default (
  equipmentRepository,
) => async (equipmentForm, equipmentId) => {
  try {
    const equipmentToUpdateId = EquipmentId.fromString(equipmentId);
    const equipment = await equipmentRepository.retrieve(equipmentToUpdateId);

    const equipmentTodUpdateRepresentation = buildEquipmentToUpdateRepresentation(equipmentForm);

    equipment.update(equipmentTodUpdateRepresentation);
    await equipmentRepository.update(equipment);

    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
