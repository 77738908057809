import AbstractHeadquarterRepresentation
  from '../../domain/headquarter/AbstractHeadquarterRepresentation';

export default class HeadquarterRepresentationApi extends AbstractHeadquarterRepresentation {
  toJson() {
    return JSON.stringify({
      id: this._id.toString(),
      clientId: this._clientId.toInt(),
      name: this._name.toString(),
      address: this._address.toString(),
      country: this._country.toString(),
      locality: this._locality.toString(),
      security_measures: this._securityMeasures ? this._securityMeasures.map(
        (securityMeasure) => securityMeasure.toString(),
      ) : [],
    });
  }
}
