import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import CreateClientUserCommand from '@/application/clientUser/createClientUser/CreateClientUserCommand';
import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import Language from '@/domain/language/Language';
import ClientId from '@/domain/client/ClientId';
import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import EventBus from '@/application/eventBus/EventBus';
import ClientUserWasCreated from '@/domain/clientUser/ClientUserWasCreated';
import ClientUser from '@/domain/clientUser/ClientUser';
import DepartmentId from '@/domain/department/DepartmentId';
import ClientUserId from '@/domain/clientUser/ClientUserId';

export default class CreateClientUserCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  private readonly eventBus: EventBus;

  constructor(
    clientUserRepository: ClientUserRepository,
    eventBus: EventBus,
  ) {
    this.clientUserRepository = clientUserRepository;
    this.eventBus = eventBus;
  }

  public async invoke(command: CreateClientUserCommand)
    : Promise<{ success: boolean, error?: string }> {
    try {
      const clientUser: ClientUser = this.buildClientUser(command);

      const userId: number = await this.clientUserRepository.create(
        ClientId.fromInt(command.clientId),
        clientUser,
        EmailValueObject.fromString(command.emailRepeat),
      );

      this.eventBus.handle(ClientUserWasCreated.fromClientUserId(ClientUserId.fromInt(userId)));

      return { success: true };
    } catch (e) {
      return { success: false, error: e.message || null };
    }
  }

  private buildClientUser(command: CreateClientUserCommand): ClientUser {
    const clientUserType: ClientUserType = ClientUserType.fromString(command.type);
    return new ClientUser(
      ClientUserEmail.fromString(command.emailInit),
      ClientUserName.fromString(command.name),
      ClientUserSurname.fromString(command.surname),
      clientUserType,
      clientUserType.isEmployee()
        ? this.buildDepartments(command.departments)
        : [],
      Language.fromString(command.locale),
    );
  }

  private buildDepartments(departments: string[]): DepartmentId[] {
    return departments.map((dept: string) => (DepartmentId.fromString(dept)));
  }
}
