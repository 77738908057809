import ListDepartmentQueryHandler
  from '@/application/department/listDepartment/query/listDepartment/ListDepartmentQueryHandler';
import DepartmentReadModelApi
  from '@/infrastructure/department/listDepartment/DepartmentReadModelApi';
import Translator from '@/infrastructure/Translator';
import ResetDepartmentCommandHandler
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommandHandler';
import DepartmentWriteModelApi
  from '@/infrastructure/department/listDepartment/DepartmentWriteModelApi';

export default {};

export const fetchListDepartment = new ListDepartmentQueryHandler(
  new DepartmentReadModelApi(),
  new Translator(),
);

export const resetListDepartment = new ResetDepartmentCommandHandler(
  new DepartmentWriteModelApi(),
);
