export default class RecipientModel {
  _id;

  _name;

  _country;

  _state;

  _category;

  _role;

  _warranties;

  _dpa;

  _businessName;

  constructor(id, name) {
    this._id = id;
    this._name = name;
  }

  addCountry(country) {
    this._country = country;
  }

  addState(state) {
    this._state = state;
  }

  addCategory(value) {
    this._category = value;
  }

  addRole(value) {
    this._role = value;
  }

  addWarranties(warranties) {
    this._warranties = warranties;
  }

  addDpa(dpa) {
    this._dpa = dpa;
  }

  addBusinessName(businessName) {
    this._businessName = businessName;
  }

  representedAs(recipientModelRepresentation) {
    recipientModelRepresentation.setId(this._id);
    recipientModelRepresentation.setName(this._name);
    recipientModelRepresentation.setCategory(this._category);
    recipientModelRepresentation.setRole(this._role);
    recipientModelRepresentation.setCountry(this._country);
    recipientModelRepresentation.setState(this._state);
    recipientModelRepresentation.setDpa(this._dpa);
    recipientModelRepresentation.setWarranties(this._warranties);
    recipientModelRepresentation.setBusinessName(this._businessName);

    return recipientModelRepresentation;
  }
}
