<template>
  <div id="data-map">
    <div fluid grid-list-xl class="pt-3 pb-0 p-l-0 p-r-30">
      <v-layout wrap>
        <v-flex xs12 sm12>
          <div class="text-xs-center mt-5" v-show="progressVisible">
              <v-progress-circular
                :size="100"
                :width="7"
                color="primary"
                indeterminate>
              </v-progress-circular>
              <p class="mt-4 body-1 font-weight-light">Cargando data map</p>
          </div>
          <div id="diagram" v-resize="onResize" ref="diagramrefs">
              <template>
                <diagram :model="model" :width="diagramWidth" :height="diagramHeight"
                  ref="pridatectDiagram"></diagram>
              </template>
          </div>
          <v-layout row>
            <v-flex
              shrink
              class="legend-container"
            >
            <span class="legend">
              <v-chip class="sensitive" />
              <span>{{ $t('processingActivities.data_map.sensitive') }}</span>
              <v-chip class="no-sensitive" />
              <span>{{ $t('processingActivities.data_map.no_sensitive') }}</span>
            </span>
            </v-flex>
            <v-flex
              grow
              pa-1
            >
            </v-flex>
            <v-flex
              shrink
              pa-1
              pr-3
              class="m-r-30"
            >
              <v-btn
                color="primary"
                rounded
                @click="cancelChanges"
              >
                {{ $t('processingActivities.data_map.restore') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import dataMapService from '@/services/dataMap/initialInfoComponents';

import { Diagram } from '@/components/modules/dataMap/vue-diagrams/src';

export default {
  props: {
    dataMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      progressVisible: null,
      diagramHeight: this.getHeigth(),
      diagramWidth: this.getWitdth(),
      model: new Diagram.Model(),
      diagramData: {
        serialized: '',
        highestY: 0,
        initDiagramApi: {
          activities: [],
        },
        initDiagram: dataMapService.getInitDiagram(),
        diagram: {
          activities: {},
        },
      },
      info: null,
    };
  },
  components: {
    Diagram,
  },
  created() {
    this.progressVisible = true;
  },
  mounted() {
    this.buildDiagramData(this.dataMap);
    // Ocultamos el loading
    this.progressVisible = false;

    this.initModel();
    const zoomControls = document.getElementById('svg-pan-zoom-controls').getAttribute('transform');
    const values = zoomControls.split(' ');
    const height = parseFloat(values[1]);
    document.getElementById('svg-pan-zoom-controls').setAttribute('transform', `translate(0,${height}) scale(0.75)`);
    this.diagramWidth = this.$refs.diagramrefs.clientWidth;
  },
  methods: {
    onResize() {
      this.diagramWidth = this.$refs.diagramrefs.clientWidth;
      this.diagramHeight = this.getHeigth();
    },
    getWitdth() {
      return window.innerWidth - 120;
    },
    getHeigth() {
      return window.innerHeight - 295;
    },
    cancelChanges() {
      this.initModel();
    },
    getSeralizedModel() {
      return this.$refs.pridatectDiagram.model.serialize();
    },
    simulateClick(elem) {
      // Create our event (with options)
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      // If cancelled, don't dispatch our event
      // eslint-disable-next-line no-unused-vars
      const canceled = !elem.dispatchEvent(evt);
    },
    initModel() {
      const component = this;
      this.model = new Diagram.Model();

      const diagramData = { ...this.diagramData };

      /* Clonamos los datos de entrada iniciales y los
      guardamos en una nueva variable para su posterior tratamiento */
      diagramData.diagram.activities = JSON.parse(
        JSON.stringify(diagramData.initDiagramApi.activities),
      );

      /* Contador de Nodos del Diagrama */
      let diagramNodeCounter = 0;

      /**
       * Variables del grid
       */
      const mxl = 20;
      const mxr = 20;
      const myt = 60;

      const maxWidth = 185;
      const separatorX = 100;
      const separatorY = 50;
      const maxHeight = 107;

      const offsetNode = maxWidth + separatorX;
      const widthSlot = mxl + (2 * (maxWidth)) + separatorX + mxr;

      const widthRow = this.getWitdth();

      // Calculamos el número de dataflows que caben en un row
      const slotsPerRow = Math.floor(widthRow / widthSlot);

      const struct = {};

      // Recorremos las actividades para hacer cálculo de cómo será la estructura en pantalla
      const activities = { ...diagramData.diagram.activities };

      let nslot = 1;
      let nrow = 1;

      Object.keys(activities).forEach((key) => {
        struct[activities[key].activity.id] = {};

        activities[key].dataflows_multidestino.forEach((multidestinoData) => {
          multidestinoData.origen.id = diagramNodeCounter += 1;

          // 1. origen
          struct[activities[key].activity.id][multidestinoData.origen.id] = {
            row: nrow,
            slot: nslot,
            offset: 0,
          };

          // Varios destinos
          multidestinoData.destinos.forEach((destino) => {
            destino.id = diagramNodeCounter += 1;

            struct[activities[key].activity.id][destino.id] = {
              row: nrow,
              slot: nslot,
              offset: offsetNode,
            };
          });

          nslot += 1;
          if (nslot > slotsPerRow) {
            nslot = 1;
            nrow += 1;
          }
        });

        activities[key].dataflows_multiorigen.forEach((multiorigenData) => {
          // Un destino
          multiorigenData.destino.id = diagramNodeCounter += 1;
          struct[activities[key].activity.id][multiorigenData.destino.id] = {
            row: nrow,
            slot: nslot,
            offset: offsetNode,
          };

          // 1..n destinos
          multiorigenData.origenes.forEach((origen) => {
            origen.id = diagramNodeCounter += 1;
            struct[activities[key].activity.id][origen.id] = {
              row: nrow,
              slot: nslot,
              offset: 0,
            };
          });

          nslot += 1;
          if (nslot > slotsPerRow) {
            nslot = 1;
            nrow += 1;
          }
        });

        nslot = 1;
        nrow += 1;
      });

      let offsetRow = 0;
      let offsetY = 0;
      let lastRow = 1;
      const nodeLinks = [];
      let nLink = 1;

      Object.keys(activities).forEach((key) => {
        let coordX = mxl;
        /* Le sumamos una pequeña distancia a la Y en la posición del título */
        const coordY = offsetY + 15;
        const activity = { ...activities[key].activity };

        const titleActivity = component.model.addNode(
          activity.title,
          coordX,
          coordY + 10,
          100,
          20,
          'title',
          '',
          '',
          '',
        );

        offsetY += 25;
        titleActivity.deleatable = false;
        const activityId = activities[key].activity.id;
        let heightRow = 0;

        offsetY -= offsetRow;

        // bucle origines
        activities[key].dataflows_multidestino.forEach((multidestinoData) => {
          const dataflowOrigen = multidestinoData.origen;
          let nodeId = dataflowOrigen.id;
          let position = struct[activityId][nodeId];

          // Si hay cambio de fila se incrementa el offset

          if (lastRow < position.row) {
            offsetY += offsetRow;

            lastRow = position.row;
            offsetRow = 0;
            heightRow = 0;
          }

          coordX = mxl + ((position.slot - 1) * widthSlot) + position.offset;

          let middle = offsetY + myt
            + (((multidestinoData.destinos.length * maxHeight)
            + (separatorY * (multidestinoData.destinos.length - 1))) / 2)
            - (maxHeight / 2);
          // origen
          const dataflowSrc = component.model.addNode(
            dataflowOrigen.title,
            coordX,
            middle,
            maxWidth,
            maxHeight,
            'dataflow',
            dataflowOrigen.icon,
            dataflowOrigen.class_bottom,
            dataflowOrigen.text_top,
            dataflowOrigen.text_bottom,
          );

          dataflowSrc.deleatable = false;
          const origenOutPort = dataflowSrc.addOutPort(' ', nodeId);

          // 1..n destinos
          let nNode = 0;

          multidestinoData.destinos.forEach((destino) => {
            nodeId = destino.id;
            position = struct[activityId][nodeId];
            coordX = ((position.slot - 1) * widthSlot) + position.offset;

            let nodeHeight = maxHeight;

            if (nNode > 0) {
              nodeHeight += separatorY;
            }

            middle = offsetY + myt + (nNode * nodeHeight);
            heightRow = myt + (nNode * nodeHeight) + (separatorY * 2);

            if (offsetRow < heightRow) {
              offsetRow = heightRow;
            }
            // destino
            const dataflowDest = component.model.addNode(
              destino.title,
              coordX,
              middle,
              maxWidth,
              maxHeight,
              'dataflow',
              destino.icon,
              destino.class_bottom,
              destino.text_top,
              destino.text_bottom,
            );
            dataflowDest.deleatable = false;
            const destinoInPort = dataflowDest.addInPort(' ', nodeId);
            // component.model.addLink(origenOutPort, destinoInPort);
            nodeLinks[nLink] = { src: origenOutPort, dest: destinoInPort };
            nLink += 1;
            nNode += 1;
          });
        });

        activities[key].dataflows_multiorigen.forEach((multiorigenData) => {
          const dataflowDestino = multiorigenData.destino;
          let nodeId = dataflowDestino.id;
          let position = struct[activityId][nodeId];

          // Si hay cambio de fila se incrementa el offset
          if (lastRow < position.row) {
            offsetY += offsetRow;
            lastRow = position.row;
            offsetRow = 0;
            heightRow = 0;
          }

          coordX = ((position.slot - 1) * widthSlot) + position.offset;
          let middle = offsetY + myt + (((multiorigenData.origenes.length * maxHeight)
            + (separatorY * (multiorigenData.origenes.length - 1))) / 2) - (maxHeight / 2);
          const dataflowSrc = component.model.addNode(
            dataflowDestino.title,
            coordX,
            middle,
            maxWidth,
            maxHeight,
            'dataflow',
            dataflowDestino.icon,
            dataflowDestino.class_bottom,
            dataflowDestino.text_top,
            dataflowDestino.text_bottom,
          );

          dataflowSrc.deleatable = false;
          const destinoOutPort = dataflowSrc.addInPort(' ', nodeId);

          // 1..n origenes
          let nNode = 0;

          multiorigenData.origenes.forEach((origen) => {
            nodeId = origen.id;
            position = struct[activityId][nodeId];
            coordX = mxl + ((position.slot - 1) * widthSlot) + position.offset;

            let nodeHeight = maxHeight;
            if (nNode > 0) {
              nodeHeight += separatorY;
            }

            middle = offsetY + myt + (nNode * nodeHeight);
            heightRow = myt + (nNode * nodeHeight) + (separatorY * 2);

            if (offsetRow < heightRow) {
              offsetRow = heightRow;
            }

            const dataflowOrigen = component.model.addNode(
              origen.title,
              coordX,
              middle,
              maxWidth,
              maxHeight,
              'dataflow',
              origen.icon,
              origen.class_bottom,
              origen.text_top,
              origen.text_bottom,
            );

            dataflowOrigen.deleatable = false;
            const origenInPort = dataflowOrigen.addOutPort(' ', nodeId);

            // component.model.addLink(origenInPort, destinoOutPort);
            nodeLinks[nLink] = { src: origenInPort, dest: destinoOutPort };
            nLink += 1;
            nNode += 1;
          });
        });
        // Cuando cambiamos de actividad reiniciamos variables locales de altura
        offsetY += heightRow;
        heightRow = 0;
      });

      // Montamos los links
      nodeLinks.forEach((links) => {
        component.model.addLink(links.src, links.dest);
      });
    },
    isEmptyJson(obj) {
      // eslint-disable-next-line consistent-return
      obj.forEach((prop) => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      });
      return JSON.stringify(obj) === JSON.stringify({});
    },
    buildDiagramData(json) {
      const self = this;

      // eslint-disable-next-line no-restricted-syntax
      for (const activityId in json) {
        // skip loop if the property is from prototype
        // eslint-disable-next-line no-prototype-builtins
        if (!json.hasOwnProperty(activityId)) {
          // eslint-disable-next-line no-continue
          continue;
        }

        // var activity = json[activityId];
        const activityMapped = self.buildActivityData(json[activityId]);

        self.diagramData.initDiagramApi.activities.push(activityMapped);
      }
    },
    buildActivityData(activityAPI) {
      const activityData = {};

      activityData.activity = {};
      activityData.activity.id = activityAPI.id;
      activityData.activity.title = activityAPI.name;
      activityData.activity.x = null;
      activityData.activity.y = null;
      activityData.activity.icon = null;
      activityData.activity.class_bottom = null;

      this.buildDataFlow(activityData, activityAPI);

      return activityData;
    },
    buildDataFlow(activityData, activityAPI) {
      const self = this;

      activityData.dataflows_multidestino = [];
      activityData.dataflows_multiorigen = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const dataFlowId in activityAPI.data_flows) {
        // skip loop if the property is from prototype
        // eslint-disable-next-line no-prototype-builtins
        if (!activityAPI.data_flows.hasOwnProperty(dataFlowId)) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const dataFlowJson = activityAPI.data_flows[dataFlowId];

        if (dataFlowJson.data_source.length > 1 && dataFlowJson.receivers.length > 1) {
          dataFlowJson.data_source.forEach((dataSource) => {
            const dataFlowMultiDestinoData = self.buildActivityDataFlowMultiOrigenMultiDestino(activityData, dataSource, dataFlowJson.receivers);
            activityData.dataflows_multidestino.push(dataFlowMultiDestinoData);
          });
        } else if (dataFlowJson.data_source.length > 1) {
          const dataFlowMultiOrigenData = self.buildActivityDataFlowMultiOrigen(activityData, dataFlowJson);
          activityData.dataflows_multiorigen.push(dataFlowMultiOrigenData);
        } else {
          const dataFlowMultiDestinoData = self.buildActivityDataFlowMultiDestino(activityData, dataFlowJson);
          activityData.dataflows_multidestino.push(dataFlowMultiDestinoData);
        }
      }

      return activityData;
    },
    buildActivityDataFlowMultiDestino(activityData, dataFlowJson) {
      const self = this;

      const dataFlowMultiDestinoData = {};
      dataFlowMultiDestinoData.destinos = [];
      const origen = {};

      dataFlowJson.receivers.forEach((receiver) => {
        const destino = {};
        destino.id = receiver.id;
        destino.title = this.$t('processingActivities.data_map.receiver');
        destino.x = null;
        destino.y = null;
        destino.icon = null;
        destino.text_top = receiver.name;
        destino.text_bottom = ((receiver || {}).receiver_category || {}).name || '';
        destino.class_bottom = 'interno';

        dataFlowMultiDestinoData.destinos.push(destino);
      });

      dataFlowJson.data_source.forEach((dataSource) => {
        origen.id = dataSource.id;
        origen.title = this.$t('processingActivities.data_map.source');
        origen.x = null;
        origen.y = null;
        origen.icon = self.getOrigenIcon(dataSource.id);
        origen.text_top = null;
        origen.text_bottom = dataSource.name;

        if (dataSource.sensitive === true) {
          origen.class_bottom = 'sensitive';
        } else {
          origen.class_bottom = 'no-sensitive';
        }
      });
      dataFlowMultiDestinoData.origen = origen;

      return dataFlowMultiDestinoData;
    },
    buildActivityDataFlowMultiOrigen(activityData, dataFlowJson) {
      const self = this;

      const dataFlowMultiOrigenData = {};
      dataFlowMultiOrigenData.origenes = [];
      const destino = {};

      dataFlowJson.data_source.forEach((dataSource) => {
        const origen = {};
        origen.id = dataSource.id;
        origen.title = this.$t('processingActivities.data_map.source');
        origen.x = null;
        origen.y = null;
        origen.icon = self.getOrigenIcon(dataSource.id);
        origen.text_top = null;
        origen.text_bottom = dataSource.name;

        if (dataSource.sensitive === true) {
          origen.class_bottom = 'sensitive';
        } else {
          origen.class_bottom = 'no-sensitive';
        }

        dataFlowMultiOrigenData.origenes.push(origen);
      });

      dataFlowJson.receivers.forEach((receiver) => {
        destino.id = receiver.id;
        destino.title = this.$t('processingActivities.data_map.receiver');
        destino.x = null;
        destino.y = null;
        destino.icon = null;
        destino.text_top = receiver.name;
        destino.text_bottom = ((receiver || {}).receiver_category || {}).name || '';
        destino.class_bottom = 'interno';
      });
      dataFlowMultiOrigenData.destino = destino;

      return dataFlowMultiOrigenData;
    },
    buildActivityDataFlowMultiOrigenMultiDestino(activityData, dataSource, receivers) {
      const self = this;

      const dataFlowMultiDestinoData = {};
      dataFlowMultiDestinoData.destinos = [];
      const origen = self.buildOrigen(dataSource);

      dataFlowMultiDestinoData.origen = origen;

      if (receivers.length !== 0) {
        receivers.forEach((receiver) => {
          const destino = {};
          destino.id = receiver.id;
          destino.title = this.$t('processingActivities.data_map.receiver');
          destino.x = null;
          destino.y = null;
          destino.icon = null;
          destino.text_top = receiver.name;
          destino.text_bottom = ((receiver || {}).receiver_category || {}).name || '';
          destino.class_bottom = 'interno';
          dataFlowMultiDestinoData.destinos.push(destino);
        });
      }

      return dataFlowMultiDestinoData;
    },
    getOrigenIcon(origenId) {
      let icon;

      switch (origenId) {
        case 1:
          icon = 'data-subject-directly';
          break;
        case 2:
          icon = 'third-party';
          break;
        case 3:
          icon = 'self-generated';
          break;
        case 4:
          icon = 'public-source';
          break;
        case 5:
          icon = 'other-processing-activity';
          break;
        case 6:
          icon = 'legal-representative';
          break;
        case 7:
          icon = 'data-processor';
          break;
        case 8:
          icon = 'data-controller';
          break;
        default:
          icon = '';
          break;
      }

      return icon;
    },
    buildOrigen(dataSource) {
      const self = this;

      const origen = {};

      origen.id = dataSource.id;
      origen.title = this.$t('processingActivities.data_map.source');
      origen.x = null;
      origen.y = null;
      origen.icon = self.getOrigenIcon(dataSource.id);
      origen.text_top = null;
      origen.text_bottom = dataSource.name;

      if (dataSource.sensitive === true) {
        origen.class_bottom = 'sensitive';
      } else {
        origen.class_bottom = 'no-sensitive';
      }
      return origen;
    },
  },
};
</script>
