export default class RecipientReplaceWasRequested {
  _recipientIdToReplace;

  _replacementRecipientId;

  _ocurredOn;

  constructor(recipientIdToReplace, replacementRecipientId) {
    this._ocurredOn = new Date();
    this._recipientIdToReplace = recipientIdToReplace;
    this._replacementRecipientId = replacementRecipientId;
  }

  getRecipientIdToReplace() {
    return this._recipientIdToReplace;
  }

  getReplacementRecipientId() {
    return this._replacementRecipientId;
  }
}
