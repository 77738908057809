const ADD_AVAILABLE_LANGUAGES = (state, available) => {
  state.availableLanguages = available;
};

const ADD_LANGUAGE = (state, language) => {
  state.language = language;
};

const ADD_STATUS = (state, status) => {
  state.status = status;
};

const RESET_AVAILABLE_LANGUAGES = (state) => {
  state.availableLanguages = [];
};

const RESET_LANGUAGE = (state) => {
  state.language = null;
};

const RESET_STATUS = (state) => {
  state.status = null;
};

const ADD_TRAINING_EMPLOYEES = (state, trainingEmployees) => {
  state.trainingEmployees = trainingEmployees;
};

const RESET_TRAINING_EMPLOYEES = (state) => {
  state.trainingEmployees = [];
};

export default {
  ADD_AVAILABLE_LANGUAGES,
  ADD_LANGUAGE,
  ADD_STATUS,
  ADD_TRAINING_EMPLOYEES,
  RESET_AVAILABLE_LANGUAGES,
  RESET_LANGUAGE,
  RESET_STATUS,
  RESET_TRAINING_EMPLOYEES,
};
