export default ({
  taskRepository,
}) => async () => {
  try {
    await taskRepository.resetStoreTasksList();

    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};
