<template>
  <v-row
    justify="start"
    dense
    class="icon-radio-button primary_lighten"
  >
    <v-col
      v-for="item in items"
      :key="item.value"
      cols="auto"
    >
      <v-btn
        plain
        :ripple="false"
        class="clear-hover"
        @click="changeValue(item)"
      >
        <v-icon
          :color="getColor(item)"
        >
          {{ item.icon }}
        </v-icon>
        {{ item.name }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'icon-radio-button',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  methods: {
    changeValue(item) {
      const result = item.value === this.value ? this.defaultValue : item.value;
      this.$emit('update:value', result);
    },
    getColor(item) {
      return item.value === this.value ? item.color : 'grey';
    },
  },
};

</script>
