<template>
  <div class="h-100 w-100">
    <v-row class="pl-5 data-breach__form-step data-breach__form-step__dpo-answer">
      <h3 class="mb-2 ml-5">
        <span class="grey--text text--darken-3">
          {{ $t('data_breach.form.step.dpo.title') }}
        </span>
      </h3>
      <v-col cols="12">
        <div class="grey lighten-3 pa-5 mb-5 rounded">
          <span class="mb-0">{{ $t('data_breach.form.step.dpo.title_info') }}</span>
        </div>
      </v-col>
      <v-col cols="12">
        <h4>
          <span class="grey--text text--darken-3">
            {{ $t('data_breach.form.step.dpo.question_1') }} *
          </span>
        </h4>
        <p>
          {{ $t('data_breach.form.step.dpo.question_1_info') }}
        </p>
        <textarea-field
          :value.sync="dataBreach.evaluation"
          :counter="evaluationMaxLength"
          :counterValue="textCounter"
          :rules="[validations.maxLength, validations.requiredText]"
        />
      </v-col>
      <v-col cols="12">
        <h4>
          <span class="grey--text text--darken-3">
            {{ $t('data_breach.form.step.dpo.question_2') }} *
          </span>
        </h4>
        <v-radio-group
          v-model="dataBreach.agencyNotification"
          row
          mandatory
        >
          <v-radio
            :label="$t('data_breach.form.step.dpo.question_2_yes')"
            :value="true"
          />
          <v-radio
            :label="$t('data_breach.form.step.dpo.question_2_no')"
            :value="false"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <h4>
          <span class="grey--text text--darken-3">
            {{ $t('data_breach.form.step.dpo.question_3') }} *
          </span>
        </h4>
          <v-radio-group
            v-model="dataBreach.reportLanguage"
            row
            mandatory
          >
            <v-radio
              v-for="language in availableLanguages"
              :key="language.code"
              :label="language.name"
              :value="language.code"
            />
          </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextareaField from '@/components/base/form/TextareaField.vue';

const EVALUATION_MAX_LENGTH = 1500;

export default {
  name: 'dpo-answers',
  inject: {
    user: 'user',
    fetchLanguagesOfClient: 'fetchLanguagesOfClient',
  },
  components: { TextareaField },
  props: {
    dataBreach: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      evaluationMaxLength: EVALUATION_MAX_LENGTH,
      validations: {
        requiredText: (v) => !!v.trim() || this.$t('form.validations.required_field'),
        maxLength: (v) => v.trim().length <= EVALUATION_MAX_LENGTH || this.$t('form.validations.max_length', { length: EVALUATION_MAX_LENGTH }),
      },
      availableLanguages: [],
    };
  },
  methods: {
    textCounter(text) {
      return text.trim().length;
    },
    async loadAvailableLanguages() {
      const response = await this.fetchLanguagesOfClient.invoke(this.user.clientId);
      if (!response.success) {
        this.$showError('data_breach.errorPage.error_load_form');
      }
      this.availableLanguages = response.languages;
      this.setDataBreachLanguageIfNeeded();
    },
    setDataBreachLanguageIfNeeded() {
      if (this.dataBreach.reportLanguage !== '') {
        return;
      }
      this.availableLanguages.forEach((language) => {
        if (language.code === this.$i18n.locale) {
          this.dataBreach.reportLanguage = language.code;
        }
      });
    },
  },
  beforeMount() {
    this.loadAvailableLanguages();
  },
};
</script>
