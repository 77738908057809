<template>
  <delete-button-alert
    @delete="removeRecipient"
    :disabledButton="eliminationIsActive"
    actionTracker="Delete Recipient --start"
  >
    <template v-slot:alert-title>
      {{ $t('recipient.delete.title') }}
    </template>
    <template v-slot:text-alert>
      {{ $t('recipient.delete.alert') }}
    </template>
    <template v-slot:body-text>
      {{ $t('recipient.delete.deletion') }}
      <strong>{{ recipientToDelete.name }}</strong><br/>
      {{ $t('recipient.delete.sure') }}
    </template>
  </delete-button-alert>
</template>

<script>
import DeleteButtonAlert from '@/components/base/tables/DeleteButtonAlert.vue';

export default {
  name: 'remove-recipient',
  inject: {
    deleteRecipient: 'deleteRecipient',
  },
  components: {
    DeleteButtonAlert,
  },
  props: {
    recipientToDelete: {
      type: Object,
      default: null,
    },
  },
  computed: {
    eliminationIsActive() {
      return !!this.recipientToDelete.replacedBy || this.recipientToDelete.isReplacement;
    },
  },
  methods: {
    async removeRecipient() {
      this.$showLoading();
      const response = await this.deleteRecipient(this.recipientToDelete.id);
      this.$hideLoading();
      this.showAlert = false;

      if (!response.success) {
        this.$showError('recipient.delete.error');
        this.$track({ action: 'Delete Recipient --error' });
        return;
      }

      this.$showSuccess('recipient.delete.success');
      this.$eventHub.$emit('recipientWasDeleted');
      this.$track({ action: 'Delete Recipient --success' });
    },
  },
};
</script>
