<template>
  <v-row class="info-model-activity">
    <v-col cols="12" md="6" class="info-model-activity__left">
      <div>
        <p>
          {{ $t('processing_activities.info_model.country') }}
          <strong v-if="countryId">
            {{ $t(`countries.by_id.country_${countryId}`) }}
          </strong>
        </p>
        <p>
          {{ $t('processing_activities.info_model.language') }}
          <strong v-if="language">
            {{ $t(`languages.by_code.lang_${language}`) }}
          </strong>
        </p>
      </div>
      <v-btn
        v-if="editCountryLanguage"
        outlined
        color="primary"
        icon
        v-track="'Change processing activity language or country -- start'"
        @click="$emit('editCountryLanguage', true)"
      >
        <v-icon>
          edit
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" md="6" class="text-right info-model-activity__right">
      <p v-if="department">
        {{ department }}
      </p>
      <p>
        {{ processingActivityName }}
      </p>
    </v-col>
    <v-col cols="12">
      <v-divider/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'info-model-activity',
  props: {
    countryId: {
      type: Number,
      default: 0,
    },
    language: {
      type: String,
      default: 'es',
    },
    editCountryLanguage: {
      type: Boolean,
      default: true,
    },
    department: {
      type: String,
      default: null,
    },
    processingActivityName: {
      type: String,
      default: null,
    },
  },
};
</script>
