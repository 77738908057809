import ClientSelectedWasChangedEventListenerDataBreach
  from '@/application/dataBreach/resetDataBreaches/ClientSelectedWasChangedEventListener';
import ClientSelectedWasChangedEventListenerHistoric
  from '@/application/dataBreach/historic/resetDataBreaches/ClientSelectedWasChangedEventListener';
import ClientSelectedWasChangedEventListenerPending
  from '@/application/dataBreach/pending/resetDataBreachesPending/ClientSelectedWasChangedEventListener';
import ClientSelectedWasChangedEventListenerTrainingEmployee
  from '@/application/training/employee/resetTrainingEmployees/ClientSelectedWasChangedEventListener';
import ClientSelectedWasChangedEventListenerDepartment
  from '@/application/department/resetDepartments/ClientSelectedWasChangedEventListener';
import DataBreachRepositoryApi from '@/infrastructure/dataBreach/DataBreachRepositoryApi';
import EventListener from '@/application/eventBus/EventListener';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';
import ClientSelectedWasChangedEventListenerTom
  from '@/application/tom/resetTom/ClientSelectedWasChangedEventListener';
import TomRepositoryApi from '@/infrastructure/tom/TomRepositoryApi';
import DataBreachesPendingWriteModelApi
  from '@/infrastructure/dataBreach/pending/DataBreachesPendingWriteModelApi';
import DataBreachHistoricWriteModelApi
  from '@/infrastructure/dataBreach/historic/DataBreachHistoricWriteModelApi';
import DepartmentRepositoryApi from '@/infrastructure/department/DepartmentRepositoryApi';
import ClientSelectedWasChangedEventListenerListDepartment
  from '@/application/department/listDepartment/command/resetDepartment/ClientSelectedWasChangedEventListener';
import ResetDepartmentCommandHandler
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommandHandler';
import DepartmentWriteModelApi
  from '@/infrastructure/department/listDepartment/DepartmentWriteModelApi';

const listenersContext: {[key: string]: EventListener} = {
  DataBreach: new ClientSelectedWasChangedEventListenerDataBreach(new DataBreachRepositoryApi()),
  DataBreachHistoric: new ClientSelectedWasChangedEventListenerHistoric(
    new DataBreachHistoricWriteModelApi(),
  ),
  DataBreachPending: new ClientSelectedWasChangedEventListenerPending(
    new DataBreachesPendingWriteModelApi(),
  ),
  TrainingEmployee: new ClientSelectedWasChangedEventListenerTrainingEmployee(
    new TrainingEmployeeRepositoryApi(),
  ),
  Tom: new ClientSelectedWasChangedEventListenerTom(new TomRepositoryApi()),
  Department: new ClientSelectedWasChangedEventListenerDepartment(new DepartmentRepositoryApi()),
  ListDepartment: new ClientSelectedWasChangedEventListenerListDepartment(
    new ResetDepartmentCommandHandler(new DepartmentWriteModelApi()),
  ),
};

export default class ClientSelectedWasChangedEventListenerBuilder {
  static build(context: string) {
    return listenersContext[context];
  }
}
