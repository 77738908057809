import DepartmentId from '@/domain/department/DepartmentId';
import DepartmentReadModel from '@/domain/department/listDepartment/DepartmentReadModel';

export default class ObtainDepartmentName {
  private readModel: DepartmentReadModel;

  constructor(readModel: DepartmentReadModel) {
    this.readModel = readModel;
  }

  public invoke(departmentId: DepartmentId) {
    return this.readModel.retrieve(departmentId).name;
  }
}
