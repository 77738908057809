const ADD_DATA_BREACHES_HISTORIC = (state, dataBreachesHistoric) => {
  state.dataBreachesHistoric = dataBreachesHistoric;
};

const ADD_DATA_BREACHES = (state, dataBreaches) => {
  state.dataBreaches = dataBreaches;
};

const ADD_DATA_BREACHES_PENDING = (state, dataBreachesPending) => {
  state.dataBreachesPending = dataBreachesPending;
};

const RESET_DATA_BREACHES_HISTORIC = (state) => {
  state.dataBreachesHistoric = {};
};

const RESET_DATA_BREACHES = (state) => {
  state.dataBreaches = {};
};

const RESET_DATA_BREACHES_PENDING = (state) => {
  state.dataBreachesPending = {};
};

export default {
  ADD_DATA_BREACHES_HISTORIC,
  ADD_DATA_BREACHES,
  ADD_DATA_BREACHES_PENDING,
  RESET_DATA_BREACHES_HISTORIC,
  RESET_DATA_BREACHES,
  RESET_DATA_BREACHES_PENDING,
};
