<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @click:outside="$emit('update:show', false)"
    @keydown.esc="$emit('update:show', false)"
  >
    <v-card class="modal-deletion">
      <v-card-title>
        <h2>
          <slot name="alert-title"/>
        </h2>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:show', false)"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert
          text
          prominent
          type="error"
          icon="priority_high"
          color="error"
          border="left"
          class="modal-deletion__alert"
        >
          <span>
            <slot name="text-alert"/>
          </span>
        </v-alert>
        <p>
          <slot name="body-text"/>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('update:show', false)"
        >
          {{ $t('deletion.cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          @click="$emit('confirm')"
        >
          {{ $t('deletion.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'alert-deletion',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
