import { getManagement } from '@/shared/restActions';
import DataBreachesPendingReadModel from '@/domain/dataBreach/pending/DataBreachesPendingReadModel';
import ClientId from '@/domain/client/ClientId';
import DataBreachPendingRaw from '@/infrastructure/dataBreach/pending/DataBreachPendingRaw';
import DataBreachesPendingRepresentation
  from '@/application/dataBreach/pending/fetchDataBreachesPending/DataBreachesPendingRepresentation';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachCreatedDate from '@/domain/dataBreach/DataBreachCreatedDate';
import DataBreachPendingCache from '@/infrastructure/dataBreach/pending/DataBreachPendingCache';
import DataBreachCreatedBy from '@/domain/dataBreach/DataBreachCreatedBy';

export default class DataBreachesPendingReadModelApi implements DataBreachesPendingReadModel {
  async ofClient(clientId:ClientId): Promise<DataBreachesPendingRepresentation[]> {
    try {
      let dataBreachesPending: DataBreachesPendingRepresentation[] = DataBreachPendingCache.all();

      if (dataBreachesPending.length) {
        return dataBreachesPending;
      }

      const dataBreachesPendingRaw: DataBreachPendingRaw[] = await DataBreachesPendingReadModelApi
        .getDataBreachesPendingFromApi(clientId);

      dataBreachesPending = this
        .buildDataBreachesPendingRepresentation(dataBreachesPendingRaw);

      DataBreachPendingCache.saveAll(dataBreachesPending);

      return dataBreachesPending;
    } catch (e) {
      throw new Error('Error fetching pending data breaches');
    }
  }

  private static async getDataBreachesPendingFromApi(clientId: ClientId)
    : Promise<DataBreachPendingRaw[]> {
    const response = await getManagement(
      `/api/v2/clients/${clientId.toString()}/data-breaches/in-progress`,
    );
    return response.data;
  }

  private buildDataBreachesPendingRepresentation(dataBreachesPendingRaw: DataBreachPendingRaw[])
    : DataBreachesPendingRepresentation[] {
    const dataBreachPending: DataBreachesPendingRepresentation[] = [];

    dataBreachesPendingRaw.forEach((dataBreachRaw: DataBreachPendingRaw) => {
      const dataBreachPendingRepresentation
        : DataBreachesPendingRepresentation = new DataBreachesPendingRepresentation();

      dataBreachPendingRepresentation.setId(DataBreachId.fromString(dataBreachRaw.id));
      dataBreachPendingRepresentation.setCreatedDate(DataBreachCreatedDate.fromW3CDate(
        dataBreachRaw.created_date,
      ));
      dataBreachPendingRepresentation.setCreatedBy(
        DataBreachCreatedBy.fromString(dataBreachRaw.created_by),
      );

      dataBreachPending.push(dataBreachPendingRepresentation);
    });

    return dataBreachPending;
  }
}
