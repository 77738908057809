<template>
  <v-card-actions class="banner-download">
    <v-row align="center">
      <v-col cols="12" class="banner-download__form">
        <v-row align="center">
          <p>{{ fileName }}</p>
          <p>{{ $t('banner_footer.download.label') }}</p>
          <div
            v-if="multipleType"
          >
            <v-checkbox
                v-for="fileType in fileTypes"
                :key="fileType"
                v-model="fileTypesSelected"
                hide-details
                color="primary"
                :label="fileType"
                :value="fileType"
            />
          </div>
          <v-radio-group
            v-else
            v-model="fileTypesSelected"
            row
          >
            <v-radio
              v-for="fileType in fileTypes"
              :key="fileType"
              :label="fileType"
              :value="fileType"
              hide-details
              color="primary"
            />
          </v-radio-group>
        </v-row>
      </v-col>
      <v-col cols="12">
        <alert-fixed
          v-if="showAlertSelection"
          type="error"
        >
          {{ $t('banner_footer.download.alert') }}
        </alert-fixed>
      </v-col>
      <v-col cols="12" class="text-right banner-download__button">
        <v-btn
            color="primary"
            outlined
            rounded
            @click="$emit('update:show', false)"
        >
          {{ $t('banner_footer.download.cancel') }}
        </v-btn>
        <v-btn
            color="primary"
            rounded
            @click="download(fileTypesSelected)"
        >
          {{ $t('banner_footer.download.accept') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>

import AlertFixed from '@/components/base/feedback/AlertFixed.vue';

export default {
  name: 'banner-footer-download',
  components: {
    AlertFixed,
  },
  inject: {
    downloadDocument: 'downloadDocument',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    multipleType: {
      type: Boolean,
      default: true,
    },
    methodDownloadUseCase: {
      type: Function,
      default: null,
    },
    typeDocument: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    fileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileTypes: ['doc', 'pdf'],
      fileTypesSelected: this.multipleType ? ['doc', 'pdf'] : 'pdf',
      showAlertSelection: false,
    };
  },
  methods: {
    async download(fileTypes) {
      if (!fileTypes.length) {
        this.showAlertSelection = true;
        return;
      }

      this.showAlertSelection = false;
      if (this.methodDownloadUseCase) {
        this.methodDownloadUseCase(fileTypes);
        return;
      }

      const fileTypesString = Array.isArray(fileTypes) ? fileTypes.join('-') : fileTypes;
      const response = await this.downloadDocument.invoke(
        this.typeDocument, this.id, fileTypesString,
      );

      if (!response.success) {
        this.$showError('employee.document_data.download.error');
        return;
      }

      this.$emit('update:show', false);
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.fileTypesSelected = this.multipleType ? ['doc', 'pdf'] : 'pdf';
        this.showAlertSelection = false;
      }
    },
  },
};
</script>
