import ClientId from '@/domain/client/ClientId';
import DataBreachesHistoricRepresentation
  from '@/application/dataBreach/historic/fetchDataBreaches/DataBreachesHistoricRepresentation';
import DataBreachHistoricReadModel from '@/domain/dataBreach/historic/DataBreachHistoricReadModel';

export default class FetchDataBreachesHistoric {
  private readonly dataBreachHistoricReadModel: DataBreachHistoricReadModel;

  constructor(
    dataBreachRepository: DataBreachHistoricReadModel,
  ) {
    this.dataBreachHistoricReadModel = dataBreachRepository;
  }

  async invoke(clientId: number): Promise<object> {
    try {
      const listDataBreach: DataBreachesHistoricRepresentation[] = await this
        .dataBreachHistoricReadModel.ofClient(ClientId.fromInt(clientId));

      return {
        success: true,
        dataBreaches: listDataBreach,
      };
    } catch (e) {
      return { success: false };
    }
  }
}
