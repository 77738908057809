import IntIdValidationException from '@/domain/shared/intId/IntIdValidationException';

export default class IntId {
  private readonly _id: number;

  protected constructor(id: number) {
    this.isValidOrFail(id);
    this._id = id;
  }

  private isValidOrFail(id: number) {
    if (!Number.isInteger(id)) {
      throw new IntIdValidationException(`${this.constructor.name} should be a number`);
    }

    if (id <= 0) {
      throw new IntIdValidationException(`${this.constructor.name} should not be less than 0 or equals`);
    }
  }

  public static fromInt(id: number): IntId {
    return new this(id);
  }

  public static fromString(id: string): IntId {
    return new this(Number(id));
  }

  public toString(): string {
    return String(this._id);
  }

  public toInt(): number {
    return this._id;
  }
}
