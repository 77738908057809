import store from '@/store';

export default class HeadquarterCache {
  static resetHeadquarters() {
    return store.dispatch('headquarter/resetHeadquarters');
  }

  static getHeadquarters() {
    return store.getters['headquarter/headquarters'];
  }

  static setHeadquarters(headquarters) {
    store.dispatch('headquarter/addHeadquarters', headquarters);
  }

  static getHeadquarterById(headquarterId) {
    const headquarters = HeadquarterCache.getHeadquarters();

    return headquarters.find((headquarter) => headquarter.getId().isEquals(
      headquarterId,
    ));
  }

  static removeHeadquarters(headquarterId) {
    const headquarters = HeadquarterCache.getHeadquarters();

    const indexHeadquarter = headquarters.findIndex((headquarter) => headquarter.getId().isEquals(
      headquarterId,
    ));
    headquarters.splice(indexHeadquarter, 1);
  }

  static addHeadquarter(headquarter) {
    const headquarters = HeadquarterCache.getHeadquarters();
    headquarters.push(headquarter);
  }

  static updateHeadquarter(headquarter) {
    const headquarters = HeadquarterCache.getHeadquarters();

    const indexHeadquarter = headquarters.findIndex((element) => element.getId().isEquals(
      headquarter.getId(),
    ));

    headquarters[indexHeadquarter] = headquarter;
  }
}
