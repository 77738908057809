import EditTomCommand from '@/application/tom/editTom/EditTomCommand';
import Tom from '@/domain/tom/Tom';
import TomRepository from '@/domain/tom/TomRepository';
import TomId from '@/domain/tom/TomId';
import TomToUpdate from '@/domain/tom/TomToUpdate';
import TomNote from '@/domain/tom/TomNote';
import TomNoteContent from '@/domain/tom/TomNoteContent';
import Language from '@/domain/language/Language';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import TomStatus from '@/domain/tom/TomStatus';

export default class EditTomCommandHandler {
  private readonly tomRepository: TomRepository;

  constructor(tomRepository: TomRepository) {
    this.tomRepository = tomRepository;
  }

  public async invoke(command: EditTomCommand): Promise<any> {
    try {
      const tom: Tom = await this.tomRepository.retrieve(TomId.fromString(command.id));
      const tomToUpdate: TomToUpdate = EditTomCommandHandler
        .buildTomToUpdate(command, tom.getAttachedDocuments());

      tom.update(tomToUpdate);
      await this.tomRepository.update(tom);

      return { success: true };
    } catch (e) {
      return { success: false, error: e.response?.data?.data || '' };
    }
  }

  private static buildTomToUpdate(
    command: EditTomCommand,
    alreadyAttachedDocuments: TomAttachedDocument[],
  ): TomToUpdate {
    const notes: TomNote[] = command.notes.map(
      (note) => new TomNote(
        TomNoteContent.fromString(note.content),
        Language.fromString(note.language),
      ),
    );

    const newAttachedDocuments: TomAttachedDocument[] = this.buildNewAttachedDocuments(command);
    const attachedDocumentsWithoutDeleted = this.buildAttachedDocumentsWithoutDeleteOnes(
      alreadyAttachedDocuments,
      command,
    );

    const tomToUpdate = new TomToUpdate();
    tomToUpdate.setNotes(notes)
      .setAttachedDocuments([...newAttachedDocuments, ...attachedDocumentsWithoutDeleted])
      .setStatus(TomStatus.fromString(command.status));
    return tomToUpdate;
  }

  private static buildNewAttachedDocuments(command: EditTomCommand): TomAttachedDocument[] {
    const newAttachedDocuments: TomAttachedDocument[] = [];

    command.attachedDocuments.forEach((doc) => {
      const attachedDocument: AttachedDocumentStruct = {
        name: doc.name,
        id: UuidValueObject.generate().toString(),
        content: doc.content,
      };
      newAttachedDocuments.push(TomAttachedDocument.fromAttachedDocumentStruct(attachedDocument));
    });

    return newAttachedDocuments;
  }

  private static buildAttachedDocumentsWithoutDeleteOnes(
    alreadyAttachedDocuments: TomAttachedDocument[],
    command: EditTomCommand,
  ) {
    if (!alreadyAttachedDocuments) {
      return [];
    }

    return alreadyAttachedDocuments.filter(
      (doc: TomAttachedDocument) => !command.attachedDocumentsToDelete.includes(
        doc.getId().toString(),
      ),
    ) || [];
  }
}
