import EquipmentLocation from '@/domain/equipment/equipmentLocation/EquipmentLocation';

export default (equipmentRepository) => async (headquartersId) => {
  try {
    const equipmentLocation = new EquipmentLocation(EquipmentLocation.HEADQUARTERS, headquartersId);
    const equipmentsCount = await equipmentRepository.countByLocation(equipmentLocation);

    return { success: true, numEquipments: equipmentsCount };
  } catch (e) {
    return { success: false };
  }
};
