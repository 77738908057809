import store from '../../store';

export default class EquipmentsCache {
  static resetEquipments() {
    store.dispatch('equipment/resetEquipments');
  }

  static setEquipments(equipments) {
    store.dispatch('equipment/addEquipments', equipments);
  }

  static removeEquipment(equipmentId) {
    let equipmentList = EquipmentsCache.getEquipments();
    equipmentList = equipmentList.filter((equipment) => !equipment.getId().isEquals(equipmentId));
    this.setEquipments(equipmentList);
  }

  static getEquipments() {
    return store.getters['equipment/equipments'];
  }

  static getEquipment(equipmentId) {
    const equipments = this.getEquipments();

    return equipments.find((equipment) => equipment.getId().isEquals(equipmentId));
  }

  static addEquipment(equipment) {
    const equipments = EquipmentsCache.getEquipments();
    equipments.push(equipment);
  }

  static updateEquipment(equipment) {
    const equipments = EquipmentsCache.getEquipments();
    const indexEquipment = EquipmentsCache.findRecipientIndexById(equipments, equipment.getId());
    equipments.splice(indexEquipment, 1);
    equipments.push(equipment);
  }

  static findRecipientIndexById(equipments, equipmentId) {
    return equipments.findIndex((equipment) => equipmentId.isEquals(equipment.getId()));
  }
}
