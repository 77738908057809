<template>
  <div>
    <a
      @click="initializeAndShowModal"
      v-track="'Change user role –- start'"
    >
      {{clientUser.role}}
    </a>
    <v-dialog
      v-model="showModal"
      persistent
      scrollable
      width="40%"
    >
      <v-card
        class="user-role"
      >
        <v-card-title>
          <h2>{{$t('clientUsers.change_role.title')}}</h2>
          <v-spacer/>
          <v-btn
            icon
            @click="hideModalAndAlert"
          >
            <v-icon v-text="'close'"/>
          </v-btn>
          <p>
            {{$t('clientUsers.change_role.title_info', { userName: clientUser.fullName })}}
          </p>
        </v-card-title>
        <v-card-text>
          <alert-fixed
            v-if="showAlert"
            type="error"
          >
            {{$t('clientUsers.change_role.error.mandatory_input')}}
          </alert-fixed>
          <select-user-role-and-departments
            v-if="showModal"
            :userRole.sync="roleSelected"
            :departmentSelected.sync="departmentSelected"
            @clientUserTypesWereLoaded="setRoleSelectedWithCurrentRole"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            rounded
            class="mb-0"
            @click="hideModalAndAlert"
          >
            {{$t("clientUsers.change_role.button_cancel")}}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            class="mb-0"
            @click="save"
          >
            {{$t("clientUsers.change_role.button_accept")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ChangeClientUserRoleCommand
  from '@/application/clientUser/editClientUser/ChangeClientUserRoleCommand';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import SelectUserRoleAndDepartments
  from '@/components/modules/clientUsers/SelectUserRoleAndDepartments.vue';
import FetchClientUserQuery from '@/application/clientUser/fetchClientUser/FetchClientUserQuery';

const EMPLOYEE_TYPE = 'employee';

export default {
  name: 'edit-client-user-role',
  components: {
    AlertFixed,
    SelectUserRoleAndDepartments,
  },
  inject: {
    changeClientUserRole: 'changeClientUserRole',
    fetchClientUser: 'fetchClientUser',
  },
  props: {
    clientUser: {
      type: Object,
      default: null,
    },
  },
  computed: {
    roleSelectedIsEmployee() {
      return this.roleSelected === EMPLOYEE_TYPE;
    },
  },
  data() {
    return {
      showModal: false,
      showAlert: false,
      roleSelected: '',
      departmentSelected: [],
    };
  },
  methods: {
    hideModalAndAlert() {
      this.showModal = false;
      this.showAlert = false;
      this.$track({ action: 'Change user role -- cancel' });
    },
    async initializeAndShowModal() {
      await this.loadClientUser();
      this.showModal = true;
    },
    async loadClientUser() {
      this.$showLoading();
      const response = await this.fetchClientUser
        .invoke(new FetchClientUserQuery(this.clientUser.id));
      this.$hideLoading();
      if (!response.success) {
        this.$showError('clientUsers.change_role.error.load_user');
      }
      this.roleSelected = response.clientUser.type;
      this.departmentSelected = response.clientUser.departments;
    },
    setRoleSelectedWithCurrentRole(availableRoles) {
      if (!this.clientUser.isTrainingsManager) {
        return;
      }
      this.roleSelected = availableRoles
        .find((role) => role.isRestrictedEmployeeType).type;
    },
    formIsValid() {
      if (this.roleSelectedIsEmployee && !this.departmentSelected.length) {
        this.showAlert = true;
        return false;
      }
      return true;
    },
    async save() {
      if (!this.formIsValid()) {
        return;
      }
      this.showAlert = false;
      this.$showLoading();

      const response = await this.changeClientUserRole.invoke(new ChangeClientUserRoleCommand(
        this.clientUser.id,
        this.roleSelected,
        this.departmentSelected,
      ));

      this.trackEvent(response);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('clientUsers.change_role.error.user_role');
        return;
      }

      this.$eventHub.$emit('clientUserRoleWasUpdated');
      this.$showSuccess('clientUsers.change_role.success.role_changed');
      this.showModal = false;
    },
    trackEvent(response) {
      const data = {
        action: 'Change user role -- finish',
        result: response.success,
      };
      if (response.success) {
        data.changes = `From ${this.clientUser.type} to ${this.roleSelected}`;
      }
      this.$track(data);
    },
  },
};
</script>
