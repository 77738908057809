export default ({
  adminRepository,
}) => async () => {
  try {
    const result = await adminRepository.getLastUploadedDocuments();

    return { success: true, data: result };
  } catch (e) {
    return { success: false };
  }
};
