import ClientUsersWriteModel from '@/application/clientUser/fetchClientUsers/ClientUsersWriteModel';
import ClientUsersRepresentation
  from '@/application/clientUser/fetchClientUsers/ClientUsersRepresentation';
import ClientUserListCache from '@/infrastructure/clientUser/ClientUserListCache';
import ClientUserId from '@/domain/clientUser/ClientUserId';

export default class ClientUserWriteModelApi implements ClientUsersWriteModel {
  public add(clientUser: ClientUsersRepresentation): void {
    ClientUserListCache.save(clientUser);
  }

  public update(clientUser: ClientUsersRepresentation): void {
    ClientUserListCache.save(clientUser);
  }

  public delete(clientUserId: ClientUserId): void {
    ClientUserListCache.delete(clientUserId);
  }
}
