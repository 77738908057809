import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import EventBus from '@/application/eventBus/EventBus';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import DeleteClientUserCommand
  from '@/application/clientUser/deleteClientUser/DeleteClientUserCommand';
import ClientUserWasDeleted from '@/domain/clientUser/ClientUserWasDeleted';

export default class DeleteClientUserCommandHandler {
  private readonly clientUserRepository: ClientUserRepository;

  private readonly eventBus: EventBus;

  constructor(clientUserRepository: ClientUserRepository, eventBus: EventBus) {
    this.clientUserRepository = clientUserRepository;
    this.eventBus = eventBus;
  }

  public async invoke(command: DeleteClientUserCommand) {
    try {
      const clientUserId: ClientUserId = ClientUserId.fromInt(command.clientUserId);
      await this.clientUserRepository.delete(clientUserId);

      this.eventBus.handle(ClientUserWasDeleted.fromClientUserId(clientUserId));

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
