import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';

export default class CreationDate extends DateTimeValueObject {
  creationDate;

  constructor(creationDate) {
    super(creationDate);
    this.creationDate = creationDate;
  }
}
