<template>
  <v-text-field
    v-model="searchInput"
    :label="label"
    solo
    class="input-search"
    append-icon="search"
    @input="$emit('update:value', searchInput)"
  />
</template>
<script>
export default {
  name: 'input-search',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchInput: this.value,
    };
  },
  watch: {
    value() {
      this.value = this.searchInput;
    },
  },
};
</script>
