import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';

export default class ExpirationDate extends DateTimeValueObject {
  expirationDate;

  constructor(expirationDate) {
    super(expirationDate);
    this.expirationDate = expirationDate;
  }
}
