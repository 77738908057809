<template>
  <data-map-container />
</template>

<script>
import DataMapContainer from '@/components/containers/DataMapContainer.vue';

export default {
  name: 'data-map',
  components: {
    DataMapContainer,
  },
};
</script>
