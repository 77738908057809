import RecipientListRepresentation from './RecipientListRepresentation';

export default (
  translator,
  normalizationService,
  processingDatesService,
  recipient,
) => {
  const recipientRepresentationReadModel = new RecipientListRepresentation(
    translator,
    normalizationService,
    processingDatesService,
  );
  return recipient.representedAs(recipientRepresentationReadModel);
};
