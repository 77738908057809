<template>
  <v-row class="text-center">
    <v-col cols="12">
      <h1 class="grey--text text--darken-3">
        <strong>{{ $t('register.registered.thanks') }}</strong>
      </h1>
      <p class="mb-5">{{ $t('register.registered.email') }}</p>
      <p class="primary--text">
        <strong>
          {{ userEmail }}
        </strong>
      </p>
      <p>{{ $t('register.registered.follow') }}</p>
      <router-link
        :to="{ name: 'login' }"
        class="mt-10"
        v-track="'Go to Login__pendingemailvalidation'"
      >
        {{ $t('register.registered.return') }}
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'user-register-feedback',
  props: {
    userEmail: {
      type: String,
      default: '',
    },
  },
};
</script>
