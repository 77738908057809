export default ({
  legalDocumentsRepository,
  downloadFile,
}) => async (companyId, documentId, type, adminLayoutId) => {
  try {
    const urlDownload = await legalDocumentsRepository.downloadDocument(
      companyId, documentId, type, adminLayoutId,
    );

    downloadFile.download(urlDownload.url);

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
