import { postManagement } from '@/shared/restActions';
import Department from '@/domain/department/Department';
import DepartmentId from '@/domain/department/DepartmentId';
import DepartmentRepository from '@/domain/department/DepartmentRepository';
import DepartmentCache from '@/infrastructure/department/DepartmentCache';
import ClientId from '@/domain/client/ClientId';
import DepartmentRawRepresentation from '@/infrastructure/department/DepartmentRawRepresentation';

export default class DepartmentRepositoryApi implements DepartmentRepository {
  public async save(clientId: ClientId, department: Department): Promise<void> {
    const departmentRaw = department
      .representedAs(new DepartmentRawRepresentation()) as DepartmentRawRepresentation;

    await postManagement(
      `/api/v2/clients/${clientId.toString()}/departments`,
      departmentRaw.toJson(),
    );

    DepartmentCache.save(department);
  }

  public async retrieve(departmentId: DepartmentId): Promise<Department> {
    return DepartmentCache.retrieve(departmentId);
  }

  public reset(): void {
    DepartmentCache.reset();
  }
}
