<template>
    <general-table
      :headers="headers"
      :list.sync="employees"
      :search.sync="search"
      :customFilterSearch="customFilterSearch"
      :columns="['documents', 'name', 'actions']"
    >
      <template v-slot:name="props">
        <edit-employee
          :employee="props.item"
          :showNif="showNif"
        />
      </template>
      <template v-slot:documents="props">
        <document-data-employee-modal
          v-if="props.item.totalDocumentsData"
          :employee="props.item"
        />
      </template>
      <template v-slot:actions="props">
        <remove-employee
          :employee="props.item"
        />
      </template>
    </general-table>
</template>

<script>
import DocumentDataEmployeeModal
  from '@/components/modules/register/employees/documentData/DocumentDataEmployeeModal.vue';
import EditEmployee from '@/components/modules/register/employees/EditEmployee.vue';
import RemoveEmployee from '@/components/modules/register/employees/RemoveEmployee.vue';
import GeneralTable from '../../../base/tables/GeneralTable.vue';

export default {
  name: 'list-employees',
  inject: {
    user: 'user',
    fetchDocumentsDataEmployee: 'fetchDocumentsDataEmployee',
    fetchEmployees: 'fetchEmployees',
  },
  components: {
    RemoveEmployee,
    EditEmployee,
    GeneralTable,
    DocumentDataEmployeeModal,
  },
  props: {
    showNif: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('employee.table.name'),
          align: 'start',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: this.$t('employee.table.email'),
          align: 'start',
          sortable: true,
          value: 'email',
          width: '20%',
        },
        {
          text: this.$t('employee.table.position'),
          align: 'start',
          sortable: true,
          value: 'position',
          width: '20%',
        },
        {
          text: this.$t('employee.table.entry_date'),
          align: 'start',
          sortable: true,
          value: 'entry',
          width: '10%',
        },
        {
          text: this.$t('employee.table.leaving_date'),
          align: 'start',
          sortable: true,
          value: 'exit',
          width: '10%',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'documents',
          width: '20%',
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          width: '10%',
        },
      ];
      if (this.showNif) {
        headers.splice(2, 0, {
          text: this.$t('employee.table.nif'),
          align: 'start',
          sortable: true,
          value: 'nif',
          width: '10%',
        });
      }
      return headers;
    },
  },
  data() {
    return {
      employees: [],
      customFilterSearch: (value, search, item) => (
        item.nameNormalized.indexOf(search) !== -1),
    };
  },
  methods: {
    async getEmployees() {
      this.$showLoading();
      const response = await this.fetchEmployees.invoke(this.user.clientId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('employee.table.error');
        return;
      }

      this.employees = response.employees;
      this.$eventHub.$emit('employeesLoaded', this.employees.length);
    },
  },
  beforeMount() {
    this.getEmployees();
  },
  created() {
    this.$eventHub.$on('employeeWasUpdated', this.getEmployees);
    this.$eventHub.$on('employeeWasCreated', this.getEmployees);
    this.$eventHub.$on('employeeWasDeleted', this.getEmployees);
  },
  beforeDestroy() {
    this.$eventHub.$off('employeeWasUpdated', this.getEmployees);
    this.$eventHub.$off('employeeWasCreated', this.getEmployees);
    this.$eventHub.$off('employeeWasDeleted', this.getEmployees);
  },
};
</script>
