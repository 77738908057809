import buildRecipientReadModelRepresentation from '../buildRecipientReadModelRepresentation';

export default class RecipientWasCreatedEventListener {
   _translator;

  _normalization;

  _processingDates;

  _writeModel;

  constructor(
    translator,
    normalizationService,
    processingDatesService,
    RecipientsWriteModel,
  ) {
    this._translator = translator;
    this._normalization = normalizationService;
    this._processingDates = processingDatesService;
    this._writeModel = RecipientsWriteModel;
  }

  invoke(recipientWasCreated) {
    const recipientRepresentationReadModel = buildRecipientReadModelRepresentation(
      this._translator,
      this._normalization,
      this._processingDates,
      recipientWasCreated.getRecipient(),
    );

    this._writeModel.addRecipient(recipientRepresentationReadModel);
  }
}
