import TrainingUserRepresentation from '@/domain/training/user/TrainingUserRepresentation';

export default class TrainingUserApiRepresentation extends TrainingUserRepresentation {
  get id(): string {
    return this._id.toString();
  }

  get language(): string {
    return this._language.toString();
  }
}
