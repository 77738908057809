import TrainingEmployee from '@/domain/training/trainingEmployee/TrainingEmployee';
import store from '../../../store';

export default class TrainingEmployeeCache {
  public static reset(): void {
    store.dispatch('training/resetTrainingEmployees');
  }

  public static all(): TrainingEmployee[] {
    return store.getters['training/trainingEmployees'];
  }

  public static save(trainingEmployees: TrainingEmployee[]) {
    store.dispatch('training/addTrainingEmployees', trainingEmployees);
  }
}
