function construcRecipientsGroup(recipientsSelection) {
  const recipientsGroup = [];
  recipientsSelection.forEach((obj) => {
    obj.selection.forEach((recipient) => {
      recipientsGroup.push({
        recipients_categories: obj.categoryId,
        recipients: recipient,
      });
    });
  });

  return recipientsGroup;
}

function getRecipientsByCategoryId(categoryId, recipients) {
  let recipientsSelected = [];
  recipients.forEach((obj) => {
    if (obj.categoryId === categoryId) {
      recipientsSelected = obj.selection;
    }
  });
  return recipientsSelected;
}

function compareToSort(a, b) {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }

  if (b.name.toLowerCase() > a.name.toLowerCase()) {
    return -1;
  }

  return 0;
}

function sortListByName(list) {
  list.sort(compareToSort);
  return list;
}

export default {
  construcRecipientsGroup,
  getRecipientsByCategoryId,
  sortListByName,
};
