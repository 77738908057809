<template>
  <div>
    <span
      v-if="clientUser.isValidated"
    >
      {{ clientUser.lastLoginDate }}
    </span>
    <a
      v-else
      @click="resendActivationEmail(clientUser)"
    >
      {{ $t('clientUsers.table.resend') }}
    </a>
  </div>
</template>

<script>
import ResendActivationClientUserEmailCommand
  from '@/application/clientUser/resendActivationClientUserEmail/ResendActivationClientUserEmailCommand';

export default {
  name: 'resend-activation-client-user',
  inject: {
    resendActivationClientUserEmail: 'resendActivationClientUserEmail',
  },
  props: {
    clientUser: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async resendActivationEmail(clientUser) {
      this.$showLoading();
      const response = await this.resendActivationClientUserEmail.invoke(
        new ResendActivationClientUserEmailCommand(clientUser.id),
      );
      this.$hideLoading();

      if (!response.success) {
        this.$showError('clientUsers.error.resend');
        return;
      }
      this.$showSuccess('clientUsers.success.resend', { email: clientUser.email });
    },
  },
};
</script>
