<template>
  <div class="h-100 w-100">
    <div v-if="userExists">
      <logo-img
        :logoUrl="urlLogo || companyLogoUrl"
        :companyName="companyName"
        :height="heightLogo"
        :position="positionLogo"
        :classLogo="classLogo"
        titleTag="h1"
      />
      <div v-if="!clientUserActivated">
        <google-login-btn
          v-if="showGoogleSSO"
          @userGoogleLogin="googleLogin"
        />
        <ask-password
          :userEmail.sync="user.email"
          :errorFeedback.sync="errorFeedback"
          @activateClientUser="activateClientUser"
          :errorInput.sync="thereIsPasswordError"
        />
      </div>
      <div v-else>
        <client-user-activated-confirmation
        />
      </div>
    </div>
    <div v-else>
      <activate-token-error />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import formValidationService from '@/services/validations/formValidation';
import userUseCases from '@/usecases/users';
import GoogleLoginBtn from '@/components/modules/login/GoogleLoginBtn.vue';
import { fetchUserInfo, googleLogin } from '@/application/user';
import LoginCentralized from '@/services/login/LoginCentralized';
import subdomainService from '@/services/admin/subdomain';
import AskPassword from '../modules/activateClientUser/AskPassword.vue';
import ClientUserActivatedConfirmation from '../modules/activateClientUser/ClientUserActivatedConfirmation.vue';
import ActivateTokenError from '../modules/activateClientUser/ActivateTokenError.vue';
import LogoImg from '../base/icons/LogoImg.vue';

export default {
  name: 'activate-client-user-container',
  inject: {
    hasAccessLoginLayoutB2B: 'hasAccessLoginLayoutB2B',
    heightLogo: 'heightLogo',
    positionLogo: 'positionLogo',
    urlLogo: 'urlLogo',
  },
  components: {
    GoogleLoginBtn,
    AskPassword,
    ClientUserActivatedConfirmation,
    ActivateTokenError,
    LogoImg,
  },
  computed: {
    ...mapGetters('admin', {
      companyName: 'name',
      companyLogoUrl: 'logoUrl',
    }),
    ...mapGetters('user', {
      userId: 'userId',
    }),
    classLogo() {
      if (!this.hasAccessLoginLayoutB2B) {
        return 'login__brand';
      }

      return this.confirmChangePassword ? 'mb-0' : 'mb-10';
    },
    showGoogleSSO() {
      return this.showGoogleBtn && LoginCentralized.hasLoginCentralizedUrl();
    },
  },
  props: ['token'],
  data() {
    return {
      user: {},
      errorFeedback: [],
      thereIsPasswordError: false,
      clientUserActivated: false,
      userExists: false,
      showGoogleBtn: true,
    };
  },
  methods: {
    async activateClientUser(password) {
      this.$showLoading();
      this.errorFeedback = this.checkForm(password);

      if (this.errorFeedback.length === 0) {
        const response = await userUseCases.activate(this.token, password);
        this.showAlertResponse(response);
      }
      this.$hideLoading();
    },
    showAlertResponse(response) {
      if (response.success) {
        this.clientUserActivated = true;
      } else {
        this.$showError(
          `activateClientUser.exception.${response.data}`,
          'activateClientUser.exception.generic',
        );
      }
    },
    checkForm(password) {
      const textErrors = [];
      this.thereIsPasswordError = false;

      const emptyPassword = formValidationService.isEmptyField(password);
      const validPassword = formValidationService.isValidPassword(password);

      if (emptyPassword) {
        textErrors.push(this.$t('activateClientUser.error.required_field_password'));
        this.thereIsPasswordError = true;
      } else if (!validPassword) {
        textErrors.push(this.$t('activateClientUser.error.incorrect_password'));
        this.thereIsPasswordError = true;
      }

      return textErrors;
    },
    async loadUserByToken() {
      const response = await userUseCases.getInfoByToken(this.token);
      if (response) {
        this.user = response;
        this.userExists = true;
      }
    },
    hideGoogleSSO() {
      this.showGoogleBtn = false;
    },
    async googleLogin(googleAccessToken) {
      this.$showLoading();
      const response = await googleLogin.invoke(googleAccessToken);
      await this.completeLogin(response, 'Platform access', 'gmail');
    },
    async completeLogin(response, action, method) {
      if (!response.success) {
        this.$track({
          action,
          authentication: method,
          result: response.success,
        });

        this.errorFeedback = [this.$t('login.error.access_denied')];
        this.$hideLoading();
        return;
      }

      await this.getInfoAdminBySubdomain(response.subdomain);

      this.$track({
        action,
        result: response.success,
      });

      await this.getUserInfo();

      this.$hideLoading();

      this.$router.push({ name: 'home' });
    },
    async getInfoAdminBySubdomain(subdomain) {
      await subdomainService.getInfoAdmin(subdomain);
    },
    async getUserInfo() {
      await fetchUserInfo.invoke(this.userId);
    },
  },
  beforeMount() {
    this.$showLoading();

    this.loadUserByToken();
    this.$i18n.locale = this.user.locale;

    this.$hideLoading();
    this.$eventHub.$on('googleLoadingError', this.hideGoogleSSO);
  },
  beforeDestroy() {
    this.$eventHub.$off('googleLoadingError', this.hideGoogleSSO);
  },
};
</script>
