import DepartmentWriteModel from '@/domain/department/listDepartment/DepartmentWriteModel';
import ResetDepartmentCommand
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommand';

export default class ResetDepartmentCommandHandler {
  private writeModel: DepartmentWriteModel;

  constructor(writeModel: DepartmentWriteModel) {
    this.writeModel = writeModel;
  }

  public invoke(command: ResetDepartmentCommand): void {
    this.writeModel.reset();
  }
}
