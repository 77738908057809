function normalizeString(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
}

function normalizeParseDateWithTime(date) {
  return new Date(Date.parse(date)).toLocaleString('en-GB')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

function normalizeDate(date) {
  return new Date(date).toLocaleDateString('en-GB')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

function normalize(key, value) {
  switch (key) {
    case 'updated':
      return normalizeDate(value);
    case 'LastModified':
      return normalizeParseDateWithTime(value);
    default:
      return normalizeString(value);
  }
}

function sanitizeString(value) {
  return normalizeString(value).trim();
}

export default {
  normalizeString,
  normalizeDate,
  normalize,
  sanitizeString,
};
