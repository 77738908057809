import SimpleCrypto from 'simple-crypto-js';

function decrypt(param) {
  try {
    const cryptoSecretKey = process.env.VUE_APP_PRIDATECT_CRYPTO;
    const simpleCrypto = new SimpleCrypto(cryptoSecretKey);
    const paramDecrypted = simpleCrypto.decrypt(decodeURIComponent(param), true);
    return paramDecrypted;
  } catch (e) {
    return {};
  }
}

export default {
  decrypt,
};
