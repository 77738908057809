import ClientUserTypeValidationException
  from '@/domain/clientUser/ClientUserTypeValidationException';
import ClientUserTypeRepresentation from '@/domain/clientUser/ClientUserTypeRepresentation';

const SUPERVISOR = 'supervisor';
const RESTRICTED_EMPLOYEE = 'restricted_employee';
const EMPLOYEE = 'employee';
const TRAININGS_MANAGER = 'trainings_manager';

const VALID_TYPES = [RESTRICTED_EMPLOYEE, SUPERVISOR, EMPLOYEE, TRAININGS_MANAGER];

export default class ClientUserType {
  private readonly type: string;

  protected constructor(type: string) {
    ClientUserType.isValidOrFail(type);
    this.type = type;
  }

  private static isValidOrFail(type: string): void {
    if (!VALID_TYPES.includes(type)) {
      throw new ClientUserTypeValidationException(type);
    }
  }

  public isEmployee(): boolean {
    return this.type === EMPLOYEE;
  }

  public isTrainingsManager(): boolean {
    return this.type === TRAININGS_MANAGER;
  }

  public isRestrictedEmployee(): boolean {
    return this.type === RESTRICTED_EMPLOYEE;
  }

  public static byDefault(): ClientUserType {
    return new this(RESTRICTED_EMPLOYEE);
  }

  public toString(): string {
    return this.type;
  }

  public static fromString(type: string) {
    return new this(type);
  }

  public static all(): ClientUserType[] {
    return VALID_TYPES.map((type) => new this(type));
  }

  public representedAs(clientUserTypeRepresentation: ClientUserTypeRepresentation)
  : ClientUserTypeRepresentation {
    clientUserTypeRepresentation.setType(this.type);
    clientUserTypeRepresentation.setIsRestrictedEmployeeType(this.isRestrictedEmployee());
    return clientUserTypeRepresentation;
  }
}
