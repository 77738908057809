import {
  getManagement, deleteMethodManagement, postManagement, putManagement,
} from '@/shared/restActions';
import EquipmentLocation from '@/domain/equipment/equipmentLocation/EquipmentLocation';
import EquipmentSecurityMeasures
  from '@/domain/equipment/equipmentSecurityMeasures/EquipmentSecurityMeasures';
import EquipmentType from '@/domain/equipment/equipmentType/EquipmentType';
import EquipmentName from '@/domain/equipment/EquipmentName';
import EquipmentId from '@/domain/equipment/EquipmentId';
import Equipment from '@/domain/equipment/Equipment';
import EquipmentRepository from '@/domain/equipment/EquipmentRepository';
import EquipmentsCache from './EquipmentsCache';
import EquipmentRepresentationApi from './EquipmentRepresentationApi';

class EquipmentRepositoryApi implements EquipmentRepository {
  async ofClient(clientId: any): Promise<Equipment[]> {
    try {
      let equipmentList = EquipmentsCache.getEquipments();

      if (equipmentList.length) {
        return equipmentList;
      }

      const equipmentsRaw = await this._getEquipmentsFromApi(clientId.toInt());
      equipmentList = this._buildEquipments(equipmentsRaw, clientId);

      EquipmentsCache.setEquipments(equipmentList);

      return equipmentList;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching equipments');
    }
  }

  async retrieve(equipmentId: any) {
    const equipment = EquipmentsCache.getEquipment(equipmentId);

    if (!equipment) {
      throw new Error('Error fetching equipment');
    }

    return equipment;
  }

  async save(equipment: any) {
    try {
      const equipmentRepresentation = equipment.representedAs(new EquipmentRepresentationApi());
      await postManagement('/api/v2/equipment', equipmentRepresentation.toJson());
      EquipmentsCache.addEquipment(equipment);
    } catch (e) {
      console.error(e);
      throw new Error('Error creating the equipments');
    }
  }

  async _getEquipmentsFromApi(clientId: any) {
    try {
      const response = await getManagement(`/api/v2/clients/${clientId}/equipment`);
      return response.data;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching equipments');
    }
  }

  _buildEquipments(equipmentsRaw: any, clientId: any): Equipment[] {
    const equipments: Equipment[] = [];
    equipmentsRaw.forEach((equipmentRaw: any) => {
      const equipment = new Equipment(
        EquipmentId.fromString(equipmentRaw.id),
        clientId,
        new EquipmentName(equipmentRaw.name),
        new EquipmentType(equipmentRaw.type),
      );

      equipment.addSecurityMeasures(equipmentRaw.security_measures.map(
        (securityMeasure: any) => new EquipmentSecurityMeasures(securityMeasure),
      ));

      if (equipmentRaw.location && Object.keys(equipmentRaw.location).length) {
        equipment.addLocation(new EquipmentLocation(
          equipmentRaw.location.type,
          equipmentRaw.location.key,
        ));
      }

      equipments.push(equipment);
    });

    return equipments;
  }

  async delete(equipmentId: any) {
    try {
      await deleteMethodManagement(`/api/v2/equipment/${equipmentId.toString()}`);
      EquipmentsCache.removeEquipment(equipmentId);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting the equipment');
    }
  }

  async countByLocation(equipmentLocation: any) {
    try {
      const equipmentLocationAsString = equipmentLocation.jsonSerialize();
      const equipmentListRaw = await getManagement(`/api/v2/equipment/location/headquarters/${equipmentLocationAsString.key}/count`);

      return equipmentListRaw.data;
    } catch (e) {
      console.error(e);
      throw new Error('Error getting equipments count of headquarters');
    }
  }

  async update(equipment: any) {
    try {
      const equipmentRepresentation = equipment.representedAs(new EquipmentRepresentationApi());
      await putManagement(`/api/v2/equipment/${equipment.getId().toString()}`, equipmentRepresentation.toJson());
      EquipmentsCache.updateEquipment(equipment);
    } catch (e) {
      console.error(e);
      throw new Error('Error updating equipment');
    }
  }
}

export default EquipmentRepositoryApi;
