import AbstractLanguageRepresentation from '@/domain/language/AbstractLanguageRepresentation';
import Translator from '@/infrastructure/Translator';
import VueI18n from 'vue-i18n';

import TranslateResult = VueI18n.TranslateResult;

export default class LanguagesRepresentation extends AbstractLanguageRepresentation {
  private readonly translator: Translator;

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  get code(): string {
    return this._code.toString();
  }

  get name(): TranslateResult {
    return this.translator.translate(`languages.by_code.lang_${this._code.toString()}`);
  }
}
