<template>
  <div class="h-100 w-100">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'sso-layout',
};
</script>
