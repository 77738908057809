import EmailException from '@/domain/shared/email/EmailException';

const EMAIL_VALIDATION = /^(?![.+\-_])([\w.+-]*)[\w-]@[a-zA-Z0-9]([\w\-.]{0,61})[a-zA-Z0-9]\.([a-zA-Z0-9]{2,})$/i;

export default class EmailValueObject {
  private readonly email: string;

  protected constructor(email: string) {
    const emailTrim = email.trim();
    EmailValueObject.isValidOrFail(emailTrim);
    this.email = emailTrim;
  }

  private static isValidOrFail(email: string) {
    if (!EMAIL_VALIDATION.test(email)) {
      throw new EmailException(`${this.constructor.name} is not valid`);
    }
  }

  public toString(): string {
    return this.email;
  }

  public static fromString(email: string): EmailValueObject {
    return new this(email);
  }
}
