import ClientId from '@/domain/client/ClientId';

export default (
  readModel,
) => async (clientId) => {
  try {
    const recipients = await readModel.ofClient(ClientId.fromInt(clientId));

    return { success: true, recipients };
  } catch (e) {
    return { success: false };
  }
};
