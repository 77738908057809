<template>
  <v-img
    v-if="logoUrl"
    :src="logoUrl"
    :height="height"
    :position="position"
    :class="classLogo"
    contain
  />
  <div v-else>
    <h1
      v-if="titleTag === 'h1'"
    >
      {{ companyName }}
    </h1>
    <h2
      v-if="titleTag === 'h2'"
    >
      {{ companyName }}
    </h2>
  </div>
</template>
<script>
export default {
  name: 'logo-img',
  props: {
    logoUrl: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    classLogo: {
      type: String,
      default: '',
    },
    titleTag: {
      type: String,
      default: 'h1',
    },
    height: {
      type: String,
      default: '40',
    },
    position: {
      type: String,
      default: 'center left',
    },
  },
};
</script>
