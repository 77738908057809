<template>
  <v-select
    v-model="inputValue"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    append-icon="mdi-chevron-down"
    filled
    @input="$emit('update:value', inputValue)"
  />
</template>
<script>
export default {
  name: 'select-field',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
