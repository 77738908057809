import Login from '@/domain/user/login/Login';
import UserRaw from '@/infrastructure/user/UserRaw';
import { postManagement } from '@/shared/restActions';
import UserCache from '@/infrastructure/user/UserCache';
import TokenLoginCredentials from '@/domain/user/login/Credentials/TokenLoginCredentials';

export default class LoginGoogleApi implements Login {
  async invoke(loginCredentials: TokenLoginCredentials): Promise<string> {
    try {
      const userInfo: UserRaw = await postManagement(
        '/api/v2/login',
        { googleToken: loginCredentials.toString() },
        { loginType: 'google' },
      );

      localStorage.setItem('user-token', userInfo.token);

      UserCache.save(userInfo);

      return userInfo.subdomain;
    } catch (e) {
      throw Error('Error login user with google SSO');
    }
  }
}
