import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class RecipientName extends StringValueObject {
  name;

  constructor(name) {
    super(name, 3, 255);
    this.name = name;
  }
}
