import TrainingRepository from '@/domain/training/TrainingRepository';
import TrainingCache from '@/infrastructure/training/TrainingCache';
import { getManagement, postManagement } from '@/shared/restActions';
import Training from '@/domain/training/Training';
import TrainingStatus from '@/domain/training/TrainingStatus';
import Language from '@/domain/language/Language';
import ClientId from '@/domain/client/ClientId';
import Url from '@/domain/shared/url/Url';
import TrainingUrl from '@/domain/training/TrainingUrl';
import TrainingToken from '@/domain/training/TrainingToken';
import TrainingUrlRaw from '@/infrastructure/training/TrainingUrlRaw';

export default class TrainingRepositoryApi implements TrainingRepository {
  async ofClient(clientId: ClientId): Promise<Training> {
    const response = await getManagement(`/api/v2/clients/${clientId.toString()}/trainings/info`);
    const trainingRaw = response.data;
    const training = this.buildTraining(trainingRaw);
    TrainingCache.save(training);

    return training;
  }

  private buildTraining(trainingRaw: TrainingRaw): Training {
    const languagesCodes = trainingRaw.languages.available;
    const languages = languagesCodes.map((language) => new Language(language));
    const training = new Training(languages);

    const status: TrainingStatus = trainingRaw.training_status
      ? TrainingStatus.fromString(trainingRaw.training_status)
      : TrainingStatus.defaultStatus();
    training.setStatus(status);

    if (trainingRaw.languages.user) {
      training.setUserLanguage(new Language(trainingRaw.languages.user));
    }

    return training;
  }

  async getUrl(clientId: ClientId): Promise<TrainingUrl> {
    const response = await getManagement(`/api/v2/clients/${clientId.toString()}/trainings/url`);

    const trainingUrlRaw: TrainingUrlRaw = response.data;
    return new TrainingUrl(
      Url.fromString(trainingUrlRaw.url),
      TrainingToken.fromString(trainingUrlRaw.token),
    );
  }

  async request(clientId: ClientId): Promise<void> {
    try {
      await postManagement(`/api/v2/clients/${clientId.toInt()}/training/request`);
      TrainingCache.changeStatusToRequested();
    } catch (e) {
      console.error(e);
      throw new Error('Error requesting training');
    }
  }
}
