<template>
  <v-footer class="table-footer">
    <v-spacer/>
    <v-row
      class="table-footer"
      justify="end"
      align="center"
    >
      <p class="mr-4">{{ $t('table.footer_pagination.rows') }}</p>
      <select-field
        class="table-footer__select-items-per-page mr-10"
        :value.sync="selectedItemsPerPage"
        :items.sync="selectListPerPageOptions"
        itemText="name"
        itemValue="code"
        @update:value="updatePageAndItemsPerPageProps"
      />
      <p class="mr-10">
        {{ firstNumber }} - {{ lastNumber }}
        {{ $t('table.footer_pagination.of') }} {{ this.totalItems }}
      </p>
      <v-btn
        :disabled="this.page === 1"
        icon
        class="mr-7"
        @click="prevPage"
      >
        <v-icon>
          chevron_left
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="this.page === this.numberOfPages || lastNumber === totalItems"
        icon
        @click="nextPage"
      >
        <v-icon>
          chevron_right
        </v-icon>
      </v-btn>
    </v-row>
  </v-footer>
</template>
<script>

import SelectField from '@/components/base/form/SelectField.vue';

const NUMBER_OF_ITEMS_PER_PAGE_LIST = [25, 50, 100];
const ITEMS_PER_PAGE_DEFAULT = 10;
const ALL_ITEMS = -1;

export default {
  name: 'footer-table',
  components: { SelectField },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: ITEMS_PER_PAGE_DEFAULT,
    },
    listLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    selectListPerPageOptions() {
      const selectOptions = [{
        name: ITEMS_PER_PAGE_DEFAULT.toString(),
        code: ITEMS_PER_PAGE_DEFAULT,
      }];

      NUMBER_OF_ITEMS_PER_PAGE_LIST.forEach((numOfItems) => {
        if (this.totalItems >= numOfItems) {
          selectOptions.push({ name: numOfItems.toString(), code: numOfItems });
        }
      });

      selectOptions.push({ name: this.$t('table.footer_pagination.all'), code: ALL_ITEMS });

      this.updateSelectedItemsPerPage();

      return selectOptions;
    },
    firstNumber() {
      const prevNumberPage = this.page - 1;
      const itemOffset = 1;

      return this.selectedItemsPerPage * (prevNumberPage) + itemOffset;
    },
    lastNumber() {
      const pageOffset = this.firstNumber - 1;
      const maxItemNumber = this.selectedItemsPerPage + pageOffset;
      if (maxItemNumber > this.totalItems || maxItemNumber === ALL_ITEMS) {
        return this.totalItems;
      }
      return maxItemNumber;
    },
  },
  data() {
    return {
      selectedItemsPerPage: this.itemsPerPage,
      listLengthCache: this.listLength,
    };
  },
  methods: {
    nextPage() {
      this.$emit('update:page', this.page + 1);
    },
    prevPage() {
      this.$emit('update:page', this.page - 1);
    },
    updateSelectedItemsPerPage() {
      if (this.totalItems > 0 && this.selectedItemsPerPage > this.totalItems) {
        this.selectedItemsPerPage = ITEMS_PER_PAGE_DEFAULT;
      }

      this.updatePageAndItemsPerPageProps();
    },
    updatePageAndItemsPerPageProps() {
      this.$emit('update:itemsPerPage', this.selectedItemsPerPage);
      this.$emit('update:page', 1);
    },
  },
};
</script>
