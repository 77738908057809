import UuidValueObject from '../shared/uuid/UuidValueObject';

export default class HeadquarterId extends UuidValueObject {
  id;

  constructor(id) {
    super(id);
    this.id = id;
  }
}
