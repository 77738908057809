import AbstractDocumentDataRecipientRepresentation
  from '@/domain/documentData/recipient/AbstractDocumentDataRecipientRepresentation';

export default class DocumentDataRecipientRepresentation
  extends AbstractDocumentDataRecipientRepresentation {
  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get lastModification() {
    return this._lastModification.toStringDatetime();
  }

  get lastModificationToOrderBy() {
    return this._lastModification.toString();
  }
}
