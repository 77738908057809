import RecipientModelRepositoryApi from '@/infrastructure/recipient/recipientModel/RecipientModelRepositoryApi';
import normalization from '@/services/shared/normalization';
import RecipientModelCache from '@/infrastructure/recipient/recipientModel/RecipientModelCache';
import fetchRecipientsModelsBuilder from './fetchRecipientsModels/fetchRecipientsModels';
import resetRecipientsModelsCacheBuilder from './resetRecipientsModelsCache';

export default {
  fetchRecipientsModels: fetchRecipientsModelsBuilder(
    new RecipientModelRepositoryApi(), normalization,
  ),
  resetRecipientsModelsCache: resetRecipientsModelsCacheBuilder(RecipientModelCache),
};
