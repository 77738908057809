import TomList from '@/domain/tom/clasification/TomList';
import TomCategory from '@/domain/tom/clasification/TomCategory';

export default class TomClassification {
  private readonly list: TomList;

  private readonly category: TomCategory | null;

  constructor(list: TomList, category: TomCategory | null) {
    this.list = list;
    this.category = category;
  }

  public getList(): TomList {
    return this.list;
  }

  public getCategory(): TomCategory | null {
    return this.category;
  }
}
