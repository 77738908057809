import InscriptionStatusValidationException
  from '@/domain/training/inscription/InscriptionStatusValidationException';

const COMPLETED: string = 'completed';

const IN_PROGRESS: string = 'in_progress';

const VALID_STATUS: string[] = [
  COMPLETED,
  IN_PROGRESS,
];

export default class InscriptionStatus {
  private readonly status: string;

  protected constructor(status: string) {
    InscriptionStatus.isValidOrFail(status);
    this.status = status;
  }

  private static isValidOrFail(status: string): void {
    if (!VALID_STATUS.includes(status)) {
      throw new InscriptionStatusValidationException(status);
    }
  }

  public isCompleted(): boolean {
    return this.status === COMPLETED;
  }

  public toString(): string {
    return this.status;
  }

  public static fromString(status: string): InscriptionStatus {
    return new this(status);
  }
}
