export default (
  recipientListRepresentationCache,
  recipientCache,
) => async () => {
  try {
    await recipientListRepresentationCache.resetRecipients();
    await recipientCache.resetRecipients();
  } catch (e) {
    console.error(e);
  }
};
