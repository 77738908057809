<template>
  <div class="checkbox-yes-no">
    <div class="btn-checkbox">
      <v-btn
        outlined
        icon
        class="btn-checkbox__no"
        :class="{'active': value === false}"
        @click="$emit('update:value', false)"
      >
        {{ $t('checkbox.no') }}
      </v-btn>
      <v-btn
        outlined
        icon
        class="btn-checkbox__yes"
        :class="{'active': value === true}"
        @click="$emit('update:value', true)"
      >
        {{ $t('checkbox.yes') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'checkbox-yes-no',
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    data() {
      return {
        checkboxValue: this.value,
      };
    },
    watch: {
      value() {
        this.checkboxValue = this.value;
      },
    },
  },
};
</script>
