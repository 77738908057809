import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';

export default class CompletionDateResponse extends DateTimeValueObject {
  completionDateResponse;

  constructor(completionDateResponse) {
    super(completionDateResponse);
    this.completionDateResponse = completionDateResponse;
  }
}
