<template>
  <v-alert
    text
    prominent
    :type="alertType"
    :icon="icon"
    :color="color"
    border="left"
    class="alert-fixed"
  >
    {{ text }}
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'alert-fixed',
  props: {
    type: {
      type: String,
      default: 'error',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    alertType() {
      switch (this.type) {
        case 'error':
          return 'error';
        case 'warning':
          return 'warning';
        case 'userdesk':
          return 'success';
        default:
          return 'info';
      }
    },
    icon() {
      switch (this.type) {
        case 'error':
          return 'priority_high';
        case 'warning':
          return 'priority_high';
        case 'userdesk':
          return 'priority_high';
        default:
          return 'info';
      }
    },
    color() {
      switch (this.type) {
        case 'error':
          return 'error';
        case 'warning':
          return 'orange darken-3';
        case 'userdesk':
          return 'userdesk';
        case 'primary':
          return 'primary';
        default:
          return 'grey';
      }
    },
  },
};
</script>
