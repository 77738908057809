import { getManagement } from '@/shared/restActions';
import ClientId from '@/domain/client/ClientId';
import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import ClientUserLastLogin from '@/domain/clientUser/ClientUserLastLogin';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserReadModel from '@/application/clientUser/fetchClientUsers/ClientUserReadModel';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import Language from '@/domain/language/Language';
import Translator from '@/infrastructure/Translator';
import ClientUserListCache from '@/infrastructure/clientUser/ClientUserListCache';
import ClientUsersRepresentation
  from '@/application/clientUser/fetchClientUsers/ClientUsersRepresentation';
import DepartmentNameValue from '@/domain/department/DepartmentNameValue';

export default class ClientUserReadModelApi implements ClientUserReadModel {
  public async ofClient(clientId: ClientId): Promise<ClientUsersRepresentation[]> {
    let clientUsers: ClientUsersRepresentation[] = ClientUserListCache.all();

    if (clientUsers.length) {
      return clientUsers;
    }

    const clientUsersRaw: ClientUsersRaw[] = await ClientUserReadModelApi
      .getClientUsersFromApi(clientId);

    clientUsers = ClientUserReadModelApi
      .buildClientUsersRepresentation(clientUsersRaw);

    ClientUserListCache.saveAll(clientUsers);

    return clientUsers;
  }

  private static async getClientUsersFromApi(clientId: ClientId): Promise<ClientUsersRaw[]> {
    const response = await getManagement(`/api/v2/clients/${clientId.toInt()}/users`);
    return response.data;
  }

  private static buildClientUsersRepresentation(clientUsersRaw: ClientUsersRaw[])
    : ClientUsersRepresentation[] {
    const clientUsersRepresentation: ClientUsersRepresentation[] = [];

    clientUsersRaw.forEach((clientUserRaw: ClientUsersRaw) => {
      const clientUser = new ClientUsersRepresentation(
        new Translator(),
      );

      clientUser.setEmail(ClientUserEmail.fromString(clientUserRaw.email))
        .setId(ClientUserId.fromInt(clientUserRaw.id))
        .setValidated(clientUserRaw.validated)
        .setName(ClientUserName.fromString(clientUserRaw.name))
        .setSurname(ClientUserSurname.fromString(clientUserRaw.surname))
        .setType(ClientUserType.fromString(clientUserRaw.type))
        .setLanguage(Language.fromString(clientUserRaw.language));

      clientUser.setDepartmentsName(clientUserRaw.departments
        .map((department: string) => DepartmentNameValue.fromString(department)));

      if (clientUserRaw.lastLogin) {
        clientUser.setLastLogin(ClientUserLastLogin.fromW3CDate(clientUserRaw.lastLogin));
      }

      clientUsersRepresentation.push(clientUser);
    });

    return clientUsersRepresentation;
  }
}
