import StateValidationException from './StateValidationException';

export default class State {
  _code;

  static states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  constructor(state) {
    this.isValid(state);
    this._code = state;
  }

  isValid(state) {
    if (!State.states.includes(state)) {
      throw new StateValidationException('State does not exist');
    }
  }

  static all() {
    return State.states.map((state) => new this(state));
  }

  toString() {
    return this._code;
  }

  representedAs(statesRepresentation) {
    statesRepresentation.setCode(this._code);
    return statesRepresentation;
  }
}
