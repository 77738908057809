<template>
  <v-dialog
    v-model="showModal"
    persistent
    scrollable
    max-width="60%"
  >
    <v-card>
      <v-card-title>
        <h2>{{ $t("training.access.modal_title") }}</h2>
        <v-spacer />
        <v-btn
          icon
          @click="closeModal"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
        <p>{{ $t("training.access.modal_title_info") }}</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12"
                 class="pa-0"
          >
            <alert-fixed
              v-if="showAlert"
              type="error"
              :text="$t('training.error.language')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-form
              ref="trainingLanguageForm"
            >
              <select-field
                :items="availableLanguages"
                :label="`${$t('training.access.modal_language_picker')}*`"
                item-text="name"
                item-value="code"
                :value.sync="languageSelected"
                :rules="[validations.required]"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          rounded
          class="mb-0"
          @click="closeModal"
        >
          {{ $t("training.access.modal_button_cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="mb-0"
          @click="save"
        >
          <v-icon small>
            open_in_new
          </v-icon>
          {{ $t("training.access.modal_button_access") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectField from '@/components/base/form/SelectField.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';

export default {
  name: 'dialog-select-languages',
  components: { AlertFixed, SelectField },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      languageSelected: '',
      validations: {
        required: (v) => !!v.trim() || this.$t('form.validations.required_field'),
      },
      showAlert: false,
    };
  },
  computed: {
    availableLanguages() {
      return this.languages.map((code) => ({ name: this.$t(`training.access.modal_language_${code}`), code }));
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
    },
    save() {
      if (!this.$refs.trainingLanguageForm.validate()) {
        this.showAlert = true;
        return;
      }
      this.showAlert = false;
      this.$emit('save', this.languageSelected);
    },
  },
};
</script>
