<template>
  <v-dialog v-model="showModal" persistent scrollable max-width="60%">
    <v-card class="registers-form registers-form__equipment">
      <v-card-title>
        <h2>
          {{ $t('equipment.create.title') }}
        </h2>
        <v-spacer />
        <v-btn
            icon
            @click="$emit('update:show-modal', false)"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
        <p>
          {{ $t('equipment.form.explanation') }}
        </p>
      </v-card-title>
      <v-card-text id="form-equipment" class="form-equipment">
        <v-form
            ref="equipmentForm"
        >
          <v-row>
            <v-col cols="12">
              <alert-fixed
                v-if="showAlert"
                type="error"
                :text="$t('form.review')"
              />
            </v-col>
            <v-col cols="12">
              <input-field
                :value.sync="equipment.name"
                :label="`${$t('equipment.form.name')} *`"
                :rules="[validation.required, validation.max_name, validation.min]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12">
              <type-selector
                :selectedValues.sync="equipment.type"
                :rules="[validation.required]"
              />
            </v-col>
            <v-col cols="12">
              <security-measures-selector
                :selectedValues.sync="equipment.securityMeasures"
                :label="$t('equipment.form.security_measures')"
              />
            </v-col>
            <v-col
              v-if="showAlertHeadquarters"
              cols="12"
              class="form-equipment__alert-headquarters"
            >
              <v-divider class="mb-8"/>
              <alert-fixed
                  type="warning"
              >
                {{ $t('equipment.form.alert.text') }}
                <a
                  @click="goToHeadquartersTab()"
                >
                  <strong> {{ $t('equipment.form.alert.link') }} </strong>
                </a>
              </alert-fixed>
            </v-col>
            <v-col cols="12">
              <location-selector
                :selectedValues.sync="equipment.location"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('update:show-modal', false)"
        >
          {{ $t('equipment.form.cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          @click="save()"
        >
          {{ $t('equipment.form.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import InputField from '@/components/base/form/InputField.vue';
import SecurityMeasuresSelector from '@/components/base/form/SecurityMeasuresSelector.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import TypeSelector from './TypeSelector.vue';
import LocationSelector from './LocationSelector.vue';

export default {
  name: 'dialog-form-equipment',
  components: {
    AlertFixed,
    LocationSelector,
    SecurityMeasuresSelector,
    TypeSelector,
    InputField,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    dataEquipment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      equipment: {
        name: '',
        type: '',
        securityMeasures: [],
        location: null,
      },
      validation: {
        required: (v) => !!v || this.$t('form.validations.required_field'),
        min: (v) => v.trim().length >= 3 || this.$t('form.validations.min_length', { length: 3 }),
        max: (v) => v.trim().length <= 200 || this.$t('form.validations.max_length', { length: 200 }),
        max_name: (v) => v.trim().length <= 50 || this.$t('form.validations.max_length', { length: 50 }),
      },
      showAlert: false,
      showAlertHeadquarters: false,
    };
  },
  methods: {
    save() {
      if (!this.$refs.equipmentForm.validate()) {
        this.showAlert = true;
        return;
      }
      this.$emit('saveEquipment', this.equipment);
    },
    fillEquipmentWithData() {
      Object.keys(this.equipment).forEach((key) => {
        this.equipment[key] = this.dataEquipment[key];
      });
      this.validation.max_name = (v) => v.length <= 200 || this.$t('form.validations.max_length', { length: 200 });
    },
    shouldBeShownAlertHeadquarters(hasHeadquarters) {
      this.showAlertHeadquarters = !hasHeadquarters;
    },
    goToHeadquartersTab() {
      this.$eventHub.$emit('goToHeadquartersTab', 'headquarter');
      this.$emit('update:showModal', false);
    },
  },
  mounted() {
    if (this.dataEquipment && Object.keys(this.dataEquipment).length) {
      this.fillEquipmentWithData();
    }
  },
  created() {
    this.$eventHub.$on('locationsLoaded', this.shouldBeShownAlertHeadquarters);
  },
  beforeDestroy() {
    this.$eventHub.$off('locationsLoaded');
  },
};
</script>
