import appCountries from './app/countries.json';
import appErrorPage from './app/errorPage.json';
import appForm from './app/form.json';
import appLanguages from './app/languages.json';
import appMenus from './app/menus.json';
import appModal from './app/modal.json';
import appTable from './app/table.json';
import appTitlePage from './app/titlePage.json';
import appStates from './app/states.json';

import appDepartments from './department/departments.json';

import clientFolder from './documents/clientFolder.json';
import legalDocuments from './documents/legalDocuments.json';
import processingActivities from './documents/processingActivities.json';
import registers from './documents/registers.json';
import risks from './documents/risks.json';
import treatmentManagers from './documents/treatmentManagers.json';

import headquarters from './registries/headquarters.json';
import equipment from './registries/equipment.json';
import employee from './registries/employee.json';
import recipient from './registries/recipient.json';

import changePassword from './login/changePassword.json';
import forgotPassword from './login/forgotPassword.json';
import activateClientUser from './login/activateClientUser.json';
import login from './login/login.json';

import privacyPolicy from './legal/privacyPolicy.json';
import legalNotice from './legal/legalNotice.json';
import cookies from './legal/cookies.json';

import processingActivitiesForm from './questionnaires/processingActivity/processingActivities.json';
import recipientCategory from './questionnaires/processingActivity/recipientCategory.json';
import recipientProcessingActivity from './questionnaires/processingActivity/recipient.json';

import home from './user/home.json';
import settings from './user/settings.json';
import tasks from './user/tasks.json';
import clientUsers from './user/clientUsers.json';
import training from './user/training.json';

import dataBreach from './dataBreach/dataBreach.json';
import userRegister from './login/register.json';

import toms from './toms/toms.json';
import tom from './toms/tom.json';

export default {
  ...appDepartments,
  ...appCountries,
  ...appErrorPage,
  ...appForm,
  ...appLanguages,
  ...appMenus,
  ...appModal,
  ...appTable,
  ...appTitlePage,
  ...appStates,
  ...clientFolder,
  ...legalDocuments,
  ...processingActivities,
  ...registers,
  ...risks,
  ...treatmentManagers,
  ...headquarters,
  ...equipment,
  ...employee,
  ...recipient,
  ...changePassword,
  ...forgotPassword,
  ...activateClientUser,
  ...login,
  ...privacyPolicy,
  ...legalNotice,
  ...cookies,
  ...processingActivitiesForm,
  ...recipientCategory,
  ...recipientProcessingActivity,
  ...home,
  ...settings,
  ...tasks,
  ...clientUsers,
  ...training,
  ...dataBreach,
  ...userRegister,
  ...toms,
  ...tom,
};
