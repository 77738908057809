import EquipmentRepository from '@/domain/equipment/EquipmentRepository';
import EquipmentsRepresentation from './EquipmentsRepresentation';
import ClientId from '../../../domain/client/ClientId';

const fetchEquipments = (
  HeadquarterCache: any,
  equipmentRepository: EquipmentRepository,
  translator: any,
  normalization: any,
) => async (clientId: any): Promise<any> => {
  try {
    const equipments = await equipmentRepository.ofClient(ClientId.fromInt(clientId));

    return {
      success: true,
      equipments: equipments.map(
        (equipment: any) => equipment.representedAs(new EquipmentsRepresentation(
          HeadquarterCache, translator, normalization,
        )),
      ),
    };
  } catch (e) {
    return { success: false };
  }
};

export default fetchEquipments;
