<template>
  <v-row>
    <v-col cols="12">
      <list-employees-trainings />
    </v-col>
  </v-row>
</template>

<script>
import ListEmployeesTrainings from '@/components/modules/training/employeesTrainings/ListEmployeesTrainings.vue';
import { fetchTrainingEmployees } from '@/application/training/employee';
import { downloadDocument } from '@/application/document';

export default {
  name: 'employees-trainings-section',
  provide: {
    fetchTrainingEmployees,
    downloadDocument,
  },
  components: {
    ListEmployeesTrainings,
  },
};
</script>
