import normalization from '@/services/shared/normalization';
import Translator from '@/infrastructure/Translator';
import HeadquarterCache from '@/infrastructure/headquarter/HeadquarterCache';
import HeadquarterRepositoryApi from '@/infrastructure/headquarter/HeadquarterRepositoryApi';
import resetHeadquartersBuilder from './resetHeadquarters/resetHeadquarters';
import fetchHeadquartersBuilder from './fetchHeadquarters/fetchHeadquarters';
import createHeadquarterBuilder from './createHeadquarter/createHeadquarter';
import removeHeadquartersBuilder from './removeHeadquarters/removeHeadquarters';
import fetchSecurityMeasuresBuilder from './fetchSecurityMeasures/fetchSecurityMeasures';
import fetchHeadquarterBuilder from './fetchHeadquarter/fetchHeadquarter';
import updateHeadquarterBuilder from './updateHeadquarter/updateHeadquarter';

export default {
  resetHeadquarters: resetHeadquartersBuilder(HeadquarterCache),
  fetchHeadquarters: fetchHeadquartersBuilder(
    new HeadquarterRepositoryApi(),
    new Translator(),
    normalization,
  ),
  createHeadquarter: createHeadquarterBuilder(new HeadquarterRepositoryApi()),
  removeHeadquarters: removeHeadquartersBuilder(new HeadquarterRepositoryApi()),
  fetchSecurityMeasures: fetchSecurityMeasuresBuilder(new Translator()),
  fetchHeadquarter: fetchHeadquarterBuilder(new HeadquarterRepositoryApi()),
  updateHeadquarter: updateHeadquarterBuilder(new HeadquarterRepositoryApi()),
};
