<template>
  <span>
    <v-btn
      color="primary"
      rounded
      v-track="'Create new resource -- start'"
      @click="openNewEquipmentModal = true"
    >
      {{ $t('equipment.create.button') }}
    </v-btn>
    <dialog-form-equipment
      v-if="openNewEquipmentModal"
      :showModal.sync="openNewEquipmentModal"
      @saveEquipment="saveNewEquipment"
    />
  </span>
</template>

<script>
import DialogFormEquipment from './form/DialogFormEquipment.vue';

export default {
  name: 'create-equipment',
  inject: {
    user: 'user',
    createEquipment: 'createEquipment',
  },
  components: {
    DialogFormEquipment,
  },
  data() {
    return {
      openNewEquipmentModal: false,
    };
  },
  methods: {
    async saveNewEquipment(equipment) {
      this.$showLoading();
      const response = await this.createEquipment(this.user.clientId, equipment);
      this.$hideLoading();
      this.$track({ action: 'Create new resource -- finish', result: response.success });
      if (!response.success) {
        this.$showError('equipment.create.error');
        return;
      }

      this.$showSuccess('equipment.create.success');
      this.$eventHub.$emit('equipmentWasCreated');
      this.openNewEquipmentModal = false;
    },
  },
};
</script>
