<template>
  <v-text-field
    v-model="input"
    :label="label"
    :placeholder="placeholder"
    :error="error"
    :error-messages="errorMessages"
    :rules="rules"
    :min="min"
    :max="max"
    type="number"
    filled
    @input="$emit('update:value', input)"
    @keyup.enter="$emit('keyUpEnter')"
    @click="$emit('clickInput')"
    @change="checkNum()"
  />
</template>

<script>
export default {
  name: 'number-input-field',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
  methods: {
    checkNum() {
      let val = this.input;
      if (val.length === 0) {
        val = 0;
      }
      if (this.min !== null && val < this.min) {
        val = this.min;
      }
      if (this.max !== null && val > this.max) {
        val = this.max;
      }
      this.input = val;
    },
  },
  watch: {
    value() {
      this.input = this.value;
    },
  },
};
</script>
