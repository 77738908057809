export default ({
  statsRepository,
}) => async (companyId) => {
  try {
    const result = await statsRepository.getTotalDocumentsBySection(companyId);
    return { success: true, stats: result };
  } catch (e) {
    return { success: false };
  }
};
