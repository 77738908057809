const addUserInfo = ({ commit }, payload) => {
  commit('ADD_USER_TOKEN', payload.token);
  commit('ADD_USER_EMAIL', payload.userEmail);
  if (!payload.isAdmin) {
    commit('ADD_USER_COMPANY_ID', payload.companyId);
    commit('ADD_COUNTRY_ID', payload.countryId);
    commit('ADD_USER_COMPANY_NAME', payload.companyName);
  }
  commit('ADD_USER_ID', payload.userId);
  commit('ADD_USER_IS_ADMIN', payload.isAdmin);
  commit('ADD_USER_LANGUAGE', payload.language);
  commit('ADD_USER_NAME', payload.userName);
  commit('ADD_IS_B2B', payload.isB2b);
};

const resetUserInfo = ({ commit }) => {
  commit('RESET_USER_TOKEN');
  commit('RESET_USER_EMAIL');
  commit('RESET_USER_COMPANY_ID');
  commit('RESET_USER_COMPANY_NAME');
  commit('RESET_USER_ID');
  commit('RESET_USER_IS_ADMIN');
  commit('RESET_USER_LANGUAGE');
  commit('RESET_USER_NAME');
  commit('RESET_COUNTRY_ID');
  commit('RESET_IS_B2B');
};

const changeUserLanguage = ({ commit }, language) => {
  commit('ADD_USER_LANGUAGE', language);
};

const selectClient = ({ commit }, client) => {
  commit('ADD_USER_COMPANY_ID', client.id);
  commit('ADD_COUNTRY_ID', client.countryId);
  commit('ADD_USER_COMPANY_NAME', client.name);
};

const setUserRole = ({ commit }, payload) => {
  commit('SET_USER_TYPE', payload.type);
  commit('SET_USER_DEPARTMENTS', payload.departments);
};

const resetUserRole = ({ commit }) => {
  commit('RESET_USER_TYPE');
  commit('RESET_USER_DEPARTMENTS');
};

const changeReducedMenu = ({ commit }, isReducedMenu) => {
  commit('CHANGE_REDUCED_MENU', isReducedMenu);
};

export default {
  addUserInfo,
  resetUserInfo,
  changeUserLanguage,
  selectClient,
  setUserRole,
  resetUserRole,
  changeReducedMenu,
};
