<template>
  <g>
    <svg :y="y + 67" v-if="type === 'in'">
      <rect
        :fill="fill"
        ref="handle"
        y="0"
        :x="0"
        rx="1" ry="1"
        width="7" height="7"
        @mouseenter="enter" @mouseleave="leave" @mousedown="startDragNewLink" @mouseup="mouseup">
      </rect>
      <text x="12" y="9" font-size="8pt" fill="#000000">{{name}}</text>
    </svg>
    <svg :y="y + 67" v-else>
      <rect
        :fill="fill"
        ref="handle"
        :x="nodeWidth - 2" y="0"
        rx="1" ry="1"
        width="7" height="7"
        @mouseenter="enter" @mouseleave="leave" @mousedown="startDragNewLink" @mouseup="mouseup">
      </rect>
      <text :x="nodeWidth - 6" y="9" text-anchor="end" font-size="8pt" fill="#000000">
        {{ name }}
      </text>
    </svg>
  </g>
</template>
<script>
export default {
  name: 'DiagramPort',
  props: ['id', 'y', 'type', 'name', 'nodeWidth', 'nodeIndex'],
  data() {
    return {
      fill: '#c4c4c4',
    };
  },
  methods: {
    mouseup() {
      this.$emit('mouseUpPort', this.id);
    },

    enter() {
      this.fill = '#c4c4c4';
    },

    leave() {
      this.fill = '#c4c4c4';
    },
    startDragNewLink() {
      this.$emit('onStartDragNewLink', this.id);
    },
  },
};
</script>
