<template>
  <processing-activities-container :pudk="pudk"/>
</template>
<script>
import ProcessingActivitiesContainer from '@/components/containers/ProcessingActivitiesContainer.vue';

export default {
  name: 'processing-activities',
  components: {
    ProcessingActivitiesContainer,
  },
  props: {
    pudk: {
      type: String,
      default: '',
    },
  },
};
</script>
