import AbstractCountryRepresentation from '../../../domain/country/AbstractCountryRepresentation';

export default class CountriesRepresentation extends AbstractCountryRepresentation {
  _translator;

  _name = null;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get code() {
    return this._code.toString();
  }

  get name() {
    if (!this._name) {
      this._name = this._translator.translate(`countries.by_code.${this._code.toString()}`);
    }

    return this._name;
  }
}
