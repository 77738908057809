import TomRepresentation from '@/domain/tom/TomRepresentation';
import TomNote from '@/domain/tom/TomNote';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';

export default class TomToUpdateRepresentation extends TomRepresentation {
  public toJson(): object {
    const attachedDocuments: {[key: string]: string | null}[] = [];
    this._attachedDocuments!.forEach((doc: TomAttachedDocument) => {
      const document: {[key: string]: string | null} = doc.toObject();
      if (document.content) {
        attachedDocuments.push(document);
      }
    });

    return {
      notes: this._notes!.map((note: TomNote) => note.toObject()) || [],
      attached_documents: attachedDocuments,
      status: this._status.toString(),
    };
  }
}
