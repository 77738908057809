export default ({
  treatmentManagersRepository,
}) => async (companyId) => {
  try {
    const hasTreatmentManagers = await treatmentManagersRepository.hasTreatmentManagers(
      companyId,
    );
    return { success: true, data: hasTreatmentManagers };
  } catch (e) {
    if (e === 401) {
      return { success: true, data: [] };
    }
    return { success: false };
  }
};
