<template>
  <v-dialog v-model="showModal" persistent max-width="60%" scrollable>
    <v-card class="form-recipient">
      <v-card-title>
        <h2>
          {{ title }}
        </h2>
        <v-spacer />
        <v-btn
            icon
            @click="closeModal"
        >
          <v-icon>
            close
          </v-icon>
        </v-btn>
        <p>
          {{ $t('recipient.form.explanation') }}
        </p>
      </v-card-title>
      <v-card-text
        id="form-recipient__card-text"
      >
        <submenu-section
          v-if="!recipientInfo"
          :tabActive.sync="tabTypesRecipientActive"
          :sections="tabsTypesRecipient"
        >
          <v-tab-item
            v-for="tab in tabsTypesRecipient"
            :key="tab.key"
            :value="tab.key"
            :transition="false"
          >
            <component
              :is="tab.key"
              @saveRecipient="save"
            >
              <alert-fixed
                v-if="showAlertExist"
                type="error"
                :text="$t('recipient.form.alert.recipient_exist')"
              />
            </component>
          </v-tab-item>
        </submenu-section>
        <div v-else>
          <custom
            :recipientInfo="recipientInfo"
            @saveRecipient="save"
          >
            <alert-fixed
              v-if="showAlertExist"
              type="error"
              :text="$t('recipient.form.alert.recipient_exist')"
            />
          </custom>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import normalizationService from '@/services/shared/normalization';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import DefaultRecipientForm from './DefaultRecipientForm.vue';
import CustomRecipientForm from './CustomRecipientForm.vue';

export default {
  name: 'dialog-form-recipient',
  inject: {
    resetProcessingActivityFormStatus: 'resetProcessingActivityFormStatus',
    resetProcessingActivityForm: 'resetProcessingActivityForm',
  },
  components: {
    SubmenuSection,
    AlertFixed,
    default: DefaultRecipientForm,
    custom: CustomRecipientForm,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    recipientInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    tabsTypesRecipient() {
      return [
        {
          name: this.$t('recipient.form.tab.default'),
          key: 'default',
        },
        {
          name: this.$t('recipient.form.tab.new'),
          key: 'custom',
        },
      ];
    },
  },
  data() {
    return {
      tabTypesRecipientActive: 'default',
      showAlertExist: false,
      recipientsCreated: [],
    };
  },
  methods: {
    save(recipient) {
      if (this.recipientExist(recipient)) {
        const textCard = document.getElementById('form-recipient__card-text');
        textCard.scroll({ top: 0, behavior: 'smooth' });
        this.showAlertExist = true;
        return;
      }

      this.showAlertExist = false;
      this.$emit('saveRecipient', recipient);
    },
    changeActiveTab(tabActive) {
      this.showAlertExist = false;
      this.tabTypesRecipientActive = tabActive;
    },
    fillRecipientsCreated(recipients) {
      this.recipientsCreated = recipients;
    },
    recipientExist(recipientToCreate) {
      const recipientId = (this.recipientInfo) ? this.recipientInfo.id : null;
      return !!this.recipientsCreated.find(
        (recipient) => recipient.nameNormalized
          === normalizationService.sanitizeString(recipientToCreate.name)
          && recipient.id !== recipientId,
      );
    },
    closeModal() {
      this.resetProcessingActivityForm.invoke();
      this.resetProcessingActivityFormStatus.invoke();
      this.$emit('update:show-modal', false);
    },
  },
  beforeMount() {
    this.$eventHub.$on('passRecipientsCreated', this.fillRecipientsCreated);
    this.$eventHub.$emit('getRecipientsCreated');
  },
  beforeDestroy() {
    this.$eventHub.$off('passRecipientsCreated', this.fillRecipientsCreated);
  },
};
</script>
