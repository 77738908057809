import EmployeeId from '@/domain/employee/EmployeeId';
import EmployeeBuilder from '@/domain/employee/EmployeeBuilder';
import EmployeeEntryDate from '@/domain/employee/EmployeeEntryDate';
import EmployeeExitDate from '@/domain/employee/EmployeeExitDate';
import EmployeeWasCreated from '@/domain/employee/EmployeeWasCreated';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import EventBus from '@/application/eventBus/EventBus';
import Employee from '@/domain/employee/Employee';

export default class CreateEmployee {
  private readonly employeeRepository: EmployeeRepository;

  private readonly eventBus: EventBus;

  constructor(employeeRepository: EmployeeRepository, eventBus: EventBus) {
    this.employeeRepository = employeeRepository;
    this.eventBus = eventBus;
  }

  public async invoke(employeeForm: EmployeeObject, clientId: string): Promise<object> {
    try {
      const employeeUuid = EmployeeId.generate().toString();
      const entryDate = employeeForm.entry
        ? EmployeeEntryDate.fromStringDate(employeeForm.entry).toString() : null;
      const exitDate = employeeForm.exit
        ? EmployeeExitDate.fromStringDate(employeeForm.exit).toString() : null;
      const employeeBuilder = new EmployeeBuilder();
      employeeBuilder.withId(employeeUuid)
        .withClientId(clientId)
        .withName(employeeForm.name)
        .withSurname(employeeForm.surname)
        .withEmail(employeeForm.email)
        .withEntry(entryDate)
        .withExit(exitDate)
        .withNif(employeeForm.nif)
        .withPosition(employeeForm.position);

      const employee: Employee = employeeBuilder.create();
      await this.employeeRepository.save(employee);
      this.eventBus.handle(EmployeeWasCreated.fromEmployeeId(employee.getId()));
      return { success: true, employee };
    } catch (e) {
      return { success: false };
    }
  }
}
