import Tom from '@/domain/tom/Tom';
import TomBuilderValidationException from '@/domain/tom/TomBuilderValidationException';
import TomCategory from '@/domain/tom/clasification/TomCategory';
import TomDescription from '@/domain/tom/TomDescription';
import TomId from '@/domain/tom/TomId';
import TomList from '@/domain/tom/clasification/TomList';
import TomName from '@/domain/tom/TomName';
import TomReferenceCode from '@/domain/tom/TomReferenceCode';
import TomStatus from '@/domain/tom/TomStatus';
import TomType from '@/domain/tom/TomType';
import TomClassification from '@/domain/tom/clasification/TomClassification';
import TomNoteContent from '@/domain/tom/TomNoteContent';
import Language from '@/domain/language/Language';
import TomNote from '@/domain/tom/TomNote';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';

interface Classification {
  list: string;
  category: string | null;
}

interface Notes {
  content: string;
  language: string;
}

export default class TomBuilder {
  private id: string | null = null;

  private name: string | null = null;

  private type: string | null = null;

  private status: string | null = null;

  private classification: Classification | null = null

  private referenceCode: string | null = null;

  private description: string | null = null;

  private notes: Notes[] | null = null;

  private attachedDocuments: AttachedDocumentStruct[] | null = null;

  public withMandatoryValues(
    id: string,
    name: string,
    type: string,
    status: string,
    description: string | null,
  ): TomBuilder {
    this.id = id;
    this.name = name;
    this.type = type;
    this.status = status;
    this.description = description;

    return this;
  }

  public withClassification(classification: Classification): TomBuilder {
    this.classification = classification;
    return this;
  }

  public withReferenceCode(referenceCode: string | null): TomBuilder {
    this.referenceCode = referenceCode;
    return this;
  }

  public withNotes(notes: Notes[]): TomBuilder {
    this.notes = notes;
    return this;
  }

  public withAttachedDocuments(attachedDocuments: AttachedDocumentStruct[]): TomBuilder {
    this.attachedDocuments = attachedDocuments;
    return this;
  }

  public create(): Tom {
    this.hasMandatoryValuesOrFails();

    const tom: Tom = new Tom(
      TomId.fromString(this.id!),
      TomName.fromString(this.name!),
      TomType.fromString(this.type!),
      TomStatus.fromString(this.status!),
      this.buildClassification(),
      TomDescription.fromString(this.description!),
    );

    if (this.referenceCode) {
      tom.addReferenceCode(TomReferenceCode.fromString(this.referenceCode));
    }

    if (this.notes) {
      tom.addNotes(this.buildNotes());
    }

    if (this.attachedDocuments) {
      tom.addAttachedDocuments(this.buildAttachedDocuments());
    }

    this.reset();

    return tom;
  }

  private hasMandatoryValuesOrFails() {
    if (!this.id) {
      throw new TomBuilderValidationException('id');
    }

    if (!this.name) {
      throw new TomBuilderValidationException('name');
    }

    if (!this.type) {
      throw new TomBuilderValidationException('type');
    }

    if (!this.status) {
      throw new TomBuilderValidationException('status');
    }

    if (!this.description) {
      throw new TomBuilderValidationException('description');
    }

    if (!this.classification) {
      throw new TomBuilderValidationException('classification');
    }
  }

  private buildClassification(): TomClassification {
    return new TomClassification(
      TomList.fromString(this.classification!.list),
      this.classification!.category ? TomCategory.fromString(this.classification!.category) : null,
    );
  }

  private buildNotes(): TomNote[] {
    return this.notes!.map((note) => new TomNote(
      TomNoteContent.fromString(note.content),
      Language.fromString(note.language),
    ));
  }

  private buildAttachedDocuments(): TomAttachedDocument[] {
    const tomDocuments: TomAttachedDocument [] = [];
    this.attachedDocuments!.forEach((document: AttachedDocumentStruct) => {
      const tomDocument: TomAttachedDocument = TomAttachedDocument
        .fromAttachedDocumentStruct(document);

      tomDocuments.push(tomDocument);
    });

    return tomDocuments;
  }

  private reset(): void {
    this.id = null;
    this.name = null;
    this.type = null;
    this.status = null;
    this.classification = null;
    this.referenceCode = null;
    this.description = null;
    this.notes = null;
    this.attachedDocuments = null;
  }
}
