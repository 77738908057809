<template>
  <div class="h-100 w-100">
    <v-row>
      <v-col cols="12">
        <title-section
          :title="$t('settings.title')"
          :subtitle="$t('settings.subtitle')"
        />
      </v-col>
      <v-col v-if="hasDepartmentSettingsAccess && user.isAdmin" cols="12">
        <submenu-section
          :sections="menuSections"
          :tabActive.sync="currentTab"
          v-on:update:tabActive="tabWasChanged"
        >
          <v-tab-item
            v-for="section in menuSections"
            :key="section.key"
            :value="section.key"
            :transition="false"
          >
            <component
              :isCurrentTab="section.key === currentTab"
              :is="section.key"
            />
          </v-tab-item>
        </submenu-section>
      </v-col>
      <v-col v-else cols="12">
        <personal />
      </v-col>
    </v-row>
  </div>
</template>
<script>

import PersonalSection from '@/components/modules/settings/profile/ProfileSection.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import DepartmentsSection from '@/components/modules/settings/departments/DepartmentsSection.vue';
import departmentSettingsAccess from '@/services/permissions/departmentSettingsAccess';
import TitleSection from '../base/section/TitleSection.vue';

export default {
  name: 'settings-container',
  inject: {
    user: 'user',
  },
  components: {
    personal: PersonalSection,
    departments: DepartmentsSection,
    TitleSection,
    SubmenuSection,
  },
  computed: {
    menuSections() {
      return [
        {
          name: this.$t('settings.menu.personal_settings'),
          key: 'personal',
        },
        {
          name: this.$t('settings.menu.departments'),
          key: 'departments',
        },
      ];
    },
    hasDepartmentSettingsAccess() {
      return departmentSettingsAccess.hasDepartmentSettingsAccess();
    },
  },
  data() {
    return {
      currentTab: 'personal',
    };
  },
  methods: {
    tabWasChanged(sectionName) {
      this.trackActions(sectionName);
      this.$router.push({ path: `/settings/${sectionName}` });
    },
    trackActions(sectionName) {
      this.$track({
        action: `Go to Settings ${sectionName}`,
      });
    },
    updateCurrentTabByRoute() {
      if (!this.$route.path.includes('settings')) {
        return;
      }

      const tab = this.getTabFromRoute();

      if (!tab && this.hasDepartmentSettingsAccess && this.user.isAdmin) {
        this.$router.push({ name: `settings-${this.currentTab}` });
        return;
      }

      this.currentTab = tab;
    },
    getTabFromRoute() {
      return this.$route.path.substring(1).split('/')[1];
    },
  },
  beforeMount() {
    this.$watch(() => this.$route, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.updateCurrentTabByRoute();
      }
    });
    this.updateCurrentTabByRoute();
  },
};
</script>
