<template>
  <div class="loading" v-if="loadingPage">
    <div class="loading__content">
      <div class="spinner-wrapper">
        <div class="spinner-elmnt">
          <div></div><div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'loading-page',
  computed: {
    ...mapGetters('shared', [
      'loadingPage',
    ]),
  },
};
</script>
