import DocumentData from '../DocumentData';

export default class DocumentDataRecipient extends DocumentData {
  representedAs(documentDataRecipientRepresentation) {
    documentDataRecipientRepresentation.setId(this._id);
    documentDataRecipientRepresentation.setClientId(this._clientId);
    documentDataRecipientRepresentation.setName(this._name);
    documentDataRecipientRepresentation.setLastModification(this._lastModification);

    return documentDataRecipientRepresentation;
  }
}
