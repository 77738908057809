import RecipientReadModel from '@/domain/recipient/readModel/RecipientReadModel';
import RecipientListRepresentationCache from '@/infrastructure/recipient/RecipientListRepresentationCache';
import GetRecipientsException from '@/infrastructure/recipient/GetRecipientsException';
import RecipientListRepresentation from '@/application/recipient/RecipientListRepresentation';
import { getManagement } from '@/shared/restActions';

export default class RecipientReadModelApi extends RecipientReadModel {
  _translator;

  _normalizationService;

  _processingDateService;

  constructor(translator, normalizationService, processingDatesService) {
    super();
    this._translator = translator;
    this._normalizationService = normalizationService;
    this._processingDateService = processingDatesService;
  }

  async ofClient(clientId) {
    try {
      let recipientsRaw = RecipientListRepresentationCache.getRecipients();

      if (recipientsRaw.length) {
        return recipientsRaw;
      }

      recipientsRaw = await this._getRecipientsFromApi(clientId.toInt());

      const recipients = this._buildRecipientListRepresentation(recipientsRaw);

      RecipientListRepresentationCache.setRecipients(recipients);

      return recipients;
    } catch (e) {
      throw new Error('Error fetching recipients');
    }
  }

  async _getRecipientsFromApi(clientId) {
    try {
      const response = await getManagement(`/api/v2/clients/${clientId}/recipients`);

      return response.data;
    } catch (e) {
      console.error(e);
      throw new GetRecipientsException('Error fetch employees');
    }
  }

  _buildRecipientListRepresentation(recipientsRaw) {
    const recipientsListRepresentation = [];
    recipientsRaw.forEach((recipientRaw) => {
      const recipientsRepresentation = new RecipientListRepresentation(
        this._translator, this._normalizationService, this._processingDateService,
      );
      recipientsRepresentation.setId(recipientRaw.id);
      recipientsRepresentation.setName(recipientRaw.name);
      recipientsRepresentation.setStatus(recipientRaw.status);
      recipientsRepresentation.setCategory(recipientRaw.category);
      recipientsRepresentation.setRole(recipientRaw.role);
      recipientsRepresentation.setLastModification(recipientRaw.last_modification);
      recipientsRepresentation.setDocuments(recipientRaw.contracts);
      recipientsRepresentation.setReplacedBy(recipientRaw.replaced_by);
      recipientsRepresentation.setIsReplacement(recipientRaw.is_replacement);
      recipientsRepresentation.setProcessingActivities(recipientRaw.processing_activities);

      recipientsListRepresentation.push(recipientsRepresentation);
    });

    return recipientsListRepresentation;
  }
}
