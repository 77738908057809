import StringValueObject from '@/domain/shared/strings/StringValueObject';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import AttachedDocumentStruct from '@/domain/attachedDocuments/AttachedDocumentStruct';

export default class AttachedDocument {
  private readonly name: StringValueObject;

  private readonly id: UuidValueObject;

  private content: string | null = null;

  constructor(name: StringValueObject, id: UuidValueObject) {
    this.name = name;
    this.id = id;
  }

  addContent(content: string) {
    this.content = content;
  }

  getName(): StringValueObject {
    return this.name;
  }

  getId(): UuidValueObject {
    return this.id;
  }

  toObject(): {[key: string]: string | null} {
    return {
      name: this.name.toString(),
      id: this.id ? this.id.toString() : null,
      content: this.content ? this.content.toString() : null,
    };
  }

  public static fromAttachedDocumentStruct<T extends typeof AttachedDocument>(
    this: T,
    attachedDocumentsStruct: AttachedDocumentStruct,
  ): InstanceType<T> {
    const document = (new this(
      StringValueObject.fromString(attachedDocumentsStruct.name),
      UuidValueObject.fromString(attachedDocumentsStruct.id),
    )) as InstanceType<T>;

    if (attachedDocumentsStruct.content) {
      document.addContent(attachedDocumentsStruct.content);
    }

    return document;
  }
}
