import RecipientId from '@/domain/recipient/RecipientId';
import RecipientReplaceWasRequested from '@/domain/recipient/RecipientReplaceWasRequested';

export default class RequestRecipientReplace {
  _recipientRepository;

  _recipientReplaceWasRequestedEventListener;

  constructor(recipientRepository, recipientReplaceWasRequestedEventListener) {
    this._recipientRepository = recipientRepository;
    this._recipientReplaceWasRequestedEventListener = recipientReplaceWasRequestedEventListener;
  }

  async invoke(fromRecipientId, toRecipientId) {
    try {
      await this._recipientRepository.requestReplacement(
        RecipientId.fromString(fromRecipientId),
        RecipientId.fromString(toRecipientId),
      );

      const event = new RecipientReplaceWasRequested(fromRecipientId, toRecipientId);
      this._recipientReplaceWasRequestedEventListener.invoke(event);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
