<template>
  <div>
    <a @click="getEmployeeAndShowForm">
      {{ employee.name }}
    </a>
    <dialog-form-employee
      v-if="openEditEmployeeModal"
      :showModal.sync="openEditEmployeeModal"
      :showNif="showNif"
      :dataEmployee="employeeToEdit"
      @saveEmployee="update"
    />
  </div>
</template>

<script>
import DialogFormEmployee from './form/DialogFormEmployee.vue';

export default {
  name: 'edit-employee',
  inject: {
    user: 'user',
    fetchEmployee: 'fetchEmployee',
    updateEmployee: 'updateEmployee',
  },
  components: {
    DialogFormEmployee,
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    showNif: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openEditEmployeeModal: false,
      employeeToEdit: {},
    };
  },
  methods: {
    async getEmployeeAndShowForm() {
      const response = await this.fetchEmployee.invoke(this.employee.id);

      if (!response.success) {
        this.$showError('employee.edit.error_load');
        return;
      }

      this.employeeToEdit = response.employee;
      this.openEditEmployeeModal = true;
    },
    async update(employee) {
      this.$showLoading();
      const response = await this.updateEmployee.invoke(
        this.user.clientId,
        this.employeeToEdit.id,
        employee,
      );
      this.$hideLoading();

      if (!response.success) {
        this.$showError('employee.edit.error');
        return;
      }

      this.$showSuccess('employee.edit.success');
      this.openEditEmployeeModal = false;
      this.$eventHub.$emit('employeeWasUpdated');
    },
  },
};
</script>
