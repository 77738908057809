import Client from '@/domain/client/Client';
import ClientCacheRepresentation from '@/infrastructure/client/ClientCacheRepresentation';
import ClientId from '@/domain/client/ClientId';
import store from '../../store';

export default class ClientCache {
  public static saveAll(clients: Client[]) {
    const clientCollection: { [key: string]: Client} = {};
    clients.forEach((client: Client) => {
      clientCollection[client.getId().toString()] = client;
    });
    store.dispatch('client/addClients', clientCollection);
  }

  public static all(): Client[] {
    return Object.values(store.getters['client/clients']);
  }

  public static changeClientSelected(client: ClientCacheRepresentation): void {
    store.dispatch('user/selectClient', client.toObject());
  }

  public static changeClientSelectedWithId(client: { id: number }): void {
    store.dispatch('user/selectClient', client);
  }

  public static retrieve(clientId: ClientId): Client {
    const clients: { [key: string]: Client} = store.getters['client/clients'];
    return clients[clientId.toString()];
  }
}
