<template>
  <div>
    <h2>
      {{ $t('privacyPolicy.title') }}
    </h2>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.data_protect.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.data_protect.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.responsible.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.responsible.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.responsible.p_first_second') }}
              <br>
              {{ $t('privacyPolicy.responsible.p_first_third') }}
              <br>
              {{ $t('privacyPolicy.responsible.p_first_fourth') }}
              <br>
              {{ $t('privacyPolicy.responsible.p_first_fifth') }}
              <br>
              {{ $t('privacyPolicy.responsible.p_first_sixth') }}
              <br>
                {{ $t('privacyPolicy.responsible.p_first_seventh') }}
              <strong>
                {{ $t('privacyPolicy.responsible.p_first_seventh_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.responsible.p_first_eigth') }}
              <br>
              <strong>
                {{ $t('privacyPolicy.responsible.p_first_nineth_bold') }}
              </strong>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.purpose.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>
                {{ $t('privacyPolicy.purpose.p_first_first_first_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.purpose.p_first_second') }}
            </p>
            <p>
              <strong>
                {{ $t('privacyPolicy.purpose.p_second_first_first_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.purpose.p_second_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.purpose.p_third') }}
            </p>
            <p>
              {{ $t('privacyPolicy.purpose.p_fourth') }}
            </p>
            <p>
              <strong>
                {{ $t('privacyPolicy.purpose.p_fifth_first_first_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.purpose.p_fifth_second') }}
              <br>
                {{ $t('privacyPolicy.purpose.p_fifth_third') }}
                <ul>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_fifth_third_list_first') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_fifth_third_list_second') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_fifth_third_list_third') }}
                  </li>
                </ul>
            </p>
            <p>
              {{ $t('privacyPolicy.purpose.p_sixth_first_second') }}
              <br>
                {{ $t('privacyPolicy.purpose.p_seventh') }}
                <ul>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_seventh_list_first') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_seventh_list_second') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_seventh_list_third') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.purpose.p_seventh_list_fourth') }}
                  </li>
                </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.legal_basis.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.legal_basis.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.recipients.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <strong>
                {{ $t('privacyPolicy.recipients.p_first_first_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.recipients.p_first_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.recipients.p_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.recipients.p_third') }}
            </p>
            <p>
              <strong>
                {{ $t('privacyPolicy.recipients.p_fourth_first_bold') }}
              </strong>
              <br>
                {{ $t('privacyPolicy.recipients.p_fourth_second') }}
            </p>
            <p>
              {{ $t('privacyPolicy.recipients.p_fifth') }}
              <ul>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_first_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_first') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_second_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_second') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_third_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_third') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_fourth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_fourth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_fifth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_fifth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_sixth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_sixth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_seventh_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_seventh') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_eighth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_eighth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_nineth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_nineth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_tenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_tenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_eleventh_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_eleventh') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twelfth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twelfth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_thirteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_thirteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_fourteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_fourteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_fiveteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_fiveteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_sixteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_sixteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_seventeenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_seventeenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_eighteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_eighteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_nineteenth_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_nineteenth') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentith_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentith') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentyone_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentyone') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentytwo_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentytwo') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentythird_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentythird') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentyfour_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentyfour') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentyfive_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentyfive') }}
                </li>
                <li>
                  <strong>
                    {{ $t('privacyPolicy.recipients.p_fifth_list_twentysix_bold') }}
                  </strong>
                  <br>
                  {{ $t('privacyPolicy.recipients.p_fifth_list_twentysix') }}
                </li>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.data_transfers.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.data_transfers.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.data_transfers.p_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.period.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.period.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.intersted_rigths.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_first_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_first') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_second') }}
                <ul>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_first') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_second') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_third') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_fourth') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_fifth') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_sixth') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_seventh') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_list_eigth') }}
                  </li>
                </ul>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_second_second') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_third_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_third') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth') }}
                <ul>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_first') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_second') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_third') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_fourth') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_fifth') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_list_sixth') }}
                  </li>
                </ul>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_fourth_second') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth') }}
                <ul>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_list_first') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_list_second') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_list_third') }}
                  </li>
                  <li>
                    {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_list_fourth') }}
                  </li>
                </ul>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_fifth_second') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_sixth_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_sixth_first') }}
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_sixth_second') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_seventh_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_seventh_first') }}
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_seventh_second') }}
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_seventh_third') }}
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_seventh_fourth') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_eigth_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_eigth_first') }}
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_eigth_second') }}
              </li>
              <li>
                <strong>
                  {{ $t('privacyPolicy.intersted_rigths.p_first_list_nineth_bold') }}
                </strong>
                <br>
                {{ $t('privacyPolicy.intersted_rigths.p_first_list_nineth') }}
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.data_rights.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.data_rights.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.data_rights.p_second_first') }}
              <br>
              {{ $t('privacyPolicy.data_rights.p_second_second') }}
              <br>
              {{ $t('privacyPolicy.data_rights.p_second_third') }}
              <br>
              {{ $t('privacyPolicy.data_rights.p_second_fourth') }}
              <br>
              {{ $t('privacyPolicy.data_rights.p_second_fifth') }}
              <br>
              {{ $t('privacyPolicy.data_rights.p_second_sixth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.data_requirements.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.data_requirements.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.automation.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.automation.p_first') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.cookies.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.cookies.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.cookies.p_first_second') }}
              <br>
              {{ $t('privacyPolicy.cookies.p_first_third') }}
              <br>
              {{ $t('privacyPolicy.cookies.p_first_fourth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.precision.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.precision.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.precision.p_first_second') }}
            </p>
            <p>
            {{ $t('privacyPolicy.precision.p_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.changes.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.changes.p_first_first') }}
              <br>
              {{ $t('privacyPolicy.changes.p_first_second') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('privacyPolicy.uk.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              {{ $t('privacyPolicy.uk.p_first') }}
            </p>
            <p>
              {{ $t('privacyPolicy.uk.p_second_first') }}
              <br>
              {{ $t('privacyPolicy.uk.p_second_second') }}
              <br>
              {{ $t('privacyPolicy.uk.p_second_thirth') }}
              <br>
              {{ $t('privacyPolicy.uk.p_second_fourth') }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'privacy-policy-de',
};
</script>
