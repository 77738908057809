import TomType from '@/domain/tom/TomType';
import TomStatus from '@/domain/tom/TomStatus';
import TomDescription from '@/domain/tom/TomDescription';
import TomId from '@/domain/tom/TomId';
import TomName from '@/domain/tom/TomName';
import TomReferenceCode from '@/domain/tom/TomReferenceCode';
import TomClassification from '@/domain/tom/clasification/TomClassification';
import TomNote from '@/domain/tom/TomNote';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';

export default abstract class TomRepresentation {
  protected _id!: TomId;

  protected _name!: TomName;

  protected _type!: TomType;

  protected _status!: TomStatus;

  protected _classification!: TomClassification;

  protected _referenceCode!: TomReferenceCode | null;

  protected _description!: TomDescription;

  protected _notes: TomNote[] | null = null;

  protected _attachedDocuments: TomAttachedDocument[] | null = null;

  public setId(id: TomId): TomRepresentation {
    this._id = id;
    return this;
  }

  public setName(name: TomName): TomRepresentation {
    this._name = name;
    return this;
  }

  public setType(type: TomType): TomRepresentation {
    this._type = type;
    return this;
  }

  public setStatus(status: TomStatus): TomRepresentation {
    this._status = status;
    return this;
  }

  public setClassification(classification: TomClassification) {
    this._classification = classification;
    return this;
  }

  public setReferenceCode(referenceCode: TomReferenceCode | null): TomRepresentation {
    this._referenceCode = referenceCode;
    return this;
  }

  public setDescription(description: TomDescription): TomRepresentation {
    this._description = description;
    return this;
  }

  public setNotes(notes: TomNote[] | null): TomRepresentation {
    this._notes = notes;
    return this;
  }

  public setAttachedDocuments(attachedDocuments: TomAttachedDocument[] | null): TomRepresentation {
    this._attachedDocuments = attachedDocuments;
    return this;
  }
}
