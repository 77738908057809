export default class ChangeClientUserRoleCommand {
  public readonly departments: string[];

  public readonly type: string;

  public readonly id: number;

  constructor(
    id: number,
    type: string,
    departments: string[],
  ) {
    this.id = id;
    this.type = type;
    this.departments = departments;
  }
}
