<template>
  <v-container fluid class="home-page home-page-client">
    <v-row>
      <v-col md="6" sm="12">
        <sections-client />
      </v-col>
      <v-col md="6" sm="12">
        <tasks-list />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import SectionsClient from '../modules/homePage/SectionsClient.vue';
import TasksList from '../modules/homePage/taskSection/TasksList.vue';

export default {
  name: 'home-client-container',
  components: {
    SectionsClient,
    TasksList,
  },
};
</script>
