import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';

export default abstract class DomainEvent {
  protected occurredOn: Date;

  protected id: UuidValueObject;

  protected constructor() {
    this.occurredOn = new Date();

    this.id = UuidValueObject.generate();
  }

  public getOccurredOn(): Date {
    return this.occurredOn;
  }

  public getId(): string {
    return this.id.toString();
  }

  public abstract get eventName(): string;
}
