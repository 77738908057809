<template>
  <treatment-managers-container />
</template>
<script>
import TreatmentManagersContainer from '@/components/containers/TreatmentManagersContainer.vue';

export default {
  name: 'treatment-managers',
  components: {
    TreatmentManagersContainer,
  },
};
</script>
