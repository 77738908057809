import EmployeeRepositoryApi from '@/infrastructure/employee/EmployeeRepositoryApi';
import normalization from '@/services/shared/normalization';
import eventBus from '@/infrastructure/eventBus/EventBus';
import ResetEmployees from './resetEmployees/ResetEmployees';
import FetchEmployees from './fetchEmployees/FetchEmployees';
import RemoveEmployees from './removeEmployees/RemoveEmployees';
import CreateEmployee from './createEmployee/CreateEmployee';
import UpdateEmployee from './updateEmployee/UpdateEmployee';
import FetchEmployee from './fetchEmployee/FetchEmployee';

export default {
  resetEmployees: new ResetEmployees(new EmployeeRepositoryApi()),
  fetchEmployees: new FetchEmployees(new EmployeeRepositoryApi(), normalization),
  removeEmployee: new RemoveEmployees(new EmployeeRepositoryApi(), eventBus),
  createEmployee: new CreateEmployee(new EmployeeRepositoryApi(), eventBus),
  updateEmployee: new UpdateEmployee(new EmployeeRepositoryApi(), eventBus),
  fetchEmployee: new FetchEmployee(new EmployeeRepositoryApi()),
};
