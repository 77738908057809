export default ({
  treatmentManagersRepository,
  documentViewer,
}) => async (documentId, typeDocument, lang, nameDocument) => {
  try {
    const urlView = await treatmentManagersRepository.viewDocument(
      documentId, typeDocument, lang,
    );

    documentViewer.setDocumentInformation({ url: urlView.url, name: nameDocument });
    documentViewer.showDocumentViewer();
    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
