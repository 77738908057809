<template>
  <list-data-breach>
    <template
      slot="downloadAttachedDocumentButton"
      slot-scope="props"
    >
      <download-attached-document-button
        :documents="props.documents"
      />
    </template>
    <template
      slot="downloadReportButton"
      slot-scope="props"
    >
      <download-report-button
        :data-breach-id="props.id"
        :data-breach-code="props.code"
      />
    </template>
  </list-data-breach>
</template>

<script>
import DownloadReportButton from '@/components/modules/dataBreach/historic/DownloadReportButton.vue';
import DownloadAttachedDocumentButton
  from '@/components/modules/dataBreach/historic/DownloadAttachedDocumentButton.vue';
import ListDataBreach from '@/components/modules/dataBreach/historic/ListDataBreach.vue';

export default {
  name: 'historic-section',
  components: {
    DownloadReportButton,
    DownloadAttachedDocumentButton,
    ListDataBreach,
  },
};
</script>
