import AbstractRecipientRepresentation from '@/domain/recipient/AbstractRecipientRepresentation';

export default class RecipientRepresentationApi extends AbstractRecipientRepresentation {
  toJson() {
    return {
      id: this._id.toString(),
      client_id: this._clientId.toInt(),
      name: this._name.toString(),
      business_name: (this._businessName) ? this._businessName.toString() : null,
      country: (this._country) ? this._country.toString() : null,
      state: (this._state) ? this._state.toString() : null,
      category: (this._category) ? this._category.toString() : null,
      role: (this._role) ? this._role.toString() : null,
      warranties: this._warranties.map((warranty) => warranty.toString()),
      status: (this._status) ? this._status.toString() : null,
      dpa: (this._dpa) ? this._dpa.toString() : null,
      files: this._documents.filter((document) => document.isPending())
        .map((document) => document.toObject()),
      dpa_status: (this._dpaStatus) ? this._dpaStatus.toString() : null,
      recipient_model: (this._recipientModel) ? this._recipientModel : null,
      last_modification: this._lastModification.toString(),
    };
  }
}
