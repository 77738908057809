import DateTimeValidationException from '@/domain/shared/dateTimeValueObject/DateTimeValidationException';

const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}|\d{4}-\d{2}-\d{2}$/;

export default class DateTimeValueObject {
  private readonly date: Date;

  constructor(date: string) {
    DateTimeValueObject.isValidOrFail(date);
    this.date = new Date(date);
  }

  static isValidOrFail(date: string): void {
    if (!date.match(DATE_FORMAT)) {
      throw new DateTimeValidationException(`${this.constructor.name} is not valid`);
    }
  }

  toString(): string {
    return DateTimeValueObject.createStringDatetimeW3C(this.date);
  }

  toStringDate(): string {
    return `${String(this.date.getUTCDate()).padStart(2, '0')}/${String(this.date.getUTCMonth() + 1).padStart(2, '0')}/${this.date.getUTCFullYear()}`;
  }

  toStringDatetime(): string {
    return `${String(this.date.getUTCDate()).padStart(2, '0')}/${String(this.date.getUTCMonth() + 1).padStart(2, '0')}/${this.date.getUTCFullYear()} ${String(this.date.getUTCHours()).padStart(2, '0')}:${String(this.date.getUTCMinutes()).padStart(2, '0')}`;
  }

  toStringTime(): string {
    return `${String(this.date.getUTCHours()).padStart(2, '0')}:${String(this.date.getUTCMinutes()).padStart(2, '0')}`;
  }

  static fromStringDate(date: string): DateTimeValueObject {
    const dateSplit: string[] = date.split('/');
    const dateISO: string = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
    return new this(dateISO);
  }

  static fromStringDateTime(dateString: string): DateTimeValueObject {
    const dateSplit: string[] = dateString.split(' ');
    const date: string[] = dateSplit[0].split('/');
    const dateTime: string[] = dateSplit[1].split(':');
    const dateISO: string = `${date[2]}-${date[1]}-${date[0]}T${dateTime[0]}:${dateTime[1]}:${dateTime[2] || '00'}+00:00`;
    return new this(dateISO);
  }

  static fromW3CDate(date: string): DateTimeValueObject {
    return new this(date);
  }

  static generateW3C(): DateTimeValueObject {
    const today: Date = new Date();
    const todayW3C: string = `${today.getUTCFullYear()}-${String(today.getUTCMonth() + 1).padStart(2, '0')}-${String(today.getUTCDate()).padStart(2, '0')}T${String(today.getUTCHours()).padStart(2, '0')}:${String(today.getUTCMinutes()).padStart(2, '0')}:${String(today.getUTCSeconds()).padStart(2, '0')}+00:00`;
    return new this(todayW3C);
  }

  static createStringDatetimeW3C(date: Date): string {
    return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}+00:00`;
  }
}
