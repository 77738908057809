<template>
  <div>
    <v-btn
      outlined
      rounded
      color="primary"
      @click="showForm = true"
      v-track="'Create User -- start'"
    >
      <v-icon>
        add_circle
      </v-icon>
      {{ $t('clientUsers.new_user') }}
    </v-btn>
    <v-dialog
      v-model="showForm"
      width="60%"
      persistent
      scrollable
    >
      <v-card
        v-if="showForm"
        class="modal-users">
        <v-card-title
          class="modal-users__form--title"
        >
          <h2>
            {{ $t('clientUsers.form.title') }}
          </h2>
          <v-spacer/>
          <v-btn
            icon
            @click="showForm = false"
          >
            <v-icon v-text="'close'" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <add-user v-if="!user.isB2B" />
          <submenu-section v-else
            :tabActive.sync="currentTab"
            :sections="menuSections"
            v-on:update:tabActive="trackActions"
          >
            <v-tab-item
              v-for="section in menuSections"
              :key="section.key"
              :value="section.key"
              :transition="false"
            >
              <component
                :is="section.key"
              />
            </v-tab-item>
          </submenu-section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddUserSection from '@/components/modules/clientUsers/addUser/AddUserSection.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import ImportUsersSection from '@/components/modules/clientUsers/importUsers/ImportUsersSection.vue';

export default {
  name: 'create-client-user-modal',
  inject: {
    user: 'user',
  },
  components: {
    SubmenuSection,
    addUser: AddUserSection,
    importUsers: ImportUsersSection,
  },
  computed: {
    menuSections() {
      return [
        {
          name: this.$t('clientUsers.add_user'),
          key: 'addUser',
        },
        {
          name: this.$t('clientUsers.massupload.submenu'),
          key: 'importUsers',
        },
      ];
    },
  },
  data() {
    return {
      showForm: false,
      currentTab: 'addUser',
    };
  },
  methods: {
    hideModalForm() {
      this.showForm = false;
    },
    trackActions(tabName) {
      this.$track({
        action: `Go to ${tabName}`,
      });
    },
  },
  beforeMount() {
    this.$eventHub.$on('anUserWasCreated', this.hideModalForm);
    this.$eventHub.$on('clientUserFileWasUploaded', this.hideModalForm);
  },
  beforeDestroy() {
    this.$eventHub.$off('anUserWasCreated', this.hideModalForm);
    this.$eventHub.$off('clientUserFileWasUploaded', this.hideModalForm);
  },
  watch: {
    showForm() {
      if (!this.showForm) {
        this.currentTab = 'addUser';
      }
    },
  },
};
</script>
