import Translator from '@/infrastructure/Translator';
import DataBreachesHistoricReadModelRepresentation
  from '@/domain/dataBreach/historic/DataBreachesHistoricReadModelRepresentation';

export default class DataBreachesHistoricRepresentation
  extends DataBreachesHistoricReadModelRepresentation {
  private readonly translator: Translator;

  private _agencyNotificationTranslation: string | null = null;

  constructor(translator: Translator) {
    super();
    this.translator = translator;
  }

  get name(): string {
    return `${this.translator.translate('data_breach.title_barra')}:`;
  }

  get agencyNotificationTranslation(): string {
    if (!this._agencyNotificationTranslation) {
      this._agencyNotificationTranslation = this.agencyNotification ? `${this.translator.translate('data_breach.breach_notify_agency_yes')}` : `${this.translator.translate('data_breach.breach_notify_agency_no')}`;
    }

    return this._agencyNotificationTranslation;
  }
}
