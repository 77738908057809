<template>
  <training-container/>
</template>

<script>

import TrainingContainer from '@/components/containers/TrainingContainer.vue';

export default {
  name: 'training',
  components: { TrainingContainer },
};
</script>
