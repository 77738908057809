import userLogoutBuilder from '@/usecases/users/userLogout';
import getInfoByTokenBuilder from '@/usecases/users/getInfoByToken';
import activateClientUserBuilder from '@/usecases/users/activateClientUser';
import changeLanguageBuilder from '@/usecases/users/changeLanguage';
import changePasswordSettingsBuilder from '@/usecases/users/changePasswordSettings';
import userRepository from '@/repositories/users';
import adminRepository from '@/repositories/admins';
import authRepository from '@/services/auth';
import userLanguageService from '@/services/user/userLanguage';
import refreshContainerService from '@/services/shared/refreshContainer';

export default {
  logout: userLogoutBuilder({
    userRepository, authRepository, adminRepository, refreshContainerService,
  }),
  getInfoByToken: getInfoByTokenBuilder({ userRepository }),
  activate: activateClientUserBuilder({ userRepository }),
  changeLanguage: changeLanguageBuilder({ userRepository, userLanguageService }),
  changePasswordSettings: changePasswordSettingsBuilder({ userRepository }),
};
