import { getManagement } from '@/shared/restActions';

function getUrlUserIsAdmin(companyId, isAdmin) {
  if (isAdmin) {
    return `?company=${companyId}`;
  }
  return '';
}

function getListDocumentsUrl(companyId, locale, spainAdmin) {
  let url = `/api/v2/documents-legacy/risks-pias/${locale}/list/${companyId}`;

  if (!spainAdmin) {
    url = `/api/v2/documents-legacy/risks-pias/${locale}/non-spain-list/${companyId}`;
  }
  return url;
}

function getViewUrl(documentId, typeDocument, lang) {
  let url = `/api/v2/documents-legacy/preview/layouts/${lang}/${documentId}`;

  if (typeDocument === 'pia') {
    url = `/api/v2/documents-legacy/preview/pias/es/${documentId}`;
  }

  return url;
}

function getListDocuments(companyId, locale, spainAdmin, isAdmin) {
  let url = getListDocumentsUrl(companyId, locale, spainAdmin);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function downloadDocument(companyId, documentId, typeDocument, type, isAdmin) {
  if (typeDocument !== 'pia') {
    return getManagement(`/api/v2/documents/custom-document/${documentId}/download/${type}`, {}).then((response) => response.data);
  }

  let url = `/api/v2/documents-legacy/download/pias/${type}/${documentId}`;
  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

function viewDocument(documentId, typeDocument, lang, companyId, isAdmin) {
  if (typeDocument !== 'pia') {
    return getManagement(`/api/v2/documents/custom-document/${documentId}/preview/${lang}`, {}).then((response) => response.data);
  }

  let url = getViewUrl(documentId, typeDocument, lang);

  url += getUrlUserIsAdmin(companyId, isAdmin);

  return getManagement(url, {});
}

export default {
  getListDocuments,
  downloadDocument,
  viewDocument,
};
