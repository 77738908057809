<template>
  <tom-container />
</template>

<script>
import TomContainer from '@/components/containers/TomContainer.vue';

export default {
  name: 'tom',
  components: { TomContainer },
};
</script>
