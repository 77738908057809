import TomNote from '@/domain/tom/TomNote';
import TomRepresentation from '@/domain/tom/TomRepresentation';
import TomAttachedDocument from '@/domain/tom/TomAttachedDocument';
import TomStatus from '@/domain/tom/TomStatus';

export default class TomToUpdate extends TomRepresentation {
  get notes(): TomNote[] | null {
    return this._notes! || null;
  }

  get attachedDocuments(): TomAttachedDocument[] | null {
    return this._attachedDocuments! || null;
  }

  get status(): TomStatus {
    return this._status;
  }
}
