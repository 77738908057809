import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import department from '@/store/department';
import adminInfo from './admin';
import client from './client';
import clientUsers from './clientUsers';
import dataBreach from './dataBreach';
import employee from './employee';
import equipment from './equipment';
import headquarter from './headquarter';
import processingActivity from './processingActivity';
import recipient from './recipient';
import recipientModel from './recipient/model';
import recipientsListRepresentation from './recipient/listRepresentation';
import shared from './shared';
import task from './task';
import tom from './tom';
import training from './training';
import user from './user';
import viewDocument from './viewDocument';
import historicRoutes from './historicRoutes';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'userdesk-info',
  paths: [],
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    admin: state.admin,
  }),
});

const vuexLocalStorageHistoricRoutes = new VuexPersist({
  key: 'userdesk-historic-routes',
  paths: [],
  storage: window.localStorage,
  reducer: (state) => ({
    historicRoutes: state.historicRoutes.historicRoutes,
  }),
});

export default new Vuex.Store({
  modules: {
    admin: adminInfo,
    client,
    clientUsers,
    employee,
    equipment,
    dataBreach,
    department,
    headquarter,
    processingActivity,
    recipient,
    recipientModel,
    recipientsListRepresentation,
    shared,
    task,
    tom,
    training,
    user,
    viewDocument,
    historicRoutes,
  },
  plugins: [vuexLocalStorage.plugin, vuexLocalStorageHistoricRoutes.plugin],
});
