import RecipientDocumentValidationException from '@/domain/recipient/RecipientDocumentValidationException';
import DocumentDataName from '@/domain/documentData/DocumentDataName';

export default class RecipientDocument {
  _name;

  _content;

  constructor(name) {
    this._name = DocumentDataName.fromString(name);
  }

  static fromName(documentName) {
    return new this(documentName);
  }

  static fromContent(document) {
    RecipientDocument.isValidContentOrFail(document.content);
    const recipientDocument = new this(document.name);
    recipientDocument._content = document.content;
    return recipientDocument;
  }

  static isValidContentOrFail(content) {
    if (!content) {
      throw new RecipientDocumentValidationException('Recipient Document content should not be empty');
    }
  }

  isPending() {
    return !!this._content;
  }

  activate() {
    this._content = null;
  }

  toObject() {
    return {
      name: this._name.toString(),
      content: this._content,
    };
  }

  toString() {
    return this._name.toString();
  }
}
