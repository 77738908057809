export default class Headquarter {
  _id;

  _clientId;

  _name;

  _address;

  _country;

  _locality;

  _securityMeasures;

  constructor(
    id,
    clientId,
    name,
    address,
    country,
    locality,
  ) {
    this._id = id;
    this._clientId = clientId;
    this._name = name;
    this._address = address;
    this._country = country;
    this._locality = locality;
  }

  addSecurityMeasures(securityMeasures) {
    this._securityMeasures = securityMeasures;
  }

  getId() {
    return this._id;
  }

  representedAs(headquarterRepresentation) {
    headquarterRepresentation.setId(this._id);
    headquarterRepresentation.setClientId(this._clientId);
    headquarterRepresentation.setName(this._name);
    headquarterRepresentation.setAddress(this._address);
    headquarterRepresentation.setCountry(this._country);
    headquarterRepresentation.setLocality(this._locality);
    headquarterRepresentation.setSecurityMeasures(this._securityMeasures);

    return headquarterRepresentation;
  }

  update(headquarterToUpdateRepresentation) {
    this._name = headquarterToUpdateRepresentation.name;
    this._address = headquarterToUpdateRepresentation.address;
    this._country = headquarterToUpdateRepresentation.country;
    this._locality = headquarterToUpdateRepresentation.locality;
    this._securityMeasures = headquarterToUpdateRepresentation.securityMeasures;
  }
}
