import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MAX_LENGTH = 600;
const MIN_LENGTH = 1;

export default class DataBreachConsequences extends StringValueObject {
  constructor(string: string) {
    super(string, MIN_LENGTH, MAX_LENGTH);
  }
}
