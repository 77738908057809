import Language from '@/domain/language/Language';
import TrainingStatus from '@/domain/training/TrainingStatus';

export default class TrainingRepresentation {
  protected _availableLanguages!: Language[];

  protected _status!: TrainingStatus | null;

  protected _userLanguage!: Language | null;

  public setAvailableLanguages(availableLanguages: Language[]): this {
    this._availableLanguages = availableLanguages;
    return this;
  }

  public setStatus(status: TrainingStatus | null): this {
    this._status = status;
    return this;
  }

  public setUserLanguage(userLanguage: Language | null): this {
    this._userLanguage = userLanguage;
    return this;
  }
}
