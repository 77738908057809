import DepartmentRepresentation from '@/domain/department/DepartmentRepresentation';
import DepartmentName from '@/domain/department/DepartmentName';

export default class DepartmentRawRepresentation extends DepartmentRepresentation {
  toJson(): object {
    return {
      name: this._name.map((name: DepartmentName) => name.toObject()),
      id: this._id.toString(),
    };
  }
}
