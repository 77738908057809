import DataBreachWasCompletedEventListener
  from '@/application/dataBreach/dataBreachWasCompleted/DataBreachWasCompletedEventListener';
import EditDataBreach from '@/application/dataBreach/editDataBreach/EditDataBreach';
import DataBreachObject from '@/application/dataBreach/DataBreachObject';
import DataBreachStatus from '@/domain/dataBreach/DataBreachStatus';
import DataBreachWasCompleted from '@/domain/dataBreach/DataBreachWasCompleted';
import DataBreachId from '@/domain/dataBreach/DataBreachId';

export default class EditDataBreachWithReportWrapper {
  private readonly editDataBreach: EditDataBreach;

  private readonly dataBreachWasCompletedEventListener: DataBreachWasCompletedEventListener;

  constructor(
    editDataBreach: EditDataBreach,
    dataBreachWasCompletedEventListener: DataBreachWasCompletedEventListener,
  ) {
    this.editDataBreach = editDataBreach;
    this.dataBreachWasCompletedEventListener = dataBreachWasCompletedEventListener;
  }

  public async invoke(dataBreach: DataBreachObject): Promise<object> {
    try {
      this.hasMandatoryDpoAnswer(dataBreach);

      Object.assign(dataBreach, { status: DataBreachStatus.completed().toString() });
      const response: {[key: string]: any} = await this.editDataBreach.invoke(dataBreach);

      if (!response.success) {
        return response;
      }

      const event = new DataBreachWasCompleted(DataBreachId.fromString(dataBreach.id!));
      await this.dataBreachWasCompletedEventListener.invoke(event);

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }

  private hasMandatoryDpoAnswer(dataBreach: DataBreachObject): void {
    if (!dataBreach.evaluation.trim() || !dataBreach.reportLanguage.trim()) {
      throw Error('No mandatory fields are filled in to generate the report');
    }
  }
}
