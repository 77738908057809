<template>
  <v-text-field
    v-model="inputValue"
    :type="type"
    :label="label"
    :rules="rules"
    :error="error"
    :disabled="disabled"
    :placeholder="placeholder"
    :autofocus="autofocus"
    :ref="reference"
    :validate-on-blur="validateOnBlur"
    :readonly="readonly"
    filled
    @input="$emit('update:value', inputValue)"
    @keyup.enter="$emit('keyUpEnter')"
    @click="$emit('clickInput')"
  />
</template>
<script>
export default {
  name: 'input-field',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'string',
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: null,
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
