import EmployeeWasUpdated from '@/domain/employee/EmployeeWasUpdated';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import EventBus from '@/application/eventBus/EventBus';
import Employee from '@/domain/employee/Employee';
import EmployeeId from '@/domain/employee/EmployeeId';
import EmployeeBuilder from '@/domain/employee/EmployeeBuilder';
import EmployeeExitDate from '@/domain/employee/EmployeeExitDate';
import EmployeeEntryDate from '@/domain/employee/EmployeeEntryDate';

export default class UpdateEmployee {
  private readonly employeeRepository: EmployeeRepository;

  private readonly eventBus: EventBus;

  constructor(employeeRepository: EmployeeRepository, eventBus: EventBus) {
    this.employeeRepository = employeeRepository;
    this.eventBus = eventBus;
  }

  public async invoke(
    clientId: string,
    employeeId: string,
    employeeForm: EmployeeObject,
  ): Promise<object> {
    try {
      const id: EmployeeId = EmployeeId.fromString(employeeId);
      const employeeBuilder = new EmployeeBuilder();
      employeeBuilder.withId(id.toString())
        .withClientId(clientId)
        .withName(employeeForm.name)
        .withSurname(employeeForm.surname)
        .withEmail(employeeForm.email)
        .withPosition(employeeForm.position)
        .withEntry(employeeForm.entry ? EmployeeEntryDate.fromStringDate(employeeForm.entry)
          .toString() : null)
        .withExit(employeeForm.exit ? EmployeeExitDate.fromStringDate(employeeForm.exit)
          .toString() : null)
        .withDocumentData(employeeForm.documentData)
        .withNif(employeeForm.nif);

      const employee: Employee = employeeBuilder.create();

      await this.employeeRepository.update(employee);
      this.eventBus.handle(EmployeeWasUpdated.fromEmployeeId(employee.getId()));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
