const addRecipientsModels = ({ commit }, recipients) => {
  commit('ADD_RECIPIENTS_MODELS', recipients);
};

const resetRecipientsModels = ({ commit }) => {
  commit('RESET_RECIPIENTS_MODELS');
};

export default {
  addRecipientsModels,
  resetRecipientsModels,
};
