import StringValueObject from '../shared/strings/StringValueObject';

export default class HeadquarterLocality extends StringValueObject {
  _locality;

  constructor(locality) {
    super(locality);
    this._locality = locality;
  }
}
