export default class FetchProcessingActivityFormStatus {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke() {
    return this._processingActivityCache.getFormStatus();
  }
}
