import Url from '@/domain/shared/url/Url';
import TrainingToken from '@/domain/training/TrainingToken';

export default class TrainingUrl {
  private readonly _url: Url;

  private readonly _token: TrainingToken;

  constructor(url: Url, token: TrainingToken) {
    this._url = url;
    this._token = token;
  }

  public toString(): string {
    return this._url.toString();
  }

  get url(): Url {
    return this._url;
  }

  get token(): TrainingToken {
    return this._token;
  }
}
