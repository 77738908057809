<template>
  <div class="h-100 w-100">
    <alert
      v-if="errorFeedback.length > 0"
      :messages.sync="errorFeedback"
      type="error"
    />
    <p class="mb-5">
      {{ $t('changePassword.put_new_password') }} <strong>{{ userEmail }}</strong>
    </p>
    <input-field-password
      :value.sync="password"
      :label="$t('changePassword.password') + ' *'"
      :persistent-hint="true"
      :error.sync="errorInput"
      @keyUpEnter="$emit('recoverPassword', password)"
    />
    <div>
      <small class="password__rules">
        {{ $t('form.password.rules') }}
      </small>
    </div>
    <div class="text-right">
      <v-btn
        color="primary"
        rounded
        @click="$emit('recoverPassword', password)"
      >
        {{ $t('changePassword.save') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import InputFieldPassword from '../../base/form/InputFieldPassword.vue';
import Alert from '../../base/feedback/Alert.vue';

export default {
  name: 'ask-password',
  components: {
    InputFieldPassword,
    Alert,
  },
  props: {
    userEmail: {
      type: String,
      default: '',
    },
    errorFeedback: {
      type: Array,
      default: () => [],
    },
    errorInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
    };
  },
};
</script>
