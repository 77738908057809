import EquipmentSecurityMeasures
  from '@/domain/equipment/equipmentSecurityMeasures/EquipmentSecurityMeasures';
import EquipmentSecurityMeasuresRepresentation
  from '@/application/equipment/fetchSecurityMeasures/EquipmentSecurityMeasuresRepresentation';

export default (
  translator,
) => () => {
  const securityMeasures = EquipmentSecurityMeasures.all();
  const securityMeasuresRepresentation = securityMeasures.map((security) => security.representedAs(
    new EquipmentSecurityMeasuresRepresentation(translator),
  ));
  return securityMeasuresRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
