import EquipmentTypeValidationException from './EquipmentTypeValidationException';

export default class EquipmentType {
  _key;

  static VALID_TYPES = [
    'applications',
    'hardware',
    'furniture',
    'other',
    'printer',
    'videoSurveillance',
    'paperDocumentation',
    'communications',
    'shredder',
    'server',
    'cloudServices',
    'dataInformation',
    'externalStorage',
    'hosting',
  ];

  constructor(key) {
    this.isValid(key);
    this._key = key;
  }

  isValid(key) {
    if (!key) {
      throw new EquipmentTypeValidationException('Equipment type should not be empty');
    }

    if (!EquipmentType.VALID_TYPES.includes(key)) {
      throw new EquipmentTypeValidationException('Equipment type does not exist');
    }
  }

  representedAs(typeRepresentation) {
    typeRepresentation.setKey(this._key);
    return typeRepresentation;
  }

  static all() {
    return this.VALID_TYPES.map((type) => new this(type));
  }

  toString() {
    return this._key;
  }

  static fromKey(key) {
    return new this(key);
  }
}
