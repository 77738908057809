<template>
  <general-table
    :headers="headers"
    :list="dataBreaches"
    showExpand
    singleExpand
    :currentExpanded.sync="expanded"
    sort-by="code"
    item-key="code"
    class="table-expanded border-custom"
    @update:currentExpanded="goToShowDataBreach"
  >
    <template
      slot="expandedData"
      slot-scope="props"
    >
      <td
        :colspan="headers.length"
        class="table-expanded table-expanded__content"
      >
        <table>
          <tr>
            <td
              v-bind:style="{ width: headers[0].width }"
            >
              {{ $t("data_breach.created_by") }}
            </td>
            <td
              :colspan="headers.length -1"
            >
              {{ getNameCreatedBy(props.item.createdBy) }}
            </td>
          </tr>
          <tr>
            <td
              v-bind:style="{ width: headers[0].width }"
            >
              {{ $t('data_breach.breach_notify_agency') }}
            </td>
            <td
              :colspan="headers.length -1"
            >
              {{ props.item.agencyNotificationTranslation }}
            </td>
          </tr>
          <tr
            v-if="props.item.documents.length"
          >
            <td
              v-bind:style="{ width: headers[0].width }"
            >
              {{ $t('data_breach.breach_extra_documents') }}
            </td>
            <td
              :colspan="headers.length -1"
              class="text-right table-expanded__buttons"
            >
              <slot
                name="downloadAttachedDocumentButton"
                :documents="props.item.documents"
              />
            </td>
          </tr>
          <tr>
            <td
              v-bind:style="{ width: headers[0].width }"
            >
              {{ $t('data_breach.breach_report') }}
            </td>
            <td
              :colspan="headers.length -1"
              class="text-right table-expanded__buttons"
            >
              <slot
                name="downloadReportButton"
                :id="props.item.id"
                :code="props.item.code"
              />
            </td>
          </tr>
        </table>
      </td>
    </template>
  </general-table>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';

export default {
  name: 'list-data-breach',
  inject: {
    user: 'user',
    fetchDataBreaches: 'fetchDataBreaches',
  },
  components: {
    GeneralTable,
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('data_breach.title_barra'),
          align: 'start',
          sortable: false,
          value: 'name',
          width: '30%',
        },
        {
          text: this.$t('data_breach.number'),
          align: 'start',
          sortable: true,
          value: 'code',
          width: '30%',
        },
        {
          text: this.$t('data_breach.breach_date'),
          align: 'start',
          sortable: true,
          value: 'occurredOn',
          width: '30%',
        },
        {
          text: '',
          align: 'end',
          value: 'data-table-expand',
          width: '10%',
        },
      ];
    },
  },
  data() {
    return {
      dataBreaches: [],
      expanded: [],
    };
  },
  methods: {
    async loadDataBreaches() {
      this.$showLoading();
      const response = await this.fetchDataBreaches.invoke(this.user.clientId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('data_breach.error_to_load');
        return;
      }
      this.dataBreaches = response.dataBreaches;
    },
    showDataBreachIfRouteRequestIt() {
      const dataBreachToShow = this.dataBreaches.find(
        (dataBreach) => dataBreach.id === this.$route.params.dataBreachId,
      );
      this.expanded = dataBreachToShow ? [dataBreachToShow] : [];
    },
    goToShowDataBreach() {
      if (!this.expanded.length && !this.$route.params.dataBreachId) {
        return;
      }

      if (!this.expanded.length) {
        this.$router.push({ path: '/data-breach/historic' });
        return;
      }

      if (this.expanded[0].id !== this.$route.params.dataBreachId) {
        this.$router.push({ name: 'data-breach-historic', params: { dataBreachId: this.expanded[0].id } });
      }
    },
    getNameCreatedBy(createdBy) {
      return createdBy !== '' ? createdBy : this.$t('data_breach.created_by_deleted_user');
    },
  },
  async beforeMount() {
    await this.loadDataBreaches();
    this.showDataBreachIfRouteRequestIt();

    this.$eventHub.$on('dataBreachAndReportWereCreated', this.loadDataBreaches);
    this.$eventHub.$on('dataBreachWasUpdatedAndReportWasCreated', this.loadDataBreaches);
  },
  beforeDestroy() {
    this.$eventHub.$off('dataBreachAndReportWereCreated', this.loadDataBreaches);
    this.$eventHub.$off('dataBreachWasUpdatedAndReportWasCreated', this.loadDataBreaches);
  },
};
</script>
