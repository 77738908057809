import CompletionDateException from '@/domain/task/exceptions/CompletionDateException';

export default class CompletionDate {
  completionDate;

  constructor(completionDate) {
    CompletionDate.isValid(completionDate);
    this.completionDate = completionDate;
  }

  static isValid(completionDate) {
    if (completionDate) {
      throw new CompletionDateException('Task should not has completation date');
    }
  }
}
