const addRecipientsRecipientRepresentations = ({ commit }, recipients) => {
  commit('ADD_RECIPIENTS_LIST_REPRESENTATIONS', recipients);
};

const resetRecipientsListRepresentations = ({ commit }) => {
  commit('RESET_RECIPIENTS_LIST_REPRESENTATION');
};

export default {
  addRecipientsRecipientRepresentations,
  resetRecipientsListRepresentations,
};
