import NumberException from '@/domain/shared/number/NumberException';

export default class NumberValueObject {
  private readonly number: number;

  constructor(number: number) {
    this.isValidOrFail(number);
    this.number = number;
  }

  isValidOrFail(number: number): void {
    if (!Number.isInteger(number)) {
      throw new NumberException(`${this.constructor.name} should be a number`);
    }

    if (number < 0) {
      throw new NumberException(`${this.constructor.name} should not be less than 0`);
    }
  }

  public toInt(): number {
    return this.number;
  }

  public static fromInt(number: number): NumberValueObject {
    return new this(number);
  }
}
