<template>
  <v-alert
    text
    prominent
    color="orange darken-3"
    border="left"
    class="recipient-contract-check"
  >
    <v-row>
      <v-col cols="12">
        {{ $t('recipient.form.document_data.review') }}
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="value">
          <v-radio
            v-for="status in dpaStatus"
            color="orange darken-3"
            :key="status.key"
            :label="status.name"
            :value="status.key"
          />
        </v-radio-group>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'check-contract-selector',
  inject: {
    fetchRecipientDpaStatus: 'fetchRecipientDpaStatus',
  },
  props: {
    dpaStatusSelected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.dpaStatusSelected,
      dpaStatus: [],
    };
  },
  methods: {
    getDpaStatus() {
      this.dpaStatus = this.fetchRecipientDpaStatus();
    },
  },
  beforeMount() {
    this.getDpaStatus();
  },
  watch: {
    dpaStatusSelected() {
      this.value = this.dpaStatusSelected;
    },
    value() {
      this.$emit('update:dpaStatusSelected', this.value);
    },
  },
};
</script>
