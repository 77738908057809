import TrainingRepresentation from '@/domain/training/TrainingRepresentation';

export default class FetchTrainingInfoRepresentation extends TrainingRepresentation {
  get availableLanguages(): string[] | null {
    return this._availableLanguages
      ? this._availableLanguages.map((language) => language.toString())
      : null;
  }

  get status(): string | null {
    return this._status ? this._status.toString() : null;
  }

  get userLanguage(): string | null {
    return this._userLanguage ? this._userLanguage.toString() : null;
  }
}
