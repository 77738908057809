<template>
  <list-departments />
</template>

<script>
import ListDepartments
  from '@/components/modules/settings/departments/ListDepartments.vue';
import {
  fetchListDepartment,
  resetListDepartment,
} from '@/infrastructure/application/department/listDepartment';

export default {
  name: 'departments-section',
  components: { ListDepartments },
  provide: {
    fetchListDepartment,
    resetListDepartment,
  },
};
</script>
