<template>
  <div class="h-100 w-100">
    <v-tabs
      v-model="currentTab"
      class="submenu-section"
      hide-slider
    >
      <v-tab
        v-for="item in sections"
        :key="item.key"
        :href="'#' + item.key"
      >
        {{ item.name }}
      </v-tab>
      <v-tabs-items v-model="currentTab">
        <slot />
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'submenu-section',
  props: {
    tabActive: {
      type: String,
      default: '',
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTab: this.tabActive,
    };
  },
  watch: {
    currentTab() {
      this.$emit('update:tabActive', this.currentTab);
    },
    tabActive() {
      this.currentTab = this.tabActive;
    },
  },
};
</script>
