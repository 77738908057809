export default class SaveProcessingActivityForm {
  _processingActivityCache;

  constructor(ProcessingActivityFormCache) {
    this._processingActivityCache = ProcessingActivityFormCache;
  }

  invoke(processingActivity) {
    this._processingActivityCache.setProcessingActivity(processingActivity);
  }
}
