<template>
  <v-col id="tom-notes" cols="12">
    <h4>
      <span>
        {{ $t('toms.information_modal.note') }}
      </span>
    </h4>
    <v-tabs
      v-if="availableLanguages.length"
      v-model="currentTab"
      class="language-tabs"
      hide-slider
    >
      <v-tab
        v-for="note in notes"
        :key="note.language"
        v-track="`Note TOME switch language -- ${note.language}`"
      >
        <v-icon
          x-small
          class="mr-1"
          :color="getColorByNoteContent(note.content)"
        >
          circle
        </v-icon>
        {{ $t(`toms.information_modal.note_${note.language}`) }}
      </v-tab>
      <v-tabs-items v-model="currentTab">
        <v-tab-item
          v-for="note in notes"
          :key="note.language"
          :transition="false"
        >
          <textarea-field
            :value.sync="note.content"
            :label="$t('toms.information_modal.note_placeholder_text', note.language)"
            :counter="3000"
            :rules="[validations.maxLength]"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-col>
</template>

<script>
import TextareaField from '@/components/base/form/TextareaField.vue';

export default {
  name: 'tom-notes',
  components: { TextareaField },
  inject: {
    user: 'user',
    fetchLanguagesOfClient: 'fetchLanguagesOfClient',
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      availableLanguages: [],
      currentTab: this.$i18n.locale,
      validations: {
        maxLength: (v) => v.trim().length <= 3000 || this.$t('form.validations.max_length', { length: 3000 }),
      },
    };
  },
  methods: {
    async getNotes() {
      this.availableLanguages = await this.loadAvailableLanguages();

      this.availableLanguages.forEach((language) => {
        if (this.notes.find((note) => note.language === language.code)) {
          return;
        }
        this.notes.push({ content: '', language: language.code });
      });

      const note = this.getNoteWithPlatformLanguage();
      this.notes.unshift(note);
      this.currentTab = this.notes[0].language;
    },
    async loadAvailableLanguages() {
      const response = await this.fetchLanguagesOfClient.invoke(this.user.clientId);
      if (!response.success) {
        this.$showError('toms.error.load_tom_form');
        return [];
      }
      return response.languages;
    },
    getNoteWithPlatformLanguage() {
      const index = this.notes.findIndex((note) => note.language === this.$i18n.locale);
      return this.notes.splice(index, 1)[0];
    },
    getColorByNoteContent(noteContent) {
      if (!noteContent.trim()) {
        return 'grey lighten-1';
      }
      return noteContent.trim().length <= 3000 ? 'success' : 'error';
    },
  },
  beforeMount() {
    this.getNotes();
  },
};
</script>
