<template>
  <v-row>
    <v-col cols="12">
      <title-section
        :title="$t('training.title')"
      />
    </v-col>
    <v-col cols="12">
      <user-trainings
        v-if="!hasUsersTrainingAccess"
      />
      <submenu-section
        v-else
        :tabActive.sync="currentTab"
        :sections="menuSections"
        v-on:update:tabActive="trackActions"
      >
        <v-tab-item
          v-for="section in menuSections"
          :key="section.key"
          :value="section.key"
          :transition="false"
        >
          <component
            :is="section.key"
          />
        </v-tab-item>
      </submenu-section>
    </v-col>
  </v-row>
</template>
<script>
import UserTrainingsSection from '@/components/modules/training/userTrainings/UserTrainingsSection.vue';
import EmployeesTrainingsSection from '@/components/modules/training/employeesTrainings/EmployeesTrainingsSection.vue';
import SubmenuSection from '@/components/base/menus/SubmenuSection.vue';
import TitleSection from '../base/section/TitleSection.vue';

export default {
  name: 'training-container',
  inject: {
    user: 'user',
  },
  components: {
    userTrainings: UserTrainingsSection,
    employeesTrainings: EmployeesTrainingsSection,
    TitleSection,
    SubmenuSection,
  },
  computed: {
    menuSections() {
      return [
        {
          name: this.$t('training.menu.user_trainings'),
          key: 'employeesTrainings',
        },
        {
          name: this.$t('training.menu.my_trainings'),
          key: 'userTrainings',
        },
      ];
    },
    hasUsersTrainingAccess() {
      return this.user.isSupervisor || this.user.isAdmin || this.user.isTrainingsManager;
    },
  },
  data() {
    return {
      currentTab: 'employeesTrainings',
    };
  },
  methods: {
    trackActions(tabName) {
      this.$track({
        action: `See ${tabName}`,
      });
    },
  },
};
</script>
