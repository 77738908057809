import HeadquartersRepresentation
  from '@/application/equipment/fetchLocations/HeadquartersRepresentation';
import EquipmentLocation from '@/domain/equipment/equipmentLocation/EquipmentLocation';
import LocationRepresentation from '@/application/equipment/fetchLocations/LocationRepresentation';
import ClientId from '@/domain/client/ClientId';

export default (
  headquarterRepository,
  translator,
) => async (clientId) => {
  const headquarters = await headquarterRepository.ofClient(ClientId.fromInt(clientId));
  const headquartersCollection = {};
  headquarters.forEach((headquarter) => {
    const headquarterRepresentation = headquarter.representedAs(new HeadquartersRepresentation());
    headquartersCollection[headquarterRepresentation.id] = headquarterRepresentation.name;
  });

  const locations = EquipmentLocation.all(Object.keys(headquartersCollection));

  const locationsRepresentation = locations.map(
    (location) => location.representedAs(
      new LocationRepresentation(translator, headquartersCollection),
    ),
  );

  return locationsRepresentation.sort((a, b) => ((a.name > b.name) ? 1 : -1));
};
