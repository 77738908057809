export default class AbstractEquipmentRepresentation {
  _id;

  _name;

  _type;

  _location;

  _securityMeasures;

  _clientId;

  setId(id) {
    this._id = id;
  }

  setName(name) {
    this._name = name;
  }

  setType(type) {
    this._type = type;
  }

  setLocation(location) {
    this._location = location;
  }

  setSecurityMeasures(securityMeasures) {
    this._securityMeasures = securityMeasures;
  }

  setClientId(clientId) {
    this._clientId = clientId;
  }
}
