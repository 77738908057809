const addTrainingInfo = ({ commit }, trainingInfo) => {
  commit('ADD_AVAILABLE_LANGUAGES', trainingInfo.availableLanguages.map((language) => language.toString()));
  commit('ADD_LANGUAGE', trainingInfo.userLanguage ? trainingInfo.userLanguage.toString() : null);
  commit('ADD_STATUS', trainingInfo.status ? trainingInfo.status.toString() : null);
};

const changeStatus = ({ commit }, status) => {
  commit('ADD_STATUS', status);
};

const resetTrainingInfo = ({ commit }) => {
  commit('RESET_AVAILABLE_LANGUAGES');
  commit('RESET_LANGUAGE');
  commit('RESET_STATUS');
};

const setUserLanguage = ({ commit }, language) => {
  commit('ADD_LANGUAGE', language);
};

const addTrainingEmployees = ({ commit }, trainingEmployees) => {
  commit('ADD_TRAINING_EMPLOYEES', trainingEmployees);
};

const resetTrainingEmployees = ({ commit }) => {
  commit('RESET_TRAINING_EMPLOYEES');
};

export default {
  addTrainingInfo,
  resetTrainingInfo,
  setUserLanguage,
  changeStatus,
  addTrainingEmployees,
  resetTrainingEmployees,
};
