<template>
  <div class="h-100 w-100" v-if="pendingDataBreaches.length">
    <v-card
      class="elevation-lighten mb-5"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              class="mb-2"
            >
              {{ $t("data_breach.report_in_progress_title") }}
            </h2>
            <p
              class="mb-0"
            >
              {{ $t("data_breach.report_in_progress_description") }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-right"
          >
            <div
              v-for="pendingDataBreach in pendingDataBreaches"
              :key="pendingDataBreach.id"
              class="data-breach__pending mb-2"
            >
              <div>
                <p
                  class="mr-4 mb-0"
                >
                  {{ pendingDataBreach.createdDate }}
                </p>
                <p
                  class="mb-4 mr-4"
                >
                  {{ $t("data_breach.created_by") }}
                  {{ getNameCreatedBy(pendingDataBreach.createdBy) }}
                </p>
              </div>
              <edit-data-breach
                :dataBreachId="pendingDataBreach.id"
              />
              <add-collaborator
                :dataBreach="pendingDataBreach"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EditDataBreach from '@/components/modules/dataBreach/notify/EditDataBreach.vue';
import AddCollaborator from '@/components/modules/dataBreach/notify/AddCollaborator.vue';

export default {
  name: 'pending-data-breaches',
  components: {
    AddCollaborator,
    EditDataBreach,
  },
  inject: {
    user: 'user',
    fetchDataBreachesPending: 'fetchDataBreachesPending',
  },
  data() {
    return {
      showForm: false,
      pendingDataBreaches: [],
      dataBreach: {},
      dataBreachSelected: null,
    };
  },
  methods: {
    async loadListPendingDataBreaches() {
      this.$showLoading();
      const response = await this.fetchDataBreachesPending.invoke(this.user.clientId);
      this.$hideLoading();

      if (!response.success) {
        this.$showError('data_breach.errorPage.error_save_form');
        return;
      }

      this.pendingDataBreaches = response.dataBreachesPending;
    },
    getNameCreatedBy(createdBy) {
      return createdBy !== '' ? createdBy : this.$t('data_breach.created_by_deleted_user');
    },
  },
  mounted() {
    this.loadListPendingDataBreaches();
  },
  beforeMount() {
    this.$eventHub.$on('dataBreachWasCreated', this.loadListPendingDataBreaches);
    this.$eventHub.$on('dataBreachWasDeleted', this.loadListPendingDataBreaches);
    this.$eventHub.$on('dataBreachWasUpdatedAndReportWasCreated', this.loadListPendingDataBreaches);
  },
  beforeDestroy() {
    this.$eventHub.$off('dataBreachWasCreated', this.loadListPendingDataBreaches);
    this.$eventHub.$off('dataBreachWasDeleted', this.loadListPendingDataBreaches);
    this.$eventHub.$off('dataBreachWasUpdatedAndReportWasCreated', this.loadListPendingDataBreaches);
  },
};
</script>
