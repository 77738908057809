export default class AbstractProcessingActivityModelRepresentation {
  _id;

  _name;

  _countryId;

  _languageCode;

  _department;

  setId(id) {
    this._id = id;
  }

  setName(name) {
    this._name = name;
  }

  setCountryId(countryId) {
    this._countryId = countryId;
  }

  setLanguage(languageCode) {
    this._languageCode = languageCode;
  }

  setDepartment(department) {
    this._department = department;
  }
}
