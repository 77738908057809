const ADD_DEPARTMENT = (state, department) => {
  state.department = department;
};

const RESET_DEPARTMENT = (state) => {
  state.department = {};
};

const ADD_LIST_DEPARTMENT = (state, listDepartment) => {
  state.listDepartment = listDepartment;
};

const RESET_LIST_DEPARTMENT = (state) => {
  state.listDepartment = {};
};

export default {
  ADD_DEPARTMENT,
  RESET_DEPARTMENT,
  ADD_LIST_DEPARTMENT,
  RESET_LIST_DEPARTMENT,
};
