import {
  deleteMethodManagement,
  getManagement,
  postManagement,
  putManagement,
} from '@/shared/restActions';
import ClientId from '@/domain/client/ClientId';
import ClientUserRepository from '@/domain/clientUser/ClientUserRepository';
import ClientUserId from '@/domain/clientUser/ClientUserId';
import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import ClientUserRawRegisterRepresentation
  from '@/infrastructure/clientUser/ClientUserRawRegisterRepresentation';
import StringValueObject from '@/domain/shared/strings/StringValueObject';
import ClientUsersCache from '@/infrastructure/clientUser/ClientUsersCache';
import ClientUserSurname from '@/domain/clientUser/ClientUserSurname';
import ClientUserType from '@/domain/clientUser/ClientUserType';
import ClientUserName from '@/domain/clientUser/ClientUserName';
import ClientUserEmail from '@/domain/clientUser/ClientUserEmail';
import Language from '@/domain/language/Language';
import ClientUserLastLogin from '@/domain/clientUser/ClientUserLastLogin';
import ClientUserListCache from '@/infrastructure/clientUser/ClientUserListCache';
import ClientUser from '@/domain/clientUser/ClientUser';
import ClientUserRawRepresentation from '@/infrastructure/clientUser/ClientUserRawRepresentation';
import ClientUserRaw from '@/infrastructure/clientUser/ClientUserRaw';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ClientUserRepositoryApi implements ClientUserRepository {
  public async create(
    clientId: ClientId,
    clientUser: ClientUser,
    emailRepeat: EmailValueObject,
  ): Promise<number> {
    const clientUserRawRepresentation: ClientUserRawRepresentation = clientUser
      .representedAs(new ClientUserRawRepresentation()) as ClientUserRawRepresentation;

    const clientUserObject = clientUserRawRepresentation.toObject();
    clientUserObject.email_repeat = emailRepeat.toString();
    clientUserObject.clientId = clientId.toInt();

    const response = await postManagement(`/api/v3/clients/${clientId.toInt()}/users`, {
      ...clientUserObject,
    });

    const userId: number = response.data.id;
    clientUser.addId(ClientUserId.fromInt(userId));

    ClientUsersCache.save(clientUser);

    return userId;
  }

  public async delete(clientUserId: ClientUserId): Promise<void> {
    try {
      await deleteMethodManagement(`/api/v2/users/client/${clientUserId.toInt()}`);
      ClientUsersCache.delete(clientUserId);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting client user');
    }
  }

  public async resend(clientUserId: ClientUserId): Promise<void> {
    try {
      await postManagement(`/api/v2/users/client/${clientUserId.toInt()}/activation-email`);
    } catch (e) {
      console.error(e);
      throw new Error('Error resending activation email');
    }
  }

  public reset(): void {
    ClientUsersCache.reset();
    ClientUserListCache.reset();
  }

  public async retrieve(clientUserId: ClientUserId): Promise<ClientUser> {
    let clientUser: ClientUser = ClientUsersCache.retrieve(clientUserId);

    if (clientUser) {
      return clientUser;
    }

    const clientUserRaw: ClientUserRaw = await ClientUserRepositoryApi
      .getClientUserFromApi(clientUserId);
    clientUser = ClientUserRepositoryApi.buildClientUser(clientUserRaw);

    ClientUsersCache.save(clientUser);

    return clientUser;
  }

  private static async getClientUserFromApi(clientUserId: ClientUserId): Promise<ClientUserRaw> {
    const response = await getManagement(`/api/v2/users/client/${clientUserId.toInt()}`);
    return response.data;
  }

  private static buildClientUser(clientUserRaw: ClientUserRaw): ClientUser {
    const clientUser = new ClientUser(
      ClientUserEmail.fromString(clientUserRaw.email),
      ClientUserName.fromString(clientUserRaw.name),
      ClientUserSurname.fromString(clientUserRaw.surname),
      ClientUserType.fromString(clientUserRaw.type),
      ClientUserRepositoryApi.buildClientUserDepartments(clientUserRaw.departments),
      Language.fromString(clientUserRaw.language),
    );

    clientUser.addId(ClientUserId.fromInt(clientUserRaw.id));
    clientUser.addValidated(clientUserRaw.validated);

    if (clientUserRaw.lastLogin) {
      clientUser.addLastLogin(ClientUserLastLogin.fromW3CDate(clientUserRaw.lastLogin));
    }

    return clientUser;
  }

  private static buildClientUserDepartments(departments: {id: string, name: string}[])
    : DepartmentId[] {
    return departments.map((dept: {id: string, name: string}) => DepartmentId.fromString(dept.id));
  }

  public async update(clientUser: ClientUser): Promise<void> {
    const clientUserRaw: ClientUserRawRepresentation = clientUser
      .representedAs(new ClientUserRawRepresentation()) as ClientUserRawRepresentation;

    await putManagement(
      `/api/v3/users/client/${clientUser.getId().toInt()}/type`,
      clientUserRaw.toObject(),
    );

    ClientUsersCache.save(clientUser);
  }

  public async register(clientUser: ClientUser): Promise<void> {
    try {
      const clientUserRaw: ClientUserRawRegisterRepresentation = clientUser.representedAs(
        new ClientUserRawRegisterRepresentation(),
      ) as ClientUserRawRegisterRepresentation;

      await postManagement('/api/v2/users/register', clientUserRaw.toObject());
    } catch (e) {
      console.error(e);
      throw new Error(e.response?.data?.data || null);
    }
  }

  public async validate(token: StringValueObject): Promise<void> {
    try {
      await putManagement('/api/v2/users/validate', { token: token.toString() });
    } catch (e) {
      console.error(e);
      throw new Error('Error validating user');
    }
  }
}
