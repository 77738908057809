import { getManagement } from '@/shared/restActions';
import ClientId from '@/domain/client/ClientId';
import Course from '@/domain/training/course/Course';
import CourseName from '@/domain/training/course/CourseName';
import InscriptionStatus from '@/domain/training/inscription/InscriptionStatus';
import TrainingEmployee from '@/domain/training/trainingEmployee/TrainingEmployee';
import EmployeeEmail from '@/domain/training/trainingEmployee/EmployeeEmail';
import EmployeeName from '@/domain/training/trainingEmployee/EmployeeName';
import Inscription from '@/domain/training/inscription/Inscription';
import TrainingEmployeesRaw from '@/infrastructure/training/employee/TrainingEmployeesRaw';
import TrainingEmployeeRepository from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';
import TrainingEmployeeCache from '@/infrastructure/training/employee/TrainingEmployeeCache';
import InscriptionId from '@/domain/training/inscription/InscriptionId';

export default class TrainingEmployeeRepositoryApi implements TrainingEmployeeRepository {
  public async ofClient(clientId: ClientId): Promise<TrainingEmployee[]> {
    try {
      let trainingEmployees: TrainingEmployee[] = TrainingEmployeeCache.all();

      if (trainingEmployees.length) {
        return trainingEmployees;
      }

      const employeesInscriptionsRaw: TrainingEmployeesRaw[] = await TrainingEmployeeRepositoryApi
        .getTrainingEmployeesFromApi(clientId);

      trainingEmployees = this.buildEmployeesTrainings(employeesInscriptionsRaw);
      TrainingEmployeeCache.save(trainingEmployees);

      return trainingEmployees;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching inscriptions of client');
    }
  }

  private static async getTrainingEmployeesFromApi(clientId: ClientId):
    Promise<TrainingEmployeesRaw[]> {
    const responseApi: {[key: string]: TrainingEmployeesRaw[]} = await getManagement(
      `/api/v2/clients/${clientId.toInt()}/employee-inscriptions`,
    );

    return responseApi.data;
  }

  private buildEmployeesTrainings(
    employeesInscriptionsRaw: TrainingEmployeesRaw[],
  ): TrainingEmployee[] {
    const trainingEmployees: TrainingEmployee[] = [];
    employeesInscriptionsRaw.forEach((employeeInscriptionsRaw: TrainingEmployeesRaw) => {
      const inscriptions: Inscription[] = employeeInscriptionsRaw
        .inscriptions.map((inscription) => new Inscription(
          InscriptionStatus.fromString(inscription.status),
          new Course(CourseName.fromString(inscription.course.name)),
          InscriptionId.fromString(inscription.id),
        ));

      const trainingEmployee = new TrainingEmployee(
        EmployeeName.fromString(employeeInscriptionsRaw.name),
      );

      if (employeeInscriptionsRaw.email) {
        trainingEmployee.addEmail(EmployeeEmail.fromString(employeeInscriptionsRaw.email));
      }

      if (inscriptions.length) {
        trainingEmployee.addInscriptions(inscriptions);
      }

      trainingEmployees.push(trainingEmployee);
    });

    return trainingEmployees;
  }

  public reset() {
    TrainingEmployeeCache.reset();
  }
}
