import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MAX_LENGTH = 80;
const MIN_LENGTH = 3;

export default class Title extends StringValueObject {
  title;

  constructor(title) {
    super(title, MIN_LENGTH, MAX_LENGTH);
    this.title = title;
  }
}
