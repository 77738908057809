<template>
  <v-btn
    outlined
    rounded
    color="primary"
    @click="downloadCertificate()"
  >
    <v-icon
      class="material-icons-round"
    >
      download
    </v-icon>
    {{ $t('training.my_trainings.certificate') }}
  </v-btn>
</template>

<script>

export default {
  name: 'download-certificate',
  inject: {
    downloadDocument: 'downloadDocument',
  },
  props: {
    certificate: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async downloadCertificate() {
      const response = await this.downloadDocument.invoke('training-certificate', this.certificate.id);
      if (!response.success) {
        this.$showError('errorPage.error_download');
      }
      this.$track({
        action: 'download user certificate',
        result: response.success,
        user_certificate: this.certificate.name,
      });
    },
  },
};
</script>
