import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ClientUserRawRepresentation extends ClientUserRepresentation {
  public toObject(): any {
    return {
      email_init: this._email.toString(),
      email_repeat: this._email.toString(),
      id: (this._id) ? this._id.toInt() : 0,
      locale: this._language.toString(),
      name: this._name.toString(),
      surname: this._surname.toString(),
      type: this._type.toString(),
      lasLogin: (this._lastLogin) ? this._lastLogin.toString() : null,
      departments: (this._departments.length) ? this._departments
        .map((department: DepartmentId) => department.toString()) : [],
    };
  }
}
