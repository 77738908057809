import DateTimeValueObject from '@/domain/shared/dateTimeValueObject/DateTimeValueObject';

export default class RecipientLastModification extends DateTimeValueObject {
  _lastModification;

  constructor(lastModification) {
    super(lastModification, 'Recipient last modification date');
    this._lastModification = lastModification;
  }
}
