const addClientUsers = ({ commit }, clientUsers) => {
  commit('ADD_CLIENT_USERS', clientUsers);
};

const resetClientUsers = ({ commit }) => {
  commit('RESET_CLIENT_USERS');
};

const addClientUsersList = ({ commit }, clientUsersList) => {
  commit('ADD_CLIENT_USERS_LIST', clientUsersList);
};

const resetClientUsersList = ({ commit }) => {
  commit('RESET_CLIENT_USERS_LIST');
};

export default {
  addClientUsers,
  resetClientUsers,
  addClientUsersList,
  resetClientUsersList,
};
