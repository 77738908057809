<template>
  <div class="h-100 w-100">
    <p>{{ $t('forgotPassword.send_email') }}</p>
    <p class="text-center recover-password__email">
      <strong>{{ email }}</strong>
    </p>
    <p>{{ $t('forgotPassword.check_email') }}</p>
    <router-link :to="{ name: 'login' }">
      {{ $t('forgotPassword.return_login_page') }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'confirm-send-email',
  props: {
    email: {
      type: String,
      default: null,
    },
  },
};
</script>
