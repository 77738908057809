<template>
  <div>
    <v-row v-if="hasTasks">
      <v-col
        v-for="(groupTasks, key) in groupListTasks"
        :key="key"
        cols="12"
        class="task__list__content"
      >
        <div v-if="groupTasks.length" class="task__list">
          <h4>{{ getNameGroupTask(key) }}</h4>
          <div
            v-for="(task, index) in groupTasks"
            :key="index"
          >
            <task-alert-response
              :task="task"
              @taskResponded="loadTasks"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <div
      v-else
      class="task__list--no-tasks"
    >
      <v-icon>
        check_circle
      </v-icon>
      <p>
        {{ $t('tasks.no_tasks') }}
      </p>
    </div>
  </div>
</template>

<script>
import TaskAlertResponse from './TaskAlertResponse.vue';

export default {
  name: 'tasks-department',
  components: {
    TaskAlertResponse,
  },
  props: {
    groupListTasks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasPendingTasks: false,
      heightScroll: { height: '0px' },
    };
  },
  computed: {
    hasTasks() {
      let length = 0;

      if (this.groupListTasks !== undefined) {
        Object.keys(this.groupListTasks).forEach((key) => {
          length += this.groupListTasks[key].length;
        });
      }

      return length > 0;
    },
  },
  methods: {
    getNameGroupTask(key) {
      const nameSplit = key.split('_');
      if (nameSplit.length > 1) {
        return `${nameSplit[0]} ${this.$t(`tasks.months.${nameSplit[1]}`)} ${nameSplit[2]}`;
      }
      return `${this.$t(`tasks.${key}_section`)}`;
    },
    loadTasks() {
      this.$emit('taskResponded');
    },
  },
};
</script>
