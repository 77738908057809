import TomStatusValidationException from '@/domain/tom/TomStatusValidationException';

const IMPLEMENTED = 'implemented';

const AVAILABLE = 'available';

const PENDING_TO_IMPLEMENT = 'pendingToImplement';

const VALID_STATUS = [IMPLEMENTED, AVAILABLE, PENDING_TO_IMPLEMENT];

export default class TomStatus {
  private readonly status: string;

  protected constructor(status: string) {
    TomStatus.isValidOrFail(status);
    this.status = status;
  }

  private static isValidOrFail(status: string) {
    if (!VALID_STATUS.includes(status)) {
      throw new TomStatusValidationException(status);
    }
  }

  public toString(): string {
    return this.status;
  }

  public static fromString(status: string): TomStatus {
    return new this(status);
  }
}
