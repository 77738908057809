import fetchModelListBuilder from '@/application/processingActivity/fetchModelList/fetchModelList';
import Translator from '@/infrastructure/Translator';
import ProcessingActivityModelReadModelApi from '@/infrastructure/processingActivity/ProcessingActivityModelReadModelApi';
import ProcessingActivityFormCache
  from '@/infrastructure/processingActivity/ProcessingActivityFormCache';
import FetchProcessingActivityForm
  from '@/application/processingActivity/fetchProcessingActivityForm/FetchProcessingActivityForm';
import SaveProcessingActivityForm
  from '@/application/processingActivity/saveProcessingActivityForm/SaveProcessingActivityForm';
import SaveProcessingActivityFormStatus
  from '@/application/processingActivity/saveProcessingActivityFormStatus/SaveProcessingActivityFormStatus';
import FetchProcessingActivityFormStatus
  from '@/application/processingActivity/fetchProcessingActivityFormStatus/FetchProcessingActivityFormStatus';
import ResetProcessingActivityForm
  from '@/application/processingActivity/resetProcessingActivityForm/ResetProcessingActivityForm';
import ResetProcessingActivityFormStatus
  from '@/application/processingActivity/resetProcessingActivityFormStatus/ResetProcessingActivityFormStatus';
import FetchActiveRecipients
  from '@/application/processingActivity/fetchActiveRecipients/FetchActiveRecipients';
import RecipientReadModelApi from '@/infrastructure/recipient/RecipientReadModelApi';
import normalization from '@/services/shared/normalization';
import processingDates from '@/services/shared/processingDates';

export default {
  fetchModelList: fetchModelListBuilder(
    new ProcessingActivityModelReadModelApi(new Translator()),
  ),
};

export const saveProcessingActivityForm = new SaveProcessingActivityForm(
  ProcessingActivityFormCache,
);
export const fetchProcessingActivityForm = new FetchProcessingActivityForm(
  ProcessingActivityFormCache,
);
export const saveProcessingActivityFormStatus = new SaveProcessingActivityFormStatus(
  ProcessingActivityFormCache,
);
export const fetchProcessingActivityFormStatus = new FetchProcessingActivityFormStatus(
  ProcessingActivityFormCache,
);
export const resetProcessingActivityForm = new ResetProcessingActivityForm(
  ProcessingActivityFormCache,
);
export const resetProcessingActivityFormStatus = new ResetProcessingActivityFormStatus(
  ProcessingActivityFormCache,
);

export const fetchActiveRecipients = new FetchActiveRecipients(new RecipientReadModelApi(
  new Translator(), normalization, processingDates,
));
