import { initialState } from './state';

const RESET_DOCUMENT_VIEWER = (state) => {
  state.showViewer = initialState.showViewer;
};
const SHOW_DOCUMENT_VIEWER = (state) => {
  state.showViewer = true;
};

const SET_URL_DOCUMENT = (state, url) => {
  state.url = url;
};
const RESET_URL_DOCUMENT = (state) => {
  state.url = initialState.url;
};

const SET_NAME_DOCUMENT = (state, name) => {
  state.name = name;
};
const RESET_NAME_DOCUMENT = (state) => {
  state.name = initialState.name;
};

export default {
  RESET_DOCUMENT_VIEWER,
  SHOW_DOCUMENT_VIEWER,
  RESET_URL_DOCUMENT,
  SET_URL_DOCUMENT,
  RESET_NAME_DOCUMENT,
  SET_NAME_DOCUMENT,
};
