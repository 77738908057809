<template>
  <data-breach-container />
</template>
<script>

import DataBreachContainer from '@/components/containers/DataBreachContainer.vue';

export default {
  name: 'data-breach',
  components: {
    DataBreachContainer,
  },
};
</script>
