import store from '@/store';
import UserRaw from '@/infrastructure/user/UserRaw';
import UserInfoRaw from '@/infrastructure/user/UserInfoRaw';

const SUPERVISOR = 'supervisor';
const RESTRICTED_EMPLOYEE = 'restricted_employee';
const EMPLOYEE = 'employee';
const TRAININGS_MANAGER = 'trainings_manager';

export default class UserCache {
  public static save(user: UserRaw): void {
    store.dispatch('user/addUserInfo', user);
  }

  public static saveInfo(info: UserInfoRaw) {
    store.dispatch('user/setUserRole', info);
  }

  get id(): number {
    return store.state.user.id;
  }

  get language(): string {
    return store.state.user.language;
  }

  get email(): string {
    return store.state.user.email;
  }

  get name(): string {
    return store.state.user.name;
  }

  get clientId(): number {
    return store.state.user.companyId;
  }

  get countryId(): number {
    return store.state.user.countryId;
  }

  get departments(): string[] {
    return store.state.user.departments;
  }

  get isB2B(): boolean {
    return store.state.user.isB2b;
  }

  get type(): string {
    return store.state.user.type;
  }

  get isAdmin(): boolean {
    return store.state.user.isAdmin;
  }

  get isSupervisor(): boolean {
    return store.state.user.type === SUPERVISOR;
  }

  get isEmployee(): boolean {
    return store.state.user.type === EMPLOYEE;
  }

  get isTrainingsManager(): boolean {
    return store.state.user.type === TRAININGS_MANAGER;
  }

  get isRestrictedEmployee(): boolean {
    return store.state.user.type === RESTRICTED_EMPLOYEE;
  }
}

export const user = new UserCache();
