import DataBreachesPendingWriteModel
  from '@/domain/dataBreach/pending/DataBreachesPendingWriteModel';
import DataBreachesPendingRepresentation
  from '@/application/dataBreach/pending/fetchDataBreachesPending/DataBreachesPendingRepresentation';
import DataBreachPendingCache from '@/infrastructure/dataBreach/pending/DataBreachPendingCache';
import DataBreachId from '@/domain/dataBreach/DataBreachId';

export default class DataBreachesPendingWriteModelApi implements DataBreachesPendingWriteModel {
  async reset(): Promise<void> {
    await DataBreachPendingCache.reset();
  }

  async addDataBreach(dataBreachPendingRepresentation: DataBreachesPendingRepresentation)
    : Promise<void> {
    await DataBreachPendingCache.save(dataBreachPendingRepresentation);
  }

  async deleteDataBreach(dataBreachId: DataBreachId)
    : Promise<void> {
    await DataBreachPendingCache.deleteDataBreach(dataBreachId);
  }
}
