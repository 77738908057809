import ClientId from '@/domain/client/ClientId';
import FetchClientUsersQuery from '@/application/clientUser/fetchClientUsers/FetchClientUsersQuery';
import ClientUsersRepresentation
  from '@/application/clientUser/fetchClientUsers/ClientUsersRepresentation';
import ClientUserReadModel from '@/application/clientUser/fetchClientUsers/ClientUserReadModel';

export default class FetchClientUsersQueryHandler {
  private readonly clientUserRepository: ClientUserReadModel;

  constructor(clientUserRepository: ClientUserReadModel) {
    this.clientUserRepository = clientUserRepository;
  }

  public async invoke(command: FetchClientUsersQuery) {
    try {
      const clientUsers: ClientUsersRepresentation[] = await this.clientUserRepository
        .ofClient(ClientId.fromInt(command.clientId));
      return { success: true, clientUsers };
    } catch (e) {
      return { success: false };
    }
  }
}
