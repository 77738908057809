import FetchClientsQueryHandler from '@/application/client/fetchClients/FetchClientsQueryHandler';
import ClientRepositoryApi from '@/infrastructure/client/ClientRepositoryApi';
import normalization from '@/services/shared/normalization';
import ChangeClientSelectedCommandHandler
  from '@/application/client/changeClientSelected/ChangeClientSelectedCommandHandler';
import ChangeSelectedClientCache from '@/infrastructure/client/ChangeSelectedClientCache';
import eventBus from '@/infrastructure/eventBus/EventBus';

export default {};

export const fetchClients = new FetchClientsQueryHandler(new ClientRepositoryApi(), normalization);
export const changeSelectedClient = new ChangeClientSelectedCommandHandler(
  new ClientRepositoryApi(),
  new ChangeSelectedClientCache(),
  eventBus,
);
