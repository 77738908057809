<template>
  <div class="h-100 w-100">
    <h3
      class="font-weight-bold"
    >
      {{ $t("data_breach.form.step.cero.intro_title") }}
    </h3>
    <p v-html="$t('data_breach.form.step.cero.intro_description')"></p>
  </div>
</template>

<script>
export default {
  name: 'form-explanation',
};
</script>
