import feature from 'vue-feature-toggle';

function companyInEnvVar(companyId, envVar) {
  if (companyId == null) return false;

  const companyIds = envVar.split(',');
  return companyIds.includes(companyId.toString());
}

feature.visibility('processingActivitiesOldChip', (process.env.VUE_APP_FEATURE_PROCESSING_ACTIVITIES_OLD_CHIP_ACTIVATED === 'true'));

feature.visibility('b2b', (rule) => (companyInEnvVar(rule.data.adminCompanyId, process.env.VUE_APP_B2B_ADMINS)));

feature.visibility('developers', (rule) => (companyInEnvVar(rule.data.adminCompanyId, process.env.VUE_APP_DEVELOPERS_ADMINS)));

feature.visibility('userdeskUserRoles', (rule) => (companyInEnvVar(rule.data.adminCompanyId, process.env.VUE_APP_FT_USERDESK_USER_ROLES)));

feature.visibility('training', (rule) => (companyInEnvVar(rule.data.adminCompanyId, process.env.VUE_APP_FT_TRAINING_SECTION)));

feature.visibility('customLayoutsMigrated', (rule) => {
  if (rule.data.adminLayoutId) {
    const customLayoutsMigrated = process.env.VUE_APP_DOC_REVAMP_MIGRATED_CUSTOM_LAYOUTS.split(',');
    const layoutId = rule.data.adminLayoutId;
    return customLayoutsMigrated.includes(layoutId.toString());
  }
  return false;
});

feature.visibility('earlyAdopters', (rule) => (companyInEnvVar(rule.data.adminCompanyId, process.env.VUE_APP_FT_EARLY_ADOPTERS)));

export default feature;
