<template>
<div>
  <v-row>
    <v-col cols="12" md="4">
      <p class="p-label">
        {{ $t('settings.aplication_language') }}
      </p>
      <select-field
        :value.sync="languageToChange"
        :items.sync="languageList"
        itemText="name"
        itemValue="code"
      />
    </v-col>
    <v-col cols="12" md="4">
      <p class="p-label">
        {{ $t('settings.password') }}
      </p>
      <div v-if="!isAdmin" class="inline-elements setting-section">
        <strong>**********</strong>
        <v-btn
          color="primary"
          outlined
          rounded
          @click="changePasswordShow = true"
        >
          {{ $t('settings.change_password') }}
        </v-btn>
      </div>
      <div v-else>
        <p>
          {{ $t('settings.admin_password_text') }}
        </p>
        <a :href="urlPasswordManagement" target="__blank">
          {{ $t('settings.admin_pridatect_link') }}
          <v-icon color="primary">
            launch
          </v-icon>
        </a>
      </div>
    </v-col>
    <v-col cols="12">
      <a @click="$router.go(-1)">
        {{ $t('settings.go_back') }}
      </a>
    </v-col>
  </v-row>
  <change-password
    v-if="changePasswordShow"
    :show.sync="changePasswordShow"
    :errorInput.sync="thereIsInputError"
    @changePassword="changePassword"
  >
    <template v-slot:alert>
      <alert
        v-if="alertFeedback.length > 0"
        :messages.sync="alertFeedback"
        :type.sync="typeAlert"
      />
    </template>
  </change-password>
</div>
</template>

<script>
import userUseCases from '@/usecases/users';
import userUsecases from '@/application/user/usecases';

import SelectField from '@/components/base/form/SelectField.vue';
import ChangePassword from '@/components/modules/settings/profile/ChangePassword.vue';
import Alert from '@/components/base/feedback/Alert.vue';
import { mapGetters } from 'vuex';
import formValidationService from '@/services/validations/formValidation';

export default {
  name: 'profile-section',
  components: {
    SelectField,
    ChangePassword,
    Alert,
  },
  computed: {
    ...mapGetters('user', {
      isAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    urlPasswordManagement() {
      return `${process.env.VUE_APP_URL_MANAGEMENT}${this.$i18n.locale}/user/change-password`;
    },
  },
  data() {
    return {
      languageToChange: this.$i18n.locale,
      languageList: [],
      changePasswordShow: false,
      thereIsInputError: {
        password: false,
        currentPassword: false,
        newPassword: false,
        newPasswordConfirm: false,
      },
      alertFeedback: [],
      typeAlert: 'error',
    };
  },
  methods: {
    async getClientLanguagesList() {
      const response = await userUsecases.fetchClientLanguagesListByClientId(this.clientId);

      if (!response.success) {
        this.$showError('processingActivities.error.error_page_load');
      }
      this.languageList = response.data;
      this.translateAndSortLanguages();
    },
    translateAndSortLanguages() {
      this.languageList.forEach((obj, index) => {
        this.languageList[index].name = this.$t(`languages.by_code.lang_${obj.code}`);
      });

      this.languageList.sort((a, b) => ((a.name < b.name) ? -1 : 1));
    },
    async changePassword(user) {
      this.$showLoading();
      this.alertFeedback = this.checkChangePasswordForm(
        user.currentPassword,
        user.newPassword,
        user.newPasswordConfirm,
      );

      if (this.alertFeedback.length) {
        this.$hideLoading();
        return;
      }

      this.alertFeedback = [];
      const result = await userUseCases.changePasswordSettings(
        user.currentPassword,
        user.newPassword,
      );

      this.$hideLoading();
      if (!result.success) {
        this.typeAlert = 'error';
        if (result.code === 403) {
          this.alertFeedback = [this.$t('settings.error.invalid_credentials')];
          return;
        }

        this.$showError('settings.error.something_went_wrong');
        return;
      }

      this.changePasswordShow = false;
      this.$eventHub.$emit('showAlertOverlay', {
        type: 'success',
        text: [
          this.$t('settings.success.change_password'),
          this.$t('settings.success.user_logout'),
        ],
        buttons: [
          {
            text: this.$t('settings.success.accept'),
            action: this.doLogout,
          },
        ],
      });
    },
    checkChangePasswordForm(currentPassword, newPassword, newPasswordConfirm) {
      const textErrors = [];
      this.typeAlert = 'error';
      this.thereIsInputError.currentPassword = false;
      this.thereIsInputError.newPassword = false;
      this.thereIsInputError.newPasswordConfirm = false;

      const emptyCurrentPassword = formValidationService.isEmptyField(currentPassword);
      const emptyNewPassword = formValidationService.isEmptyField(newPassword);
      const emptyNewPasswordConfirm = formValidationService.isEmptyField(newPasswordConfirm);
      const validNewPassword = formValidationService.isValidPassword(newPassword);
      const differentPasswords = !formValidationService.isPasswordsSame(
        newPassword,
        newPasswordConfirm,
      );

      if (emptyCurrentPassword) {
        textErrors.push(this.$t('settings.error.required_current_password'));
        this.thereIsInputError.currentPassword = true;
      }

      if (emptyNewPassword) {
        textErrors.push(this.$t('settings.error.required_new_password'));
        this.thereIsInputError.newPassword = true;
      } else if (!validNewPassword) {
        textErrors.push(this.$t('settings.error.wrong_password'));
        this.thereIsInputError.newPassword = true;
      }

      if (emptyNewPasswordConfirm) {
        textErrors.push(this.$t('settings.error.required_new_password_confirm'));
        this.thereIsInputError.newPasswordConfirm = true;
      }

      if (!emptyNewPassword && !emptyNewPasswordConfirm && differentPasswords) {
        textErrors.push(this.$t('settings.error.passwords_differ'));
        this.thereIsInputError.newPassword = true;
        this.thereIsInputError.newPasswordConfirm = true;
      }

      return textErrors;
    },
    doLogout() {
      userUseCases.logout();
      this.$router.push({ name: 'login' });
    },
    resetPasswordErrors() {
      this.thereIsInputError.currentPassword = false;
      this.thereIsInputError.newPassword = false;
      this.thereIsInputError.newPasswordConfirm = false;
    },
  },
  async beforeMount() {
    if (!this.clientId) {
      this.$router.push({ name: 'home' });
      return;
    }
    this.$showLoading();
    await this.getClientLanguagesList();
    this.$hideLoading();
  },
  watch: {
    changePasswordShow() {
      this.alertFeedback = [];
      this.resetPasswordErrors();
    },
    async languageToChange() {
      if (this.languageToChange !== this.$i18n.locale) {
        this.$showLoading();

        const response = await userUseCases.changeLanguage(this.languageToChange);

        if (response.success) {
          this.$i18n.locale = this.languageToChange;
          this.$eventHub.$emit('userLanguageWasChanged');
          this.translateAndSortLanguages();
        } else {
          this.$showError('settings.error.save_language');
        }

        this.$hideLoading();
      }
    },
    'alertPage.show': function () {
      if (!this.alertPage.show && this.alertPage.button.includes('accept')) {
        this.doLogout();
      }
    },
  },
};
</script>
