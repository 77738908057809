import ClientUserRepresentation from '@/domain/clientUser/ClientUserRepresentation';
import DepartmentId from '@/domain/department/DepartmentId';

export default class ClientUserToEditRepresentation extends ClientUserRepresentation {
  get fullName(): string {
    return `${this._name.toString()} ${this._surname.toString()}`;
  }

  get type(): string {
    return this._type.toString();
  }

  get departments(): string[] {
    return this._departments.map((department: DepartmentId) => department.toString());
  }
}
