<template>
  <v-form
    ref="recipientDefaultForm"
  >
    <v-row>
      <v-col cols="12">
        <slot />
        <alert-fixed
          v-if="showAlert"
          type="error"
          :text="alertMessage"
        />
      </v-col>
      <v-col cols="12">
        <input-search
          :value.sync="search"
          :label="$t('form.search')"
        />
      </v-col>
      <v-col cols="12" class="form-recipient__select-buttons">
        <select-buttons
          :search="search"
          :items="recipientsModels"
          :selection.sync="defaultRecipientSelected"
          @selectedWasChanged="fillRecipientWithDefaultSelected"
        />
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <p class="form-recipient__link-explanation">
         {{ $t('recipient.form.link_explanation') }}
        </p>
      </v-col>
      <v-col cols="12">
        <input-field
          :value.sync="recipient.dpa"
          :label="$t('recipient.form.link')"
          :rules="[validation.min, validation.max]"
          :validateOnBlur="true"
        />
      </v-col>
      <v-col cols="12">
        <p class="form-recipient__label-upload">{{ $t('recipient.form.upload') }}</p>
        <upload-document
          :key="reloadUploadFiles"
          :filesUploaded.sync="recipient.files"
        />
      </v-col>
      <v-col cols="12">
        <check-contract-selector
          :dpa-status-selected.sync="recipient.dpaStatus"
        />
      </v-col>
      <v-col cols="12" class="text-right form-recipient__btn-save">
        <v-btn
          color="primary"
          rounded
          :loading="loadingButton"
          @click="save"
        >
          {{ $t('recipient.form.accept') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import InputSearch from '@/components/base/form/InputSearch.vue';
import SelectButtons from '@/components/base/form/SelectButtons.vue';
import InputField from '@/components/base/form/InputField.vue';
import UploadDocument from '@/components/base/form/UploadDocument.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import CheckContractSelector
  from './CheckContractSelector.vue';

export default {
  name: 'default-recipient-form',
  inject: {
    fetchRecipientsModels: 'fetchRecipientsModels',
  },
  components: {
    CheckContractSelector,
    AlertFixed,
    UploadDocument,
    InputField,
    SelectButtons,
    InputSearch,
  },
  props: {
    loadingButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      search: '',
      recipient: {
        name: '',
        businessName: '',
        country: '',
        state: '',
        category: '',
        role: '',
        warranties: [],
        status: '',
        dpa: '',
        files: [],
        dpaStatus: '',
        recipientModel: '',
      },
      validation: {
        min: (v) => !v || v.trim().length >= 3 || this.$t('form.validations.min_length', { length: 3 }),
        max: (v) => !v || v.trim().length <= 200 || this.$t('form.validations.max_length', { length: 200 }),
      },
      defaultRecipientSelected: {},
      recipientsModels: [],
      reloadUploadFiles: 0,
    };
  },
  methods: {
    async getRecipientModels() {
      const response = await this.fetchRecipientsModels();
      this.recipientsModels = response.recipientsModels;
    },
    save() {
      const textCard = document.getElementById('form-recipient__card-text');

      if (!this.$refs.recipientDefaultForm.validate()) {
        this.alertMessage = this.$t('form.review');
        this.showAlert = true;
        textCard.scroll({ top: 0, behavior: 'smooth' });
        return;
      }

      if (!Object.keys(this.defaultRecipientSelected).length) {
        this.alertMessage = this.$t('recipient.form.alert.not_selected');
        this.showAlert = true;
        textCard.scroll({ top: 0, behavior: 'smooth' });
        return;
      }

      this.showAlert = false;
      this.$emit('saveRecipient', this.recipient);
    },
    fillRecipientWithDefaultSelected() {
      Object.keys(this.recipient).forEach((key) => {
        if (key === 'files') {
          return;
        }
        this.recipient[key] = this.defaultRecipientSelected[key];
      });

      this.recipient.recipientModel = this.defaultRecipientSelected.id;
    },
    resetUploadedFiles() {
      this.recipient.files = [];
      this.reloadUploadFiles += 1;
    },
  },
  beforeMount() {
    this.$eventHub.$on('saveRecipientFailed', this.resetUploadedFiles);
    this.getRecipientModels();
  },
  beforeDestroy() {
    this.$eventHub.$off('saveRecipientFailed', this.resetUploadedFiles);
  },
};
</script>
