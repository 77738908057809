<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      scrollable
      max-width="95%"
    >
      <v-card>
        <v-card-title class="horizontal-stepper-header">
          <horizontal-steps-line
            v-if="showFormSteps"
            :steps="dataBreachSteps"
            DOMScrollId="form-data-breach__card-text"
            DOMSteps="data-breach__form-block"
          />
          <v-btn
            icon
            @click="showCloseModal = true"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text id="form-data-breach__card-text">
          <v-form
            ref="dataBreachForm"
          >
            <v-row
              class="data-breach__form-block"
              justify="center"
            >
              <v-col cols="12" md="6">
                <form-explanation />
              </v-col>
            </v-row>
            <v-row
              v-if="showAlert"
              justify="center"
              class="data-breach__form-block__alert"
            >
              <v-col cols="12" md="6" mt="1" mb="1">
                <alert-fixed
                  type="error"
                  :text="$t('form.review')"
                />
              </v-col>
            </v-row>
            <v-row
              id="whatHappened"
              justify="center"
              class="data-breach__form-block"
            >
              <v-col cols="12" md="6">
                <what-happened
                  :dataBreach="dataBreach"
                />
              </v-col>
            </v-row>
            <v-row
              id="affectedDataInfo"
              class="data-breach__form-block"
              justify="center"
            >
              <v-col cols="12" md="6">
                <affected-data-info
                  :dataBreach="dataBreach"
                />
              </v-col>
            </v-row>
            <v-row
              id="preventiveMeasures"
              class="data-breach__form-block"
              justify="center"
            >
              <v-col cols="12" md="6">
                <preventive-measures
                  :dataBreach="dataBreach"
                />
              </v-col>
            </v-row>
            <v-row
              id="additionalInfo"
              class="data-breach__form-block"
              justify="center"
            >
              <v-col cols="12" md="6">
                <additional-info
                  :dataBreach="dataBreach"
                  :already-uploaded-files="alreadyUploadedDocuments"
                />
              </v-col>
              <v-col v-if="!isSupervisorOrAdmin" cols="12" class="mt-n10">
                <v-row
                  justify="center"
                  class="pl-5"
                >
                  <v-col cols="12" md="6">
                    <div class="grey lighten-3 pa-5 rounded">
                      <p class="mb-0">{{ $t("data_breach.form.step.end_explanation") }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-form
                v-if="isSupervisorOrAdmin"
                ref="dataBreachDpoForm"
                id="form-data-breach__dpo-section"
                class="data-breach__dpo-answer"
              >
                <v-col cols="12" md="6" offset="0" offset-md="3">
                  <alert-fixed
                    v-if="showAlertDpo"
                    type="error"
                    :text="$t('form.review')"
                  />
                </v-col>
                <v-col cols="12" md="6" offset="0" offset-md="3">
                  <dpo-answers
                    v-if="isSupervisorOrAdmin"
                    :dataBreach="dataBreach"
                  />
                </v-col>
              </v-form>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="elevation-lighten pt-5 mt-1">
          <slot />
          <v-spacer />
          <v-btn
            color="primary"
            :outlined="isSupervisorOrAdmin"
            rounded
            class="mb-0"
            @click="save"
            v-track="'save DataBreach Form'"
          >
            {{ $t("data_breach.form.save") }}
          </v-btn>
          <v-btn
            v-if="isSupervisorOrAdmin"
            color="primary"
            rounded
            class="mb-0"
            @click="generateReport"
          >
            {{ $t("data_breach.form.generate_report") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <close-dialog-form
      :showCloseModal.sync="showCloseModal"
      @closeDataBreach="confirmClose"
      @saveDataBreach="save"
    />
  </div>
</template>

<script>
import HorizontalStepsLine from '@/components/base/form/HorizontalStepsLine.vue';
import FormExplanation from '@/components/modules/dataBreach/notify/form/FormExplanation.vue';
import WhatHappened from '@/components/modules/dataBreach/notify/form/WhatHappend.vue';
import AffectedDataInfo from '@/components/modules/dataBreach/notify/form/AffectedDataInfo.vue';
import PreventiveMeasures from '@/components/modules/dataBreach/notify/form/PreventiveMeasures.vue';
import AdditionalInfo from '@/components/modules/dataBreach/notify/form/AdditionalInfo.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import CloseDialogForm from '@/components/modules/dataBreach/notify/form/CloseDialogForm.vue';
import DpoAnswers from '@/components/modules/dataBreach/notify/form/DpoAnswers.vue';

export default {
  name: 'dialog-form-data-breach',
  inject: {
    user: 'user',
  },
  components: {
    DpoAnswers,
    HorizontalStepsLine,
    FormExplanation,
    WhatHappened,
    AffectedDataInfo,
    PreventiveMeasures,
    AdditionalInfo,
    AlertFixed,
    CloseDialogForm,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    dataBreachToEdit: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isSupervisorOrAdmin() {
      return this.user.isSupervisor || this.user.isAdmin;
    },
    dataBreachSteps() {
      return [
        {
          name: this.$t('data_breach.form.steppers_one'),
          id: 'whatHappened',
        },
        {
          name: this.$t('data_breach.form.steppers_two'),
          id: 'affectedDataInfo',
        },
        {
          name: this.$t('data_breach.form.steppers_three'),
          id: 'preventiveMeasures',
        },
        {
          name: this.$t('data_breach.form.steppers_four'),
          id: 'additionalInfo',
        },
      ];
    },
    alreadyUploadedDocuments() {
      return (this.dataBreachToEdit && this.dataBreachToEdit.attachedDocuments)
        ? this.dataBreachToEdit.attachedDocuments : [];
    },
  },
  data() {
    return {
      showAlert: false,
      showAlertDpo: false,
      dataBreach: {
        id: null,
        createdDate: null,
        createdBy: null,
        occurredOnDate: null,
        occurredOnTime: null,
        discoveredOnDate: null,
        discoveredOnTime: null,
        description: '',
        howDiscovered: '',
        wasIntentional: '',
        compromisedData: '',
        affectedProfiles: '',
        consequences: '',
        affectedCountries: '',
        numberAffected: null,
        securityMeasuresImplemented: '',
        futureSecurityMeasures: '',
        securityMeasuresProblems: '',
        informedAffectedPeople: '',
        informedAuthorities: '',
        attachedDocuments: [],
        attachedDocumentsToDelete: [],
        evaluation: '',
        agencyNotification: false,
        reportLanguage: '',
      },
      showCloseModal: false,
      showFormSteps: false,
    };
  },
  methods: {
    confirmClose() {
      this.showCloseModal = false;
      this.$emit('update:showModal', false);
    },
    fillDataBreachWithInfo() {
      Object.keys(this.dataBreach).forEach((key) => {
        if (key === 'attachedDocuments') {
          return;
        }
        this.dataBreach[key] = this.dataBreachToEdit[key];
      });
    },
    save() {
      this.showCloseModal = false;

      if (!this.isFormValid(false)) {
        return;
      }

      this.setCreatedBy();

      this.$emit('saveDataBreach', this.dataBreach);
    },
    setCreatedBy() {
      if (this.dataBreach.createdBy !== null) {
        return;
      }

      const nameSplit = this.user.name.split(',');
      this.dataBreach.createdBy = `${nameSplit[1]} ${nameSplit[0]}`;
    },
    generateReport() {
      this.showCloseModal = false;

      if (!this.isFormValid(true)) {
        return;
      }
      this.setCreatedBy();
      this.$emit('generateReport', this.dataBreach);
    },
    isFormValid(isReportRequired) {
      const textCard = document.getElementById('form-data-breach__card-text');
      const dpoSection = document.getElementById('form-data-breach__dpo-section');
      let topScroll = null;

      this.showAlert = false;
      this.showAlertDpo = false;

      if (isReportRequired && !this.$refs.dataBreachDpoForm.validate()) {
        this.showAlertDpo = true;
        topScroll = dpoSection.offsetTop - 300;
      }
      if (!this.$refs.dataBreachForm.validate()) {
        this.showAlert = true;
        topScroll = 350;
      }

      if (topScroll) {
        textCard.scroll({ top: topScroll, behavior: 'smooth' });
      }

      return !this.showAlert && !this.showAlertDpo;
    },
  },
  mounted() {
    if (this.dataBreachToEdit) {
      this.fillDataBreachWithInfo();
    }
    this.showFormSteps = true;
  },
};
</script>
