import UserPassword from '@/domain/user/UserPassword';
import UserRepository from '@/domain/user/UserRepository';
import UserToken from '@/domain/user/UserToken';

export default class ChangePassword {
  private readonly userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  public async invoke(token: string, password: string): Promise<object> {
    try {
      await this.userRepository.changePassword(
        UserToken.fromString(token),
        UserPassword.fromString(password),
      );

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
