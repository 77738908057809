import AbstractRecipientDpaStatusRepresentation
  from '@/domain/recipient/recipientDpaStatus/AbstractRecipientDpaStatusRepresentation';

export default class RecipientDpaStatusRepresentation
  extends AbstractRecipientDpaStatusRepresentation {
  _translator;

  _name = null;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get key() {
    return this._key.toString();
  }

  get name() {
    if (!this._name) {
      this._name = this._translator.translate(`recipient.form.document_data.${this._key.toString()}`);
    }

    return this._name;
  }
}
