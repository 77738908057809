import { user } from '@/infrastructure/user/UserCache';
import { admin } from '@/infrastructure/admin/AdminCache';

interface Section {
  name: string,
  icon: string,
  translation: string,
  trackAction: string,
  link?: string,
}

export default class MenuSections {
  public static getMenuSections(): Section[] {
    if (user.isRestrictedEmployee || user.isTrainingsManager) {
      return this.menuRestrictedEmployeeAndTrainingsManager();
    }

    let menu: Section[] = this.defaultMenuSection();

    if (admin.isSpanishAdmin) {
      menu = this.addTreatmentManagers(menu);
    }

    if (user.isB2B) {
      menu = this.addDataBreach(menu);
      menu = this.addTraining(menu);
      menu = this.addFAQ(menu);
      menu = this.addTom(menu);
    }

    return menu;
  }

  private static menuRestrictedEmployeeAndTrainingsManager(): Section[] {
    if (!user.isB2B) {
      return [];
    }

    const menu = [
      {
        name: 'training',
        icon: 'school',
        translation: 'menus.training',
        trackAction: 'Training',
      },
      {
        name: 'data-breach',
        icon: 'gpp_maybe',
        translation: 'menus.data_breach',
        trackAction: 'Data Breach',
      },
    ];

    return menu;
  }

  private static defaultMenuSection(): Section[] {
    return [
      {
        name: 'processing-activities',
        icon: 'linear_scale',
        translation: 'menus.processing_activities',
        trackAction: 'Processing activities',
      },
      {
        name: 'legal-documents',
        icon: 'gavel',
        translation: 'menus.legal_documents',
        trackAction: 'Legal documents',
      },
      {
        name: 'risks',
        icon: 'report_problem',
        translation: 'menus.risks',
        trackAction: 'Risks',
      },
      {
        name: 'registers',
        icon: 'business',
        translation: 'menus.registers',
        trackAction: 'Registers',
      },
      {
        name: 'client-folder',
        icon: 'folder_open',
        translation: 'menus.client_folder',
        trackAction: 'Client folder',
      },
    ];
  }

  private static addTreatmentManagers(menu: Section[]): Section[] {
    const clientFolderIndex = menu.findIndex((section) => section.name === 'client-folder');
    menu.splice(clientFolderIndex, 0, {
      name: 'treatment-managers',
      icon: 'work',
      translation: 'menus.treatment_managers',
      trackAction: 'Processors',
    });

    return menu;
  }

  private static addDataBreach(menu: Section[]): Section[] {
    const clientFolderIndex = menu.findIndex((section) => section.name === 'client-folder');
    menu.splice(clientFolderIndex, 0, {
      name: 'data-breach',
      icon: 'gpp_maybe',
      translation: 'menus.data_breach',
      trackAction: 'Data Breach',
    });

    return menu;
  }

  private static addTraining(menu: Section[]): Section[] {
    menu.push({
      name: 'training',
      icon: 'school',
      translation: 'menus.training',
      trackAction: 'Training',
    });

    return menu;
  }

  private static addFAQ(menu: Section[]): Section[] {
    menu.push({
      name: 'faq',
      icon: 'help',
      translation: 'menus.faq.title',
      trackAction: 'FAQ',
      link: 'menus.faq.url',
    });

    return menu;
  }

  private static addTom(menu: Section[]): Section[] {
    const clientFolderIndex = menu.findIndex((section) => section.name === 'client-folder');
    menu.splice(clientFolderIndex, 0, {
      name: 'tom',
      icon: 'format_list_bulleted ',
      translation: 'menus.toms',
      trackAction: 'Tom',
    });
    return menu;
  }
}
