export default ({
  risksPiasRepository,
  downloadFile,
}) => async (companyId, documentId, typeDocument, type, isAdmin) => {
  try {
    const urlDownload = await risksPiasRepository.downloadDocument(
      companyId, documentId, typeDocument, type, isAdmin,
    );

    const url = urlDownload.preview_url || urlDownload.url;
    downloadFile.download(url);

    return { success: true };
  } catch (e) {
    if (e === 401) {
      return { success: true };
    }
    return { success: false };
  }
};
