<template>
  <div class="h-100 w-100">
    <logo-img
      :logoUrl="urlLogo || companyLogoUrl"
      :companyName="companyName"
      :classLogo="classLogo"
      :height="heightLogo"
      :position="positionLogo"
      titleTag="h1"
    />
    <div v-if="showConfirmation">
      <confirm-send-email
        :email.sync="userEmail"
      />
    </div>
    <div v-else>
      <ask-email
        :errorFeedback.sync="errorFeedback"
        @recoverPassword="recoverPassword"
        :errorInput.sync="thereIsEmailError"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import formValidationService from '@/services/validations/formValidation';
import { recoverPassword } from '@/application/user';
import AskEmail from '../modules/forgotPassword/AskEmail.vue';
import ConfirmSendEmail from '../modules/forgotPassword/ConfirmSendEmail.vue';
import LogoImg from '../base/icons/LogoImg.vue';

export default {
  name: 'forgot-password-container',
  inject: {
    urlLogo: 'urlLogo',
    heightLogo: 'heightLogo',
    positionLogo: 'positionLogo',
    classLogo: 'classLogo',
  },
  components: {
    AskEmail,
    ConfirmSendEmail,
    LogoImg,
  },
  computed: {
    ...mapGetters('admin', {
      companyName: 'name',
      companyLogoUrl: 'logoUrl',
    }),
  },
  data() {
    return {
      errorFeedback: [],
      userEmail: '',
      thereIsEmailError: false,
      showConfirmation: false,
    };
  },
  methods: {
    recoverPassword(email) {
      this.$showLoading();
      this.errorFeedback = this.checkForm(email);

      if (this.errorFeedback.length === 0) {
        recoverPassword.invoke(email);
        this.userEmail = email;
        this.showConfirmation = true;
      }

      this.$hideLoading();
    },
    checkForm(email) {
      const textErrors = [];
      this.thereIsEmailError = false;

      const emptyEmail = formValidationService.isEmptyField(email);
      const validEmail = formValidationService.isValidEmail(email);

      if (emptyEmail) {
        textErrors.push(this.$t('forgotPassword.error.required_field_email'));
        this.thereIsEmailError = true;
      } else if (!validEmail) {
        textErrors.push(this.$t('forgotPassword.error.incorrect_email'));
        this.thereIsEmailError = true;
      }

      return textErrors;
    },
  },
};
</script>
