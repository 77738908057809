import DepartmentRepository from '@/domain/department/DepartmentRepository';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';

export default class ClientSelectedWasChangedEventListener {
  private readonly departmentRepository: DepartmentRepository;

  constructor(departmentsRepository: DepartmentRepository) {
    this.departmentRepository = departmentsRepository;
  }

  public handle(event: ClientSelectedWasChanged): void {
    this.departmentRepository.reset();
  }
}
