<template>
  <v-row
    v-if="listCertificates.length"
  >
    <v-col cols="12">
      <h2 class="mb-1">
        {{$t('training.my_trainings.your_certificates')}}
      </h2>
      <p>{{$t('training.my_trainings.completed_courses')}}</p>
    </v-col>
    <v-col cols="12">
      <general-table
        :headers="headers"
        :list="listCertificates"
        :columns="['certificate']"
      >
        <template v-slot:certificate="props">
          <download-certificate
            :certificate="props.item"
          />
        </template>
      </general-table>
    </v-col>
  </v-row>
</template>

<script>
import GeneralTable from '@/components/base/tables/GeneralTable.vue';
import DownloadCertificate from '@/components/modules/training/userTrainings/DownloadCertificate.vue';
import FetchInscriptionsQuery
  from '@/application/training/user/fethcInscriptions/FetchInscriptionsQuery';

export default {
  name: 'list-user-certificates',
  inject: {
    user: 'user',
    fetchInscriptions: 'fetchInscriptions',
  },
  components: {
    DownloadCertificate,
    GeneralTable,
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('training.my_trainings.course_name'),
          align: 'start',
          value: 'name',
          width: '40%',
        },
        {
          text: this.$t('training.my_trainings.completion_date'),
          align: 'start',
          value: 'completedDate',
          width: '30%',
        },
        {
          text: this.$t('training.my_trainings.certificate'),
          align: 'start',
          value: 'certificate',
          width: '30%',
        },
      ];
    },
  },
  data() {
    return {
      listCertificates: [],
    };
  },
  methods: {
    async loadUserCertificatesList() {
      this.$showLoading();
      const response = await this.fetchInscriptions
        .invoke(new FetchInscriptionsQuery(this.user.clientId));
      this.$hideLoading();
      if (!response.success) {
        this.$showError(
          `training.my_trainings.error.${response.error}`,
          'training.my_trainings.error.error_loading_list',
        );
        return;
      }
      this.listCertificates = response.inscriptions;
    },
  },
  beforeMount() {
    this.loadUserCertificatesList();
  },
};
</script>
