<template>
  <span>
    <v-btn
      color="primary"
      rounded
      v-track="'New office location -- start'"
      @click="openNewHeadquarterModal"
    >
      {{ $t('headquarters.create.button') }}
    </v-btn>
    <dialog-form-headquarter
      v-if="modalIsOpen"
      :show-modal.sync="modalIsOpen"
      @saveHeadquarters="saveHeadquarters"
    />
  </span>
</template>

<script>

import DialogFormHeadquarter from './form/DialogFormHeadquarter.vue';

export default {
  name: 'create-headquarter',
  inject: {
    user: 'user',
    createHeadquarter: 'createHeadquarter',
  },
  components: {
    DialogFormHeadquarter,
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    openNewHeadquarterModal() {
      this.modalIsOpen = true;
    },
    async saveHeadquarters(headquarters) {
      this.$showLoading();
      const response = await this.createHeadquarter(headquarters, this.user.clientId);
      this.$hideLoading();
      this.$track({ action: 'New office location -- finish', result: response.success });
      if (!response.success) {
        this.$showError('headquarters.create.error');
        return;
      }

      this.$showSuccess('headquarters.create.success');
      this.$eventHub.$emit('headquarterWasCreated');
      this.modalIsOpen = false;
    },
  },
};
</script>
