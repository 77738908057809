<template>
  <v-autocomplete
    v-model="clientSelected"
    :items="clients"
    :label="label"
    :no-data-text="$t('form.no_data_text')"
    item-text="name"
    item-value="id"
    :filter="searchCustomFilter"
    solo
    append-icon="mdi-chevron-down"
    height="40px"
    @blur="recoverSelectedClientIfEmptySelection()"
    @change="changeClientSelected"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.businessName }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { fetchClients, changeSelectedClient } from '@/infrastructure/application/client';
import FetchClientsQuery from '@/application/client/fetchClients/FetchClientsQuery';
import normalizationService from '@/services/shared/normalization';
import decryptQueryParamService from '@/services/user/decryptQueryParam';
import ChangeClientSelectedCommand
  from '@/application/client/changeClientSelected/ChangeClientSelectedCommand';

export default {
  name: 'select-client',
  inject: {
    user: 'user',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      clients: [],
      clientSelected: null,
    };
  },
  methods: {
    async loadClients() {
      const response = await fetchClients.invoke(new FetchClientsQuery());
      if (!response.success) {
        return;
      }

      this.clients = response.clients;
    },
    changeClientSelected(clientId) {
      const clientSelected = this.clients.find((client) => client.id === clientId);

      if (!clientSelected) {
        return;
      }

      this.clientSelected = clientSelected.id;
      this.updateSelectedClient(clientSelected);
    },
    async updateSelectedClient(client) {
      await changeSelectedClient.invoke(new ChangeClientSelectedCommand(client.id));
    },
    recoverSelectedClientIfEmptySelection() {
      if (this.clientSelected) {
        return;
      }

      this.changeClientSelected(this.user.clientId);
    },
    searchCustomFilter(client, queryString) {
      const searchNorm = normalizationService.normalizeString(queryString);
      return (client.nameNormalized.includes(searchNorm)
        || client.businessNameNormalized.includes(searchNorm));
    },
    changeSelectedClientAndDeleteListener(clientId) {
      this.changeClientSelected(clientId);
      this.$eventHub.$off('clientSelectedWasChanged', this.changeSelectedClientAndDeleteListener);
    },
  },
  async beforeMount() {
    let clientId;
    if (this.$route.query.pudk) {
      const params = decryptQueryParamService.decrypt(this.$route.query.pudk);
      clientId = params.clientId;
    }

    await this.loadClients();
    this.changeClientSelected(clientId || this.user.clientId);

    this.$eventHub.$on('clientSelectedWasChanged', this.changeSelectedClientAndDeleteListener);
  },
};
</script>
