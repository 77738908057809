import EquipmentSecurityMeasuresValidationException from './EquipmentSecurityMeasuresValidationException';

export default class EquipmentSecurityMeasures {
  _key;

  static VALID_SECURITY_MEASURES = [
    'firewall',
    'detectionSystem',
    'mobileDevice',
    'remoteAccess',
    'encryptionData',
    'smartphoneEncryption',
    'caseLock',
    'biosProtection',
    'usbLock',
    'desktopLock',
    'encryptionDevices',
    'userPermissions',
    'userProfiles',
    'passwordAssignment',
    'vpn',
    'technicalLogging',
    'traceability',
    'allocationRights',
    'storageData',
    'clearResponsibilities',
    'firewallDeployment',
    'spamFilters',
    'virusScanners',
    'intrusionDetection',
    'intrusionPrevention',
    'programsUseData',
  ];

  constructor(key) {
    this.isValid(key);
    this._key = key;
  }

  isValid(key) {
    if (!key) {
      throw new EquipmentSecurityMeasuresValidationException('Security measures should not be empty');
    }

    if (!EquipmentSecurityMeasures.VALID_SECURITY_MEASURES.includes(key)) {
      throw new EquipmentSecurityMeasuresValidationException('Security measures does not exist');
    }
  }

  representedAs(securityMeasuresRepresentation) {
    securityMeasuresRepresentation.setKey(this._key);
    return securityMeasuresRepresentation;
  }

  toString() {
    return this._key;
  }

  static all() {
    return this.VALID_SECURITY_MEASURES.map((securityMeasure) => new this(securityMeasure));
  }

  static fromKey(key) {
    return new this(key);
  }
}
