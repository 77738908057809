<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items="countries"
    :label="label"
    :rules="rules"
    item-value="code"
    :attach="true"
  />
</template>

<script>
import countryUseCases from '@/application/country';
import SelectAutocompleteField from '../SelectAutocompleteField.vue';

export default {
  name: 'country-selector',
  components: {
    SelectAutocompleteField,
  },
  props: {
    item: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: () => this.$t('form.country.select_country'),
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countries: [],
      value: this.item,
    };
  },
  methods: {
    async getCountries() {
      this.countries = await countryUseCases.fetchCountries();
    },
  },
  beforeMount() {
    this.getCountries();
  },
  watch: {
    value() {
      this.$emit('update:item', this.value);
    },
    item() {
      this.value = this.item;
    },
  },
};
</script>
