import EventBusInterface from '@/application/eventBus/EventBus';
import DomainEvent from '@/domain/domainEvent/DomainEvent';
import Container from '@/infrastructure/dependecyContainer/Container';
import EventListener from '@/application/eventBus/EventListener';

class EventBus implements EventBusInterface {
  private readonly eventListenerCollection: { [ key: string ]: EventBus[] } = {};

  private readonly eventBusInversionOfControls: { [ key: string ]: any };

  constructor(eventBusInversionOfControls: { [ key: string ]: any }) {
    this.eventBusInversionOfControls = eventBusInversionOfControls;
  }

  public handleBatch(events: DomainEvent[]): void {
    events.forEach((event: DomainEvent) => {
      this.handle(event);
    });
  }

  public handle(event: DomainEvent): void {
    const { eventName } = event;

    this.addEvent(eventName);

    this.eventListenerCollection[eventName].forEach((eventListener: EventListener) => {
      if (!eventListener) {
        return;
      }

      eventListener.handle(event);
    });

    this.removeEvent(eventName);
  }

  private addEvent(eventName: string): void {
    const eventListenersBuilder: { [ key: string ]: EventBus[] } = this.eventBusInversionOfControls;
    this.eventListenerCollection[eventName] = eventListenersBuilder[eventName].map(
      (eventListener: any) => eventListener(),
    );
  }

  private removeEvent(eventName: string): void {
    delete this.eventListenerCollection[eventName];
  }
}

export default new EventBus(Container);
