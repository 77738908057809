import { getManagement } from '@/shared/restActions';
import DataBreachHistoricReadModel from '@/domain/dataBreach/historic/DataBreachHistoricReadModel';
import ClientId from '@/domain/client/ClientId';
import DataBreachHistoricCache from '@/infrastructure/dataBreach/historic/DataBreachHistoricCache';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachCode from '@/domain/dataBreach/DataBreachCode';
import DataBreachOccurredOn from '@/domain/dataBreach/DataBreachOccurredOn';
import DataBreachAttachedDocuments from '@/domain/dataBreach/DataBreachAttachedDocuments';
import DataBreachHistoricRaw, { AttachedDocuments } from '@/infrastructure/dataBreach/historic/DataBreachHistoricRaw';
import Translator from '@/infrastructure/Translator';
import DataBreachesHistoricRepresentation
  from '@/application/dataBreach/historic/fetchDataBreaches/DataBreachesHistoricRepresentation';
import DataBreachCreatedBy from '@/domain/dataBreach/DataBreachCreatedBy';

export default class DataBreachHistoricReadModelApi implements DataBreachHistoricReadModel {
  private readonly translator: Translator

  constructor(translator: Translator) {
    this.translator = translator;
  }

  async ofClient(clientId: ClientId): Promise<DataBreachesHistoricRepresentation[]> {
    try {
      let dataBreaches: DataBreachesHistoricRepresentation[] = DataBreachHistoricCache.all();

      if (dataBreaches.length) {
        return dataBreaches;
      }

      const dataBreachesRaw: DataBreachHistoricRaw[] = await this.getDataBreachesFromApi(
        clientId.toInt(),
      );
      dataBreaches = this.buildDataBreachesRepresentation(dataBreachesRaw);

      DataBreachHistoricCache.saveAll(dataBreaches);

      return dataBreaches;
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching data breaches');
    }
  }

  private async getDataBreachesFromApi(clientId: number): Promise<DataBreachHistoricRaw[]> {
    const response = await getManagement(`/api/v2/clients/${clientId}/data-breaches`);
    return response.data;
  }

  private buildDataBreachesRepresentation(
    dataBreachesRaw: DataBreachHistoricRaw[],
  ): DataBreachesHistoricRepresentation[] {
    const dataBreaches: DataBreachesHistoricRepresentation[] = [];

    dataBreachesRaw.forEach((dataBreachRaw: DataBreachHistoricRaw) => {
      const dataBreach: DataBreachesHistoricRepresentation = new DataBreachesHistoricRepresentation(
        this.translator,
      );

      dataBreach.setId(DataBreachId.fromString(dataBreachRaw.id));
      dataBreach.setCode(DataBreachCode.fromString(dataBreachRaw.code));
      dataBreach.setAgencyNotification(dataBreachRaw.agency_notification);
      dataBreach.setOccurredOn(DataBreachOccurredOn.fromW3CDate(dataBreachRaw.occurred_on));
      dataBreach.setCreatedBy(DataBreachCreatedBy.fromString(dataBreachRaw.created_by));

      dataBreach.setAttachedDocuments(
        this.buildAttachedDocuments(dataBreachRaw.attached_documents),
      );

      dataBreaches.push(dataBreach);
    });

    return dataBreaches;
  }

  private buildAttachedDocuments(attachedDocumentsRaw: AttachedDocuments[]) {
    const attachedDocuments: DataBreachAttachedDocuments[] = [];
    attachedDocumentsRaw.forEach((attachedDocument: AttachedDocuments) => {
      const dataBreachAttachedDocument = DataBreachAttachedDocuments
        .fromAttachedDocumentStruct(attachedDocument);

      attachedDocuments.push(dataBreachAttachedDocument);
    });

    return attachedDocuments;
  }
}
