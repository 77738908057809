<template>
  <div class="sections">
    <div class="sections-title">
      <h3 class="mb-5">{{ $t('training.section') }}</h3>
      <v-divider/>
    </div>
    <v-row>
      <v-col
        v-for="(section, key) in sectionsUser"
        :key="key"
        class="sections-sticker"
        @click="$router.push({ name: section.routeName })"
        cols="12"
      >
        <v-row align-content="center" align="center">
          <v-col cols="3" md="2" class="sections-sticker">
            <div class="sections-sticker__icon-number text-center">
              <div>
                <v-icon>
                  {{ section.icon }}
                </v-icon>
                <h2>
                  {{ section.total }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col cols="9" md="10">
            <h3>
              {{ section.title }}
            </h3>
            <p>
              {{ section.text }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import statsUseCases from '@/usecases/client/stats';

export default {
  name: 'sections-client',
  computed: {
    ...mapGetters('admin', {
      isSpanishAdmin: 'isSpanishAdmin',
    }),
    ...mapGetters('user', {
      companyId: 'companyId',
    }),
    sectionsUser() {
      const sections = {
        processing_activities: {
          icon: 'linear_scale',
          total: this.totalDocsSection.processingActivities,
          title: this.$t('home.client.processing_activities_title'),
          text: this.$t('home.client.processing_activities_text'),
          routeName: 'processing-activities',
        },
        legal_document: {
          icon: 'gavel',
          total: this.totalDocsSection.legalDocuments,
          title: this.$t('home.client.legal_documents_title'),
          text: this.$t('home.client.legal_documents_text'),
          routeName: 'legal-documents',
        },
        risks: {
          icon: 'report_problem',
          total: this.totalDocsSection.risks,
          title: this.$t('home.client.risks_title'),
          text: this.$t('home.client.risks_text'),
          routeName: 'risks',
        },
        registers: {
          icon: 'business',
          total: this.totalDocsSection.registers,
          title: this.$t('home.client.registers_title'),
          text: this.$t('home.client.registers_text'),
          routeName: 'registers',
        },
        treatment_managers: {
          icon: 'work',
          total: this.totalDocsSection.treatmentManagers,
          title: this.$t('home.client.treatment_managers_title'),
          text: this.$t('home.client.treatment_managers_text'),
          routeName: 'treatment-managers',
        },
        client_folder: {
          icon: 'cloud_upload',
          total: this.totalDocsSection.clientFolder,
          title: this.$t('home.client.client_folder_title'),
          text: this.$t('home.client.client_folder_text'),
          routeName: 'client-folder',
        },
      };

      if (!this.isSpanishAdmin) {
        delete sections.treatment_managers;
      }

      return sections;
    },
  },
  data() {
    return {
      totalDocsSection: {},
    };
  },
  methods: {
    async getTotalDocsBySection() {
      const response = await statsUseCases.fetchTotalDocumentsBySection(this.companyId);

      if (response.success) {
        return response.stats;
      }

      this.$showError('home.client.error.error_page_load');
      return {};
    },
  },
  async beforeMount() {
    this.$showLoading();
    this.totalDocsSection = await this.getTotalDocsBySection();
    this.$hideLoading();
  },
};
</script>
