import store from '@/store';

function showDocumentViewer() {
  return store.dispatch('viewDocument/showDocumentViewer', {});
}

function hideDocumentViewer() {
  return store.dispatch('viewDocument/hideDocumentViewer', {});
}

function setDocumentInformation(data) {
  return store.dispatch('viewDocument/setDocumentInformation', data);
}
function resetDocumentInformation() {
  return store.dispatch('viewDocument/resetDocumentInformation');
}

export default {
  showDocumentViewer,
  hideDocumentViewer,
  setDocumentInformation,
  resetDocumentInformation,
};
