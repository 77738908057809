import ResetDepartmentCommandHandler
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommandHandler';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';
import ResetDepartmentCommand
  from '@/application/department/listDepartment/command/resetDepartment/ResetDepartmentCommand';

export default class ClientSelectedWasChangedEventListener {
  private readonly resetDepartment: ResetDepartmentCommandHandler;

  constructor(resetDepartment: ResetDepartmentCommandHandler) {
    this.resetDepartment = resetDepartment;
  }

  public handle(event: ClientSelectedWasChanged): void {
    this.resetDepartment.invoke(new ResetDepartmentCommand());
  }
}
