import RecipientCategoryValidationException
  from './RecipientCategoryValidationException';

export default class RecipientCategory {
  _category;

  static VALID_CATEGORIES = [
    'accountingSoftware',
    'adminDepartment',
    'cloudComputing',
    'cloudStorage',
    'crmSoftware',
    'customerServiceProvider',
    'customerSupport',
    'dataStorage',
    'eCommerce',
    'emailServices',
    'erpSoftware',
    'eventsSoftware',
    'hosting',
    'internalDepartment',
    'itDepartment',
    'lawEnforcement',
    'lawServices',
    'legalDepartment',
    'logistics',
    'management',
    'marketingDepartment',
    'marketingSoftware',
    'marketingTools',
    'messagingServices',
    'officesApps',
    'orderingSoftware',
    'other',
    'passwordManagement',
    'paymentPlatform',
    'prDepartment',
    'preventionRisks',
    'productionDepartment',
    'propertyAdministration',
    'publicAdministration',
    'rrhhDepartment',
    'rrhhManagement',
    'rrhhSoftware',
    'securityServices',
    'sellDepartment',
    'servicesPlatform',
    'taxManagement',
    'ticServices',
    'tracking',
  ]

  static OTHER_CATEGORY = 'other';

  constructor(category) {
    this.isValid(category);
    this._category = category;
  }

  static other() {
    return new RecipientCategory(RecipientCategory.OTHER_CATEGORY);
  }

  isValid(category) {
    if (!RecipientCategory.VALID_CATEGORIES.includes(category)) {
      throw new RecipientCategoryValidationException('Recipient category does not exist');
    }
  }

  static all() {
    return this.VALID_CATEGORIES.map((category) => new this(category));
  }

  representedAs(recipientCategoryRepresentation) {
    recipientCategoryRepresentation.setKey(this._category);
    return recipientCategoryRepresentation;
  }

  toString() {
    return this._category;
  }

  isEquals(recipientCategory) {
    return recipientCategory._category === this._category;
  }
}
