<template>
  <div class="d-inline-block">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          color="primary"
          class="mb-5 ml-3"
          @click="initializeAndShowModal"
          v-track="'Add collaborator data breach -- start'"
        >
          <v-icon class="material-icons-round">
            person_add
          </v-icon>
        </v-btn>
      </template>
      {{ $t('data_breach.collaborator.title') }}
    </v-tooltip>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="60%"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h2>
            {{ $t('data_breach.collaborator.title') }}
          </h2>
          <v-spacer />
          <v-btn
            icon
            @click="resetFormAndCloseModal"
            v-track="'Add collaborator data breach -- cancel'"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
          <p class="primary--text">
            {{ $t('data_breach.collaborator.date_time') }} {{ dataBreach.createdDate }}
          </p>
          <p>
            {{ $t('data_breach.collaborator.info') }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-form ref="inviteCollaborator">
            <v-row>
              <v-col v-if="showAlert" cols="12">
                <alert-fixed
                  type="error"
                >
                  {{ textAlert }}
                </alert-fixed>
              </v-col>
              <v-col cols="6" md="6">
                <input-field
                  :value.sync="collaborator.name"
                  :label="`${$t('data_breach.collaborator.name')} *`"
                  :rules="[validation.required, validation.min]"
                  :validateOnBlur="true"
                />
              </v-col>
              <v-col cols="6" md="6">
                <input-field
                  :value.sync="collaborator.surname"
                  :label="`${$t('data_breach.collaborator.surname')} *`"
                  :rules="[validation.required, validation.min]"
                  :validateOnBlur="true"
                />
              </v-col>
              <v-col cols="12" md="6">
                <input-field
                  :value.sync="collaborator.email"
                  :label="`${$t('data_breach.collaborator.email')} *`"
                  :rules="[validation.required, validation.email]"
                  :validateOnBlur="true"
                />
              </v-col>
              <v-col cols="12">
                <textarea-field
                  :value.sync="collaborator.message"
                  :counter="700"
                  :counterValue="textCounter"
                  :rules="[validation.maxLength]"
                  :label="$t('data_breach.collaborator.message_placeholder')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            rounded
            @click="resetFormAndCloseModal"
            v-track="'Add collaborator data breach -- cancel'"
          >
            {{ $t('data_breach.collaborator.button_cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="save()"
          >
            {{ $t('data_breach.collaborator.button_continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InputField from '@/components/base/form/InputField.vue';
import TextareaField from '@/components/base/form/TextareaField.vue';
import formValidationService from '@/services/validations/formValidation';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';
import AddDataBreachCollaboratorCommand
  from '@/application/dataBreach/addDataBreachCollaborator/AddDataBreachCollaboratorCommand';

export default {
  name: 'add-collaborator',
  inject: {
    addDataBreachCollaborator: 'addDataBreachCollaborator',
  },
  components: {
    AlertFixed,
    InputField,
    TextareaField,
  },
  props: {
    dataBreach: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      showAlert: false,
      textAlert: '',
      validation: {
        required: (v) => (v !== null && !!v.trim()) || this.$t('form.validations.required_field'),
        min: (v) => (v !== null && v.trim().length >= 2) || this.$t('form.validations.min_length', { length: 2 }),
        email: (v) => (v !== null && formValidationService.isValidEmail(v.trim())) || this.$t('form.validations.wrong_email'),
        maxLength: (v) => (v !== null && v.trim().length <= 700) || this.$t('form.validations.max_length', { length: 700 }),
      },
      collaborator: {
        name: null,
        surname: null,
        email: '',
        message: '',
      },
    };
  },
  methods: {
    resetFormAndCloseModal() {
      this.$refs.inviteCollaborator.reset();
      this.showModal = false;
    },
    initializeAndShowModal() {
      Object.keys(this.collaborator).forEach((key) => { this.collaborator[key] = ''; });
      this.showAlert = false;
      this.showModal = true;
    },
    async save() {
      if (!this.$refs.inviteCollaborator.validate()) {
        this.textAlert = this.$t('form.review');
        this.showAlert = true;
        return;
      }

      this.showAlert = false;
      this.$showLoading();

      const response = await this.addDataBreachCollaborator.invoke(
        new AddDataBreachCollaboratorCommand(
          this.dataBreach.id,
          this.collaborator.email,
          this.collaborator.name,
          this.collaborator.surname,
          this.collaborator.message,
        ),
      );

      this.trackEvent(response);

      this.$hideLoading();

      if (!response.success) {
        this.manageErrors(response.error);
        return;
      }

      this.$showSuccess([
        this.$t('data_breach.collaborator.success_added', { name: this.collaborator.email }),
        'data_breach.collaborator.success_added_info',
      ]);
      this.resetFormAndCloseModal();
    },
    manageErrors(error) {
      if (this.$te(`data_breach.collaborator.${error}`)) {
        this.textAlert = this.$t(`data_breach.collaborator.${error}`);
        this.showAlert = true;
        return;
      }

      this.$showError([
        'data_breach.collaborator.error_not_added',
        'data_breach.collaborator.error_not_added_info',
      ]);
    },
    trackEvent(response) {
      const event = {
        action: 'Add collaborator data breach -- finish',
        result: response.success,
      };

      if (!response.success) {
        event.error = response.error;
      }

      this.$track(event);
    },
    textCounter(text) {
      return text.trim().length;
    },
  },
};
</script>
