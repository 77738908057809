import Login from '@/domain/user/login/Login';
import OktaLoginCredentials from '@/domain/user/login/Credentials/OktaLoginCredentials';
import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class OktaLoginHandler {
  private login: Login;

  constructor(login: Login) {
    this.login = login;
  }

  public async invoke(code: string, oktaId: string): Promise<Object> {
    try {
      const loginCredential = new OktaLoginCredentials(
        StringValueObject.fromString(code),
        UuidValueObject.fromString(oktaId),
      );
      const subdomain = await this.login.invoke(loginCredential);

      return { success: true, subdomain };
    } catch (e) {
      return { success: false };
    }
  }
}
