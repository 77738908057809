import TrainingUserRepository from '@/domain/training/user/TrainingUserRepository';
import TrainingUser from '@/domain/training/user/TrainingUser';
import { getManagement, postManagement } from '@/shared/restActions';
import TrainingUserApiRepresentation
  from '@/infrastructure/training/user/TrainingUserApiRepresentation';
import TrainingCache from '@/infrastructure/training/TrainingCache';
import ClientId from '@/domain/client/ClientId';
import Inscription from '@/domain/training/inscription/Inscription';
import InscriptionRaw from '@/infrastructure/training/employee/InscriptionRaw';
import Course from '@/domain/training/course/Course';
import CourseName from '@/domain/training/course/CourseName';
import InscriptionStatus from '@/domain/training/inscription/InscriptionStatus';
import InscriptionId from '@/domain/training/inscription/InscriptionId';
import InscriptionCompletedDate
  from '@/domain/training/inscription/InscriptionCompletedDate';

export default class TrainingUserRepositoryApi implements TrainingUserRepository {
  async save(trainingUser: TrainingUser, clientId: ClientId): Promise<void> {
    // @ts-ignore
    const representation: TrainingUserApiRepresentation = trainingUser.representedAs(
      new TrainingUserApiRepresentation(),
    );
    const payload = {
      id: representation.id,
      language: representation.language,
      client_id: clientId.toInt(),
    };
    await postManagement('/api/v2/trainings/users', payload);
    TrainingCache.setUserLanguage(representation.language);
  }

  public async ofClient(clientId: ClientId): Promise<Inscription[]> {
    const inscriptionsRaw: InscriptionRaw[] = await this.getInscriptionsFromApi(clientId);
    return this.buildInscriptions(inscriptionsRaw);
  }

  private async getInscriptionsFromApi(clientId: ClientId): Promise<InscriptionRaw[]> {
    const response = await getManagement(`/api/v2/clients/${clientId.toInt()}/user-inscriptions/completed`);
    return response.data;
  }

  private buildInscriptions(inscriptionsRaw: InscriptionRaw[]) {
    const inscriptions: Inscription[] = [];
    inscriptionsRaw.forEach((inscription: InscriptionRaw) => {
      const course = new Course(CourseName.fromString(inscription.course_name));
      const inscriptionEntity: Inscription = new Inscription(
        InscriptionStatus.fromString(inscription.inscription_status),
        course,
        InscriptionId.fromString(inscription.inscription_id),
      );

      inscriptionEntity.addCompletedDate(
        InscriptionCompletedDate.fromW3CDate(inscription.completed_date),
      );

      inscriptions.push(inscriptionEntity);
    });

    return inscriptions;
  }
}
