import AbstractDocumentDataEmployeeRepresentation from '@/domain/documentData/employee/AbstractDocumentDataEmployeeRepresentation';

export default class DocumentDataEmployeeRepresentation
  extends AbstractDocumentDataEmployeeRepresentation {
  _translator;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get languageTranslation() {
    return this._translator.translate(`languages.by_code.lang_${this._language}`);
  }

  get lastModification() {
    return this._lastModification.toStringDatetime();
  }
}
