<template>
  <select-autocomplete-field
    :selectedItems.sync="value"
    :items.sync="categories"
    :label="`${$t('recipient.form.category')} *`"
    :rules="rules"
    item-value="key"
    :attach="true"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'recipient-category-selector',
  components: {
    SelectAutocompleteField,
  },
  inject: {
    fetchRecipientCategories: 'fetchRecipientCategories',
  },
  props: {
    categorySelected: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categories: [],
      value: this.categorySelected,
    };
  },
  methods: {
    getRecipientCategories() {
      this.categories = this.fetchRecipientCategories();
    },
  },
  beforeMount() {
    this.getRecipientCategories();
  },
  watch: {
    categorySelected() {
      this.value = this.categorySelected;
    },
    value() {
      this.$emit('update:categorySelected', this.value);
    },
  },
};
</script>
