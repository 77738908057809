const EMAIL_VALIDATION = /^(?![.+\-_])([\w.+-]*)[\w-]@[a-zA-Z0-9]([\w\-.]{0,61})[a-zA-Z0-9]\.([a-zA-Z0-9]{2,})$/i;
const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=(.*\d)|(.*[!"#$%^'()*+´\-./:;<=>?@[\\\]^_`{|}])).{8,}$/i;
const TYPE_FILES_ACCEPT_COMPARE = ['doc', 'txt', 'png', 'jpg', 'jpeg',
  'dot', 'wbk', 'docx', 'docm', 'dotx', 'docb', 'xls', 'xltx', 'xltm', 'xlsb', 'xla',
  'xlam', 'xll', 'xlw', 'ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam',
  'ppsx', 'ppsm', 'sldx', 'sldm', 'key', 'odp', 'pps', 'ods', 'xlr', 'odt', 'odt',
  'pdf', 'rtf', 'tex', 'wks', 'xlsx'];
const MB50 = 52449856;
const MB10 = 10506316;

const KB240 = 240000;
const MB20 = 20000000;

const SIZE_FILE = {
  mb10: MB10,
  mb20: MB20,
  kb240: KB240,
};
const SIZE_FILES = {
  mb50: MB50,
  mb20: MB20,
};

function isEmptyField(field) {
  return !field.length;
}

function isValidEmail(email) {
  return EMAIL_VALIDATION.test(email.trim());
}

function isValidPassword(password) {
  return PASSWORD_VALIDATION.test(password);
}

function isEmailsSame(email, repeatEmail) {
  return email.trim() === repeatEmail.trim();
}

function isPasswordsSame(password, repeatPassword) {
  return password === repeatPassword;
}

function correctTypeFiles(fileName, allowedExtensions = null) {
  const fileExtension = fileName.split(/\.(?=[^.]+$)/)[1];
  if (allowedExtensions) {
    return allowedExtensions.includes(fileExtension);
  }
  return TYPE_FILES_ACCEPT_COMPARE.includes(fileExtension);
}

function correctFileSize(size, quantity) {
  return size < SIZE_FILE[quantity];
}

function correctAllFilesSize(size, quantity) {
  return size < SIZE_FILES[quantity];
}

export default {
  isEmptyField,
  isValidEmail,
  isValidPassword,
  isEmailsSame,
  isPasswordsSame,
  correctTypeFiles,
  correctFileSize,
  correctAllFilesSize,
};
