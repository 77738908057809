// @ts-ignore
import { v4 as uuidv4, validate, version } from 'uuid';
import UuidException from '@/domain/shared/uuid/UuidException';

export default class UuidValueObject {
  private readonly _uuid: string;

  protected constructor(uuid: string) {
    this.isValidOrFail(uuid);
    this._uuid = uuid;
  }

  isValidOrFail(uuid: string): void {
    if (!uuid) {
      throw new UuidException(`${this.constructor.name} should not be empty`, `${this.constructor.name}Exception`);
    }

    if (!UuidValueObject.isValid(uuid)) {
      throw new UuidException(`${this.constructor.name} is an invalid uuid`, `${this.constructor.name}Exception`);
    }
  }

  static isValid(uuid: string): boolean {
    return validate(uuid) && version(uuid) === 4;
  }

  static generate(): UuidValueObject {
    return new this(uuidv4());
  }

  static fromString(id: string): UuidValueObject {
    return new this(id);
  }

  toString(): string {
    return this._uuid;
  }

  isEquals(uuid: UuidValueObject): boolean {
    return this._uuid === uuid._uuid;
  }
}
