import { postManagement } from '@/shared/restActions';

function saveNewRecipientsCategoryCustom(adminId, clientId, recipientCategoryCustom) {
  const url = '/api/v2/recipients-categories';
  const data = {
    name: recipientCategoryCustom,
    adminId,
    clientId,
  };

  return postManagement(url, data);
}

function saveNewRecipientsCustom(adminId, clientId, recipientCategoryCustom) {
  const url = '/api/v2/processing-activities/recipients';
  const data = {
    name: recipientCategoryCustom,
    adminId,
    clientId,
  };

  return postManagement(url, data);
}

function createProcessingActivity(activity) {
  const url = '/api/v2/processing-activities';

  return postManagement(url, activity);
}

export default {
  saveNewRecipientsCategoryCustom,
  saveNewRecipientsCustom,
  createProcessingActivity,
};
