import { TranslateResult } from 'vue-i18n';
import i18n from '@/main';

export default class Translator {
  public translate(key: string): TranslateResult {
    return i18n.t(key);
  }

  public translationExist(key: string): boolean {
    return i18n.te(key);
  }
}
