import RecipientDpaStatusValidationException
  from '@/domain/recipient/recipientDpaStatus/RecipientDpaStatusValidationException';

export default class RecipientDpaStatus {
  _dpaStatus;

  static VALID_DPA_STATUS = [
    'attached',
    'notRequired',
    'requested',
  ];

  constructor(dpaStatus) {
    this.isValid(dpaStatus);
    this._dpaStatus = dpaStatus;
  }

  isValid(dpaStatus) {
    if (!RecipientDpaStatus.VALID_DPA_STATUS.includes(dpaStatus)) {
      throw new RecipientDpaStatusValidationException('Recipient DPA status does not exist');
    }
  }

  static all() {
    return this.VALID_DPA_STATUS.map((dpaStatus) => new this(dpaStatus));
  }

  representedAs(recipientDpaStatusRepresentation) {
    recipientDpaStatusRepresentation.setKey(this._dpaStatus);
    return recipientDpaStatusRepresentation;
  }

  toString() {
    return this._dpaStatus;
  }
}
