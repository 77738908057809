import EventListener from '@/application/eventBus/EventListener';
import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';

export default class ClientSelectedWasChangedEventListener implements EventListener {
  private readonly dataBreachRepository: DataBreachRepository;

  constructor(dataBreachRepository: DataBreachRepository) {
    this.dataBreachRepository = dataBreachRepository;
  }

  public handle(event: ClientSelectedWasChanged) {
    this.dataBreachRepository.reset();
  }
}
