<template>
  <div class="form-steps__list">
    <v-row class="form-steps__list__step">
      <download-template />
      <form-import-users />
    </v-row>
  </div>
</template>

<script>
import DownloadTemplate from '@/components/modules/clientUsers/importUsers/DownloadTemplate.vue';
import FormImportUsers from '@/components/modules/clientUsers/importUsers/FormImportUsers.vue';
import { createImportFile } from '@/application/import/';

export default {
  name: 'import-users-section',
  provide: {
    createImportFile,
  },
  components: {
    DownloadTemplate,
    FormImportUsers,
  },
  data() {
    return {
      usersFileUploaded: [],
    };
  },
};
</script>
