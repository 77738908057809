import EventListener from '@/application/eventBus/EventListener';
import ClientSelectedWasChanged from '@/domain/client/ClientSelectedWasChanged';
import DataBreachHistoricWriteModel
  from '@/domain/dataBreach/historic/DataBreachHistoricWriteModel';

export default class ClientSelectedWasChangedEventListener implements EventListener {
  private readonly dataBreachHistoricWriteModel: DataBreachHistoricWriteModel;

  constructor(dataBreachHistoricWriteModel: DataBreachHistoricWriteModel) {
    this.dataBreachHistoricWriteModel = dataBreachHistoricWriteModel;
  }

  public handle(event: ClientSelectedWasChanged): void {
    this.dataBreachHistoricWriteModel.reset();
  }
}
