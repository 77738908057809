import feature from '@/shared/featureToggle';
import store from '@/store/index';

function hasDepartmentSettingsAccess() {
  return feature.isVisible('developers', '', { adminCompanyId: store.state.admin.adminCompanyId });
}

export default {
  hasDepartmentSettingsAccess,
};
