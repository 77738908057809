const ADD_MODELS = (state, models) => {
  state.models = models;
};

const SAVE_PROCESSING_ACTIVITY = (state, processingActivity) => {
  state.processingActivity = processingActivity;
};

const SAVE_FORM_STATUS = (state, formStatus) => {
  state.formStatus = formStatus;
};

const RESET_PROCESSING_ACTIVITY = (state) => {
  state.processingActivity = {};
};

const RESET_FORM_STATUS = (state) => {
  state.formStatus = {
    step: null,
    args: null,
  };
};

export default {
  ADD_MODELS,
  SAVE_PROCESSING_ACTIVITY,
  SAVE_FORM_STATUS,
  RESET_PROCESSING_ACTIVITY,
  RESET_FORM_STATUS,
};
