import { getManagement, postManagement, putManagement } from '@/shared/restActions';
import UserCache from '@/infrastructure/user/UserCache';
import UserRepository from '@/domain/user/UserRepository';
import EmailValueObject from '@/domain/shared/email/EmailValueObject';
import UserPassword from '@/domain/user/UserPassword';
import UserToken from '@/domain/user/UserToken';
import UserId from '@/domain/user/UserId';

export default class UserRepositoryApi implements UserRepository {
  public async recoverPassword(email: EmailValueObject): Promise<void> {
    try {
      await postManagement(
        '/api/v2/users/password/reset',
        { email: email.toString() },
      );
    } catch (e) {
      console.error(e);
      throw Error('Error recover user password');
    }
  }

  public async changePassword(token: UserToken, password: UserPassword): Promise<void> {
    try {
      await putManagement(
        '/api/v2/users/client/password',
        { password: password.toString(), token: token.toString() },
      );
    } catch (e) {
      console.error(e);
      throw Error('Error change user password');
    }
  }

  public async retrieve(userId: UserId): Promise<void> {
    try {
      const response = await getManagement(`/api/v2/users/client/${userId.toInt()}/login-info`);

      UserCache.saveInfo(response.data);
    } catch (e) {
      console.error('Error retrieving user');
    }
  }
}
