export default ({
  processingActivitiesListsService,
}) => async () => {
  try {
    const response = await processingActivitiesListsService.getRecipientsList();

    return { success: true, data: response };
  } catch (e) {
    return { success: false, data: [] };
  }
};
