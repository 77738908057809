import StringValueObject from '@/domain/shared/strings/StringValueObject';

const MIN_LENGTH = 0;
const MAX_LENGTH = 3000;

export default class TomNoteContent extends StringValueObject {
  constructor(note: string) {
    super(note, MIN_LENGTH, MAX_LENGTH);
  }
}
