export default class AddDataBreachCollaboratorCommand {
  public readonly dataBreachId: string;

  public readonly email: string;

  public readonly name: string;

  public readonly surname: string;

  public readonly message: string;

  constructor(
    dataBreachId: string,
    email: string,
    name: string,
    surname: string,
    message: string,
  ) {
    this.dataBreachId = dataBreachId;
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.message = message;
  }
}
