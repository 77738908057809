<template>
  <v-card-text>
    <v-row
      align="center"
    >
      <v-col
        cols="12"
        md="9"
      >
        <h2 class="font-weight-bold mb-2">
          {{ $t("training.request_access.title") }}
        </h2>
        <p
          v-html="$t('training.request_access.title_info')"
          class="mb-0"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-btn
          rounded
          block
          class="mb-0"
          color="primary"
          :disabled="isRequested"
          @click="request()"
          v-track="'Request Trainings'"
        >
          {{ textTrainingButton }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'activate-training-button',
  inject: {
    user: 'user',
    requestTraining: 'requestTraining',
  },
  props: {
    isRequested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textTrainingButton() {
      if (this.isRequested) {
        return this.$t('training.requested.button');
      }
      return this.$t('training.request.button');
    },
  },
  data() {
    return {
      trainingInfo: {},
    };
  },
  methods: {
    openTraining() {
      window.open(this.trainingInfo.url, '_blank');
    },
    async request() {
      const response = await this.requestTraining.invoke(this.user.clientId);
      if (!response.success) {
        this.$showError('training.request_access');
        return;
      }
      this.$track({
        action: 'Request access trainings',
        eventLaunched: 'Request training',
      });
    },
  },
};
</script>
