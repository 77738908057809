import EventListener from '@/application/eventBus/EventListener';
import EmployeeWasCreated from '@/domain/employee/EmployeeWasCreated';
import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';

export default class EmployeeWasCreatedEventListener implements EventListener {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public handle(employeeWacCreated: EmployeeWasCreated): void {
    this.trainingEmployeeRepository.reset();
  }
}
