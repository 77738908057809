<template>
  <div>
    <div
      class="task-alert__header"
    >
      <p @click="showTask()">
        <v-icon>
          note_add
        </v-icon>
        {{ task.title }}
      </p>
      <v-icon
        class="task-alert__header-icon-show"
        :class="{'active': task.show}"
        @click="showOrHide()"
      >
        add_circle_outline
      </v-icon>
    </div>
    <v-expand-transition>
      <div
        v-show="task.show"
        class="task-alert__content task-alert__upload-document"
      >
        <p class="task-alert__content-expiration">
          <strong>
            {{ $t('tasks.answer_before_label') }} {{ task.expirationDate }}
          </strong>
        </p>
        <div class="task-alert__content-petition">
          <p>
            "{{ task.requestPetition }}"
          </p>
        </div>
        <upload-file
          v-if="!user.isAdmin"
          :filesToUpdated.sync="files"
          :fromTask="true"
          @uploadFiles="uploadFiles"
        />
        <div v-else class="cant-upload-file">
          <v-icon
            color="grey lighten-2"
          >
            cloud_download
          </v-icon>
          <p>
            {{ $t('tasks.admin_cant_upload') }}
          </p>
        </div>
        <div class="task-alert__upload-document-list mt-5">
          <tooltip
            :items="filesToUpload"
            :max-length="40"
            max-width="30%"
          >
            <template slot-scope="props">
              <v-chip
                v-on="props.on"
                close
                small
                color="rgba(96, 157, 152, 0.15)"
                text-color="grey darken-1"
                @click:close="removeFileToUpload( props.item )"
              >
                {{ props.itemName }}
              </v-chip>
            </template>
          </tooltip>
        </div>
        <v-row justify="center" align="center">
          <v-col cols="6" md="6" class="text-left">
            <change-department-btn
              v-if="user.isAdmin || user.isSupervisor"
              :task.sync="task"
            />
          </v-col>
          <v-col cols="6" md="6" class="text-right">
            <v-btn
              rounded
              color="primary"
              :disabled="isDisabled()"
              @click="$emit('save', task, filesToUpload)"
            >
              {{ $t('tasks.send_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import UploadFile from '@/components/base/form/UploadFile.vue';

import uploadFilesValidationService from '@/services/validations/uploadFilesValidation';
import ChangeDepartmentBtn
  from '@/components/modules/homePage/taskSection/ChangeDepartmentButton.vue';
import Tooltip from '@/components/base/section/Tooltip.vue';

export default {
  name: 'documentation_request-task',
  inject: {
    user: 'user',
  },
  components: {
    Tooltip,
    ChangeDepartmentBtn,
    UploadFile,
  },
  props: {
    task: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      filesToUpload: [],
      files: [],
    };
  },
  methods: {
    getNamesFile(files) {
      const nameFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        nameFiles.push(`${files[i].name}`);
        this.removeFileToUpload(files[i]);
      }
      return nameFiles.join(', ');
    },
    async uploadFiles(files) {
      this.files = [];
      if (!files.length) {
        return;
      }
      this.filesToUpload = [...this.filesToUpload, ...files];
      this.filesToUpload = this.filesToUpload.reduce((unique, currentFile) => {
        const unic = unique.find((file) => file.name === currentFile.name);
        if (!unic) {
          return unique.concat([currentFile]);
        }
        return unique;
      }, []);

      this.$showLoading();
      const validationFiles = await uploadFilesValidationService.validate(this.filesToUpload);
      this.$hideLoading();

      if (validationFiles.success) {
        return;
      }

      if (validationFiles.error && validationFiles.error.type === 'maxFilesSizes') {
        this.getNamesFile(files);
        this.$showError('clientFolder.error.no_correct_files_size');
        return;
      }

      const textAlert = [];

      if (validationFiles.error.data.filesWrongType.length) {
        textAlert.push('clientFolder.error.no_correct_file_type');
        textAlert.push(
          this.getNamesFile(validationFiles.error.data.filesWrongType),
        );
      }

      if (validationFiles.error.data.filesWrongSize.length) {
        textAlert.push('clientFolder.error.no_correct_file_size');
        textAlert.push(
          this.getNamesFile(validationFiles.error.data.filesWrongSize),
        );
      }

      this.$showError(textAlert);
    },
    isDisabled() {
      return this.filesToUpload.length < 1 || this.filesToUpload.length > 500;
    },
    hideTasksAndShowOne(taskId) {
      this.filesToUpload = [];
      this.task.show = this.task.taskId === taskId;
    },
    showTask() {
      this.$eventHub.$emit('hideTasksAndShowOne', this.task.taskId);
    },
    showOrHide() {
      if (this.task.show) {
        this.task.show = !this.task.show;
        this.filesToUpload = [];
        return;
      }
      this.$eventHub.$emit('hideTasksAndShowOne', this.task.taskId);
    },
    removeFileToUpload(file) {
      const index = this.filesToUpload.findIndex((item) => item.name === file.name);
      this.filesToUpload.splice(index, 1);
    },
  },
  beforeMount() {
    this.$eventHub.$on('hideTasksAndShowOne', this.hideTasksAndShowOne);
  },
};
</script>
