<template>
  <div>
    <v-tooltip
      v-for="item in items"
      :key="item.id"
      :disabled="!isTooLongName(item.name)"
      bottom
    >
      <template v-slot:activator="{ on }">
        <slot
          :item="item"
          :on="on"
          :itemName="shortenName(item.name)"
        />
      </template>
      <span>{{ item.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
const MAX_NAME_DOCUMENT_LENGTH = 20;

export default {
  name: 'tooltip',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    maxLength: {
      type: Number,
      default: MAX_NAME_DOCUMENT_LENGTH,
    },
  },
  methods: {
    isTooLongName(name) {
      return name.length > MAX_NAME_DOCUMENT_LENGTH;
    },
    shortenName(name) {
      if (!this.isTooLongName(name)) {
        return name;
      }
      return `${name.substr(0, MAX_NAME_DOCUMENT_LENGTH - 3)}...`;
    },
  },
};
</script>
