import { deleteMethodManagement, getManagement } from '@/shared/restActions';
import DocumentDataRecipientRepository from '@/domain/documentData/recipient/DocumentDataRecipientRepository';
import DocumentDataRecipient from '@/domain/documentData/recipient/DocumentDataRecipient';
import DocumentDataId from '@/domain/documentData/DocumentDataId';
import ClientId from '@/domain/client/ClientId';
import DocumentDataName from '@/domain/documentData/DocumentDataName';
import DocumentDataLastModification from '@/domain/documentData/DocumentDataLastModification';

export default class DocumentDataRecipientRepositoryApi extends DocumentDataRecipientRepository {
  async ofRecipient(recipientId, clientId) {
    try {
      const documentDataRecipientRaw = await this._getDocumentDataRecipientFromApi(recipientId);
      return this._buildDocumentDataRecipient(documentDataRecipientRaw, clientId);
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching recipient documentData');
    }
  }

  async delete(documentDataId) {
    try {
      await deleteMethodManagement(`/api/v2/contracts/${documentDataId.toString()}/recipient`);
    } catch (e) {
      console.error(e);
      throw new Error('Error deleting document data of recipient');
    }
  }

  async _getDocumentDataRecipientFromApi(recipientId) {
    const response = await getManagement(`/api/v2/recipients/${recipientId.toString()}/contracts`);
    return response.data;
  }

  _buildDocumentDataRecipient(documentsDataRaw, clientId) {
    const documentDataList = [];
    documentsDataRaw.forEach((documentDataRaw) => {
      const documentData = new DocumentDataRecipient(
        DocumentDataId.fromString(documentDataRaw.id),
        ClientId.fromInt(clientId),
        DocumentDataName.fromString(documentDataRaw.name),
        new DocumentDataLastModification(documentDataRaw.last_modification),
      );

      documentDataList.push(documentData);
    });
    return documentDataList;
  }
}
