import EventListener from '@/application/eventBus/EventListener';
import DepartmentWasCreated from '@/domain/department/DepartmentWasCreated';
import SaveDepartmentCommandHandler
  from '@/application/department/listDepartment/command/saveDepartment/SaveDepartmentCommandHandler';
import SaveDepartmentCommand
  from '@/application/department/listDepartment/command/saveDepartment/SaveDepartmentCommand';

export default class DepartmentWasCreatedEventListener implements EventListener {
  private readonly saveDepartment: SaveDepartmentCommandHandler;

  constructor(saveDepartment: SaveDepartmentCommandHandler) {
    this.saveDepartment = saveDepartment;
  }

  public handle(event: DepartmentWasCreated) {
    this.saveDepartment.invoke(new SaveDepartmentCommand(event.getDepartmentId()));
  }
}
