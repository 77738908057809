import FetchLanguagesOfClient
  from '@/application/language/fetchLanguagesOfClient/FetchLanguagesOfClient';
import LanguageRepositoryApi from '@/infrastructure/language/LanguageRepositoryApi';
import Translator from '@/infrastructure/Translator';

export default {
  fetchLanguagesOfClient: new FetchLanguagesOfClient(
    new LanguageRepositoryApi(),
    new Translator(),
  ),
};
