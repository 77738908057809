const addTasks = ({ commit }, tasks) => {
  commit('ADD_TASKS', tasks);
};

const resetTasks = ({ commit }) => {
  commit('RESET_TASKS');
};

const updateTaskDepartment = ({ commit }, payload) => {
  commit('UPDATE_TASK_DEPARTMENT', payload);
};

export default {
  addTasks,
  resetTasks,
  updateTaskDepartment,
};
