import TrainingStatus from '@/domain/training/TrainingStatus';
import Language from '@/domain/language/Language';
import TrainingRepresentation from '@/domain/training/TrainingRepresentation';

export default class Training {
  private status: TrainingStatus | null = null;

  private readonly _availableLanguages: Language[];

  private userLanguage: Language | null = null;

  constructor(availableLanguages: Language[]) {
    this._availableLanguages = availableLanguages;
  }

  setStatus(status: TrainingStatus | null) {
    this.status = status;
  }

  setUserLanguage(userLanguage: Language | null) {
    this.userLanguage = userLanguage;
  }

  get availableLanguages(): Language[] {
    return this._availableLanguages;
  }

  public representedAs(trainingRepresentation: TrainingRepresentation): TrainingRepresentation {
    trainingRepresentation
      .setAvailableLanguages(this._availableLanguages)
      .setStatus(this.status)
      .setUserLanguage(this.userLanguage);

    return trainingRepresentation;
  }
}
