<template>
  <v-row class="activity-step activity-step__category">
    <v-col cols="12" class="text-center">
      <h2>
        {{ $t('processing_activities.step_recipients_category.where_data_share') }}
      </h2>
    </v-col>
    <v-col
      ref="scrollCheckboxButtons"
      cols="12"
      class="activity-step__category-selection"
    >
      <div>
        <checkbox-button-field
          :value.sync="recipientsCategory"
          :items.sync="recipientsCategoryList"
          :cols="4"
          @update:value="trackSelections"
        />
      </div>
    </v-col>
    <v-col cols="12">
      <input-field
        :value.sync="newRecipientCategory"
        :label="$t('processing_activities.step_recipients_category.add_new_recipient_category')"
        :disabled.sync="disabledInput"
        @keyUpEnter="saveNewRecipientsCategory"
      />
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        color="primary"
        :disabled="!recipientsCategory.length"
        rounded
        @click="categoryGoNextStep()"
      >
        {{ $t('processing_activities.step_recipients_category.continue') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import CheckboxButtonField from '../../../base/form/CheckboxButtonField.vue';
import InputField from '../../../base/form/InputField.vue';

export default {
  name: 'recipients-category',
  components: {
    CheckboxButtonField,
    InputField,
  },
  props: {
    recipientsCategory: {
      type: Array,
      default: () => [],
    },
    recipientsCategoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newRecipientCategory: '',
      disabledInput: false,
      recipientHasBeenSaved: false,
      selectedCategories: [],
    };
  },
  methods: {
    saveNewRecipientsCategory() {
      if (this.newRecipientCategory !== '' && !this.disabledInput) {
        this.recipientHasBeenSaved = true;
        this.disabledInput = true;
        this.$emit('saveNewRecipientsCategory', this.newRecipientCategory);
      }
    },
    categoryGoNextStep() {
      this.$emit('goNextStep');
      this.$track({
        action: 'Create processing activity -- select category -- continue',
        category: this.recipientsCategory,
      });
    },
    trackSelections(categories) {
      let category = categories.filter((value) => !this.selectedCategories.includes(value));
      if (category.length > 0) {
        this.$track({
          action: 'Select recipient category',
          category: category[0],
        });
        this.selectedCategories = [...categories];
        return;
      }
      category = this.selectedCategories.filter((value) => !categories.includes(value));
      this.selectedCategories = [...categories];
      this.$track({
        action: 'Unselect recipient category',
        category: category[0],
      });
    },
  },
  watch: {
    recipientsCategoryList() {
      if (!this.recipientHasBeenSaved) {
        return;
      }
      this.$vuetify.goTo(600, { container: this.$refs.scrollCheckboxButtons });
      this.newRecipientCategory = '';

      this.disabledInput = false;
      this.recipientHasBeenSaved = false;
    },
  },
};
</script>
