import AbstractProcessingActivityRepresentation
  from '@/domain/recipient/processingActivity/AbstractProcessingActivityRepresentation';

const STATUS = {
  active: 1,
  draft: 2,
  cancelled: 3,
};

export default class ProcessingActivitiesRepresentation
  extends AbstractProcessingActivityRepresentation {
  _translator;

  _normalizationService;

  _processingDates;

  _completeName = null;

  _nameNormalized = null;

  _lastModificationString = null;

  constructor(translator, normalizationService, processingDates) {
    super();
    this._translator = translator;
    this._normalizationService = normalizationService;
    this._processingDates = processingDates;
  }

  get id() {
    return this._id;
  }

  get name() {
    if (!this._completeName) {
      this._completeName = (this._name !== '') ? this._name : `${this._translator.translate('table.no_name')}_${this._id}`;
    }

    return this._completeName;
  }

  get nameNormalized() {
    if (!this._nameNormalized) {
      this._nameNormalized = this._normalizationService.normalizeString(this.name);
    }

    return this._nameNormalized;
  }

  get language() {
    return this._language;
  }

  get languageTranslation() {
    return this._translator.translate(`languages.by_code.lang_${this._language}`);
  }

  get statusTranslation() {
    if (this.isStatusActive()) {
      return this._translator.translate('processingActivities.status_name.active');
    }

    if (this.isStatusDraft()) {
      return this._translator.translate('processingActivities.status_name.draft');
    }

    return this._translator.translate('processingActivities.status_name.cancelled');
  }

  get lastModificationString() {
    if (!this._lastModificationString) {
      return this._processingDates.getStringDateTimeFromDate(this._lastModification);
    }

    return this._lastModificationString;
  }

  get country() {
    return this._translator.translate(`countries.by_id.country_${this._countryId}`);
  }

  isStatusDraft() {
    return this._status === STATUS.draft;
  }

  isStatusActive() {
    return this._status === STATUS.active;
  }
}
