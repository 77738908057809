<template>
  <v-row class="text-center">
    <logo-img
      :logoUrl="urlLogo"
      :height="heightLogo"
      :position="positionLogo"
      :classLogo="classLogo"
      titleTag="h1"
    />
    <v-col v-if="isSuccessFeedback" cols="12">
      <h1 class="grey--text text--darken-3">
        <strong>{{ $t('register.verified.email') }}</strong>
      </h1>
      <p class="mb-5">{{ $t('register.verified.enter_platform') }}</p>
      <v-btn
        color="primary"
        rounded
        :to="{ name: 'login' }" class="mt-10"
        v-track="'go to login_emailvalidation'"
      >
        {{ $t('register.verified.button_login') }}
      </v-btn>
    </v-col>
    <v-col v-else cols="12">
      <h1 class="grey--text text--darken-3">
        <strong>{{ $t('register.form.error.email_not_verified') }}</strong>
      </h1>
      <p class="mb-5">{{ $t('register.form.error.email_not_verified_info') }}</p>
    </v-col>
  </v-row>
</template>

<script>
import { validateClientUser } from '@/infrastructure/application/clientUser';
import ValidateClientUserCommand
  from '@/application/clientUser/validateClientUser/ValidateClientUserCommand';
import LogoImg from '@/components/base/icons/LogoImg.vue';

export default {
  name: 'user-validated-container',
  inject: {
    urlLogo: 'urlLogo',
    heightLogo: 'heightLogo',
    positionLogo: 'positionLogo',
    classLogo: 'classLogo',
  },
  components: { LogoImg },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSuccessFeedback: null,
    };
  },
  methods: {
    async validateUserByToken() {
      const response = await validateClientUser
        .invoke(new ValidateClientUserCommand(this.token));
      this.isSuccessFeedback = response.success;
      this.$track({ action: 'Validated user', result: this.isSuccessFeedback });
    },
  },
  beforeMount() {
    this.validateUserByToken();
  },
};
</script>
