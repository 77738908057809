const ADD_EQUIPMENTS = (state, equipments) => {
  state.equipments = equipments;
};

const RESET_EQUIPMENTS = (state) => {
  state.equipments = [];
};

export default {
  ADD_EQUIPMENTS,
  RESET_EQUIPMENTS,
};
