import FetchTrainingEmployees
  from '@/application/training/employee/fetchTrainingEmployee/FetchTrainingEmployees';
import TrainingEmployeeRepositoryApi
  from '@/infrastructure/training/employee/TrainingEmployeeRepositoryApi';
import ResetTrainingEmployees
  from '@/application/training/employee/resetTrainingEmployees/ResetTrainingEmployees';

export default {};

export const fetchTrainingEmployees = new FetchTrainingEmployees(
  new TrainingEmployeeRepositoryApi(),
);

export const resetTrainingEmployees = new ResetTrainingEmployees(
  new TrainingEmployeeRepositoryApi(),
);
