import TomRepository from '@/domain/tom/TomRepository';
import ChangeTomStatusCommand from '@/application/tom/changeTomStatus/ChangeTomStatusCommand';
import TomStatus from '@/domain/tom/TomStatus';
import TomId from '@/domain/tom/TomId';

export default class ChangeTomStatusCommandHandler {
  private readonly tomRepository: TomRepository;

  constructor(tomRepository: TomRepository) {
    this.tomRepository = tomRepository;
  }

  public async invoke(command: ChangeTomStatusCommand): Promise<any> {
    try {
      const tom = await this.tomRepository.retrieveFromCache(TomId.fromString(command.tomId));
      tom.changeStatus(TomStatus.fromString(command.status));
      await this.tomRepository.changeStatus(tom);

      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
