import DepartmentWriteModel from '@/domain/department/listDepartment/DepartmentWriteModel';
import SaveDepartmentCommand
  from '@/application/department/listDepartment/command/saveDepartment/SaveDepartmentCommand';
import DepartmentRepository from '@/domain/department/DepartmentRepository';
import UserCache from '@/infrastructure/user/UserCache';
import DepartmentId from '@/domain/department/DepartmentId';
import Department from '@/domain/department/Department';
import DepartmentProjection from '@/domain/department/listDepartment/DepartmentProjection';

export default class SaveDepartmentCommandHandler {
  private readonly departmentRepository: DepartmentRepository;

  private readonly departmentWriteModel: DepartmentWriteModel;

  private readonly userCache: UserCache;

  constructor(
    departmentRepository: DepartmentRepository,
    departmentWriteModel: DepartmentWriteModel,
    userCache: UserCache,
  ) {
    this.departmentRepository = departmentRepository;
    this.departmentWriteModel = departmentWriteModel;
    this.userCache = userCache;
  }

  public async invoke(command: SaveDepartmentCommand) {
    try {
      const department: Department = await this.departmentRepository
        .retrieve(DepartmentId.fromString(command.departmentId));

      const departmentProjection: DepartmentProjection = new DepartmentProjection(
        department.getId().toString(),
        department.getDepartmentNameValueInPriorityLanguage(this.userCache.language).toString(),
        null,
      );

      this.departmentWriteModel.save(departmentProjection);
    } catch (e) {
      this.departmentWriteModel.reset();
    }
  }
}
