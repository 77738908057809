<template>
  <v-dialog v-model="showModal" persistent max-width="60%" scrollable>
    <v-card>
      <v-card-title>
        <h2>
          {{ $t('headquarters.create.title') }}
        </h2>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:show-modal', false)"
        >
        <v-icon>
          close
        </v-icon>
        </v-btn>
        <p>
          {{ $t('headquarters.form.explanation') }}
        </p>
      </v-card-title>
      <v-card-text id="form-headquarter">
        <v-form
          ref="headquarterForm"
        >
          <v-row>
            <v-col cols="12">
              <alert-fixed
                v-if="showAlert"
                type="error"
                :text="$t('form.review')"
              />
            </v-col>
             <v-col cols="12">
              <input-field
                :value.sync="headquarter.name"
                :label="`${$t('headquarters.form.name')} *`"
                :rules="[validation.required, validation.min, validation.max]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12">
              <input-field
                :value.sync="headquarter.address"
                :label="`${$t('headquarters.form.address')} *`"
                :rules="[validation.required, validation.min, validation.max_500]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <country-selector
                :item.sync="headquarter.countryCode"
                :label="`${$t('headquarters.form.country')} *`"
                :rules="[validation.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <input-field
                :value.sync="headquarter.locality"
                :label="`${$t('headquarters.form.locality')} *`"
                :rules="[validation.required, validation.min, validation.max]"
                :validateOnBlur="true"
              />
            </v-col>
            <v-col cols="12">
              <security-measures-selector
                :selectedValues.sync="headquarter.securityMeasures"
                :label="$t('headquarters.form.security_measures')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('update:show-modal', false)"
        >
          {{ $t('headquarters.form.cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="primary"
          @click="save()"
        >
          {{ $t('headquarters.form.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import SecurityMeasuresSelector from '@/components/base/form/securityMeasures/SecurityMeasuresSelector.vue';
import InputField from '@/components/base/form/InputField.vue';
import CountrySelector from '@/components/base/form/country/CountrySelector.vue';
import AlertFixed from '@/components/base/feedback/AlertFixed.vue';

export default {
  name: 'dialog-form-headquarter',
  components: {
    AlertFixed,
    SecurityMeasuresSelector,
    CountrySelector,
    InputField,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    dataHeadquarter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headquarter: {
        id: null,
        name: '',
        address: '',
        countryCode: '',
        locality: '',
        securityMeasures: [],
      },
      validation: {
        required: (v) => !!v || this.$t('form.validations.required_field'),
        min: (v) => v.trim().length >= 3 || this.$t('form.validations.min_length', { length: 3 }),
        max: (v) => v.trim().length <= 200 || this.$t('form.validations.max_length', { length: 200 }),
        max_500: (v) => v.trim().length <= 500 || this.$t('form.validations.max_length', { length: 500 }),
      },
      showAlert: false,
    };
  },
  methods: {
    save() {
      if (!this.$refs.headquarterForm.validate()) {
        this.showAlert = true;
        return;
      }
      this.$emit('saveHeadquarters', this.headquarter);
    },
    fillHeadquarterWithData() {
      Object.keys(this.headquarter).forEach((key) => {
        this.headquarter[key] = this.dataHeadquarter[key];
      });
    },
  },
  mounted() {
    if (this.dataHeadquarter && Object.keys(this.dataHeadquarter).length) {
      this.fillHeadquarterWithData();
    }
  },
};
</script>
