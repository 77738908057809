export default class RegisterClientUserCommand {
  public readonly name: string;

  public readonly surname: string;

  public readonly email: string;

  public readonly password: string;

  public readonly language: string;

  constructor(
    name: string,
    surname: string,
    email: string,
    password: string,
    language: string,
  ) {
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.password = password;
    this.language = language;
  }
}
