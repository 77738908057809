import EmployeeId from '@/domain/employee/EmployeeId';
import EmployeeWasDeleted from '@/domain/employee/EmployeeWasDeleted';
import EmployeeRepository from '@/domain/employee/EmployeeRepository';
import EventBus from '@/application/eventBus/EventBus';

export default class RemoveEmployees {
  private readonly employeeRepository: EmployeeRepository;

  private readonly eventBus: EventBus;

  constructor(employeeRepository: EmployeeRepository, eventBus: EventBus) {
    this.employeeRepository = employeeRepository;
    this.eventBus = eventBus;
  }

  public async invoke(employeeId: string): Promise<object> {
    try {
      const employeeIdToDelete: EmployeeId = EmployeeId.fromString(employeeId);
      await this.employeeRepository.delete(employeeIdToDelete);
      this.eventBus.handle(EmployeeWasDeleted.fromEmployeeId(employeeIdToDelete));
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
