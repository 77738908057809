<template>
  <legal-documents-container />
</template>
<script>
import LegalDocumentsContainer from '@/components/containers/LegalDocumentsContainer.vue';

export default {
  name: 'legal-documents',
  components: {
    LegalDocumentsContainer,
  },
};
</script>
