export default class Task {
  taskId;

  adminId;

  clientId;

  createdBy;

  completionDate;

  title;

  department;

  expirationDate;

  taskType;

  critical;

  requestPetition;

  status;

  creationDate;

  constructor(
    taskId,
    title,
    department,
    expirationDate,
    taskType,
    critical,
    requestPetition,
    status,
    creationDate,
    completionDate,
    adminId,
    clientId,
    createdBy,
  ) {
    this.taskId = taskId;
    this.title = title;
    this.department = department;
    this.expirationDate = expirationDate;
    this.taskType = taskType;
    this.critical = critical;
    this.requestPetition = requestPetition;
    this.status = status;
    this.creationDate = creationDate;
    this.completionDate = completionDate;
    this.adminId = adminId;
    this.clientId = clientId;
    this.createdBy = createdBy;
  }

  representedAs(taskRepresentation) {
    taskRepresentation.setTaskId(this.taskId);
    taskRepresentation.setTitle(this.title);
    taskRepresentation.setDepartment(this.department);
    taskRepresentation.setExpirationDate(this.expirationDate);
    taskRepresentation.setTaskType(this.taskType);
    taskRepresentation.setCritical(this.critical);
    taskRepresentation.setRequestPetition(this.requestPetition);
    taskRepresentation.setStatus(this.status);
    taskRepresentation.setCreationDate(this.creationDate);
    taskRepresentation.setCompletationDate(this.completionDate);
    taskRepresentation.setAdminId(this.adminId);
    taskRepresentation.setClientId(this.clientId);
    taskRepresentation.setCreatedBy(this.createdBy);

    return taskRepresentation;
  }

  updateDepartment(department) {
    this.department = department;
  }
}
