<template>
  <div>
    <v-btn
      class="mb-4"
      color="primary"
      rounded
      v-track="'Continue DataBreach'"
      @click="goToEditDataBreach()"
    >
      {{ $t("data_breach.report_in_progress_button") }}
    </v-btn>
    <dialog-form-data-breach
      v-if="showForm"
      :showModal.sync="showForm"
      :dataBreachToEdit="dataBreach"
      @saveDataBreach="updateDataBreach"
      @generateReport="updateAndGenerateReportDataBreach"
      @update:showModal="closeModalAndRedirect()"
    >
      <delete-data-breach
        :dataBreachId="dataBreachId"
        @hideFormModal="closeModalAndRedirect()"
      />
    </dialog-form-data-breach>
  </div>
</template>

<script>
import DeleteDataBreach from '@/components/modules/dataBreach/notify/DeleteDataBreach.vue';
import DialogFormDataBreach from '@/components/modules/dataBreach/notify/form/DialogFormDataBreach.vue';

const TRACK = {
  UPDATE_DATA_BREACH: 'Edit data breach -- finish',
  UPDATE_DATA_BREACH_AND_GENERATE_REPORT: 'Edit data breach and generate report -- finish',
};
export default {
  name: 'edit-data-breach',
  inject: {
    fetchDataBreach: 'fetchDataBreach',
    editDataBreach: 'editDataBreach',
    editDataBreachWithReportWrapper: 'editDataBreachWithReportWrapper',
  },
  components: {
    DeleteDataBreach,
    DialogFormDataBreach,
  },
  props: {
    dataBreachId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showForm: false,
      dataBreach: {},
    };
  },
  methods: {
    goToEditDataBreach() {
      this.$router.push({ name: 'data-breach-notify', params: { dataBreachId: this.dataBreachId } });
    },
    async getDataBreach() {
      this.$showLoading();
      const response = await this.fetchDataBreach.invoke(this.dataBreachId);
      this.$hideLoading();
      if (!response.success) {
        this.$showError('data_breach.errorPage.error_load_form');
        return;
      }
      this.dataBreach = response.dataBreach;
      this.showForm = true;
    },
    async updateDataBreach(dataBreach) {
      this.$showLoading();
      const response = await this.editDataBreach.invoke(dataBreach);
      this.$track({ action: TRACK.UPDATE_DATA_BREACH, result: response.success });
      this.$hideLoading();

      if (!response.success) {
        this.$showError('data_breach.errorPage.error_save_form');
        return;
      }

      this.$showSuccess('data_breach.form.alert_message_form_saved_success');
      this.closeModalAndRedirect();
    },
    async updateAndGenerateReportDataBreach(dataBreach) {
      this.$showLoading();
      const response = await this.editDataBreachWithReportWrapper.invoke(dataBreach);
      this.$track({
        action: TRACK.UPDATE_DATA_BREACH_AND_GENERATE_REPORT,
        result: response.success,
      });
      this.$hideLoading();

      if (!response.success) {
        this.$showError('data_breach.errorPage.error_generate_report');
        return;
      }

      this.$showSuccess('data_breach.form.alert_message_form_generate_report_success');
      this.closeModalAndRedirect();

      this.$eventHub.$emit('dataBreachWasUpdatedAndReportWasCreated');
    },
    closeModalAndRedirect() {
      this.showForm = false;
      this.$router.push({ name: 'data-breach-notify' });
    },
    openFormEditIfRouteRequestIt() {
      if (this.dataBreachId === this.$route.params.dataBreachId) {
        this.getDataBreach();
        return;
      }
      this.showForm = false;
    },
  },
  beforeMount() {
    this.openFormEditIfRouteRequestIt();
    this.$watch(
      () => this.$route.params, (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.openFormEditIfRouteRequestIt();
        }
      },
    );
  },
};
</script>
