export default class Equipment {
  _id;

  _name;

  _type;

  _location;

  _securityMeasures;

  _clientId;

  constructor(
    id,
    clientId,
    name,
    type,
  ) {
    this._id = id;
    this._name = name;
    this._type = type;
    this._clientId = clientId;
  }

  addSecurityMeasures(securityMeasures) {
    this._securityMeasures = securityMeasures;
  }

  addLocation(location) {
    this._location = location;
  }

  getId() {
    return this._id;
  }

  representedAs(equipmentRepresentation) {
    equipmentRepresentation.setId(this._id);
    equipmentRepresentation.setName(this._name);
    equipmentRepresentation.setType(this._type);
    equipmentRepresentation.setLocation(this._location);
    equipmentRepresentation.setSecurityMeasures(this._securityMeasures);
    equipmentRepresentation.setClientId(this._clientId);

    return equipmentRepresentation;
  }

  update(equipmentToUpdateRepresentation) {
    this._name = equipmentToUpdateRepresentation.name;
    this._type = equipmentToUpdateRepresentation.type;
    this._location = equipmentToUpdateRepresentation.location;
    this._securityMeasures = equipmentToUpdateRepresentation.securityMeasures;
  }
}
