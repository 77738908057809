<template>
  <settings-container />
</template>
<script>
import SettingsContainer from '@/components/containers/SettingsContainer.vue';

export default {
  name: 'settings',
  components: {
    SettingsContainer,
  },
};
</script>
