import UuidValueObject from '@/domain/shared/uuid/UuidValueObject';
import StringValueObject from '@/domain/shared/strings/StringValueObject';

export default class OktaLoginCredentials implements LoginCredentials {
  private readonly _code: StringValueObject;

  private readonly _oktaId: UuidValueObject;

  constructor(code: StringValueObject, oktaId: UuidValueObject) {
    this._code = code;
    this._oktaId = oktaId;
  }

  get code(): string {
    return this._code.toString();
  }

  get oktaId(): string {
    return this._oktaId.toString();
  }
}
