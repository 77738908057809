export default class RecipientWasCreated {
  _recipient;

  _ocurredOn;

  constructor(recipient) {
    this._ocurredOn = new Date();
    this._recipient = recipient;
  }

  getRecipient() {
    return this._recipient;
  }
}
