import {
  removeCommonHeaders,
} from '@/shared/restActions';

function logout() {
  localStorage.removeItem('user-token');
  localStorage.removeItem('userdesk-info');
  removeCommonHeaders();
}

export default {
  logout,
};
