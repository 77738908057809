<template>
  <v-row>
    <v-col cols="12">
      <title-section
        :title="$t('clientFolder.title')"
        :subtitle="$t('clientFolder.subtitle')"
      />
    </v-col>
    <v-col cols="12" class="mb-10">
      <upload-file
        :filesToUpdated.sync="filesToUpdated"
        @uploadFiles="uploadFiles"
        @startCatchFiles="startCatchFiles"
      />
    </v-col>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="search"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12">
      <default-table
        :items.sync="filesList"
        :headers="headers"
        :search.sync="search"
        :actions="['download', 'delete']"
        sortBy="title"
        @downloadDocument="downloadFile"
        @delete="deleteFile"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

import filesUseCases from '@/usecases/clientFolder';

import TitleSection from '../base/section/TitleSection.vue';
import UploadFile from '../base/form/UploadFile.vue';
import DefaultTable from '../base/tables/DefaultTable.vue';
import InputSearch from '../base/form/InputSearch.vue';

export default {
  name: 'client-folder-container',
  components: {
    UploadFile,
    DefaultTable,
    InputSearch,
    TitleSection,
  },
  computed: {
    ...mapGetters('user', {
      userIsAdmin: 'isAdmin',
      clientId: 'companyId',
    }),
    headers() {
      return [
        {
          text: this.$t('clientFolder.name_doc_table'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: '60%',
        },
        {
          text: this.$t('clientFolder.last_modification'),
          align: 'start',
          sortable: true,
          value: 'LastModified',
          width: '30%',
        },
        {
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ];
    },
  },
  data() {
    return {
      search: '',
      filesList: [],
      filesToUpdated: [],
    };
  },
  methods: {
    filterDate(date) {
      const auxDate = new Date(date);
      return `${auxDate.toLocaleDateString('en-GB')}  -  ${auxDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    },
    async getFiles() {
      const response = await filesUseCases.fetchFilesList(this.clientId, this.userIsAdmin);
      if (response.success) {
        this.filesList = response.data;
      } else {
        this.$showError('clientFolder.error.error_page_load');
      }
    },
    async deleteFile(file) {
      this.$showLoading();

      const response = await filesUseCases.deleteFile(this.clientId, file.id, this.userIsAdmin);

      if (response.success) {
        this.$showSuccess('clientFolder.success.success_deleted');
        await this.getFiles();
      } else {
        this.$showError('clientFolder.error.something_wrong_delete');
      }

      this.$hideLoading();
    },
    async downloadFile(file) {
      this.$showLoading();
      const response = await filesUseCases.downloadFile(this.clientId, file.id, this.userIsAdmin);

      if (!response.success) {
        this.$showError('clientFolder.error.error_download_document');
      }
      this.$hideLoading();
    },
    startCatchFiles() {
      this.$showLoading();
    },
    async uploadFiles(files) {
      this.filesToUpdated = [];
      if (!files.length) {
        return;
      }

      this.$showLoading();
      const response = await filesUseCases.uploadFile(this.clientId, files);
      this.$hideLoading();

      if (response.success) {
        this.$showSuccess('clientFolder.success.success_upload');
        await this.getFiles();
        return;
      }

      if (response.error.type === 'maxFilesSizes') {
        this.$showError('clientFolder.error.no_correct_files_size');
        return;
      }
      if (response.error.type === 'apiFailure') {
        this.$showError('clientFolder.error.something_wrong_upload');
        return;
      }

      const textError = [];

      if (response.error.data.filesWrongType.length) {
        textError.push('clientFolder.error.no_correct_file_type');
        textError.push(
          this.getNamesFile(response.error.data.filesWrongType),
        );
      }

      if (response.error.data.filesWrongSize.length) {
        textError.push('clientFolder.error.no_correct_file_size');
        textError.push(
          this.getNamesFile(response.error.data.filesWrongSize),
        );
      }

      this.$showError(textError);
    },
    getNamesFile(files) {
      const nameFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        nameFiles.push(`${files[i].name}`);
      }
      return nameFiles.join(', ');
    },
  },
  async beforeMount() {
    this.$showLoading();

    await this.getFiles();

    this.$hideLoading();
  },
};
</script>
