<template>
  <v-row>
    <v-col cols="6">
      <input-search
        :value.sync="search"
        :label="$t('toms.search_bar')"
      />
    </v-col>
    <v-col cols="12">
      <list-tom
        v-if="isCurrentTab"
        :search="search"
        statusFilter="pendingToImplement"
        noDataText="toms.pending_toms.empty"
      />
    </v-col>
  </v-row>
</template>

<script>

import InputSearch from '@/components/base/form/InputSearch.vue';
import ListTom from '@/components/modules/tom/ListTom.vue';

export default {
  name: 'pending-to-implement-tom-section',
  components: {
    ListTom,
    InputSearch,
  },
  props: {
    isCurrentTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>
