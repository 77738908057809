import EventListener from '@/application/eventBus/EventListener';
import TrainingEmployeeRepository
  from '@/domain/training/trainingEmployee/TrainingEmployeeRepository';
import EmployeeWasUpdated from '@/domain/employee/EmployeeWasUpdated';

export default class EmployeeWasUpdatedEventListener implements EventListener {
  private readonly trainingEmployeeRepository: TrainingEmployeeRepository;

  constructor(trainingEmployeeRepository: TrainingEmployeeRepository) {
    this.trainingEmployeeRepository = trainingEmployeeRepository;
  }

  public handle(employeeWasUpdated: EmployeeWasUpdated): void {
    this.trainingEmployeeRepository.reset();
  }
}
