import fetchRecipientsCategoryBuilder from '@/usecases/questionnaires/processingActivities/fetchRecipientsCategory';
import saveNewRecipientsCategoryCustomBuilder from '@/usecases/questionnaires/processingActivities/saveNewRecipientsCategoryCustom';
import fetchListCountriesBuilder from '@/usecases/questionnaires/processingActivities/fetchListCountries';
import fetchRecipientsBuilder from '@/usecases/questionnaires/processingActivities/fetchRecipients';
import saveNewRecipientsCustomBuilder from '@/usecases/questionnaires/processingActivities/saveNewRecipientsCustom';
import saveProcessingActivityBuilder from '@/usecases/questionnaires/processingActivities/saveProcessingActivity';
import processingActivitiesRepository from '@/repositories/questionnaires/processingActivities';
import countryRepository from '@/repositories/country';
import processingActivitiesListsService from '@/services/questionnaires/processingActivities/processingActivitiesLists';

export default {
  fetchRecipientsCategory: fetchRecipientsCategoryBuilder({ processingActivitiesListsService }),
  fetchRecipients: fetchRecipientsBuilder({ processingActivitiesListsService }),
  saveNewRecipientsCategoryCustom: saveNewRecipientsCategoryCustomBuilder({
    processingActivitiesRepository,
  }),
  saveNewRecipientsCustom: saveNewRecipientsCustomBuilder({
    processingActivitiesRepository,
  }),
  fetchListCountries: fetchListCountriesBuilder({ countryRepository }),
  saveProcessingActivity: saveProcessingActivityBuilder({
    processingActivitiesRepository,
  }),
};
