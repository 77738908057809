<script>
/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
</script>
<template >
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       :x="x"
       :y="y"
       @mousedown="mouseDown"
       @mouseenter="mouseenter"
       @mouseleave="mouseleave"

  >
    <text class="d-title" v-if="type==='title'" :x="10" :y="30" >{{title}}</text>

    <rect
            v-if="type =='dataflow'"
            fill="#ffffff"
            x="7" y="35"
            rx="3" ry="3"
            :width="width-4" :height="height - 22"
            class="node-light-background">
    </rect>

    <rect y="20" class="st0" width="190" height="52" v-if="type=='dataflow'"/>
    <path fill="#4e4e4e" class="st1" d="M190,20H0V5c0-2.8,2.2-5,5-5h180c2.8,0,5,2.2,5,5V20z"  v-if="type=='dataflow'"/>
    <svg width="190" >
      <foreignObject x="0" y="20" width="190" height="50" v-if="text_top != null" class="d-top">
        <div
          xmlns="http://www.w3.org/1999/xhtml"
        >
          {{text_top}}
        </div>
      </foreignObject>
    </svg>
    <svg width="190" >
      <path :class="class_bottom" d="M185,120H5c-2.8,0-5-2.2-5-5V70h190v45C190,117.8,187.8,120,185,120z"  v-if="type=='dataflow'"/>
      <foreignObject x="0" y="70" width="190" height="50" v-if="text_bottom != null" class="d-bottom">
        <div
          xmlns="http://www.w3.org/1999/xhtml"
        >
          {{text_bottom}}
        </div>
      </foreignObject>
    </svg>

    <g v-if="type=='dataflow'">

      <g v-if="icon === 'self-generated' ">
        <path class="st2" d="M92.1,36.2c0-2.4-2-4.4-4.4-4.4s-4.4,2-4.4,4.4s2,4.4,4.4,4.4C90.1,40.5,92.1,38.6,92.1,36.2z M84.6,36.2
			c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1C86,39.2,84.6,37.9,84.6,36.2z"/>
        <path class="st1" d="M95.8,38.9c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7C93.1,37.7,94.3,38.9,95.8,38.9z
			 M95.8,34.8c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4C94.4,35.4,95.1,34.8,95.8,34.8z"/>
        <path class="st2" d="M87.7,41.6c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1S89.4,41.6,87.7,41.6z M87.7,46.4
			c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S88.7,46.4,87.7,46.4z"/>
        <path class="st1" d="M82.1,38.8c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1S83.2,38.8,82.1,38.8z
			 M82.1,41.7c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C82.9,41.3,82.5,41.7,82.1,41.7z"/>
        <path class="st2" d="M104.7,49.8c-2.9,0-5.3,2.4-5.3,5.3c0,2.9,2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3S107.6,49.8,104.7,49.8z
			 M104.7,59.2c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4C108.7,57.4,106.9,59.2,104.7,59.2z"/>
        <path class="st2" d="M101.9,47.5c0.1-0.4-0.2-0.7-0.5-0.8c-0.4-0.1-0.7,0.2-0.8,0.5l-0.2,1.2L92.7,40c-0.2-0.3-0.7-0.3-0.9,0
			c-0.3,0.2-0.3,0.7,0,0.9l7.6,8.4h-1.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h3.1L101.9,47.5z"/>
      </g>
      <g v-if="icon === 'legal-representative' ">
        <path class="st2" d="M98.2,48.2c0.3-0.6-0.3-0.9-0.3-0.9L94,45.7c-0.3-0.1-0.5-0.4-0.5-0.7v-1.1c1.3-1,2.1-2.6,2.1-4.4v-1.5
				c0.4-0.2,0.7-0.7,0.7-1.2v-0.1c0-3.1-2.5-5.7-5.6-5.7h-1.4c-3.1,0-5.6,2.5-5.6,5.7v1.3c0,0.5,0.2,0.9,0.5,1.1
				c0.1,0,0.1,0.1,0.2,0.1v0.3c0,1.8,0.8,3.4,2.1,4.4v1.1c0,0.3-0.2,0.6-0.5,0.7l-3.9,1.7c-1.3,0.6-2.1,1.8-2.1,3.2v8.8
				c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-8.8c0-0.1,0-0.2,0-0.2s0.1-0.8,0.6-1.3c0.2-0.2,0.4-0.4,0.7-0.5l3.8-1.7l2.8,2.8v9.7
				c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-9.7l2.8-2.8l3.8,1.7C97.3,48.6,97.9,48.8,98.2,48.2z M90,48.6l-2.5-2.5
				c0.2-0.3,0.3-0.7,0.3-1.1v-0.3c0.7,0.3,1.4,0.4,2.2,0.4c0.8,0,1.5-0.2,2.2-0.4v0.3c0,0.4,0.1,0.8,0.3,1.1L90,48.6z M90,43.8
				c-2.4,0-4.3-1.9-4.3-4.3v-0.3c0.4-0.2,0.8-0.4,1.2-0.8c0.3-0.2,0.3-0.7,0-0.9c-0.2-0.3-0.7-0.3-0.9,0c-0.3,0.3-0.7,0.5-1,0.6l0,0
				c0,0,0,0,0-0.1v-1.3c0-2.4,1.9-4.3,4.3-4.3h1.4c2.4,0,4.3,1.9,4.3,4.3v0.1l0,0c-4,0.8-6-0.7-6.7-1.4c-0.3-0.3-0.7-0.3-0.9,0
				c-0.3,0.3-0.3,0.7,0,0.9c2.1,2.1,5.1,2.1,6.9,1.9v1.2C94.3,41.8,92.4,43.8,90,43.8z"/>
        <path class="st2" d="M108.8,51.3l-3.1-1.6c-0.2-0.1-0.3-0.3-0.3-0.5v-0.8c0.8-0.7,1.2-1.7,1.2-2.7v-0.6c0.3,0,0.6-0.3,0.6-0.6
				v-1.2c0-2-1.6-3.6-3.6-3.6h-1.2c-2,0-3.6,1.6-3.6,3.6v1.2c0,0.3,0.3,0.6,0.6,0.6v0.6c0,1,0.4,2,1.2,2.7v0.8
				c0,0.2-0.1,0.4-0.3,0.5c0,0-0.1,0-0.1,0.1l-3,1.5c-0.8,0.4-1.3,1.2-1.3,2.2v6.1c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
				v-6.1c0-0.1,0-0.2,0-0.3c0,0,0.2-0.5,0.7-0.8l3-1.5c0.1,0,0.2-0.1,0.2-0.1l1.3,1.3c0,0,0.2,0.1,0.4,0.2c0.2,0.1,0.5,0.1,0.8-0.2
				l1.3-1.3c0.1,0.1,0.2,0.1,0.3,0.2l3,1.5c0,0,0.6,0.3,0.7,0.8l0,0c0,0.1,0,0.2,0,0.3v6.1c0,0.3,0.3,0.6,0.6,0.6
				c0.3,0,0.6-0.3,0.6-0.6v-6.1C110.1,52.6,109.6,51.8,108.8,51.3z M101.7,42.8c-0.2,0-0.3,0.1-0.4,0.2c-0.5,0.5-1,0.8-1.4,0.9v-0.5
				c0-1.3,1.1-2.4,2.4-2.4h1.2c1.3,0,2.4,1.1,2.4,2.4v0.6c-2.4-0.1-3.8-1.1-3.8-1.1C102,42.8,101.8,42.8,101.7,42.8z M100.5,44.8
				c0.4-0.2,0.9-0.4,1.3-0.8c0.6,0.3,1.8,0.8,3.5,1v0.7c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4V44.8z M101.7,49.2L101.7,49.2
				c0.4,0.1,0.8,0.2,1.2,0.2c0.4,0,0.8-0.1,1.2-0.2l0,0c0,0.1,0,0.3,0.1,0.4l-1.2,1.2l-1.3-1.2C101.7,49.4,101.7,49.3,101.7,49.2z"
        />
      </g>
      <g v-if="icon === 'third-party' ">
        <path class="st2" d="M102.4,34.5h-15c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h15.1c0.4,0,0.7-0.3,0.7-0.7S102.8,34.5,102.4,34.5z"
        />
        <path class="st2" d="M102.4,38.1h-15c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h15.1c0.4,0,0.7-0.3,0.7-0.7S102.8,38.1,102.4,38.1z"
        />
        <path class="st2" d="M104.1,29.8H85.8c-1.4,0-2.5,1.1-2.5,2.5V56c0,1.4,1.1,2.5,2.5,2.5h13.5l1.3,2.2c0.4,0.7,1.1,1.1,1.9,1.1
			c0.4,0,0.7-0.1,1.1-0.3c0.7-0.4,1.1-1.1,1.1-1.9c0-0.4-0.1-0.7-0.3-1.1v-0.1c1.3,0,2.4-1.1,2.4-2.5V32.2
			C106.6,30.9,105.5,29.8,104.1,29.8z M91.2,51.6c-0.8-1.3-0.9-2.8-0.6-4.3c0.4-1.5,1.3-2.7,2.6-3.4c0.9-0.5,1.8-0.8,2.8-0.8
			c2,0,3.9,1.1,4.9,2.8c0.8,1.3,1,2.8,0.6,4.3c-0.4,1.4-1.3,2.7-2.6,3.4c-1.3,0.8-2.8,0.9-4.3,0.6l0,0
			C93.2,53.8,91.9,52.9,91.2,51.6z M101.8,60l-2.8-4.8c0.2-0.1,0.5-0.2,0.7-0.4c0.2-0.1,0.4-0.3,0.7-0.4l2.8,4.8
			c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.4,0.7l0,0C102.3,60.5,101.9,60.4,101.8,60z M105.2,56c0,0.6-0.5,1.1-1.1,1.1h-0.8
			l-2.1-3.6c0.7-0.8,1.3-1.8,1.6-2.9c0.5-1.8,0.2-3.7-0.7-5.3c-1.9-3.3-6.2-4.5-9.6-2.6c-1.6,0.9-2.8,2.4-3.3,4.3
			c-0.5,1.8-0.2,3.7,0.7,5.3s2.4,2.8,4.3,3.3c1.1,0.3,2.2,0.3,3.3,0.1l0.8,1.5H85.8c-0.6,0-1.1-0.5-1.1-1.1V32.2
			c0-0.6,0.5-1.1,1.1-1.1h18.4c0.6,0,1.1,0.5,1.1,1.1C105.2,32.2,105.2,56,105.2,56z"/>
      </g>
      <g v-if="icon === 'data-subject-directly' ">
        <path class="st2" d="M103.8,46.9L99.5,45c-0.3-0.1-0.5-0.4-0.5-0.8V43c1.4-1.2,2.4-2.9,2.4-4.9v-1.7c0.5-0.3,0.8-0.8,0.8-1.3v-0.2
		c0-3.5-2.8-6.3-6.3-6.3h-1.6c-3.5,0-6.3,2.8-6.3,6.3v1.5c0,0.5,0.2,1,0.6,1.2c0.1,0,0.1,0.1,0.2,0.1V38c0,2,0.9,3.8,2.4,4.9v1.2
		c0,0.3-0.2,0.6-0.5,0.8l-4.3,1.9c-1.4,0.6-2.3,2-2.3,3.6v9.9c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-9.8c0-0.1,0-0.2,0-0.3
		c0,0,0.1-0.9,0.7-1.4c0.2-0.2,0.5-0.4,0.8-0.5l4.2-1.9l3.1,3.1v10.8c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7V49.5l3.1-3.1l4.2,1.9
		c0.3,0.1,0.5,0.3,0.8,0.5c0,0,0.6,0.7,0.7,1.4c0,0.1,0,0.2,0,0.3v9.8c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-9.8
		C106.1,48.9,105.2,47.5,103.8,46.9z M95.1,48.1l-2.8-2.8c0.2-0.4,0.3-0.8,0.3-1.2v-0.3c0.7,0.3,1.6,0.5,2.4,0.5
		c0.9,0,1.7-0.2,2.4-0.5v0.3c0,0.4,0.1,0.8,0.3,1.2L95.1,48.1z M95.1,42.9c-2.6,0-4.8-2.2-4.8-4.8v-0.3c0.5-0.2,0.9-0.5,1.3-0.9
		c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0c-0.4,0.3-0.7,0.6-1.1,0.7l0,0c0,0,0,0,0-0.1V35c0-2.6,2.2-4.8,4.8-4.8h1.6
		c2.6,0,4.8,2.2,4.8,4.8v0.2l0,0c-4.4,0.9-6.7-0.8-7.4-1.5c-0.3-0.3-0.8-0.3-1,0c-0.3,0.3-0.3,0.8,0,1C94.6,37,98,37,100,36.8v1.3
		C99.9,40.8,97.7,42.9,95.1,42.9z"/>
      </g>
      <g v-if="icon === 'public-source' ">
        <path class="st2" d="M106.2,33.7c-3-3-7-4.7-11.2-4.7l0,0c-4.3,0-8.2,1.7-11.3,4.7c-3,3-4.7,7-4.7,11.3s1.7,8.2,4.7,11.3
		c3,3,7,4.7,11.3,4.7c4.3,0,8.2-1.7,11.3-4.7c3-3,4.7-7,4.7-11.3C110.8,40.7,109.2,36.7,106.2,33.7z M104.3,55.9
		c-1.2,1-2.5,1.8-3.9,2.4c1.6-1.6,2.8-3.6,3.4-5.8l3.7-0.6C106.7,53.4,105.6,54.8,104.3,55.9z M95.6,52.1v-6.3h7.9
		c-0.1,1.9-0.3,3.7-0.9,5.5l-1.7,0.3C99.2,51.9,97.4,52,95.6,52.1z M102.2,52.9c-1.5,3.7-3.9,6.1-6.5,6.5v-5.8
		c1.9,0,3.8-0.2,5.6-0.5L102.2,52.9z M101.2,37L101.2,37c-1.9-0.3-3.7-0.5-5.6-0.5v-5.8c2.7,0.4,5.1,2.8,6.5,6.5L101.2,37z
		 M103.8,37.4c-0.7-2.2-1.9-4.1-3.4-5.8c1.4,0.6,2.7,1.4,3.9,2.4c1.3,1.1,2.4,2.5,3.3,4L103.8,37.4z M103.6,44.2h-7.9v-6.3
		c1.8,0,3.6,0.2,5.4,0.5l1.7,0.3C103.2,40.5,103.5,42.4,103.6,44.2z M109.3,45.7c-0.1,1.6-0.4,3.1-1,4.6l-4,0.7
		c0.4-1.7,0.7-3.5,0.7-5.3H109.3z M109.3,44.2H105c0-1.8-0.3-3.5-0.7-5.3l4,0.7C108.9,41.1,109.2,42.7,109.3,44.2z M85.5,51l-4-0.7
		c-0.6-1.5-0.9-3-1-4.6h4.3C84.8,47.5,85,49.3,85.5,51z M80.5,44.2c0.1-1.6,0.4-3.1,1-4.6l4-0.7c-0.4,1.7-0.7,3.5-0.7,5.3H80.5z
		 M88.6,53L88.6,53c1.8,0.3,3.7,0.5,5.6,0.5v5.8c-2.7-0.4-5.1-2.8-6.5-6.5L88.6,53z M86,52.6c0.7,2.2,1.9,4.1,3.4,5.8
		C88,57.8,86.7,57,85.5,56c-1.3-1.1-2.4-2.5-3.3-4L86,52.6z M86.2,45.7h7.9V52c-1.8,0-3.6-0.2-5.4-0.5L87,51.2
		C86.6,49.5,86.3,47.6,86.2,45.7z M87.1,38.7l1.7-0.3c1.8-0.3,3.6-0.5,5.4-0.5v6.3h-7.9C86.3,42.4,86.6,40.5,87.1,38.7z M82.2,38
		c0.8-1.5,1.9-2.9,3.3-4c1.2-1,2.5-1.8,3.9-2.4c-1.6,1.6-2.8,3.6-3.4,5.8L82.2,38z M87.6,37.1c1.5-3.7,3.9-6.1,6.5-6.5v5.8
		c-1.9,0-3.8,0.2-5.6,0.5L87.6,37.1z"/>
      </g>
      <g v-if="icon === 'other-processing-activity' ">
        <path class="st2" d="M82.3,40c-2.7,0-4.9,2.2-4.9,5c0,2.7,2.2,5,4.9,5c2.7,0,4.9-2.2,4.9-5C87.2,42.2,85,40,82.3,40z M82.3,48.3
			c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3S84.1,48.3,82.3,48.3z"/>
        <path class="st2" d="M95,40c-2.7,0-4.9,2.2-4.9,5c0,2.7,2.2,5,4.9,5c2.7,0,4.9-2.2,4.9-5C99.9,42.2,97.7,40,95,40z M95,48.3
			c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3S96.8,48.3,95,48.3z"/>
        <path class="st2" d="M108.8,40.1c-1.3-0.3-2.6-0.1-3.7,0.7c-1.1,0.7-1.9,1.8-2.2,3.1c-0.6,2.7,1.1,5.3,3.7,5.9
			c0.4,0.1,0.7,0.1,1.1,0.1c0.9,0,1.8-0.3,2.6-0.8c1.1-0.7,1.9-1.8,2.2-3.1C113.2,43.3,111.5,40.7,108.8,40.1z M111,45.7
			c-0.2,0.9-0.7,1.6-1.5,2.1s-1.6,0.6-2.5,0.4c-1.8-0.4-2.9-2.2-2.5-4c0.2-0.9,0.7-1.6,1.5-2.1c0.5-0.3,1.2-0.5,1.8-0.5
			c0.2,0,0.5,0,0.7,0.1C110.3,42.1,111.4,43.9,111,45.7z"/>
      </g>
      <g v-if="icon === 'data-controller' ">
        <path class="st2" d="M113.3,37l-4.7-4.7c-1.7-1.7-4.2-2.3-6.6-1.5L95,33l-7-2.2c-2.4-0.7-4.9-0.2-6.6,1.5L76.7,37
		c-2.5,2.5-2.4,6.8,0.3,9.5c2.5,2.5,5.8,5.8,8.5,8.5l3.5,3.5c0.4,0.4,1,0.7,1.6,0.9c0.1,0,1.8,0.4,2.6-0.5c0.3-0.4,0.6-0.9,0.7-1.3
		l1.5,1.5c0.6,0.6,1.3,0.9,2.1,0.9h0.1l0,0c0.7,0,1.3-0.2,1.8-0.7c0.6-0.1,1.1-0.4,1.5-0.9l0.2-0.2c0.9,0.2,1.9-0.1,2.6-0.8l0,0
		c0.7-0.7,1-1.7,0.8-2.6c2.5-2.5,5.5-5.5,8.3-8.4C115.7,43.8,115.8,39.6,113.3,37z M90.1,46.1c-0.3,0.1-0.6,0.3-0.6,0.6
		c-0.1,0.3,0,0.7,0.2,0.9l8.5,8.6l0,0c0.2,0.2,0.4,0.6,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3
		c-0.3,0-0.7-0.2-0.9-0.4L88,49.2c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.5,0.1-0.6,0.3s-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6l5,5
		c0.2,0.2,0.4,0.6,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8l0,0c-0.4,0.4-1.2,0.4-1.7-0.1l-0.5-0.4C89,55.9,88.1,55,87.1,54
		c-0.1-0.1-0.2-0.2-0.3-0.3c-2.8-2.8-6.3-6.3-8.6-8.6c-2-2-2.1-5.1-0.3-6.9l4.7-4.7c1.2-1.2,3-1.6,4.7-1.1l4.6,1.4l-2.2,0.7
		c-0.4,0.1-0.7,0.5-0.6,0.9l0.1,0.8c0,1.1,0.5,2.1,1.3,2.7c0.8,0.7,1.9,1,3,0.9h0.3l9.3,9.3c0.5,0.5,0.5,1.3,0.1,1.7l0,0
		c-0.4,0.4-1.2,0.4-1.7-0.1l-3.1-3.1c-2.2-2.2-4.3-4.3-4.5-4.5c-0.4-0.4-0.9-0.4-1.3,0s-0.4,0.9,0,1.3c0.2,0.2,1.8,1.8,3.9,3.9
		l3.6,3.6l0,0l0,0l0,0c0.8,0.8,1.6,1.6,2.2,2.2c0.5,0.5,0.5,1.3,0.1,1.7l0,0c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.2-0.9-0.4L91,46.3
		C90.8,46.1,90.5,46,90.1,46.1z M104.5,52.4L104.5,52.4c1.2-1.2,1.1-3.1-0.1-4.3l-8.1-8.2l0.4-0.4c0.3-0.3,0.3-0.7,0.2-1
		c-0.2-0.3-0.5-0.6-0.9-0.5l-2.6,0.3c-0.6,0.1-1.2-0.1-1.6-0.5s-0.7-0.9-0.7-1.5c0,0,0,0,0-0.1v-0.1l11.5-3.6
		c1.7-0.5,3.5-0.1,4.7,1.1l4.7,4.7c1.8,1.8,1.7,4.9-0.3,6.9L104.5,52.4z"/>
      </g>
      <g v-if="icon == 'data-processor' ">
        <path class="st2" d="M101.8,32.4H88.2c-0.4,0-0.7,0.3-0.7,0.7v3.1c0,0.4,0.3,0.7,0.7,0.7h13.6c0.4,0,0.7-0.3,0.7-0.7v-3.1
		C102.4,32.7,102.1,32.4,101.8,32.4z M101,33.7v1.8H88.8v-1.8H101z"/>
        <path class="st2" d="M101.8,38.6H88.2c-0.4,0-0.7,0.3-0.7,0.7v3.1c0,0.4,0.3,0.7,0.7,0.7h13.6c0.4,0,0.7-0.3,0.7-0.7v-3.1
		C102.4,38.9,102.1,38.6,101.8,38.6z M101,40v1.8H88.8V40H101z"/>
        <path class="st2" d="M101.8,44.8H88.2c-0.4,0-0.7,0.3-0.7,0.7v3.1c0,0.4,0.3,0.7,0.7,0.7h13.6c0.4,0,0.7-0.3,0.7-0.7v-3.1
		C102.4,45.1,102.1,44.8,101.8,44.8z M101,46.2V48H88.8v-1.8H101z"/>
        <path class="st2" d="M101.8,51H88.2c-0.4,0-0.7,0.3-0.7,0.7v3.1c0,0.4,0.3,0.7,0.7,0.7h13.6c0.4,0,0.7-0.3,0.7-0.7v-3.1
		C102.4,51.4,102.1,51,101.8,51z M101,52.4v1.8H88.8v-1.8H101z"/>
        <path class="st2" d="M111.2,59.5v-25c0-0.4-0.3-0.7-0.7-0.7h-5.7v-4.1c0-0.4-0.3-0.7-0.7-0.7H85.8c-0.4,0-0.7,0.3-0.7,0.7V37h-5.7
		c-0.4,0-0.7,0.3-0.7,0.7v22c0,0.1,0,0.1,0,0.2v0.5c0,0.3,0.3,0.6,0.6,0.6h31.3c0.3,0,0.6-0.3,0.6-0.6L111.2,59.5L111.2,59.5z
		 M86.5,59.5V30.4h16.8v29.1H86.5z M85.2,59.5h-5V38.4h5V59.5z M104.8,59.5V35.2h5v24.3H104.8z"/>
      </g>
    </g>
    <text :x="97" :y="14" font-size="9" font-weight="bold" fill="#fff"  v-if="type==='dataflow'" text-anchor="middle">{{title}}</text>

    <slot></slot>
  </svg>

</template>

<script>
/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
export default {
  name: 'DiagramNode',
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    class_bottom: {
      type: String,
      required: true,
    },
    text_top: {
      type: String,
      required: false,
    },
    text_bottom: {
      type: String,
      required: false,
    },
    index: Number,
    ports: {
      type: Array,
      default: () => [],
    },
    x: Number,
    y: Number,
    width: {
      type: Number,
      default: 72,
    },
    height: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: '#66cc00',
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    selected: Boolean,
  },

  data() {
    return {
      nodeStrokeWidth: 0,
      titleFillOpacity: 0.25,
    };
  },

  methods: {
    deleteNode() {
      this.$emit('delete');
    },

    mouseDown(event) {
      this.$emit(
        'onStartDrag',
        { type: 'nodes', index: this.index },
        event.x - this.x,
        event.y - this.y,
      );
    },

    mouseOver() {
      const viewport = document.getElementsByClassName('svg-pan-zoom_viewport');
      const { transform } = viewport[0].style;
      const values = transform.split(',');

      const computedTranslateX = parseFloat(values[4]) * parseFloat(values[3]);
      const computedTranslateY = parseFloat(values[5]) * parseFloat(values[3]);

      const x = parseFloat(this.x) * parseFloat(values[3]);
      const y = parseFloat(this.y) * parseFloat(values[3]);

      const offsetX = x + computedTranslateX;
      const offsetY = y + computedTranslateY - 60;

      document.getElementById('tooltip').innerHTML = this.tooltip;
      document.getElementById('tooltip').style.left = `${offsetX}px`;
      document.getElementById('tooltip').style.top = `${offsetY}px`;
      document.getElementById('tooltip').style.display = 'block';
      setTimeout(() => {
        document.getElementById('tooltip').style.display = 'none';
      }, 5000);
    },

    mouseenter() {
      this.titleFillOpacity = 0.5;
    },

    mouseleave() {
      // document.getElementById('tooltip').style.display = 'none';
      this.titleFillOpacity = 0.25;
    },
  },
};
</script>
