import TomTypeValidationException from '@/domain/tom/TomTypeValidationException';

const TECHNICAL_TYPE = 'technical';

const ORGANISATIONAL_TYPE = 'organisational';

const VALID_TYPES = [TECHNICAL_TYPE, ORGANISATIONAL_TYPE];

export default class TomType {
  private readonly type: string;

  protected constructor(type: string) {
    TomType.isValidOrFail(type);
    this.type = type;
  }

  static isValidOrFail(type: string) {
    if (!VALID_TYPES.includes(type)) {
      throw new TomTypeValidationException(type);
    }
  }

  public toString(): string {
    return this.type;
  }

  public static fromString(type: string): TomType {
    return new this(type);
  }
}
