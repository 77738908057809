import AbstractHeadquarterRepresentation from '@/domain/headquarter/AbstractHeadquarterRepresentation';

export default class HeadquartersRepresentation extends AbstractHeadquarterRepresentation {
  get id() {
    return this._id;
  }

  get name() {
    return this._name.toString();
  }
}
