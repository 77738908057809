<template>
  <div>
    <v-data-table
      :items.sync="list"
      :headers="headers"
      :search="searchTrigger"
      :custom-filter="filterSearch"
      :custom-sort="customSort"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :no-data-text="$t(`${noDataTextKeyRoute}`)"
      :no-results-text="$t('table.no_results')"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      class="elevation-0 data-table table-documents"
      :class="{'data-table__no-fotter': list.length < itemsPerPage}"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :disable-sort="disableSort"
      @update:expanded="$emit('update:currentExpanded', expanded)"
      @pagination="updateTotalItems"
    >
      <template
        v-for="column in columns"
        v-slot:[`item.${column}`]="{ item }"
      >
        <slot :name="column" :item="item" />
      </template>
      <template
        v-slot:expanded-item="{ item }"
      >
        <slot
          name="expandedData"
          :item="item"
        />
      </template>
    </v-data-table>
    <footer-table
      v-show="showFooterIfHasMinItems"
      :page.sync="page"
      :itemsPerPage.sync="itemsPerPage"
      :totalItems="totalItems"
      :listLength="list.length"
    />
  </div>
</template>

<script>
import normalizationService from '@/services/shared/normalization';
import FooterTable from './FooterTable.vue';

const ITEMS_PER_PAGE_DEFAULT = 10;
const MIN_SEARCH_LENGTH = 3;

export default {
  name: 'general-table',
  components: {
    FooterTable,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    sortBy: {
      type: [String, Array],
      default: 'name',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    customFilterSearch: {
      type: Function,
      default: (value, search, item) => {
        if (value) {
          return normalizationService.normalizeString(value).indexOf(search) !== -1;
        }
        return -1;
      },
    },
    columns: {
      type: Array,
      default: () => [],
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    currentExpanded: {
      type: Array,
      default: () => [],
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    noDataTextKeyRoute: {
      type: String,
      default: 'table.no_data',
    },
  },
  computed: {
    searchTrigger() {
      if (this.search.length >= MIN_SEARCH_LENGTH) {
        return this.search;
      }
      return '';
    },
    showFooterIfHasMinItems() {
      return this.totalItems > ITEMS_PER_PAGE_DEFAULT;
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
      expanded: this.currentExpanded,
      totalItems: 0,
    };
  },
  methods: {
    filterSearch(value, search, item) {
      return this.customFilterSearch(value, normalizationService.normalizeString(search), item);
    },
    customSort(items, sortBy, sortDesc) {
      if (items.length === 0 || sortBy.length === 0) {
        return items;
      }

      const orderBy = (items[0][`${sortBy[0]}ToOrderBy`] !== undefined) ? `${sortBy[0]}ToOrderBy` : sortBy[0];
      const itemsSorted = items.sort((compareFirstObject, compareSecondObject) => {
        const firstValue = (typeof compareFirstObject[orderBy] === 'string')
          ? normalizationService.normalizeString(compareFirstObject[orderBy])
          : compareFirstObject[orderBy];
        const secondValue = (typeof compareSecondObject[orderBy] === 'string')
          ? normalizationService.normalizeString(compareSecondObject[orderBy])
          : compareSecondObject[orderBy];

        return (firstValue > secondValue) ? 1 : -1;
      });

      return sortDesc[0] ? itemsSorted.reverse() : itemsSorted;
    },
    updateTotalItems(paginationInfo) {
      this.totalItems = paginationInfo.itemsLength;
    },
  },
  watch: {
    currentExpanded() {
      this.expanded = this.currentExpanded;
    },
  },
};
</script>
