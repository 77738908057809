<template>
  <select-autocomplete-field
      :selectedItems.sync="value"
      :items.sync="securityMeasures"
      :label="label"
      :multiple="true"
      :chips="true"
      item-value="key"
  />
</template>

<script>
import SelectAutocompleteField from '@/components/base/form/SelectAutocompleteField.vue';

export default {
  name: 'security-measures-selector',
  inject: {
    fetchSecurityMeasures: 'fetchSecurityMeasures',
  },
  components: {
    SelectAutocompleteField,
  },
  props: {
    selectedValues: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => this.$t('form.security_measures.select'),
    },
  },
  data() {
    return {
      securityMeasures: [],
      value: this.selectedValues,
    };
  },
  methods: {
    async getSecurityMeasures() {
      this.securityMeasures = await this.fetchSecurityMeasures();
    },
  },
  beforeMount() {
    this.getSecurityMeasures();
  },
  watch: {
    value() {
      this.$emit('update:selectedValues', this.value);
    },
    selectedValues() {
      this.value = this.selectedValues;
    },
  },
};
</script>
