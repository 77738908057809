import { Store } from 'vuex';
import VueI18n from 'vue-i18n';

import TranslateResult = VueI18n.TranslateResult;

export default class AlertSnackBar {
  private vue: any;

  private store: Store<any>;

  constructor(store: Store<any>) {
    this.store = store;
  }

  public install(Vue: any) {
    this.vue = Vue;
    this.addPrototypes();
  }

  private addPrototypes(): void {
    this.vue.prototype.$showError = (
      translationToShow: TranslateResult[] | string | string[],
      translationByDefault: string | null = null,
    ): void => {
      this.showErrorSnackBar(translationToShow, translationByDefault);
    };

    this.vue.prototype.$showSuccess = (
      text: TranslateResult[] | string | string[],
      translationParameters: object = {},
    ): void => {
      this.showSuccessSnackBar(text, translationParameters);
    };

    this.vue.prototype.$showWarning = (
      text: TranslateResult[],
    ): void => {
      this.showWarningSnackBar(text);
    };

    this.vue.prototype.$hideSnackBar = (): void => {
      this.hideSnackBar();
    };
  }

  private showErrorSnackBar(
    translationToShow: TranslateResult[] | string | string[],
    translationByDefault: string | null,
  ): void {
    this.show({
      text: translationToShow,
      parameters: {},
      default: translationByDefault,
      type: 'error',
    });
  }

  private showSuccessSnackBar(
    text: TranslateResult[] | string | string[],
    translationParameters: object,
  ): void {
    this.show({
      text,
      parameters: translationParameters,
      default: null,
      type: 'success',
    });
  }

  private showWarningSnackBar(
    text: TranslateResult[] | string | string[],
  ): void {
    this.show({
      text,
      parameters: {},
      default: null,
      type: 'warning',
    });
  }

  private async hideSnackBar(): Promise<void> {
    await this.store.dispatch('shared/hideSnackBar');
  }

  private async show(config: object): Promise<void> {
    await this.hideSnackBar();
    await this.store.dispatch('shared/showSnackBar', config);
  }
}
