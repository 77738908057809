import DataBreachRepository from '@/domain/dataBreach/DataBreachRepository';
import DataBreachId from '@/domain/dataBreach/DataBreachId';
import DataBreachWasDeleted from '@/domain/dataBreach/DataBreachWasDeleted';
import DataBreachWasDeletedEventListener
  from '@/application/dataBreach/dataBreachWasDeleted/DataBreachWasDeletedEventListener';

export default class DeleteDataBreach {
  private readonly dataBreachRepository: DataBreachRepository;

  private readonly dataBreachWasDeletedEventListener: DataBreachWasDeletedEventListener;

  constructor(
    dataBreachRepository: DataBreachRepository,
    dataBreachWasDeletedEventListener: DataBreachWasDeletedEventListener,
  ) {
    this.dataBreachRepository = dataBreachRepository;
    this.dataBreachWasDeletedEventListener = dataBreachWasDeletedEventListener;
  }

  public async invoke(dataBreachId: string): Promise<object> {
    try {
      const dataBreachIdToDelete = DataBreachId.fromString(dataBreachId);
      await this.dataBreachRepository.delete(dataBreachIdToDelete);
      const event = new DataBreachWasDeleted(dataBreachIdToDelete);
      await this.dataBreachWasDeletedEventListener.invoke(event);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  }
}
