<template>
  <risks-container />
</template>
<script>
import RisksContainer from '@/components/containers/RisksContainer.vue';

export default {
  name: 'risks',
  components: {
    RisksContainer,
  },
};
</script>
