const ADD_CLIENT_USERS = (state, clientUsers) => {
  state.clientUsers = clientUsers;
};

const RESET_CLIENT_USERS = (state) => {
  state.clientUsers = {};
};

const ADD_CLIENT_USERS_LIST = (state, clientUsersList) => {
  state.clientUsersList = clientUsersList;
};

const RESET_CLIENT_USERS_LIST = (state) => {
  state.clientUsersList = {};
};

export default {
  ADD_CLIENT_USERS,
  RESET_CLIENT_USERS,
  ADD_CLIENT_USERS_LIST,
  RESET_CLIENT_USERS_LIST,
};
