<template>
  <div class="d-inline-block">
    <v-btn
      icon
      color="primary"
      :disabled="disabledButton"
      @click="showModalAlertDeletion"
      v-track="actionTracker"
    >
      <v-icon>
        delete
      </v-icon>
    </v-btn>
    <alert-deletion
      v-if="showAlertDeletion"
      :show.sync="showAlertDeletion"
      @confirm="$emit('delete')"
    >
      <template
        v-for="slotName in slotNames"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </alert-deletion>
  </div>
</template>

<script>
import AlertDeletion from '@/components/base/feedback/AlertDeletion.vue';

export default {
  name: 'delete-button-alert',
  components: { AlertDeletion },
  props: {
    disabledButton: {
      type: Boolean,
      default: false,
    },
    actionTracker: {
      type: String,
      default: '',
    },
  },
  computed: {
    slotNames() {
      return ['alert-title', 'text-alert', 'body-text'];
    },
  },
  data() {
    return {
      showAlertDeletion: false,
    };
  },
  methods: {
    showModalAlertDeletion() {
      this.showAlertDeletion = true;
      this.$emit('alertDeletionWasShowed');
    },
  },
};
</script>
