<template>
  <div class="h-100 w-100">
    <h2 class="mb-4">1.
      <span class="grey--text text--darken-3">
        {{ $t("data_breach.form.step.one.title") }}
      </span>
    </h2>
    <v-divider class="ml-5 mb-4"/>
    <v-row class="pl-5 data-breach__form-step">
      <v-col cols="12" md="6">
        <h4 class="mt-3">
          <span>{{ $t("data_breach.form.step.one.question_1") }} *</span>
        </h4>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker-field
          :value.sync="dataBreach.occurredOnDate"
          :maxDate="maxDateOccurred"
          :rules="[validations.requiredDates]"
          :label="$t('data_breach.form.step.one.date')"
        />
      </v-col>
      <v-col cols="6" md="3">
        <time-picker-field
          :value.sync="dataBreach.occurredOnTime"
          :maxTime="maxTimeOccurred"
          :rules="[validations.requiredDates]"
          :label="$t('data_breach.form.step.one.time')"
        />
        </v-col>
      <v-col cols="12" md="6">
        <h4 class="mt-3">
          <span>{{ $t("data_breach.form.step.one.question_2") }} *</span>
        </h4>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker-field
            :value.sync="dataBreach.discoveredOnDate"
            :maxDate="currentDate"
            :minDate="minDateDiscovered"
            :rules="[validations.requiredDates]"
            :label="$t('data_breach.form.step.one.date')"
          />
      </v-col>
      <v-col cols="6" md="3">
        <time-picker-field
            :value.sync="dataBreach.discoveredOnTime"
            :maxTime="maxTimeDiscovered"
            :minTime="minTimeDiscovered"
            :rules="[validations.requiredDates]"
            :label="$t('data_breach.form.step.one.time')"
        />
      </v-col>
      <v-col cols="12">
        <h4>
          <span>{{ $t("data_breach.form.step.one.question_3") }} *
        </span>
        </h4>
        <p>
          {{ $t("data_breach.form.step.one.question_3_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.description"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength, validations.requiredText]"
        />
      </v-col>
      <v-col cols="12">
        <h4>
          <span>{{ $t("data_breach.form.step.one.question_4") }}</span>
        </h4>
        <p>
          {{ $t("data_breach.form.step.one.question_4_info") }}
        </p>
        <textarea-field
          :value.sync="dataBreach.howDiscovered"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
      <v-col cols="12">
        <h4>
          <span>{{ $t("data_breach.form.step.one.question_5") }}</span>
        </h4>
        <textarea-field
          :value.sync="dataBreach.wasIntentional"
          :counter="600"
          :counterValue="textCounter"
          :rules="[validations.maxLength]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePickerField from '@/components/base/form/DatePickerField.vue';
import TextareaField from '@/components/base/form/TextareaField.vue';
import TimePickerField from '@/components/base/form/TimePickerField.vue';

export default {
  name: 'what-happened',
  inject: {
    processingDatesServices: 'processingDatesServices',
  },
  components: {
    TimePickerField,
    TextareaField,
    DatePickerField,
  },
  props: {
    dataBreach: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    currentDate() {
      return new Date().toISOString().substr(0, 10);
    },
    currentTime() {
      const date = new Date();
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    },
    minDateDiscovered() {
      if (!this.dataBreach.occurredOnDate) {
        return null;
      }
      return this.getDate(this.dataBreach.occurredOnDate);
    },
    maxDateOccurred() {
      if (!this.dataBreach.discoveredOnDate) {
        return this.currentDate;
      }
      return this.getDate(this.dataBreach.discoveredOnDate);
    },
    minTimeDiscovered() {
      if (!this.hasOccurredOnTimeAndDatesAreEquals()) {
        return null;
      }

      return this.dataBreach.occurredOnTime;
    },
    maxTimeDiscovered() {
      if (!this.currentDateAndDiscoveredOnAreEquals()) {
        return null;
      }

      this.resetTimeIfIsHigher('discoveredOnTime', this.currentTime);
      return this.currentTime;
    },
    maxTimeOccurred() {
      if (!this.occurredOnAndCurrentDateAreEquals()
        && !this.hasDiscoveredOnTimeAndDatesAreEquals()) {
        return null;
      }

      this.resetTimeIfIsHigher('occurredOnTime', this.dataBreach.discoveredOnTime);
      if (this.occurredOnAndCurrentDateAreEquals()) {
        return this.currentTime;
      }

      return this.dataBreach.discoveredOnTime;
    },
  },
  data() {
    return {
      validations: {
        maxLength: (v) => v.trim().length <= 600 || this.$t('form.validations.max_length', { length: 600 }),
        requiredDates: (v) => !!v || this.$t('form.validations.required_field'),
        requiredText: (v) => !!v.trim() || this.$t('form.validations.required_field'),
      },
    };
  },
  methods: {
    textCounter(text) {
      return text.trim().length;
    },
    hasOccurredOnTimeAndDatesAreEquals() {
      return this.dataBreach.occurredOnTime
        && this.dataBreach.occurredOnDate === this.dataBreach.discoveredOnDate;
    },
    currentDateAndDiscoveredOnAreEquals() {
      return this.getDate(this.dataBreach.discoveredOnDate) === this.currentDate;
    },
    occurredOnAndCurrentDateAreEquals() {
      return this.dataBreach.occurredOnDate !== this.dataBreach.discoveredOnDate
        && this.getDate(this.dataBreach.occurredOnDate) === this.currentDate;
    },
    hasDiscoveredOnTimeAndDatesAreEquals() {
      return this.dataBreach.discoveredOnTime
        && this.dataBreach.occurredOnDate === this.dataBreach.discoveredOnDate;
    },
    getDate(date) {
      if (!date) {
        return null;
      }

      return this.processingDatesServices.createDateFromString(date).toISOString().slice(0, 10);
    },
    resetTimeIfIsHigher(timeToReset, timeToCompare) {
      if (this.dataBreach[timeToReset] > timeToCompare) {
        this.dataBreach[timeToReset] = null;
      }
    },
  },
};
</script>
