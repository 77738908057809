import AbstractRecipientRepresentation from '@/domain/recipient/AbstractRecipientRepresentation';

export default class RecipientRepresentation extends AbstractRecipientRepresentation {
  _translator;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get id() {
    return this._id.toString();
  }

  get name() {
    return this._name.toString();
  }

  get businessName() {
    return (this._businessName) ? this._businessName.toString() : '';
  }

  get country() {
    return (this._country) ? this._country.toString() : '';
  }

  get state() {
    return (this._state) ? this._state.toString() : '';
  }

  get category() {
    return (this._category) ? this._category.toString() : '';
  }

  get role() {
    return (this._role) ? this._role.toString() : '';
  }

  get warranties() {
    return (this._warranties) ? this._warranties.map((warranty) => warranty.toString()) : [];
  }

  get status() {
    return this._status.toString();
  }

  get dpa() {
    return (this._dpa) ? this._dpa.toString() : '';
  }

  get documents() {
    return (this._documents) ? this._documents.map(
      (document) => ({ name: document.toString() }),
    ) : [];
  }

  get dpaStatus() {
    return (this._dpaStatus) ? this._dpaStatus.toString() : '';
  }

  get categoryTranslation() {
    return (this._category) ? this._translator.translate(`recipient.category.${this._category.toString()}`) : '';
  }

  get roleTranslation() {
    return (this._role) ? this._translator.translate(`recipient.role.${this._role.toString()}`) : '';
  }

  get countryTranslation() {
    return (this._country) ? this._translator.translate(`countries.by_code.${this._country.toString()}`) : '';
  }

  get stateTranslation() {
    return (this._country) ? this._translator.translate(`state.${this._state.toString()}`) : '';
  }
}
