<template>
  <div class="h-100 w-100">
    <okta-login
      :iss="iss"
      :code="code"
      :oktaId="oktaId"
    />
  </div>
</template>

<script>
import OktaLogin from '@/components/containers/OktaLogin.vue';

export default {
  name: 'okta-sso',
  components: { OktaLogin },
  props: {
    iss: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    oktaId: {
      type: String,
      default: null,
    },
  },
};
</script>
