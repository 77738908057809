<template>
  <v-navigation-drawer
    :mini-variant="reducedMenu"
    app
    mini-variant-width="70"
    class="vertical-menu"
    color="grey lighten-4"
    permanent
  >
    <v-list-item
      v-track="'Home'"
      class="vertical-menu__home"
      :ripple="false"
      :to="{ name: 'home' }"
    >
        <div
          class="vertical-menu__circle_home"
          :class="{'active': activeLink('home'),
            'warning-notification': hasPendingTasks() }"
        >
          <div
            v-if="hasPendingTasks()"
            class="wave"
          />
        </div>
        <img
          src="@/assets/img/tick_menu.svg"
          alt=""
        >
    </v-list-item>
    <v-list>
      <v-list-item
        v-for="(item, key) in pages"
        :key="key"
        :disabled="user.isAdmin && !!clientId === false"
        :class="{'active_page': activeLink(item.name)}"
        v-track="`Go to ${item.trackAction}`"
        link
        :to="sectionLinkOrNull(item)"
        :href="externalLinkOrNull(item)"
        :target="null !== externalLinkOrNull(item) ? '_blank' : '_self'"
      >
        <v-tooltip
          :disabled="!reducedMenu"
          right
        >
          <template v-slot:activator="{ on }">
            <v-list-item-icon v-on="on">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
          </template>
          <span>{{ item.translation }}</span>
        </v-tooltip>
        <v-list-item-content>
          <v-list-item-title>{{ item.translation }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div
        class="pa-2"
        :class="{
          'text-center': reducedMenu,
          'text-right pr-8' : !reducedMenu
         }"
      >
        <v-btn
          icon
          @click="changeStatusReducedMenu"
        >
          <v-icon
            :class="{'vertical-menu__reduced' : !reducedMenu}"
          >
            keyboard_tab
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import MenuSections from '@/components/base/menus/MenuSection';

export default {
  name: 'vertical-menu',
  inject: {
    user: 'user',
  },
  computed: {
    ...mapGetters('user', {
      clientId: 'companyId',
      userDepartments: 'departments',
      reducedMenu: 'reducedMenu',
    }),
    ...mapGetters('task', {
      tasks: 'tasks',
    }),
    pages() {
      const menu = MenuSections.getMenuSections();

      menu.forEach((section) => {
        Object.assign(section, { translation: this.$t(section.translation) });
        if (section.link) {
          Object.assign(section, { link: this.$t(section.link) });
        }
      });

      return menu;
    },
  },
  methods: {
    ...mapActions('user', {
      changeReducedMenu: 'changeReducedMenu',
    }),
    activeLink(nameRoute) {
      return this.$route.name === nameRoute;
    },
    checkPendingTasksByUserDepartments() {
      if (this.user.isSupervisor) {
        return this.tasks.length;
      }
      let hasTasks = false;
      this.tasks.forEach((task) => {
        if (this.userDepartments.includes(task.department.department)) {
          hasTasks = true;
        }
      });

      return hasTasks;
    },
    hasPendingTasks() {
      this.checkPendingTasksByUserDepartments();
      return this.checkPendingTasksByUserDepartments() && !this.user.isAdmin;
    },
    changeStatusReducedMenu() {
      this.changeReducedMenu(!this.reducedMenu);
      this.$track({
        action: this.reducedMenu ? 'Close menu' : 'Open menu',
      });
    },
    sectionLinkOrNull(menuElement) {
      if (menuElement.link) {
        return null;
      }

      return { name: menuElement.name };
    },
    externalLinkOrNull(menuElement) {
      if (!menuElement.link) {
        return null;
      }

      return menuElement.link;
    },
  },
};
</script>
