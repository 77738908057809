import AbstractEquipmentSecurityMeasuresRepresentation from
  '@/domain/equipment/equipmentSecurityMeasures/AbstractEquipmentSecurityMeasuresRepresentation';

export default class EquipmentSecurityMeasuresRepresentation
  extends AbstractEquipmentSecurityMeasuresRepresentation {
  _translator;

  _name = null;

  constructor(translator) {
    super();
    this._translator = translator;
  }

  get key() {
    return this._key;
  }

  get name() {
    if (!this._name) {
      this._name = this._translator.translate(`equipment.security_measures.${this._key}`);
    }
    return this._name;
  }
}
