import DocumentDataRecipientRepositoryApi from '@/infrastructure/documentData/recipient/DocumentDataRecipientRepositoryApi';
import RecipientCache from '@/infrastructure/recipient/RecipientCache';
import RecipientDocumentWasDeletedEventListener
  from '@/application/recipient/recipientDocumentWasDeleted/RecipientDocumentWasDeletedEventListener';
import fetchDocumentDataRecipientBuilder from './fetchDocumentDataRecipient/fetchDocumentDataRecipient';
import deleteDocumentDataRecipientBuilder
  from './deleteDocumentDataRecipient/deleteDocumentDataRecipient';

export default {
  fetchDocumentDataRecipient: fetchDocumentDataRecipientBuilder(
    new DocumentDataRecipientRepositoryApi(),
  ),
  deleteDocumentDataRecipient: deleteDocumentDataRecipientBuilder(
    new DocumentDataRecipientRepositoryApi(),
    new RecipientDocumentWasDeletedEventListener(RecipientCache),
  ),
};
