<template>
  <v-row>
    <v-col cols="12" md="6">
      <input-search
        :value.sync="searchEquipment"
        :label="$t('form.search')"
      />
    </v-col>
    <v-col cols="12" md="6" class="text-right multiple-button">
      <download-document-button
        v-if="showDownloadButton"
        :id="user.clientId"
        errorMsg="equipment.download.error"
        :btnMsg="$t('equipment.download.button')"
        docType="equipment-list"
        actionTrack="Download resource registry"
      />
      <create-equipment />
    </v-col>
    <v-col cols="12">
      <list-equipments
        :search.sync="searchEquipment"
      />
    </v-col>
  </v-row>
</template>

<script>
import equipmentUseCases from '@/application/equipment';
import documentUseCases from '@/application/document';

import CreateEquipment from '@/components/modules/register/equipments/CreateEquipment.vue';
import ListEquipments from './ListEquipments.vue';
import InputSearch from '../../../base/form/InputSearch.vue';
import DownloadDocumentButton from '../../../base/section/DownloadDocumentButton.vue';

export default {
  name: 'equipments-section',
  provide: {
    ...equipmentUseCases,
    ...documentUseCases,
  },
  inject: {
    user: 'user',
  },
  components: {
    DownloadDocumentButton,
    ListEquipments,
    InputSearch,
    CreateEquipment,
  },
  props: {
    currentTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchEquipment: '',
      showDownloadButton: false,
    };
  },
  methods: {
    mustBeShowDownloadButton(equipmentLength) {
      this.showDownloadButton = !!equipmentLength && this.currentTab === 'equipment';
    },
  },
  beforeMount() {
    equipmentUseCases.resetEquipments();
    this.$eventHub.$on('equipmentsLoaded', this.mustBeShowDownloadButton);
  },
  beforeDestroy() {
    this.$eventHub.$off('equipmentsLoaded', this.mustBeShowDownloadButton);
  },
};
</script>
