export default class TokenLoginCredentials implements LoginCredentials {
  private readonly _token: string;

  constructor(token: string) {
    this._token = token;
  }

  public toString(): string {
    return this._token;
  }
}
